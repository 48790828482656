<template>
  <div>
    <condition-part
      :hasDate="true"
      :hasSupplier="true"
      :hasWarehouse="true"
      :items="purchases"
      :pagination="pagination"
      :columns="columns"
      @getItems="getItems"
      @addItem="add"
      @editItem="edit"
      @removeItem="remove"
      :nameProperty="nameProperty"
      :getTrigger="getTrigger"
      :isReturn="isReturn"
      :hasAdd="authority.IsAdd"
      :hasEdit="authority.IsUpdate"
      :hasRemove="authority.IsRemove"
      :isSortable="true"
    >
      <template slot="dataButtons" slot-scope="props">
        <button
          type="button"
          class="btn btn-outline-secondary btn-icon"
          @click="print(props.index)"
        >
          <i class="fas fa-print"></i>
        </button>
      </template>
    </condition-part>
  </div>
</template>

<script>
import conditionPart from "@/components/parts/conditionPart";

export default {
  data() {
    return {
      purchases: [],
      isReturn: false,
      pagination: {},
      columns: [
        { name: "PurchaseDate" },
        { name: "PurchaseNumber" },
        { name: "SupplierName" },
        { name: "BuyerName" },
        { name: "Total" },
        { name: "YTaxPrice" },
        { name: "TaxPrice" },
        { name: "Memo" },
        { name: "CreateTime" },
        { name: "UpdateTime" },
      ],
      nameProperty: "PurchaseNumber",
      getTrigger: false,
      authority: {},
    };
  },
  computed: {
    routerName() {
      return this.isReturn ? "PurchaseReturn" : "Purchase";
    },
  },
  components: {
    conditionPart,
  },
  methods: {
    getItems(condition) {
      condition.TradeType = this.isReturn ? 4 : 2;
      const vm = this;
      this.$api.purchases.getPages(condition).then((data) => {
        vm.purchases = data.Datas;
        vm.pagination = data.Pagination;
      });
    },
    add() {
      this.$router.push({ name: `${this.routerName}Add` });
    },
    edit(item) {
      if (item.Payed > 0) {
        this.$func.message.alert("此單據已付款無法編輯 !");
        return;
      }
      this.$router.push({
        name: `${this.routerName}Edit`,
        params: { id: item.PurchaseId },
      });
    },
    remove(item) {
      if (item.Payed > 0) {
        this.$func.message.alert("此單據已付款無法刪除 !");
        return;
      }
      const vm = this;
      this.$api.purchases.delete(item.PurchaseId).then(() => {
        vm.getTrigger = !vm.getTrigger;
      });
    },
    print(index) {
      let purchase = this.purchases[index];
      if (!purchase) {
        this.$func.message.alert("請選擇一筆單據 !");
      } else {
        this.$api.purchases.print(purchase.PurchaseId);
      }
    },
  },
  created() {
    const name = this.$route.name;
    if (name.toLowerCase() == "purchasereturns") {
      this.isReturn = true;
    }
    this.authority = this.$func.localStorage.getAuthByName(this.$route.name);
  },
};
</script>
