<template>
  <div>
    <pageTable
      :parentPage="pagination"
      @getItems="getWarehouses"
      @addItem="addWarehouse"
      @editItem="editWarehouse"
      @removeItem="removeWarehouse"
      @trdbClicked="editWarehouse"
      :items="warehouses"
      :columns="columns"
      :idProperty="idProperty"
      :nameProperty="nameProperty"
      :getTrigger="getTrigger"
      :hasAdd="authority.IsAdd || !hasDWAPI"
      :hasEdit="authority.IsUpdate || !hasDWAPI"
      :hasRemove="authority.IsRemove || !hasDWAPI"
      :isSortable="true"
    >
      <template slot="button">
        <button
          v-if="hasDWAPI"
          class="btn btn-digiwin btn-icon"
          @click="postDigiwin()"
        >
          鼎
          <p>新</p>
        </button>
      </template>
    </pageTable>

    <warehouse-data
      :modalTitle="modalTitle"
      :id="warehouseId"
      :trigger="modalTrigger"
      @onSaved="saveWarehouse"
    >
    </warehouse-data>
  </div>
</template>

<script>
import pageTable from "@/components/PageTable";
import warehouseData from "@/components/datas/WarehouseData";

export default {
  data() {
    return {
      warehouses: [],
      pagination: {},
      columns: [
        { name: "WarehouseNumber" },
        { name: "WarehouseName" },
        { name: "Memo" },
      ],
      idProperty: "WarehouseId",
      nameProperty: "WarehouseName",
      getTrigger: false,
      modalTrigger: false,
      warehouseId: "",
      authority: {},
      modalTitle: "",
    };
  },
  components: {
    pageTable,
    warehouseData,
  },
  computed: {
    hasDWAPI() {
      return window.localStorage["erpType"] == 3;
    },
  },
  methods: {
    postDigiwin() {
      this.$func.message.confirm("確定執行同步嗎 ?").then((result) => {
        this.$api.digiwin
          .postDigiwin()
          .then(() => {
            this.getWarehouses();
            this.$func.message.success("下傳成功 !");
          })
          .catch((error) => {
            this.$func.message.error(error.data.Message);
          });
      });
    },
    getWarehouses(dTO) {
      const vm = this;
      this.$api.warehouses.getPages(dTO).then((data) => {
        vm.warehouses = data.Datas;
        vm.pagination = data.Pagination;
      });
    },
    addWarehouse() {
      this.modalTrigger = !this.modalTrigger;
      this.warehouseId = "";
      this.modalTitle = this.$t("modal.addwarehouse");
    },
    editWarehouse(warehouse) {
      if (!this.authority.IsUpdate) {
        return;
      }
      this.modalTrigger = !this.modalTrigger;
      this.warehouseId = warehouse.WarehouseId;
      this.modalTitle = this.$t("modal.editwarehouse");
    },
    removeWarehouse(warehouse) {
      const vm = this;
      this.$api.warehouses.remove(warehouse.WarehouseId).then(() => {
        vm.getTrigger = !vm.getTrigger;
      });
    },
    saveWarehouse() {
      this.getTrigger = !this.getTrigger;
    },
  },
  created() {
    this.authority = this.$func.localStorage.getAuthByName(this.$route.name);
  },
};
</script>
