import { httpGet, httpPost, httpDelete } from "@/request/http";

let path = "/foodkindtastes";

export default {
  getFoodKinds(tasteKindId) {
    return httpGet(`tastekinds/${tasteKindId}/foodkinds`);
  },
  postFoodKinds(item) {
    return httpPost(`${path}/addbatch`, item);
  },
  removeFoodKinds(dto) {
    return httpPost(`${path}/deletebatch`, dto);
  }
};
