<template>
  <div>
    <item-modal
      :isShowModal="isShow"
      :title="modalTitle"
      modalClass="modal-lg"
      @onSaved="save"
      @onClosed="close"
    >
      <div slot="item">
        <div class="row">
          <div class="col-md-6">
            <div class="form-group">
              <label for="storeID">StoreID</label>
              <input
                type="text"
                class="form-control"
                id="storeID"
                v-model="item.store_id"
                readonly
              />
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label for="onlineStatus">{{ $t("modal.status") }}</label>
              <input
                type="text"
                class="form-control"
                id="onlineStatus"
                v-model="item.online_status"
                readonly
              />
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label for="foodId">{{
                $t("modal.ubereatspromotionfood")
              }}</label>
              <div class="input-group mb-3">
                <input
                  id="foodId"
                  type="text"
                  class="form-control"
                  :value="item.FoodName"
                  @dblclick="foodModalTrigger = !foodModalTrigger"
                  :placeholder="`${$t('placeholder.dbselect')}`"
                  readonly
                />
                <div class="input-group-append">
                  <button
                    class="btn btn-outline-danger"
                    type="button"
                    @click="setFood"
                  >
                    {{ $t("button.clear") }}
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="custom-control custom-checkbox">
              <input
                type="checkbox"
                class="custom-control-input"
                id="IntegrationEnabled"
                v-model="integrationEnabled"
              />
              <label class="custom-control-label" for="IntegrationEnabled">{{
                $t("modal.integrationenabled")
              }}</label>
            </div>
          </div>
        </div>
      </div>
    </item-modal>
    <food-selects
      :trigger="foodModalTrigger"
      :isMultiple="false"
      @onSaved="setFood"
    ></food-selects>
  </div>
</template>

<script>
import itemModal from "@/components/ItemModal";
import foodSelects from "@/components/selects/FoodSelects";

export default {
  data() {
    return {
      item: {},
      foodName: "",
      integrationEnabled: false,
      deviceId: "",
      foodModalTrigger: false,
    };
  },
  props: ["isShow", "isEdit", "entity", "id", "modalTitle"],
  watch: {
    entity(val) {
      this.item = val;
      this.integrationEnabled = val.integration_enabled;
    },
    id(value) {
      this.deviceId = value;
    },
  },
  components: {
    itemModal,
    foodSelects,
  },
  methods: {
    setFood(item) {
      if (item) {
        this.item.FoodId = item.FoodId;
        this.item.FoodName = item.FoodName;
      } else {
        this.item.FoodId = undefined;
        this.item.FoodName = undefined;
      }
    },
    save() {
      const vm = this;
      this.$api.uberEats
        .integrationPatch({
          DeviceId: vm.deviceId,
          IntegrationEnabled: vm.integrationEnabled,
          FoodId: vm.item.FoodId,
          FoodName: vm.item.FoodName,
        })
        .then(() => {
          vm.$func.message.success("修改成功 !");
          vm.$emit("save");
        })
        .catch((error) => vm.$func.message.error(error.data.Message));
    },
    close() {
      this.$emit("close");
    },
  },
};
</script>
