<template>
  <div class="card card-table">
    <div class="card-header">
      <slot name="add">
        <button
          v-if="hasAdd"
          type="button"
          class="btn btn-outline-primary btn-icon"
          @click="addItem"
        >
          <i class="fa fa-plus"></i>
        </button>
      </slot>

      <button
        v-if="hasEdit"
        type="button"
        class="btn btn-outline-success btn-icon"
        @click="editItem"
      >
        <i class="fas fa-pen"></i>
      </button>
      <button
        v-if="hasRemove"
        type="button"
        class="btn btn-outline-danger btn-icon"
        @click="removeItem"
      >
        <i class="fas fa-trash-alt"></i>
      </button>
      <button
        v-if="hasLine"
        type="button"
        class="btn btn-outline-success btn-icon"
        @click="setLine"
      >
        <i class="fab fa-line line-icon"></i>
      </button>
      <slot name="button" :index="currentIndex" :item="getCurrentItem"></slot>
    </div>
    <data-body-part
      :parentPage="parentPage"
      :columns="columns"
      :items="items"
      :idProperty="idProperty"
      :nameProperty="nameProperty"
      :getTrigger="getTrigger"
      :hasPage="hasPage"
      :searchTypes="searchTypes"
      @getItems="getItems"
      @currentIndexChanged="currentIndexChanged"
      @trdbClicked="trdbClicked"
      :changeIndex="currentIndex"
      :noInitialLoading="noInitialLoading"
      :viewName="viewName"
      :tableName="tableName"
      :noInitialTableName="noInitialTableName"
      :isSortable="isSortable"
      :isMultiple="isMultiple"
      :hasTotal="hasTotal"
    >
      <template slot="th-before">
        <slot name="th-before" />
      </template>
      <template slot="th-after">
        <slot name="th-after" />
      </template>
      <template slot="td-before" slot-scope="props">
        <slot name="td-before" :item="props.item" />
      </template>
      <template slot="td-after" slot-scope="props">
        <slot name="td-after" :item="props.item" />
      </template>
    </data-body-part>
  </div>
</template>

<script>
import dataBodyPart from "@/components/parts/dataBodyPart";

export default {
  data() {
    return {
      pagination: this.$func.defaultModel.getPage(),
      currentIndex: -1,
      pageDTO: {},
    };
  },
  props: {
    parentPage: Object,
    hasAdd: {
      type: Boolean,
      default: true,
    },
    hasEdit: {
      type: Boolean,
      default: true,
    },
    hasRemove: {
      type: Boolean,
      default: true,
    },
    hasPage: {
      type: Boolean,
      default: true,
    },
    hasLine: {
      type: Boolean,
      default: false,
    },
    items: Array,
    columns: Array,
    idProperty: String,
    nameProperty: String,
    getTrigger: {
      type: Boolean,
      default: false,
    },
    searchTypes: Array,
    changeIndex: {
      type: Number,
      default: -1,
    },
    noInitialLoading: false,
    viewName: String,
    tableName: String,
    noInitialTableName: {
      type: Boolean,
      default: false,
    },
    isSortable: {
      type: Boolean,
      default: false,
    },
    isMultiple: {
      type: Boolean,
      default: false,
    },
    hasTotal: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    items(value) {
      if (value.length == 0 && this.pageDTO.page > 1) {
        let newDTO = this.pageDTO;
        newDTO.page -= 1;
        this.$emit("getItems", newDTO);
      }
    },
    changeIndex: function (value) {
      this.currentIndex = value;
    },
  },
  components: {
    dataBodyPart,
  },
  computed: {
    getCurrentItem() {
      return this.items[this.currentIndex];
    },
  },
  methods: {
    getItems(dTO) {
      this.pageDTO = dTO;
      this.$emit("getItems", this.pageDTO);
    },
    addItem() {
      this.$emit("addItem");
    },
    editItem() {
      let item = this.checkItem();
      if (item) {
        this.$emit("editItem", item);
      }
    },
    removeItem() {
      let item = this.checkItem();
      if (item) {
        const vm = this;

        this.$func.message
          .confirm(`確定刪除「${item[this.nameProperty]}」嗎 ?`)
          .then((result) => {
            if (result.isConfirmed) vm.$emit("removeItem", item);
          });
      }
    },
    setLine() {
      let item = this.checkItem();
      if (item) {
        this.$emit("setLine", item);
      }
    },
    checkItem() {
      let item = this.items[this.currentIndex];
      if (item) {
        return item;
      } else {
        this.$func.message.alert("請選擇一筆資料 !");
        return undefined;
      }
    },
    currentIndexChanged(index) {
      this.currentIndex = index;
      this.$emit("currentIndexChanged", index);
    },
    trdbClicked(item) {
      this.$emit("trdbClicked", item);
    },
  },
};
</script>
