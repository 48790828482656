import i18n from "@/langs/i18n";

export function getAll() {
  return [
    {
      value: 0,
      name: "DegreeNone",
      text: `${i18n.t("option.customerLevelType.DegreeNone")}`,
    },
    {
      value: 1,
      name: "DegreeOne",
      text: `${i18n.t("option.customerLevelType.DegreeOne")}`,
    },
    {
      value: 2,
      name: "DegreeSecond",
      text: `${i18n.t("option.customerLevelType.DegreeSecond")}`,
    },
    {
      value: 3,
      name: "DegreeThird",
      text: `${i18n.t("option.customerLevelType.DegreeThird")}`,
    },
    {
      value: 4,
      name: "DegreeFour",
      text: `${i18n.t("option.customerLevelType.DegreeFour")}`,
    },
    {
      value: 5,
      name: "DegreeFive",
      text: `${i18n.t("option.customerLevelType.DegreeFive")}`,
    },
    {
      value: 6,
      name: "DegreeSix",
      text: `${i18n.t("option.customerLevelType.DegreeSix")}`,
    },
  ];
}

export function getByValue(value) {
  const type = getAll().find(function (item) {
    return item.value == value;
  });
  return type;
}
