<template>
  <div>
    <search-and-table-part
      :pagination="pagination"
      @getItems="getBusinesses"
      @addItem="addBusiness"
      @editItem="editBusiness"
      @removeItem="removeBusiness"
      @trdbClicked="editBusiness"
      :items="businesses"
      :columns="columns"
      :idProperty="idProperty"
      :nameProperty="nameProperty"
      :hasAdd="isEngineer"
      :hasEdit="!isAssistant"
      :hasRemove="isAdmin"
      :getTrigger="getTrigger"
      :isSortable="true"
    >
      <template slot="searchArea">
        <div class="row">
          <div class="col-lg-6">
            <div class="form-group row">
              <label
                for="searchBusinessId"
                class="col-3 col-md-2 col-lg-3 col-form-label"
                >營業人編號:</label
              >
              <div class="col">
                <input
                  type="text"
                  class="form-control"
                  id="searchBusinessId"
                  v-model="searchBusinessId"
                />
              </div>
            </div>
          </div>
          <div class="col-lg-6">
            <div class="form-group row">
              <label
                for="searchBusinessName"
                class="col-3 col-md-2 col-lg-3 col-form-label"
                >營業人名稱:</label
              >
              <div class="col">
                <input
                  type="text"
                  class="form-control"
                  id="searchBusinessName"
                  v-model="searchBusinessName"
                />
              </div>
            </div>
          </div>
          <div class="col-lg-6">
            <div class="form-group row">
              <label
                for="searchProductType"
                class="col-3 col-md-2 col-lg-3 col-form-label"
                >產品名稱:</label
              >
              <div class="col">
                <select
                  class="form-control"
                  id="searchProductType"
                  v-model="searchProductType"
                >
                  <option
                    v-for="productType in productTypes"
                    :key="productType.value"
                    :value="productType.value"
                  >
                    {{ productType.text }}
                  </option>
                </select>
              </div>
            </div>
          </div>
        </div>
        <user-search
          v-if="!isSaler"
          title="業務員"
          roleType="1"
          :searchItems="searchItems"
          @set="userChanged"
        />
      </template>
      <template slot="dataButtonArea" slot-scope="props">
        <button
          type="button"
          class="btn btn-outline-success py-2 px-4"
          style="border-color: transparent"
          @click="onDevice(props.index)"
        >
          <i class="fas fa-cash-register"></i>
        </button>

        <div class="dropdown">
          <button
            v-if="isAdmin"
            id="initialize"
            type="button"
            class="btn btn-outline-primary btn-icon py-2 px-4"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            <i class="fa fa-mouse-pointer"></i>
          </button>
          <div class="dropdown-menu" aria-labelledby="initialize">
            <a class="dropdown-item" @click="initializeSale(props.index)"
              >清帳</a
            >
            <a class="dropdown-item" @click="initializeErp(props.index)"
              >進銷存</a
            >
          </div>
        </div>
        <button
          v-if="isAdmin || isEngineer || isSaler"
          type="button"
          class="btn btn-outline-danger py-2 px-4"
          style="border-color: transparent"
          @click="onCanVoid(props.index)"
        >
          <i class="fas fa-clipboard-list"></i>
        </button>
        <div v-if="isAdmin || isSaler" class="dropdown">
          <button
            id="depositAndIntegral"
            type="button"
            class="btn btn-outline-success btn-icon py-2 px-4"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            <i class="fas fa-file-excel"></i>
          </button>
          <div class="dropdown-menu" aria-labelledby="depositAndIntegral">
            <a
              v-if="isAdmin"
              class="dropdown-item"
              @click="depositAndIntegral(props.index, 1)"
              >重置儲值金</a
            >
            <a class="dropdown-item" @click="depositAndIntegral(props.index, 2)"
              >下載儲值金範例</a
            >
            <a
              v-if="isAdmin"
              class="dropdown-item"
              @click="depositAndIntegral(props.index, 3)"
              >重置積分</a
            >
            <a class="dropdown-item" @click="depositAndIntegral(props.index, 4)"
              >下載積分範例</a
            >
          </div>
          <div
            class="modal fade"
            id="importModal"
            tabindex="-1"
            role="dialog"
            aria-labelledby="exampleModalCenterTitle"
            aria-hidden="true"
          >
            <div class="modal-dialog modal-dialog-centered" role="document">
              <div class="modal-content">
                <div class="modal-header">
                  <h5 class="modal-title">匯入檔案</h5>
                  <button
                    type="button"
                    class="close"
                    data-dismiss="modal"
                    aria-label="Close"
                    @click="resetFile"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div class="modal-body">
                  <form>
                    <div class="fallback">
                      <input
                        name="file"
                        ref="fileref"
                        type="file"
                        @change="fileChange"
                        @click="removeFile"
                      />
                      <button type="button" @click="onImport">
                        {{ $t("button.upload") }}
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
        <button
          v-if="isAdmin || isEngineer"
          type="button"
          class="btn btn-outline-primary py-2 px-4 font-weight-bold"
          style="border-color: transparent"
          @click="onApiKey(props.index)"
        >
          API
        </button>
      </template>

      <th slot="th-before" style="width: 80px">DB</th>
      <td slot="td-before" slot-scope="props">
        <button
          class="btn btn-outline-primary font-weight-bold"
          v-if="!props.item.IsValid"
          @click="patchValid(props.item)"
        >
          啟用
        </button>
      </td>
    </search-and-table-part>

    <itemModal
      :isShowModal="isShowModal"
      @onClosed="isShowModal = !isShowModal"
      @onSaved="saveBusiness"
      :title="modalTitle"
      modalClass="modal-lg"
    >
      <div slot="item">
        <div class="row">
          <div class="col-md-6 col-lg-4">
            <div class="form-group">
              <label for="businessId">編號</label>
              <input
                type="text"
                class="form-control"
                id="businessId"
                v-model="business.BusinessId"
                required
                :readonly="!isAdd"
              />
            </div>
          </div>
          <div class="col-md-6 col-lg-4">
            <div class="form-group">
              <label for="businessName">名稱</label>
              <input
                type="text"
                class="form-control"
                id="businessName"
                v-model="business.BusinessName"
                required
              />
            </div>
          </div>
          <div class="col-md-6 col-lg-4">
            <div class="form-group">
              <label for="principle">負責人</label>
              <input
                type="text"
                class="form-control"
                id="principle"
                v-model="business.Principle"
              />
            </div>
          </div>
          <div class="col-md-6 col-lg-4">
            <div class="form-group">
              <label for="telephone">電話</label>
              <input
                type="text"
                class="form-control"
                id="telephone"
                v-model="business.Telephone"
              />
            </div>
          </div>
          <div class="col-md-6 col-lg-4">
            <div class="form-group">
              <label for="cellphone">手機</label>
              <input
                type="text"
                class="form-control"
                id="cellphone"
                v-model="business.Cellphone"
              />
            </div>
          </div>
          <div class="col-md-6 col-lg-4">
            <div class="form-group">
              <label for="email">信箱</label>
              <input
                type="email"
                class="form-control"
                id="email"
                v-model="business.Email"
              />
            </div>
          </div>
          <div class="col-md-6 col-lg-4">
            <div class="form-group">
              <label for="industryType">行業別</label>
              <select
                class="form-control"
                id="industryType"
                v-model="business.IndustryType"
              >
                <option
                  v-for="industryType in industryTypes"
                  :key="industryType.value"
                  :value="industryType.value"
                >
                  {{ industryType.text }}
                </option>
              </select>
            </div>
          </div>
          <div class="col-12">
            <div class="form-group">
              <label for="address">地址</label>
              <input
                type="text"
                class="form-control"
                id="address"
                v-model="business.Address"
              />
            </div>
          </div>
          <div class="col-md-6 col-lg-4">
            <div class="form-group">
              <label for="productType">產品名稱</label>
              <select
                class="form-control"
                id="productType"
                v-model="business.ProductType"
              >
                <option
                  v-for="productType in productTypes"
                  :key="productType.value"
                  :value="productType.value"
                >
                  {{ productType.text }}
                </option>
              </select>
            </div>
          </div>
          <div class="col-md-6 col-lg-4">
            <div class="form-group">
              <label for="saler">業務員</label>
              <select
                class="form-control"
                id="saler"
                v-model="business.SalerId"
              >
                <option
                  v-for="saler in salers"
                  :key="saler.UserId"
                  :value="saler.UserId"
                >
                  {{ saler.UserName }}
                </option>
              </select>
            </div>
          </div>
          <div class="col-md-6 col-lg-4">
            <div class="form-group">
              <label for="inventoryMachineType">盤點機</label>
              <select
                class="form-control"
                id="inventoryMachineType"
                v-model="business.InventoryMachineType"
              >
                <option
                  v-for="inventoryMachineType in inventoryMachineTypes"
                  :key="inventoryMachineType.value"
                  :value="inventoryMachineType.value"
                >
                  {{ inventoryMachineType.text }}
                </option>
              </select>
            </div>
          </div>
          <div class="col-md-6 col-lg-4">
            <div class="form-group">
              <label for="erpType">ERP</label>
              <select
                class="form-control"
                id="erpType"
                v-model="business.ERPType"
              >
                <option
                  v-for="erpType in erpTypes"
                  :key="erpType.value"
                  :value="erpType.value"
                >
                  {{ erpType.text }}
                </option>
              </select>
            </div>
          </div>
          <div class="col-md-6 col-lg-4">
            <div class="form-group">
              <label for="statusType">狀態</label>
              <select
                class="form-control"
                id="statusType"
                v-model="business.StatusType"
              >
                <option
                  v-for="statusType in statusTypes"
                  :key="statusType.value"
                  :value="statusType.value"
                >
                  {{ statusType.text }}
                </option>
              </select>
            </div>
          </div>
          <div v-if="business.HasOnlineOrder" class="col-md-6 col-lg-4">
            <div class="form-group">
              <label for="tableOrderType">點餐模式</label>
              <select
                class="form-control"
                id="tableOrderType"
                v-model="business.TableOrderType"
              >
                <option
                  v-for="tableOrderType in tableOrderTypes"
                  :key="tableOrderType.value"
                  :value="tableOrderType.value"
                >
                  {{ tableOrderType.text }}
                </option>
              </select>
            </div>
          </div>
          <div class="col-12">
            <div class="form-group">
              <label for="businessMemo">備註</label>
              <input
                type="text"
                class="form-control"
                id="businessMemo"
                v-model="business.Memo"
              />
            </div>
          </div>
          <div class="col-12 row">
            <div class="col-md-3 mt-2">
              <div class="custom-control custom-checkbox">
                <input
                  type="checkbox"
                  class="custom-control-input"
                  id="isCustomer"
                  v-model="business.IsCustomer"
                />
                <label class="custom-control-label" for="isCustomer"
                  >會員</label
                >
              </div>
            </div>
            <div class="col-md-3 mt-2">
              <div class="custom-control custom-checkbox">
                <input
                  type="checkbox"
                  class="custom-control-input"
                  id="isERP"
                  v-model="business.IsERP"
                />
                <label class="custom-control-label" for="isERP">進銷存</label>
              </div>
            </div>
            <div class="col-md-3 mt-2">
              <div class="custom-control custom-checkbox">
                <input
                  type="checkbox"
                  class="custom-control-input"
                  id="hasOcard"
                  v-model="business.HasOcard"
                />
                <label class="custom-control-label" for="hasOcard"
                  >Ocard 會員</label
                >
              </div>
            </div>
            <div
              class="col-md-3 mt-2"
              v-if="business.ProductType == 0 || business.ProductType == 2"
            >
              <div class="custom-control custom-checkbox">
                <input
                  type="checkbox"
                  class="custom-control-input"
                  id="hasOnlineOrder"
                  v-model="business.HasOnlineOrder"
                />
                <label class="custom-control-label" for="hasOnlineOrder"
                  >線上點餐</label
                >
              </div>
            </div>
            <div
              class="col-md-3 mt-2"
              v-if="business.ProductType == 0 || business.ProductType == 2"
            >
              <div class="custom-control custom-checkbox">
                <input
                  type="checkbox"
                  class="custom-control-input"
                  id="hasLineOrder"
                  v-model="business.HasLineOrder"
                />
                <label class="custom-control-label" for="hasLineOrder"
                  >LINE點餐</label
                >
              </div>
            </div>
            <div class="col-md-3 mt-2" v-if="business.ProductType != 3">
              <div class="custom-control custom-checkbox">
                <input
                  type="checkbox"
                  class="custom-control-input"
                  id="hasCloudElecInvocie"
                  v-model="business.HasCloudElecInvoice"
                />
                <label class="custom-control-label" for="hasCloudElecInvocie"
                  >雲端電子發票</label
                >
              </div>
            </div>
            <div class="col-md-3 mt-2">
              <div class="custom-control custom-checkbox">
                <input
                  type="checkbox"
                  class="custom-control-input"
                  id="isElecInvoiceCanPrevious"
                  v-model="business.IsElecInvoiceCanPrevious"
                />
                <label
                  class="custom-control-label"
                  for="isElecInvoiceCanPrevious"
                  >電子發票時間自訂</label
                >
              </div>
            </div>
            <div class="col-md-3 mt-2">
              <div class="custom-control custom-checkbox">
                <input
                  type="checkbox"
                  class="custom-control-input"
                  id="hasMemberWeb"
                  v-model="business.HasMemberWeb"
                />
                <label class="custom-control-label" for="hasMemberWeb"
                  >會員網站</label
                >
              </div>
            </div>
            <div class="col-md-3 mt-2">
              <div class="custom-control custom-checkbox">
                <input
                  type="checkbox"
                  class="custom-control-input"
                  id="hasVision"
                  v-model="business.HasVision"
                />
                <label class="custom-control-label" for="hasVision"
                  >會員視力紀錄</label
                >
              </div>
            </div>
            <div class="col-md-3 mt-2">
              <div class="custom-control custom-checkbox">
                <input
                  type="checkbox"
                  class="custom-control-input"
                  id="hasBatchNumber"
                  v-model="business.HasBatchNumber"
                />
                <label class="custom-control-label" for="hasBatchNumber"
                  >商品批號</label
                >
              </div>
            </div>
            <div class="col-md-3 mt-2">
              <div class="custom-control custom-checkbox">
                <input
                  type="checkbox"
                  class="custom-control-input"
                  id="hasSpecification"
                  v-model="business.HasSpecification"
                />
                <label class="custom-control-label" for="hasSpecification"
                  >商品多規格</label
                >
              </div>
            </div>
            <div class="col-md-3 mt-2">
              <div class="custom-control custom-checkbox">
                <input
                  type="checkbox"
                  class="custom-control-input"
                  id="hasShowmore"
                  v-model="business.HasShowmore"
                />
                <label class="custom-control-label" for="hasShowmore"
                  >Showmore</label
                >
              </div>
            </div>

            <div class="col-md-3 mt-2">
              <div class="custom-control custom-checkbox">
                <input
                  type="checkbox"
                  class="custom-control-input"
                  id="hasEnglish"
                  v-model="business.HasEnglish"
                />
                <label class="custom-control-label" for="hasEnglish"
                  >英文</label
                >
              </div>
            </div>
            <div class="col-md-3 mt-2">
              <div class="custom-control custom-checkbox">
                <input
                  type="checkbox"
                  class="custom-control-input"
                  id="hasJapanese"
                  v-model="business.HasJapanese"
                />
                <label class="custom-control-label" for="hasJapanese"
                  >日文</label
                >
              </div>
            </div>
            <div class="col-md-3 mt-2">
              <div class="custom-control custom-checkbox">
                <input
                  type="checkbox"
                  class="custom-control-input"
                  id="hasKorean"
                  v-model="business.HasKorean"
                />
                <label class="custom-control-label" for="hasKorean">韓文</label>
              </div>
            </div>
            <div class="col-md-3 mt-2">
              <div class="custom-control custom-checkbox">
                <input
                  type="checkbox"
                  class="custom-control-input"
                  id="hasFrench"
                  v-model="business.HasFrench"
                />
                <label class="custom-control-label" for="hasFrench">法文</label>
              </div>
            </div>
          </div>

          <div class="col-12 px-0" v-if="business.ERPType == 1">
            <hr />
          </div>
          <div class="col-12" v-if="business.ERPType == 1">
            <div class="pt-2 pb-3 business-setting-title">凌越</div>
          </div>
          <div class="col-md-6" v-if="business.ERPType == 1">
            <div class="form-group">
              <label for="lyip">IP</label>
              <input
                type="text"
                class="form-control"
                id="lyip"
                v-model="business.LYIP"
                required
              />
            </div>
          </div>
          <div class="col-md-6" v-if="business.ERPType == 1">
            <div class="form-group">
              <label for="lyicpno">公司代碼</label>
              <input
                type="text"
                class="form-control"
                id="lyicpno"
                v-model="business.LYICPNO"
                required
              />
            </div>
          </div>
          <div class="col-md-6" v-if="business.ERPType == 1">
            <div class="form-group">
              <label for="lypusid">帳號</label>
              <input
                type="text"
                class="form-control"
                id="lypusid"
                v-model="business.LYPUSID"
                required
              />
            </div>
          </div>
          <div class="col-md-6" v-if="business.ERPType == 1">
            <div class="form-group">
              <label for="lypverifykey">密碼</label>
              <input
                type="text"
                class="form-control"
                id="lypverifykey"
                v-model="business.LYPVERIFYKEY"
                required
              />
            </div>
          </div>
          <div class="col-12 row" v-if="business.ERPType == 1">
            <div class="col-md-3 form-group">
              <div class="custom-control custom-checkbox">
                <input
                  type="checkbox"
                  class="custom-control-input"
                  id="isLYSaleUploadInTime"
                  v-model="business.IsLYSaleUploadInTime"
                />
                <label class="custom-control-label" for="isLYSaleUploadInTime"
                  >銷貨單即時上傳</label
                >
              </div>
            </div>
            <div class="col-md-3 form-group">
              <div class="custom-control custom-checkbox">
                <input
                  type="checkbox"
                  class="custom-control-input"
                  id="noLYCheck"
                  v-model="business.NoLYCheck"
                />
                <label class="custom-control-label" for="noLYCheck"
                  >不預代確認人</label
                >
              </div>
            </div>
            <div class="col-12">
              <button
                class="btn btn-primary"
                type="button"
                @click="onLingYuehTest"
              >
                測試連線
              </button>
            </div>
          </div>

          <div class="col-12 px-0" v-if="business.ERPType == 3">
            <hr />
          </div>
          <div class="col-12" v-if="business.ERPType == 3">
            <div class="pt-2 pb-3 business-setting-title">鼎新API</div>
          </div>
          <div class="col-md-6" v-if="business.ERPType == 3">
            <div class="form-group">
              <label for="DigiwinDataSource">伺服器</label>
              <input
                type="text"
                class="form-control"
                id="DigiwinDataSource"
                v-model="business.DigiwinDataSource"
                required
              />
            </div>
          </div>
          <div class="col-md-6" v-if="business.ERPType == 3">
            <div class="form-group">
              <label for="DigiwinDatabaseName">資料庫名稱</label>
              <input
                type="text"
                class="form-control"
                id="DigiwinDatabaseName"
                v-model="business.DigiwinDatabaseName"
                required
              />
            </div>
          </div>
          <div class="col-md-6" v-if="business.ERPType == 3">
            <div class="form-group">
              <label for="DigiwinUserId">帳號</label>
              <input
                type="text"
                class="form-control"
                id="DigiwinUserId"
                v-model="business.DigiwinUserId"
                required
              />
            </div>
          </div>
          <div class="col-md-6" v-if="business.ERPType == 3">
            <div class="form-group">
              <label for="DigiwinPassword">密碼</label>
              <input
                type="text"
                class="form-control"
                id="DigiwinPassword"
                v-model="business.DigiwinPassword"
                required
              />
            </div>
          </div>
          <div class="col-md-6" v-if="business.ERPType == 3">
            <div class="form-group">
              <label for="DigiwinApiUri">API</label>
              <input
                type="text"
                class="form-control"
                id="DigiwinApiUri"
                v-model="business.DigiwinApiUri"
                required
              />
            </div>
          </div>
          <div class="col-md-6" v-if="business.ERPType == 3">
            <div class="form-group">
              <label for="DigiwinCompany">公司別</label>
              <input
                type="text"
                class="form-control"
                id="DigiwinCompany"
                v-model="business.DigiwinCompany"
                required
              />
            </div>
          </div>
          <div class="col-12 row" v-if="business.ERPType == 3">
            <div class="col-md-3 form-group">
              <div class="custom-control custom-checkbox">
                <input
                  type="checkbox"
                  class="custom-control-input"
                  id="isDGWSaleUploadInTime"
                  v-model="business.IsLYSaleUploadInTime"
                />
                <label class="custom-control-label" for="isDGWSaleUploadInTime"
                  >銷貨單即時上傳</label
                >
              </div>
            </div>
            <div class="col-12">
              <button
                class="btn btn-primary"
                type="button"
                @click="onDigiwinTest"
              >
                測試連線
              </button>
            </div>
          </div>

          <div class="col-12 px-0" v-if="business.HasShowmore">
            <hr />
          </div>
          <div class="col-md-6" v-if="business.HasShowmore">
            <div class="form-group">
              <label for="showmoreKey">Showmore Key</label>
              <input
                type="text"
                class="form-control"
                id="showmoreKey"
                v-model="business.ShowmoreKey"
                required
              />
            </div>
          </div>
          <div class="col-md-6" v-if="business.HasShowmore">
            <div class="form-group">
              <label for="showmoreWebhookKey">Showmore Webhook Key</label>
              <input
                type="text"
                class="form-control"
                id="showmoreWebhookKey"
                v-model="business.ShowmoreWebhookKey"
                required
              />
            </div>
          </div>
        </div>
      </div>
    </itemModal>

    <cancelVoidModal
      modalId="canVoid"
      :businessId="currentBusinessId"
      :isShow="isShowCanVoidModal"
      @onClosed="isShowCanVoidModal = !isShowCanVoidModal"
      title="已作廢單據"
      modalClass="modal-lg"
    >
    </cancelVoidModal>

    <apiKeyModal
      modalId="apiKey"
      :targetId="currentBusinessId"
      :isShow="isShowApiKeyModal"
      @onClosed="isShowApiKeyModal = !isShowApiKeyModal"
      title="Api Key"
      modalClass="modal-lg"
    >
    </apiKeyModal>
  </div>
</template>

<script>
import $ from "jquery";

import searchAndTablePart from "@/components/parts/searchAndTablePart";
import itemModal from "@/components/ItemModal";
import cancelVoidModal from "@/components/datas/CancelVoidModal";
import apiKeyModal from "@/components/datas/ApiKeyModal";
import userSearch from "@/components/searchs/UserSearch";

export default {
  data() {
    return {
      searchBusinessId: null,
      searchBusinessName: null,
      searchProductType: -1,
      salerIds: [],
      businesses: [],
      business: {},
      currentBusinessId: "",
      isShowCanVoidModal: false,
      pagination: {},
      isShowModal: false,
      isShowApiKeyModal: false,
      modalTitle: "",
      columns: [
        { name: "StatusType" },
        { name: "BusinessId" },
        { name: "BusinessName" },
        { name: "Principle" },
        { name: "DeviceCounts" },
        { name: "SalerName" },
        { name: "ProductType" },
        { name: "CreateTime" },
        { name: "Memo", width: 350 },
      ],
      statusTypes: [
        {
          value: 0,
          text: "未啟用",
        },
        {
          value: 1,
          text: "啟用",
        },
      ],
      productTypes: this.$enum.productType.getAll(),
      inventoryMachineTypes: this.$enum.inventoryMachineType.getAll(),
      erpTypes: this.$enum.erpType.getAll(),
      tableOrderTypes: this.$enum.tableOrderType.getAll(),
      industryTypes: this.$enum.industryType.getAll(),
      idProperty: "BusinessId",
      nameProperty: "BusinessName",
      isAdd: false,
      getTrigger: false,
      salers: [],
      currentType: null,
      file: "",
      searchItems: {},
    };
  },
  components: {
    searchAndTablePart,
    itemModal,
    cancelVoidModal,
    userSearch,
    apiKeyModal,
  },
  computed: {
    isEngineer() {
      return this.$enum.roleType.isEngineer();
    },
    isAssistant() {
      return this.$enum.roleType.isAssistant();
    },
    isSaler() {
      return this.$enum.roleType.isSaler();
    },
    isAdmin() {
      return this.$enum.roleType.isAdmin();
    },
  },
  methods: {
    onApiKey(index) {
      this.currentBusinessId = this.businesses[index].BusinessId;
      this.isShowApiKeyModal = !this.isShowApiKeyModal;
    },
    removeFile(e) {
      e.target.value = null;
    },
    fileChange(e) {
      this.file = e.target.files[0];
    },
    onImport() {
      let text = "";
      if (this.currentType == 1) {
        text = "儲值金";
      } else if (this.currentType == 3) {
        text = "積分";
      }
      this.$func.message.confirm(`確定重置${text}嗎 ?`).then((result) => {
        if (result.isConfirmed) {
          let formData = new FormData();
          formData.append("file", this.file);
          if (this.currentType == 1) {
            this.$api.businesses
              .resetDeposit(this.currentBusinessId, formData)
              .then(() => {
                this.$func.message.success(`重置${text}成功 !`);
              })
              .catch((error) => {
                this.$func.message.error(error.data.message);
              });
          } else if (this.currentType == 3) {
            this.$api.businesses
              .resetIntegral(this.currentBusinessId, formData)
              .then(() => {
                this.$func.message.success(`重置${text}成功 !`);
              })
              .catch((error) => {
                this.$func.message.error(error.data.message);
              });
          }
        }
      });
      this.resetFile();
    },
    resetFile() {
      $("#importModal").modal("toggle");
      setTimeout(() => {
        this.$refs.fileref.value = null;
      }, 500);
    },
    depositAndIntegral(index, type) {
      switch (type) {
        case 1:
          $("#importModal").modal("toggle");
          this.currentBusinessId = this.businesses[index].BusinessId;
          this.currentType = type;
          break;
        case 2:
          this.$api.customers.getDepositSample();
          break;
        case 3:
          $("#importModal").modal("toggle");
          this.currentBusinessId = this.businesses[index].BusinessId;
          this.currentType = type;
          break;
        case 4:
          this.$api.customers.getIntegralSample();
          break;
      }
    },
    onCanVoid(index) {
      this.currentBusinessId = this.businesses[index].BusinessId;
      this.isShowCanVoidModal = !this.isShowCanVoidModal;
    },
    userChanged(items) {
      this.searchItems.User = items;
      let ids = [];
      items.forEach((item) => {
        ids.push(item.UserId);
      });
      this.salerIds = ids;
    },
    getBusinesses(dTO) {
      dTO.SalerIds = this.salerIds;
      dTO.BusinessId = this.searchBusinessId;
      dTO.BusinessName = this.searchBusinessName;
      dTO.ProductType = this.searchProductType;
      if (sessionStorage.getItem("isSamePage")) {
        dTO = JSON.parse(sessionStorage.getItem("condition"));
        this.salerIds = dTO.SalerIds;
        this.searchBusinessId = dTO.BusinessId;
        this.searchBusinessName = dTO.BusinessName;
        this.searchProductType = dTO.ProductType;
        this.searchItems = JSON.parse(sessionStorage.getItem("searchItems"));
        this.$nextTick(() => sessionStorage.removeItem("isSamePage"));
      } else {
        sessionStorage.setItem("condition", JSON.stringify(dTO));
        sessionStorage.setItem("searchItems", JSON.stringify(this.searchItems));
        sessionStorage.setItem("routeName", this.$route.name);
      }
      const vm = this;
      this.$api.businesses.getPages(dTO).then((data) => {
        vm.businesses = data.Datas;
        vm.pagination = data.Pagination;
      });
    },
    addBusiness() {
      this.isAdd = true;
      this.business = {};
      this.isShowModal = !this.isShowModal;
      this.modalTitle = "新增營業人";
    },
    editBusiness(item) {
      const vm = this;
      this.$api.businesses.get(item[vm.idProperty]).then((data) => {
        vm.isAdd = false;
        vm.business = data;
        vm.isShowModal = !vm.isShowModal;
        vm.modalTitle = "編輯營業人";
      });
    },
    saveBusiness() {
      const vm = this;
      if (this.isAdd) {
        this.$api.businesses
          .post(vm.business)
          .then(() => {
            vm.isShowModal = !vm.isShowModal;
            vm.getTrigger = !vm.getTrigger;
          })
          .catch((error) => vm.$func.message.error(error.data.Message));
      } else {
        this.$api.businesses
          .put(vm.business)
          .then(() => {
            vm.isShowModal = !vm.isShowModal;
            vm.getTrigger = !vm.getTrigger;
          })
          .catch((error) => vm.$func.message.error(error.data.Message));
      }
    },
    removeBusiness(business) {
      const vm = this;
      this.$api.businesses.remove(business.BusinessId).then(() => {
        vm.getTrigger = !vm.getTrigger;
      });
    },
    patchValid(item) {
      const vm = this;
      this.$api.businesses.patchValid(item[vm.idProperty]).then(() => {
        vm.getTrigger = !vm.getTrigger;
      });
    },
    onDevice(index) {
      const item = this.businesses[index];
      if (!item) {
        this.$func.message.alert("請選擇一筆資料 !");
        return;
      }
      this.$router.push({ name: "Devices", params: { id: item.BusinessId } });
    },
    onLingYuehTest() {
      const vm = this;
      this.$api.lingYueh
        .postConnectionTest({
          LYIP: vm.business.LYIP,
          LYICPNO: vm.business.LYICPNO,
          LYPUSID: vm.business.LYPUSID,
          LYPVERIFYKEY: vm.business.LYPVERIFYKEY,
        })
        .then(() => {
          vm.$func.message.success("測試成功 !");
          return;
        })
        .catch(() => vm.$func.message.alert("測試失敗 !"));
    },
    onDigiwinTest() {
      const vm = this;
      this.$api.digiwin
        .postConnectionTest({
          DigiwinDataSource: vm.business.DigiwinDataSource,
          DigiwinDatabaseName: vm.business.DigiwinDatabaseName,
          DigiwinUserId: vm.business.DigiwinUserId,
          DigiwinPassword: vm.business.DigiwinPassword,
          DigiwinApiUri: vm.business.DigiwinApiUri,
          DigiwinCompany: vm.business.DigiwinCompany,
        })
        .then(() => {
          vm.$func.message.success("測試成功 !");
          return;
        })
        .catch(() => vm.$func.message.alert("測試失敗 !"));
    },
    initializeSale(index) {
      const item = this.businesses[index];
      const vm = this;
      if (!item) {
        this.$func.message.alert("請選擇一筆資料 !");
        return;
      }
      this.$func.message
        .confirm(`確定將「${item.BusinessName}」進行清帳嗎 ?`)
        .then((result) => {
          if (result.isConfirmed) {
            vm.$api.businesses
              .initializeSale(item.BusinessId)
              .then(() => {
                vm.getTrigger = !vm.getTrigger;
                vm.$func.message.success("清帳成功 !");
              })
              .catch(() => {
                vm.$func.message.alert("清帳失敗 !");
              });
          }
        });
    },
    initializeErp(index) {
      const item = this.businesses[index];
      const vm = this;
      if (!item) {
        this.$func.message.alert("請選擇一筆資料 !");
        return;
      }
      this.$func.message
        .confirm(`確定將「${item.BusinessName}」進銷存初始化嗎 ?`)
        .then((result) => {
          if (result.isConfirmed) {
            vm.$api.businesses
              .initializeErp(item.BusinessId)
              .then(() => {
                vm.getTrigger = !vm.getTrigger;
                vm.$func.message.success("初始化成功 !");
              })
              .catch(() => {
                vm.$func.message.alert("初始化失敗 !");
              });
          }
        });
    },
  },
  mounted() {
    const vm = this;
    this.$api.users.getSalers().then((data) => {
      vm.salers = data;
      vm.salers.splice(0, 0, {
        UserName: "無",
      });
    });
    this.productTypes.splice(0, 0, {
      value: -1,
      name: "None",
      text: "無",
    });
  },
  created() {
    if (this.$route.name == sessionStorage.getItem("routeName")) {
      sessionStorage.setItem("isSamePage", true);
    } else {
      sessionStorage.removeItem("searchItems");
    }
  },
};
</script>
