<template>
  <div>
    <h2 v-if="comboKind">{{ comboKind.ComboKindName }}</h2>
    <div class="row">
      <div class="col-md-6">
        <div class="card card-table">
          <div class="card-header">
            <button
              type="button"
              class="btn btn-outline-primary py-2 px-4"
              style="border-color: transparent"
              @click="onAdd"
            >
              <i class="fa fa-plus"></i>
            </button>
          </div>

          <div class="card-body card-table-body">
            <div class="form-group row" v-if="!isFoodBySelect">
              <label for="foodKindId" class="col-sm-2 col-form-label"
                >{{ $t("modal.kind2") }}:</label
              >
              <div class="col-sm-10">
                <select
                  id="foodKindId"
                  v-if="foodKinds"
                  class="form-control"
                  v-model="currentFoodKindId"
                >
                  <option
                    v-for="foodKind in foodKinds"
                    :key="foodKind.FoodKindId"
                    :value="foodKind.FoodKindId"
                  >
                    {{ foodKind.FoodKindName }}
                  </option>
                </select>
              </div>
            </div>
            <button
              class="btn btn-primary mb-3"
              @click="foodModalTrigger = !foodModalTrigger"
              v-else
            >
              {{ $t("button.selectfood") }}
            </button>
            <table class="table">
              <thead>
                <tr>
                  <th>
                    <div class="custom-control custom-checkbox mr-sm-2">
                      <input
                        type="checkbox"
                        class="custom-control-input"
                        id="allAdd"
                        v-model="allAdd"
                      />
                      <label class="custom-control-label" for="allAdd">{{
                        $t("columnName.add")
                      }}</label>
                    </div>
                  </th>
                  <th>{{ $t("columnName.foodnumber") }}</th>
                  <th>{{ $t("columnName.foodname") }}</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="notSelected in notSelecteds"
                  :key="notSelected.FoodId"
                  @click="notSelectedClick(notSelected.FoodId)"
                >
                  <td>
                    <input
                      type="checkbox"
                      class="checkbox"
                      :value="notSelected.FoodId"
                      v-model="addIds"
                    />
                  </td>
                  <td>{{ notSelected.FoodNumber }}</td>
                  <td>{{ notSelected.FoodName }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="card card-table">
          <div class="card-header">
            <button
              type="button"
              class="btn btn-outline-danger py-2 px-4"
              style="border-color: transparent"
              @click="onRemove"
            >
              <i class="fas fa-trash-alt"></i>
            </button>
          </div>
          <div class="card-body card-table-body">
            <table class="table">
              <thead>
                <tr>
                  <th>
                    <div class="custom-control custom-checkbox mr-sm-2">
                      <input
                        type="checkbox"
                        class="custom-control-input"
                        id="allRemove"
                        v-model="allRemove"
                      />
                      <label class="custom-control-label" for="allRemove">{{
                        $t("columnName.remove")
                      }}</label>
                    </div>
                  </th>
                  <th>{{ $t("columnName.foodnumber") }}</th>
                  <th>{{ $t("columnName.foodname") }}</th>
                  <th>{{ $t("columnName.pricemarkup") }}</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="selected in selecteds"
                  :key="selected.ComboId"
                  @click="selectedClick(selected.ComboId)"
                >
                  <td>
                    <input
                      type="checkbox"
                      class="checkbox"
                      :value="selected.ComboId"
                      v-model="removeIds"
                    />
                  </td>
                  <td>{{ selected.FoodNumber }}</td>
                  <td>{{ selected.FoodName }}</td>
                  <td>{{ selected.Price }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
    <food-selects
      :selectedIds="selectedIds"
      :isFoodsFilter="true"
      :defaultItems="notSelecteds"
      :trigger="foodModalTrigger"
      :isMultiple="true"
      @onSaved="addFoods"
    ></food-selects>
  </div>
</template>

<script>
import foodSelects from "@/components/selects/FoodSelects";

export default {
  data() {
    return {
      comboKind: {},
      foodKinds: [],
      currentFoodKindId: "",
      notSelecteds: [],
      selecteds: [],
      addIds: [],
      removeIds: [],
      allAdd: false,
      allRemove: false,
      isFoodBySelect: window.localStorage["isFoodBySelect"] == "true",
      foodModalTrigger: false,
    };
  },
  props: ["isShow"],
  components: {
    foodSelects,
  },
  computed: {
    selectedIds() {
      let ids = [];
      this.selecteds.forEach((item) => {
        ids.push(item.FoodId);
      });
      return ids;
    },
  },
  watch: {
    currentFoodKindId() {
      this.updateFoods();
    },
    allAdd(value) {
      this.addIds = [];
      if (value) {
        this.notSelecteds.forEach((item) => this.addIds.push(item.FoodId));
      }
    },
    allRemove(value) {
      this.removeIds = [];
      if (value) {
        this.selecteds.forEach((item) => this.removeIds.push(item.ComboId));
      }
    },
  },
  methods: {
    notSelectedClick(id) {
      const index = this.addIds.indexOf(id);
      if (index >= 0) {
        this.addIds.splice(index, 1);
      } else {
        this.addIds.push(id);
      }
    },
    selectedClick(id) {
      const index = this.removeIds.indexOf(id);
      if (index >= 0) {
        this.removeIds.splice(index, 1);
      } else {
        this.removeIds.push(id);
      }
    },
    updateFoods() {
      const vm = this;
      if (this.isFoodBySelect) {
        this.$api.combos
          .getFoodsBySelect(this.$route.params.id)
          .then((data) => {
            vm.addIds = [];
            vm.removeIds = [];
            vm.notSelecteds = data.NotSelecteds;
            vm.selecteds = data.Selecteds;
          });
      } else {
        this.$api.combos
          .getFoods(this.$route.params.id, this.currentFoodKindId)
          .then((data) => {
            vm.addIds = [];
            vm.removeIds = [];
            vm.notSelecteds = data.NotSelecteds;
            vm.selecteds = data.Selecteds;
          });
      }
    },
    onAdd() {
      if (this.addIds.length == 0) {
        this.$func.message.alert("請選擇商品 !");
        return;
      }
      const vm = this;
      this.$api.combos
        .postFoods({
          ComboKindId: vm.comboKind.ComboKindId,
          FoodIds: vm.addIds,
          MinQty: 0,
          MaxQty: 0,
        })
        .then(() => vm.updateFoods());
    },
    onRemove() {
      if (this.removeIds.length == 0) {
        this.$func.message.alert("請選擇商品 !");
        return;
      }
      const vm = this;
      this.$api.combos
        .removeFoods({
          ComboKindId: vm.comboKind.ComboKindId,
          ComboIds: vm.removeIds,
        })
        .then(() => vm.updateFoods());
    },
    addFoods(items) {
      const vm = this;
      items.forEach((item) => {
        let exist = vm.notSelecteds.findIndex((d) => {
          return d.FoodId == item.FoodId;
        });
        if (exist == -1) {
          vm.notSelecteds.push(item);
          vm.addIds.push(item.FoodId);
        }
      });
    },
  },
  created() {
    const vm = this;
    this.$api.comboKinds
      .get(this.$route.params.id)
      .then((data) => (vm.comboKind = data));
    if (!this.isFoodBySelect) {
      this.$api.foodKinds.getNames().then((data) => {
        vm.foodKinds = data;
        if (vm.foodKinds.length > 0) {
          vm.currentFoodKindId = vm.foodKinds[0].FoodKindId;
        }
      });
    } else {
      this.updateFoods();
    }
  },
};
</script>
