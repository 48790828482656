<template>
  <div class="card card-table">
    <div class="card-header">
      <button
        type="button"
        class="btn btn-outline-primary btn-icon"
        @click="onSaved"
      >
        <i class="fas fa-check"></i>
      </button>
      <button
        type="button"
        class="btn btn-outline-danger btn-icon"
        @click="onClosed"
      >
        <i class="fas fa-times"></i>
      </button>
    </div>
    <div class="card-body">
      <div class="table-responsive custom-table">
        <table class="table table-hover">
          <thead>
            <tr>
              <th>刪除</th>
              <th>{{ getDisplayName("Sunday") }}</th>
              <th>{{ getDisplayName("Monday") }}</th>
              <th>{{ getDisplayName("Tuesday") }}</th>
              <th>{{ getDisplayName("Wednesday") }}</th>
              <th>{{ getDisplayName("Thursday") }}</th>
              <th>{{ getDisplayName("Friday") }}</th>
              <th>{{ getDisplayName("Saturday") }}</th>
              <th>{{ getDisplayName("TimeBegin") }}</th>
              <th>{{ getDisplayName("TimeEnd") }}</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(dayModule, index) in dayModules"
              :key="dayModule.DayModuleId"
            >
              <td>
                <button
                  class="btn btn-outline-danger btn-icon"
                  @click="onRemoved(index)"
                >
                  <i class="fas fa-trash-alt"></i>
                </button>
              </td>
              <td>
                <input
                  type="checkbox"
                  class="checkbox"
                  v-model="dayModule.Sunday"
                />
              </td>
              <td>
                <input
                  type="checkbox"
                  class="checkbox"
                  v-model="dayModule.Monday"
                />
              </td>
              <td>
                <input
                  type="checkbox"
                  class="checkbox"
                  v-model="dayModule.Tuesday"
                />
              </td>
              <td>
                <input
                  type="checkbox"
                  class="checkbox"
                  v-model="dayModule.Wednesday"
                />
              </td>
              <td>
                <input
                  type="checkbox"
                  class="checkbox"
                  v-model="dayModule.Thursday"
                />
              </td>
              <td>
                <input
                  type="checkbox"
                  class="checkbox"
                  v-model="dayModule.Friday"
                />
              </td>
              <td>
                <input
                  type="checkbox"
                  class="checkbox"
                  v-model="dayModule.Saturday"
                />
              </td>
              <td>
                <div class="row" style="width: 180px">
                  <input
                    :id="index + 'HourBegin'"
                    type="number"
                    class="form-control col"
                    v-model="dayModule.HourBegin"
                  />
                  <label :for="index + 'HourBegin'" class="col-1 col-form-label"
                    >:</label
                  >
                  <input
                    type="number"
                    class="form-control col"
                    v-model="dayModule.MinuteBegin"
                  />
                </div>
              </td>
              <td style="width: 180px">
                <div class="row" style="width: 180px">
                  <input
                    :id="index + 'HourEnd'"
                    type="number"
                    class="form-control col"
                    v-model="dayModule.HourEnd"
                  />
                  <label :for="index + 'HourEnd'" class="col-1 col-form-label"
                    >:</label
                  >
                  <input
                    type="number"
                    class="form-control col"
                    v-model="dayModule.MinuteEnd"
                  />
                </div>
              </td>
            </tr>
            <tr>
              <td>
                <button class="btn btn-outline-primary btn-icon" @click="onAdd">
                  <i class="fas fa-plus"></i>
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      moduleId: "",
      dayModules: [],
    };
  },
  methods: {
    getDisplayName(name) {
      return this.$func.getColumnProperty(name).text;
    },
    onAdd() {
      this.dayModules.push(this.$func.defaultModel.getDayModule());
    },
    onRemoved(index) {
      this.dayModules.splice(index, 1);
    },
    onSaved() {
      if (this.dayModules.length == 0) {
        this.$func.message.alert("請輸入日期 !");
        return;
      }
      const notCorrect = this.dayModules.some((dayModule) => {
        return (
          parseInt(dayModule.HourBegin) > parseInt(dayModule.HourEnd) ||
          (parseInt(dayModule.HourBegin) == parseInt(dayModule.HourEnd) &&
            parseInt(dayModule.MinuteBegin) > parseInt(dayModule.MinuteEnd))
        );
      });
      if (notCorrect) {
        this.$func.message.alert("輸入時間有誤 !");
        return;
      }
      const vm = this;
      this.$api.dirModules
        .postDays(vm.moduleId, vm.dayModules)
        .then(() => vm.onClosed());
    },
    onClosed() {
      this.$router.go(-1);
    },
  },
  mounted() {
    this.moduleId = this.$route.params["id"];
    if (!this.moduleId || this.moduleId.length == 0) this.$$router.go(-1);
    const vm = this;
    this.$api.dirModules
      .getDays(vm.moduleId)
      .then((data) => (vm.dayModules = data));
  },
};
</script>
