import i18n from "@/langs/i18n";

export function getAll() {
  return [
    {
      value: -1,
      name: "All",
      text: `${i18n.t("option.eatSearchType.All")}`,
    },
    {
      value: 0,
      name: "In",
      text: `${i18n.t("option.eatSearchType.In")}`,
    },
    {
      value: 1,
      name: "Out",
      text: `${i18n.t("option.eatSearchType.Out")}`,
    },
    {
      value: 2,
      name: "Delivery",
      text: `${i18n.t("option.eatSearchType.Delivery")}`,
    },
    {
      value: 3,
      name: "InAndOut",
      text: `${i18n.t("option.eatSearchType.InAndOut")}`,
    },
    {
      value: 4,
      name: "InAndDelivery",
      text: `${i18n.t("option.eatSearchType.InAndDelivery")}`,
    },
    {
      value: 5,
      name: "OutAndDelivery",
      text: `${i18n.t("option.eatSearchType.OutAndDelivery")}`,
    },
    {
      value: 6,
      name: "PickSelf",
      text: `${i18n.t("option.eatSearchType.PickSelf")}`,
    },
    {
      value: 7,
      name: "InAndPickSelf",
      text: `${i18n.t("option.eatSearchType.InAndPickSelf")}`,
    },
    {
      value: 8,
      name: "OutAndPickSelf",
      text: `${i18n.t("option.eatSearchType.OutAndPickSelf")}`,
    },
    {
      value: 9,
      name: "DeliveryAndPickSelf",
      text: `${i18n.t("option.eatSearchType.DeliveryAndPickSelf")}`,
    },
    {
      value: 10,
      name: "InAndOutAndDelivery",
      text: `${i18n.t("option.eatSearchType.InAndOutAndDelivery")}`,
    },
    {
      value: 11,
      name: "InAndOutAndPickSelf",
      text: `${i18n.t("option.eatSearchType.InAndOutAndPickSelf")}`,
    },
    {
      value: 12,
      name: "InAndDeliveryAndPickSelf",
      text: `${i18n.t("option.eatSearchType.InAndDeliveryAndPickSelf")}`,
    },
    {
      value: 13,
      name: "OutAndDeliveryAndPickSelf",
      text: `${i18n.t("option.eatSearchType.OutAndDeliveryAndPickSelf")}`,
    },
  ];
}
