import getViewName from "@/funcs/scripts/getViewName";
import func from "@/funcs";

let path = "/reports/pdf";

function getPdf(condition, p, name) {
  if (!name) name = p;
  func.getPdf.post(`${path}/${p}`, getViewName(name), condition);
}

function printPdf(condition, p, name) {
  if (!name) name = p;
  func.printPdf.post(`${path}/${p}`, condition);
}

export default {
  getTimeStatistic(condition) {
    getPdf(condition, "timestatistic");
  },
  printTimeStatistic(condition) {
    printPdf(condition, "timestatistic");
  },

  getEmployeeAnalysis(condition) {
    getPdf(condition, "employeeanalysis");
  },
  printEmployeeAnalysis(condition) {
    printPdf(condition, "employeeanalysis");
  },

  getFoodSaleStatistic(condition) {
    getPdf(condition, "foodsalestatistic");
  },
  printFoodSaleStatistic(condition) {
    printPdf(condition, "foodsalestatistic");
  },
  getFoodSaleDetail(condition) {
    getPdf(condition, "foodsaledetail");
  },
  printFoodSaleDetail(condition) {
    printPdf(condition, "foodsaledetail");
  },
  getFoodDayStatistic(condition) {
    getPdf(condition, "fooddaystatistic");
  },
  printFoodDayStatistic(condition) {
    printPdf(condition, "fooddaystatistic");
  },
  getFoodMajorKindStatistic(condition) {
    getPdf(condition, "foodmajorkindstatistic");
  },
  printFoodMajorKindStatistic(condition) {
    printPdf(condition, "foodmajorkindstatistic");
  },
  getFoodKindStatistic(condition) {
    getPdf(condition, "foodkindstatistic");
  },
  printFoodKindStatistic(condition) {
    printPdf(condition, "foodkindstatistic");
  },

  getFree(condition) {
    getPdf(
      condition,
      "free",
      condition.ReportType == "statistic" ? "freestatistic" : "freedetail"
    );
  },
  printFree(condition) {
    printPdf(
      condition,
      "free",
      condition.ReportType == "statistic" ? "freestatistic" : "freedetail"
    );
  },

  getCustomerFree(condition) {
    getPdf(condition, "customerfree");
  },
  printCustomerFree(condition) {
    printPdf(condition, "customerfree");
  },

  getCollectionPayment(condition) {
    getPdf(condition, "collectionpayment", "collectionpaymentdetail");
  },
  printCollectionPayment(condition) {
    printPdf(condition, "collectionpayment", "collectionpaymentdetail");
  },

  getInvoice(condition) {
    getPdf(
      condition,
      "invoice",
      condition.ReportType == "simple" ? "invoicesimple" : "invoicedetail"
    );
  },
  printInvoice(condition) {
    printPdf(
      condition,
      "invoice",
      condition.ReportType == "simple" ? "invoicesimple" : "invoicedetail"
    );
  },
  getInvoicePrize(condition) {
    getPdf(condition, "invoiceprize", "reportinvoiceprize");
  },
  printInvoicePrize(condition) {
    printPdf(condition, "invoiceprize", "reportinvoiceprize");
  },

  getWorkRecord(condition) {
    getPdf(
      condition,
      "workrecord",
      condition.ReportType == "simple" ? "workrecordsimple" : "workrecorddetail"
    );
  },
  printWorkRecord(condition) {
    printPdf(
      condition,
      "workrecord",
      condition.ReportType == "simple" ? "workrecordsimple" : "workrecorddetail"
    );
  },

  getSale(condition) {
    getPdf(
      condition,
      "sale",
      condition.ReportType == "simple" ? "salesimple" : "saledetail"
    );
  },
  printSale(condition) {
    printPdf(
      condition,
      "sale",
      condition.ReportType == "simple" ? "salesimple" : "saledetail"
    );
  },

  getSaler(condition) {
    getPdf(
      condition,
      "saler",
      condition.ReportType == "simple"
        ? "salesimplesaler"
        : condition.ReportType == "detail"
        ? "saledetailsaler"
        : "daystatisticsaler"
    );
  },
  printSaler(condition) {
    printPdf(
      condition,
      "saler",
      condition.ReportType == "simple"
        ? "salesimplesaler"
        : condition.ReportType == "detail"
        ? "saledetailsaler"
        : "daystatisticsaler"
    );
  },

  getCustomer(condition) {
    getPdf(
      condition,
      "customer",
      condition.ReportType == "simple" ? "customersimple" : "customerdetail"
    );
  },
  printCustomer(condition) {
    printPdf(
      condition,
      "customer",
      condition.ReportType == "simple" ? "customersimple" : "customerdetail"
    );
  },

  getCustomerFoodSale(condition) {
    getPdf(condition, "customerfoodsale");
  },
  printCustomerFoodSale(condition) {
    printPdf(condition, "customerfoodsale");
  },

  getCustomerSaleStatistic(condition) {
    getPdf(condition, "customersalestatistic");
  },
  printCustomerSaleStatistic(condition) {
    printPdf(condition, "customersalestatistic");
  },

  getPGTalkDeposit(condition) {
    getPdf(condition, "pgtalkdeposit", "pgtalkdepositdetail");
  },
  printPGTalkDeposit(condition) {
    printPdf(condition, "pgtalkdeposit", "pgtalkdepositdetail");
  },

  getSupplier(condition) {
    getPdf(
      condition,
      "supplier",
      condition.ReportType == "simple" ? "suppliersimple" : "supplierdetail"
    );
  },
  printSupplier(condition) {
    printPdf(
      condition,
      "supplier",
      condition.ReportType == "simple" ? "suppliersimple" : "supplierdetail"
    );
  },
  getSupplierFoodSale(condition) {
    getPdf(condition, "supplierfoodsale");
  },
  printSupplierFoodSale(condition) {
    printPdf(condition, "supplierfoodsale");
  },
  getSupplierFoodSaleOriginal(condition) {
    getPdf(condition, "supplierfoodsaleoriginal");
  },
  printSupplierFoodSaleOriginal(condition) {
    printPdf(condition, "supplierfoodsaleoriginal");
  },

  getSupplierPurchaseOrder(condition) {
    getPdf(condition, "supplierpurchaseorder", "supplierpurchaseorderdetail");
  },
  printSupplierPurchaseOrder(condition) {
    printPdf(
      condition,
      "supplierpurchaseorder",
      "supplierpurchaseorderrdetail"
    );
  },

  getFoodPurchaseOrder(condition) {
    getPdf(condition, "foodpurchaseorder", "foodpurchaseorder");
  },
  printFoodPurchaseOrder(condition) {
    printPdf(condition, "foodpurchaseorder", "foodpurchaseorder");
  },
  getFoodPurchase(condition) {
    getPdf(condition, "foodpurchase", "foodpurchasedetail");
  },
  printFoodPurchase(condition) {
    printPdf(condition, "foodpurchase", "foodpurchasedetail");
  },

  getStock(condition) {
    getPdf(condition, "stock");
  },
  printStock(condition) {
    printPdf(condition, "stock");
  },

  getSafeQty(condition) {
    getPdf(condition, "safeqty");
  },
  printSafeQty(condition) {
    printPdf(condition, "safeqty");
  },

  getFoodMovement(condition) {
    getPdf(
      condition,
      "foodmovement",
      condition.ReportType == "simple"
        ? "foodmovementsimple"
        : "foodmovementdetail"
    );
  },
  printFoodMovement(condition) {
    printPdf(
      condition,
      "foodmovement",
      condition.ReportType == "simple"
        ? "foodmovementsimple"
        : "foodmovementdetail"
    );
  },

  getPayment(condition) {
    getPdf(
      condition,
      "payment",
      condition.ReportType == "detail" ? "paymentdetail" : "paymentsimple"
    );
  },
  printPayment(condition) {
    printPdf(
      condition,
      "payment",
      condition.ReportType == "detail" ? "paymentdetail" : "paymentsimple"
    );
  },

  getPayable(condition) {
    getPdf(
      condition,
      "payable",
      condition.ReportType == "detail"
        ? "payabledetail"
        : condition.ReportType == "simple"
        ? "payablesimple"
        : "payablestatistic"
    );
  },
  printPayable(condition) {
    printPdf(
      condition,
      "payable",
      condition.ReportType == "detail"
        ? "payabledetail"
        : condition.ReportType == "simple"
        ? "payablesimple"
        : "payablestatistic"
    );
  },

  getReceipt(condition) {
    getPdf(
      condition,
      "receipt",
      condition.ReportType == "detail" ? "receiptdetail" : "receiptsimple"
    );
  },
  printReceipt(condition) {
    printPdf(
      condition,
      "receipt",
      condition.ReportType == "detail" ? "receiptdetail" : "receiptsimple"
    );
  },

  getReceivable(condition) {
    getPdf(
      condition,
      "receivable",
      condition.ReportType == "detail"
        ? "receivabledetail"
        : condition.ReportType == "simple"
        ? "receivablesimple"
        : "receivablestatistic"
    );
  },
  printReceivable(condition) {
    printPdf(
      condition,
      "receivable",
      condition.ReportType == "detail"
        ? "receivabledetail"
        : condition.ReportType == "simple"
        ? "receivablesimple"
        : "receivablestatistic"
    );
  },

  getPYReceivable(condition) {
    getPdf(condition, "pyreceivable");
  },
  printPYReceivable(condition) {
    printPdf(condition, "pyreceivable");
  },

  getPay(condition) {
    getPdf(
      condition,
      "pay",
      condition.ReportType == "detail" ? "paydetail" : "paysimple"
    );
  },
  printPay(condition) {
    printPdf(
      condition,
      "pay",
      condition.ReportType == "detail" ? "paydetail" : "paysimple"
    );
  },

  getSalePay(condition) {
    getPdf(
      condition,
      "salepay",
      condition.ReportType == "detail" ? "salepaydetail" : "salepaysimple"
    );
  },
  printSalePay(condition) {
    printPdf(
      condition,
      "salepay",
      condition.ReportType == "detail" ? "salepaydetail" : "salepaysimple"
    );
  },

  getTasteStatistic(condition) {
    getPdf(condition, "tastestatistic");
  },
  printTasteStatistic(condition) {
    printPdf(condition, "tastestatistic");
  },

  getDeposit(condition) {
    getPdf(
      condition,
      "deposit",
      condition.ReportType == "sale"
        ? "depositsaledetail"
        : "depositstoredetail"
    );
  },
  printDeposit(condition) {
    printPdf(
      condition,
      "deposit",
      condition.ReportType == "sale"
        ? "depositsaledetail"
        : "depositstoredetail"
    );
  },
  getInventoryPAndL(condition) {
    getPdf(
      condition,
      "inventorypandl",
      condition.ReportType == "statistic"
        ? "inventorypandlstatistic"
        : "inventorypandldetail"
    );
  },
  printInventoryPAndL(condition) {
    printPdf(
      condition,
      "inventorypandl",
      condition.ReportType == "statistic"
        ? "inventorypandlstatistic"
        : "inventorypandldetail"
    );
  },
  getClassSale(condition) {
    getPdf(
      condition,
      "classsale",
      condition.ReportType == "simple"
        ? "classsalesimple"
        : "classsalestatistic"
    );
  },
  printClassSale(condition) {
    printPdf(
      condition,
      "classsale",
      condition.ReportType == "simple"
        ? "classsalesimple"
        : "classsalestatistic"
    );
  },
  getPurchase(condition) {
    getPdf(
      condition,
      "purchase",
      condition.ReportType == "simple" ? "purchasesimple" : "purchasedetail"
    );
  },
  printPurchase(condition) {
    printPdf(
      condition,
      "purchase",
      condition.ReportType == "simple" ? "purchasesimple" : "purchasedetail"
    );
  },
  getPurchaseReturn(condition) {
    getPdf(
      condition,
      "purchasereturn",
      condition.ReportType == "simple"
        ? "purchasereturnsimple"
        : "purchasereturndetail"
    );
  },
  printPurchaseReturn(condition) {
    printPdf(
      condition,
      "purchasereturn",
      condition.ReportType == "simple"
        ? "purchasereturnsimple"
        : "purchasereturndetail"
    );
  },
  getPurchaseOrder(condition) {
    getPdf(
      condition,
      "purchaseorder",
      condition.ReportType == "simple"
        ? "purchaseordersimple"
        : "purchaseorderdetail"
    );
  },
  printPurchaseOrder(condition) {
    printPdf(
      condition,
      "purchaseorder",
      condition.ReportType == "simple"
        ? "purchaseordersimple"
        : "purchaseorderdetail"
    );
  },
  getStockAdjust(condition) {
    getPdf(
      condition,
      "stockadjust",
      condition.ReportType == "simple"
        ? "stockadjustsimple"
        : "stockadjustdetail"
    );
  },
  printStockAdjust(condition) {
    printPdf(
      condition,
      "stockadjust",
      condition.ReportType == "simple"
        ? "stockadjustsimple"
        : "stockadjustdetail"
    );
  },

  getStockAdjustDirectory(condition) {
    getPdf(condition, "stockadjustdirectory", "reportstockadjustdirectory");
  },
  printStockAdjustDirectory(condition) {
    printPdf(condition, "stockadjustdirectory", "reportstockadjustdirectory");
  },

  getStockTransfer(condition) {
    getPdf(
      condition,
      "stocktransfer",
      condition.ReportType == "simple"
        ? "stocktransfersimple"
        : "stocktransferdetail"
    );
  },
  printStockTransfer(condition) {
    printPdf(
      condition,
      "stocktransfer",
      condition.ReportType == "simple"
        ? "stocktransfersimple"
        : "stocktransferdetail"
    );
  },
  getInventory(condition) {
    getPdf(
      condition,
      "inventory",
      condition.ReportType == "simple" ? "inventorysimple" : "inventorydetail"
    );
  },
  printInventory(condition) {
    printPdf(
      condition,
      "inventory",
      condition.ReportType == "simple" ? "inventorysimple" : "inventorydetail"
    );
  },
  getPeople(condition) {
    getPdf(
      condition,
      "people",
      condition.ReportType == "detail"
        ? "peopledetail"
        : condition.ReportType == "simple"
        ? "peoplesimple"
        : "peoplestatistic"
    );
  },
  printPeople(condition) {
    printPdf(
      condition,
      "people",
      condition.ReportType == "detail"
        ? "peopledetail"
        : condition.ReportType == "simple"
        ? "peoplesimple"
        : "peoplestatistic"
    );
  },
  getIntegral(condition) {
    getPdf(
      condition,
      "integral",
      condition.ReportType == "detail"
        ? "integraltradedetail"
        : "integraltradestatistic"
    );
  },
  printIntegral(condition) {
    printPdf(
      condition,
      "integral",
      condition.ReportType == "detail"
        ? "integraltradedetail"
        : "integraltradestatistic"
    );
  },
  getSaleOrder(condition) {
    getPdf(
      condition,
      "saleorder",
      condition.ReportType == "detail" ? "saleorderdetail" : "saleordersimple"
    );
  },
  printSaleOrder(condition) {
    printPdf(
      condition,
      "saleorder",
      condition.ReportType == "detail" ? "saleorderdetail" : "saleordersimple"
    );
  },
  getOrderFoodStatistic(condition) {
    getPdf(condition, "orderfoodstatistic");
  },
  printOrderFoodStatistic(condition) {
    printPdf(condition, "orderfoodstatistic");
  },
  getCoupon(condition) {
    getPdf(condition, "coupon", "coupondetail");
  },
  printCoupon(condition) {
    printPdf(condition, "coupon", "coupondetail");
  },
  getCombinationTrade(condition) {
    getPdf(
      condition,
      "combinationtrade",
      condition.ReportType == "detail"
        ? "combinationtradedetail"
        : "combinationtradesimple"
    );
  },
  printCombinationTrade(condition) {
    printPdf(
      condition,
      "combinationtrade",
      condition.ReportType == "detail"
        ? "combinationtradedetail"
        : "combinationtradesimple"
    );
  },
  getSeperationTrade(condition) {
    getPdf(
      condition,
      "seperationtrade",
      condition.ReportType == "detail"
        ? "seperationtradedetail"
        : "seperationtradesimple"
    );
  },
  printSeperationTrade(condition) {
    printPdf(
      condition,
      "seperationtrade",
      condition.ReportType == "detail"
        ? "seperationtradedetail"
        : "seperationtradesimple"
    );
  },
  getCustomerStock(condition) {
    getPdf(condition, "customerstock");
  },
  printCustomerStock(condition) {
    printPdf(condition, "customerstock");
  },
  getCustomerStockMovement(condition) {
    getPdf(condition, "customerstockmovement", "customerstockmovementdetail");
  },
  printCustomerStockMovement(condition) {
    printPdf(condition, "customerstockmovement", "customerstockmovementdetail");
  },
  getStockBatch(condition) {
    getPdf(condition, "stockbatch", "stockbatch");
  },
  printStockBatch(condition) {
    printPdf(condition, "stockbatch", "stockbatch");
  },
  getStockBatchMovement(condition) {
    getPdf(condition, "stockbatchmovement", "stockbatchmovement");
  },
  printStockBatchMovement(condition) {
    printPdf(condition, "stockbatchmovement", "stockbatchmovement");
  },
  getSaleProfit(condition) {
    getPdf(condition, "saleprofit", "saleprofitdetail");
  },
  printSaleProfit(condition) {
    printPdf(condition, "saleprofit", "saleprofitdetail");
  },
  getERPSummary(condition) {
    getPdf(condition, "erpsummary", "erpsummary");
  },
  printERPSummary(condition) {
    printPdf(condition, "erpsummary", "erpsummary");
  },
  getCreditCard(condition) {
    getPdf(condition, "creditcard", "creditcard");
  },
  printCreditCard(condition) {
    printPdf(condition, "creditcard", "creditcard");
  },
};
