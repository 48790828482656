import { httpGet, httpPost, httpDelete } from "@/request/http";

let path = "/directoryKinds";

export default {
  get(id) {
    return httpGet(`${path}/${id}`);
  },
  getPages(dTO) {
    return httpGet(`${path}/pages`, dTO);
  },
  save(item) {
    return httpPost(path, item);
  },
  remove(id) {
    return httpDelete(`${path}/${id}`);
  },
  getByType(type) {
    return httpGet(`${path}/types/${type}`);
  },
};
