<template>
  <div>
    <modal-part
      modalId="utaiFormData"
      modalClass="modal-lg"
      title="申請單"
      :hasButtons="isEdit"
      :isShow="isShow"
      @onSaved="onSaved"
      @onClosed="onClosed"
    >
      <div slot="item">
        <div class="row" v-if="form.Application">
          <div class="col-md-6">
            <div class="form-group utaiform-group">
              <label class="utaiform-label" for="salesPerson">業務員</label>
              <input
                v-if="isEdit"
                type="text"
                id="salesPerson"
                class="form-control utaiform-control"
                v-model="form.Application.SalesPerson"
              />
              <span v-else class="utaiform-review-value">{{
                form.Application.SalesPerson
              }}</span>
            </div>
          </div>
          <div class="col-md-6" v-if="form.Application.Partner">
            <div class="form-group utaiform-group">
              <label class="utaiform-label" for="utaiPartner">購買公司</label>
              <input
                v-if="isEdit"
                type="text"
                id="utaiPartner"
                class="form-control utaiform-control"
                v-model="form.Application.Partner"
                required
              />
              <span v-else class="utaiform-review-value">{{
                form.Application.Partner
              }}</span>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group utaiform-group">
              <label class="utaiform-label" for="locationType"
                >公司所在地</label
              >
              <select
                v-if="isEdit"
                class="form-control utaiform-control"
                id="locationType"
                v-model="form.Application.LocationType"
              >
                <option
                  v-for="locationOption in locationOptions"
                  :key="locationOption.value"
                  :value="locationOption.value"
                >
                  {{ locationOption.text }}
                </option>
              </select>
              <span v-else class="utaiform-review-value">{{
                this.$enum.utaiLocationType.getByValue(
                  form.Application.LocationType
                ).text
              }}</span>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group utaiform-group">
              <label class="utaiform-label" for="productType"
                >設備 / 系統</label
              >
              <select
                v-if="isEdit"
                class="form-control utaiform-control"
                id="productType"
                v-model="form.Application.ProductType"
              >
                <option
                  v-for="productOption in productOptions"
                  :key="productOption.value"
                  :value="productOption.value"
                >
                  {{ productOption.text }}
                </option>
              </select>
              <span v-else class="utaiform-review-value">{{
                this.$enum.utaiProductType.getByValue(
                  form.Application.ProductType
                ).text
              }}</span>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group utaiform-group">
              <label class="utaiform-label" for="applyType">申請模式</label>
              <select
                v-if="isEdit"
                class="form-control utaiform-control"
                id="applyType"
                v-model="form.Application.ApplyType"
              >
                <option
                  v-for="applyOption in applyOptions"
                  :key="applyOption.value"
                  :value="applyOption.value"
                >
                  {{ applyOption.text }}
                </option>
              </select>
              <span v-else class="utaiform-review-value">{{
                this.$enum.utaiApplyType.getByValue(form.Application.ApplyType)
                  .text
              }}</span>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group utaiform-group">
              <label class="utaiform-label" for="invoiceSheets"
                >預計每月開立發票張數</label
              >
              <input
                v-if="isEdit"
                type="text"
                id="invoiceSheets"
                class="form-control utaiform-control"
                v-model="form.Application.InvoiceSheets"
                required
              />
              <span v-else class="utaiform-review-value">{{
                form.Application.InvoiceSheets
              }}</span>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group utaiform-group">
              <label class="utaiform-label" for="invoiceIncreaseSheets"
                >增加發票張數</label
              >
              <input
                v-if="isEdit"
                type="text"
                id="invoiceIncreaseSheets"
                class="form-control utaiform-control"
                v-model="form.Application.InvoiceIncreaseSheets"
                required
              />
              <span v-else class="utaiform-review-value">{{
                form.Application.InvoiceIncreaseSheets
              }}</span>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group utaiform-group">
              <label class="utaiform-label" for="termLeaseType">使用期間</label>
              <select
                v-if="isEdit"
                class="form-control utaiform-control"
                id="termLeaseType"
                v-model="form.Application.TermLeaseType"
              >
                <option
                  v-for="termLeaseOption in termLeaseOptions"
                  :key="termLeaseOption.value"
                  :value="termLeaseOption.value"
                >
                  {{ termLeaseOption.text }}
                </option>
              </select>
              <span v-else class="utaiform-review-value">{{
                this.$enum.utaiApplyType.getByValue(
                  form.Application.TermLeaseType
                ).text
              }}</span>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group utaiform-group">
              <label class="utaiform-label" for="startDate">預計開始日期</label>
              <input
                v-if="isEdit"
                type="date"
                id="startDate"
                class="form-control utaiform-control"
                v-model="form.Application.StartDate"
              />
              <span v-else class="utaiform-review-value">{{
                form.Application.StartDate
              }}</span>
            </div>
          </div>
          <div v-if="form.Application.TermLeaseType == 1" class="col-md-6">
            <div class="form-group utaiform-group">
              <label class="utaiform-label" for="endDate">預計結束日期</label>
              <input
                v-if="isEdit"
                type="date"
                id="endDate"
                class="form-control utaiform-control"
                v-model="form.Application.EndDate"
              />
              <span v-else class="utaiform-review-value">{{
                form.Application.EndDate
              }}</span>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group utaiform-group">
              <label class="utaiform-label" for="agencyStartDate"
                >委任開始日期</label
              >
              <input
                v-if="isEdit"
                type="date"
                id="agencyStartDate"
                class="form-control utaiform-control"
                v-model="form.Application.AgencyStartDate"
              />
              <span v-else class="utaiform-review-value">{{
                form.Application.AgencyStartDate
              }}</span>
            </div>
          </div>
          <div class="col-12 mt-1">
            <hr class="utaiform-line" />
          </div>
          <div class="col-12 mt-2">
            <div class="form-group utaiform-category">
              <h5>公司基本資料</h5>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group utaiform-group">
              <label class="utaiform-label" for="companyName">公司名稱</label>
              <input
                v-if="isEdit"
                type="text"
                id="companyName"
                class="form-control utaiform-control"
                v-model="form.Application.CompanyName"
                required
              />
              <span v-else class="utaiform-review-value">{{
                form.Application.CompanyName
              }}</span>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group utaiform-group">
              <label class="utaiform-label" for="companyPostalCode"
                >郵遞區號</label
              >
              <input
                v-if="isEdit"
                type="text"
                id="companyPostalCode"
                class="form-control utaiform-control"
                v-model="form.Application.CompanyPostalCode"
                required
              />
              <span v-else class="utaiform-review-value">{{
                form.Application.CompanyPostalCode
              }}</span>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group utaiform-group">
              <label class="utaiform-label" for="companyAddress"
                >營業登記地址</label
              >
              <input
                v-if="isEdit"
                type="text"
                id="companyAddress"
                class="form-control utaiform-control"
                v-model="form.Application.CompanyAddress"
                required
              />
              <span v-else class="utaiform-review-value">{{
                form.Application.CompanyAddress
              }}</span>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group utaiform-group">
              <label class="utaiform-label" for="uniformNumber">統一編號</label>
              <input
                v-if="isEdit"
                type="text"
                id="uniformNumber"
                class="form-control utaiform-control"
                v-model="form.Application.UniformNumber"
                required
              />
              <span v-else class="utaiform-review-value">{{
                form.Application.UniformNumber
              }}</span>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group utaiform-group">
              <label class="utaiform-label" for="taxNumber">稅籍編號</label>
              <input
                v-if="isEdit"
                type="text"
                id="taxNumber"
                class="form-control utaiform-control"
                v-model="form.Application.TaxNumber"
                required
              />
              <span v-else class="utaiform-review-value">{{
                form.Application.TaxNumber
              }}</span>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group utaiform-group">
              <label class="utaiform-label" for="taxationBureau"
                >所屬國稅局</label
              >
              <input
                v-if="isEdit"
                type="text"
                id="taxationBureau"
                class="form-control utaiform-control"
                v-model="form.Application.TaxationBureau"
                required
              />
              <span v-else class="utaiform-review-value">{{
                form.Application.TaxationBureau
              }}</span>
            </div>
          </div>
          <div class="col-12 mt-1">
            <hr class="utaiform-line" />
          </div>
          <div class="col-12 mt-2">
            <div class="form-group utaiform-category">
              <h5>門市地址</h5>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group utaiform-group">
              <label class="utaiform-label" for="storePostalCode"
                >郵遞區號</label
              >
              <input
                v-if="isEdit"
                type="text"
                id="storePostalCode"
                class="form-control utaiform-control"
                v-model="form.Application.StorePostalCode"
                required
              />
              <span v-else class="utaiform-review-value">{{
                form.Application.StorePostalCode
              }}</span>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group utaiform-group">
              <label class="utaiform-label" for="storeAddress">門市地址</label>
              <input
                v-if="isEdit"
                type="text"
                id="storeAddress"
                class="form-control utaiform-control"
                v-model="form.Application.StoreAddress"
                required
              />
              <span v-else class="utaiform-review-value">{{
                form.Application.StoreAddress
              }}</span>
            </div>
          </div>
          <div class="col-12 mt-1">
            <hr class="utaiform-line" />
          </div>
          <div class="col-12 mt-2">
            <div class="form-group utaiform-category">
              <h5>負責人資料</h5>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group utaiform-group">
              <label class="utaiform-label" for="principal">姓名</label>
              <input
                v-if="isEdit"
                type="text"
                id="principal"
                class="form-control utaiform-control"
                v-model="form.Application.Principal"
                required
              />
              <span v-else class="utaiform-review-value">{{
                form.Application.Principal
              }}</span>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group utaiform-group">
              <label class="utaiform-label" for="principalPhone"
                >電話(#分機)</label
              >
              <input
                v-if="isEdit"
                type="text"
                id="principalPhone"
                class="form-control utaiform-control"
                v-model="form.Application.PrincipalPhone"
                required
              />
              <span v-else class="utaiform-review-value">{{
                form.Application.PrincipalPhone
              }}</span>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group utaiform-group">
              <label class="utaiform-label" for="principalCellphone"
                >手機</label
              >
              <input
                v-if="isEdit"
                type="text"
                id="principalCellphone"
                class="form-control utaiform-control"
                v-model="form.Application.PrincipalCellphone"
              />
              <span v-else class="utaiform-review-value">{{
                form.Application.PrincipalCellphone
              }}</span>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group utaiform-group">
              <label class="utaiform-label" for="principalFax">傳真</label>
              <input
                v-if="isEdit"
                type="text"
                id="principalFax"
                class="form-control utaiform-control"
                v-model="form.Application.PrincipalFax"
              />
              <span v-else class="utaiform-review-value">{{
                form.Application.PrincipalFax
              }}</span>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group utaiform-group">
              <label class="utaiform-label" for="principalEmail">Email</label>
              <input
                v-if="isEdit"
                type="text"
                id="principalEmail"
                class="form-control utaiform-control"
                v-model="form.Application.PrincipalEmail"
                required
              />
              <span v-else class="utaiform-review-value">{{
                form.Application.PrincipalEmail
              }}</span>
            </div>
          </div>
          <div class="col-12 mt-1">
            <hr class="utaiform-line" />
          </div>
          <div class="col-12 mt-2">
            <div class="form-group utaiform-category">
              <h5>聯絡人資料</h5>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group utaiform-group">
              <label class="utaiform-label" for="contactPerson">姓名</label>
              <input
                v-if="isEdit"
                type="text"
                id="contactPerson"
                class="form-control utaiform-control"
                v-model="form.Application.ContactPerson"
                required
              />
              <span v-else class="utaiform-review-value">{{
                form.Application.ContactPerson
              }}</span>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group utaiform-group">
              <label class="utaiform-label" for="contactPersonPhone"
                >電話(#分機)</label
              >
              <input
                v-if="isEdit"
                type="text"
                id="contactPersonPhone"
                class="form-control utaiform-control"
                v-model="form.Application.ContactPersonPhone"
                required
              />
              <span v-else class="utaiform-review-value">{{
                form.Application.ContactPersonPhone
              }}</span>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group utaiform-group">
              <label class="utaiform-label" for="contactPersonCellphone"
                >手機</label
              >
              <input
                v-if="isEdit"
                type="text"
                id="contactPersonCellphone"
                class="form-control utaiform-control"
                v-model="form.Application.ContactPersonCellphone"
              />
              <span v-else class="utaiform-review-value">{{
                form.Application.ContactPersonCellphone
              }}</span>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group utaiform-group">
              <label class="utaiform-label" for="contactPersonFax">傳真</label>
              <input
                v-if="isEdit"
                type="text"
                id="contactPersonFax"
                class="form-control utaiform-control"
                v-model="form.Application.ContactPersonFax"
              />
              <span v-else class="utaiform-review-value">{{
                form.Application.ContactPersonFax
              }}</span>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group utaiform-group">
              <label class="utaiform-label" for="contactPersonEmail"
                >Email</label
              >
              <input
                v-if="isEdit"
                type="text"
                id="contactPersonEmail"
                class="form-control utaiform-control"
                v-model="form.Application.ContactPersonEmail"
                required
              />
              <span v-else class="utaiform-review-value">{{
                form.Application.ContactPersonEmail
              }}</span>
            </div>
          </div>
          <div class="col-12 mt-1">
            <hr class="utaiform-line" />
          </div>
          <div class="col-12 mt-2">
            <div class="form-group utaiform-category">
              <h5>其他</h5>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group utaiform-group">
              <label class="utaiform-label" for="mainGoods">主要商品</label>
              <input
                v-if="isEdit"
                type="text"
                id="mainGoods"
                class="form-control utaiform-control"
                v-model="form.Application.MainGoods"
                required
              />
              <span v-else class="utaiform-review-value">{{
                form.Application.MainGoods
              }}</span>
            </div>
          </div>
          <div class="col-12" v-if="form.Application.Partner">
            <hr class="utaiform-line" />
          </div>
          <div class="col-12 mt-2" v-if="form.Application.Partner">
            <div class="form-group utaiform-category">
              <h5>財政部紙本文件收件資料</h5>
            </div>
          </div>
          <div class="col-md-6" v-if="form.Application.Partner">
            <div class="form-group utaiform-group">
              <label class="utaiform-label" for="receiver">收件人</label>
              <input
                v-if="isEdit"
                type="text"
                id="receiver"
                class="form-control utaiform-control"
                v-model="form.Application.Receiver"
                required
              />
              <span v-else class="utaiform-review-value">{{
                form.Application.Receiver
              }}</span>
            </div>
          </div>
          <div class="col-md-6" v-if="form.Application.Partner">
            <div class="form-group utaiform-group">
              <label class="utaiform-label" for="receiverAddress">地址</label>
              <input
                v-if="isEdit"
                type="text"
                id="receiverAddress"
                class="form-control utaiform-control"
                v-model="form.Application.ReceiverAddress"
              />
              <span v-else class="utaiform-review-value">{{
                form.Application.ReceiverAddress
              }}</span>
            </div>
          </div>
          <div class="col-md-6" v-if="form.Application.Partner">
            <div class="form-group utaiform-group">
              <label class="utaiform-label" for="receiverPhone"
                >手機或市話</label
              >
              <input
                v-if="isEdit"
                type="text"
                id="receiverPhone"
                class="form-control utaiform-control"
                v-model="form.Application.ReceiverPhone"
              />
              <span v-else class="utaiform-review-value">{{
                form.Application.ReceiverPhone
              }}</span>
            </div>
          </div>
          <div class="col-12">
            <hr class="utaiform-line" />
          </div>
          <div class="col-12 pb-2">
            <div class="col-12 mt-2 p-0">
              <div class="form-group utaiform-category">
                <h5>備註</h5>
              </div>
            </div>
            <div class="col-md-12 p-0">
              <div class="form-group utaiform-group">
                <input
                  v-if="isEdit"
                  type="text"
                  id="receiver"
                  class="form-control utaiform-control"
                  v-model="form.Application.Memo"
                />
                <span v-else class="utaiform-review-value">{{
                  form.Application.Memo
                }}</span>
              </div>
              <div class="utaiform-btn-area" v-if="isEdit">
                <button
                  v-for="(memo, index) in memos"
                  :key="index"
                  type="button"
                  class="btn btn-outline-primary utaiform-memo-btn"
                  @click="addMemo(memo.value)"
                >
                  {{ memo.value }}
                </button>
              </div>
            </div>
          </div>

          <div
            v-if="
              form.ApplicationBranches && form.ApplicationBranches.length > 0
            "
            class="col-12"
          >
            <hr class="utaiform-line" />
          </div>
        </div>
        <div
          v-if="form.ApplicationBranches && form.ApplicationBranches.length > 0"
        >
          <div class="col-12 mt-2 mb-3 utaiform-branch">
            <div class="form-group utaiform-category">
              <h5>總分支機構</h5>
            </div>
          </div>
          <div v-for="(branch, index) in form.ApplicationBranches" :key="index">
            <div class="branch-container">
              <div class="d-flex branch-index">
                <span>{{ `${index + 1}.` }}</span>
              </div>
              <div class="branch-uni-num-container">
                <div class="form-group utaiform-group">
                  <label class="utaiform-label" :for="`uniformNumber${index}`"
                    >統一編號</label
                  >
                  <span
                    v-if="isEdit"
                    class="d-flex align-items-center utaiform-review-value branch-uniform-number"
                    >{{ branch.UniformNumber }}</span
                  >
                  <span
                    v-else
                    class="d-flex align-items-center utaiform-review-value"
                    >{{ branch.UniformNumber }}</span
                  >
                </div>
              </div>
              <div class="d-flex flex-column branch-info-container">
                <div class="branch-flex-container">
                  <div class="col-12 col-md-6 branch-content">
                    <div class="form-group utaiform-group">
                      <label class="utaiform-label" :for="`companyName${index}`"
                        >公司名稱</label
                      >
                      <input
                        v-if="isEdit"
                        type="text"
                        :id="`companyName${index}`"
                        class="form-control utaiform-control"
                        v-model="branch.CompanyName"
                        required
                      />
                      <span v-else class="utaiform-review-value">{{
                        branch.CompanyName
                      }}</span>
                    </div>
                  </div>
                  <div class="col-12 col-md-6 branch-content">
                    <div class="form-group utaiform-group">
                      <label class="utaiform-label" :for="`city${index}`"
                        >城市</label
                      >
                      <input
                        v-if="isEdit"
                        type="text"
                        :id="`city${index}`"
                        class="form-control utaiform-control"
                        v-model="branch.City"
                        required
                      />
                      <span v-else class="utaiform-review-value">{{
                        branch.City
                      }}</span>
                    </div>
                  </div>
                </div>
                <div class="branch-flex-container">
                  <div class="col-12 col-md-6 branch-content">
                    <div class="form-group utaiform-group">
                      <label class="utaiform-label" :for="`taxNumber${index}`"
                        >稅籍編號</label
                      >
                      <input
                        v-if="isEdit"
                        type="text"
                        :id="`taxNumber${index}`"
                        class="form-control utaiform-control"
                        v-model="branch.TaxNumber"
                        required
                      />
                      <span v-else class="utaiform-review-value">{{
                        branch.TaxNumber
                      }}</span>
                    </div>
                  </div>
                  <div class="col-12 col-md-6 branch-content">
                    <div class="form-group utaiform-group">
                      <label
                        class="utaiform-label"
                        :for="`contactPerson${index}`"
                        >聯絡人姓名</label
                      >
                      <input
                        v-if="isEdit"
                        type="text"
                        :id="`ContactPerson${index}`"
                        class="form-control utaiform-control"
                        v-model="branch.ContactPerson"
                        required
                      />
                      <span v-else class="utaiform-review-value">{{
                        branch.ContactPerson
                      }}</span>
                    </div>
                  </div>
                </div>
                <div class="branch-flex-container">
                  <div class="col-12 col-md-6 branch-content">
                    <div class="form-group utaiform-group">
                      <label
                        class="utaiform-label"
                        :for="`contactPersonPhone${index}`"
                        >聯絡人電話</label
                      >
                      <input
                        v-if="isEdit"
                        type="text"
                        :id="`contactPersonPhone${index}`"
                        class="form-control utaiform-control"
                        v-model="branch.ContactPersonPhone"
                        required
                      />
                      <span v-else class="utaiform-review-value">{{
                        branch.ContactPersonPhone
                      }}</span>
                    </div>
                  </div>
                  <div class="col-12 col-md-6 branch-content">
                    <div class="form-group utaiform-group">
                      <label
                        class="utaiform-label"
                        :for="`contactPersonEmail${index}`"
                        >聯絡人信箱</label
                      >
                      <input
                        v-if="isEdit"
                        type="text"
                        :id="`contactPersonEmail${index}`"
                        class="form-control utaiform-control"
                        v-model="branch.ContactPersonEmail"
                        required
                      />
                      <span v-else class="utaiform-review-value">{{
                        branch.ContactPersonEmail
                      }}</span>
                    </div>
                  </div>
                </div>
                <div class="branch-flex-container">
                  <div class="col-12 branch-content">
                    <div class="form-group utaiform-group">
                      <label
                        class="utaiform-label"
                        :for="`storeAddress${index}`"
                        >門市地址</label
                      >
                      <input
                        v-if="isEdit"
                        type="text"
                        :id="`storeAddress${index}`"
                        class="form-control utaiform-control"
                        v-model="branch.StoreAddress"
                        required
                      />
                      <span v-else class="utaiform-review-value">{{
                        branch.StoreAddress
                      }}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              v-if="index + 1 < form.ApplicationBranches.length"
              class="col-12 mt-2 mb-3 utaiform-line-container"
            >
              <hr class="utaiform-line" />
            </div>
          </div>
        </div>
      </div>
    </modal-part>
  </div>
</template>

<script>
import modalPart from "@/components/parts/modalPart";

export default {
  data() {
    return {
      applyOptions: this.$enum.utaiApplyType.getAll(),
      termLeaseOptions: this.$enum.utaiTermLeaseType.getAll(),
      productOptions: [],
      locationOptions: [],
      statusOptions: [],
      form: {},
    };
  },
  props: [
    "item",
    "isShow",
    "isEdit",
    "productTypes",
    "locationTypes",
    "statusTypes",
  ],
  components: {
    modalPart,
  },
  computed: {
    memos() {
      return [
        {
          value: "授權",
        },
        {
          value: "授權&新增",
        },
        {
          value: "租機",
        },
        {
          value: "B2B",
        },
        {
          value: "250張方案",
        },
        {
          value: "500張方案",
        },
        {
          value: "400張方案",
        },
        {
          value: "200張方案",
        },
      ];
    },
  },
  watch: {
    isShow(value) {
      if (value) {
        this.form = this.item;
        this.productOptions = this.productTypes.filter((product) => {
          return product.value >= 0;
        });
        this.locationOptions = this.locationTypes.filter((location) => {
          return location.value >= 0;
        });
        this.statusOptions = this.statusTypes.filter((status) => {
          return status.value >= 0;
        });
      }
    },
    "form.Application.TermLeaseType": {
      handler(value) {
        if (value == 0) {
          this.form.Application.EndDate = null;
        }
      },
      immediate: true,
    },
  },
  methods: {
    addMemo(value) {
      if (this.form.Application.Memo) {
        this.form.Application.Memo += `，${value}`;
      } else {
        this.form.Application.Memo = value;
      }
    },
    onSaved() {
      const vm = this;
      this.$api.utai
        .put(vm.form.Application.ApplicationId, vm.form)
        .then(() => {
          vm.$emit("onSaved");
        })
        .catch((error) => vm.$func.message.error(error.data.Message));
    },
    onClosed() {
      this.$emit("onClosed");
    },
  },
};
</script>
