<template>
  <div>
    <modules
      :moduleType="moduleType"
      @currentModuleIdChanged="currentModuleIdChanged"
      @getModules="getModules"
      :hasTerminal="true"
      :hasAdd="authority.IsAdd"
      :hasEdit="authority.IsUpdate"
      :hasRemove="authority.IsRemove"
    >
      <template slot="buttons">
        <button
          id="discounts"
          type="button"
          class="btn btn-outline-info btn-icon dropdown-toggle"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
        >
          <i class="fas fa-percent"></i>
        </button>
        <div
          class="dropdown-menu"
          aria-labelledby="discounts"
          style="z-index: 1600"
        >
          <a class="dropdown-item" @click="totalPriceForFullQtyClick(false)">{{
            getPromotionText(0)
          }}</a>
          <a class="dropdown-item" @click="totalPriceForFullQtyClick(true)">{{
            getPromotionText(0) + $t("option.SameFood")
          }}</a>
          <a class="dropdown-item" @click="freeQtyForFullQtyClick(false)">{{
            getPromotionText(1)
          }}</a>
          <a class="dropdown-item" @click="freeQtyForFullQtyClick(true)">{{
            getPromotionText(1) + $t("option.SameFood")
          }}</a>
          <a class="dropdown-item" @click="totalPriceForMatchClick(false)">{{
            getPromotionText(2)
          }}</a>
          <a class="dropdown-item" @click="discountForFullQtyClick(false)">{{
            getPromotionText(3)
          }}</a>
          <a class="dropdown-item" @click="discountForFullQtyClick(true)">{{
            getPromotionText(3) + $t("option.SameFood")
          }}</a>
          <a class="dropdown-item" @click="salPriceForFullQtyClick(false)">{{
            getPromotionText(4)
          }}</a>
          <a class="dropdown-item" @click="salPriceForFullQtyClick(true)">{{
            getPromotionText(4) + $t("option.SameFood")
          }}</a>
          <a class="dropdown-item" @click="salpriceClick(false)">{{
            getPromotionText(5)
          }}</a>
          <a class="dropdown-item" @click="freePriceForFullPriceClick(false)">{{
            getPromotionText(6)
          }}</a>
          <a class="dropdown-item" @click="freePriceForFullPriceClick(true)">{{
            getPromotionText(6) + $t("option.SameFood")
          }}</a>
          <a class="dropdown-item" @click="discountForFullPriceClick(false)">{{
            getPromotionText(7)
          }}</a>
          <a class="dropdown-item" @click="discountForFullPriceClick(true)">{{
            getPromotionText(7) + $t("option.SameFood")
          }}</a>
          <a class="dropdown-item" @click="discountForTheQtyClick(false)">{{
            getPromotionText(8)
          }}</a>
          <a class="dropdown-item" @click="discountForTheQtyClick(true)">{{
            getPromotionText(8) + $t("option.SameFood")
          }}</a>
          <a class="dropdown-item" @click="freePriceForFullQtyClick(false)">{{
            getPromotionText(9)
          }}</a>
          <a class="dropdown-item" @click="freePriceForFullQtyClick(true)">{{
            getPromotionText(9) + $t("option.SameFood")
          }}</a>
          <a
            class="dropdown-item"
            @click="discountForGreaterThanQtyClick(false)"
            >{{ getPromotionText(10) }}</a
          >
          <a
            class="dropdown-item"
            @click="discountForGreaterThanQtyClick(true)"
            >{{ getPromotionText(10) + $t("option.SameFood") }}</a
          >
          <a class="dropdown-item" @click="changePriceTypeClick(0)">{{
            getChangePriceText(0)
          }}</a>
          <a class="dropdown-item" @click="changePriceTypeClick(1)">{{
            getChangePriceText(1)
          }}</a>
        </div>
      </template>
    </modules>

    <div class="row">
      <div class="col-12 col-md-6">
        <page-table
          :parentPage="promotionPagination"
          :hasAdd="false"
          :hasEdit="authority.IsUpdate"
          :hasRemove="authority.IsRemove"
          @editItem="editPromotion"
          :items="promotions"
          :columns="columns"
          @getItems="getPromotions"
          @removeItem="removePromotion"
          idProperty="PromotionId"
          nameProperty="PromotionName"
          :getTrigger="promotionGetTrigger"
          tableName="promotions"
        >
          <template slot="button" slot-scope="props">
            <slot name="dataButtons" :index="props.index" :item="props.item">
              <button
                type="button"
                class="btn btn-outline-primary btn-icon"
                @click="copyPromotion(props.item)"
              >
                <i class="fas fa-copy"></i>
              </button>
            </slot>
          </template>
        </page-table>
      </div>
      <div class="col-12 col-md-6">
        <page-table
          :parentPage="foodPromotionPagination"
          :hasAdd="false"
          :hasEdit="false"
          :hasRemove="false"
          :items="foodPromotions"
          :columns="foodColumns"
          @getItems="getFoodPromotions"
          @removeItem="removeFoodPromotion"
          idProperty="FoodId"
          nameProperty="FoodName"
          :getTrigger="foodPromotionGetTrigger"
          tableName="foodPromotions"
          :searchTypes="searchTypes"
        />
      </div>
    </div>

    <item-modal
      :isShowModal="isShow"
      :modalId="copyEventId"
      title="複製促銷"
      @onSaved="copySaved"
      @onClosed="copyClosed"
    >
      <div slot="item">
        <div class="row">
          <div class="col-md-6 form-group">
            <label class="col-form-label" for="copyItemName"
              >{{ $t("modal.promotionname") }}:
            </label>
            <input
              type="text"
              class="form-control"
              id="copyItemName"
              v-model="copyItem.PromotionName"
              readonly
            />
          </div>
          <div class="col-md-6 form-group">
            <label class="col-form-label" for="copyItemKind"
              >{{ $t("modal.promotiontype") }}:
            </label>
            <input
              type="text"
              class="form-control"
              id="copyItemKind"
              v-model="copyItem.PromotionTypeText"
              readonly
            />
          </div>
          <div class="col-md-12 form-group">
            <label class="col-form-label" for="targetModule"
              >{{ $t("modal.copyto") }}:
            </label>
            <select
              class="form-control"
              id="targetModule"
              v-model="targetModuleId"
            >
              <option
                v-for="targetModule in targetModules"
                :key="targetModule.ModuleId"
                :value="targetModule.ModuleId"
              >
                {{ targetModule.ModuleName }}
              </option>
            </select>
          </div>
        </div>
      </div>
    </item-modal>
  </div>
</template>

<script>
import modules from "@/components/modules/Modules";
import itemModal from "@/components/ItemModal";

import pageTable from "@/components/PageTable";

export default {
  data() {
    return {
      moduleType: this.$enum.moduleType.getByName("Promotion").value,
      currentModuleId: "",
      authority: {},
      promotions: [],
      promotionPagination: {},
      promotionGetTrigger: false,
      columns: [
        {
          name: "PromotionName",
        },
        { name: "PromotionTypeText" },
        { name: "DayModuleName" },
      ],
      modules: [],
      copyItem: {},
      targetModules: [],
      targetModuleId: "",
      isShow: false,
      copyEventId: "copyPromotion",
      foodPromotionPagination: {},
      foodPromotions: [],
      foodColumns: [
        { name: "FoodNumber" },
        { name: "FoodName" },
        { name: "PromotionName" },
      ],
      foodPromotionGetTrigger: false,
      searchTypes: this.$enum.foodSearchType.getAll(),
    };
  },
  components: {
    modules,
    itemModal,
    pageTable,
  },
  watch: {
    currentModuleId() {
      this.getFoodPromotions();
    },
  },
  methods: {
    getFoodPromotions(dTO) {
      if (!this.currentModuleId) {
        return;
      }
      this.$api.promotions.getFoods(this.currentModuleId, dTO).then((data) => {
        this.foodPromotions = data.Datas;
        this.foodPromotionPagination = data.Pagination;
      });
    },
    removeFoodPromotion(item) {
      this.$api.promotions.remove(item.PromotionId).then(() => {
        this.getFoodPromotions();
      });
    },
    changePriceTypeClick(type) {
      this.$router.push({
        name: "ChangePrice",
        params: { id: this.currentModuleId, type: type },
      });
    },
    copySaved() {
      if (!this.targetModuleId) {
        this.$func.message.alert("請選擇要複製到的模組 !");
        return;
      }
      this.$api.promotions
        .copy(this.copyItem.PromotionId, this.targetModuleId)
        .then(() => {
          this.isShow = !this.isShow;
        })
        .catch((error) => this.$func.message.error(error.data.Message));
    },
    copyClosed() {
      this.isShow = !this.isShow;
    },
    getModules(modules) {
      this.modules = modules;
    },
    copyPromotion(item) {
      if (!item) {
        this.$func.message.alert("請選擇一筆促銷 !");
        return;
      }
      this.targetModules = this.modules.filter((item) => {
        return item.ModuleId != this.currentModuleId;
      });
      this.copyItem = item;
      this.targetModuleId = "";
      this.isShow = !this.isShow;
    },
    getPromotionText(type) {
      return this.$t(
        `option.${this.$enum.promotionType.getByValue(type).name}`
      );
    },
    getChangePriceText(type) {
      return this.$enum.changePriceType.getByValue(type).text;
    },
    isSelected() {
      if (this.currentModuleId && this.currentModuleId.length > 0) {
        return true;
      } else {
        this.$func.message.alert("請選擇一筆模組 !");
        return false;
      }
    },
    currentModuleIdChanged(id) {
      this.currentModuleId = id;
      this.getPromotions();
    },
    totalPriceForFullQtyClick(isSame) {
      if (!this.isSelected()) return;
      this.$router.push({
        name: "Promotion",
        params: { id: this.currentModuleId, type: 0, isSame },
      });
    },
    freeQtyForFullQtyClick(isSame) {
      if (!this.isSelected()) return;
      this.$router.push({
        name: "Promotion",
        params: { id: this.currentModuleId, type: 1, isSame },
      });
    },
    totalPriceForMatchClick(isSame) {
      if (!this.isSelected()) return;
      this.$router.push({
        name: "Promotion",
        params: { id: this.currentModuleId, type: 2, isSame },
      });
    },
    discountForFullQtyClick(isSame) {
      if (!this.isSelected()) return;
      this.$router.push({
        name: "Promotion",
        params: { id: this.currentModuleId, type: 3, isSame },
      });
    },
    salPriceForFullQtyClick(isSame) {
      if (!this.isSelected()) return;
      this.$router.push({
        name: "Promotion",
        params: { id: this.currentModuleId, type: 4, isSame },
      });
    },
    salpriceClick(isSame) {
      if (!this.isSelected()) return;
      this.$router.push({
        name: "Promotion",
        params: { id: this.currentModuleId, type: 5, isSame },
      });
    },
    freePriceForFullPriceClick(isSame) {
      if (!this.isSelected()) return;
      this.$router.push({
        name: "Promotion",
        params: { id: this.currentModuleId, type: 6, isSame },
      });
    },
    discountForFullPriceClick(isSame) {
      if (!this.isSelected()) return;
      this.$router.push({
        name: "Promotion",
        params: { id: this.currentModuleId, type: 7, isSame },
      });
    },
    discountForTheQtyClick(isSame) {
      if (!this.isSelected()) return;
      this.$router.push({
        name: "Promotion",
        params: { id: this.currentModuleId, type: 8, isSame },
      });
    },
    freePriceForFullQtyClick(isSame) {
      if (!this.isSelected()) return;
      this.$router.push({
        name: "Promotion",
        params: { id: this.currentModuleId, type: 9, isSame },
      });
    },
    discountForGreaterThanQtyClick(isSame) {
      if (!this.isSelected()) return;
      this.$router.push({
        name: "Promotion",
        params: { id: this.currentModuleId, type: 10, isSame },
      });
    },
    getPromotions(dTO) {
      if (!this.currentModuleId) {
        this.promotions = [];
        return;
      }
      this.$api.promotions.getPage(this.currentModuleId, dTO).then((data) => {
        this.promotions = data.Datas;
        this.promotionPagination = data.Pagination;
      });
    },
    removePromotion(item) {
      if (!item) return;
      this.$api.promotions.remove(item.PromotionId).then(() => {
        this.promotionGetTrigger = !this.promotionGetTrigger;
      });
    },
    editPromotion(item) {
      if (!item) return;
      this.$router.push({
        name: "Promotion",
        params: {
          id: this.currentModuleId,
          type: item.PromotionType,
          isSame: item.IsSame,
        },
      });
    },
  },
  created() {
    this.authority = this.$func.localStorage.getAuthByName(this.$route.name);
  },
};
</script>
