export function getAll() {
  return [
    {
      value: 1,
      name: "OldCustomer",
      text: "舊客戶",
    },
    {
      value: 2,
      name: "FriendRecommend",
      text: "朋友介紹(公司名稱)",
    },
    {
      value: 3,
      name: "GoogleAds",
      text: "Google",
    },
    {
      value: 4,
      name: "YahooAds",
      text: "Yahoo",
    },
    {
      value: 5,
      name: "PassBy",
      text: "路過",
    },
     {
      value: 6,
      name: "Facebook",
      text: "FB",
    },
    {
      value: 0,
      name: "SourceElse",
      text: "其他",
    },
  ];
}

export function getByValue(value) {
  const type = getAll().find(function (item) {
    return item.value == value;
  });
  return type;
}
