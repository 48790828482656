<template>
  <div>
    <condition-part
      :hasDate="true"
      :hasWarehouse="true"
      :hasFoodStatusType="true"
      :hasFoodKind="true"
      :hasFood="true"
      :hasBatchNumber="true"
      :hasExcel="true"
      :hasPdf="true"
      :hasAdd="false"
      :hasEdit="false"
      :hasRemove="false"
      :isShowAll="true"
      :columns="columns"
      :items="items"
      :pagination="pagination"
      :isSortable="true"
      @getItems="getItems"
      @downloadExcel="downloadExcel"
      @downloadPdf="downloadPdf"
      @printPdf="printPdf"
    />
  </div>
</template>

<script>
import conditionPart from "@/components/parts/conditionPart";

export default {
  data() {
    return {
      items: [],
      pagination: {},
    };
  },
  components: {
    conditionPart,
  },
  computed: {
    columns() {
      return [
        { name: "FoodNumber" },
        { name: "FoodName" },
        { name: "Barcode" },
        { name: "BatchNumber" },
        { name: "TradeType" },
        { name: "TradeTime" },
        { name: "TradeNumber" },
        { name: "WarehouseNumber" },
        { name: "WarehouseName" },
        { name: "Qty" },
        { name: "Unit" },
        { name: "TradeDate" },
      ];
    },
  },
  methods: {
    downloadExcel(condition) {
      this.$api.reportExcels.getStockBatchMovement(condition);
    },
    downloadPdf(condition) {
      this.$api.reportPdfs.getStockBatchMovement(condition);
    },
    printPdf(condition) {
      this.$api.reportPdfs.printStockBatchMovement(condition);
    },
    getItems(condition) {
      const vm = this;
      this.$api.reports.getStockBatchMovement(condition).then((data) => {
        vm.items = data.Datas;
        vm.pagination = data.Pagination;
      });
    },
  },
};
</script>
