import { httpGet, httpPost } from '@/request/http'

export function get(url, fileName, params) {
  httpGet(url, params, { responseType: 'blob' }).then((data) => {
    const url = window.URL.createObjectURL(new Blob([data]))
    const link = document.createElement('a')
    link.href = url
    link.setAttribute('download', `${fileName}.xlsx`)
    document.body.appendChild(link)
    link.click()
  })
}
export function post(url, fileName, params) {
  httpPost(url, params, { responseType: 'blob' }).then((data) => {
    const url = window.URL.createObjectURL(new Blob([data]))
    const link = document.createElement('a')
    link.href = url
    link.setAttribute('download', `${fileName}.xlsx`)
    document.body.appendChild(link)
    link.click()
  })
}
