<template>
  <nav v-if="pagination">
    <ul class="pagination justify-content-center">
      <li class="page-item" :class="{ disabled: !pagination.HasPrevious }">
        <a
          class="page-link"
          href="#"
          aria-label="Previous"
          @click.prevent="pageChanged(pagination.CurrentPage - 1)"
        >
          <span aria-hidden="true">&laquo;</span>
          <span class="sr-only">Previous</span>
        </a>
      </li>
      <li
        class="page-item"
        v-for="item in pages"
        :key="item"
        :class="{ active: pagination.CurrentPage == item }"
      >
        <a class="page-link" href="#" @click.prevent="pageChanged(item)">{{
          item
        }}</a>
      </li>
      <li class="page-item" :class="{ disabled: !pagination.HasNext }">
        <a
          class="page-link"
          href="#"
          aria-label="Next"
          @click.prevent="pageChanged(pagination.CurrentPage + 1)"
        >
          <span aria-hidden="true">&raquo;</span>
          <span class="sr-only">Next</span>
        </a>
      </li>
    </ul>
  </nav>
</template>

<script>
export default {
  props: ["pagination"],
  computed: {
    pages() {
      if (this.pagination.PageNumber == 0) return [];
      else {
        let pages = [];
        let firstPage =
          this.pagination.CurrentPage > 4 ? this.pagination.CurrentPage - 4 : 1;
        let lastPage = firstPage + 8;
        if (lastPage > this.pagination.PageNumber) {
          firstPage -= lastPage - this.pagination.PageNumber;
          if (firstPage < 1) firstPage = 1;
          lastPage = this.pagination.PageNumber;
        }
        for (firstPage; firstPage <= lastPage; firstPage++)
          pages.push(firstPage);
        return pages;
      }
    },
  },
  methods: {
    pageChanged(page) {
      if (page == this.pagination.CurrentPage) return;
      this.$emit("pageChanged", page);
    },
  },
};
</script>
