export function getAll() {
  return [
    {
      value: 1,
      name: "CateringIndustry",
      text: "餐飲業",
    },
    {
      value: 2,
      name: "RetailIndustry",
      text: "零售業",
    },
    {
      value: 3,
      name: "PetIndustry",
      text: "寵物業",
    },
    {
      value: 4,
      name: "AirdressingIndustry",
      text: "美容業",
    },
    {
      value: 5,
      name: "ApparelIndustry",
      text: "服飾業",
    },
    {
      value: 0,
      name: "BusinessElse",
      text: "其他",
    },
  ];
}

export function getByValue(value) {
  const type = getAll().find(function (item) {
    return item.value == value;
  });
  return type;
}
