import i18n from "@/langs/i18n";

export function getAll() {
  return [
    {
      value: 0,
      name: "Product",
      text: `${i18n.t("option.foodStockType.Product")}`,
    },
    {
      value: 1,
      name: "Combination",
      text: `${i18n.t("option.foodStockType.Combination")}`,
    },
  ];
}
