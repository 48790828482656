<template>
  <basic-selects
    :trigger="trigger"
    :isMultiple="isMultiple"
    :columns="columns"
    :items="foods"
    :title="`${$t('title.selectfood')}`"
    idProperty="FoodId"
    nameProperty="FoodName"
    allSelectId="foodAllSelectBox"
    @onSaved="onSaved"
    :id="id"
    :modalId="modalId"
    :searchTypes="noSearchType ? undefined : searchTypes"
    :pagination="pagination"
    @getItems="getItems"
    sortBy="FoodNumber"
    :defaultItems="defaultItems"
    viewName="FoodSelects"
  ></basic-selects>
</template>

<script>
import basicSelects from "./BasicSelects";

export default {
  data() {
    return {
      foods: [],
      columns: [
        { name: "FoodNumber" },
        { name: "FoodName" },
        { name: "Barcode" },
      ],
      id: "foodSelectModal",
      searchTypes: this.$enum.foodSearchType.getAll(
        window.localStorage["isERP"] == "true"
      ),
      pagination: {},
    };
  },
  props: {
    trigger: false,
    isMultiple: false,
    modalId: undefined,
    parentItems: undefined,
    defaultItems: undefined,
    noSearchType: false,
    foodStockType: undefined,
    noPromotion: false,
    promotionModuleId: undefined,
    isFoodsFilter: false,
    selectedIds: undefined,
  },
  watch: {
    modalId: {
      handler(value) {
        if (value) this.id = value;
      },
      immediate: true,
    },
    trigger() {
      if (this.parentItems && this.parentItems.length > 0)
        this.foods = this.parentItems;
      else this.getItems();
    },
  },
  components: {
    basicSelects,
  },
  methods: {
    onSaved(item) {
      this.$emit("onSaved", item);
    },
    getItems(dTO) {
      if (!dTO) dTO = {};
      if (
        this.$route.path.includes("stockadjustdata") ||
        this.$route.path.includes("stocktransferdata") ||
        this.$route.path.includes("inventorydata") ||
        this.$route.path.includes("purchasedata") ||
        this.$route.path.includes("purchaseorderdata") ||
        this.$route.path.includes("purchasereturndata")
      ) {
        dTO.IsMatterStock = true;
      }
      if (this.foodStockType) dTO.FoodStockType = this.foodStockType;
      if (this.noPromotion) dTO.NoPromotion = true;
      if (this.promotionModuleId)
        dTO.PromotionModuleId = this.promotionModuleId;
      if (this.modalId == "foodChooseModal")
        dTO.PromotionType = parseInt(this.$route.params.type);

      const vm = this;
      if (this.isFoodsFilter) {
        dTO.ExceptFoodIds = this.selectedIds;
        this.$api.foods.getNamePages(dTO).then((data) => {
          vm.foods = data.Datas;
          vm.pagination = data.Pagination;
        });
        return;
      }
      dTO.showMainFood = false;
      this.$api.foods.getPages(dTO).then((data) => {
        vm.foods = data.Datas;
        vm.pagination = data.Pagination;
      });
    },
  },
};
</script>
