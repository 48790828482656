<template>
  <form class="card card-table" @submit.prevent="onSaved">
    <div class="card-header">
      <button
        type="button"
        class="btn btn-outline-primary btn-icon"
        @click="onSaved"
      >
        <i class="fas fa-check"></i>
      </button>
      <button
        type="button"
        class="btn btn-outline-danger btn-icon"
        @click="onClosed"
      >
        <i class="fas fa-times"></i>
      </button>
    </div>
    <div class="card-body">
      <div class="row">
        <input-part id="foodNumber" :labelText="`${$t('modal.number')}:`">
          <input
            type="text"
            class="form-control"
            id="foodNumber"
            v-model="combination.FoodNumber"
            readonly
          />
        </input-part>
        <input-part id="foodName" :labelText="`${$t('modal.name')}:`">
          <input
            type="text"
            class="form-control"
            id="foodName"
            v-model="combination.FoodName"
            readonly
          />
        </input-part>
        <input-part id="qty" :labelText="`${$t('modal.qty')}:`">
          <input
            type="number"
            class="form-control"
            id="qty"
            v-model="combination.Qty"
            required
          />
        </input-part>
        <input-part id="unit" :labelText="`${$t('modal.unit')}:`">
          <input
            type="text"
            class="form-control"
            id="unit"
            v-model="combination.Unit"
            readonly
          />
        </input-part>
        <input-part id="memo" :labelText="`${$t('modal.memo')}:`" :degree="12">
          <input
            type="text"
            class="form-control"
            id="memo"
            v-model="combination.Memo"
          />
        </input-part>

        <div class="col-12">
          <table-part :columns="columns" :noHide="true">
            <template slot="th-before">
              <th style="width: 80px">{{ $t("columnName.remove") }}</th>
            </template>
            <tbody slot="tbody">
              <tr v-for="(det, index) in combinationDets" :key="det.FoodId">
                <td>
                  <button
                    type="button"
                    class="btn btn-outline-danger btn-icon"
                    @click="removeDet(index)"
                  >
                    <i class="fas fa-trash-alt"></i>
                  </button>
                </td>
                <td>{{ det.FoodNumber }}</td>
                <td>{{ det.FoodName }}</td>
                <td>
                  <input
                    class="form-control text-right"
                    type="number"
                    v-model="det.Qty"
                    required
                  />
                </td>
                <td>{{ det.Unit }}</td>
                <td>
                  <input class="form-control" type="text" v-model="det.Memo" />
                </td>
              </tr>
              <tr>
                <td colspan="9">
                  <input
                    class="form-control"
                    type="text"
                    :placeholder="`${$t(
                      'placeholder.inputfoodnumberorbarcode'
                    )}`"
                    v-model="addFoodNumber"
                    @keyup.enter.exact="selectFoodByKeyword"
                    @dblclick="foodModalTrigger = !foodModalTrigger"
                  />
                </td>
              </tr>
              <tr>
                <td>{{ $t("columnName.total") }}:</td>
                <td></td>
                <td></td>
                <td class="text-right">{{ totalQty }}</td>
                <td></td>
                <td></td>
              </tr>
            </tbody>
          </table-part>
        </div>
      </div>
      <food-selects
        :trigger="foodModalTrigger"
        :isMultiple="true"
        @onSaved="addFoods"
        :parentItems="foodsByKeyword"
        :noSearchType="!hasFoodSearchType"
      />
    </div>
  </form>
</template>

<script>
import inputPart from "@/components/parts/inputPart";
import tablePart from "@/components/parts/tablePart";
import foodSelects from "@/components/selects/FoodSelects";

export default {
  data() {
    return {
      combination: {},
      combinationDets: [],
      addFoodNumber: "",
      foodModalTrigger: false,
      foodsByKeyword: [],
      hasFoodSearchType: true,
    };
  },
  computed: {
    totalQty() {
      if (this.combinationDets.length > 0)
        return this.combinationDets
          .map((item) => {
            return item.Qty;
          })
          .reduce((t, q) => {
            return parseInt(t) + parseInt(q);
          });
      else return 0;
    },
    columns() {
      return [
        { name: "FoodNumber" },
        { name: "FoodName" },
        { name: "Qty" },
        { name: "Unit" },
        { name: "Memo" },
      ];
    },
  },
  components: {
    inputPart,
    tablePart,
    foodSelects,
  },
  methods: {
    showFoodSelect() {
      this.foodsByKeyword = undefined;
      this.hasFoodSearchType = true;
      this.foodModalTrigger = !this.foodModalTrigger;
    },
    addDet(item) {
      if (this.combinationDets.find((e) => e.FoodId == item.FoodId)) {
        return;
      }
      this.addFoodNumber = "";
      this.combinationDets.push({
        FoodId: item.FoodId,
        FoodNumber: item.FoodNumber,
        FoodName: item.FoodName,
        Qty: 1,
        Unit: item.Unit,
      });
    },
    selectFoodByKeyword() {
      if (!this.addFoodNumber || this.addFoodNumber.length == 0) {
        this.$func.message.alert("請輸入商品編號或條碼");
        return;
      }
      const vm = this;
      this.$api.foods
        .getByKeyword({ Keyword: this.addFoodNumber })
        .then((data) => {
          if (data) {
            if (data.length > 1) {
              vm.foodsByKeyword = data;
              vm.hasFoodSearchType = false;
              vm.foodModalTrigger = !vm.foodModalTrigger;
            } else {
              vm.addDet(data[0]);
            }
          }
        })
        .catch(() => this.$func.message.error("查無此商品 !"));
    },
    removeDet(index) {
      this.combinationDets.splice(index, 1);
    },
    onSaved() {
      if (this.combinationDets.length == 0) {
        this.$func.message.alert("請選擇商品 !");
        return;
      }

      const vm = this;
      this.$api.combinations
        .save({
          Combination: vm.combination,
          CombinationDets: vm.combinationDets,
        })
        .then(() =>
          this.$router.push({
            name: "Combinations",
          })
        );
    },
    onClosed() {
      this.$router.go(-1);
    },
    addFoods(items) {
      const vm = this;
      items.forEach(function (item) {
        vm.addDet(item);
      });
    },
  },
  created() {
    var id = this.$route.params["id"];
    this.$api.combinations.get(id).then((data) => {
      if (data.Combination) {
        this.combination = data.Combination;
        this.combinationDets = data.CombinationDets;
      } else {
        this.$api.foods.get(id).then((food) => {
          this.combination = {
            CombinationId: food.FoodId,
            FoodNumber: food.FoodNumber,
            FoodName: food.FoodName,
            Qty: 1,
            Unit: food.Unit,
          };
        });
      }
    });
  },
};
</script>
