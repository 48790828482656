<template>
  <div>
    <pageTable
      :parentPage="pagination"
      @getItems="getCategories"
      @addItem="addCategory"
      @editItem="editCategory"
      @removeItem="removeCategory"
      :items="categories"
      :columns="columns"
      :idProperty="idProperty"
      :nameProperty="nameProperty"
      :getTrigger="getTrigger"
      :hasAdd="authority.IsAdd"
      :hasEdit="authority.IsUpdate"
      :hasRemove="authority.IsRemove"
      @currentIndexChanged="currentIndexChanged"
      :tableName="componentId"
    >
      <template slot="add">
        <button
          type="button"
          class="btn btn-outline-primary btn-icon dropdown-toggle"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
        >
          <span class="pr-2">
            <i class="fa fa-plus"></i>
          </span>
        </button>
        <div class="dropdown-menu">
          <a class="dropdown-item" @click="addCategory">{{
            $t("modal.addcategory")
          }}</a>
          <a
            class="dropdown-item"
            @click="foodKindSelectTrigger = !foodKindSelectTrigger"
            >{{ $t("modal.select") }}</a
          >
        </div>
      </template>
      <template slot="button">
        <button
          type="button"
          class="btn btn-outline-info py-2 px-4"
          style="border-color: transparent"
          @click="showItems"
        >
          <i class="fas fa-hamburger"></i>
        </button>
      </template>
    </pageTable>

    <category-data
      :modalTitle="modalTitle"
      :isShow="isShowModal"
      :entity="category"
      :onlineOrderType="onlineOrderType"
      @save="saveCategory"
      @close="isShowModal = !isShowModal"
    ></category-data>

    <food-kind-select
      :hasSequence="true"
      :trigger="foodKindSelectTrigger"
      :isMultiple="true"
      @onSaved="addMultipleFoodKind"
    >
    </food-kind-select>

    <online-order-item-select
      :hasSequence="true"
      :trigger="itemTrigger"
      :isMultiple="true"
      :defaultItems="onlineOrderItems"
      :onlineOrderType="onlineOrderType"
      @onSaved="saveItems"
    ></online-order-item-select>
  </div>
</template>

<script>
import pageTable from "@/components/PageTable";
import categoryData from "@/components/onlineOrders/OnlineOrderCategoryData";
import foodKindSelect from "@/components/selects/FoodKindSelects";
import onlineOrderItemSelect from "@/components/selects/OnlineOrderItemSelects";

export default {
  data() {
    return {
      categories: [],
      category: {},
      pagination: {},
      isShowModal: false,
      columns: [
        {
          name: "OnlineOrderCategoryName",
        },
      ],
      idProperty: "OnlineOrderCategoryId",
      nameProperty: "OnlineOrderCategoryName",
      getTrigger: false,
      authority: {},
      foodKindSelectTrigger: false,
      itemTrigger: false,
      onlineOrderItems: undefined,
      currentIndex: -1,
      modalTitle: "",
    };
  },
  props: ["componentId", "onlineOrderType"],
  components: {
    pageTable,
    categoryData,
    foodKindSelect,
    onlineOrderItemSelect,
  },
  methods: {
    currentIndexChanged(index) {
      this.currentIndex = index;
    },
    getCategories(dTO) {
      dTO.type = this.onlineOrderType;
      const vm = this;
      this.$api.onlineOrderCategories.getPages(dTO).then((data) => {
        vm.categories = data.Datas;
        vm.pagination = data.Pagination;
      });
    },
    addCategory() {
      this.category = this.$func.defaultModel.getOnlineOrderCategory(
        this.onlineOrderType
      );
      this.isShowModal = !this.isShowModal;
      this.modalTitle = this.$t("modal.addcategory");
    },
    editCategory(category) {
      const vm = this;
      this.$api.onlineOrderCategories
        .get(category.OnlineOrderCategoryId)
        .then((data) => {
          vm.category = data;
          vm.isShowModal = !vm.isShowModal;
          vm.modalTitle = vm.$t("modal.editcategory");
        });
    },
    addMultipleFoodKind(items) {
      this.$func.message.confirm("是否一併加入商品細項?").then((result) => {
        const vm = this;
        this.$api.onlineOrderCategories
          .postBatch({
            OnlineOrderType: this.onlineOrderType,
            FoodKindIds: items.map((x) => x.FoodKindId),
            IsAddDetail: result.isConfirmed,
          })
          .then(() => {
            vm.getTrigger = !vm.getTrigger;
          });
      });
    },
    removeCategory(category) {
      const vm = this;
      this.$api.onlineOrderCategories
        .remove(category.OnlineOrderCategoryId)
        .then(() => {
          vm.getTrigger = !vm.getTrigger;
        });
    },
    saveCategory() {
      this.isShowModal = !this.isShowModal;
      this.getTrigger = !this.getTrigger;
    },
    showItems() {
      const category = this.categories[this.currentIndex];
      if (!category) {
        this.$func.message.alert("請選擇類別 !");
        return;
      }
      this.onlineOrderItems = undefined;
      const vm = this;
      this.$api.onlineOrderCategories
        .getItems(category.OnlineOrderCategoryId)
        .then((data) => {
          vm.onlineOrderItems = data;
          vm.itemTrigger = !vm.itemTrigger;
        });
    },
    saveItems(items) {
      const category = this.categories[this.currentIndex];
      if (!category) {
        this.$func.message.alert("請選擇類別 !");
        return;
      }
      const vm = this;
      this.$api.onlineOrderCategories
        .postItems(
          category.OnlineOrderCategoryId,
          items.map((item) => item.OnlineOrderItemId)
        )
        .then(() => {
          vm.getTrigger = !vm.getTrigger;
        })
        .catch((error) => {
          vm.$func.message.error(error.data.Message);
        });
    },
  },
  created() {
    this.authority = this.$func.localStorage.getAuthByName(this.$route.name);
  },
};
</script>
