import { httpGet, httpPost, httpDelete } from "@/request/http";
import func from "@/funcs";

let path = "/promotions";

export default {
  get(id) {
    return httpGet(`${path}/${id}`);
  },
  gets(id, type, isSame) {
    return httpGet(`modules/${id}/type/${type}/promotions/${isSame}`);
  },
  getPage(id, page) {
    return httpGet(`modules/${id}/promotions`, page);
  },
  save(item) {
    return httpPost(path, item);
  },
  remove(id) {
    return httpDelete(`${path}/${id}`);
  },
  copy(id, moduleId) {
    return httpPost(`${path}/${id}/copy/modules/${moduleId}`);
  },
  getFoods(id, dTO) {
    return httpGet(`modules/${id}${path}/foods`, dTO);
  },
  getExcel(id) {
    func.getExcel.get(`${path}/${id}/excel`, "促銷模組");
  },
  postExcel(id, formData) {
    return func.postExcel(`${path}/${id}/excel`, formData);
  },
};
