<template>
  <form class="card" @submit.prevent="onSaved">
    <div class="card-header">
      <button
        type="button"
        class="btn btn-outline-primary btn-icon"
        @click="onSaved"
      >
        <i class="fas fa-check"></i>
      </button>
      <button
        type="button"
        class="btn btn-outline-danger btn-icon"
        @click="onClosed"
      >
        <i class="fas fa-times"></i>
      </button>
      <button
        type="button"
        class="btn btn-outline-success btn-icon"
        @click="setAllBalance"
      >
        <i class="fas fa-sack-dollar"></i>
      </button>
    </div>
    <div class="card-body">
      <div class="row">
        <input-part id="receiptDate" :labelText="`${$t('modal.date')}:`">
          <input
            type="date"
            class="form-control"
            id="receiptDate"
            v-model="receipt.ReceiptDate"
            required
          />
        </input-part>
        <input-part id="receiptNumber" :labelText="`${$t('modal.number2')}:`">
          <input
            type="text"
            class="form-control"
            id="receiptNumber"
            v-model="receipt.ReceiptNumber"
            readonly
          />
        </input-part>
        <input-part id="customerName" :labelText="`${$t('modal.client')}:`">
          <div class="row px-3">
            <input
              type="text"
              class="form-control col-12 col-md-6"
              id="customerNumber"
              :placeholder="`${$t('placeholder.inputcustomernumberordb')}`"
              v-model="addCustomerNumber"
              @keyup.enter.exact="selectCustomerByKeyword"
              @dblclick="customerModalTrigger = !customerModalTrigger"
            />
            <input
              type="text"
              class="form-control col-12 col-md-6"
              id="customerName"
              readonly
              :value="receipt.CustomerName"
            />
          </div>
        </input-part>
        <div class="form-group col-md-6 col-lg-4">
          <label for="payType">付款方式</label>
          <select
            id="payType"
            class="form-control"
            required
            v-model="receipt.PayType"
          >
            <option
              v-for="payType in payTypes"
              :key="payType.value"
              :value="payType.value"
            >
              {{ payType.text }}
            </option>
          </select>
        </div>
        <input-part id="memo" :labelText="`${$t('modal.memo')}:`" :degree="12">
          <input
            type="text"
            class="form-control"
            id="memo"
            v-model="receipt.Memo"
          />
        </input-part>
      </div>
      <div class="col-12 pl-0">
        <table-part :columns="columns" :noHide="true">
          <template slot="th-before">
            <th style="width: 80px">移除</th>
          </template>
          <tbody slot="tbody">
            <tr v-for="(det, index) in receiptDets" :key="det.ReceiptDetId">
              <td>
                <button
                  type="button"
                  class="btn btn-outline-danger btn-icon"
                  @click="removeDet(index)"
                >
                  <i class="fas fa-trash-alt"></i>
                </button>
              </td>
              <td>{{ det.TradeType }}</td>
              <td>{{ det.SalNumber }}</td>
              <td class="text-right">{{ det.Total }}</td>
              <td class="text-right">{{ det.Payed }}</td>
              <td>
                <input
                  class="form-control text-right"
                  type="number"
                  required
                  v-model.number="det.Balance"
                  @dblclick="setBalance(det)"
                  @keyup.enter.exact="checkBalance(det)"
                  @blur="checkBalance(det)"
                />
              </td>
              <td class="text-right">{{ det.NoPay }}</td>
              <td>
                <select
                  id="payType"
                  class="form-control"
                  required
                  v-model="det.PayType"
                >
                  <option
                    v-for="payType in payTypes"
                    :key="payType.value"
                    :value="payType.value"
                  >
                    {{ payType.text }}
                  </option>
                </select>
              </td>
            </tr>
            <tr>
              <td>總計:</td>
              <td></td>
              <td></td>
              <td class="text-right">{{ total }}</td>
              <td class="text-right">{{ totalPayed }}</td>
              <td class="text-right">{{ receipt.Total }}</td>
              <td class="text-right">{{ totalNoPay }}</td>
              <td></td>
            </tr>
          </tbody>
        </table-part>
      </div>
      <customer-selects
        :trigger="customerModalTrigger"
        :isMultiple="false"
        @onSaved="setCustomer"
      ></customer-selects>
    </div>
  </form>
</template>

<script>
import customerSelects from "@/components/selects/CustomerSelects";
import tablePart from "@/components/parts/tablePart";

import inputPart from "@/components/parts/inputPart";

export default {
  data() {
    return {
      receipt: {
        EmployeeId: window.localStorage["userId"],
        ReceiptDate: this.$func.getDate(),
        CustomerName: "",
        PayType: 0,
      },
      receiptDets: [],
      addCustomerNumber: "",
      customerModalTrigger: false,
      payTypes: this.$enum.payType.getForAccount(),
    };
  },
  watch: {
    receiptDets: {
      handler(val) {
        val.forEach(function (item) {
          item.NoPay = item.Total - item.Payed - item.Balance;
        });
        this.calculateTotal();
      },
      deep: true,
    },
  },
  computed: {
    total() {
      let total = 0;
      this.receiptDets.forEach((item) => {
        total += item.Total;
      });
      return total;
    },
    totalPayed() {
      let total = 0;
      this.receiptDets.forEach((item) => {
        total += item.Payed;
      });
      return total;
    },
    totalNoPay() {
      let total = 0;
      this.receiptDets.forEach((item) => {
        total += item.NoPay;
      });
      return total;
    },
    columns() {
      return [
        { name: "TradeType" },
        { name: "SalNumber" },
        { name: "Total" },
        { name: "Payed" },
        { name: "Balance" },
        { name: "NoPay" },
        { name: "PayType" },
      ];
    },
  },
  components: {
    customerSelects,
    tablePart,
    inputPart,
  },
  methods: {
    setAllBalance() {
      this.receiptDets.forEach((item) => {
        item.Balance += item.NoPay;
      });
    },
    removeDet(index) {
      this.receiptDets.splice(index, 1);
    },
    onSaved() {
      const checkBalance = this.receiptDets.every((item) => {
        return item.Balance == 0;
      });
      if (checkBalance) {
        this.$func.message.alert("請至少設定一筆沖帳金額 !");
        return;
      }
      if (!this.receipt.CustomerId || this.receipt.CustomerId.length == 0) {
        this.$func.message.alert("請選擇會員 !");
        return;
      }
      if (this.receiptDets.length == 0) {
        this.$func.message.alert("此筆單無應收單據 !");
        return;
      }
      this.receipt.EmployeeId = window.localStorage["userId"];
      const vm = this;
      this.$api.receipts
        .post({
          Receipt: vm.receipt,
          ReceiptDets: vm.receiptDets,
        })
        .then(() => this.$router.push({ name: "Receipts" }));
    },
    onClosed() {
      this.$router.go(-1);
    },
    selectCustomerByKeyword() {
      if (!this.addCustomerNumber || this.addCustomerNumber.length == 0) {
        this.$func.message.alert("請輸入會員編號");
        return;
      }
      const vm = this;
      this.$api.customers
        .getByNumber(this.addCustomerNumber)
        .then((data) => {
          if (data) {
            vm.setCustomer(data);
          }
        })
        .catch(() => {
          this.$func.message
            .confirm("查無此會員 !", "是否新增此會員 ?")
            .then((result) => {
              if (result.isConfirmed) {
                this.customer.CustomerNumber = this.addCustomerNumber;
                this.customer.CountryCode = "886";
                this.modalDisplayTrigger = !this.modalDisplayTrigger;
                this.modalTitle = this.$t("modal.addcustomer");
              } else {
                this.setCustomer({
                  CustomerNumber: null,
                  CustomerId: null,
                  CustomerName: null,
                  ContactPerson: null,
                  Phone1: null,
                  SalPriceType: null,
                });
              }
            });
        });
    },
    setCustomer(item) {
      this.addCustomerNumber = item.CustomerNumber;
      this.receipt.CustomerId = item.CustomerId;
      this.receipt.CustomerName = item.CustomerName;
      const vm = this;
      this.$api.sals.getNoPays(item.CustomerId).then((data) => {
        if (data) {
          vm.receiptDets = [];
          data.forEach((item) => {
            vm.receiptDets.push({
              TradeType: vm.$enum.tradeType.get(item.TradeType).text,
              SalId: item.SalId,
              SalNumber: item.SalNumber,
              Total: item.Total,
              Payed: item.Total - item.Receivable,
              Balance: 0,
              NoPay: item.Receivable,
              PayType: 0,
            });
          });
        }
      });
    },
    calculateTotal() {
      let sum = 0;
      this.receiptDets.forEach((item) => (sum += item.Balance));
      this.receipt.Total = sum;
    },
    checkBalance(item) {
      if (item.Total - item.Payed < item.Balance) {
        this.$func.message.alert("沖帳金額不可大於未付金額 !");
        item.Balance = item.Total - item.Payed;
      }
    },
    setBalance(item) {
      item.Balance += item.NoPay;
    },
  },
  mounted() {
    const id = this.$route.params["id"];
    const vm = this;
    if (id) {
      this.$api.receipts
        .get(id)
        .then((data) => {
          vm.receipt = data.Receipt;
          vm.receiptDets = data.ReceiptDets;
        })
        .catch(() => {
          vm.$func.message.alert("查無此筆單據 !");
          vm.$router.go(-1);
        });
    }
  },
};
</script>
