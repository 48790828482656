<template>
  <button class="btn btn-outline-primary mr-1" @click="onClicked">
    {{ name }}
    <i class="fas fa-times"></i>
  </button>
</template>

<script>
export default {
  props: ["index", "name"],
  methods: {
    onClicked() {
      this.$emit("onClicked", this.index);
    },
  },
};
</script>
