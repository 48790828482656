<template>
  <div>
    <condition-part
      :hasDate="true"
      :hasEmployee="true"
      :hasExcel="true"
      :hasPdf="true"
      :hasAdd="false"
      :hasEdit="false"
      :hasRemove="false"
      :columns="columns"
      :items="items"
      :pagination="pagination"
      :isSortable="true"
      @getItems="getItems"
      @downloadExcel="downloadExcel"
      @downloadPdf="downloadPdf"
      @printPdf="printPdf"
      :reportTypes="reportTypes"
    >
    </condition-part>
  </div>
</template>

<script>
import conditionPart from "@/components/parts/conditionPart";

export default {
  data() {
    return {
      items: [],
      pagination: {},
      reportTypes: ["simple", "detail"],
      reportType: "simple",
      trade: {},
      isShow: false,
    };
  },
  computed: {
    columns() {
      if (this.reportType == "detail") {
        return [
          { name: "CombinationTradeDate", text: "拆解日期" },
          { name: "CombinationTradeNumber", text: "拆解單號" },
          { name: "CombinationWarehouseNumber" },
          { name: "CombinationWarehouseName" },
          { name: "EmployeeNumber" },
          { name: "EmployeeName" },
          { name: "CombinationFoodNumber" },
          { name: "CombinationFoodName" },
          { name: "CombinationQty" },
          { name: "CombinationTotal" },
          { name: "CombinationUnit" },
          { name: "FoodNumber" },
          { name: "FoodName" },
          { name: "WarehouseNumber" },
          { name: "WarehouseName" },
          { name: "Qty" },
          { name: "Cost" },
          { name: "Total" },
        ];
      } else if (this.reportType == "simple") {
        return [
          { name: "CombinationTradeDate", text: "拆解日期" },
          { name: "CombinationTradeNumber", text: "拆解單號" },
          { name: "WarehouseNumber" },
          { name: "WarehouseName" },
          { name: "Memo" },
          { name: "EmployeeNumber" },
          { name: "EmployeeName" },
          { name: "FoodNumber" },
          { name: "FoodName" },
          { name: "Qty" },
          { name: "Total" },
          { name: "Unit" },
        ];
      } else {
        return undefined;
      }
    },
  },
  components: {
    conditionPart,
  },
  methods: {
    downloadExcel(condition) {
      this.$api.reportExcels.getSeperationTrade(condition);
    },
    downloadPdf(condition) {
      this.$api.reportPdfs.getSeperationTrade(condition);
    },
    printPdf(condition) {
      this.$api.reportPdfs.printSeperationTrade(condition);
    },
    getItems(condition) {
      this.reportType = condition.ReportType;
      this.trade = {};

      const vm = this;
      this.$api.reports.getSeperationTrade(condition).then((data) => {
        vm.items = data.Datas;
        vm.pagination = data.Pagination;
      });
    },
    dataOnClosed() {
      this.isShow = !this.isShow;
    },
  },
};
</script>
