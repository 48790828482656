import i18n from "@/langs/i18n";

export function getAll() {
  return [
    {
      value: -1,
      name: "All",
      text: `${i18n.t("option.reserveDaysType.All")}`,
    },
    {
      value: 0,
      name: "Today",
      text: `${i18n.t("option.reserveDaysType.Today")}`,
    },
    {
      value: 1,
      name: "Customize",
      text: `${i18n.t("option.reserveDaysType.Customize")}`,
    },
  ];
}
