import i18n from "@/langs/i18n";

export function getAll() {
  return [
    {
      value: 0,
      name: "CustomerNumber",
      text: `${i18n.t("option.customerSearchType.CustomerNumber")}`,
    },
    {
      value: 1,
      name: "CustomerName",
      text: `${i18n.t("option.customerSearchType.CustomerName")}`,
    },
    {
      value: 2,
      name: "Cellhpone",
      text: `${i18n.t("option.customerSearchType.Cellhpone")}`,
    },
  ];
}

export function getByName(name) {
  const type = getAll().find(function (item) {
    return item.name.toLowerCase() == name.toLowerCase();
  });
  return type;
}

export function getByValue(value) {
  const type = getAll().find(function (item) {
    return item.value == value;
  });
  return type;
}
