<template>
  <div>
    <search-and-table-part
      :pagination="pagination"
      @getItems="getForms"
      @editItem="editForm"
      :items="forms"
      :columns="columns"
      :idProperty="idProperty"
      :hasAdd="false"
      :hasEdit="!isAssistant"
      :hasRemove="false"
      :hasCheckDetail="true"
      :getTrigger="getTrigger"
      @trdbClicked="checkFormData"
      :isSortable="true"
    >
      <template slot="searchArea">
        <div class="row">
          <div class="col-lg-6">
            <div class="form-group row">
              <label
                for="searchApplicationId"
                class="col-3 col-md-2 col-lg-3 col-form-label"
                >ID:</label
              >
              <div class="col">
                <input
                  type="text"
                  class="form-control"
                  id="searchApplicationId"
                  v-model="searchApplicationId"
                />
              </div>
            </div>
          </div>
          <div class="col-lg-6">
            <div class="form-group row">
              <label
                for="searchProductType"
                class="col-3 col-md-2 col-lg-3 col-form-label"
                >產品:</label
              >
              <div class="col">
                <select
                  class="form-control"
                  id="searchProductType"
                  v-model="searchProductType"
                >
                  <option
                    v-for="productType in productTypes"
                    :key="productType.value"
                    :value="productType.value"
                  >
                    {{ productType.text }}
                  </option>
                </select>
              </div>
            </div>
          </div>
          <div class="col-lg-6">
            <div class="form-group row">
              <label
                for="searchCompanyName"
                class="col-3 col-md-2 col-lg-3 col-form-label"
                >公司名稱:</label
              >
              <div class="col">
                <input
                  type="text"
                  class="form-control"
                  id="searchCompanyName"
                  v-model="searchCompanyName"
                />
              </div>
            </div>
          </div>
          <div class="col-lg-6">
            <div class="form-group row">
              <label
                for="searchLocationType"
                class="col-3 col-md-2 col-lg-3 col-form-label"
                >公司地址:</label
              >
              <div class="col">
                <select
                  class="form-control"
                  id="searchLocationType"
                  v-model="searchLocationType"
                >
                  <option
                    v-for="locationType in locationTypes"
                    :key="locationType.value"
                    :value="locationType.value"
                  >
                    {{ locationType.text }}
                  </option>
                </select>
              </div>
            </div>
          </div>
          <div class="col-lg-6">
            <div class="form-group row">
              <label
                for="searchStatusType"
                class="col-3 col-md-2 col-lg-3 col-form-label"
                >申請進度:</label
              >
              <div class="col">
                <select
                  class="form-control"
                  id="searchStatusType"
                  v-model="searchStatusType"
                >
                  <option
                    v-for="statusType in statusTypes"
                    :key="statusType.value"
                    :value="statusType.value"
                  >
                    {{ statusType.text }}
                  </option>
                </select>
              </div>
            </div>
          </div>
          <div class="col-lg-6">
            <div class="form-group row">
              <label
                for="searchUniformNumber"
                class="col-3 col-md-2 col-lg-3 col-form-label"
                >統一編號:</label
              >
              <div class="col">
                <input
                  type="text"
                  class="form-control"
                  id="searchUniformNumber"
                  v-model="searchUniformNumber"
                />
              </div>
            </div>
          </div>
        </div>
      </template>
      <template slot="dataButtonAreaFront" slot-scope="props">
        <button
          class="btn btn-outline-primary btn-icon"
          type="button"
          @click="checkFormData(forms[props.index])"
        >
          <i class="fas fas fa-eye"></i>
        </button>
      </template>
      <template slot="dataButtonArea" slot-scope="props">
        <button
          class="btn btn-outline-secondary btn-icon"
          type="button"
          @click="printPdf(forms[props.index])"
        >
          <i class="fas fa-print"></i>
        </button>
      </template>

      <th v-if="!isAssistant" slot="th-before" style="width: 180px">
        變更狀態
      </th>
      <td v-if="!isAssistant" slot="td-before" slot-scope="props">
        <button
          class="btn btn-outline-primary font-weight-bold mr-1"
          v-if="props.item.StatusType === 0"
          @click="nextStep(props.item, 1)"
        >
          未建檔
        </button>
        <button
          class="btn btn-outline-success font-weight-bold mr-1"
          v-if="props.item.StatusType === 1"
          @click="nextStep(props.item, 2)"
        >
          已建檔
        </button>
        <button
          class="btn btn-outline-danger font-weight-bold"
          v-if="props.item.StatusType !== 2 && props.item.StatusType !== 3"
          @click="removeForm(props.item)"
        >
          刪除
        </button>
      </td>
    </search-and-table-part>

    <utaiFormData
      :item="form"
      :isShow="isShow"
      :isEdit="isEdit"
      :productTypes="productTypes"
      :locationTypes="locationTypes"
      :statusTypes="statusTypes"
      @onSaved="onSaved"
      @onClosed="onClosed"
    ></utaiFormData>
  </div>
</template>

<script>
import searchAndTablePart from "@/components/parts/searchAndTablePart";
import utaiFormData from "@/components/datas/UtaiFormData";
export default {
  data() {
    return {
      productTypes: this.$enum.utaiProductType.getAll(),
      locationTypes: this.$enum.utaiLocationType.getAll(),
      statusTypes: this.$enum.utaiStatusType.getAll(),
      searchProductType: -1,
      searchLocationType: -1,
      searchStatusType: -1,
      searchApplicationId: undefined,
      searchUniformNumber: undefined,
      searchCompanyName: undefined,
      forms: [],
      form: {},
      columns: [
        {
          name: "StatusTypeText",
        },
        {
          name: "ApplicationId",
        },
        {
          name: "CreateTime",
        },
        {
          name: "IsHeadText",
        },
        {
          name: "SalesPerson",
        },
        {
          name: "UtaiLocationTypeText",
        },
        {
          name: "Partner",
        },
        {
          name: "ProductTypeText",
        },
        {
          name: "ApplyTypeText",
        },
        {
          name: "TermLeaseTypeText",
        },
        {
          name: "CompanyName",
        },
        {
          name: "UniformNumber",
        },
        {
          name: "TaxNumber",
        },
        {
          name: "TaxationBureau",
        },
        {
          name: "InvoiceSheets",
        },
        {
          name: "InvoiceIncreaseSheets",
        },
        {
          name: "StartDate",
        },
        {
          name: "EndDate",
        },
        {
          name: "AgencyStartDate",
        },
        {
          name: "Memo",
        },
      ],
      pagination: {},
      idProperty: "ApplicationId",
      getTrigger: false,
      isShow: false,
      isEdit: false,
    };
  },
  components: {
    searchAndTablePart,
    utaiFormData,
  },
  computed: {
    isAssistant() {
      return this.$enum.roleType.isAssistant();
    },
  },
  methods: {
    checkFormData(form) {
      const vm = this;
      if (form) {
        this.$api.utai
          .get(form.ApplicationId)
          .then((data) => {
            vm.form = data;
            vm.isShow = !vm.isShow;
          })
          .catch(() => vm.$func.message.alert("查無此資料 !"));
      } else {
        this.$func.message.alert("請選擇一筆表單 !");
      }
    },
    editForm(form) {
      this.isEdit = true;
      this.checkFormData(form);
    },
    getForms(dTO) {
      dTO.ProductType = this.searchProductType;
      dTO.LocationType = this.searchLocationType;
      dTO.StatusType = this.searchStatusType;
      dTO.CompanyName = this.searchCompanyName;

      if (this.searchApplicationId) {
        dTO.ApplicationId = parseInt(this.searchApplicationId);
      } else {
        dTO.ApplicationId = null;
      }
      if (this.searchUniformNumber) {
        dTO.UniformNumber = this.searchUniformNumber;
      } else {
        dTO.UniformNumber = null;
      }
      const vm = this;
      this.$api.utai.getPages(dTO).then((data) => {
        vm.forms = data.Datas;
        vm.forms.forEach((form) => {
          if (form.IsHead) {
            form.IsHeadText = "有";
          } else {
            form.IsHeadText = "無";
          }
          form.StatusTypeText = vm.$enum.utaiStatusType.getByValue(
            form.StatusType
          ).text;
          form.ProductTypeText = vm.$enum.utaiProductType.getByValue(
            form.ProductType
          ).text;
          form.UtaiLocationTypeText = vm.$enum.utaiLocationType.getByValue(
            form.LocationType
          ).text;

          form.ApplyTypeText = vm.$enum.utaiApplyType.getByValue(
            form.ApplyType
          ).text;
          if (form.TermLeaseType >= 0) {
            form.TermLeaseTypeText = vm.$enum.utaiTermLeaseType.getByValue(
              form.TermLeaseType
            ).text;
          }
        });
        vm.pagination = data.Pagination;
      });
    },
    nextStep(form, type) {
      const vm = this;
      if (type === 1) {
        vm.$func.message
          .confirm("確定將此表單狀態轉為未建檔嗎 ?")
          .then((result) => {
            if (result.isConfirmed) {
              vm.$api.utai.patchStatus(form.ApplicationId, type).then(() => {
                vm.getTrigger = !vm.getTrigger;
              });
            }
          });
      } else if (type === 2) {
        vm.$func.message
          .confirm("確定將此表單狀態轉為已建檔嗎 ?")
          .then((result) => {
            if (result.isConfirmed) {
              vm.$api.utai.patchStatus(form.ApplicationId, type).then(() => {
                vm.getTrigger = !vm.getTrigger;
              });
            }
          });
      }
    },
    removeForm(form) {
      const vm = this;
      this.$func.message.confirm("確定刪除此表單嗎 ?").then((result) => {
        if (result.isConfirmed) {
          vm.$api.utai.remove(form.ApplicationId).then(() => {
            vm.getTrigger = !vm.getTrigger;
          });
        }
      });
    },
    onSaved() {
      this.isShow = !this.isShow;
      this.isEdit = false;
      this.getTrigger = !this.getTrigger;
    },
    onClosed() {
      this.isShow = !this.isShow;
      this.isEdit = false;
    },
    printPdf(item) {
      this.$api.utai.print(item.ApplicationId);
    },
  },
};
</script>
