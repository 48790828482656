export function getAll() {
  return [
    {
      value: 0,
      name: "Normal",
      text: "一般",
    },
    {
      value: 1,
      name: "WithOrder",
      text: "含訂單",
    },
    {
      value: 2,
      name: "WithOrderAndPurchase",
      text: "含訂單及採購",
    },
  ];
}
