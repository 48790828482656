import { httpGet, httpPost, httpDelete } from "@/request/http";
import func from "@/funcs";

let path = "/foods";

export default {
  get(id) {
    return httpGet(`${path}/${id}`);
  },
  getNames() {
    return httpGet(`${path}/names`);
  },
  getName(id) {
    return httpGet(`${path}/names/${id}`);
  },
  getNamePages(dTO) {
    return httpPost(`${path}/pages/names`, dTO);
  },
  getNamesByKind(id) {
    return httpGet(`/foodkinds/${id}/${path}/names`);
  },
  getPages(dTO) {
    return httpPost(`${path}/pages`, dTO);
  },
  save(item) {
    return httpPost(path, item);
  },
  remove(id) {
    return httpDelete(`${path}/${id}`);
  },
  getSequencesByKind(moduleId, foodKindId, dTO) {
    return httpGet(
      `/modules/${moduleId}/foodkinds/${foodKindId}/foods/sequences`,
      dTO
    );
  },
  before(moduleId, foodId, pageSize) {
    return httpPost(`/modules/foods/sequences/before`, {
      moduleId,
      foodId,
      pageSize,
    });
  },
  after(moduleId, foodId, pageSize) {
    return httpPost(`/modules/foods/sequences/after`, {
      moduleId,
      foodId,
      pageSize,
    });
  },
  position(dTO) {
    return httpPost(`/modules/foods/sequences/position`, dTO);
  },
  getByNumber(number) {
    return httpGet(`${path}/number/${number}`);
  },
  getExcel() {
    func.getExcel.get(`${path}/excel`, "商品");
  },
  postExcel(formData) {
    return func.postExcel(`${path}/excel`, formData, 600000);
  },
  getImage(id) {
    return httpGet(`${path}/${id}/image`, null, { responseType: "blob" });
  },
  postImage(id, formData) {
    return httpPost(`${path}/${id}/image`, formData);
  },
  deleteImage(id) {
    return httpDelete(`${path}/${id}/image`);
  },
  getNextNumber(number) {
    return httpGet(`${path}/number/${number}/nextnumber`);
  },
  getByKeyword(item) {
    return httpPost(`${path}/keyword`, item);
  },
  getCasio(warehouseId) {
    func.getTXT(`/warehouses/${warehouseId}/casio/foods`, "product");
  },
  getTYSSO(warehouseId) {
    func.getTXT(`/warehouses/${warehouseId}/tysso/foods`, "product");
  },
  postDigiwinExcel(formData) {
    return func.postExcel(`${path}/digiwin/excel`, formData, 600000);
  },
  getIsBarcodeExist(foodId, barcode) {
    return httpGet(`${path}/${foodId}/barcode/${barcode}/exist`);
  },
  getStock(warehouseId, foodId) {
    return httpGet(`${path}/stock`, { warehouseId, foodId });
  },
  getStockByNumber(warehouseNumber, foodId) {
    return httpGet(`${path}/stock`, { warehouseNumber, foodId });
  },
  getSpecification(specificationtypeId) {
    return httpGet(`/specificationtypes/${specificationtypeId}`);
  },
  saveSpecification(items) {
    return httpPost(`/specificationtypes`, items);
  },
  removeSpecification(specificationtypeId) {
    return httpDelete(`/specificationtypes/${specificationtypeId}`);
  },
  getFoodSpecifications(foodId) {
    return httpGet(`${path}/${foodId}/specifications`);
  },
  saveFoodSpecifications(foodId, items) {
    return httpPost(`${path}/${foodId}/specifications`, items);
  },
  getFoodBrands(foodId) {
    return httpGet(`/foodBrands/${foodId}`);
  },
  saveFoodBrands(items) {
    return httpPost(`/foodBrands`, items);
  },
  postShowmore() {
    return httpPost(`/showmore/products/sync`);
  },
  postDigiwin() {
    return httpPost(`/digiwin/foods/sync`);
  },
};
