import { httpGet } from "@/request/http";

let path = "/invoiceprizes";

export default {
  getPages(dTO) {
    return httpGet(`${path}/pages`, dTO);
  },
  get(id) {
    return httpGet(`${path}/${id}`);
  },
};
