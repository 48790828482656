<template>
  <div>
    <search
      :title="`${$t('modal.foodkind')}`"
      :items="foodKinds"
      idProperty="FoodKindId"
      nameProperty="FoodKindName"
      @triggerClick="triggerClick"
      @remove="remove"
      :searchItems="searchItems"
    />

    <foodKind-select
      :isMultiple="true"
      :trigger="trigger"
      :defaultItems="defaultItems"
      @onSaved="add"
    />
  </div>
</template>

<script>
import search from "./Search";
import foodKindSelect from "@/components/selects/FoodKindSelects";

export default {
  data() {
    return {
      trigger: false,
      foodKinds: [],
      defaultItems: undefined,
    };
  },
  props: {
    searchItems: {
      type: Object,
      default: () => ({}),
    },
  },
  watch: {
    searchItems(value) {
      if (JSON.parse(sessionStorage.getItem("isSamePage")) && value.FoodKind) {
        this.foodKinds = value.FoodKind;
      }
    },
  },
  components: {
    search,
    foodKindSelect,
  },
  methods: {
    triggerClick() {
      this.defaultItems = [...this.foodKinds];
      this.trigger = !this.trigger;
    },
    add(items) {
      this.foodKinds = items;
      this.set();
    },
    remove(index) {
      if (!this.foodKinds[index]) return;
      this.foodKinds.splice(index, 1);
      this.set();
    },
    set() {
      this.$emit("set", this.foodKinds);
    },
  },
};
</script>
