
export function getAll(){
  return [
    {
      value: 0,
      name: "G",
      text: "克"
    },
    {
      value: 1,
      name: "TG",
      text: "臺斤"
    },
    {
      value: 2,
      name: "KG",
      text: "公斤"
    },
    {
      value: 3,
      name: "HG",
      text: "百克"
    },
  ]
}

export function getByName(name){
  const type = getAll().find(function(item){
    return item.name.toLowerCase() == name.toLowerCase();
  });
  return type;
}
