<template>
  <div>
    <pageTable
      :parentPage="pagination"
      @getItems="getItems"
      @addItem="add"
      @editItem="edit"
      @removeItem="remove"
      @trdbClicked="edit"
      :items="accounts"
      :columns="columns"
      :idProperty="idProperty"
      :nameProperty="nameProperty"
      :getTrigger="getTrigger"
      :hasAdd="authority.IsAdd"
      :hasEdit="authority.IsUpdate"
      :hasRemove="authority.IsRemove"
      :isSortable="true"
    >
    </pageTable>

    <account-data
      :modalTitle="modalTitle"
      :isShow="isShowModal"
      @close="isShowModal = !isShowModal"
      @save="save"
      :entity="account"
    >
    </account-data>
  </div>
</template>

<script>
import pageTable from "@/components/PageTable";
import accountData from "@/components/datas/AccountData";

export default {
  data() {
    return {
      accounts: [],
      account: {},
      pagination: {},
      isShowModal: false,
      columns: [
        {
          name: "AccountNumber",
        },
        {
          name: "AccountName",
        },
        {
          name: "Total",
        },
        {
          name: "Memo",
        },
      ],
      idProperty: "AccountId",
      nameProperty: "AccountName",
      getTrigger: false,
      authority: {},
      modalTitle: "",
    };
  },
  components: {
    pageTable,
    accountData,
  },
  methods: {
    getItems(dTO) {
      this.$api.accounts.getPages(dTO).then((data) => {
        this.accounts = data.Datas;
        this.pagination = data.Pagination;
      });
    },
    add() {
      this.account = {};
      this.isShowModal = !this.isShowModal;
      this.modalTitle = this.$t("modal.addaccount");
    },
    edit(account) {
      if (!this.authority.IsUpdate) {
        return;
      }
      this.$api.accounts.get(account.AccountId).then((data) => {
        this.account = data;
        this.isShowModal = !this.isShowModal;
        this.modalTitle = this.$t("modal.editaccount");
      });
    },
    save() {
      this.getTrigger = !this.getTrigger;
      this.isShowModal = !this.isShowModal;
    },
    remove(account) {
      this.$api.accounts.remove(account.AccountId).then(() => {
        this.getTrigger = !this.getTrigger;
      });
    },
  },
  created() {
    this.authority = this.$func.localStorage.getAuthByName(this.$route.name);
  },
};
</script>
