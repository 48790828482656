import i18n from "@/langs/i18n";

export function getAll() {
  return [
    {
      value: 0,
      name: "Minute",
      text: `${i18n.t("option.waitingType.Minute")}`,
    },
    {
      value: 1,
      name: "Hour",
      text: `${i18n.t("option.waitingType.Hour")}`,
    },
    {
      value: 2,
      name: "Day",
      text: `${i18n.t("option.waitingType.Day")}`,
    },
  ];
}
