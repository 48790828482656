<template>
  <div>
    <search-and-table-part
      :pagination="pagination"
      @getItems="getUsers"
      @addItem="addUser"
      @editItem="editUser"
      @removeItem="removeUser"
      @trdbClicked="editUser"
      :items="users"
      :columns="columns"
      :idProperty="idProperty"
      :nameProperty="nameProperty"
      :hasAdd="isAdmin"
      :hasEdit="isEngineer"
      :hasRemove="isAdmin"
      :getTrigger="getTrigger"
      :isSortable="true"
    >
      <template slot="searchArea">
        <div class="row">
          <div class="col-lg-6">
            <div class="form-group row">
              <label
                for="searchRoleType"
                class="col-3 col-md-2 col-lg-3 col-form-label"
                >角色:</label
              >
              <div class="col">
                <select
                  class="form-control"
                  id="searchRoleType"
                  v-model="searchRoleType"
                >
                  <option
                    v-for="roleType in roleTypes"
                    :key="roleType.value"
                    :value="roleType.value"
                  >
                    {{ roleType.text }}
                  </option>
                </select>
              </div>
            </div>
          </div>
          <div class="col-lg-6">
            <div class="form-group row">
              <label
                for="searchBusinessId"
                class="col-3 col-md-2 col-lg-3 col-form-label"
                >營業人編號:</label
              >
              <div class="col">
                <input
                  type="text"
                  class="form-control"
                  id="searchBusinessId"
                  v-model="searchBusinessId"
                />
              </div>
            </div>
          </div>
        </div>
      </template>
      <template slot="dataButtonArea" slot-scope="props">
        <button
          v-if="isAdmin"
          id="changePassword"
          type="button"
          class="btn btn-outline-warning py-2 px-4"
          style="border-color: transparent"
          @click="changePassword(props.index)"
        >
          <i class="fas fa-key"></i>
        </button>
      </template>
    </search-and-table-part>

    <user-data
      :hasAdmin="true"
      :trigger="modalTrigger"
      :id="userId"
      @onSaved="userSave"
    ></user-data>

    <item-modal
      modalId="changePasswordModal"
      :isShowModal="isShowModal"
      @onClosed="isShowModal = !isShowModal"
      @onSaved="saveChangePassword"
      title="變更使用者密碼"
      modalClass="modal-md"
    >
      <div slot="item">
        <div class="row">
          <div class="col-12">
            <div class="form-group">
              <label for="changeNewPassword">輸入新密碼</label>
              <input
                type="text"
                class="form-control"
                id="changeNewPassword"
                v-model="newPassword"
                required
              />
            </div>
          </div>
        </div>
      </div>
    </item-modal>
  </div>
</template>

<script>
import searchAndTablePart from "@/components/parts/searchAndTablePart";
import itemModal from "@/components/ItemModal";
import userData from "@/components/datas/UserData";

export default {
  data() {
    return {
      searchRoleType: null,
      searchBusinessId: null,
      roleTypes: this.$enum.roleType.getAll(),
      users: [],
      userId: "",
      pagination: {},
      modalTrigger: false,
      modalTitle: "",
      isShowModal: false,
      columns: [
        {
          name: "UserName",
        },
        {
          name: "Email",
        },
        {
          name: "RoleType",
        },
        {
          name: "BuilderName",
        },
        {
          name: "BusinessId",
        },
        {
          name: "BusinessName",
        },
      ],
      idProperty: "UserId",
      nameProperty: "UserName",
      getTrigger: false,
      newPassword: "",
    };
  },
  components: {
    searchAndTablePart,
    itemModal,
    userData,
  },
  computed: {
    isEngineer() {
      return this.$enum.roleType.isEngineer();
    },
    isAdmin() {
      return this.$enum.roleType.isAdmin();
    },
  },
  methods: {
    changePassword(index) {
      this.userId = this.users[index].UserId;
      this.isShowModal = !this.isShowModal;
    },
    saveChangePassword() {
      const password = this.$func.crypto.encrypt(this.newPassword);
      this.$api.users
        .adminChangePassword({ UserId: this.userId, Password: password })
        .then(() => {
          this.$func.message.success("密碼變更成功 !");
          this.isShowModal = !this.isShowModal;
        })
        .catch((error) => {
          this.$func.message.error(error.data.Message);
        });
    },
    getUsers(dTO) {
      dTO.RoleType = this.searchRoleType;
      dTO.BusinessId = this.searchBusinessId;
      const vm = this;
      this.$api.users.getPages(dTO).then((data) => {
        vm.users = data.Datas;
        vm.pagination = data.Pagination;
      });
    },
    addUser() {
      this.userId = undefined;
      this.modalTrigger = !this.modalTrigger;
    },
    editUser(item) {
      this.userId = item.UserId;
      this.modalTrigger = !this.modalTrigger;
    },
    userSave(id) {
      if (window.localStorage["userId"] == id) {
        location.reload();
      } else {
        this.getTrigger = !this.getTrigger;
      }
    },
    removeUser(user) {
      const vm = this;
      this.$api.users.remove(user.UserId).then(() => {
        vm.getTrigger = !vm.getTrigger;
      });
    },
  },
};
</script>
