import { httpGet, httpPost, httpDelete } from "@/request/http";
import func from "@/funcs";

let path = "/dirmodules";

export default {
  get(id) {
    return httpGet(`${path}/${id}`);
  },
  getlist(type) {
    return httpGet(`${path}/list/${type}`);
  },
  save(item) {
    return httpPost(path, item);
  },
  remove(id) {
    return httpDelete(`${path}/${id}`);
  },
  getDays(id) {
    return httpGet(`${path}/${id}/day`);
  },
  postDays(id, dTO) {
    return httpPost(`${path}/${id}/day`, dTO);
  },
  getDevices(moduleId) {
    return httpGet(`${path}/${moduleId}/devices`);
  },
  postDevices(devices) {
    return httpPost(`${path}/devices/addbatch`, devices);
  },
  deleteDevices(devices) {
    return httpPost(`${path}/devices/deletebatch`, devices);
  }
};
