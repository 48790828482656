export function getAll() {
  return [
    {
      value: 0,
      name: "None",
      text: "無",
    },
    {
      value: 1,
      name: "LingYuehCloud",
      text: "凌越",
    },
    {
      value: 2,
      name: "Digiwin",
      text: "鼎新",
    },
    {
      value: 3,
      name: "DigiwinAPI",
      text: "鼎新API",
    },
  ];
}
