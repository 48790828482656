<template>
  <div>
    <condition-part
      :noSearch="reportType == 'summary'"
      :hasTradeStatusType="true"
      :hasDate="true"
      :hasStore="true"
      :hasSaler="true"
      :hasTerminal="true"
      :hasExcel="reportType != 'summary'"
      :hasPdf="true"
      :hasAdd="false"
      :hasEdit="false"
      :hasRemove="false"
      :columns="columns"
      :items="items"
      :pagination="pagination"
      :isSortable="true"
      @getItems="getItems"
      @downloadExcel="downloadExcel"
      @downloadPdf="downloadPdf"
      @printPdf="printPdf"
      :reportTypes="reportTypes"
    ></condition-part>
  </div>
</template>

<script>
import conditionPart from "@/components/parts/conditionPart";

export default {
  data() {
    return {
      items: [],
      pagination: {},
      reportTypes: ["simple", "detail", "statistic", "daystatistic"],
      reportType: "simple",
      trade: {},
      isShow: false,
    };
  },
  computed: {
    columns() {
      if (this.reportType == "detail") {
        let s = [
          { name: "SalerNumber" },
          { name: "SalerName" },
          { name: "TerminalNumber" },
          { name: "TerminalName" },
          { name: "SalNumber" },
          { name: "TradeType" },
          { name: "BusinessDay" },
          { name: "SalTime" },
          { name: "EmployeeNumber" },
          { name: "EmployeeName" },
          { name: "CustomerNumber" },
          { name: "CustomerName" },
          { name: "SalTotal" },
          { name: "TotalDiscountPrice" },
          { name: "TotalFreePrice" },
          { name: "FoodNumber" },
          { name: "FoodName" },
          { name: "Qty" },
          { name: "SalPrice" },
          { name: "Total" },
          { name: "Unit" },
        ];
        return s;
      } else if (this.reportType == "simple") {
        let s = [
          { name: "SalerNumber" },
          { name: "SalerName" },
          { name: "TerminalNumber" },
          { name: "TerminalName" },
          { name: "SalNumber" },
          { name: "CustomerName" },
          { name: "BusinessDay" },
          { name: "SalTime" },
          { name: "TradeType" },
          { name: "Discount" },
          { name: "Total" },
          { name: "TotalDiscountPrice" },
          { name: "TotalFreePrice" },
          { name: "Cash" },
          { name: "CreditCardPrice" },
          { name: "PayType1" },
          { name: "PayType2" },
        ];
        return s;
      } else if (this.reportType == "statistic") {
        let s = [
          { name: "SalerNumber" },
          { name: "SalerName" },
          { name: "Counts" },
          { name: "Total" },
          { name: "TotalFreePrice" },
          { name: "TotalDiscountPrice" },
          { name: "Cash" },
          { name: "CreditCardPrice" },
          { name: "PayType1" },
          { name: "PayType2" },
          { name: "Receivable" },
        ];
        return s;
      } else {
        return [
          { name: "SalerNumber" },
          { name: "SalerName" },
          { name: "BusinessDay" },
          { name: "Total" },
          { name: "TotalDiscountPrice" },
          { name: "TotalFreePrice" },
          { name: "Cash" },
          { name: "CreditCardPrice" },
          { name: "PayType1" },
          { name: "PayType2" },
          { name: "Receivable" },
        ];
      }
    },
  },
  components: {
    conditionPart,
  },
  methods: {
    downloadExcel(condition) {
      this.$api.reportExcels.getSaler(condition);
    },
    downloadPdf(condition) {
      this.$api.reportPdfs.getSaler(condition);
    },
    printPdf(condition) {
      this.$api.reportPdfs.printSaler(condition);
    },
    getItems(condition) {
      this.reportType = condition.ReportType;
      this.trade = {};
      const vm = this;
      this.$api.reports.getSaler(condition).then((data) => {
        vm.items = data.Datas;
        vm.pagination = data.Pagination;
      });
    },
  },
};
</script>
