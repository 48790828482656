import i18n from "@/langs/i18n";

export function getAll() {
  return [
    {
      value: 0,
      name: "Total",
      text: `${i18n.t("option.changePriceType.Total")}${i18n.t(
        "option.changePriceType.Function"
      )}`,
    },
    {
      value: 1,
      name: "Qty",
      text: `${i18n.t("option.changePriceType.Qty")}${i18n.t(
        "option.changePriceType.Function"
      )}`,
    },
  ];
}
export function getByName(name) {
  const type = getAll().find(function (item) {
    return item.name.toLowerCase() == name.toLowerCase();
  });
  return type;
}
export function getByValue(value) {
  const type = getAll().find(function (item) {
    return item.value == value;
  });
  return type;
}
