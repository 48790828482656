import { httpGet, httpPost, httpDelete } from "@/request/http";

let path = "/modules";

export default {
  get(id) {
    return httpGet(`${path}/${id}`);
  },
  getByType(type) {
    return httpGet(`${path}/type/${type}`);
  },
  getPagesByType(dTO) {
    return httpGet(`${path}/pages`, dTO);
  },
  save(item) {
    return httpPost(path, item);
  },
  remove(id) {
    return httpDelete(`${path}/${id}`);
  },
  getEmployees(moduleId) {
    return httpGet(`${path}/${moduleId}/employees`);
  },
  postEmployees(employees) {
    return httpPost(`${path}/employees/addbatch`, employees);
  },
  deleteEmployee(moduleId, employeeId) {
    return httpDelete(`${path}/${moduleId}/employees/${employeeId}`);
  },
  deleteEmployees(employees) {
    return httpPost(`${path}/employees/deletebatch`, employees);
  },
  getFoodKinds(moduleId) {
    return httpGet(`${path}/${moduleId}/foodkinds`);
  },
  postFoodKinds(foodkinds) {
    return httpPost(`${path}/foodkinds/addbatch`, foodkinds);
  },
  deleteFoodKinds(foodkinds) {
    return httpPost(`${path}/foodkinds/deletebatch`, foodkinds);
  },
  getFoods(moduleId, foodKindId, dTO) {
    return httpGet(`${path}/${moduleId}/foodkinds/${foodKindId}/foods`, dTO);
  },
  getFoodsBySelect(moduleId, dTO) {
    return httpGet(`${path}/${moduleId}/foods`, dTO);
  },
  postFoods(foods) {
    return httpPost(`${path}/foods/addbatch`, foods);
  },
  deleteFoods(foods) {
    return httpPost(`${path}/foods/deletebatch`, foods);
  },
  getTerminals(moduleId) {
    return httpGet(`${path}/${moduleId}/terminals`);
  },
  postTerminals(terminals) {
    return httpPost(`${path}/terminals/addbatch`, terminals);
  },
  deleteTerminals(terminals) {
    return httpPost(`${path}/terminals/deletebatch`, terminals);
  },
  getAuthoritys(id) {
    return httpGet(`${path}/${id}/authoritys`);
  },
  postAuthoritys(id, items) {
    return httpPost(`${path}/${id}/authoritys`, items);
  },
  getDays(id) {
    return httpGet(`${path}/${id}/days`);
  },
  postDays(id, items) {
    return httpPost(`${path}/${id}/days`, items);
  },
  getDayModules() {
    return httpGet(`${path}/days`);
  },
  getStores(moduleId) {
    return httpGet(`${path}/${moduleId}/stores`);
  },
  postStores(stores) {
    return httpPost(`${path}/stores/addbatch`, stores);
  },
  deleteStores(stores) {
    return httpPost(`${path}/stores/deletebatch`, stores);
  },
  getChangePrices(id, type) {
    return httpGet(`${path}/${id}/type/${type}/changeprices`);
  },
};
