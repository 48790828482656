export function getAll() {
  return [
    {
      value: -1,
      name: "All",
      text: "全部",
    },
    {
      value: 0,
      name: "UShowPOS",
      text: "Ushow (POS系統)",
    },
    {
      value: 1,
      name: "UshowMini",
      text: "Ushow Mini (手持型發票機)",
    },
    {
      value: 2,
      name: "UM88UM99",
      text: "UM88 / UM99 (桌上型發票機)",
    },
    {
      value: 3,
      name: "UShowPad",
      text: "Ushow Pad (iPad平板)",
    },
    {
      value: 4,
      name: "UShowWeb",
      text: "Ushow Web (網頁發票系統)",
    },
    {
      value: 5,
      name: "UshowLite",
      text: "Ushow Lite (桌上型觸控11.6)",
    },
    {
      value: 6,
      name: "ROEINV",
      text: "RO_EINV (研一發財大師電子發票系統)",
    },
    {
      value: 7,
      name: "WBox",
      text: "WBox (POS系統)",
    },
  ];
}

export function getByValue(value) {
  const type = getAll().find(function (item) {
    return item.value == value;
  });
  return type;
}
