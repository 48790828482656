<template>
  <div>
    <condition-part
      :hasDate="true"
      :hasReportSourceType="true"
      :hasEatType="true"
      :hasStore="true"
      :hasEmployee="true"
      :hasTerminal="true"
      :hasExcel="true"
      :hasPdf="true"
      :hasAdd="false"
      :hasEdit="false"
      :hasRemove="false"
      :columns="columns"
      :items="items"
      :pagination="pagination"
      :isSortable="true"
      @getItems="getItems"
      @downloadExcel="downloadExcel"
      @printPdf="printPdf"
      @downloadPdf="downloadPdf"
    ></condition-part>
  </div>
</template>

<script>
import conditionPart from "@/components/parts/conditionPart";

export default {
  data() {
    return {
      items: [],
      pagination: {},
      columns: [
        {
          name: "TasteName",
        },
        {
          name: "Qty",
        },
        {
          name: "Total",
        },
      ],
    };
  },
  components: {
    conditionPart,
  },
  methods: {
    downloadExcel(condition) {
      this.$api.reportExcels.getTasteStatistic(condition);
    },
    downloadPdf(condition) {
      this.$api.reportPdfs.getTasteStatistic(condition);
    },
    printPdf(condition) {
      this.$api.reportPdfs.printTasteStatistic(condition);
    },
    getItems(condition) {
      const vm = this;
      this.$api.reports.getTasteStatistic(condition).then((data) => {
        vm.items = data.Datas;
        vm.pagination = data.Pagination;
      });
    },
  },
};
</script>
