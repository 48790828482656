import i18n from "@/langs/i18n";

export function getAll() {
  return [
    {
      value: 0,
      name: "B2C",
      text: `${i18n.t("option.invoiceTargetType.B2C")}`,
    },
    {
      value: 1,
      name: "B2B",
      text: `${i18n.t("option.invoiceTargetType.B2B")}`,
    },
  ];
}
