import i18n from "@/langs/i18n";

export function getAll() {
  return [
    {
      value: 0,
      name: "None",
      text: `${i18n.t("option.directoryType.None")}`,
    },
    {
      value: 1,
      name: "Collection",
      text: `${i18n.t("option.directoryType.Collection")}`,
    },
    {
      value: 2,
      name: "Payment",
      text: `${i18n.t("option.directoryType.Payment")}`,
    },
    {
      value: 3,
      name: "Sale",
      text: `${i18n.t("option.directoryType.Sale")}`,
    },
    {
      value: 4,
      name: "Food",
      text: `${i18n.t("option.directoryType.Food")}`,
    },
    {
      value: 5,
      name: "DeleteFood",
      text: `${i18n.t("option.directoryType.DeleteFood")}`,
    },
    {
      value: 7,
      name: "VoidSale",
      text: `${i18n.t("option.directoryType.VoidSale")}`,
    },
    {
      value: 8,
      name: "VoidDeposit",
      text: `${i18n.t("option.directoryType.VoidDeposit")}`,
    },
    {
      value: 9,
      name: "RejectOnlineOrder",
      text: `${i18n.t("option.directoryType.RejectOnlineOrder")}`,
    },
    {
      value: 10,
      name: "StockAdjust",
      text: `${i18n.t("option.directoryType.StockAdjust")}`,
    },
  ];
}

export function getWithIndustryRetail() {
  var types = getAll();
  types.splice(5, 1);
  return types;
}

export function getByName(name) {
  const type = getAll().find(function (item) {
    return item.name.toLowerCase() == name.toLowerCase();
  });
  return type;
}
