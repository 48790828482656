import i18n from "@/langs/i18n";

export function getAll() {
  return [
    {
      value: 0,
      name: "None",
      text: `${i18n.t("option.overPayType.None")}`,
    },
    {
      value: 1,
      name: "OverCharge",
      text: `${i18n.t("option.overPayType.OverCharge")}`,
    },
    {
      value: 2,
      name: "CashChange",
      text: `${i18n.t("option.overPayType.CashChange")}`,
    },
    {
      value: 3,
      name: "Change",
      text: `${i18n.t("option.overPayType.Change")}`,
    },
  ];
}
