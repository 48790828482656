<template>
  <div>
    <pageTable
      :parentPage="pagination"
      @getItems="getFoodMajorKinds"
      @addItem="addFoodMajorKind"
      @editItem="editFoodMajorKind"
      @removeItem="removeFoodMajorKind"
      @trdbClicked="editFoodMajorKind"
      :items="foodMajorKinds"
      :columns="columns"
      :idProperty="idProperty"
      :nameProperty="nameProperty"
      :getTrigger="getTrigger"
      :hasAdd="authority.IsAdd"
      :hasEdit="authority.IsUpdate"
      :hasRemove="authority.IsRemove"
      :isSortable="true"
    >
      <template slot="button">
        <import-part
          @onImport="onImport"
          :title="`${$t('modal.importfoodmajorkind')}`"
        />
        <export-part @onExport="onExport" />
      </template>
    </pageTable>

    <itemModal
      :isShowModal="isShowModal"
      @onClosed="isShowModal = !isShowModal"
      @onSaved="saveFoodMajorKind"
      :title="modalTitle"
    >
      <div slot="item">
        <div class="row">
          <div class="col-md-6">
            <div class="form-group">
              <label for="foodMajorKindNumber">{{ $t("modal.number") }}</label>
              <input
                type="text"
                class="form-control"
                id="foodMajorKindNumber"
                v-model="foodMajorKind.FoodMajorKindNumber"
                required
              />
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label for="foodMajorKindName">{{ $t("modal.name") }}</label>
              <input
                type="text"
                class="form-control"
                id="foodMajorKindName"
                v-model="foodMajorKind.FoodMajorKindName"
                required
              />
            </div>
          </div>
        </div>
      </div>
    </itemModal>
  </div>
</template>

<script>
import pageTable from "@/components/PageTable";
import itemModal from "@/components/ItemModal";
import importPart from "@/components/ImportPart";
import exportPart from "@/components/ExportPart";

import $ from "jquery";

export default {
  data() {
    return {
      foodMajorKinds: [],
      foodMajorKind: {},
      pagination: {},
      isShowModal: false,
      modalTitle: "",
      columns: [
        {
          name: "FoodMajorKindNumber",
        },
        {
          name: "FoodMajorKindName",
        },
      ],
      idProperty: "FoodMajorKindId",
      nameProperty: "FoodMajorKindName",
      getTrigger: false,
      authority: {},
    };
  },
  components: {
    pageTable,
    itemModal,
    importPart,
    exportPart,
  },
  computed: {
    hasDigiwin() {
      return window.localStorage["erpType"] == 3;
    },
    hasShowmore() {
      return window.localStorage["hasShowmore"] == "true";
    },
  },
  methods: {
    getFoodMajorKinds(dTO) {
      const vm = this;
      this.$api.foodMajorKinds.getPages(dTO).then((data) => {
        vm.foodMajorKinds = data.Datas;
        vm.pagination = data.Pagination;
      });
    },
    addFoodMajorKind() {
      this.foodMajorKind = {};
      this.isShowModal = !this.isShowModal;
      this.modalTitle = this.$t("modal.addfoodmajorkind");
    },
    editFoodMajorKind(foodMajorKind) {
      if (!this.authority.IsUpdate) {
        return;
      }
      const vm = this;
      this.$api.foodMajorKinds
        .get(foodMajorKind.FoodMajorKindId)
        .then((data) => {
          vm.foodMajorKind = data;
          vm.isShowModal = !vm.isShowModal;
          vm.modalTitle = this.$t("modal.editfoodmajorkind");
        });
    },
    removeFoodMajorKind(foodMajorKind) {
      const vm = this;
      this.$api.foodMajorKinds
        .remove(foodMajorKind.FoodMajorKindId)
        .then(() => {
          vm.getTrigger = !vm.getTrigger;
        });
    },
    saveFoodMajorKind() {
      const vm = this;
      this.$api.foodMajorKinds
        .save(vm.foodMajorKind)
        .then(() => {
          vm.isShowModal = !vm.isShowModal;
          vm.getTrigger = !vm.getTrigger;
        })
        .catch((error) => vm.$func.message.error(error.data.Message));
    },
    onExport() {
      this.$api.foodMajorKinds.getExcel();
    },
    onImport(file) {
      let formData = new FormData();
      formData.append("file", file);
      const vm = this;
      this.$api.foodMajorKinds.postExcel(formData).then(() => {
        vm.getTrigger = !vm.getTrigger;
      });
    },
  },
  created() {
    this.authority = this.$func.localStorage.getAuthByName(this.$route.name);
  },
};
</script>
