export function uniformNumber(value) {
  const uniformRegExp = /^[0-9]{8}$/;
  const uniArr = [];

  for (let i = 0; i < 8; i++) {
    uniArr.push(parseInt(value.substr(i, 1)));
  }
  const newArr =
    uniArr[0] +
    Math.floor((uniArr[1] * 2) / 10) +
    ((uniArr[1] * 2) % 10) +
    uniArr[2] +
    Math.floor((uniArr[3] * 2) / 10) +
    ((uniArr[3] * 2) % 10) +
    uniArr[4] +
    Math.floor((uniArr[5] * 2) / 10) +
    ((uniArr[5] * 2) % 10) +
    Math.floor((uniArr[6] * 4) / 10) +
    ((uniArr[6] * 4) % 10) +
    uniArr[7];

  const uniformNumberRule =
    Number(newArr % 5) === 0 ||
    (parseInt(uniArr[6]) === 7 && Number((newArr + 1) % 5) === 0);

  return uniformRegExp.test(value) && uniformNumberRule;
}
