<template>
  <td
    style="word-break: break-all"
    :key="column.ColumnName"
    :class="column.class"
  >
    {{ item[column.ColumnName] | checkFilter(column.filter) }}
  </td>
</template>

<script>
import currencyFilter from "@/filters/currency";
import discountFilter from "@/filters/discount";

export default {
  props: ["column", "item"],
  filters: {
    checkFilter(value, name) {
      if (name === null) return value;
      if (name) {
        if (name == "currency") {
          return currencyFilter(value);
        } else if (name == "discount") {
          return discountFilter(value);
        }
      }
      return value;
    },
  },
};
</script>
