import { httpGet, httpPost, httpDelete } from "@/request/http";

let path = "/terminals";

export default {
  get(id) {
    return httpGet(`${path}/${id}`);
  },
  getName(id) {
    return httpGet(`${path}/${id}/name`);
  },
  getNames() {
    return httpGet(`${path}/names`);
  },
  getPages(dTO) {
    return httpGet(`${path}/pages`, dTO);
  },
  save(item) {
    return httpPost(path, item);
  },
  getElecInvoice(id) {
    return httpGet(`${path}/${id}/elecinvoice`);
  },
  saveElecInvoice(item) {
    return httpPost(`${path}/elecinvoice`, item);
  },
  getImage(id) {
    return httpGet(`${path}/${id}/elecinvoice/image`, null, {
      responseType: "blob",
    });
  },
  postImage(id, file) {
    return httpPost(`${path}/${id}/elecinvoice/image`, file);
  },
  deleteImage(id) {
    return httpDelete(`${path}/${id}/elecinvoice/image`);
  },
  getStampImage(id) {
    return httpGet(`${path}/${id}/invoice-stamp/image`, null, {
      responseType: "blob",
    });
  },
  postStampImage(id, file) {
    return httpPost(`${path}/${id}/invoice-stamp/image`, file);
  },
  deleteStampImage(id) {
    return httpDelete(`${path}/${id}/invoice-stamp/image`);
  },
};
