import { httpGet, httpPost, httpDelete } from '@/request/http';

let path = '/receipts';

export default{
    getPages(params){
      return httpGet(`${path}/pages`, params);
    },
    get(id){
      return httpGet(`${path}/${id}`);
    },
    post(item){
      return httpPost(path , item);
    },
    delete(id){
      return httpDelete(`${path}/${id}`);
    },
}
