<template>
  <form class="card" @submit.prevent="onSaved">
    <div class="card-header">
      <button
        type="button"
        class="btn btn-outline-primary btn-icon"
        @click="onSaved"
      >
        <i class="fas fa-check"></i>
      </button>
      <button
        type="button"
        class="btn btn-outline-danger btn-icon"
        @click="onClosed"
      >
        <i class="fas fa-times"></i>
      </button>
    </div>
    <div class="card-body">
      <div class="row">
        <input-part id="paymentDate" :labelText="`${$t('modal.date')}:`">
          <input
            type="date"
            class="form-control"
            id="paymentDate"
            v-model="payment.PaymentDate"
            required
          />
        </input-part>
        <input-part id="paymentNumber" :labelText="`${$t('modal.number2')}:`">
          <input
            type="text"
            class="form-control"
            id="paymentNumber"
            v-model="payment.PaymentNumber"
            readonly
        /></input-part>
        <input-part id="supplier" :labelText="`${$t('modal.supplier')}:`">
          <div class="row px-3">
            <input
              type="text"
              class="form-control col-12 col-md-6"
              id="supplierNumber"
              :placeholder="`${$t('placeholder.inputsuppliernumberordb')}`"
              v-model="addSupplierNumber"
              @keyup.enter.exact="selectSupplierByKeyword"
              @dblclick="supplierModalTrigger = !supplierModalTrigger"
            />
            <input
              type="text"
              class="form-control col-12 col-md-6"
              id="supplierName"
              readonly
              :value="payment.SupplierName"
            />
          </div>
        </input-part>
        <input-part id="memo" :labelText="`${$t('modal.memo')}:`" :degree="12">
          <input
            type="text"
            class="form-control"
            id="memo"
            v-model="payment.Memo"
          />
        </input-part>
      </div>
      <div class="col-12 pl-0">
        <table-part :columns="columns" :noHide="true">
          <template slot="th-before">
            <th style="width: 80px">{{ $t("columnName.remove") }}</th>
          </template>
          <tbody slot="tbody">
            <tr v-for="(det, index) in paymentDets" :key="det.PaymentDetId">
              <td>
                <button
                  type="button"
                  class="btn btn-outline-danger btn-icon"
                  @click="removeDet(index)"
                >
                  <i class="fas fa-trash-alt"></i>
                </button>
              </td>
              <td>{{ det.TradeTypeText }}</td>
              <td>{{ det.PurchaseNumber }}</td>
              <td class="text-right">{{ det.Total }}</td>
              <td class="text-right">{{ det.Payed }}</td>
              <td>
                <input
                  class="form-control text-right"
                  type="number"
                  required
                  v-model.number="det.Balance"
                  @dblclick="setBalance(det)"
                />
              </td>
              <td class="text-right">{{ det.NoPay }}</td>
              <td>
                <select
                  id="payType"
                  class="form-control"
                  required
                  v-model="det.PayType"
                >
                  <option
                    v-for="payType in payTypes"
                    :key="payType.value"
                    :value="payType.value"
                  >
                    {{ payType.text }}
                  </option>
                </select>
              </td>
              <td>{{ det.Memo }}</td>
            </tr>
            <tr>
              <td>{{ $t("columnName.total") }}:</td>
              <td></td>
              <td></td>
              <td class="text-right">{{ total }}</td>
              <td class="text-right">{{ totalPayed }}</td>
              <td class="text-right">{{ payment.Total }}</td>
              <td class="text-right">{{ totalNoPay }}</td>
              <td></td>
              <td></td>
            </tr>
          </tbody>
        </table-part>
      </div>
      <supplier-selects
        :trigger="supplierModalTrigger"
        :isMultiple="false"
        @onSaved="setSupplier"
      ></supplier-selects>
    </div>
  </form>
</template>

<script>
import supplierSelects from "@/components/selects/SupplierSelects";
import tablePart from "@/components/parts/tablePart";

import inputPart from "@/components/parts/inputPart";

export default {
  data() {
    return {
      payment: {
        EmployeeId: window.localStorage["userId"],
        PaymentDate: this.$func.getDate(),
        SupplierName: "",
        PayType: 0,
      },
      paymentDets: [],
      addSupplierNumber: "",
      supplierModalTrigger: false,
      payTypes: this.$enum.payType.getForAccount(),
    };
  },
  watch: {
    paymentDets: {
      handler(val) {
        val.forEach(function (item) {
          item.NoPay = item.Total - item.Payed - item.Balance;
        });
        this.calculateTotal();
      },
      deep: true,
    },
  },
  computed: {
    total() {
      let total = 0;
      this.paymentDets.forEach((item) => {
        total += item.Total;
      });
      return total;
    },
    totalPayed() {
      let total = 0;
      this.paymentDets.forEach((item) => {
        total += item.Payed;
      });
      return total;
    },
    totalNoPay() {
      let total = 0;
      this.paymentDets.forEach((item) => {
        total += item.NoPay;
      });
      return total;
    },
    columns() {
      return [
        { name: "TradeTypeText" },
        { name: "PurchaseNumber" },
        { name: "Total" },
        { name: "Payed" },
        { name: "Balance" },
        { name: "NoPay" },
        { name: "PayType" },
        { name: "Memo" },
      ];
    },
  },
  components: {
    supplierSelects,
    tablePart,
    inputPart,
  },
  methods: {
    removeDet(index) {
      this.paymentDets.splice(index, 1);
    },
    onSaved() {
      if (!this.payment.SupplierId || this.payment.SupplierId.length == 0) {
        this.$func.message.alert("請選擇廠商 !");
        return;
      }
      if (this.paymentDets.length == 0) {
        this.$func.message.alert("此筆單無應付單據 !");
        return;
      }
      this.payment.EmployeeId = window.localStorage["userId"];
      const vm = this;
      this.$api.payments
        .post({
          Payment: vm.payment,
          PaymentDets: vm.paymentDets,
        })
        .then(() => this.$router.push({ name: "Payments" }));
    },
    onClosed() {
      this.$router.go(-1);
    },
    selectSupplierByKeyword() {
      if (!this.addSupplierNumber || this.addSupplierNumber.length == 0) {
        this.$func.message.alert("請輸入廠商編號");
        return;
      }
      const vm = this;
      this.$api.suppliers
        .getByNumber(this.addSupplierNumber)
        .then((data) => {
          if (data) {
            vm.setSupplier(data);
          }
        })
        .catch(() => {
          this.$func.message
            .confirm("查無此廠商 !", "是否新增此廠商 ?")
            .then((result) => {
              if (result.isConfirmed) {
                this.supplierNumber = this.addSupplierNumber;
                this.modalTrigger = !this.modalTrigger;
                this.modalTitle = this.$t("modal.addsupplier");
              } else {
                this.setSupplier({
                  SupplierNumber: null,
                  SupplierId: null,
                  SupplierName: null,
                  ContactPerson: null,
                  Telephone: null,
                });
              }
            });
        });
    },
    setSupplier(item) {
      this.addSupplierNumber = item.SupplierNumber;
      this.payment.SupplierId = item.SupplierId;
      this.payment.SupplierName = item.SupplierName;
      const vm = this;
      this.$api.purchases.getNoPays(item.SupplierId).then((data) => {
        if (data) {
          vm.paymentDets = [];
          data.forEach((item) => {
            vm.paymentDets.push({
              TradeType: item.TradeType,
              TradeTypeText: vm.$enum.tradeType.get(item.TradeType).text,
              PurchaseId: item.PurchaseId,
              PurchaseNumber: item.PurchaseNumber,
              Total: item.Total,
              Payed: item.Payed,
              Balance: 0,
              NoPay: item.Total - item.Payed,
              PayType: 0,
              Memo: item.Memo,
            });
          });
        }
      });
    },
    calculateTotal() {
      let sum = 0;
      this.paymentDets.forEach((item) => (sum += item.Balance));
      this.payment.Total = sum;
    },
    setBalance(item) {
      item.Balance += item.NoPay;
    },
  },
  mounted() {
    const id = this.$route.params["id"];
    const vm = this;
    if (id) {
      this.$api.payments
        .get(id)
        .then((data) => {
          vm.payment = data.Payment;
          vm.paymentDets = data.PaymentDets;
        })
        .catch(() => {
          vm.$func.message.alert("查無此筆單據 !");
          vm.$router.go(-1);
        });
    }
  },
};
</script>
