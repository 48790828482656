import i18n from "@/langs/i18n";

export function getAll(value) {
  var items = [
    {
      value: 0,
      name: "FoodKindName",
      text: `${i18n.t("option.foodSearchType.FoodKindName")}`,
    },
    {
      value: 1,
      name: "FoodKindNumber",
      text: `${i18n.t("option.foodSearchType.FoodKindNumber")}`,
    },
    {
      value: 2,
      name: "FoodName",
      text: `${i18n.t("option.foodSearchType.FoodName")}`,
    },
    {
      value: 3,
      name: "FoodNumber",
      text: `${i18n.t("option.foodSearchType.FoodNumber")}`,
    },
    {
      value: 4,
      name: "Barcode",
      text: `${i18n.t("option.foodSearchType.Barcode")}`,
    },
  ];
  if (value) {
    items.push({
      value: 5,
      name: "SupplierNumber",
      text: `${i18n.t("option.foodSearchType.SupplierNumber")}`,
    });
    items.push({
      value: 6,
      name: "SupplierName",
      text: `${i18n.t("option.foodSearchType.SupplierName")}`,
    });
  }
  return items;
}

export function getByName(name) {
  const type = getAll().find(function (item) {
    return item.name.toLowerCase() == name.toLowerCase();
  });
  return type;
}

export function getByValue(value) {
  const type = getAll().find(function (item) {
    return item.value == value;
  });
  return type;
}
