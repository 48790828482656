<template>
  <div>
    <pageTable
      :parentPage="pagination"
      @getItems="getRules"
      @addItem="addRule"
      @editItem="editRule"
      @removeItem="removeRule"
      :items="memberUpgradeRules"
      :columns="columns"
      :idProperty="idProperty"
      :nameProperty="nameProperty"
      :getTrigger="getTrigger"
      :hasAdd="true"
      :hasEdit="true"
      :hasRemove="true"
      :isSortable="true"
    >
    </pageTable>

    <item-modal
      :isShowModal="isShow"
      :title="modalTitle"
      @onSaved="save"
      @onClosed="close"
    >
      <div slot="item">
        <div class="row">
          <div class="col-md-12 form-group">
            <label class="col-form-label" for="memberUpgradeRuleId">{{
              $t("modal.degree")
            }}</label>
            <select
              class="form-control"
              id="memberUpgradeRuleId"
              v-model="memberUpgradeRule.MemberUpgradeRuleId"
              :disabled="!isAdd"
            >
              <option
                v-for="levelType in memberUpgradeLevelTypes"
                :key="levelType.value"
                :value="levelType.value"
              >
                {{ levelType.text }}
              </option>
            </select>
          </div>
          <div class="col-md-6 form-group">
            <label class="col-form-label" for="memberUpgradeRuleTotal">{{
              $t("modal.accumulatedamounts")
            }}</label>
            <input
              type="number"
              class="form-control"
              id="memberUpgradeRuleTotal"
              v-model="memberUpgradeRule.Total"
            />
          </div>
          <div class="col-md-6 form-group">
            <label class="col-form-label" for="memberUpgradeRuleDays">{{
              $t("modal.accumulateddays")
            }}</label>
            <input
              type="number"
              class="form-control"
              id="memberUpgradeRuleDays"
              v-model="memberUpgradeRule.Days"
            />
          </div>
        </div>
      </div>
    </item-modal>
  </div>
</template>

<script>
import pageTable from "@/components/PageTable";
import itemModal from "@/components/ItemModal";

export default {
  data() {
    return {
      memberUpgradeRules: [],
      memberUpgradeRule: {},
      pagination: {},
      columns: [
        {
          name: "MemberUpgradeRuleText",
          headerText: "等級",
        },
        {
          name: "Total",
          headerText: "累積金額",
        },
        {
          name: "Days",
        },
      ],
      memberUpgradeLevelTypes: this.$enum.customerLevelType.getAll(),
      idProperty: "MemberUpgradeRuleId",
      nameProperty: "MemberUpgradeRuleText",
      getTrigger: false,
      isShow: false,
      isAdd: true,
      modalTitle: "",
    };
  },
  components: {
    pageTable,
    itemModal,
  },
  methods: {
    getRules(dTO) {
      this.$api.memberUpgradeRules.getPages(dTO).then((data) => {
        this.memberUpgradeRules = data.Datas;
        this.pagination = data.Pagination;
      });
    },
    addRule() {
      this.memberUpgradeRule = {
        MemberUpgradeRuleId: 1,
      };
      this.isAdd = true;
      this.modalTitle = this.$t("modal.addmemberupgraderule");
      this.isShow = !this.isShow;
    },
    editRule(rule) {
      this.$api.memberUpgradeRules
        .get(rule.MemberUpgradeRuleId)
        .then((data) => {
          this.memberUpgradeRule = data;
          this.isAdd = false;
          this.modalTitle = this.$t("modal.editmemberupgraderule");
          this.isShow = !this.isShow;
        });
    },
    removeRule(rule) {
      this.$api.memberUpgradeRules.remove(rule.MemberUpgradeRuleId).then(() => {
        this.getTrigger = !this.getTrigger;
      });
    },
    save() {
      this.$api.memberUpgradeRules
        .save(this.memberUpgradeRule)
        .then(() => {
          this.isShow = !this.isShow;
          this.getTrigger = !this.getTrigger;
        })
        .catch((error) => this.$func.message.error(error.data.Message));
    },
    close() {
      this.isShow = !this.isShow;
    },
  },
  created() {
    this.memberUpgradeLevelTypes.splice(0, 1);
  },
};
</script>
