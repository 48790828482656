import i18n from "@/langs/i18n";

export function getAll() {
  var items = [
    {
      value: 0,
      name: "None",
      text: `${i18n.t("option.thirdPartyType.None")}`,
    },
    {
      value: 1,
      name: "LinePay",
      text: `${i18n.t("option.thirdPartyType.LinePay")}`,
    },
    {
      value: 2,
      name: "PGTalk",
      text: `${i18n.t("option.thirdPartyType.PGTalk")}`,
    },
    {
      value: 3,
      name: "MyCard",
      text: `${i18n.t("option.thirdPartyType.MyCard")}`,
    },
    {
      value: 4,
      name: "PXPay",
      text: `${i18n.t("option.thirdPartyType.PXPay")}`,
    },
    {
      value: 5,
      name: "SurveyCake",
      text: `${i18n.t("option.thirdPartyType.SurveyCake")}`,
    },
    {
      value: 6,
      name: "Ocard",
      text: `${i18n.t("option.thirdPartyType.Ocard")}`,
    },
    {
      value: 7,
      name: "UberEats",
      text: `${i18n.t("option.thirdPartyType.UberEats")}`,
    },
    {
      value: 8,
      name: "Showmore",
      text: `${i18n.t("option.thirdPartyType.Showmore")}`,
    },
    {
      value: 9,
      name: "Mitake",
      text: `${i18n.t("option.thirdPartyType.Mitake")}`,
    },
    {
      value: 10,
      name: "Digiwin",
      text: `${i18n.t("option.thirdPartyType.Digiwin")}`,
    },
  ];
  return items;
}

export function getByName(name) {
  const type = getAll().find(function (item) {
    return item.name.toLowerCase() == name.toLowerCase();
  });
  return type;
}

export function getByValue(value) {
  const type = getAll().find(function (item) {
    return item.value == value;
  });
  return type;
}
