import i18n from "@/langs/i18n";

export function getAll() {
  var items = [
    {
      value: 0,
      name: "None",
      text: `${i18n.t("option.webhookType.None")}`,
    },
    {
      value: 1,
      name: "Order",
      text: `${i18n.t("option.webhookType.Order")}`,
    },
    {
      value: 2,
      name: "UbereatsOrder",
      text: `${i18n.t("option.webhookType.UbereatsOrder")}`,
    },
    {
      value: 3,
      name: "UbereatsOrderCancel",
      text: `${i18n.t("option.webhookType.UbereatsOrderCancel")}`,
    },
    {
      value: 4,
      name: "UbereatsStoreProvision",
      text: `${i18n.t("option.webhookType.UbereatsStoreProvision")}`,
    },
    {
      value: 5,
      name: "UbereatsStoreDeProvision",
      text: `${i18n.t("option.webhookType.UbereatsStoreDeProvision")}`,
    },
    {
      value: 6,
      name: "SendGrid",
      text: `${i18n.t("option.webhookType.SendGrid")}`,
    },
    {
      value: 7,
      name: "Showmore",
      text: `${i18n.t("option.webhookType.Showmore")}`,
    },
  ];
  return items;
}

export function getByName(name) {
  const type = getAll().find(function (item) {
    return item.name.toLowerCase() == name.toLowerCase();
  });
  return type;
}

export function getByValue(value) {
  const type = getAll().find(function (item) {
    return item.value == value;
  });
  return type;
}
