import i18n from "@/langs/i18n";

export function getAll() {
  var items = [
    {
      value: -1,
      name: "All",
      text: `${i18n.t("option.notificationType.All")}`,
    },
    {
      value: 0,
      name: "None",
      text: `${i18n.t("option.notificationType.None")}`,
    },
    {
      value: 1,
      name: "OnlineOrder",
      text: `${i18n.t("option.notificationType.OnlineOrder")}`,
    },
    {
      value: 2,
      name: "UberEatsOrder",
      text: `${i18n.t("option.notificationType.UberEatsOrder")}`,
    },
    {
      value: 3,
      name: "UberEatsOrderCancel",
      text: `${i18n.t("option.notificationType.UberEatsOrderCancel")}`,
    },
    {
      value: 4,
      name: "TableOrder",
      text: `${i18n.t("option.notificationType.TableOrder")}`,
    },
  ];
  return items;
}

export function getByName(name) {
  const type = getAll().find(function (item) {
    return item.name.toLowerCase() == name.toLowerCase();
  });
  return type;
}

export function getByValue(value) {
  const type = getAll().find(function (item) {
    return item.value == value;
  });
  return type;
}
