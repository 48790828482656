<template>
  <div>
    <h2 v-if="module">{{ module.ModuleName }}</h2>
    <div class="row">
      <div class="col-md-6">
        <div class="card card-table">
          <div class="card-header">
            <button
              type="button"
              class="btn btn-outline-primary py-2 px-4"
              style="border-color: transparent"
              @click="onAdd"
            >
              <i class="fa fa-plus"></i>
            </button>
          </div>

          <div class="card-body card-table-body">
            <div class="row" v-if="productType != 4">
              <div class="col">
                <div class="form-group row">
                  <label for="fontSize" class="col col-form-label"
                    >{{ $t("modal.fontsize") }}:</label
                  >
                  <div class="col">
                    <input
                      class="form-control"
                      type="number"
                      id="fontSize"
                      v-model="fontSize"
                    />
                  </div>
                </div>
              </div>
              <div class="col">
                <div class="form-group row">
                  <label for="foreColor" class="col col-form-label"
                    >{{ $t("modal.forecolor") }}:</label
                  >
                  <div class="col">
                    <input
                      class="form-control"
                      type="color"
                      id="foreColor"
                      v-model="foreColor"
                    />
                  </div>
                </div>
              </div>
              <div class="col">
                <div class="form-group row">
                  <label for="backColor" class="col col-form-label"
                    >{{ $t("modal.backcolor") }}:</label
                  >
                  <div class="col">
                    <input
                      class="form-control"
                      type="color"
                      id="backColor"
                      v-model="backColor"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class="table-responsive custom-table">
              <table class="table table-hover">
                <thead>
                  <tr>
                    <th>{{ $t("columnName.add") }}</th>
                    <th>{{ $t("columnName.functionname") }}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="notSelected in notSelecteds"
                    :key="notSelected.FunctionId"
                    @click="notSelectedClick(notSelected.FunctionId)"
                  >
                    <td>
                      <input
                        class="checkbox"
                        type="checkbox"
                        :value="notSelected.FunctionId"
                        v-model="addIds"
                      />
                    </td>
                    <td>{{ notSelected.FunctionName }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="card card-table">
          <div class="card-header">
            <button
              type="button"
              class="btn btn-outline-success py-2 px-4"
              style="border-color: transparent"
              @click="onEdit"
            >
              <i class="fas fa-pen"></i>
            </button>
            <button
              type="button"
              class="btn btn-outline-danger py-2 px-4"
              style="border-color: transparent"
              @click="onRemove"
            >
              <i class="fas fa-trash-alt"></i>
            </button>
            <button
              type="button"
              class="btn btn-outline-primary py-2 px-4"
              style="border-color: transparent"
              @click="onBefore"
            >
              <i class="fa fa-arrow-up"></i>
            </button>
            <button
              type="button"
              class="btn btn-outline-primary py-2 px-4"
              style="border-color: transparent"
              @click="onAfter"
            >
              <i class="fa fa-arrow-down"></i>
            </button>
          </div>
          <div class="card-body card-table-body">
            <div class="table-responsive custom-table">
              <table class="table table-hover">
                <thead>
                  <tr>
                    <th>{{ $t("columnName.remove") }}</th>
                    <th>{{ $t("columnName.functionname") }}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(selected, index) in selecteds"
                    :key="selected.FunctionButtonId"
                    :class="{ 'tr-selected': currentIndex == index }"
                    @click="selectedClick(selected.FunctionButtonId, index)"
                  >
                    <td>
                      <input
                        class="checkbox"
                        type="checkbox"
                        :value="selected.FunctionButtonId"
                        v-model="removeIds"
                      />
                    </td>
                    <td>{{ selected.FunctionShowName }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>

    <item-modal
      :isShowModal="isShowModal"
      @onClosed="isShowModal = !isShowModal"
      @onSaved="onSave"
      :title="`${$t('modal.editfunctionbtn')}`"
    >
      <div slot="item">
        <div class="row">
          <div class="col-md-6">
            <div class="form-group">
              <label for="functionShowName">{{
                $t("modal.displayname")
              }}</label>
              <input
                type="text"
                class="form-control"
                id="functionShowName"
                v-model="functionButton.FunctionShowName"
                required
              />
            </div>
            <div class="form-group">
              <label for="fontSize">{{ $t("modal.fontsize") }}</label>
              <input
                type="number"
                class="form-control"
                id="fontSize"
                v-model="functionButton.FontSize"
                required
              />
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label for="foreColor">{{ $t("modal.forecolor") }}</label>
              <input
                type="color"
                class="form-control"
                id="foreColor"
                v-model="functionButton.ForeColor"
              />
            </div>
            <div class="form-group">
              <label for="backColor">{{ $t("modal.backcolor") }}</label>
              <input
                type="color"
                class="form-control"
                id="backColor"
                v-model="functionButton.BackColor"
              />
            </div>
          </div>
        </div>
      </div>
    </item-modal>
  </div>
</template>

<script>
import itemModal from "@/components/ItemModal";

export default {
  data() {
    return {
      currentIndex: -1,
      module: {},
      notSelecteds: [],
      selecteds: [],
      addIds: [],
      removeIds: [],
      fontSize: 16,
      foreColor: "#FFFFFF",
      backColor: "#ED4E51",
      columns: [
        {
          name: "FunctionShowName",
          headerText: "功能鍵",
        },
      ],
      isShowModal: false,
      functionButton: {},
    };
  },
  components: {
    itemModal,
  },
  computed: {
    productType() {
      return parseInt(window.localStorage["productType"]);
    },
  },
  methods: {
    notSelectedClick(id) {
      const index = this.addIds.indexOf(id);
      if (index >= 0) {
        this.addIds.splice(index, 1);
      } else {
        this.addIds.push(id);
      }
    },
    selectedClick(id, i) {
      const index = this.removeIds.indexOf(id);
      this.currentIndex = i;
      if (index >= 0) {
        this.removeIds.splice(index, 1);
      } else {
        this.removeIds.push(id);
      }
    },
    updateFunctionButtons() {
      const vm = this;
      this.$api.functionButtons.gets(vm.module.ModuleId).then((data) => {
        vm.addIds = [];
        vm.removeIds = [];
        vm.notSelecteds = data.NotSelecteds;
        vm.selecteds = data.Selecteds;
      });
    },
    onAdd() {
      if (this.addIds.length == 0) {
        this.$func.message.alert("請選擇功能鍵 !");
        return;
      }
      const vm = this;
      this.$api.functionButtons
        .posts({
          ModuleId: vm.module.ModuleId,
          FunctionIds: vm.addIds,
          ForeColor: vm.foreColor,
          BackColor: vm.backColor,
          FontSize: vm.fontSize,
        })
        .then(() => vm.updateFunctionButtons());
    },
    onEdit() {
      const id = this.getCurrentId();
      if (!id) {
        return;
      }
      const vm = this;
      this.$api.functionButtons.get(id).then((data) => {
        if (data) {
          vm.functionButton = data;
          vm.isShowModal = !vm.isShowModal;
        }
      });
    },
    onSave() {
      const vm = this;
      this.$api.functionButtons.save(vm.functionButton).then(() => {
        vm.updateFunctionButtons();
        vm.isShowModal = !vm.isShowModal;
      });
    },
    onRemove() {
      if (this.removeIds.length == 0) {
        this.$func.message.alert("請選擇功能鍵 !");
        return;
      }
      const vm = this;
      this.$api.functionButtons
        .deletes({
          ModuleId: vm.module.ModuleId,
          FunctionButtonIds: vm.removeIds,
        })
        .then(() => vm.updateFunctionButtons());
    },
    getCurrentId() {
      if (this.currentIndex < 0) {
        this.$func.message.alert("請選擇功能 !");
        return undefined;
      }
      const button = this.selecteds[this.currentIndex];
      if (!button) {
        return undefined;
      } else {
        return button.FunctionButtonId;
      }
    },
    onBefore() {
      const id = this.getCurrentId();
      if (!id) {
        return;
      }
      const vm = this;
      this.$api.functionButtons.before(id).then(() => {
        vm.updateFunctionButtons();
        if (vm.currentIndex > 0) {
          vm.currentIndex--;
        }
      });
    },
    onAfter() {
      const id = this.getCurrentId();
      if (!id) {
        return;
      }
      const vm = this;
      this.$api.functionButtons.after(id).then(() => {
        vm.updateFunctionButtons();
        if (vm.currentIndex < vm.selecteds.length) {
          vm.currentIndex++;
        }
      });
    },
  },
  created() {
    const vm = this;
    this.$api.modules.get(this.$route.params["id"]).then((data) => {
      if (!data) {
        vm.$func.message.alert("查無此模組 !");
        vm.$router.go(-1);
      }
      vm.module = data;
      vm.updateFunctionButtons();
    });
  },
};
</script>
