<template>
  <div>
    <div class="row mb-2 px-3">
      <div class="form-inline">
        <label for="pageSize" class="mx-2">Show</label>
        <select
          class="form-control-sm"
          v-model="pageDTO.PageSize"
          @change="pageSizeChanged"
        >
          <option v-for="size in sizes" :key="size" :value="size">
            {{ size }}
          </option>
        </select>
        <label for="pageSize" class="mx-2">entries</label>
      </div>
    </div>

    <table-part
      :columns="columns"
      :items="items"
      :idProperty="idProperty"
      @currentIndexChanged="currentIndexChanged"
      :isSortable="isSortable"
      @dbClicked="trdbClicked"
      @getSort="getSort"
    >
      <template slot="th-before">
        <slot name="th-before" />
      </template>
      <template slot="th-after">
        <slot name="th-after" />
      </template>
      <template slot="td-before" slot-scope="props">
        <slot name="td-before" :item="props.item" />
      </template>
      <template slot="td-after" slot-scope="props">
        <slot name="td-after" :item="props.item" />
      </template>
    </table-part>

    <pagination-part
      :pagination="pageDTO"
      @pageChanged="pageChanged"
    ></pagination-part>
  </div>
</template>

<script>
import tablePart from "@/components/parts/tablePart";
import paginationPart from "@/components/parts/paginationPart";

export default {
  data() {
    return {
      sizes: [10, 25, 50, 100, 250, 500],
      pageDTO: this.$func.defaultModel.getPage(),
      sortName: "",
      isDesc: 0,
    };
  },
  props: {
    pagination: Object,
    items: Array,
    columns: Array,
    idProperty: String,
    nameProperty: String,
    getTrigger: {
      type: Boolean,
      default: false,
    },
    isSortable: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    pagination(value) {
      this.pageDTO = value;
    },
    getTrigger() {
      this.getItems();
    },
  },
  components: {
    tablePart,
    paginationPart,
  },
  methods: {
    getSort(status) {
      this.sortName = status.sortName;
      this.isDesc = status.isDesc;
      this.getItems();
    },
    pageChanged(page) {
      this.pageDTO.CurrentPage = page;
      this.getItems();
    },
    currentIndexChanged(index) {
      this.$emit("currentIndexChanged", index);
    },
    pageSizeChanged() {
      this.pageDTO.CurrentPage = 1;
      this.getItems();
    },
    getItems() {
      const vm = this;
      this.$emit("getItems", {
        page: vm.pageDTO.CurrentPage,
        pageSize: vm.pageDTO.PageSize,
        sortName: vm.sortName,
        isDesc: vm.isDesc,
      });
    },
    trdbClicked(item) {
      this.$emit("trdbClicked", item);
    },
  },
  mounted() {
    this.getItems();
  },
};
</script>
