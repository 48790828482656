export function checkEl(el, result, invalidMessage) {
  let isValid = result || (!el.required && !el.value);
  if (isValid) {
    removeInvalid(el);
  } else {
    addInvalid(el, invalidMessage);
  }
}

function addInvalid(el, invalidMessage) {
  const elParent = el.parentNode;
  const validateAlert = document.createElement("div");

  validateAlert.innerHTML = invalidMessage;
  validateAlert.classList.add("validate-alert");

  if (!el.classList.contains("invalid")) {
    elParent.appendChild(validateAlert);
    el.classList.add("invalid");
  }
}

function removeInvalid(el) {
  const elParent = el.parentNode;
  const currentValidateAlert =
    elParent.getElementsByClassName("validate-alert");

  if (el.classList.contains("invalid")) {
    elParent.removeChild(currentValidateAlert[0]);
    el.classList.remove("invalid");
  }
}
