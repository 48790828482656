import { httpGet, httpPost, httpDelete } from '@/request/http'
import func from '@/funcs'

let path = '/foodmajorkinds'

export default {
  get(id) {
    return httpGet(`${path}/${id}`)
  },
  getPages(dTO) {
    return httpGet(`${path}/pages`, dTO)
  },
  getAll() {
    return httpGet(`${path}`)
  },
  save(item) {
    return httpPost(`${path}`, item)
  },
  remove(id) {
    return httpDelete(`${path}/${id}`)
  },
  getExcel() {
    func.getExcel.get(`${path}/excel`, '商品大類別')
  },
  postExcel(formData) {
    return func.postExcel(`${path}/excel`, formData)
  },
}
