<template>
  <div>
    <condition-part
      :hasDate="true"
      :hasTerminal="true"
      :hasEmployee="true"
      :hasExcel="true"
      :hasPdf="true"
      :hasAdd="false"
      :hasEdit="false"
      :hasRemove="false"
      :columns="columns"
      :items="items"
      :pagination="pagination"
      :isSortable="true"
      @getItems="getItems"
      @downloadExcel="downloadExcel"
      @downloadPdf="downloadPdf"
      @printPdf="printPdf"
    />
  </div>
</template>

<script>
import conditionPart from "@/components/parts/conditionPart";

export default {
  data() {
    return {
      items: [],
      columns: [
        { name: "TradeNumber" },
        { name: "TerminalName", headerText: "收銀台" },
        { name: "EmployeeName", headerText: "員工" },
        { name: "ExecuteTime" },
        { name: "Total", headerText: "金額" },
        { name: "LastRechargeAmount" },
      ],
      pagination: {},
    };
  },
  components: {
    conditionPart,
  },
  methods: {
    downloadExcel(condition) {
      this.$api.reportExcels.getPGTalkDeposit(condition);
    },
    downloadPdf(condition) {
      this.$api.reportPdfs.getPGTalkDeposit(condition);
    },
    printPdf(condition) {
      this.$api.reportPdfs.printPGTalkDeposit(condition);
    },
    getItems(condition) {
      const vm = this;
      this.$api.reports.getPGTalkDeposit(condition).then((data) => {
        vm.items = data.Datas;
        vm.pagination = data.Pagination;
      });
    },
  },
};
</script>
