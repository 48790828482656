<template>
  <div>
    <search-and-table-part
      :pagination="pagination"
      @getItems="getItems"
      @trdbClicked="checkItem"
      :items="items"
      :columns="columns"
      :idProperty="idProperty"
      :nameProperty="nameProperty"
      :hasAdd="false"
      :hasEdit="false"
      :hasRemove="false"
      :hasCheckDetail="true"
      :getTrigger="getTrigger"
    >
      <template slot="searchArea">
        <div class="row">
          <div class="col-12 col-md-6">
            <div class="form-group d-flex col-12 p-0">
              <label
                for="searchBusinessId"
                class="col-3 col-form-label col-md-4 col-lg-3 col-xl-2"
                >營業人編號:</label
              >
              <div class="col">
                <input
                  type="text"
                  class="form-control"
                  id="searchBusinessId"
                  v-model="searchDTO.BusinessId"
                />
              </div>
            </div>
          </div>
          <div class="col-12 col-md-6">
            <date-range-part
              :date="dateRange"
              :gridDegree="6"
              @dateChanged="dateChanged"
            />
          </div>
          <div class="col-12 col-md-6">
            <input-inline-part
              id="searchThirdPartyType"
              :labelText="`${$t('searchAndTablePart.thirdpartytype')}:`"
              :gridDegree="6"
            >
              <select
                id="searchThirdPartyType"
                class="form-control"
                v-model="searchDTO.ThirdPartyType"
              >
                <option
                  v-for="thirdPartyType in thirdPartyTypes"
                  :key="thirdPartyType.value"
                  :value="thirdPartyType.value"
                >
                  {{ thirdPartyType.text }}
                </option>
              </select>
            </input-inline-part>
          </div>
          <div class="col-12 col-md-6">
            <div class="form-group d-flex col-12 p-0">
              <label
                for="searchStatusCode"
                class="col-3 col-form-label col-md-4 col-lg-3 col-xl-2"
                >狀態碼:</label
              >
              <div class="col">
                <input
                  type="text"
                  class="form-control"
                  id="searchStatusCode"
                  v-model="searchDTO.StatusCode"
                />
              </div>
            </div>
          </div>
        </div>
      </template>
      <template slot="dataButtonAreaFront" slot-scope="props">
        <button
          class="btn btn-outline-primary btn-icon"
          type="button"
          @click="checkItem(items[props.index])"
        >
          <i class="fas fas fa-eye"></i>
        </button>
      </template>
    </search-and-table-part>
    <log-data
      :modalId="modalId"
      :item="item"
      :columns="columns"
      :isShow="isShow"
      @onClosed="onClosed"
    ></log-data>
  </div>
</template>

<script>
import searchAndTablePart from "@/components/parts/searchAndTablePart";
import dateRangePart from "@/components/parts/dateRangePart";
import inputInlinePart from "@/components/parts/inputInlinePart";
import logData from "@/components/datas/LogData";

import date from "@/filters/date";

export default {
  data() {
    return {
      items: [],
      item: {},
      columns: [
        { name: "CreateTime" },
        { name: "BusinessId" },
        { name: "DeviceId" },
        { name: "ThirdPartyTypeText" },
        { name: "RequestURL" },
        { name: "RequestTime" },
        { name: "ResponseCode" },
        { name: "HttpMethod" },
        { name: "DurationSeconds" },
      ],
      thirdPartyTypes: this.$enum.thirdPartyType.getAll(),
      searchDTO: {},
      pagination: {},
      idProperty: "ThirdPartyLogId",
      nameProperty: "",
      dateRange: {
        DayBegin: "",
        DayEnd: "",
      },
      isShow: false,
      modalId: "ThirdParty",
      getTrigger: false,
    };
  },
  components: {
    searchAndTablePart,
    dateRangePart,
    inputInlinePart,
    logData,
  },
  methods: {
    getItems(dTO) {
      this.searchDTO.DayBegin = this.dateRange.DayBegin;
      this.searchDTO.DayEnd = this.dateRange.DayEnd;
      const d = { ...dTO, ...this.searchDTO };
      this.$api.logs.getThirdParties(d).then((data) => {
        this.items = data.Datas;
        this.items.forEach((item) => {
          item.ThirdPartyTypeText = this.$enum.thirdPartyType.getByValue(
            item.ThirdPartyType
          ).text;
        });
        this.pagination = data.Pagination;
      });
    },
    checkItem(i) {
      this.$api.logs.getThirdParty(i.ThirdPartyLogId).then((data) => {
        this.item = data;
        this.item.ThirdPartyTypeText = this.$enum.thirdPartyType.getByValue(
          this.item.ThirdPartyType
        ).text;
        this.item.DurationSeconds += "s";
        this.isShow = !this.isShow;
      });
    },
    onClosed() {
      this.isShow = !this.isShow;
    },
    dateChanged(begin, end) {
      this.dateRange.DayBegin = begin;
      this.dateRange.DayEnd = end;
    },
  },
};
</script>
