import { httpGet, httpPost, httpDelete } from '@/request/http';

let path = '/directorys';

export default{
    get(id){
      return httpGet(`${path}/${id}`);
    },
    getPages(id, dTO){
      return httpGet(`/directorykinds/${id}/directorys/pages`, dTO);
    },
    save(item){
      return httpPost(path , item);
    },
    remove(id){
      return httpDelete(`${path}/${id}`);
    }
}
