<template>
  <div>
    <page-table
      :hasAdd="true"
      :hasEdit="true"
      :hasRemove="true"
      :parentPage="pagination"
      @getItems="getItems"
      @addItem="addItem"
      @editItem="editItem"
      @removeItem="removeItem"
      :items="items"
      :columns="columns"
      :idProperty="idProperty"
      :nameProperty="nameProperty"
      :getTrigger="getTrigger"
      :isSortable="true"
    ></page-table>

    <item-modal
      :isShowModal="isShowModal"
      @onClosed="isShowModal = !isShowModal"
      @onSaved="saveItem"
      :title="modalTitle"
    >
      <div slot="item">
        <div class="row">
          <div class="col-md-6">
            <div class="form-group">
              <label for="fullNumber">{{ $t("title.fullnumber") }}:</label>
              <input
                type="text"
                class="form-control"
                id="fullNumber"
                v-model="changePrice.FullNumber"
                @keydown.enter.prevent
              />
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label for="salPriceType">{{ $t("title.salpricetype") }}:</label>
              <select
                class="form-control"
                id="salPriceType"
                v-model="changePrice.SalPriceType"
              >
                <option
                  v-for="type in priceTypes"
                  :value="type.value"
                  :key="type.value"
                >
                  {{ type.text }}
                </option>
              </select>
            </div>
          </div>
        </div>
      </div>
    </item-modal>
  </div>
</template>

<script>
import pageTable from "@/components/PageTable";
import itemModal from "@/components/ItemModal";

export default {
  data() {
    return {
      columns: [
        { name: "ChangePriceType" },
        { name: "FullNumber" },
        { name: "SalPriceType" },
      ],
      priceTypes: [
        { value: 1, text: "售價1" },
        { value: 2, text: "售價2" },
        { value: 3, text: "售價3" },
        { value: 4, text: "售價4" },
        { value: 5, text: "售價5" },
        { value: 6, text: "售價6" },
      ],
      items: [],
      changePrice: {},
      pagination: {},
      isShowModal: false,
      modalTitle: "",
      idProperty: "ChangePriceId",
      nameProperty: "ChangePriceType",
      getTrigger: false,
      moduleId: "",
      type: null,
    };
  },
  components: {
    pageTable,
    itemModal,
  },
  methods: {
    getItems() {
      this.$api.modules
        .getChangePrices(this.moduleId, this.type)
        .then((data) => {
          this.items = data;
        });
    },
    addItem() {
      this.changePrice = {};
      this.isShowModal = !this.isShowModal;
      if (this.type == 0) {
        this.modalTitle = this.$t("modal.addchangepriceforprice");
      } else if (this.type == 1) {
        this.modalTitle = this.$t("modal.addchangepriceforqty");
      }
    },
    editItem(item) {
      const vm = this;
      this.$api.changePrices.get(item.ChangePriceId).then((data) => {
        vm.changePrice = data;
        vm.isShowModal = !vm.isShowModal;
        if (vm.type == 0) {
          vm.modalTitle = vm.$t("modal.editchangepriceforprice");
        } else if (vm.type == 1) {
          vm.modalTitle = vm.$t("modal.editchangepriceforqty");
        }
      });
    },
    removeItem(item) {
      const vm = this;
      this.$api.changePrices.remove(item.ChangePriceId).then(() => {
        vm.getTrigger = !vm.getTrigger;
      });
    },
    saveItem() {
      const vm = this;
      this.changePrice.ModuleId = this.moduleId;
      this.changePrice.ChangePriceType = this.type;
      this.$api.changePrices
        .save(this.changePrice)
        .then(() => {
          vm.getTrigger = !vm.getTrigger;
          vm.isShowModal = !vm.isShowModal;
        })
        .catch((error) => vm.$func.message.error(error.data.Message));
    },
  },
  mounted() {},
  created() {
    this.type = parseInt(this.$route.params["type"]);
    if (this.type == 0) {
    } else if (this.type == 1) {
    }
    this.moduleId = this.$route.params["id"];
  },
};
</script>
