<template>
  <div>
    <pageTable
      :parentPage="pagination"
      @getItems="getItems"
      @addItem="addItem"
      @editItem="editItem"
      @removeItem="removeItem"
      :items="items"
      :columns="columns"
      :idProperty="idProperty"
      :nameProperty="nameProperty"
      :getTrigger="getTrigger"
      :hasAdd="authority.IsAdd"
      :hasEdit="authority.IsUpdate"
      :hasRemove="authority.IsRemove"
      @currentIndexChanged="currentIndexChanged"
      :tableName="componentId"
    >
      <template slot="add">
        <button
          type="button"
          class="btn btn-outline-primary btn-icon dropdown-toggle"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
        >
          <span class="pr-2">
            <i class="fa fa-plus"></i>
          </span>
        </button>
        <div class="dropdown-menu">
          <a class="dropdown-item" @click="addItem">{{
            $t("modal.additem")
          }}</a>
          <a
            class="dropdown-item"
            @click="foodSelectTrigger = !foodSelectTrigger"
            >{{ $t("modal.select") }}</a
          >
        </div>
      </template>
      <template slot="button">
        <button
          type="button"
          class="btn btn-outline-info py-2 px-4"
          style="border-color: transparent"
          @click="showCategories"
        >
          <i class="fa fa-project-diagram"></i>
        </button>
        <button
          type="button"
          class="btn btn-outline-info py-2 px-4"
          style="border-color: transparent"
          @click="showModifierGroups"
        >
          <i class="fas fa-pepper-hot"></i>
        </button>
        <!-- <button
          type="button"
          class="btn btn-outline-primary py-2 px-4"
          style="border-color: transparent"
          @click="updateUberEatsItem"
        >
          <i class="fas fa-cloud-upload-alt"></i>
        </button> -->
      </template>
    </pageTable>

    <item-data
      :modalTitle="modalTitle"
      :isShow="isShowModal"
      :isEdit="isEdit"
      :entity="item"
      :onlineOrderType="onlineOrderType"
      @save="saveItem"
      @close="onClosed"
    ></item-data>

    <food-select
      :trigger="foodSelectTrigger"
      :isMultiple="true"
      @onSaved="addMultipleFood"
    >
    </food-select>

    <category-select
      :trigger="categoryTrigger"
      :isMultiple="true"
      :defaultItems="onlineOrderCategories"
      :onlineOrderType="onlineOrderType"
      @onSaved="saveCategories"
    ></category-select>

    <modifier-group-select
      :hasSequence="true"
      :trigger="modifierGroupTrigger"
      :isMultiple="true"
      :defaultItems="onlineOrderModifierGroups"
      :onlineOrderType="onlineOrderType"
      @onSaved="saveModifierGroups"
    ></modifier-group-select>

    <modal-part
      modalId="salPriceType"
      :isShow="salPriceTrigger"
      title="選擇售價別"
      @onSaved="saveSalPrice"
      @onClosed="closeSalPrice"
    >
      <div slot="item">
        <div class="row">
          <div class="col-md-12 form-group">
            <select
              class="form-control"
              id="salPriceType"
              v-model="salPriceType"
            >
              <option
                v-for="salPriceType in salPriceTypes"
                :key="salPriceType.value"
                :value="salPriceType.value"
              >
                {{ salPriceType.text }}
              </option>
            </select>
          </div>
        </div>
      </div>
    </modal-part>
  </div>
</template>

<script>
import pageTable from "@/components/PageTable";
import itemData from "@/components/onlineOrders/OnlineOrderItemData";
import foodSelect from "@/components/selects/FoodSelects";
import categorySelect from "@/components/selects/OnlineOrderCategorySelects";
import modifierGroupSelect from "@/components/selects/OnlineOrderModifierGroupSelects";
import modalPart from "@/components/parts/modalPart";

export default {
  data() {
    return {
      items: [],
      item: {},
      pagination: {},
      isShowModal: false,
      isEdit: false,
      columns: [
        {
          name: "OnlineOrderItemName",
        },
        {
          name: "SalPrice",
        },
        {
          name: "FoodName",
          headerText: "對應商品",
        },
        {
          name: "OnlineOrderCategoryText",
          headerText: "類別",
        },
        {
          name: "OnlineOrderModifierGroupText",
          headerText: "口味",
        },
      ],
      idProperty: "OnlineOrderItemId",
      nameProperty: "OnlineOrderItemName",
      getTrigger: false,
      authority: {},
      foodSelectTrigger: false,
      categoryTrigger: false,
      onlineOrderCategories: undefined,
      modifierGroupTrigger: false,
      onlineOrderModifierGroups: undefined,
      currentIndex: -1,
      modalTitle: "",
      salPriceTrigger: false,
      salPriceTypes: [],
      salPriceType: 1,
      addItems: [],
    };
  },
  props: ["componentId", "onlineOrderType"],
  components: {
    pageTable,
    itemData,
    foodSelect,
    categorySelect,
    modifierGroupSelect,
    modalPart,
  },
  methods: {
    currentIndexChanged(index) {
      this.currentIndex = index;
    },
    getItems(dTO) {
      dTO.type = this.onlineOrderType;
      const vm = this;
      this.$api.onlineOrderItems.getPages(dTO).then((data) => {
        vm.items = data.Datas;
        vm.pagination = data.Pagination;
      });
    },
    addItem() {
      this.item = this.$func.defaultModel.getOnlineOrderItem(
        this.onlineOrderType
      );
      this.isEdit = false;
      this.isShowModal = !this.isShowModal;
      this.modalTitle = this.$t("modal.additem");
    },
    editItem(item) {
      const vm = this;
      this.$api.onlineOrderItems.get(item.OnlineOrderItemId).then((data) => {
        vm.item = data;
        vm.isEdit = true;
        vm.isShowModal = !vm.isShowModal;
        vm.modalTitle = vm.$t("modal.edititem");
      });
    },
    addMultipleFood(items) {
      this.salPriceTrigger = !this.salPriceTrigger;
      this.addItems = items;
    },
    saveSalPrice() {
      const vm = this;
      this.$api.onlineOrderItems
        .postBatch({
          OnlineOrderType: this.onlineOrderType,
          SalPriceType: this.salPriceType,
          FoodIds: this.addItems.map((x) => x.FoodId),
        })
        .then(() => {
          vm.salPriceTrigger = !vm.salPriceTrigger;
          vm.getTrigger = !vm.getTrigger;
        });
    },
    closeSalPrice() {
      this.salPriceTrigger = !this.salPriceTrigger;
    },
    removeItem(item) {
      const vm = this;
      this.$api.onlineOrderItems.remove(item.OnlineOrderItemId).then(() => {
        vm.getTrigger = !vm.getTrigger;
      });
    },
    saveItem() {
      this.getTrigger = !this.getTrigger;
      this.onClosed();
    },
    onClosed() {
      this.isShowModal = !this.isShowModal;
    },
    showCategories() {
      const item = this.items[this.currentIndex];
      if (!item) {
        this.$func.message.alert("請選擇商品 !");
        return;
      }
      this.onlineOrderCategories = undefined;
      const vm = this;
      this.$api.onlineOrderItems
        .getCategories(item.OnlineOrderItemId)
        .then((data) => {
          vm.onlineOrderCategories = data;
          vm.categoryTrigger = !vm.categoryTrigger;
        });
    },
    saveCategories(categories) {
      const item = this.items[this.currentIndex];
      if (!item) {
        this.$func.message.alert("請選擇商品 !");
        return;
      }
      const vm = this;
      this.$api.onlineOrderItems
        .postCategories(
          item.OnlineOrderItemId,
          categories.map((category) => category.OnlineOrderCategoryId)
        )
        .then(() => {
          vm.getTrigger = !vm.getTrigger;
        })
        .catch((error) => {
          vm.$func.message.error(error.data.Message);
        });
    },
    showModifierGroups() {
      const item = this.items[this.currentIndex];
      if (!item) {
        this.$func.message.alert("請選擇商品 !");
        return;
      }
      this.onlineOrderCategories = undefined;
      const vm = this;
      this.$api.onlineOrderItems
        .getModifierGroups(item.OnlineOrderItemId)
        .then((data) => {
          vm.onlineOrderModifierGroups = data;
          vm.modifierGroupTrigger = !vm.modifierGroupTrigger;
        });
    },
    saveModifierGroups(modifierGroups) {
      const item = this.items[this.currentIndex];
      if (!item) {
        this.$func.message.alert("請選擇商品 !");
        return;
      }
      const vm = this;
      this.$api.onlineOrderItems
        .postModifierGroups(
          item.OnlineOrderItemId,
          modifierGroups.map(
            (modifierGroup) => modifierGroup.OnlineOrderModifierGroupId
          )
        )
        .then(() => {
          vm.getTrigger = !vm.getTrigger;
        })
        .catch((error) => {
          vm.$func.message.error(error.data.Message);
        });
    },
    updateUberEatsItem() {
      const vm = this;
      const item = this.items[this.currentIndex];
      this.$api.uberEats
        .updateUberEatsFood(item.OnlineOrderItemId)
        .then(() => {
          vm.$func.message.success("更新商品成功 !");
        })
        .catch((error) => {
          vm.$func.message.error(error.data.Message);
        });
    },
  },
  mounted() {
    this.salPriceTypes = this.$enum.salPriceType.getAll();
    this.salPriceTypes.splice(0, 1);
  },
  created() {
    const vm = this;
    this.$api.foodMajorKinds.getAll().then((data) => {
      vm.foodMajorKinds = data;
      vm.foodMajorKinds.splice(0, 0, {
        FoodMajorKindId: null,
        FoodMajorKindName: "無",
      });
    });

    this.authority = this.$func.localStorage.getAuthByName(this.$route.name);
  },
};
</script>
