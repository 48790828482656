<template>
  <button
    class="btn btn-outline-success py-2 px-4"
    style="border-color:transparent"
    type="button"
    @click="onExport"
  >
    <i class="fas fa-file-download"></i>
  </button>
</template>

<script>
export default {
  methods: {
    onExport() {
      this.$emit("onExport");
    }
  }
};
</script>
