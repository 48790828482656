<template>
  <div>
    <h2 v-if="module">{{ module.ModuleName }}</h2>
    <div class="card card-table">
      <div class="card-header">
        <button
          type="button"
          class="btn btn-outline-primary py-2 px-4"
          style="border-color: transparent"
          @click="onBefore"
        >
          <i class="fa fa-arrow-up"></i>
        </button>
        <button
          type="button"
          class="btn btn-outline-primary py-2 px-4"
          style="border-color: transparent"
          @click="onAfter"
        >
          <i class="fa fa-arrow-down"></i>
        </button>
      </div>

      <div class="card-body">
        <table-part
          :items="items"
          :columns="columns"
          :idProperty="idProperty"
          :parentIndex="currentIndex"
          @currentIndexChanged="currentIndexChanged"
          :changeIndex="currentIndex"
        />
      </div>
    </div>
  </div>
</template>

<script>
import tablePart from "@/components/parts/tablePart";

export default {
  data() {
    return {
      module: {},
      items: [],
      columns: [
        {
          name: "FoodKindNumber",
          headerText: "類別編號",
        },
        {
          name: "FoodKindName",
          headerText: "類別名稱",
        },
      ],
      currentIndex: -1,
      idProperty: "FoodKindId",
    };
  },
  components: {
    tablePart,
  },
  methods: {
    currentIndexChanged(index) {
      if (index > -1) this.currentIndex = index;
    },
    updateFoodKinds() {
      const vm = this;
      this.$api.foodKinds.getSequences(vm.module.ModuleId).then((data) => {
        vm.items = data;
      });
    },
    getCurrentId() {
      if (this.currentIndex < 0) {
        this.$func.message.alert("請選擇商品類別 !");
        return undefined;
      }
      const foodKind = this.items[this.currentIndex];
      if (!foodKind) {
        return undefined;
      } else {
        return foodKind.FoodKindId;
      }
    },
    onBefore() {
      const id = this.getCurrentId();
      if (!id) {
        return;
      }
      const vm = this;
      this.$api.foodKinds.before(vm.module.ModuleId, id).then(() => {
        vm.updateFoodKinds();
        if (vm.currentIndex > 0) {
          vm.currentIndex--;
        }
      });
    },
    onAfter() {
      const id = this.getCurrentId();
      if (!id) {
        return;
      }
      const vm = this;
      this.$api.foodKinds.after(vm.module.ModuleId, id).then(() => {
        vm.updateFoodKinds();
        if (vm.currentIndex < vm.items.length) {
          vm.currentIndex++;
        }
      });
    },
  },
  created() {
    const vm = this;
    this.$api.modules.get(this.$route.params["id"]).then((data) => {
      if (!data) {
        vm.$func.message.alert("查無此模組 !");
        vm.$router.go(-1);
      }
      vm.module = data;
      vm.updateFoodKinds();
    });
  },
};
</script>
