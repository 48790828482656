<template>
  <div>
    <modal-part
      modalId="ushowFormData"
      modalClass="modal-lg"
      title="詢問信"
      :hasButtons="isEdit"
      :isShow="isShow"
      @onSaved="onSaved"
      @onClosed="onClosed"
    >
      <div slot="item">
        <div class="row" v-if="form.InquiryId">
          <div class="col-md-6 row p-0 m-0">
            <div class="col-md-12">
              <div class="form-group ushowform-group">
                <label class="ushowform-label" for="salerType">業務員</label>
                <select
                  v-if="isEdit"
                  class="form-control ushowform-control"
                  id="salerType"
                  v-model="form.Saler"
                >
                  <option
                    v-for="salerType in salerTypes"
                    :key="salerType.value"
                    :value="salerType.value"
                  >
                    {{ salerType.text }}
                  </option>
                </select>
                <span v-else class="ushowform-review-value">{{
                  this.$enum.salerType.getByValue(form.Saler).text
                }}</span>
              </div>
            </div>
            <div class="col-md-12" v-if="form.Saler == 0">
              <div class="form-group ushowform-group">
                <label class="ushowform-label" for="salerMemo">備註</label>
                <input
                  v-if="isEdit"
                  type="text"
                  id="salerMemo"
                  class="form-control ushowform-control"
                  v-model="form.SalerMemo"
                />
                <span v-else class="ushowform-review-value">{{
                  form.SalerMemo
                }}</span>
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group ushowform-group">
              <label class="ushowform-label" for="createTime">建立時間</label>
              <span class="ushowform-review-value">{{
                form.CreateTimeText
              }}</span>
            </div>
          </div>
          <div class="col-12 mt-1">
            <hr class="ushowform-line" />
          </div>
          <div class="col-12 mt-2">
            <div class="form-group ushowform-category">
              <h5>基本資料</h5>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group ushowform-group">
              <label class="ushowform-label" for="name">名稱</label>
              <input
                v-if="isEdit"
                type="text"
                id="name"
                class="form-control ushowform-control"
                v-model="form.Name"
                required
              />
              <span v-else class="ushowform-review-value">{{ form.Name }}</span>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group ushowform-group">
              <label class="ushowform-label" for="gender">性別</label>
              <input
                v-if="isEdit"
                type="text"
                id="gender"
                class="form-control ushowform-control"
                v-model="form.Gender"
              />
              <span
                v-else-if="!isEdit && form.Gender"
                class="ushowform-review-value"
                >{{ form.Gender }}</span
              >
              <span v-else class="ushowform-review-value"></span>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group ushowform-group">
              <label class="ushowform-label" for="phone">電話</label>
              <input
                v-if="isEdit"
                type="text"
                id="phone"
                class="form-control ushowform-control"
                v-model="form.Phone"
                required
              />
              <span v-else class="ushowform-review-value">{{
                form.Phone
              }}</span>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group ushowform-group">
              <label class="ushowform-label" for="email">Email</label>
              <input
                v-if="isEdit"
                type="text"
                id="email"
                class="form-control ushowform-control"
                v-model="form.Email"
              />
              <span v-else class="ushowform-review-value">{{
                form.Email
              }}</span>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group ushowform-group">
              <label class="ushowform-label" for="companyName">公司名稱</label>
              <input
                v-if="isEdit"
                type="text"
                id="companyName"
                class="form-control ushowform-control"
                v-model="form.CompanyName"
              />
              <span v-else class="ushowform-review-value">{{
                form.CompanyName
              }}</span>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group ushowform-group">
              <label class="ushowform-label" for="uniformNumber"
                >統一編號</label
              >
              <input
                v-if="isEdit"
                type="text"
                id="uniformNumber"
                class="form-control ushowform-control"
                v-model="form.UniformNumber"
              />
              <span v-else class="ushowform-review-value">{{
                form.UniformNumber
              }}</span>
            </div>
          </div>
          <div class="col-md-12">
            <div class="form-group ushowform-group">
              <label class="ushowform-label" for="address">地址</label>
              <input
                v-if="isEdit"
                type="text"
                id="address"
                class="form-control ushowform-control"
                v-model="form.Address"
              />
              <span v-else class="ushowform-review-value">{{
                form.Address
              }}</span>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group ushowform-group">
              <label class="ushowform-label" for="sourceType">來源說明</label>
              <select
                v-if="isEdit"
                class="form-control ushowform-control"
                id="sourceType"
                v-model="form.SourceType"
              >
                <option
                  v-for="sourceType in sourceTypes"
                  :key="sourceType.value"
                  :value="sourceType.value"
                >
                  {{ sourceType.text }}
                </option>
              </select>
              <span
                v-else-if="!isEdit && form.SourceType >= 0"
                class="ushowform-review-value"
                >{{
                  this.$enum.sourceType.getByValue(form.SourceType).text
                }}</span
              >
              <span v-else class="ushowform-review-value"></span>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group ushowform-group">
              <label class="ushowform-label" for="sourceMemo">來源備註</label>
              <input
                v-if="isEdit"
                type="text"
                id="sourceMemo"
                class="form-control ushowform-control"
                v-model="form.SourceMemo"
              />
              <span v-else class="ushowform-review-value">{{
                form.SourceMemo
              }}</span>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group ushowform-group">
              <label class="ushowform-label" for="businessType">行業別</label>
              <select
                v-if="isEdit"
                class="form-control ushowform-control"
                id="businessType"
                v-model="form.BusinessType"
              >
                <option
                  v-for="businessType in businessTypes"
                  :key="businessType.value"
                  :value="businessType.value"
                >
                  {{ businessType.text }}
                </option>
              </select>
              <span
                v-else-if="!isEdit && form.BusinessType >= 0"
                class="ushowform-review-value"
                >{{
                  this.$enum.businessType.getByValue(form.BusinessType).text
                }}</span
              >
              <span v-else class="ushowform-review-value"></span>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group ushowform-group">
              <label class="ushowform-label" for="businessMemo"
                >行業別備註</label
              >
              <input
                v-if="isEdit"
                type="text"
                id="businessMemo"
                class="form-control ushowform-control"
                v-model="form.BusinessMemo"
              />
              <span v-else class="ushowform-review-value">{{
                form.BusinessMemo
              }}</span>
            </div>
          </div>
          <div class="col-md-12">
            <div class="form-group ushowform-group">
              <label class="ushowform-label" for="memo">備註</label>
              <input
                v-if="isEdit"
                type="text"
                id="memo"
                class="form-control ushowform-control"
                v-model="form.Memo"
              />
              <span v-else class="ushowform-review-value">{{ form.Memo }}</span>
            </div>
          </div>

          <div class="col-12 mt-1">
            <hr class="ushowform-line" />
          </div>

          <div class="col-12 mt-2">
            <div class="form-group ushowform-category">
              <h5>需求</h5>
            </div>
          </div>
          <div class="col-md-12">
            <div class="form-group ushowform-group">
              <label class="ushowform-label">· POS (多選)</label>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group ushowform-group">
              <div class="custom-control custom-checkbox" v-if="isEdit">
                <input
                  type="checkbox"
                  id="hasUshow"
                  class="custom-control-input"
                  v-model="form.HasUshow"
                />
                <label
                  class="custom-control-label ushowform-checkbox-label"
                  for="hasUshow"
                  >UShow</label
                >
              </div>
              <div class="custom-control custom-checkbox" v-else>
                <input
                  type="checkbox"
                  id="hasUshow"
                  class="custom-control-input"
                  v-model="form.HasUshow"
                  disabled
                />
                <label
                  class="custom-control-label ushowform-checkbox-label"
                  for="hasUshow"
                  >UShow</label
                >
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group ushowform-group">
              <div class="custom-control custom-checkbox" v-if="isEdit">
                <input
                  type="checkbox"
                  id="hasUshowPad"
                  class="custom-control-input"
                  v-model="form.HasUshowPad"
                />
                <label
                  class="custom-control-label ushowform-checkbox-label"
                  for="hasUshowPad"
                  >UShow Pad</label
                >
              </div>
              <div class="custom-control custom-checkbox" v-else>
                <input
                  type="checkbox"
                  id="hasUshowPad"
                  class="custom-control-input"
                  v-model="form.HasUshowPad"
                  disabled
                />
                <label
                  class="custom-control-label ushowform-checkbox-label"
                  for="hasUshowPad"
                  >UShow Pad</label
                >
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group ushowform-group">
              <div class="custom-control custom-checkbox" v-if="isEdit">
                <input
                  type="checkbox"
                  id="hasUshowLite"
                  class="custom-control-input"
                  v-model="form.HasUshowLite"
                />
                <label
                  class="custom-control-label ushowform-checkbox-label"
                  for="hasUshowLite"
                  >UShow Lite</label
                >
              </div>
              <div class="custom-control custom-checkbox" v-else>
                <input
                  type="checkbox"
                  id="hasUshowLite"
                  class="custom-control-input"
                  v-model="form.HasUshowLite"
                  disabled
                />
                <label
                  class="custom-control-label ushowform-checkbox-label"
                  for="hasUshowLite"
                  >UShow lite</label
                >
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group ushowform-group">
              <div class="custom-control custom-checkbox" v-if="isEdit">
                <input
                  type="checkbox"
                  id="hasUshowMini"
                  class="custom-control-input"
                  v-model="form.HasUshowMini"
                />
                <label
                  class="custom-control-label ushowform-checkbox-label"
                  for="hasUshowMini"
                  >UShow Mini</label
                >
              </div>
              <div class="custom-control custom-checkbox" v-else>
                <input
                  type="checkbox"
                  id="hasUshowMini"
                  class="custom-control-input"
                  v-model="form.HasUshowMini"
                  disabled
                />
                <label
                  class="custom-control-label ushowform-checkbox-label"
                  for="hasUshowMini"
                  >UShow Mini</label
                >
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group ushowform-group">
              <div class="custom-control custom-checkbox" v-if="isEdit">
                <input
                  type="checkbox"
                  id="hasUshowWeb"
                  class="custom-control-input"
                  v-model="form.HasUshowWeb"
                />
                <label
                  class="custom-control-label ushowform-checkbox-label"
                  for="hasUshowWeb"
                  >UShow Web</label
                >
              </div>
              <div class="custom-control custom-checkbox" v-else>
                <input
                  type="checkbox"
                  id="hasUshowWeb"
                  class="custom-control-input"
                  v-model="form.HasUshowWeb"
                  disabled
                />
                <label
                  class="custom-control-label ushowform-checkbox-label"
                  for="hasUshowWeb"
                  >UShow Web</label
                >
              </div>
            </div>
          </div>
          <div class="col-md-12" v-if="form.ProductMemo || isEdit">
            <div class="form-group ushowform-group">
              <label class="ushowform-label" for="productMemo">備註</label>
              <input
                v-if="isEdit"
                type="text"
                id="productMemo"
                class="form-control ushowform-control"
                v-model="form.ProductMemo"
              />
              <span v-else class="ushowform-review-value">{{
                form.ProductMemo
              }}</span>
            </div>
          </div>

          <div class="col-md-12 ushowform-inquiry-title">
            <div class="form-group ushowform-group">
              <label class="ushowform-label">· 加值服務 (多選)</label>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group ushowform-group">
              <div class="custom-control custom-checkbox" v-if="isEdit">
                <input
                  type="checkbox"
                  id="hasElecInvoice"
                  class="custom-control-input"
                  v-model="form.HasElecInvoice"
                />
                <label
                  class="custom-control-label ushowform-checkbox-label"
                  for="hasElecInvoice"
                  >電子發票</label
                >
              </div>
              <div class="custom-control custom-checkbox" v-else>
                <input
                  type="checkbox"
                  id="hasElecInvoice"
                  class="custom-control-input"
                  v-model="form.HasElecInvoice"
                  disabled
                />
                <label
                  class="custom-control-label ushowform-checkbox-label"
                  for="hasElecInvoice"
                  >電子發票</label
                >
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group ushowform-group">
              <div class="custom-control custom-checkbox" v-if="isEdit">
                <input
                  type="checkbox"
                  id="hasCloud"
                  class="custom-control-input"
                  v-model="form.HasCloud"
                />
                <label
                  class="custom-control-label ushowform-checkbox-label"
                  for="hasCloud"
                  >雲端</label
                >
              </div>
              <div class="custom-control custom-checkbox" v-else>
                <input
                  type="checkbox"
                  id="hasCloud"
                  class="custom-control-input"
                  v-model="form.HasCloud"
                  disabled
                />
                <label
                  class="custom-control-label ushowform-checkbox-label"
                  for="hasCloud"
                  >雲端</label
                >
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group ushowform-group">
              <div class="custom-control custom-checkbox" v-if="isEdit">
                <input
                  type="checkbox"
                  id="hasMultiPay"
                  class="custom-control-input"
                  v-model="form.HasMultiPay"
                />
                <label
                  class="custom-control-label ushowform-checkbox-label"
                  for="hasMultiPay"
                  >多元支付</label
                >
              </div>
              <div class="custom-control custom-checkbox" v-else>
                <input
                  type="checkbox"
                  id="hasMultiPay"
                  class="custom-control-input"
                  v-model="form.HasMultiPay"
                  disabled
                />
                <label
                  class="custom-control-label ushowform-checkbox-label"
                  for="hasMultiPay"
                  >多元支付</label
                >
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group ushowform-group">
              <div class="custom-control custom-checkbox" v-if="isEdit">
                <input
                  type="checkbox"
                  id="hasERP"
                  class="custom-control-input"
                  v-model="form.HasERP"
                />
                <label
                  class="custom-control-label ushowform-checkbox-label"
                  for="hasERP"
                  >進銷存</label
                >
              </div>
              <div class="custom-control custom-checkbox" v-else>
                <input
                  type="checkbox"
                  id="hasERP"
                  class="custom-control-input"
                  v-model="form.HasERP"
                  disabled
                />
                <label
                  class="custom-control-label ushowform-checkbox-label"
                  for="hasERP"
                  >進銷存</label
                >
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group ushowform-group">
              <div class="custom-control custom-checkbox" v-if="isEdit">
                <input
                  type="checkbox"
                  id="hasCustomer"
                  class="custom-control-input"
                  v-model="form.HasCustomer"
                />
                <label
                  class="custom-control-label ushowform-checkbox-label"
                  for="hasCustomer"
                  >會員</label
                >
              </div>
              <div class="custom-control custom-checkbox" v-else>
                <input
                  type="checkbox"
                  id="hasCustomer"
                  class="custom-control-input"
                  v-model="form.HasCustomer"
                  disabled
                />
                <label
                  class="custom-control-label ushowform-checkbox-label"
                  for="hasCustomer"
                  >會員</label
                >
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group ushowform-group">
              <div class="custom-control custom-checkbox" v-if="isEdit">
                <input
                  type="checkbox"
                  id="hasOnlineOrder"
                  class="custom-control-input"
                  v-model="form.HasOnlineOrder"
                />
                <label
                  class="custom-control-label ushowform-checkbox-label"
                  for="hasOnlineOrder"
                  >線上點餐</label
                >
              </div>
              <div class="custom-control custom-checkbox" v-else>
                <input
                  type="checkbox"
                  id="hasOnlineOrder"
                  class="custom-control-input"
                  v-model="form.HasOnlineOrder"
                  disabled
                />
                <label
                  class="custom-control-label ushowform-checkbox-label"
                  for="hasOnlineOrder"
                  >線上點餐</label
                >
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group ushowform-group">
              <div class="custom-control custom-checkbox" v-if="isEdit">
                <input
                  type="checkbox"
                  id="hasLineOrder"
                  class="custom-control-input"
                  v-model="form.HasLineOrder"
                />
                <label
                  class="custom-control-label ushowform-checkbox-label"
                  for="hasLineOrder"
                  >LINE點餐</label
                >
              </div>
              <div class="custom-control custom-checkbox" v-else>
                <input
                  type="checkbox"
                  id="hasLineOrder"
                  class="custom-control-input"
                  v-model="form.HasLineOrder"
                  disabled
                />
                <label
                  class="custom-control-label ushowform-checkbox-label"
                  for="hasLineOrder"
                  >LINE點餐</label
                >
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group ushowform-group">
              <div class="custom-control custom-checkbox" v-if="isEdit">
                <input
                  type="checkbox"
                  id="hasOcard"
                  class="custom-control-input"
                  v-model="form.HasOcard"
                />
                <label
                  class="custom-control-label ushowform-checkbox-label"
                  for="hasOcard"
                  >Ocard</label
                >
              </div>
              <div class="custom-control custom-checkbox" v-else>
                <input
                  type="checkbox"
                  id="hasOcard"
                  class="custom-control-input"
                  v-model="form.HasOcard"
                  disabled
                />
                <label
                  class="custom-control-label ushowform-checkbox-label"
                  for="hasOcard"
                  >Ocard</label
                >
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group ushowform-group">
              <div class="custom-control custom-checkbox" v-if="isEdit">
                <input
                  type="checkbox"
                  id="hasUbereats"
                  class="custom-control-input"
                  v-model="form.HasUbereats"
                />
                <label
                  class="custom-control-label ushowform-checkbox-label"
                  for="hasUbereats"
                  >Uber Eats</label
                >
              </div>
              <div class="custom-control custom-checkbox" v-else>
                <input
                  type="checkbox"
                  id="hasUbereats"
                  class="custom-control-input"
                  v-model="form.HasUbereats"
                  disabled
                />
                <label
                  class="custom-control-label ushowform-checkbox-label"
                  for="hasUbereats"
                  >Uber Eats</label
                >
              </div>
            </div>
          </div>
          <div class="col-md-12" v-if="form.ExtensionMemo || isEdit">
            <div class="form-group ushowform-group">
              <label class="ushowform-label" for="extensionMemo">備註</label>
              <input
                v-if="isEdit"
                type="text"
                id="extensionMemo"
                class="form-control ushowform-control"
                v-model="form.ExtensionMemo"
              />
              <span v-else class="ushowform-review-value">{{
                form.ExtensionMemo
              }}</span>
            </div>
          </div>

          <div class="col-md-12 ushowform-inquiry-title">
            <div class="form-group ushowform-group">
              <label class="ushowform-label">· 使用期間 (多選)</label>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group ushowform-group">
              <div class="custom-control custom-checkbox" v-if="isEdit">
                <input
                  type="checkbox"
                  id="isBuyOut"
                  class="custom-control-input"
                  v-model="form.IsBuyOut"
                />
                <label
                  class="custom-control-label ushowform-checkbox-label"
                  for="isBuyOut"
                  >買斷</label
                >
              </div>
              <div class="custom-control custom-checkbox" v-else>
                <input
                  type="checkbox"
                  id="isBuyOut"
                  class="custom-control-input"
                  v-model="form.IsBuyOut"
                  disabled
                />
                <label
                  class="custom-control-label ushowform-checkbox-label"
                  for="isBuyOut"
                  >買斷</label
                >
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group ushowform-group">
              <div class="custom-control custom-checkbox" v-if="isEdit">
                <input
                  type="checkbox"
                  id="isLongTermLease"
                  class="custom-control-input"
                  v-model="form.IsLongTermLease"
                />
                <label
                  class="custom-control-label ushowform-checkbox-label"
                  for="isLongTermLease"
                  >長期租賃</label
                >
              </div>
              <div class="custom-control custom-checkbox" v-else>
                <input
                  type="checkbox"
                  id="isLongTermLease"
                  class="custom-control-input"
                  v-model="form.IsLongTermLease"
                  disabled
                />
                <label
                  class="custom-control-label ushowform-checkbox-label"
                  for="isLongTermLease"
                  >長期租賃</label
                >
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group ushowform-group">
              <div class="custom-control custom-checkbox" v-if="isEdit">
                <input
                  type="checkbox"
                  id="isShortTermLease"
                  class="custom-control-input"
                  v-model="form.IsShortTermLease"
                />
                <label
                  class="custom-control-label ushowform-checkbox-label"
                  for="isShortTermLease"
                  >短期租賃</label
                >
              </div>
              <div class="custom-control custom-checkbox" v-else>
                <input
                  type="checkbox"
                  id="isShortTermLease"
                  class="custom-control-input"
                  v-model="form.IsShortTermLease"
                  disabled
                />
                <label
                  class="custom-control-label ushowform-checkbox-label"
                  for="isShortTermLease"
                  >短期租賃</label
                >
              </div>
            </div>
          </div>
          <div class="col-md-12" v-if="form.TermLeaseMemo || isEdit">
            <div class="form-group ushowform-group">
              <label class="ushowform-label" for="termLeaseMemo">備註</label>
              <input
                v-if="isEdit"
                type="text"
                id="termLeaseMemo"
                class="form-control ushowform-control"
                v-model="form.TermLeaseMemo"
              />
              <span v-else class="ushowform-review-value">{{
                form.TermLeaseMemo
              }}</span>
            </div>
          </div>
          <div
            class="col-md-12 ushowform-inquiry-title ushowform-inquiry-bottom"
          >
            <div class="form-group ushowform-group">
              <label class="ushowform-label" for="eInvType"
                >· 每月電子發票開立張數</label
              >
              <select
                v-if="isEdit"
                class="form-control ushowform-control"
                id="eInvType"
                v-model="form.EInvType"
              >
                <option
                  v-for="eInvType in eInvTypes"
                  :key="eInvType.value"
                  :value="eInvType.value"
                >
                  {{ eInvType.text }}
                </option>
              </select>
              <span
                v-else-if="!isEdit && form.EInvType >= 0"
                class="ushowform-review-value"
                >{{ this.$enum.eInvType.getByValue(form.EInvType).text }}</span
              >
              <span v-else class="ushowform-review-value"></span>
            </div>
          </div>
        </div>
      </div>
    </modal-part>
  </div>
</template>

<script>
import modalPart from "@/components/parts/modalPart";

export default {
  data() {
    return {
      salerTypes: this.$enum.salerType.getAll(),
      sourceTypes: this.$enum.sourceType.getAll(),
      businessTypes: this.$enum.businessType.getAll(),
      eInvTypes: this.$enum.eInvType.getAll(),
      form: {},
    };
  },
  props: ["item", "isShow", "isEdit"],
  components: {
    modalPart,
  },
  watch: {
    isShow(value) {
      if (value) {
        this.form = this.item;
      }
    },
  },
  methods: {
    onSaved() {
      const vm = this;
      this.$api.ushow
        .put(vm.form.InquiryId, vm.form)
        .then(() => {
          vm.$emit("onSaved");
        })
        .catch((error) => vm.$func.message.error(error.data.Message));
    },
    onClosed() {
      this.$emit("onClosed");
    },
  },
};
</script>
