import { httpGet, httpPost, httpDelete } from "@/request/http";
import func from "@/funcs";

let path = "/sals";

export default {
  get(id) {
    return httpGet(`${path}/${id}`);
  },
  getPages(params) {
    return httpGet(`${path}/pages`, params);
  },
  post(item) {
    return httpPost(path, item);
  },
  delete(id) {
    return httpDelete(`${path}/${id}`);
  },
  getNoPays(id) {
    return httpGet(`/customers/${id}/sals/nopay`);
  },
  print(id) {
    func.printPdf.get(`${path}/${id}/report`);
  },
  elecInvoice(item) {
    return httpPost(`${path}/elecinvoice`, item);
  },
  elecInvoiceVoid(item) {
    return httpPost(`${path}/elecinvoice/void`, item);
  },
  printElecInvoice(id, type) {
    func.printPdf.get(`${path}/${id}/elecinvoice/b2c/${type}/print`);
  },
  printElecInvoiceDetail(id) {
    func.printPdf.get(`${path}/${id}/elecinvoice/b2c/detail/print`);
  },
  printElecInvoiceB2B(id, type) {
    func.printPdf.get(`${path}/${id}/elecinvoice/b2b/${type}/print`);
  },
  emailElecInvoice(item) {
    return httpPost(`${path}/elecinvoice/email`, item);
  },
  getExcel() {
    func.getExcel.get(`${path}/foodexcel/example`, "匯入銷貨單商品");
  },
  getExcelByPost(foods) {
    func.getExcel.post(`${path}/exportfood`, "匯入銷貨單商品", foods);
  },
  postExcel(formData) {
    return func.postExcel(`${path}/importfood`, formData);
  },
  getSalExcel() {
    func.getExcel.get(`${path}/excel/example`, "銷貨單");
  },
  postSalExcel(formData) {
    return func.postExcel(`${path}/excel`, formData);
  },
  getImportData(params) {
    return httpGet(`/saleimportlogs/pages`, params);
  },
  getImportDetail(id) {
    return httpGet(`/saleimportlogs/${id}/unfinished`);
  },
};
