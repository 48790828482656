<template>
  <div>
    <search-and-table-part
      :pagination="pagination"
      @getItems="getFoods"
      @addItem="addFood"
      @editItem="editFood"
      @removeItem="removeFood"
      :items="foods"
      :columns="columns"
      :idProperty="idProperty"
      :nameProperty="nameProperty"
      :getTrigger="getTrigger"
      :hasAdd="authority.IsAdd && !hasShowmore && !hasDWAPI"
      :hasEdit="authority.IsUpdate && !hasShowmore && !hasDWAPI"
      :hasRemove="authority.IsRemove && !hasShowmore && !hasDWAPI"
      @trdbClicked="editFood"
      :isSortable="true"
    >
      <th
        v-if="hasBatchNumber"
        class="text-center"
        slot="th-after"
        style="width: 120px"
      >
        {{ $t("columnName.hasbatchnumber") }}
      </th>
      <td
        v-if="hasBatchNumber"
        class="text-center"
        slot="td-after"
        slot-scope="props"
      >
        <span v-if="props.item.HasBatchNumber">V</span>
      </td>
      <template slot="searchArea">
        <div class="row">
          <div class="col-lg-6">
            <div class="form-group row">
              <label
                for="searchFoodKindNumber"
                class="col-3 col-md-2 col-lg-3 col-form-label"
                >{{ $t("searchAndTablePart.foodkindnumber") }}:</label
              >
              <div class="col">
                <input
                  type="text"
                  class="form-control"
                  id="searchFoodKindNumber"
                  v-model="searchDTO.FoodKindNumber"
                />
              </div>
            </div>
          </div>
          <div class="col-lg-6">
            <div class="form-group row">
              <label
                for="searchFoodKindName"
                class="col-3 col-md-2 col-lg-3 col-form-label"
                >{{ $t("searchAndTablePart.foodkindname") }}:</label
              >
              <div class="col">
                <input
                  type="text"
                  class="form-control"
                  id="searchFoodKindName"
                  v-model="searchDTO.FoodKindName"
                />
              </div>
            </div>
          </div>
          <div class="col-lg-6">
            <div class="form-group row">
              <label
                for="searchFoodNumber"
                class="col-3 col-md-2 col-lg-3 col-form-label"
                >{{ $t("searchAndTablePart.foodnumber") }}:</label
              >
              <div class="col">
                <input
                  type="text"
                  class="form-control"
                  id="searchFoodNumber"
                  v-model="searchDTO.FoodNumber"
                />
              </div>
            </div>
          </div>
          <div class="col-lg-6">
            <div class="form-group row">
              <label
                for="searchFoodName"
                class="col-3 col-md-2 col-lg-3 col-form-label"
                >{{ $t("searchAndTablePart.foodname") }}:</label
              >
              <div class="col">
                <input
                  type="text"
                  class="form-control"
                  id="searchFoodName"
                  v-model="searchDTO.FoodName"
                />
              </div>
            </div>
          </div>
          <div class="col-lg-6">
            <div class="form-group row">
              <label
                for="searchBarcode"
                class="col-3 col-md-2 col-lg-3 col-form-label"
                >{{ $t("searchAndTablePart.barcode") }}:</label
              >
              <div class="col">
                <input
                  type="text"
                  class="form-control"
                  id="searchBarcode"
                  v-model="searchDTO.Barcode"
                />
              </div>
            </div>
          </div>
          <div class="col-lg-6">
            <input-inline-part
              id="searchTaxType"
              :labelText="`${$t('searchAndTablePart.taxtype')}:`"
              :degree="6"
            >
              <select
                id="searchTaxType"
                class="form-control"
                v-model="searchDTO.TaxType"
              >
                <option
                  v-for="taxType in searchTaxTypes"
                  :key="taxType.value"
                  :value="taxType.value"
                >
                  {{ taxType.text }}
                </option>
              </select>
            </input-inline-part>
          </div>
        </div>
      </template>

      <template slot="searchButtonArea">
        <import-part
          v-if="authority.IsAdd && !hasShowmore && !hasDWAPI"
          @onImport="onImport"
          :title="`${$t('modal.importfood')}`"
        />
        <export-part
          @onExport="onExport"
          v-if="erpType != 2 && !hasShowmore && !hasDWAPI"
        />
        <button
          v-if="hasShowmore"
          class="btn btn-showmore btn-icon"
          @click="postShowmore()"
        >
          <svg class="svg-inline--fa" viewBox="-32 0 160 170">
            <path
              class="cls-2"
              d="M69.28,1.88L7.1,37.78c-.16,.09-.28,.22-.43,.31-.59,.36-1.15,.76-1.68,1.21-.15,.13-.29,.27-.44,.41-.55,.5-1.05,1.05-1.52,1.64-.08,.11-.15,.21-.23,.32-.31,.42-.65,.81-.91,1.27-3.87,6.71-1.57,15.29,5.13,19.16l41.13,23.75L7.04,109.59c-.33,.19-.61,.45-.92,.66-.27,.18-.53,.36-.79,.57-.5,.4-.95,.82-1.38,1.27-.19,.2-.39,.4-.57,.62-.44,.52-.83,1.07-1.18,1.65-.09,.14-.2,.25-.28,.39-.05,.08-.07,.17-.12,.26-.32,.58-.58,1.19-.81,1.81-.1,.27-.21,.53-.3,.8-.18,.57-.29,1.15-.39,1.74-.06,.32-.13,.64-.16,.96-.06,.56-.05,1.11-.04,1.67,0,.35,0,.69,.03,1.04,.05,.52,.15,1.03,.26,1.55,.08,.39,.15,.77,.26,1.15,.13,.44,.31,.86,.48,1.29,.18,.45,.37,.9,.6,1.34,.07,.13,.11,.28,.18,.41,.13,.23,.31,.4,.45,.62,.29,.45,.59,.88,.94,1.3,.26,.32,.54,.6,.82,.89,.36,.36,.72,.71,1.12,1.04,.33,.27,.68,.51,1.04,.75,.27,.18,.5,.4,.78,.56l62.18,35.9c6.71,3.87,15.29,1.57,19.16-5.13s1.57-15.29-5.13-19.16l-41.17-23.77,41.15-23.76c.22-.13,.39-.29,.6-.43,.43-.28,.85-.57,1.26-.91,.37-.3,.71-.63,1.04-.96,.29-.29,.57-.58,.83-.9,.38-.45,.71-.93,1.02-1.41,.11-.18,.25-.32,.36-.5,.06-.11,.09-.22,.15-.33,.3-.56,.55-1.13,.78-1.73,.1-.27,.22-.54,.3-.82,.19-.61,.32-1.24,.42-1.87,.04-.25,.1-.5,.13-.76,.08-.68,.08-1.37,.05-2.06,0-.2,0-.39-.02-.59-.06-.71-.19-1.41-.35-2.12-.04-.18-.07-.35-.12-.53-.18-.66-.44-1.29-.72-1.93-.1-.22-.18-.44-.29-.65-.11-.22-.18-.46-.3-.67h0c-.22-.37-.5-.68-.74-1.03-.14-.21-.28-.41-.44-.61-.44-.56-.91-1.06-1.42-1.54-.15-.14-.29-.28-.45-.42-.58-.5-1.19-.93-1.84-1.32-.1-.06-.17-.14-.27-.2l-.82-.47h-.02l-40.29-23.27,41.21-23.79c6.71-3.87,9.01-12.45,5.13-19.16-3.87-6.71-12.45-9.01-19.16-5.13Z"
            ></path>
            <path
              class="cls-1"
              d="M48.17,85.86L7.06,109.6c-.34,.19-.61,.45-.92,.66-.27,.18-.53,.36-.79,.57-.5,.4-.95,.82-1.38,1.27-.19,.2-.39,.4-.57,.62-.44,.52-.83,1.07-1.19,1.65-.09,.14-.2,.25-.28,.39-.05,.08-.07,.17-.12,.26-.32,.58-.58,1.19-.81,1.81-.1,.27-.21,.53-.3,.8-.18,.57-.29,1.15-.39,1.74-.06,.32-.13,.64-.16,.96-.06,.56-.05,1.11-.04,1.68,0,.35,0,.69,.03,1.03,.05,.52,.15,1.04,.26,1.55,.08,.39,.15,.77,.26,1.15,.13,.44,.31,.86,.48,1.29,.18,.46,.37,.9,.6,1.34,.07,.13,.11,.28,.18,.41,.13,.23,.31,.4,.45,.62,.29,.45,.59,.88,.93,1.3,.26,.32,.54,.6,.82,.89,.36,.36,.72,.71,1.12,1.04,.33,.27,.68,.51,1.04,.75,.27,.18,.5,.4,.78,.56l40.69,23.5s-26.54-23.67-5.65-35.66l41.15-23.76c.22-.12,.39-.29,.59-.43,.43-.28,.86-.57,1.26-.91,.37-.3,.71-.63,1.04-.96,.29-.29,.57-.58,.83-.9,.38-.45,.71-.93,1.02-1.42,.11-.17,.25-.32,.36-.5,.06-.11,.09-.22,.15-.33,.3-.56,.55-1.14,.78-1.73,.1-.27,.22-.54,.3-.81,.19-.61,.32-1.24,.42-1.88,.04-.25,.1-.5,.13-.75,.08-.68,.08-1.37,.05-2.06,0-.2,0-.39-.02-.58-.06-.71-.19-1.42-.36-2.12-.04-.17-.07-.35-.12-.52-.18-.66-.44-1.3-.72-1.93-.1-.22-.18-.43-.29-.65-.11-.22-.18-.46-.3-.68-.22-.37-.5-.68-.74-1.03-.14-.21-.28-.41-.44-.61-.44-.56-.92-1.06-1.42-1.54-.15-.14-.29-.28-.45-.42-.58-.5-1.2-.93-1.84-1.33-.1-.06-.17-.14-.27-.19l-.81-.47s-.02-.01-.03-.02l-40.29-23.26c-20.22,11.77,6.05,35.89,6.05,35.89Z"
            ></path>
          </svg>
        </button>
        <button
          v-if="hasDWAPI"
          class="btn btn-digiwin btn-icon"
          @click="postDigiwin()"
        >
          鼎
          <p>新</p>
        </button>
      </template>
      <template slot="dataButtonArea" slot-scope="props">
        <button
          class="btn btn-outline-secondary btn-icon"
          @click="onImage(props.index)"
        >
          <i class="far fa-image"></i>
        </button>
        <button
          v-if="hasSpecification"
          class="btn btn-outline-success btn-icon"
          @click="setSpecification(props.index)"
        >
          <i class="fas fa-tshirt"></i>
        </button>
        <button
          v-if="hasBrand"
          class="btn btn-outline-primary btn-icon"
          @click="setBrand(props.index)"
        >
          <i class="fas fa-tags"></i>
        </button>
      </template>
    </search-and-table-part>

    <data-modal-part
      :displayTrigger="modalDisplayTrigger"
      @onClosed="
        modalDisplayTrigger = false;
        isEdit = false;
      "
      @onSaved="saveFood"
      :title="modalTitle"
      modalClass="modal-lg"
    >
      <div slot="fieldArea">
        <div class="row">
          <ul
            v-if="isMultiLanguage"
            class="col-12 nav nav-tabs my-2 px-1"
            id="myLangTab"
            role="tablist"
          >
            <li
              v-if="isMultiLanguage"
              class="nav-item"
              @click="currentSettingLang(0)"
            >
              <a
                class="nav-link lang-tab show active"
                data-toggle="tab"
                href="#chineseTab"
                role="tab"
                aria-controls="chineseTab"
                aria-selected="true"
                >{{ $t("dataModalPart.chinese") }}</a
              >
            </li>
            <li
              v-if="hasEnglish"
              class="nav-item"
              @click="currentSettingLang(1)"
            >
              <a
                class="nav-link lang-tab"
                data-toggle="tab"
                href="#englishTab"
                role="tab"
                aria-controls="englishTab"
                aria-selected="false"
                >{{ $t("dataModalPart.english") }}</a
              >
            </li>
            <li
              v-if="hasJapanese"
              class="nav-item"
              @click="currentSettingLang(2)"
            >
              <a
                class="nav-link lang-tab"
                data-toggle="tab"
                href="#japaneseTab"
                role="tab"
                aria-controls="japaneseTab"
                aria-selected="false"
                >{{ $t("dataModalPart.japanese") }}</a
              >
            </li>
            <li
              v-if="hasKorean"
              class="nav-item"
              @click="currentSettingLang(3)"
            >
              <a
                class="nav-link lang-tab"
                data-toggle="tab"
                href="#koreanTab"
                role="tab"
                aria-controls="koreanTab"
                aria-selected="false"
                >{{ $t("dataModalPart.korean") }}</a
              >
            </li>
            <li
              v-if="hasFrench"
              class="nav-item"
              @click="currentSettingLang(4)"
            >
              <a
                class="nav-link lang-tab"
                data-toggle="tab"
                href="#frenchTab"
                role="tab"
                aria-controls="frenchTab"
                aria-selected="false"
                >{{ $t("dataModalPart.french") }}</a
              >
            </li>
          </ul>
          <div class="col-md-6 col-lg-4 form-group">
            <label for="foodKindId">{{ $t("dataModalPart.foodkindid") }}</label>
            <select
              class="form-control"
              id="foodKindId"
              v-model="food.FoodKindId"
              required
            >
              <option
                v-for="foodKind in foodKinds"
                :value="foodKind.FoodKindId"
                :key="foodKind.FoodKindId"
              >
                {{ foodKind.FoodKindNumber }} -
                {{ foodKind.FoodKindName }}
              </option>
            </select>
          </div>
          <div class="col-md-6 col-lg-4 form-group">
            <label for="foodNumber">{{ $t("dataModalPart.foodnumber") }}</label>
            <input
              type="text"
              class="form-control"
              id="foodNumber"
              v-model="food.FoodNumber"
              required
            />
          </div>
          <div class="col-md-6 col-lg-4 form-group">
            <label for="foodName">{{ $t("dataModalPart.foodname") }}</label>
            <input
              type="text"
              class="form-control"
              id="foodName"
              v-model="settingLang.FoodName"
              :required="currentLangValue == 0"
            />
          </div>
          <div class="col-md-6 col-lg-4 form-group" v-if="isShow">
            <label for="foodName2">{{ $t("dataModalPart.foodname2") }}</label>
            <input
              type="text"
              class="form-control"
              id="foodName2"
              v-model="food.FoodName2"
            />
          </div>
          <div
            class="col-md-6 col-lg-4 form-group"
            v-if="isShow || productType == 1 || productType == 4"
          >
            <label for="barcode"
              >{{ $t("dataModalPart.barcode") }}
              <span v-if="isBarcodeExist" style="color: red">
                ({{ $t("dataModalPart.isbarcodeexist") }})
              </span></label
            >
            <input
              type="text"
              class="form-control"
              id="barcode"
              v-model="food.Barcode"
              @focusout="checkBarcode"
              @keydown.enter.prevent
            />
          </div>
          <div class="col-md-6 col-lg-4 form-group" v-if="isShow">
            <label for="unit">{{ $t("dataModalPart.unit") }}</label>
            <input
              type="text"
              class="form-control"
              id="unit"
              v-model="settingLang.Unit"
            />
          </div>
          <div class="col-md-6 col-lg-4 form-group">
            <label for="salPrice1">{{ $t("dataModalPart.salprice1") }}</label>
            <input
              type="number"
              class="form-control"
              id="salPrice1"
              v-model.number="food.SalPrice1"
              step="0.001"
              required
            />
          </div>
          <div class="col-md-6 col-lg-4 form-group" v-if="isShow">
            <label for="salPrice2">{{ $t("dataModalPart.salprice2") }}</label>
            <input
              type="number"
              class="form-control"
              id="salPrice2"
              v-model.number="food.SalPrice2"
              step="0.001"
            />
          </div>
          <div class="col-md-6 col-lg-4 form-group" v-if="isShow">
            <label for="salPrice3">{{ $t("dataModalPart.salprice3") }}</label>
            <input
              type="number"
              class="form-control"
              id="salPrice3"
              v-model.number="food.SalPrice3"
              step="0.001"
            />
          </div>
          <div class="col-md-6 col-lg-4 form-group" v-if="isShow">
            <label for="salPrice4">{{ $t("dataModalPart.salprice4") }}</label>
            <input
              type="number"
              class="form-control"
              id="salPrice4"
              v-model.number="food.SalPrice4"
              step="0.001"
            />
          </div>
          <div class="col-md-6 col-lg-4 form-group" v-if="isShow">
            <label for="salPrice5">{{ $t("dataModalPart.salprice5") }}</label>
            <input
              type="number"
              class="form-control"
              id="salPrice5"
              v-model.number="food.SalPrice5"
              step="0.001"
            />
          </div>
          <div class="col-md-6 col-lg-4 form-group" v-if="isShow">
            <label for="salPrice6">{{ $t("dataModalPart.salprice6") }}</label>
            <input
              type="number"
              class="form-control"
              id="salPrice6"
              v-model.number="food.SalPrice6"
              step="0.001"
            />
          </div>
          <div class="col-md-6 col-lg-4 form-group">
            <label for="cost">{{ $t("dataModalPart.cost") }}</label>
            <input
              type="number"
              class="form-control"
              id="cost"
              v-model.number="food.Cost"
              step="0.001"
            />
          </div>

          <div class="col-md-6 col-lg-4 form-group" v-if="!isUshowWeb">
            <label for="taxType">{{ $t("dataModalPart.taxtype") }}</label>
            <select
              id="taxType"
              class="form-control"
              required
              v-model="food.TaxType"
            >
              <option
                v-for="taxType in taxTypes"
                :key="taxType.value"
                :value="taxType.value"
              >
                {{ taxType.text }}
              </option>
            </select>
          </div>
          <div class="col-md-6 col-lg-4 form-group" v-if="hasMyCard">
            <label for="myCardId">MyCard ID</label>
            <input
              type="text"
              class="form-control"
              id="myCardId"
              v-model="food.MyCardId"
            />
          </div>

          <div class="col-12 form-group">
            <label for="memo">{{ $t("dataModalPart.memo") }}</label>
            <input
              type="text"
              class="form-control"
              id="memo"
              v-model="settingLang.Memo"
            />
          </div>

          <div class="col-12 row">
            <div class="col-6 col-md-4 col-lg-2" v-if="isShow">
              <div class="custom-control custom-checkbox">
                <input
                  type="checkbox"
                  class="custom-control-input"
                  id="isScale"
                  v-model="food.IsScale"
                />
                <label class="custom-control-label" for="isScale">{{
                  $t("dataModalPart.isscale")
                }}</label>
              </div>
            </div>
            <div class="col-6 col-md-4 col-lg-2" v-if="productType != 3">
              <div class="custom-control custom-checkbox">
                <input
                  type="checkbox"
                  class="custom-control-input"
                  id="noDiscount"
                  v-model="food.NoDiscount"
                />
                <label class="custom-control-label" for="noDiscount">{{
                  $t("dataModalPart.nodiscount")
                }}</label>
              </div>
            </div>
            <div class="col-6 col-md-4 col-lg-2">
              <div class="custom-control custom-checkbox">
                <input
                  type="checkbox"
                  class="custom-control-input"
                  id="isStopSale"
                  v-model="food.IsStopSale"
                />
                <label class="custom-control-label" for="isStopSale">{{
                  $t("dataModalPart.isstopsale")
                }}</label>
              </div>
            </div>
            <div class="col-6 col-md-4 col-lg-2" v-if="isShow">
              <div class="custom-control custom-checkbox">
                <input
                  type="checkbox"
                  class="custom-control-input"
                  id="noService"
                  v-model="food.NoService"
                />
                <label class="custom-control-label" for="noService">{{
                  $t("dataModalPart.noservice")
                }}</label>
              </div>
            </div>
            <div class="col-6 col-md-4 col-lg-3" v-if="hasSpecification">
              <div class="custom-control custom-checkbox">
                <input
                  type="checkbox"
                  class="custom-control-input"
                  id="isMultiSpecification"
                  v-model="food.IsMultiSpecification"
                />
                <label
                  class="custom-control-label"
                  for="isMultiSpecification"
                  >{{ $t("dataModalPart.isMultiSpecification") }}</label
                >
              </div>
            </div>
          </div>

          <ul
            class="col-12 nav nav-tabs mt-2 px-1"
            id="myTab"
            role="tablist"
            v-if="isShow || isERP"
          >
            <li class="nav-item" v-if="isShow || isUshowLite">
              <a
                class="nav-link advanced-setting"
                :class="{ 'show active': isShow || isUshowLite }"
                data-toggle="tab"
                href="#colorSetting"
                role="tab"
                aria-controls="colorSetting"
                aria-selected="false"
                >{{ $t("dataModalPart.color") }}</a
              >
            </li>
            <li class="nav-item" v-if="isShow">
              <a
                class="nav-link advanced-setting"
                data-toggle="tab"
                href="#foodSetting"
                role="tab"
                aria-controls="foodSetting"
                aria-selected="false"
                >{{ $t("dataModalPart.foodsetting") }}</a
              >
            </li>
            <li class="nav-item" v-if="isShow">
              <a
                class="nav-link advanced-setting"
                data-toggle="tab"
                href="#otherSetting"
                role="tab"
                aria-controls="otherSetting"
                aria-selected="false"
                >{{ $t("dataModalPart.othersetting") }}</a
              >
            </li>
            <li class="nav-item" v-if="isERP">
              <a
                class="nav-link advanced-setting"
                :class="{ 'show active': !isShow & !isUshowLite & isERP }"
                data-toggle="tab"
                href="#erpSetting"
                role="tab"
                aria-controls="erpSetting"
                aria-selected="false"
                >{{ $t("dataModalPart.erpsetting") }}</a
              >
            </li>
            <li class="nav-item" v-if="isShow || isCustomer">
              <a
                class="nav-link advanced-setting"
                data-toggle="tab"
                href="#memberSetting"
                role="tab"
                aria-controls="memberSetting"
                aria-selected="false"
                >{{ $t("dataModalPart.membersetting") }}</a
              >
            </li>
            <li class="nav-item" v-if="isMultiSpecification">
              <a
                class="nav-link advanced-setting"
                data-toggle="tab"
                href="#multiSpecification"
                role="tab"
                aria-controls="multiSpecification"
                aria-selected="false"
                >{{ $t("dataModalPart.multispecification") }}</a
              >
            </li>
          </ul>
          <div class="col-12 tab-content mt-2" id="myTabContent">
            <div
              class="tab-pane fade advanced-content"
              :class="{ 'show active': isShow || isUshowLite }"
              id="colorSetting"
              role="tabpanel"
              aria-labelledby="profile-tab"
              v-if="isShow || isUshowLite"
            >
              <div class="row">
                <div class="col-md-6 col-lg-4 form-group">
                  <label for="fontSize">{{
                    $t("dataModalPart.fontsize")
                  }}</label>
                  <input
                    type="number"
                    class="form-control"
                    id="fontSize"
                    v-model.number="food.FontSize"
                    required
                  />
                </div>
                <div class="col-md-6 col-lg-4 form-group">
                  <label for="foreColor">{{
                    $t("dataModalPart.forecolor")
                  }}</label>
                  <input
                    type="color"
                    class="form-control"
                    id="foreColor"
                    v-model="food.ForeColor"
                  />
                </div>
                <div class="col-md-6 col-lg-4 form-group">
                  <label for="backColor">{{
                    $t("dataModalPart.backcolor")
                  }}</label>
                  <input
                    type="color"
                    class="form-control"
                    id="backColor"
                    v-model="food.BackColor"
                  />
                </div>
              </div>
            </div>
            <div
              class="tab-pane fade advanced-content"
              id="foodSetting"
              role="tabpanel"
              aria-labelledby="profile-tab"
              v-if="isShow"
            >
              <div class="row">
                <div class="col-12 form-group">
                  <label for="ingredient">{{
                    $t("dataModalPart.ingredient")
                  }}</label>
                  <input
                    type="text"
                    class="form-control"
                    id="ingredient"
                    v-model="settingLang.Ingredient"
                  />
                </div>
                <div class="col-12 form-group">
                  <label for="storage">{{ $t("dataModalPart.storage") }}</label>
                  <input
                    type="text"
                    class="form-control"
                    id="storage"
                    v-model="settingLang.Storage"
                  />
                </div>
                <div class="col-12 form-group">
                  <label for="origin">{{ $t("dataModalPart.origin") }}</label>
                  <input
                    type="text"
                    class="form-control"
                    id="origin"
                    v-model="settingLang.Origin"
                  />
                </div>
              </div>
            </div>
            <div
              class="tab-pane fade advanced-content"
              id="otherSetting"
              role="tabpanel"
              aria-labelledby="contact-tab"
              v-if="isShow"
            >
              <div class="row">
                <div class="col-12 form-group">
                  <label for="memo2">{{ $t("dataModalPart.memo2") }}</label>
                  <input
                    type="text"
                    class="form-control"
                    id="memo2"
                    v-model="food.Memo2"
                  />
                </div>
                <div class="col-12 form-group">
                  <label for="memo3">{{ $t("dataModalPart.memo3") }}</label>
                  <input
                    type="text"
                    class="form-control"
                    id="memo3"
                    v-model="food.Memo3"
                  />
                </div>
                <div class="col-12 col-md-6 form-group">
                  <label for="barcode2">{{
                    $t("dataModalPart.barcode2")
                  }}</label>
                  <input
                    type="text"
                    class="form-control"
                    id="barcode2"
                    v-model="food.Barcode2"
                  />
                </div>
                <div class="col-12 col-md-6 form-group">
                  <label for="barcode3">{{
                    $t("dataModalPart.barcode3")
                  }}</label>
                  <input
                    type="text"
                    class="form-control"
                    id="barcode3"
                    v-model="food.Barcode3"
                  />
                </div>
              </div>
            </div>
            <div
              class="tab-pane fade advanced-content"
              :class="{ 'show active': !isShow & !isUshowLite & isERP }"
              id="erpSetting"
              role="tabpanel"
              aria-labelledby="contact-tab"
              v-if="isERP"
            >
              <div class="row">
                <div class="col-md-6 col-lg-4 form-group">
                  <label for="supplierId">{{
                    $t("dataModalPart.supplierid")
                  }}</label>
                  <select
                    class="form-control"
                    id="supplierId"
                    v-model="food.SupplierId"
                  >
                    <option
                      v-for="supplier in suppliers"
                      :value="supplier.SupplierId"
                      :key="supplier.SupplierId"
                    >
                      {{ supplier.SupplierNumber }} -
                      {{ supplier.SupplierName }}
                    </option>
                  </select>
                </div>
                <div class="col-md-6 col-lg-4 form-group">
                  <label for="purchaseQty">{{
                    $t("dataModalPart.purchaseqty")
                  }}</label>
                  <input
                    type="number"
                    class="form-control"
                    id="purchaseQty"
                    v-model.number="food.PurchaseQty"
                    step="0.01"
                  />
                </div>
                <div class="col-md-6 col-lg-4 form-group">
                  <label for="purchaseFoodId"
                    >{{ $t("dataModalPart.purchasefoodid") }}:</label
                  >
                  <input
                    type="text"
                    class="form-control"
                    id="purchaseFoodId"
                    v-model="purchaseFood.FoodNumber"
                    :placeholder="`${$t('placeholder.inputfoodnumber')}`"
                    @dblclick="foodSelectTrigger = !foodSelectTrigger"
                    @change.prevent="purchaseFoodChangeEvent"
                  />
                </div>
                <div class="col-md-6 col-lg-4 form-group">
                  <label for="foodStockType">{{
                    $t("dataModalPart.foodstocktype")
                  }}</label>
                  <select
                    class="form-control"
                    id="foodStockType"
                    v-model="food.FoodStockType"
                  >
                    <option
                      v-for="foodStockType in foodStockTypes"
                      :value="foodStockType.value"
                      :key="foodStockType.value"
                    >
                      {{ foodStockType.text }}
                    </option>
                  </select>
                </div>
                <div class="col-12 row">
                  <div class="col-md-6 col-lg-3">
                    <div class="custom-control custom-checkbox">
                      <input
                        type="checkbox"
                        class="custom-control-input"
                        id="isStock"
                        v-model="food.IsStock"
                      />
                      <label class="custom-control-label" for="isStock">{{
                        $t("dataModalPart.isstock")
                      }}</label>
                    </div>
                  </div>
                  <div class="col-md-6 col-lg-3" v-if="isShowAutoCombination">
                    <div class="custom-control custom-checkbox">
                      <input
                        type="checkbox"
                        class="custom-control-input"
                        id="isSaleAutoCombination"
                        v-model="food.IsSaleAutoCombination"
                      />
                      <label
                        class="custom-control-label"
                        for="isSaleAutoCombination"
                        >{{ $t("dataModalPart.issaleautocombination") }}</label
                      >
                    </div>
                  </div>
                  <div class="col-md-6 col-lg-3" v-if="hasBatchNumber">
                    <div class="custom-control custom-checkbox">
                      <input
                        type="checkbox"
                        class="custom-control-input"
                        id="hasBatchNumber"
                        v-model="food.HasBatchNumber"
                        :disabled="isEdit"
                      />
                      <label
                        class="custom-control-label"
                        for="hasBatchNumber"
                        >{{ $t("dataModalPart.hasbatchnumber") }}</label
                      >
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="tab-pane fade advanced-content"
              :class="{ 'show active': !isShow & isCustomer }"
              id="memberSetting"
              role="tabpanel"
              aria-labelledby="contact-tab"
              v-if="isShow || isCustomer"
            >
              <div class="row">
                <div class="col-12 row">
                  <div
                    class="col-md-6 col-lg-4 form-group"
                    v-if="isShow || isCustomer"
                  >
                    <label for="integralGet">{{
                      $t("dataModalPart.integralget")
                    }}</label>
                    <input
                      type="number"
                      class="form-control"
                      id="integralGet"
                      v-model="food.IntegralGet"
                    />
                  </div>
                  <div
                    class="col-md-6 col-lg-4 form-group"
                    v-if="isShow || isCustomer"
                  >
                    <label for="integralFree">{{
                      $t("dataModalPart.integralfree")
                    }}</label>
                    <input
                      type="number"
                      class="form-control"
                      id="integralFree"
                      v-model="food.IntegralFree"
                    />
                  </div>
                </div>
                <div class="col-12 row">
                  <div
                    class="col-6 col-md-4 col-lg-2"
                    v-if="isShow || isCustomer"
                  >
                    <div class="custom-control custom-checkbox">
                      <input
                        type="checkbox"
                        class="custom-control-input"
                        id="isCustomerStock"
                        v-model="food.IsCustomerStock"
                      />
                      <label
                        class="custom-control-label"
                        for="isCustomerStock"
                        >{{ $t("dataModalPart.iscustomerstock") }}</label
                      >
                    </div>
                  </div>
                  <div class="col-6" v-if="isShow || isCustomer">
                    <div class="custom-control custom-checkbox">
                      <input
                        type="checkbox"
                        class="custom-control-input"
                        id="noGiveIntegral"
                        v-model="food.NoGiveIntegral"
                      />
                      <label
                        class="custom-control-label"
                        for="noGiveIntegral"
                        >{{ $t("dataModalPart.nogiveintegral") }}</label
                      >
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="tab-pane fade advanced-content mt-2"
              :class="{
                'show active':
                  !isShow &&
                  !isUshowLite &&
                  !isCustomer &&
                  !isERP &&
                  isMultiSpecification,
              }"
              id="multiSpecification"
              role="tabpanel"
              aria-labelledby="contact-tab"
              v-if="isMultiSpecification"
            >
              <div class="row">
                <div class="col-12 row">
                  <div class="col-md-6 col-lg-4">
                    <div class="custom-control custom-checkbox">
                      <input
                        type="checkbox"
                        class="custom-control-input"
                        id="isSpecificationSamePromotion"
                        v-model="food.IsSpecificationSamePromotion"
                      />
                      <label
                        class="custom-control-label"
                        for="isSpecificationSamePromotion"
                        >{{
                          $t("dataModalPart.isspecificationsamepromotion")
                        }}</label
                      >
                    </div>
                  </div>
                  <div class="col-md-6 col-lg-4">
                    <div class="custom-control custom-checkbox">
                      <input
                        type="checkbox"
                        class="custom-control-input"
                        id="isSpecificationSameTaste"
                        v-model="food.IsSpecificationSameTaste"
                      />
                      <label
                        class="custom-control-label"
                        for="isSpecificationSameTaste"
                        >{{
                          $t("dataModalPart.isspecificationsametaste")
                        }}</label
                      >
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </data-modal-part>

    <food-image v-if="imageTrigger" :id="foodId" @onClosed="onImageClosed" />
    <food-selects
      :trigger="foodSelectTrigger"
      :isMultiple="false"
      @onSaved="setFood"
    ></food-selects>
  </div>
</template>

<script>
import searchAndTablePart from "@/components/parts/searchAndTablePart";
import dataModalPart from "@/components/parts/dataModalPart";

import importPart from "@/components/ImportPart";
import exportPart from "@/components/ExportPart";
import foodImage from "@/components/datas/FoodImageModal";
import foodSelects from "@/components/selects/FoodSelects";

import inputInlinePart from "@/components/parts/inputInlinePart";

export default {
  data() {
    return {
      searchDTO: {},
      file: "",
      foodKinds: [],
      foods: [],
      suppliers: [],
      food: {},
      purchaseFood: {},
      pagination: {},
      modalDisplayTrigger: false,
      modalTitle: "",
      idProperty: "FoodId",
      nameProperty: "FoodName",
      getTrigger: false,
      taxTypes: [],
      searchTaxTypes: [],
      imageTrigger: false,
      foodId: "",
      foodStockTypes: [],
      isCustomer: window.localStorage["isCustomer"] == "true",
      isERP: window.localStorage["isERP"] == "true",
      erpType: window.localStorage["erpType"],
      hasEnglish: window.localStorage["hasEnglish"] == "true",
      hasJapanese: window.localStorage["hasJapanese"] == "true",
      hasKorean: window.localStorage["hasKorean"] == "true",
      hasFrench: window.localStorage["hasFrench"] == "true",
      isBarcodeExist: false,
      authority: {},
      foodSelectTrigger: false,
      isEdit: false,
      currentLangValue: 0,
      settingLang: {
        LanguageType: null,
        FoodId: "",
        FoodName: "",
        Memo: "",
        Ingredient: "",
        Storage: "",
        Origin: "",
      },
    };
  },
  watch: {
    currentLang() {
      this.setOptions();
    },
    modalDisplayTrigger(value) {
      this.isBarcodeExist = false;
      if (value) {
        if (this.foodKinds.length == 0) this.getFoodKinds();
        if (this.isERP && this.suppliers.length == 0) this.getSuppliers();
        if (this.isMultiLanguage) {
          let langTabs = document.querySelectorAll(".lang-tab");
          let advancedTabs = document.querySelectorAll(".advanced-setting");
          let advancedContents = document.querySelectorAll(".advanced-content");
          langTabs.forEach((tab) => {
            tab.classList.remove("show");
            tab.classList.remove("active");
          });
          advancedTabs.forEach((tab) => {
            tab.classList.remove("show");
            tab.classList.remove("active");
          });
          advancedContents.forEach((content) => {
            content.classList.remove("show");
            content.classList.remove("active");
          });
          langTabs[0].classList.add("show");
          langTabs[0].classList.add("active");
          advancedTabs[0].classList.add("show");
          advancedTabs[0].classList.add("active");
          advancedContents[0].classList.add("show");
          advancedContents[0].classList.add("active");
        }
      }
    },
    "food.FoodNumber"(value) {
      if (value && value.endsWith("?")) {
        let number = value.substring(0, value.length - 1);
        this.$api.foods
          .getNextNumber(number)
          .then((data) => (this.food.FoodNumber = data));
      }
    },
  },
  components: {
    searchAndTablePart,
    inputInlinePart,
    dataModalPart,
    importPart,
    exportPart,
    foodImage,
    foodSelects,
  },
  computed: {
    isMultiLanguage() {
      return (
        this.hasEnglish || this.hasJapanese || this.hasKorean || this.hasFrench
      );
    },
    productType() {
      return parseInt(window.localStorage["productType"]);
    },
    hasDWAPI() {
      return window.localStorage["erpType"] == 3;
    },
    hasShowmore() {
      return window.localStorage["hasShowmore"] == "true";
    },
    hasSpecification() {
      return window.localStorage["hasSpecification"] == "true";
    },
    hasBrand() {
      return true;
    },
    currentLang() {
      return this.$store.state.currentLang;
    },
    isShowAutoCombination() {
      return this.food.FoodStockType == 1;
    },
    isMultiSpecification() {
      return this.food.IsMultiSpecification;
    },
    isShow() {
      return (
        window.localStorage["productType"] == 0 ||
        window.localStorage["productType"] == 2
      );
    },
    isUshowLite() {
      return window.localStorage["productType"] == 4;
    },
    isUshowWeb() {
      return window.localStorage["productType"] == 3;
    },
    hasMyCard() {
      return window.localStorage["hasMyCard"] == "true";
    },
    hasBatchNumber() {
      return window.localStorage["hasBatchNumber"] == "true";
    },
    columns() {
      let cols = [
        { name: "FoodKindNumber" },
        { name: "FoodKindName" },
        { name: "FoodNumber" },
        { name: "FoodName" },
        { name: "SalPrice1" },
        { name: "SalPrice2" },
        { name: "SalPrice3" },
        { name: "Cost" },
        { name: "TaxTypeText" },
        { name: "Barcode" },
        { name: "Barcode2" },
        { name: "Barcode3" },
        { name: "Unit" },
        { name: "CreateTime" },
        { name: "UpdateTime" },
      ];
      return cols;
    },
  },
  methods: {
    currentSettingLang(langValue) {
      if (this.currentLangValue == 0) {
        this.food.FoodName = this.settingLang.FoodName;
        this.food.Memo = this.settingLang.Memo;
        this.food.Ingredient = this.settingLang.Ingredient;
        this.food.Storage = this.settingLang.Storage;
        this.food.Origin = this.settingLang.Origin;
      }
      if (this.currentLangValue == langValue) return;
      if (this.currentLangValue != 0) {
        let checkLangIndex = this.food.Languages.findIndex((lang) => {
          return lang.LanguageType == this.currentLangValue;
        });
        if (checkLangIndex > -1) {
          this.food.Languages[checkLangIndex] = this.settingLang;
        } else {
          this.food.Languages.push(this.settingLang);
        }
      }
      this.currentLangValue = langValue;
      this.setSettingLang(this.currentLangValue);
    },
    setSettingLang(langValue) {
      if (langValue == 0) {
        this.settingLang = {
          FoodId: this.food.FoodId,
          FoodName: this.food.FoodName,
          Unit: this.food.Unit,
          Memo: this.food.Memo,
          Ingredient: this.food.Ingredient,
          Storage: this.food.Storage,
          Origin: this.food.Origin,
        };
      } else {
        let langObj = this.food.Languages.find((lang) => {
          return lang.LanguageType == langValue;
        });
        if (langObj) {
          this.settingLang = {
            LanguageType: langObj.LanguageType,
            FoodId: langObj.FoodId,
            FoodName: langObj.FoodName,
            Unit: langObj.Unit,
            Memo: langObj.Memo,
            Ingredient: langObj.Ingredient,
            Storage: langObj.Storage,
            Origin: langObj.Origin,
          };
        } else {
          this.settingLang = {
            LanguageType: langValue,
            FoodId: this.food.FoodId,
            FoodName: "",
            Unit: "",
            Memo: "",
            Ingredient: "",
            Storage: "",
            Origin: "",
          };
        }
      }
    },
    postDigiwin() {
      this.$func.message.confirm("確定執行同步嗎 ?").then((result) => {
        if (result.isConfirmed) {
          this.$api.foods
            .postDigiwin()
            .then(() => {
              this.getFoods();
              this.$func.message.success("下傳成功 !");
            })
            .catch((error) => {
              if (error.status == "409") {
                this.$func.message.alert("同步執行中");
              } else {
                this.$func.message.error(error.data.Message);
              }
            });
        }
      });
    },
    postShowmore() {
      this.$func.message.confirm("確定執行同步嗎 ?").then((result) => {
        this.$api.foods
          .postShowmore()
          .then(() => {
            this.getFoods();
            this.$func.message.success("下傳成功 !");
          })
          .catch((error) => {
            this.$func.message.error(error.data.Message);
          });
      });
    },
    setSpecification(index) {
      if (!this.foods[index].IsMultiSpecification) {
        this.$func.message.alert("此商品尚未啟用多規格");
        return;
      }
      this.$router.push({
        name: "Specifications",
        params: { id: this.foods[index].FoodId },
      });
    },
    setBrand(index) {
      this.$router.push({
        name: "FoodBrands",
        params: { id: this.foods[index].FoodId },
      });
    },
    setOptions() {
      this.taxTypes = this.$enum.taxType.getAllWithFood();
      this.searchTaxTypes = this.$enum.taxType.getAllWithAll();
      this.foodStockTypes = this.$enum.foodStockType.getAll();
    },
    checkBarcode() {
      this.$api.foods
        .getIsBarcodeExist(this.food.FoodId, this.food.Barcode)
        .then((data) => {
          this.isBarcodeExist = data;
        });
    },
    getFoodKinds() {
      this.$api.foodKinds.getNames().then((data) => {
        this.foodKinds = data;
      });
    },
    getSuppliers() {
      this.$api.suppliers.getNames().then((data) => {
        this.suppliers = data;
      });
    },
    getFoods(dTO) {
      if (!dTO) dTO = {};
      dTO.showMainFood = true;
      this.$api.foods.getPages({ ...dTO, ...this.searchDTO }).then((data) => {
        this.foods = data.Datas;
        this.pagination = data.Pagination;
      });
    },
    addFood() {
      this.food = this.$func.defaultModel.getFood();
      this.settingLang = {
        LanguageType: null,
        FoodId: "",
        FoodName: "",
        Memo: "",
        Ingredient: "",
        Storage: "",
        Origin: "",
      };
      if (this.isERP) {
        this.food.IsStock = true;
      } else {
        this.food.IsStock = false;
      }
      if (this.hasBatchNumber) {
        this.food.HasBatchNumber = true;
      } else {
        this.food.HasBatchNumber = false;
      }
      this.purchaseFood = {};
      this.modalDisplayTrigger = !this.modalDisplayTrigger;
      this.modalTitle = this.$t("modal.addfood");
    },
    editFood(food) {
      if (!this.authority.IsUpdate || this.hasShowmore || this.hasDWAPI) {
        return;
      }
      this.$api.foods.get(food.FoodId).then((data) => {
        this.food = data;
        this.currentLangValue = 0;
        this.setSettingLang(this.currentLangValue);
        this.isEdit = true;
        this.getPurchaseFood(data.PurchaseFoodId);
        this.modalDisplayTrigger = !this.modalDisplayTrigger;
        this.modalTitle = this.$t("modal.editfood");
      });
    },
    removeFood(food) {
      this.$api.foods.remove(food.FoodId).then(() => {
        this.getTrigger = !this.getTrigger;
      });
    },
    saveFood() {
      if (this.currentLangValue != 0) {
        let checkLangIndex = this.food.Languages.findIndex((lang) => {
          return lang.LanguageType == this.currentLangValue;
        });
        if (checkLangIndex > -1) {
          this.food.Languages[checkLangIndex] = this.settingLang;
        } else {
          this.food.Languages.push(this.settingLang);
        }
      } else {
        this.food.FoodName = this.settingLang.FoodName;
        this.food.Unit = this.settingLang.Unit;
        this.food.Memo = this.settingLang.Memo;
        this.food.Ingredient = this.settingLang.Ingredient;
        this.food.Storage = this.settingLang.Storage;
        this.food.Origin = this.settingLang.Origin;
      }
      if (this.hasBatchNumber && !this.isEdit) {
        this.$func.message
          .emphasizeConfirm(
            "商品批號啟用僅能在新增時編輯，\n儲存後無法再修改。",
            "請問要儲存嗎 ?"
          )
          .then((result) => {
            if (result.isConfirmed) {
              this.$api.foods
                .save(this.food)
                .then(() => {
                  this.modalDisplayTrigger = !this.modalDisplayTrigger;
                  this.isEdit = false;
                  this.getTrigger = !this.getTrigger;
                })
                .catch((error) => this.$func.message.error(error.data.Message));
            }
          });
      } else {
        this.$api.foods
          .save(this.food)
          .then(() => {
            this.modalDisplayTrigger = !this.modalDisplayTrigger;
            this.isEdit = false;
            this.getTrigger = !this.getTrigger;
          })
          .catch((error) => this.$func.message.error(error.data.Message));
      }
    },
    setPurchaseFoodByNumber(num) {
      let number = num;
      this.purchaseFood = {};
      this.food.PurchaseFoodId = null;
      if (number && number.length > 0) {
        this.$api.foods
          .getByNumber(number)
          .then((data) => {
            this.purchaseFood = data;
            this.food.PurchaseFoodId = data.FoodId;
          })
          .catch(() => {
            this.$func.message.alert("請輸入正確的商品編號 !");
          });
      }
    },
    purchaseFoodChangeEvent() {
      if (this.purchaseFood.FoodNumber) {
        this.setPurchaseFoodByNumber(this.purchaseFood.FoodNumber);
      } else {
        this.setPurchaseFoodByNumber();
      }
    },
    setFood(item) {
      this.setPurchaseFoodByNumber(item.FoodNumber);
    },
    getPurchaseFood(id) {
      this.purchaseFood = {};
      if (!id) {
        return;
      }
      this.$api.foods
        .get(id)
        .then((data) => {
          this.purchaseFood = data;
        })
        .catch(() => {
          this.food.PurchaseFoodId = null;
        });
    },
    onExport() {
      this.$api.foods.getExcel();
    },
    onImport(file) {
      if (this.hasBatchNumber) {
        this.$func.message
          .confirm(
            "商品批號啟用僅能在新增時編輯，儲存後無法再修改，\n請問要儲存嗎 ?"
          )
          .then((result) => {
            if (!result.isConfirmed) {
              return;
            }
          });
      }
      let formData = new FormData();
      formData.append("file", file);
      if (this.erpType == 2) {
        this.$api.foods.postDigiwinExcel(formData).then((data) => {
          if (data.ErrorText) {
            this.$func.message.alert(
              `${data.ErrorText}\n總筆數：${data.TotalCounts}\n成功筆數：${data.SuccessCounts}\n失敗筆數：${data.FailCounts}`
            );
          } else {
            this.$func.message.success(
              `總筆數：${data.TotalCounts}\n成功筆數：${data.SuccessCounts}\n失敗筆數：${data.FailCounts}`
            );
          }
          window.localStorage["isFoodBySelect"] = data.IsFoodBySelect == true;
          this.getTrigger = !this.getTrigger;
        });
      } else {
        this.$api.foods.postExcel(formData).then((data) => {
          if (data.ErrorText) {
            this.$func.message.alert(
              `${data.ErrorText}\n總筆數：${data.TotalCounts}\n成功筆數：${data.SuccessCounts}\n失敗筆數：${data.FailCounts}`
            );
          } else {
            this.$func.message.success(
              `總筆數：${data.TotalCounts}\n成功筆數：${data.SuccessCounts}\n失敗筆數：${data.FailCounts}`
            );
          }
          window.localStorage["isFoodBySelect"] = data.IsFoodBySelect == true;
          this.getTrigger = !this.getTrigger;
        });
      }
    },
    onImage(index) {
      const food = this.foods[index];
      if (!food) {
        this.$func.message.alert("請選擇一筆商品 !");
        return;
      }
      this.foodId = food.FoodId;
      this.imageTrigger = true;
    },
    onImageClosed() {
      setTimeout(() => {
        this.imageTrigger = false;
      }, 500);
    },
  },
  mounted() {
    this.setOptions();
  },
  created() {
    this.authority = this.$func.localStorage.getAuthByName(this.$route.name);
  },
};
</script>

<style lang="scss">
.cls-2 {
  fill: #26b8d6;
}
.cls-1 {
  fill: #e6725e;
}
.btn-showmore {
  transition: all 0.15s;
  &:hover {
    background-color: #d1d1d1;
  }
}
.btn-digiwin {
  display: flex;
  transition: all 0.15s;
  font-weight: 600;
  p {
    transition: all 0.15s;
    margin-bottom: 0;
  }
  &:hover {
    color: black;
    background-color: #d1d1d1;
    p {
      color: #de0010;
    }
  }
}
</style>
