<template>
  <div>
    <search-and-table-part
      :pagination="pagination"
      @getItems="getSuppliers"
      @addItem="addSupplier"
      @editItem="editSupplier"
      @removeItem="removeSupplier"
      @trdbClicked="editSupplier"
      :items="suppliers"
      :columns="columns"
      :idProperty="idProperty"
      :nameProperty="nameProperty"
      :getTrigger="getTrigger"
      :hasAdd="authority.IsAdd"
      :hasEdit="authority.IsUpdate"
      :hasRemove="authority.IsRemove"
      :isSortable="true"
      ><template slot="searchArea">
        <div class="row">
          <div class="col-lg-6">
            <div class="form-group row">
              <label
                for="searchSupplierNumber"
                class="col-3 col-md-2 col-lg-3 col-form-label"
                >{{ $t("searchAndTablePart.suppliernumber") }}:</label
              >
              <div class="col">
                <input
                  type="text"
                  class="form-control"
                  id="searchSupplierNumber"
                  v-model="searchDTO.SupplierNumber"
                />
              </div>
            </div>
          </div>
          <div class="col-lg-6">
            <div class="form-group row">
              <label
                for="searchSupplierName"
                class="col-3 col-md-2 col-lg-3 col-form-label"
                >{{ $t("searchAndTablePart.suppliername") }}:</label
              >
              <div class="col">
                <input
                  type="text"
                  class="form-control"
                  id="searchSupplierName"
                  v-model="searchDTO.SupplierName"
                />
              </div>
            </div>
          </div>
        </div>
      </template>
      <template slot="searchButtonArea">
        <import-part
          @onImport="onImport"
          :title="`${$t('modal.importsupplier')}`"
        />
        <export-part @onExport="onExport" />
      </template>
      <template slot="dataButtonArea" slot-scope="props">
        <button
          slot="button"
          type="button"
          class="btn btn-outline-primary btn-icon py-2 px-4"
          data-toggle="tooltip"
          data-placement="top"
          :title="`${$t('button.food')}`"
          @click="onFoodClick(props.index)"
        >
          <i class="fa fa-hamburger"></i>
        </button>
      </template>
    </search-and-table-part>

    <supplier-data
      :id="supplierId"
      :modalTitle="modalTitle"
      :trigger="modalTrigger"
      @onSaved="saveSupplier"
    ></supplier-data>
  </div>
</template>

<script>
import searchAndTablePart from "@/components/parts/searchAndTablePart";
import supplierData from "@/components/datas/SupplierData";
import importPart from "@/components/ImportPart";
import exportPart from "@/components/ExportPart";

export default {
  data() {
    return {
      searchDTO: {},
      suppliers: [],
      pagination: {},
      columns: [
        { name: "SupplierNumber" },
        { name: "SupplierName" },
        { name: "BusinessNumber" },
        { name: "Cellphone" },
        { name: "Telephone" },
        { name: "Email" },
        { name: "ContactPerson" },
        { name: "FaxNumber" },
        { name: "Memo" },
      ],
      idProperty: "SupplierId",
      nameProperty: "SupplierName",
      getTrigger: false,
      modalTrigger: false,
      supplierId: "",
      authority: {},
      modalTitle: "",
    };
  },
  components: {
    searchAndTablePart,
    supplierData,
    importPart,
    exportPart,
  },
  methods: {
    getSuppliers(dTO) {
      const vm = this;
      this.$api.suppliers
        .getPages({ ...dTO, ...this.searchDTO })
        .then((data) => {
          vm.suppliers = data.Datas;
          vm.pagination = data.Pagination;
        });
    },
    addSupplier() {
      this.modalTrigger = !this.modalTrigger;
      this.supplierId = "";
      this.modalTitle = this.$t("modal.addsupplier");
    },
    editSupplier(supplier) {
      if (!this.authority.IsUpdate) {
        return;
      }
      this.modalTrigger = !this.modalTrigger;
      this.supplierId = supplier.SupplierId;
      this.modalTitle = this.$t("modal.editsupplier");
    },
    removeSupplier(supplier) {
      const vm = this;
      this.$api.suppliers.remove(supplier.SupplierId).then(() => {
        vm.getTrigger = !vm.getTrigger;
      });
    },
    saveSupplier() {
      this.getTrigger = !this.getTrigger;
    },
    onFoodClick(index) {
      const supplier = this.suppliers[index];
      if (!supplier) {
        this.$func.message.alert("請選擇一筆資料");
        return;
      }
      this.$router.push({
        name: "SupplierFoods",
        params: { id: supplier.SupplierId },
      });
    },
    onExport() {
      this.$api.suppliers.getExcel();
    },
    onImport(file) {
      let formData = new FormData();
      formData.append("file", file);

      this.$api.suppliers.postExcel(formData).then((data) => {
        this.getTrigger = !this.getTrigger;
      });
    },
  },
  created() {
    this.authority = this.$func.localStorage.getAuthByName(this.$route.name);
  },
};
</script>
