<template>
  <div>
    <condition-part
      :hasDate="true"
      :hasEmployee="true"
      :hasWarehouse="true"
      :hasFood="isDetail"
      :hasFoodKind="isDetail"
      :hasExcel="true"
      :hasPdf="true"
      :hasAdd="false"
      :hasEdit="false"
      :hasRemove="false"
      :columns="columns"
      :items="items"
      :pagination="pagination"
      :isSortable="true"
      @getItems="getItems"
      @downloadExcel="downloadExcel"
      @downloadPdf="downloadPdf"
      @printPdf="printPdf"
      :reportTypes="reportTypes"
    ></condition-part>
  </div>
</template>

<script>
import conditionPart from "@/components/parts/conditionPart";

export default {
  data() {
    return {
      items: [],
      pagination: {},
      reportTypes: ["simple", "detail"],
      reportType: "simple",
    };
  },
  computed: {
    columns() {
      if (this.reportType == "detail") {
        let s = [
          { name: "InventoryDate" },
          { name: "InventoryNumber" },
          { name: "WarehouseNumber" },
          { name: "WarehouseName" },
          { name: "EmployeeNumber" },
          { name: "EmployeeName" },
          { name: "StockAdjustNumber" },
          { name: "TradeMemo" },
          { name: "FoodNumber" },
          { name: "FoodName" },
          { name: "Qty" },
          { name: "Memo" },
        ];
        if (window.localStorage["hasBatchNumber"] == "true") {
          s.push({ name: "BatchNumber" });
        }
        return s;
      } else if (this.reportType == "simple") {
        return [
          { name: "InventoryDate" },
          { name: "InventoryNumber" },
          { name: "WarehouseNumber" },
          { name: "WarehouseName" },
          { name: "EmployeeNumber" },
          { name: "EmployeeName" },
          { name: "StockAdjustNumber" },
          { name: "Memo" },
        ];
      } else {
        return undefined;
      }
    },
    isDetail() {
      return this.reportType == "detail";
    },
  },
  components: {
    conditionPart,
  },
  methods: {
    downloadExcel(condition) {
      this.$api.reportExcels.getInventory(condition);
    },
    downloadPdf(condition) {
      this.$api.reportPdfs.getInventory(condition);
    },
    printPdf(condition) {
      this.$api.reportPdfs.printInventory(condition);
    },
    getItems(condition) {
      this.reportType = condition.ReportType;
      this.trade = {};

      const vm = this;
      this.$api.reports.getInventory(condition).then((data) => {
        vm.items = data.Datas;
        vm.pagination = data.Pagination;
      });
    },
  },
};
</script>
