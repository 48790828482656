export function getAll() {
  return [
    {
      value: -1,
      name: 'All',
      text: '全部',
    },
    {
      value: 0,
      name: 'New',
      text: '新建',
    },
    {
      value: 1,
      name: 'Executing',
      text: '未建檔',
    },
    {
      value: 2,
      name: 'Done',
      text: '已建檔',
    },
    {
      value: 3,
      name: 'Delete',
      text: '已刪除',
    },
  ]
}

export function getByValue(value) {
  const type = getAll().find(function (item) {
    return item.value == value
  })
  return type
}
