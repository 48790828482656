<template>
  <div>
    <search-and-table-part
      :hasEmployee="true"
      :pagination="pagination"
      @getItems="getDownloads"
      @addItem="addDownload"
      @removeItem="remove"
      :items="downloads"
      :columns="columns"
      :idProperty="idProperty"
      :nameProperty="nameProperty"
      :hasEdit="false"
      :getTrigger="getTrigger"
      :isMultiple="true"
      @currentIndexChanged="currentIndexChanged"
    >
      <template slot="secondTablePart">
        <div class="col-12 col-md-4">
          <page-table
            v-if="detColumns"
            :items="downloadList.DownloadDets"
            :columns="detColumns"
            :hasAdd="false"
            :hasEdit="false"
            :hasRemove="false"
            :hasPage="false"
            :isMultiple="true"
            idProperty="DownloadItemType"
            nameProperty="DownloadItemName"
            tableName="downloadDets"
          />
        </div>
        <div class="col-12 col-md-4">
          <page-table
            v-if="terminalColumns"
            :items="downloadList.DownloadTerminals"
            :columns="terminalColumns"
            :hasAdd="false"
            :hasEdit="false"
            :hasRemove="false"
            :hasPage="false"
            :isMultiple="true"
            idProperty="TerminalNumber"
            nameProperty="TerminalName"
            tableName="downloadTerminals"
          >
            <th class="text-center" slot="th-after" style="width: 80px">
              {{ $t("columnName.issuccess") }}
            </th>
            <td class="text-center" slot="td-after" slot-scope="props">
              <span v-if="props.item.IsSuccess">V</span>
            </td>
          </page-table>
        </div>
      </template>
    </search-and-table-part>
  </div>
</template>

<script>
import searchAndTablePart from "@/components/parts/searchAndTablePart";
import pageTable from "@/components/PageTable";

export default {
  data() {
    return {
      downloads: [],
      pagination: {},
      columns: [
        { name: "DownloadName" },
        { name: "Memo" },
        { name: "CreateTime" },
        { name: "EmployeeName" },
      ],
      idProperty: "DownloadId",
      nameProperty: "DownloadName",
      getTrigger: false,
      currentIndex: -1,
      downloadList: {
        DownloadDets: [],
        DownloadTerminals: [],
      },
      detColumns: [{ name: "DownloadItemName" }],
      terminalColumns: [
        { name: "TerminalName" },
        { name: "UpdateTime", headerText: "下載時間" },
      ],
    };
  },
  components: {
    searchAndTablePart,
    pageTable,
  },
  watch: {
    currentIndex(value) {
      if (value >= 0) {
        this.$api.downloads
          .downloadList(this.downloads[this.currentIndex].DownloadId)
          .then((data) => {
            this.downloadList = data;
          });
      }
    },
  },
  methods: {
    currentIndexChanged(index) {
      this.currentIndex = index;
    },
    getDownloads(dTO) {
      const vm = this;
      this.$api.downloads.getPages(dTO).then((data) => {
        vm.downloads = data.Datas;
        vm.pagination = data.Pagination;
      });
    },
    addDownload() {
      this.$router.push({ name: "DownloadAdd" });
    },
    remove(item) {
      if (!item) {
        this.$func.message.alert("請選擇一筆資料 !");
        return;
      }
      const vm = this;
      this.$api.downloads.remove(item.DownloadId).then(() => {
        vm.getTrigger = !vm.getTrigger;
      });
    },
  },
};
</script>
