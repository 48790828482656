<template>
  <form class="card" @submit.prevent="onSaved">
    <div class="card-header">
      <button
        type="button"
        class="btn btn-outline-primary btn-icon"
        @click="onSaved"
      >
        <i class="fas fa-check"></i>
      </button>
      <button
        type="button"
        class="btn btn-outline-danger btn-icon"
        @click="onClosed"
      >
        <i class="fas fa-times"></i>
      </button>
      <import-part
        @onImport="onImport"
        :nesWarehouse="true"
        :warehouse="inventory.WarehouseId"
        :title="`${$t('modal.importfood')}`"
      />
      <export-part @onExport="onExport" v-if="!isTYSSO" />
    </div>
    <div class="card-body">
      <div class="row">
        <input-part
          id="inventoryDate"
          :labelText="`${$t('modal.inventorydate')}:`"
        >
          <input
            type="date"
            class="form-control"
            id="inventoryDate"
            v-model="inventory.InventoryDate"
            required
          />
        </input-part>
        <input-part
          id="inventoryNumber"
          :labelText="`${$t('modal.inventorynumber')}:`"
        >
          <input
            type="text"
            class="form-control"
            id="inventoryNumber"
            v-model="inventory.InventoryNumber"
            readonly
          />
        </input-part>
        <input-part id="employeeName" :labelText="`${$t('modal.writername')}:`">
          <input
            type="text"
            readonly
            class="form-control"
            id="employeeName"
            :value="employeeName"
          />
        </input-part>
        <input-part id="warehouseName" :labelText="`${$t('modal.warehouse')}:`">
          <div class="row px-3">
            <input
              type="text"
              class="form-control col-12 col-md-6"
              id="warehouseNumber"
              :placeholder="`${$t('placeholder.inputwarehousenumberordb')}`"
              v-model="addWarehouseNumber"
              @keyup.enter.exact="selectWarehouseByKeyword"
              @dblclick="warehouseModalTrigger = !warehouseModalTrigger"
            />
            <input
              type="text"
              class="form-control col-12 col-md-6"
              id="warehouseName"
              readonly
              :value="warehouseName"
            />
          </div>
        </input-part>
        <input-part id="memo" :labelText="`${$t('modal.memo')}`" :degree="12">
          <input
            type="text"
            class="form-control"
            id="memo"
            v-model="inventory.Memo"
          />
        </input-part>
      </div>
      <div class="col-12 pl-0">
        <table-part :columns="columns" :noHide="true">
          <template slot="th-before">
            <th style="width: 80px">{{ $t("columnName.remove") }}</th>
            <th style="width: 80px">{{ $t("columnName.index") }}</th>
          </template>
          <tbody slot="tbody">
            <tr v-for="(food, index) in showFoods" :key="food.FoodId">
              <td>
                <button
                  type="button"
                  class="btn btn-outline-danger btn-icon"
                  @click="removeDet(index)"
                >
                  <i class="fas fa-trash-alt"></i>
                </button>
              </td>
              <td>{{ index + 1 }}</td>
              <td>{{ food.FoodNumber }}</td>
              <td>{{ food.FoodName }}</td>
              <td class="text-right" type="number">{{ food.OriginalQty }}</td>
              <td>
                <input
                  class="form-control text-right"
                  type="number"
                  v-model.number="food.Qty"
                  required
                />
              </td>
              <td v-if="hasBatchNumber">
                <input
                  class="form-control"
                  type="text"
                  v-model="food.BatchNumber"
                  :readonly="!food.HasBatchNumber"
                  @dblclick="openBatchSelector(food, index)"
                  @change="checkBatchNumber(food)"
                />
              </td>
              <td>
                <input class="form-control" type="text" v-model="food.Memo" />
              </td>
            </tr>
            <tr>
              <td colspan="7">
                <input
                  class="form-control"
                  type="text"
                  :placeholder="`${$t('placeholder.inputfoodnumberorbarcode')}`"
                  v-model="addFoodNumber"
                  @keyup.enter.exact="selectFoodByKeyword"
                  @dblclick="showFoodSelect"
                />
              </td>
            </tr>
            <tr>
              <td>{{ $t("columnName.total") }}</td>
              <td></td>
              <td></td>
              <td></td>
              <td class="text-right">{{ originalTotalQty }}</td>
              <td class="text-right">{{ totalQty }}</td>
              <td></td>
              <td></td>
            </tr>
          </tbody>
        </table-part>
        <pagination-part
          :pagination="pageDTO"
          @pageChanged="pageChanged"
        ></pagination-part>
      </div>
      <food-selects
        :trigger="foodModalTrigger"
        :isMultiple="true"
        @onSaved="addFoods"
        :parentItems="foodsByKeyword"
        :noSearchType="!hasFoodSearchType"
      />
      <warehouse-selects
        :trigger="warehouseModalTrigger"
        :isMultiple="false"
        @onSaved="setWarehouse"
      ></warehouse-selects>
      <batch-selects
        :trigger="batchModalTrigger"
        :isMultiple="false"
        :warehouseId="currentWarehouseId"
        :foodId="currentFoodId"
        @onSaved="setBatchNumber"
      />
    </div>
  </form>
</template>

<script>
import foodSelects from "@/components/selects/FoodSelects";
import warehouseSelects from "@/components/selects/WarehouseSelects";
import batchSelects from "@/components/selects/BatchSelects";

import tablePart from "@/components/parts/tablePart";

import inputPart from "@/components/parts/inputPart";
import importPart from "@/components/ImportPart";
import exportPart from "@/components/ExportPart";

import paginationPart from "@/components/parts/paginationPart";

export default {
  data() {
    return {
      file: "",
      inventory: {
        EmployeeId: window.localStorage["userId"],
        InventoryDate: this.$func.getDate(),
      },
      inventoryDets: [],
      employeeName: window.localStorage["userName"],
      warehouseName: "",
      addFoodNumber: "",
      addWarehouseNumber: "",
      foodModalTrigger: false,
      warehouseModalTrigger: false,
      batchModalTrigger: false,
      foodsByKeyword: [],
      hasFoodSearchType: true,
      pageDTO: {
        CurrentPage: 1,
        PageNumber: 0,
        PageSize: 25,
        HasPrevious: false,
        HasNext: false,
      },
      showFoods: [],
      currentWarehouseId: "",
      currentFoodId: "",
      currentFoodIndex: null,
    };
  },
  components: {
    foodSelects,
    warehouseSelects,
    batchSelects,
    inputPart,
    importPart,
    exportPart,
    tablePart,
    paginationPart,
  },
  computed: {
    hasBatchNumber() {
      return window.localStorage["hasBatchNumber"] == "true";
    },
    originalTotalQty() {
      if (this.inventoryDets.length > 0)
        return this.inventoryDets
          .map((item) => {
            return item.OriginalQty;
          })
          .reduce((t, q) => {
            if (!t) t = 0;
            if (!q) q = 0;
            return parseInt(t) + parseInt(q);
          });
      else return 0;
    },
    totalQty() {
      if (this.inventoryDets.length > 0)
        return this.inventoryDets
          .map((item) => {
            return item.Qty;
          })
          .reduce((t, q) => {
            if (!t) t = 0;
            if (!q) q = 0;
            return parseInt(t) + parseInt(q);
          });
      else return 0;
    },
    isTYSSO() {
      return this.$store.state.inventoryMachineType == 2;
    },
    columns() {
      let columns = [
        { name: "FoodNumber" },
        { name: "FoodName" },
        { name: "OriginalQty", headerText: "原數量" },
        { name: "Qty" },
        { name: "Memo" },
      ];
      if (this.hasBatchNumber) {
        columns.splice(-1, 0, { name: "BatchNumber" });
      }
      return columns;
    },
  },
  methods: {
    selectWarehouseByKeyword() {
      if (!this.addWarehouseNumber || this.addWarehouseNumber.length == 0) {
        this.$func.message.alert("請輸入倉庫編號");
        return;
      }
      const vm = this;
      this.$api.warehouses
        .getByNumber(this.addWarehouseNumber)
        .then((data) => {
          if (data) {
            vm.setWarehouse(data);
          }
        })
        .catch(() => {
          this.$func.message.error("查無此倉庫 !");
          this.setWarehouse({
            WarehouseId: null,
            WarehouseNumber: null,
            WarehouseName: null,
          });
        });
    },
    checkBatchNumber(item) {
      if (item.BatchNumber) {
        this.$api.warehouses
          .getFoodBatchNumbers(
            this.inventory.WarehouseId,
            item.FoodId,
            item.BatchNumber
          )
          .then((data) => {
            if (data.BatchNumber) {
              item.OriginalQty = data.Qty;
            } else {
              item.OriginalQty = 0;
            }
          });
      } else {
        item.OriginalQty = 0;
      }
    },
    setBatchNumber(item) {
      this.inventoryDets[this.currentFoodIndex].BatchNumber = item.BatchNumber;
      this.$api.warehouses
        .getFoodBatchNumbers(
          this.inventory.WarehouseId,
          item.FoodId,
          item.BatchNumber
        )
        .then((data) => {
          this.inventoryDets[this.currentFoodIndex].OriginalQty = data.Qty;
        });
    },
    openBatchSelector(det, index) {
      if (!det.HasBatchNumber) {
        return;
      }
      this.currentWarehouseId = this.inventory.WarehouseId;
      this.currentFoodId = det.FoodId;
      this.currentFoodIndex = index;
      this.batchModalTrigger = !this.batchModalTrigger;
    },
    showFoodSelect() {
      if (
        !this.inventory.WarehouseId ||
        this.inventory.WarehouseId.length == 0
      ) {
        this.$func.message.alert("請選擇倉庫 !");
        return;
      }
      this.foodsByKeyword = undefined;
      this.hasFoodSearchType = true;
      this.foodModalTrigger = !this.foodModalTrigger;
    },
    addDet(item) {
      this.inventoryDets.push({
        FoodId: item.FoodId,
        FoodNumber: item.FoodNumber,
        FoodName: item.FoodName,
        HasBatchNumber: item.HasBatchNumber,
        BatchNumber: null,
      });
    },
    selectFoodByKeyword() {
      if (!this.addFoodNumber || this.addFoodNumber.length == 0) {
        this.$func.message.alert("請輸入商品編號或條碼");
        return;
      }
      if (
        !this.inventory.WarehouseId ||
        this.inventory.WarehouseId.length == 0
      ) {
        this.$func.message.alert("請選擇倉庫 !");
        return;
      }
      const vm = this;
      this.$api.foods
        .getByKeyword({ IsMatterStock: true, Keyword: this.addFoodNumber })
        .then((data) => {
          if (data) {
            if (data.length > 1) {
              vm.foodsByKeyword = data;
              vm.hasFoodSearchType = false;
              vm.foodModalTrigger = !vm.foodModalTrigger;
            } else vm.addFood(data[0].FoodId);
          }
        })
        .catch(() => this.$func.message.error("查無此商品 !"));
    },
    addFood(foodId) {
      const vm = this;
      this.$api.inventorys
        .getFood(this.inventory.WarehouseId, foodId)
        .then((data) => {
          if (data) {
            vm.addFoodNumber = "";
            if (!data.HasBatchNumber) {
              data.Qty = data.OriginalQty;
            } else {
              data.OriginalQty = 0;
              data.Qty = 0;
            }
            vm.pushItem(data);
          }
          vm.getShowFoods(vm.pageDTO.CurrentPage);
        });
    },
    removeDet(index) {
      let num = this.inventoryDets.findIndex((det) => {
        return det.FoodId == this.showFoods[index].FoodId;
      });
      this.inventoryDets.splice(num, 1);
      if (
        this.pageDTO.CurrentPage > 1 &&
        this.showFoods.length == 1 &&
        index == 0
      ) {
        this.pageDTO.CurrentPage -= 1;
      }
      this.getShowFoods(this.pageDTO.CurrentPage);
    },
    onSaved() {
      this.saveDets();
      if (
        !this.inventory.WarehouseId ||
        this.inventory.WarehouseId.length == 0
      ) {
        this.$func.message.alert("請選擇倉庫 !");
        return;
      }
      if (this.inventoryDets.length == 0) {
        this.$func.message.alert("請選擇商品 !");
        return;
      }

      let emptyBatch = this.inventoryDets.some((det) => {
        return det.HasBatchNumber && !det.BatchNumber;
      });
      if (emptyBatch) {
        this.$func.message.alert("請設定商品批號 !");
        return;
      }

      this.inventory.EmployeeId = window.localStorage["userId"];
      const vm = this;
      this.$api.inventorys
        .post({
          Inventory: vm.inventory,
          InventoryDets: vm.inventoryDets,
        })
        .then((data) => {
          if (vm.$route.name == "InventoryAdd") {
            vm.$func.message
              .confirm(
                `新增盤點單號為${data.Inventory.InventoryNumber}，是否直接轉調整單 ?`
              )
              .then((result) => {
                if (result.isConfirmed) {
                  vm.$api.inventorys
                    .turnStockAdjust({
                      InventoryIds: [`${data.Inventory.InventoryId}`],
                    })
                    .then((trade) => {
                      vm.$func.message.success(
                        `新增調整單號為${trade.StockAdjustNumber}`
                      );
                    })
                    .catch((err) => {
                      vm.$func.message.error(err.data.Message);
                    });
                }
              });
          }
          this.$store.dispatch("setSaveOrClose", true);
          this.$router.push({ name: "Inventorys" });
        })
        .catch((error) => {
          vm.$func.message.error(error.data.Message);
        });
    },
    onClosed() {
      this.$store.dispatch("setSaveOrClose", true);
      this.$router.go(-1);
    },
    setWarehouse(item) {
      this.addWarehouseNumber = item.WarehouseNumber;
      this.inventory.WarehouseId = item.WarehouseId;
      this.warehouseName = item.WarehouseName;
    },
    addFoods(items) {
      const vm = this;
      this.$api.inventorys
        .getFoods(
          this.inventory.WarehouseId,
          items.map((a) => a.FoodId)
        )
        .then((data) => {
          if (data) {
            vm.addFoodNumber = "";
            data.forEach((element) => {
              if (!element.HasBatchNumber) {
                element.Qty = element.OriginalQty;
              } else {
                element.OriginalQty = 0;
                element.Qty = 0;
              }
              vm.pushItem(element);
            });
            vm.getShowFoods(vm.pageDTO.CurrentPage);
          }
        });
    },
    onExport() {
      this.$api.inventorys.getExcelByPost(this.inventoryDets);
    },
    pushItem(item) {
      if (!item.HasBatchNumber) {
        let exist = this.inventoryDets.find((i) => i.FoodId == item.FoodId);
        if (exist) {
          const index = this.inventoryDets.indexOf(exist);
          if (index > -1) {
            const food = this.inventoryDets[index];
            this.inventoryDets.splice(index, 1);
            this.inventoryDets.push(food);
            return;
          }
        }
      }
      this.inventoryDets.push(item);
    },
    onImport(file) {
      let formData = new FormData();
      formData.append("file", file);
      const vm = this;
      if (this.isTYSSO) {
        this.$api.inventorys
          .postTYSSO(vm.inventory.WarehouseId, formData)
          .then((data) => {
            if (data) {
              data.forEach((item) => {
                vm.pushItem(item);
              });
              vm.getShowFoods(vm.pageDTO.CurrentPage);
            }
          });
      } else {
        this.$api.inventorys
          .postExcel(vm.inventory.WarehouseId, formData)
          .then((data) => {
            if (data) {
              data.forEach((item) => {
                vm.pushItem(item);
              });
              vm.getShowFoods(vm.pageDTO.CurrentPage);
            }
          });
      }
    },
    getShowFoods(currentPage) {
      this.showFoods = [];
      let start = 0;
      let pageSize = this.pageDTO.PageSize * currentPage;

      if (currentPage > 1) {
        start = (currentPage - 1) * this.pageDTO.PageSize;
      }

      if (this.inventoryDets.length > 0) {
        this.pageDTO.PageNumber = Math.floor(
          this.inventoryDets.length / this.pageDTO.PageSize
        );
      } else {
        this.pageDTO.PageNumber = 0;
      }

      if (this.inventoryDets.length % this.pageDTO.PageSize != 0) {
        this.pageDTO.PageNumber += 1;
      }

      if (
        currentPage == this.pageDTO.PageNumber &&
        this.inventoryDets.length % this.pageDTO.PageSize > 0
      ) {
        pageSize = this.inventoryDets.length;
      }
      if (this.inventoryDets.length > 0) {
        for (let i = start; i < pageSize; i++) {
          this.showFoods.push(this.inventoryDets[i]);
        }
      }

      if (this.pageDTO.PageNumber <= 1) {
        this.pageDTO.HasPrevious = false;
        this.pageDTO.HasNext = false;
      } else {
        if (currentPage == this.pageDTO.PageNumber)
          this.pageDTO.HasNext = false;
        else this.pageDTO.HasNext = true;

        if (currentPage == 1) this.pageDTO.HasPrevious = false;
        else this.pageDTO.HasPrevious = true;
      }
    },
    pageChanged(page) {
      this.pageDTO.CurrentPage = page;
      this.saveDets();
      this.getShowFoods(page);
    },
    saveDets() {
      const vm = this;
      this.showFoods.forEach((food) => {
        vm.inventoryDets.forEach((det) => {
          if (food.FoodId == det.FoodId) {
            det.Qty = food.Qty;
            det.Memo = food.Memo;
          }
        });
      });
    },
  },
  beforeRouteLeave(to, from, next) {
    const isSaveOrClose = this.$store.state.isSaveOrClose;
    if (isSaveOrClose) {
      next();
    } else {
      const result = confirm("資料尚未儲存，確定離開當前頁面嗎 ?");
      if (result) {
        next();
      } else {
        next(false);
      }
    }
  },
  mounted() {
    const id = this.$route.params["id"];
    const vm = this;
    if (id) {
      this.$api.inventorys
        .get(id)
        .then((data) => {
          vm.addWarehouseNumber = data.Inventory.WarehouseNumber;
          vm.inventory = data.Inventory;
          vm.inventoryDets = data.InventoryDets;
          vm.warehouseName = data.Inventory.WarehouseName;

          vm.inventoryDets.forEach((det) => {
            vm.showFoods.push(det);
          });
        })
        .catch(() => {
          vm.$func.message.alert("查無此筆單據 !");
          vm.$router.go(-1);
        });
    }
  },
};
</script>
