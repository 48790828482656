export function getAll() {
  return [
    {
      value: 0,
      name: "Default",
      text: "預設",
    },
    {
      value: 1,
      name: "Round",
      text: "四捨五入（到整數）",
    },
    {
      value: 2,
      name: "Up",
      text: "無條件進位（到整數）",
    },
    {
      value: 3,
      name: "Down",
      text: "無條件捨去（到整數）",
    },
  ];
}
