<template>
  <div>
    <modal-part
      modalId="salData"
      modalClass="modal-xl"
      :title="title"
      :hasButtons="false"
      :isShow="isShow"
      @onClosed="onClosed"
    >
      <div slot="item">
        <div class="row" v-if="trade.Purchase">
          <div class="col-md-6 col-lg-4">
            <div class="form-group">
              <label for="salnumber">單號:</label>
              <input
                type="text"
                id="salnumber"
                class="form-control"
                :value="trade.Purchase.PurchaseNumber"
                readonly
              />
            </div>
          </div>
          <div class="col-md-6 col-lg-4">
            <div class="form-group">
              <label for="supplierNumber">廠商:</label>
              <input
                type="text"
                id="supplierNumber"
                class="form-control"
                :value="
                  trade.Purchase.SupplierNumber +
                  ' - ' +
                  trade.Purchase.SupplierName
                "
                readonly
              />
            </div>
          </div>
          <div class="col-md-6 col-lg-4">
            <div class="form-group">
              <label for="purchaseDate">交易日期:</label>
              <input
                type="text"
                id="purchaseDate"
                class="form-control"
                :value="trade.Purchase.PurchaseDate"
                readonly
              />
            </div>
          </div>
          <div class="col-md-6 col-lg-4">
            <div class="form-group">
              <label for="accountDate">帳款日期:</label>
              <input
                type="text"
                id="accountDate"
                class="form-control"
                :value="trade.Purchase.AccountDate"
                readonly
              />
            </div>
          </div>
          <div class="col-md-6 col-lg-4">
            <div class="form-group">
              <label for="invoicenumber">發票號碼:</label>
              <input
                type="text"
                id="invoicenumber"
                class="form-control"
                :value="trade.Purchase.InvoiceNumber"
                readonly
              />
            </div>
          </div>
          <div class="col-md-6 col-lg-4">
            <div class="form-group">
              <label for="invoiceDate">發票日期:</label>
              <input
                type="text"
                id="invoiceDate"
                class="form-control"
                :value="trade.Purchase.InvoiceDate"
                readonly
              />
            </div>
          </div>

          <div class="col-md-6 col-lg-4">
            <div class="form-group">
              <label for="total">總計:</label>
              <input
                type="text"
                id="total"
                class="form-control"
                :value="trade.Purchase.Total"
                readonly
              />
            </div>
          </div>
          <div class="col-md-6 col-lg-4">
            <div class="form-group">
              <label for="nopay">未付金額:</label>
              <input
                type="text"
                id="nopay"
                class="form-control"
                :value="trade.Purchase.NoPay"
                readonly
              />
            </div>
          </div>
          <div class="col-12">
            <div class="form-group">
              <label for="memo">備註:</label>
              <input
                type="text"
                id="memo"
                class="form-control"
                :value="trade.Purchase.Memo"
                readonly
              />
            </div>
          </div>
        </div>
        <table-part :columns="columns" :items="trade.PurchaseDets"></table-part>
      </div>
    </modal-part>
  </div>
</template>

<script>
import modalPart from "@/components/parts/modalPart";
import tablePart from "@/components/parts/tablePart";

export default {
  data() {
    return {
      columns: [
        {
          name: "FoodNumber",
        },
        {
          name: "FoodName",
        },
        {
          name: "WarehouseNumber",
        },
        {
          name: "WarehouseName",
        },
        {
          name: "Discount",
        },
        {
          name: "Qty",
        },
        {
          name: "SalPrice",
        },
        {
          name: "Total",
        },
      ],
    };
  },
  props: ["trade", "isShow"],
  computed: {
    title() {
      if (!this.trade || !this.trade.Purchase) return "";
      return this.trade.Purchase.TradeTypeText;
    },
  },
  components: {
    modalPart,
    tablePart,
  },
  methods: {
    onClosed() {
      this.$emit("onClosed");
    },
  },
};
</script>
