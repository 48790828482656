import { httpGet, httpPost, httpDelete } from "@/request/http";
import func from "@/funcs";

let path = "/promotionDets";

export default {
  get(id) {
    return httpGet(`${path}/${id}`);
  },
  gets(id) {
    return httpGet(`promotions/${id}/promotiondets`);
  },
  save(item) {
    return httpPost(path, item);
  },
  remove(id) {
    return httpDelete(`${path}/${id}`);
  },
  getExcel(id) {
    func.getExcel.get(`${path}/${id}/excel`, "促銷模組");
  },
  postExcel(id, formData) {
    return func.postExcel(`${path}/${id}/excel`, formData);
  },
};
