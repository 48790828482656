import { httpGet, httpPost, httpPatch, httpDelete } from "@/request/http";

let path = "/ubereats";

export default {
  uploadMenu(id) {
    return httpPost(`/terminals/${id}${path}/menu`);
  },
  downloadMenu(id) {
    return httpGet(`/terminals/${id}${path}/menu`);
  },
  postAccessToken(ob) {
    return httpPost(`${path}/accesstoken`, ob);
  },
  postIntegration(ob) {
    return httpPost(`${path}/integration`, ob);
  },
  getDevices() {
    return httpGet(`/devices/hasubereats`);
  },
  getUberEatsDevices() {
    return httpGet(`${path}/devices`);
  },
  getUberEatsDevice(id) {
    return httpGet(`${path}/devices/${id}/integration`);
  },
  updateUberEatsFood(id) {
    return httpPost(`${path}/items/${id}`);
  },
  integrationPatch(item) {
    return httpPatch(`${path}/integration`, item);
  },
  deleteUberEats(id) {
    return httpDelete(`/devices/${id}${path}/integration`);
  },
  getMenus(type) {
    return httpGet(`/onlineorder/${type}/menus`);
  },
  postMenus(id, item) {
    return httpPost(`/terminals/${id}/onlineorder/menus`, item);
  },
};
