export function getAll() {
  return [
    {
      value: -1,
      name: "All",
      text: "全部",
    },
    {
      value: 0,
      name: "New",
      text: "新建",
    },
    {
      value: 1,
      name: "Callback",
      text: "回電",
    },
    {
      value: 2,
      name: "Done",
      text: "成交",
    },
    {
      value: 3,
      name: "Delete",
      text: "刪除",
    },
    {
      value: 4,
      name: "Deny",
      text: "拒絕",
    },
  ];
}

export function getByValue(value) {
  const type = getAll().find(function (item) {
    return item.value == value;
  });
  return type;
}
