export function getAll() {
  return [
    {
      value: 0,
      name: "NewApply",
      text: "全新申請",
    },
    {
      value: 1,
      name: "AuthorizeApply",
      text: "加值中心授權",
    },
    {
      value: 2,
      name: "B2BApply",
      text: "已申請B2B資格",
    },
  ];
}

export function getByValue(value) {
  const type = getAll().find(function (item) {
    return item.value == value;
  });
  return type;
}
