<template>
  <form
    id="elecInvoiceform"
    class="modal fade"
    tabindex="-1"
    role="dialog"
    data-backdrop="static"
    @submit.prevent="onSaved"
  >
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">電子發票</h5>
          <button type="button" class="close" @click="onClosed">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-12">
              <label for="loadFile">上傳TXT : </label>
              <input id="loadFile" type="file" @change="loadTextFromFile" />
              <hr />
            </div>
            <div class="col-12">
              <span>環境:</span>
              <div class="form-check form-check-inline">
                <input
                  class="form-check-input"
                  type="radio"
                  name="inlineRadioOptions"
                  id="normalEnvironment"
                  value="false"
                  v-model="elecInvoice.IsElecTest"
                />
                <label class="form-check-label" for="normalEnvironment"
                  >正式</label
                >
              </div>
              <div class="form-check form-check-inline">
                <input
                  class="form-check-input"
                  type="radio"
                  name="inlineRadioOptions"
                  id="testEnvironment"
                  value="true"
                  v-model="elecInvoice.IsElecTest"
                />
                <label class="form-check-label" for="testEnvironment"
                  >測試</label
                >
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <label for="businessNumber">統一編號</label>
                <input
                  type="text"
                  class="form-control"
                  id="businessNumber"
                  v-model="elecInvoice.BusinessNumber"
                  required
                />
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <label for="branchno">門市編號</label>
                <input
                  type="text"
                  class="form-control"
                  id="branchno"
                  v-model="elecInvoice.branchno"
                  required
                />
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <label for="posno">POS編號</label>
                <input
                  type="text"
                  class="form-control"
                  id="posno"
                  v-model="elecInvoice.posno"
                  required
                />
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <label for="pname">產品名稱</label>
                <input
                  type="text"
                  class="form-control"
                  id="pname"
                  v-model="elecInvoice.pname"
                  required
                />
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <label for="pid">POS SN</label>
                <input
                  type="text"
                  class="form-control"
                  id="pid"
                  v-model="elecInvoice.pid"
                  required
                />
              </div>
            </div>
            <div class="col-12">
              <div class="form-group">
                <label for="rid">rid</label>
                <input
                  type="text"
                  class="form-control"
                  id="rid"
                  v-model="elecInvoice.rid"
                  required
                />
              </div>
            </div>
            <div class="col-12">
              <div class="form-group">
                <label for="qrcodeKey">Qrcode Key</label>
                <input
                  type="text"
                  class="form-control"
                  id="qrcodeKey"
                  v-model="elecInvoice.QrcodeKey"
                  readonly
                  required
                />
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-success mr-auto"
            @click="onVerify"
          >
            驗證
          </button>
          <button type="submit" class="btn btn-primary">儲存</button>
          <button type="button" class="btn btn-danger" @click="onDeleted">
            刪除
          </button>
          <button type="button" class="btn btn-secondary" @click="onClosed">
            取消
          </button>
        </div>
      </div>
    </div>
  </form>
</template>

<script>
import $ from "jquery";

export default {
  data() {
    return {};
  },
  props: {
    formTrigger: false,
    elecInvoice: {},
  },
  watch: {
    formTrigger() {
      $(`#elecInvoiceform`).modal("toggle");
    },
  },
  methods: {
    onSaved() {
      this.$api.devices
        .saveElecInvoice(this.elecInvoice)
        .then(() => {
          $(`#elecInvoiceform`).modal("toggle");
        })
        .catch((error) => this.$func.message.error(error.data.Message));
    },
    onClosed() {
      $(`#elecInvoiceform`).modal("toggle");
    },
    onDeleted() {
      this.$func.message.confirm("確定刪除嗎 ?").then((result) => {
        if (result.isConfirmed) {
          this.$api.devices
            .deleteElecInvoice(this.elecInvoice.TerminalId)
            .then(() => {
              $(`#elecInvoiceform`).modal("toggle");
            });
        }
      });
    },
    onVerify() {
      this.$api.devices
        .getElecInvoiceVerify(this.elecInvoice)
        .then((data) => {
          this.elecInvoice.QrcodeKey = data;
          this.$func.message.success("成功");
        })
        .catch((error) => this.$func.message.error(error.data.Message));
    },
    loadTextFromFile(e) {
      const file = e.target.files[0];
      if (file == undefined) {
        return;
      }
      const reader = new FileReader();
      reader.onload = () => {
        const strs = reader.result.split(/\r?\n/);
        this.elecInvoice.BusinessNumber = strs[2].split(":")[1].trim();
        this.elecInvoice.branchno = strs[3].split(":")[1].trim();
        this.elecInvoice.posno = strs[4].split(":")[1].trim();
        this.elecInvoice.pid = strs[5].split(":")[1].trim();
        this.elecInvoice.rid = strs[6].split(":")[1].trim();
        this.elecInvoice.pname = strs[7].split(":")[1].trim();
      };
      reader.readAsText(file);
    },
  },
};
</script>
