import { httpGet } from "@/request/http";

export default function(url, fileName, params) {
  httpGet(url, params, { responseType: "blob" }).then(data => {
    const url = window.URL.createObjectURL(new Blob([data]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", `${fileName}.txt`);
    document.body.appendChild(link);
    link.click();
  });
}
