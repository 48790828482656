import { add as mathAdd, subtract, multiply, divide } from "mathjs";

export function add(a, b) {
  return parseFloat(mathAdd(a, b));
}

export function sub(a, b) {
  return parseFloat(subtract(a, b));
}

export function mul(a, b) {
  return parseFloat(multiply(a, b));
}

export function div(a, b) {
  return parseFloat(divide(a, b));
}

export function addFixed(a, b) {
  return parseFloat(mathAdd(a, b).toFixed(2));
}

export function subFixed(a, b) {
  return parseFloat(subtract(a, b).toFixed(2));
}

export function mulFixed(a, b) {
  return parseFloat(multiply(a, b).toFixed(2));
}

export function divFixed(a, b) {
  return parseFloat(divide(a, b).toFixed(2));
}
