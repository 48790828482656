import { httpGet, httpPost, httpDelete } from "@/request/http";

let path = "/foodtastes";

export default {
  getFoods(tasteKindId, foodKindId) {
    return httpGet(`tastekinds/${tasteKindId}/foodkinds/${foodKindId}/foods`);
  },
  getFoodsBySelect(tasteKindId) {
    return httpGet(`tastekinds/${tasteKindId}/foods`);
  },
  postFoods(item) {
    return httpPost(`${path}/addbatch`, item);
  },
  removeFoods(dto) {
    return httpPost(`${path}/deletebatch`, dto);
  },
};
