<template>
  <div>
    <condition-part
      :hasWarehouse="true"
      :hasFood="true"
      :hasAdd="false"
      :hasEdit="false"
      :hasRemove="false"
      :items="items"
      :pagination="pagination"
      :columns="columns"
      :getTrigger="getTrigger"
      @getItems="getItems"
      :noInitialLoading="true"
    >
    </condition-part>
  </div>
</template>

<script>
import conditionPart from "@/components/parts/conditionPart";

export default {
  data() {
    return {
      items: [],
      pagination: {},
      columns: [
        { name: "WarehouseNumber" },
        { name: "WarehouseName" },
        { name: "FoodNumber" },
        { name: "FoodName" },
        { name: "Qty" },
        { name: "Unit" },
      ],
      getTrigger: false,
    };
  },
  components: {
    conditionPart,
  },
  methods: {
    getItems(condition) {
      const vm = this;
      this.$api.stocks
        .getLY(condition)
        .then((data) => {
          data.Datas.forEach((item) => (item.IsEdit = false));
          vm.items = data.Datas;
          vm.pagination = data.Pagination;
        })
        .catch(() => {
          vm.items = [];
          vm.pagination = {};
        });
    },
    onSaved(item) {
      this.$api.stocks.postSafeQty(item).then(() => {
        item.IsEdit = false;
      });
    },
  },
};
</script>
