<template>
  <div>
    <page-table
      :parentPage="pagination"
      :items="modules"
      :columns="columns"
      :idProperty="idProperty"
      :nameProperty="nameProperty"
      :getTrigger="getTrigger"
      @getItems="getModules"
      @addItem="addModule"
      @editItem="editModule"
      @removeItem="removeModule"
      @currentIndexChanged="currentIndexChanged"
      :hasAdd="hasAdd"
      :hasEdit="hasEdit"
      :hasRemove="hasRemove"
    >
      <template slot="button">
        <button
          v-if="hasTerminal && hasEdit"
          type="button"
          class="btn btn-outline-secondary btn-icon"
          @click="onTerminal"
        >
          <i class="fas fa-cash-register"></i>
        </button>
        <button
          v-if="hasEmployee && hasEdit"
          type="button"
          class="btn btn-outline-primary btn-icon"
          @click="onEmployee"
        >
          <i class="fas fa-user"></i>
        </button>
        <slot name="buttons" />
      </template>
    </page-table>

    <item-modal
      :isShowModal="isShowModal"
      @onClosed="isShowModal = !isShowModal"
      @onSaved="saveModule"
      :title="modalTitle"
    >
      <div slot="item">
        <div class="row">
          <div class="col">
            <div class="form-group">
              <label for="moduleName">{{ $t("modal.name") }}</label>
              <input
                type="text"
                class="form-control"
                id="moduleName"
                v-model="module.ModuleName"
                required
              />
            </div>
          </div>
        </div>
      </div>
    </item-modal>
  </div>
</template>

<script>
import pageTable from "@/components/PageTable";
import itemModal from "@/components/ItemModal";

export default {
  data() {
    return {
      modules: [],
      module: {},
      currentModuleId: "",
      pagination: {},
      isShowModal: false,
      modalTitle: "",
      columns: [
        {
          name: "ModuleName",
        },
      ],
      idProperty: "ModuleId",
      nameProperty: "ModuleName",
      getTrigger: false,
    };
  },
  props: {
    moduleType: {
      type: Number,
      default: 0,
    },
    hasTerminal: {
      type: Boolean,
      default: false,
    },
    hasEmployee: {
      type: Boolean,
      default: false,
    },
    hasAdd: {
      type: Boolean,
      default: true,
    },
    hasEdit: {
      type: Boolean,
      default: true,
    },
    hasRemove: {
      type: Boolean,
      default: true,
    },
  },
  components: {
    pageTable,
    itemModal,
  },
  methods: {
    currentIndexChanged(index) {
      const module = this.modules[index];
      if (module) {
        this.currentModuleId = module.ModuleId;
        this.$emit("currentModuleIdChanged", this.currentModuleId);
      } else {
        this.$emit("currentModuleIdChanged", undefined);
      }
    },
    getModules(dTO) {
      if (this.moduleType == undefined) return;
      dTO.type = this.moduleType;
      const vm = this;
      this.$api.modules.getPagesByType(dTO).then((data) => {
        vm.modules = data.Modules;
        vm.pagination = data.Pagination;
        vm.$emit("getModules", vm.modules);
      });
    },
    addModule() {
      this.module = {};
      this.isShowModal = !this.isShowModal;
      this.modalTitle = this.$t("modal.addmodule");
    },
    editModule() {
      const vm = this;
      this.$api.modules.get(vm.currentModuleId).then((data) => {
        vm.module = data;
        vm.isShowModal = !vm.isShowModal;
        vm.modalTitle = this.$t("modal.editmodule");
      });
    },
    removeModule() {
      const vm = this;
      this.$api.modules.remove(vm.currentModuleId).then(() => {
        vm.getTrigger = !vm.getTrigger;
      });
    },
    saveModule() {
      const vm = this;
      this.module.ModuleType = this.moduleType;
      this.$api.modules.save(vm.module).then(() => {
        vm.isShowModal = !vm.isShowModal;
        vm.getTrigger = !vm.getTrigger;
      });
    },
    getCurrentId() {
      if (!this.currentModuleId || this.currentModuleId.length == 0) {
        this.$func.message.alert("請選擇一筆資料 !");
        return false;
      }
      return true;
    },
    onTerminal() {
      if (!this.getCurrentId()) return;
      const vm = this;
      this.$router.push({
        name: "ModuleTerminal",
        params: { id: vm.currentModuleId },
      });
    },
    onEmployee() {
      if (!this.getCurrentId()) return;
      const vm = this;
      this.$router.push({
        name: "ModuleEmployee",
        params: { id: vm.currentModuleId },
      });
    },
  },
  watch: {
    moduleType() {
      this.getTrigger = !this.getTrigger;
    },
  },
};
</script>
