<template>
  <div>
    <modules
      :moduleType="moduleType"
      @currentModuleIdChanged="currentModuleIdChanged"
      :hasTerminal="true"
      :hasAdd="authority.IsAdd"
      :hasEdit="authority.IsUpdate"
      :hasRemove="authority.IsRemove"
    >
      <template slot="buttons">
        <button
          type="button"
          class="btn btn-outline-primary btn-icon py-2 px-4"
          data-toggle="tooltip"
          data-placement="top"
          :title="`${$t('button.foodkind')}`"
          v-if="authority.IsUpdate"
          @click="foodKindClick"
        >
          <i class="fa fa-project-diagram"></i>
        </button>
        <button
          type="button"
          class="btn btn-outline-primary btn-icon py-2 px-4"
          data-toggle="tooltip"
          data-placement="top"
          :title="`${$t('button.food')}`"
          v-if="authority.IsUpdate"
          @click="foodClick"
        >
          <i class="fa fa-hamburger"></i>
        </button>
      </template>
    </modules>

    <router-view />
  </div>
</template>

<script>
import modules from "@/components/modules/Modules";

export default {
  data() {
    return {
      moduleType: 2,
      currentModuleId: "",
      authority: {},
    };
  },
  components: {
    modules,
  },

  methods: {
    isSelected() {
      if (this.currentModuleId && this.currentModuleId.length > 0) {
        return true;
      } else {
        this.$func.message.alert("請選擇一筆模組 !");
        return false;
      }
    },
    currentModuleIdChanged(id) {
      this.currentModuleId = id;
    },
    foodKindClick() {
      if (!this.isSelected()) {
        return;
      }
      this.$router.push({
        name: "ModuleFoodKind",
        params: { id: this.currentModuleId },
      });
    },
    foodClick() {
      if (!this.isSelected()) {
        return;
      }
      this.$router.push({
        name: "ModuleFood",
        params: { id: this.currentModuleId },
      });
    },
  },
  created() {
    this.authority = this.$func.localStorage.getAuthByName(this.$route.name);
  },
};
</script>
