<template>
  <div>
    <condition-part
      :hasDate="true"
      :hasTradeStatusType="true"
      :hasReportSourceType="true"
      :hasEatType="true"
      :hasTimeType="true"
      :hasStore="true"
      :hasEmployee="true"
      :hasTerminal="true"
      :hasExcel="true"
      :hasPdf="true"
      :hasAdd="false"
      :hasEdit="false"
      :hasRemove="false"
      :columns="columns"
      :items="items"
      :pagination="pagination"
      :isSortable="true"
      @getItems="getItems"
      @downloadExcel="downloadExcel"
      @printPdf="printPdf"
      @downloadPdf="downloadPdf"
    >
      <div class="row" v-if="timeData.rows.length > 0" slot="middleArea">
        <div class="col-md-6">
          <div class="card card-table card-body">
            <ve-histogram :data="timeData" :settings="timeSettings" />
          </div>
        </div>
        <div class="col-md-6">
          <div class="card card-table card-body">
            <ve-pie :data="timeData"></ve-pie>
          </div>
        </div>
      </div>
    </condition-part>
  </div>
</template>

<script>
import conditionPart from "@/components/parts/conditionPart";

export default {
  data() {
    return {
      items: [],
      pagination: {},
      columns: [
        {
          name: "Time",
        },
        {
          name: "Total",
        },
        {
          name: "Cash",
        },
        {
          name: "CreditCardPrice",
        },
        {
          name: "People",
        },
        {
          name: "SaleCounts",
        },
        {
          name: "VoidCounts",
        },
        {
          name: "FoodCounts",
        },
      ],
      timeSettings: {
        labelMap: {
          Total: "總計",
          People: "人數",
          SaleCounts: "次數",
        },
        axisSite: { right: ["Prople", "SaleCounts"] },
        yAxisType: ["KMB", "normal"],
        yAxisName: ["金額", "數量"],
      },
      timeData: {
        columns: ["Time", "Total", "People", "SaleCounts"],
        rows: [],
      },
    };
  },
  components: {
    conditionPart,
  },
  methods: {
    downloadExcel(condition) {
      this.$api.reportExcels.getTimeStatistic(condition);
    },
    downloadPdf(condition) {
      this.$api.reportPdfs.getTimeStatistic(condition);
    },
    printPdf(condition) {
      this.$api.reportPdfs.printTimeStatistic(condition);
    },
    getItems(condition) {
      const vm = this;
      this.$api.reports.getTimeStatistic(condition).then((data) => {
        vm.items = data.Datas;
        vm.timeData.rows = data.Datas;
        vm.pagination = data.Pagination;
      });
    },
  },
};
</script>
