import i18n from "@/langs/i18n";

export function getAll() {
  return [
    {
      value: 0,
      name: "none",
      text: `${i18n.t("option.vehicleType.none")}`,
    },
    {
      value: 1,
      name: "cellphone",
      text: `${i18n.t("option.vehicleType.cellphone")}`,
    },
    {
      value: 2,
      name: "nature",
      text: `${i18n.t("option.vehicleType.nature")}`,
    },
    {
      value: 3,
      name: "donate",
      text: `${i18n.t("option.vehicleType.donate")}`,
    },
    {
      value: 4,
      name: "member",
      text: `${i18n.t("option.vehicleType.member")}`,
    },
  ];
}
