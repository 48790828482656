import func from "../funcs";
import store from "../store";

import Vue from "vue";
import VueRouter from "vue-router";

import PrivacyPolicy from "@/components/PrivacyPolicy";

import Dashboard from "@/components/Dashboard";
import Home from "@/components/Home";
import Accounts from "@/views/datas/Accounts";
import Stores from "@/views/datas/Stores";
import Terminals from "@/views/datas/Terminals";
import Employees from "@/views/datas/Employees";
import FoodMajorKinds from "@/views/datas/FoodMajorKinds";
import FoodKinds from "@/views/datas/FoodKinds";
import Foods from "@/views/datas/Foods";
import Specifications from "@/components/datas/Specifications";
import FoodBrands from "@/components/datas/FoodBrands";

import Functions from "@/views/datas/Functions";
import Businesses from "@/views/datas/Businesses";
import Users from "@/views/datas/Users";
import Devices from "@/views/datas/Devices";
import PriceLabels from "@/views/datas/PriceLabels";
import Departments from "@/views/datas/Departments";

import TasteKinds from "@/views/datas/TasteKinds";
import FoodKindTastes from "@/components/datas/FoodKindTastes";
import FoodTastes from "@/components/datas/FoodTastes";

import ComboKinds from "@/views/datas/ComboKinds";
import FoodCombos from "@/components/datas/FoodCombos";
import ComboAdds from "@/components/datas/ComboAdd";

import Pays from "@/views/datas/Pays";
import DirectoryKinds from "@/views/datas/DirectoryKinds";

import UtaiForms from "@/views/datas/UtaiForms";
import InvoicePrizes from "@/views/datas/InvoicePrizes";
import UShowForms from "@/views/datas/UShowForms";

import Brands from "@/views/datas/Brands";

//Log
import ErrorMessage from "@/views/datas/ErrorMessage";
import ThirdParty from "@/views/datas/ThirdParty";
import System from "@/views/datas/System";
import Notification from "@/views/datas/Notification";
import Webhook from "@/views/datas/Webhook";

//Modules
import ModuleAuthority from "@/components/modules/ModuleAuthority";
import ModuleInvisible from "@/components/modules/ModuleInvisible";
import ModuleEmployee from "@/components/modules/ModuleEmployee";
import ModuleFoodKind from "@/components/modules/ModuleFoodKind";
import ModuleFood from "@/components/modules/ModuleFood";
import ModuleStore from "@/components/modules/ModuleStore";
import ModuleStoreSelect from "@/components/modules/ModuleStoreSelect";
import Authority from "@/components/modules/Authority";
import ModuleTerminal from "@/components/modules/ModuleTerminal";
import ModulePromotion from "@/components/modules/ModulePromotion";
import Promotion from "@/components/modules/Promotion";
import ChangePrice from "@/components/modules/ChangePrice";

import ModuleSequence from "@/components/modules/ModuleSequence";
import FoodKindSequence from "@/components/modules/FoodKindSequence";
import FoodSequence from "@/components/modules/FoodSequence";

import ModuleFunction from "@/components/modules/ModuleFunction";
import FunctionButton from "@/components/modules/FunctionButton";

import DayModule from "@/components/modules/DayModule";
import Day from "@/components/modules/Day";

import Downloads from "@/components/Downloads";
import DownloadAdd from "@/components/DownloadAdd";

import Login from "@/components/Login";

//Report
import ReportSale from "@/components/reports/Sale";
import ReportSalData from "@/components/reports/SalData";
import ReportInvoice from "@/components/reports/Invoice";
import ReportInvoicePrize from "@/components/reports/InvoicePrize";
import ReportFree from "@/components/reports/Free";
import ReportCustomerFree from "@/components/reports/CustomerFree";
import FoodMajorKindStatistic from "@/components/reports/FoodMajorKindStatistic";
import FoodKindStatistic from "@/components/reports/FoodKindStatistic";
import FoodSaleStatistic from "@/components/reports/FoodSaleStatistic";
import FoodDayStatistic from "@/components/reports/FoodDayStatistic";
import ReportFoodSale from "@/components/reports/FoodSale";
import TimeStatistic from "@/components/reports/TimeStatistic";
import TasteStatistic from "@/components/reports/TasteStatistic";
import EmployeeAnalysis from "@/components/reports/EmployeeAnalysis";
import ReportWorkRecord from "@/components/reports/WorkRecord";
import ReportClassSale from "@/components/reports/ClassSale";
import ReportSaler from "@/components/reports/Saler";
import ReportPurchase from "@/components/reports/Purchase";
import ReportPurchaseReturn from "@/components/reports/PurchaseReturn";
import ReportPurchaseOrder from "@/components/reports/PurchaseOrder";
import ReportSupplierPurchaseOrder from "@/components/reports/SupplierPurchaseOrder";
import ReportFoodPurchaseOrder from "@/components/reports/FoodPurchaseOrder";
import ReportFoodPurchase from "@/components/reports/FoodPurchase";
import ReportStockAdjust from "@/components/reports/StockAdjust";
import ReportStockAdjustDirectory from "@/components/reports/StockAdjustDirectory";
import ReportStockTransfers from "@/components/reports/StockTransfers";
import ReportInventory from "@/components/reports/Inventory";
import ReportPeople from "@/components/reports/People";
import ReportIntegral from "@/components/reports/Integral";
import ReportSaleOrder from "@/components/reports/SaleOrder";
import ReportCoupon from "@/components/reports/Coupon";
import ReportOrderFoodStatistic from "@/components/reports/OrderFoodStatistic";
import ReportCollectionPayment from "@/components/reports/CollectionPayment";
import ReportSaleProfit from "@/components/reports/SaleProfit";
import ReportCreditCard from "@/components/reports/CreditCard";
import ReportCustomerStock from "@/components/reports/CustomerStock";
import ReportCustomerStockMovement from "@/components/reports/CustomerStockMovement";
import ReportCustomerFoodSale from "@/components/reports/CustomerFoodSale";
import ReportCustomerSaleStatistic from "@/components/reports/CustomerSaleStatistic";

import ReportPay from "@/components/reports/Pay";
import ReportSalePay from "@/components/reports/SalePay";

import Stock from "@/components/reports/Stock";
import ReportSafeQty from "@/components/reports/SafeQty";
import ReportFoodMovement from "@/components/reports/FoodMovement";
import ReportInventoryPAndL from "@/components/reports/InventoryPAndL";

import ReportCustomer from "@/components/reports/Customer";
import ReportDeposit from "@/components/reports/DepositTrade";
import ReportSupplier from "@/components/reports/Supplier";
import ReportSupplierFoodSale from "@/components/reports/SupplierFoodSale";
import ReportSupplierFoodSaleOriginal from "@/components/reports/SupplierFoodSaleOriginal";
import ReportPGTalkDeposit from "@/components/reports/PGTalkDeposit";

import ReportStockBatch from "@/components/reports/StockBatch";
import ReportStockBatchMovement from "@/components/reports/StockBatchMovement";

import ReportERPSummary from "@/components/reports/ERPSummary";

import ErrorView from "@/components/Error";

//ERP
import Warehouses from "@/views/datas/Warehouses";
import Suppliers from "@/views/datas/Suppliers";
import SupplierFoods from "@/components/erps/SupplierFoods";
import StockAdjusts from "@/components/erps/StockAdjusts";
import StockAdjustData from "@/components/erps/StockAdjustData";
import StockTransfers from "@/components/erps/StockTransfers";
import StockTransferData from "@/components/erps/StockTransferData";
import Purchases from "@/components/erps/Purchases";
import PurchaseData from "@/components/erps/PurchaseData";
import PurchaseOrders from "@/components/erps/PurchaseOrders";
import PurchaseOrderData from "@/components/erps/PurchaseOrderData";
import Sals from "@/components/erps/Sals";
import SalData from "@/components/erps/SalData";
import Inventorys from "@/components/erps/Inventorys";
import InventoryData from "@/components/erps/InventoryData";
import InventoryMachine from "@/components/erps/InventoryMachine";
import SafeQty from "@/components/erps/SafeQty";
import LYStock from "@/components/erps/LYStock";
import DWStock from "@/components/erps/DWStock";

//Account
import Receipts from "@/components/accounts/Receipts";
import ReceiptData from "@/components/accounts/ReceiptData";
import Receivables from "@/components/accounts/Receivables";
import Payments from "@/components/accounts/Payments";
import PaymentData from "@/components/accounts/PaymentData";
import Payables from "@/components/accounts/Payables";
import PYReceivables from "@/components/accounts/PYReceivables";

//Customer
import Customers from "@/components/customers/Customers";
import MemberSetting from "@/components/customers/MemberSetting";
import MemberUpgradeRules from "@/components/customers/MemberUpgradeRules";
import IntegralRules from "@/components/customers/IntegralRules";
import IntegralFoodKind from "@/components/customers/IntegralFoodKind";
import IntegralFood from "@/components/customers/IntegralFood";
import OcardRules from "@/components/customers/OcardRules";
import MemberWeb from "@/components/customers/MemberWeb";

//Dining
// import TableMaps from "@/components/dinings/tableMaps";

//OnlineOrder
import DirDayModule from "@/components/modules/DirDayModule";
import DirModuleDevice from "@/components/modules/DirectoryModuleDevice";
import DirDay from "@/components/modules/DirectoryDay";
import ModuleOnlineOrderFood from "@/components/modules/ModuleOnlineOrderFood";
import OnlineOrderStore from "@/components/onlineOrders/OnlineOrderStore";
import UberEats from "@/views/uberEats";
import UberEatsToken from "@/views/uberEatsToken";
import LineOrderSetting from "@/components/onlineOrders/LineOrderSetting";
import TableMaps from "@/components/onlineOrders/TableMaps";

//Combination
import Combinations from "@/views/combinations/Combinations";
import CombinationData from "@/views/combinations/CombinationData";
import CombinationTrades from "@/views/combinations/CombinationTrades";
import CombinationTradeData from "@/views/combinations/CombinationTradeData";
import SeperationTrades from "@/views/combinations/SeperationTrades";
import SeperationTradeData from "@/views/combinations/SeperationTradeData";
import ReportCombinationTrade from "@/components/reports/CombinationTrade";
import ReportSeperationTrade from "@/components/reports/SeperationTrade";

Vue.use(VueRouter);

const router = new VueRouter({
  mode: "history",
  routes: [
    {
      path: "/",
      component: Dashboard,
      children: [
        {
          name: "Home",
          path: "",
          component: Home,
        },
        {
          name: "Stores",
          path: "stores",
          component: Stores,
        },
        {
          name: "Businesses",
          path: "businesses",
          component: Businesses,
        },
        {
          name: "Devices",
          path: "businesses/:id/devices",
          component: Devices,
        },
        {
          name: "Users",
          path: "users",
          component: Users,
        },
        {
          name: "UtaiForms",
          path: "utai-forms",
          component: UtaiForms,
        },
        {
          name: "InvoicePrizes",
          path: "invoiceprizes",
          component: InvoicePrizes,
        },
        {
          name: "UShowForms",
          path: "ushow-forms",
          component: UShowForms,
        },
        {
          name: "ErrorMessage",
          path: "errormessage",
          component: ErrorMessage,
        },
        {
          name: "ThirdParty",
          path: "thirdparty",
          component: ThirdParty,
        },
        {
          name: "System",
          path: "system",
          component: System,
        },
        {
          name: "Notification",
          path: "notification",
          component: Notification,
        },
        {
          name: "Webhook",
          path: "webhook",
          component: Webhook,
        },
        {
          name: "Accounts",
          path: "accounts",
          component: Accounts,
        },
        {
          name: "Terminals",
          path: "terminals",
          component: Terminals,
        },
        {
          name: "Employees",
          path: "employees",
          component: Employees,
        },
        {
          name: "Customers",
          path: "customers",
          component: Customers,
        },
        {
          name: "MemberSetting",
          path: "membersetting",
          component: MemberSetting,
        },
        {
          name: "MemberUpgradeRules",
          path: "memberupgraderules",
          component: MemberUpgradeRules,
        },
        {
          name: "IntegralRules",
          path: "integralrules",
          component: IntegralRules,
        },
        {
          name: "IntegralFoodKind",
          path: "integralrules/foodkind",
          component: IntegralFoodKind,
        },
        {
          name: "IntegralFood",
          path: "integralrules/food",
          component: IntegralFood,
        },
        {
          name: "OcardRules",
          path: "ocardrules",
          component: OcardRules,
        },
        {
          name: "MemberWeb",
          path: "memberweb",
          component: MemberWeb,
        },
        {
          name: "FoodMajorKinds",
          path: "foodmajorkinds",
          component: FoodMajorKinds,
        },
        {
          name: "FoodKinds",
          path: "foodkinds",
          component: FoodKinds,
        },
        {
          name: "Foods",
          path: "foods",
          component: Foods,
        },
        {
          name: "Specifications",
          path: "foods/:id/specifications",
          component: Specifications,
        },
        {
          name: "FoodBrands",
          path: "foods/:id/foodbrands",
          component: FoodBrands,
        },
        {
          name: "Departments",
          path: "departments",
          component: Departments,
        },
        {
          name: "PriceLabels",
          path: "pricelabels",
          component: PriceLabels,
        },
        {
          name: "TasteKinds",
          path: "tastekinds",
          component: TasteKinds,
        },
        {
          name: "FoodKindTastes",
          path: "tastekinds/:id/foodkindtastes",
          component: FoodKindTastes,
        },
        {
          name: "FoodTastes",
          path: "tastekinds/:id/foodtastes",
          component: FoodTastes,
        },
        {
          name: "ComboKinds",
          path: "combokinds",
          component: ComboKinds,
        },
        {
          name: "Functions",
          path: "functions",
          component: Functions,
        },
        {
          name: "FoodCombos",
          path: "combokinds/:id/foodcombos",
          component: FoodCombos,
        },
        {
          name: "ComboAdd",
          path: "combokinds/:id/comboadd",
          component: ComboAdds,
        },
        {
          name: "Pays",
          path: "pays",
          component: Pays,
        },
        {
          name: "DirectoryKinds",
          path: "directorykinds",
          component: DirectoryKinds,
        },
        {
          name: "Downloads",
          path: "downloads",
          component: Downloads,
        },
        {
          name: "DownloadAdd",
          path: "downloadadd",
          component: DownloadAdd,
        },
        {
          name: "Warehouses",
          path: "warehouses",
          component: Warehouses,
        },
        {
          name: "Suppliers",
          path: "suppliers",
          component: Suppliers,
        },
        {
          name: "Brands",
          path: "brands",
          component: Brands,
        },
        {
          name: "SupplierFoods",
          path: "suppliers/:id/foods",
          component: SupplierFoods,
        },
        {
          name: "ModuleAuthority",
          path: "modules/authority",
          component: ModuleAuthority,
        },
        {
          name: "ModulePromotion",
          path: "modules/promotion",
          component: ModulePromotion,
        },
        {
          name: "Promotion",
          path: "modules/:id/type/:type/promotions/:isSame",
          component: Promotion,
        },
        {
          name: "ChangePrice",
          path: "modules/:id/type/:type/ChangePrice",
          component: ChangePrice,
        },
        {
          name: "ModuleFoodKind",
          path: "modules/:id/foodkinds",
          component: ModuleFoodKind,
        },
        {
          name: "ModuleFood",
          path: "modules/:id/foods",
          component: ModuleFood,
        },
        {
          name: "ModuleStore",
          path: "modules/stores",
          component: ModuleStore,
        },
        {
          name: "ModuleStoreSelect",
          path: "modules/:id/stores",
          component: ModuleStoreSelect,
        },
        {
          name: "ModuleTerminal",
          path: "modules/:id/terminals",
          component: ModuleTerminal,
        },
        {
          name: "ModuleEmployee",
          path: "modules/:id/employees",
          component: ModuleEmployee,
        },
        {
          name: "Authority",
          path: "modules/:id/authoritys",
          component: Authority,
        },
        {
          name: "ModuleInvisible",
          path: "modules/invisible",
          component: ModuleInvisible,
        },
        {
          name: "ModuleOnlineOrderFood",
          path: "modules/onlineorder-food",
          component: ModuleOnlineOrderFood,
        },
        {
          name: "ModuleSequence",
          path: "modules/sequence",
          component: ModuleSequence,
        },
        {
          name: "FoodKindSequence",
          path: "modules/:id/sequence/foodkinds",
          component: FoodKindSequence,
        },
        {
          name: "FoodSequence",
          path: "modules/:id/sequence/foods",
          component: FoodSequence,
        },
        {
          name: "ModuleFunction",
          path: "modules/function",
          component: ModuleFunction,
        },
        {
          name: "FunctionButton",
          path: "modules/:id/function/buttons",
          component: FunctionButton,
        },
        {
          name: "DayModule",
          path: "modules/day",
          component: DayModule,
        },
        {
          name: "Day",
          path: "modules/:id/day",
          component: Day,
        },
        {
          name: "OnlineOrderWork",
          path: "onlineorder-work",
          component: DirDayModule,
        },
        {
          name: "DirModuleDevice",
          path: "dirmodules/:id/devices",
          component: DirModuleDevice,
        },
        {
          name: "DirDay",
          path: "dirmodules/:id/day",
          component: DirDay,
        },
        {
          name: "ReportSale",
          path: "reports/sale",
          component: ReportSale,
        },
        {
          name: "ReportSaler",
          path: "reports/saler",
          component: ReportSaler,
        },
        {
          name: "ReportClassSale",
          path: "reports/classsale",
          component: ReportClassSale,
        },
        {
          name: "ReportSalData",
          path: "reports/sale/:id",
          component: ReportSalData,
        },
        {
          name: "ReportInvoice",
          path: "reports/invoice",
          component: ReportInvoice,
        },
        {
          name: "ReportInvoicePrize",
          path: "reports/invoiceprize",
          component: ReportInvoicePrize,
        },
        {
          name: "ReportWorkRecord",
          path: "reports/workrecord",
          component: ReportWorkRecord,
        },
        {
          name: "ReportCoupon",
          path: "reports/coupon",
          component: ReportCoupon,
        },
        {
          name: "FoodMajorKindStatistic",
          path: "reports/foodmajorkindstatistic",
          component: FoodMajorKindStatistic,
        },
        {
          name: "FoodKindStatistic",
          path: "reports/foodkindstatistic",
          component: FoodKindStatistic,
        },
        {
          name: "FoodSaleStatistic",
          path: "reports/foodsalestatistic",
          component: FoodSaleStatistic,
        },
        {
          name: "FoodDayStatistic",
          path: "reports/fooddaystatistic",
          component: FoodDayStatistic,
        },
        {
          name: "ReportFoodSale",
          path: "reports/foodsale",
          component: ReportFoodSale,
        },
        {
          name: "ReportFree",
          path: "reports/free",
          component: ReportFree,
        },
        {
          name: "ReportCustomerFree",
          path: "reports/customerfree",
          component: ReportCustomerFree,
        },
        {
          name: "ReportCollectionPayment",
          path: "reports/collectionpayment",
          component: ReportCollectionPayment,
        },
        {
          name: "ReportSaleProfit",
          path: "reports/saleprofit",
          component: ReportSaleProfit,
        },
        {
          name: "ReportCreditCard",
          path: "reports/creditcard",
          component: ReportCreditCard,
        },
        {
          name: "TimeStatistic",
          path: "reports/timestatistic",
          component: TimeStatistic,
        },
        {
          name: "TasteStatistic",
          path: "reports/tastetatistic",
          component: TasteStatistic,
        },
        {
          name: "EmployeeAnalysis",
          path: "reports/employeeanalysis",
          component: EmployeeAnalysis,
        },
        {
          name: "Stock",
          path: "reports/stock",
          component: Stock,
        },
        {
          name: "ReportSafeQty",
          path: "reports/safeqty",
          component: ReportSafeQty,
        },
        {
          name: "ReportFoodMovement",
          path: "reports/foodmovement",
          component: ReportFoodMovement,
        },
        {
          name: "ReportPay",
          path: "reports/pay",
          component: ReportPay,
        },
        {
          name: "ReportDeposit",
          path: "reports/deposit",
          component: ReportDeposit,
        },
        {
          name: "ReportInventoryPAndL",
          path: "reports/inventorypandl",
          component: ReportInventoryPAndL,
        },
        {
          name: "ReportCustomer",
          path: "reports/customer",
          component: ReportCustomer,
        },
        {
          name: "ReportSupplier",
          path: "reports/supplier",
          component: ReportSupplier,
        },
        {
          name: "ReportSupplierFoodSale",
          path: "reports/supplierfoodsale",
          component: ReportSupplierFoodSale,
        },
        {
          name: "ReportSupplierFoodSaleOriginal",
          path: "reports/supplierfoodsaleoriginal",
          component: ReportSupplierFoodSaleOriginal,
        },
        {
          name: "ReportSalePay",
          path: "reports/salepay",
          component: ReportSalePay,
        },
        {
          name: "ReportPurchase",
          path: "reports/purchase",
          component: ReportPurchase,
        },
        {
          name: "ReportPurchaseReturn",
          path: "reports/purchasereturn",
          component: ReportPurchaseReturn,
        },
        {
          name: "ReportPurchaseOrder",
          path: "reports/purchaseorder",
          component: ReportPurchaseOrder,
        },
        {
          name: "ReportSupplierPurchaseOrder",
          path: "reports/supplierpurchaseorder",
          component: ReportSupplierPurchaseOrder,
        },
        {
          name: "ReportFoodPurchaseOrder",
          path: "reports/foodpurchaseorder",
          component: ReportFoodPurchaseOrder,
        },
        {
          name: "ReportFoodPurchase",
          path: "reports/foodpurchase",
          component: ReportFoodPurchase,
        },
        {
          name: "ReportStockAdjust",
          path: "reports/stockadjust",
          component: ReportStockAdjust,
        },
        {
          name: "ReportStockAdjustDirectory",
          path: "reports/stockadjustdirectory",
          component: ReportStockAdjustDirectory,
        },
        {
          name: "ReportStockTransfers",
          path: "reports/stockTransfers",
          component: ReportStockTransfers,
        },
        {
          name: "ReportInventory",
          path: "reports/inventory",
          component: ReportInventory,
        },
        {
          name: "ReportSaleOrder",
          path: "reports/saleorder",
          component: ReportSaleOrder,
        },
        {
          name: "ReportOrderFoodStatistic",
          path: "reports/orderfoodstatistic",
          component: ReportOrderFoodStatistic,
        },
        {
          name: "ReportCombinationTrade",
          path: "reports/combinationtrade",
          component: ReportCombinationTrade,
        },
        {
          name: "ReportSeperationTrade",
          path: "reports/seperationtrade",
          component: ReportSeperationTrade,
        },
        {
          name: "ReportCustomerStock",
          path: "reports/customerstock",
          component: ReportCustomerStock,
        },
        {
          name: "ReportCustomerStockMovement",
          path: "reports/customerstockmovement",
          component: ReportCustomerStockMovement,
        },
        {
          name: "ReportCustomerFoodSale",
          path: "reports/customerfoodsale",
          component: ReportCustomerFoodSale,
        },
        {
          name: "ReportCustomerSaleStatistic",
          path: "reports/customersalestatistic",
          component: ReportCustomerSaleStatistic,
        },
        {
          name: "ReportPGTalkDeposit",
          path: "reports/pgtalkdeposit",
          component: ReportPGTalkDeposit,
        },
        {
          name: "ReportStockBatch",
          path: "reports/stockbatch",
          component: ReportStockBatch,
        },
        {
          name: "ReportStockBatchMovement",
          path: "reports/stockbatchmovement",
          component: ReportStockBatchMovement,
        },
        {
          name: "ReportERPSummary",
          path: "reports/erpsummary",
          component: ReportERPSummary,
        },
        {
          name: "StockAdjusts",
          path: "stockadjusts",
          component: StockAdjusts,
        },
        {
          name: "StockAdjustAdd",
          path: "stockadjustdata",
          component: StockAdjustData,
        },
        {
          name: "StockAdjustEdit",
          path: "stockadjusts/data/:id",
          component: StockAdjustData,
        },
        {
          name: "StockTransfers",
          path: "stocktransfers",
          component: StockTransfers,
        },
        {
          name: "StockTransferAdd",
          path: "stocktransferdata",
          component: StockTransferData,
        },
        {
          name: "StockTransferEdit",
          path: "stocktransferdata/:id",
          component: StockTransferData,
        },
        {
          name: "StockTransferCheck",
          path: "stocktransfercheck/:id",
          component: StockTransferData,
        },
        {
          name: "Purchases",
          path: "purchases",
          component: Purchases,
        },
        {
          name: "PurchaseAdd",
          path: "purchasedata",
          component: PurchaseData,
        },
        {
          name: "PurchaseEdit",
          path: "purchasedata/:id",
          component: PurchaseData,
        },
        {
          name: "PurchaseOrderTurn",
          path: "purchaseorders/:orderid/purchasedata",
          component: PurchaseData,
        },
        {
          name: "PurchaseOrders",
          path: "purchaseorders",
          component: PurchaseOrders,
        },
        {
          name: "PurchaseOrderAdd",
          path: "purchaseorderdata",
          component: PurchaseOrderData,
        },
        {
          name: "PurchaseOrderEdit",
          path: "purchaseorderdata/:id",
          component: PurchaseOrderData,
        },
        {
          name: "PurchaseReturns",
          path: "purchasereturns",
          component: Purchases,
        },
        {
          name: "PurchaseReturnAdd",
          path: "purchasereturndata",
          component: PurchaseData,
        },
        {
          name: "PurchaseReturnEdit",
          path: "purchasereturndata/:id",
          component: PurchaseData,
        },
        {
          name: "Sals",
          path: "sals",
          component: Sals,
        },
        {
          name: "SalAdd",
          path: "saldata",
          component: SalData,
        },
        {
          name: "SalEdit",
          path: "saldata/:id",
          component: SalData,
        },
        {
          name: "SalReturns",
          path: "salreturns",
          component: Sals,
        },
        {
          name: "SalReturnAdd",
          path: "salreturndata",
          component: SalData,
        },
        {
          name: "SalReturnEdit",
          path: "salreturndata/:id",
          component: SalData,
        },
        {
          name: "SafeQty",
          path: "safeqty",
          component: SafeQty,
        },
        {
          name: "LYStock",
          path: "lystock",
          component: LYStock,
        },
        {
          name: "DWStock",
          path: "dwstock",
          component: DWStock,
        },
        {
          name: "Inventorys",
          path: "inventorys",
          component: Inventorys,
        },
        {
          name: "InventoryAdd",
          path: "inventorydata",
          component: InventoryData,
        },
        {
          name: "InventoryEdit",
          path: "inventorydata/:id",
          component: InventoryData,
        },
        {
          name: "InventoryMachine",
          path: "inventorymachine",
          component: InventoryMachine,
        },
        {
          name: "Payments",
          path: "payments",
          component: Payments,
        },
        {
          name: "PaymentAdd",
          path: "paymentdata",
          component: PaymentData,
        },
        {
          name: "PaymentEdit",
          path: "paymentdata/:id",
          component: PaymentData,
        },
        {
          name: "Payables",
          path: "payables",
          component: Payables,
        },
        {
          name: "Receipts",
          path: "receipts",
          component: Receipts,
        },
        {
          name: "ReceiptAdd",
          path: "receiptdata",
          component: ReceiptData,
        },
        {
          name: "ReceiptEdit",
          path: "receiptdata/:id",
          component: ReceiptData,
        },
        {
          name: "Receivables",
          path: "receivables",
          component: Receivables,
        },
        {
          name: "PYReceivables",
          path: "pyreceivables",
          component: PYReceivables,
        },
        // {
        //   name: "TableMaps",
        //   path: "tablemaps",
        //   component: TableMaps,
        // },
        {
          name: "ReportPeople",
          path: "reports/people",
          component: ReportPeople,
        },
        {
          name: "ReportIntegral",
          path: "reports/integral",
          component: ReportIntegral,
        },

        {
          name: "OnlineOrderStore",
          path: "onlineorder-store",
          component: OnlineOrderStore,
        },
        {
          name: "TableMaps",
          path: "onlineorder-store/:id/tablemaps/:tablemapid/type/:type",
          component: TableMaps,
        },
        {
          name: "UberEats",
          path: "ubereats",
          component: UberEats,
        },
        {
          name: "LineOrderSetting",
          path: "lineordersetting/:id",
          component: LineOrderSetting,
        },
        {
          name: "Combinations",
          path: "combinations",
          component: Combinations,
        },
        {
          name: "CombinationEdit",
          path: "combinationdata/:id",
          component: CombinationData,
        },
        {
          name: "CombinationTrades",
          path: "combinationtrades",
          component: CombinationTrades,
        },
        {
          name: "CombinationTradeAdd",
          path: "combinationtradedata",
          component: CombinationTradeData,
        },
        {
          name: "CombinationTradeEdit",
          path: "combinationtradedata/:id",
          component: CombinationTradeData,
        },
        {
          name: "SeperationTrades",
          path: "seperationtrades",
          component: SeperationTrades,
        },
        {
          name: "SeperationTradeAdd",
          path: "seperationtradedata",
          component: SeperationTradeData,
        },
        {
          name: "SeperationTradeEdit",
          path: "seperationtradedata/:id",
          component: SeperationTradeData,
        },
        {
          name: "CombinationTradeReturn",
          path: "combinationtrade/:id/seperation",
          component: SeperationTradeData,
        },
      ],
    },
    {
      name: "UberEatsToken",
      path: "/ubereats/accesstoken",
      component: UberEatsToken,
    },
    {
      name: "PrivacyPolicy",
      path: "/privacypolicy",
      component: PrivacyPolicy,
    },
    {
      name: "Login",
      path: "/login",
      component: Login,
    },
    {
      name: "Error",
      path: "*",
      component: ErrorView,
    },
  ],
});

router.beforeEach((to, from, next) => {
  store.dispatch("setSaveOrClose", false);
  const auth = func.localStorage.getAuthByName(to.name);
  if (auth && auth.IsList) {
    next();
  } else {
    next({ name: "Home" });
  }
});

export default router;
