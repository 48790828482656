<template>
  <div>
    <page-table
      :hasAdd="false"
      :hasRemove="false"
      :parentPage="pagination"
      @getItems="getTerminals"
      @addItem="addTerminal"
      @editItem="editTerminal"
      @removeItem="removeTerminal"
      @trdbClicked="editTerminal"
      :items="terminals"
      :columns="columns"
      :idProperty="idProperty"
      :nameProperty="nameProperty"
      :getTrigger="getTrigger"
      :hasEdit="authority.IsUpdate"
      :isSortable="true"
    >
      <template slot="button" slot-scope="props">
        <button
          type="button"
          class="btn btn-outline-info btn-icon"
          v-if="hasCloudElecInvoice"
          @click="showElecInvoice(props.index)"
        >
          <i class="fas fa-file-invoice-dollar"></i></button></template
    ></page-table>

    <item-modal
      :isShowModal="isShowModal"
      @onClosed="isShowModal = !isShowModal"
      @onSaved="saveTerminal"
      :title="modalTitle"
    >
      <div slot="item">
        <div class="row">
          <div class="col-md-6">
            <div class="form-group">
              <label for="storeId">{{ $t("title.store") }}:</label>
              <select
                class="form-control"
                id="storeId"
                v-model="terminal.StoreId"
              >
                <option
                  v-for="store in stores"
                  :value="store.StoreId"
                  :key="store.StoreId"
                >
                  {{ store.StoreName }}
                </option>
              </select>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label for="terminalNumber">{{ $t("modal.number") }}:</label>
              <input
                type="text"
                class="form-control"
                id="terminalNumber"
                v-model="terminal.TerminalNumber"
                required
              />
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label for="terminalName">{{ $t("modal.name") }}:</label>
              <input
                type="text"
                class="form-control"
                id="terminalName"
                v-model="terminal.TerminalName"
                required
              />
            </div>
          </div>
          <div class="col-md-6" v-if="isERP">
            <div class="form-group">
              <label for="warehouseId">{{ $t("modal.warehouse") }}:</label>
              <select
                class="form-control"
                id="warehouseId"
                v-model="terminal.WarehouseId"
              >
                <option
                  v-for="warehouse in warehouses"
                  :value="warehouse.WarehouseId"
                  :key="warehouse.WarehouseId"
                >
                  {{ warehouse.WarehouseName }}
                </option>
              </select>
            </div>
          </div>
          <div class="col-md-6" v-if="hasLY">
            <div class="form-group">
              <label for="lyCustomerNumber"
                >{{ $t("modal.lycustomernumber") }}:</label
              >
              <input
                type="text"
                class="form-control"
                id="lyCustomerNumber"
                v-model="customer.CustomerNumber"
                :placeholder="`${$t('placeholder.inputcustomernumber')}`"
                @change="setCustomerByNumber"
                @keydown.enter.prevent
              />
            </div>
          </div>
          <div class="col-md-6" v-if="hasLY">
            <div class="form-group">
              <label for="lYWarehouseNumber"
                >{{ $t("modal.lywarehousenumber") }}:</label
              >
              <input
                type="text"
                class="form-control"
                id="lYWarehouseNumber"
                v-model="terminal.LYWarehouseNumber"
              />
            </div>
          </div>
          <div class="col-md-6" v-if="hasLY">
            <div class="form-group">
              <label for="lYUniformNumber"
                >{{ $t("modal.lyuniformnumber") }}:</label
              >
              <input
                type="text"
                class="form-control"
                id="lYUniformNumber"
                v-model="terminal.LYUniformNumber"
                @blur="checkUniformNumber"
                @keydown.enter="checkUniformNumber"
              />
            </div>
          </div>
          <div class="col-md-6" v-if="hasSurveyCake">
            <div class="form-group">
              <label for="SurveyCakeURL"
                >{{ $t("modal.surveycakeurl") }}:</label
              >
              <input
                type="text"
                class="form-control"
                id="SurveyCakeURL"
                v-model="terminal.SurveyCakeURL"
              />
            </div>
          </div>
          <div class="col-md-6" v-if="isDigiwinAPI">
            <div class="form-group">
              <label for="DigiwinWarehouse"
                >{{ $t("modal.warehousetype") }}:</label
              >
              <input
                type="text"
                class="form-control"
                id="DigiwinWarehouse"
                v-model="terminal.DigiwinWarehouse"
                required
              />
            </div>
          </div>
          <div class="col-12" v-if="hasCloudElecInvoice">
            <div class="form-group">
              <label for="defaultCustomerId">預設會員:</label>
              <div class="row px-3">
                <input
                  type="text"
                  class="form-control col-12 col-md-6"
                  id="customerNumber"
                  :placeholder="`${$t('placeholder.inputcustomernumberordb')}`"
                  v-model="addCustomerNumber"
                  @keydown.enter.exact="handleEnter"
                  @dblclick="customerModalTrigger = !customerModalTrigger"
                />
                <input
                  type="text"
                  class="form-control col-12 col-md-6"
                  id="customerName"
                  readonly
                  :value="addCustomerName"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </item-modal>

    <customer-selects
      :trigger="customerModalTrigger"
      :isMultiple="false"
      @onSaved="setCustomer"
    ></customer-selects>
    <terminal-elec-invoice
      :elecInvoice="elecInvoice"
      :terminalId="terminalId"
      :formTrigger="elecInvoiceTrigger"
      @onClosed="hideElecInvoice"
    ></terminal-elec-invoice>
  </div>
</template>

<script>
import pageTable from "@/components/PageTable";
import itemModal from "@/components/ItemModal";
import terminalElecInvoice from "@/components/datas/TerminalElecInvoice";
import customerSelects from "@/components/selects/CustomerSelects";

export default {
  data() {
    return {
      stores: [],
      warehouses: [],
      terminals: [],
      terminal: {},
      pagination: {},
      isShowModal: false,
      modalTitle: "",
      idProperty: "TerminalId",
      nameProperty: "TerminalName",
      getTrigger: false,
      isERP: window.localStorage["isERP"] == "true",
      elecInvoice: {
        ElecInvoiceTitleType: 0,
      },
      elecInvoiceTrigger: false,
      terminalId: "",
      authority: {},
      addCustomerNumber: "",
      addCustomerName: "",
      customerModalTrigger: false,
    };
  },
  computed: {
    columns() {
      let cols = [
        { name: "StoreNumber" },
        { name: "StoreName" },
        { name: "TerminalNumber" },
        { name: "TerminalName" },
      ];
      if (this.isERP) {
        cols.push({ name: "WarehouseNumber" });
        cols.push({ name: "WarehouseName" });
      }
      if (this.hasLY) {
        cols.push({ name: "LYWarehouseNumber" });
        cols.push({ name: "LYUniformNumber" });
      }
      return cols;
    },
    hasCloudElecInvoice() {
      return (
        window.localStorage["productType"] == 3 ||
        window.localStorage["hasCloudElecInvoice"] == "true"
      );
    },
    hasLY() {
      return window.localStorage["erpType"] == 1;
    },
    isDigiwinAPI() {
      return window.localStorage["erpType"] == 3;
    },
    hasSurveyCake() {
      return window.localStorage["hasSurveyCake"] == "true";
    },
    isUshowPad() {
      return window.localStorage["productType"] == 2;
    },
  },
  watch: {
    isShowModal(value) {
      if (value) {
        this.getStores();
        if (this.isERP) this.getWarehouses();
      }
    },
  },
  components: {
    pageTable,
    itemModal,
    terminalElecInvoice,
    customerSelects,
  },
  methods: {
    setCustomer(item) {
      this.addCustomerNumber = item.CustomerNumber;
      this.terminal.DefaultCustomerId = item.CustomerId;
      this.addCustomerName = item.CustomerName;
    },
    handleEnter(event) {
      event.preventDefault();
      event.stopPropagation();
      this.selectCustomerByKeyword();
    },
    selectCustomerByKeyword() {
      if (!this.addCustomerNumber || this.addCustomerNumber.length == 0) {
        this.terminal.DefaultCustomerId = null;
        this.addCustomerNumber = "";
        this.addCustomerName = "";
        return;
      }
      const vm = this;
      this.$api.customers
        .getByNumber(this.addCustomerNumber)
        .then((data) => {
          if (data) {
            vm.setCustomer(data);
          }
        })
        .catch((error) => {
          this.$func.message.error(error.data.Message);
          this.terminal.DefaultCustomerId = null;
          this.addCustomerNumber = "";
          this.addCustomerName = "";
        });
    },
    checkUniformNumber(e) {
      e.preventDefault();
      this.$func.inputValidate.uniformNumber(
        e,
        "統編格式有誤，請檢查輸入的統編是否正確"
      );
    },
    getStores() {
      const vm = this;
      let none = { StoreId: null, StoreName: this.$t("modal.none") };
      this.$api.stores.getNames().then((data) => {
        data.splice(0, 0, none);
        vm.stores = data;
      });
    },
    getWarehouses() {
      const vm = this;
      let none = { WarehouseId: null, WarehouseName: this.$t("modal.none") };
      this.$api.warehouses.getNames().then((data) => {
        data.splice(0, 0, none);
        vm.warehouses = data;
      });
    },
    getTerminals(dTO) {
      const vm = this;
      this.$api.terminals.getPages(dTO).then((data) => {
        vm.terminals = data.Datas;
        vm.pagination = data.Pagination;
      });
    },
    addTerminal() {
      this.$func.message.alert("無此功能 !");
    },
    editTerminal(item) {
      if (!this.authority.IsUpdate) {
        return;
      }
      const vm = this;
      this.$api.terminals.get(item.TerminalId).then((data) => {
        vm.terminal = data;
        vm.getCustomer(data.DefaultCustomerId);
        vm.isShowModal = !vm.isShowModal;
        vm.modalTitle = this.$t("modal.editterminal");
      });
    },
    setCustomerByNumber() {
      let number = this.customer.CustomerNumber;
      this.customer = {};
      this.terminal.DefaultCustomerId = null;
      if (number && number.length > 0) {
        const vm = this;
        this.$api.customers
          .getByNumber(number)
          .then((data) => {
            vm.customer = data;
            vm.terminal.DefaultCustomerId = data.CustomerId;
          })
          .catch(() => {
            vm.$func.message.alert("請輸入正確的會員編號 !");
          });
      }
    },
    getCustomer(id) {
      if (!id) {
        return;
      }
      const vm = this;
      this.$api.customers
        .get(id)
        .then((data) => {
          vm.addCustomerNumber = data.CustomerNumber;
          vm.addCustomerName = data.CustomerName;
        })
        .catch(() => {
          vm.terminal.DefaultCustomerId = null;
        });
    },
    removeTerminal() {
      this.$func.message.alert("無此功能 !");
    },
    saveTerminal() {
      if (this.hasLY) {
        const invalidInput = document.querySelector(".invalid");
        if (invalidInput) {
          this.$func.message.alert("填寫格式有誤");
          return;
        }
      }
      const vm = this;
      this.$api.terminals
        .save(vm.terminal)
        .then(() => {
          vm.isShowModal = !vm.isShowModal;
          vm.getTrigger = !vm.getTrigger;
        })
        .catch((error) => vm.$func.message.error(error.data.Message));
    },
    showElecInvoice(index) {
      const terminal = this.terminals[index];
      if (!terminal) {
        this.$func.message.alert("請選擇收銀台 !");
        return;
      }
      this.terminalId = this.terminals[index].TerminalId;
      const vm = this;
      this.$api.terminals
        .getElecInvoice(terminal.TerminalId)
        .then((data) => {
          vm.elecInvoice = data;
          vm.elecInvoiceTrigger = !vm.elecInvoiceTrigger;
        })
        .catch((error) => {
          vm.$func.message.error(error.data.Message);
        });
    },
    hideElecInvoice() {
      this.elecInvoiceTrigger = !this.elecInvoiceTrigger;
    },
  },
  created() {
    this.authority = this.$func.localStorage.getAuthByName(this.$route.name);
  },
};
</script>
