<template>
  <div>
    <modal-part
      modalId="invoicePrizeDetail"
      modalClass="modal-lg"
      title="中獎名單"
      :hasButtons="false"
      :isShow="isShow"
      @onClosed="onClosed"
    >
      <div slot="item">
        <div class="row">
          <div class="col-md-6">
            <div class="form-group utaiform-group">
              <label class="utaiform-label" for="businessId">營業人編號</label>
              <span class="utaiform-review-value">{{
                invoicePrize.BusinessId
              }}</span>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group utaiform-group">
              <label class="utaiform-label" for="period">期別</label>
              <span class="utaiform-review-value">{{
                invoicePrize.Period
              }}</span>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group utaiform-group">
              <label class="utaiform-label" for="invoiceNumber">發票號碼</label>
              <span class="utaiform-review-value">{{
                invoicePrize.InvoiceNumber
              }}</span>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group utaiform-group">
              <label class="utaiform-label" for="invoiceprizeNumber"
                >中獎號碼</label
              >
              <span class="utaiform-review-value">{{
                invoicePrize.InvoicePrizeNumber
              }}</span>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group utaiform-group">
              <label class="utaiform-label" for="invoiceprizeType"
                >中獎獎別</label
              >
              <span class="utaiform-review-value">{{
                invoicePrize.InvoicePrizeTypeText
              }}</span>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group utaiform-group">
              <label class="utaiform-label" for="invoiceprizeAmount"
                >中獎金額</label
              >
              <span class="utaiform-review-value">{{
                invoicePrize.InvoicePrizeAmount
              }}</span>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group utaiform-group">
              <label class="utaiform-label" for="receiver">收件人</label>
              <span class="utaiform-review-value">{{
                invoicePrize.Receiver
              }}</span>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group utaiform-group">
              <label class="utaiform-label" for="phone">電話</label>
              <span class="utaiform-review-value">{{
                invoicePrize.Phone
              }}</span>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group utaiform-group">
              <label class="utaiform-label" for="postalCode">郵遞區號</label>
              <span class="utaiform-review-value">{{
                invoicePrize.PostalCode
              }}</span>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group utaiform-group">
              <label class="utaiform-label" for="address">地址</label>
              <span class="utaiform-review-value">{{
                invoicePrize.Address
              }}</span>
            </div>
          </div>
        </div>
      </div>
    </modal-part>
  </div>
</template>

<script>
import modalPart from "@/components/parts/modalPart";

export default {
  data() {
    return {
      invoicePrize: {},
    };
  },
  props: ["item", "isShow"],
  components: {
    modalPart,
  },
  watch: {
    isShow(value) {
      if (value) {
        this.invoicePrize = this.item;
      }
    },
  },
  methods: {
    onClosed() {
      this.$emit("onClosed");
    },
  },
};
</script>
