import i18n from "@/langs/i18n";

export function getAll() {
  return [
    {
      value: 0,
      name: "SupplierNumber",
      text: `${i18n.t("option.supplierSearchType.SupplierNumber")}`,
    },
    {
      value: 1,
      name: "SupplierName",
      text: `${i18n.t("option.supplierSearchType.SupplierName")}`,
    },
    {
      value: 2,
      name: "BusinessNumber",
      text: `${i18n.t("option.supplierSearchType.BusinessNumber")}`,
    },
    {
      value: 3,
      name: "Telephone",
      text: `${i18n.t("option.supplierSearchType.Telephone")}`,
    },
  ];
}
