<template>
  <div>
    <search-part @getItems="getDevices">
      <template slot="buttonArea">
        <slot name="searchButtonArea" />
      </template>
      <template slot="searchArea">
        <div class="row">
          <div class="col-lg-6">
            <div class="form-group row">
              <label
                for="searchDeviceId"
                class="col-3 col-md-2 col-lg-3 col-form-label"
                >編號:</label
              >
              <div class="col">
                <input
                  type="text"
                  class="form-control"
                  id="searchDeviceId"
                  v-model="searchDTO.DeviceId"
                />
              </div>
            </div>
          </div>
          <div class="col-lg-6">
            <div class="form-group row">
              <label
                for="searchDeviceName"
                class="col-3 col-md-2 col-lg-3 col-form-label"
                >名稱:</label
              >
              <div class="col">
                <input
                  type="text"
                  class="form-control"
                  id="searchDeviceName"
                  v-model="searchDTO.DeviceName"
                />
              </div>
            </div>
          </div>
          <div class="col-lg-6">
            <div class="form-group row">
              <label
                for="searchCpuId"
                class="col-3 col-md-2 col-lg-3 col-form-label"
                >金鑰:</label
              >
              <div class="col">
                <input
                  type="text"
                  class="form-control"
                  id="searchCpuId"
                  v-model="searchDTO.CpuId"
                />
              </div>
            </div>
          </div>
          <div class="col-lg-6">
            <div class="form-group row">
              <label
                for="searchProductType"
                class="col-3 col-md-2 col-lg-3 col-form-label"
                >產品:</label
              >
              <div class="col">
                <select
                  class="form-control"
                  id="searchProductType"
                  v-model="searchDTO.ProductType"
                >
                  <option
                    v-for="productType in productTypes"
                    :key="productType.value"
                    :value="productType.value"
                  >
                    {{ productType.text }}
                  </option>
                </select>
              </div>
            </div>
          </div>
        </div>
      </template>
    </search-part>
    <pageTable
      :parentPage="pagination"
      @getItems="getDevices"
      :hasAdd="isEngineer"
      :hasEdit="!isAssistant"
      @addItem="addDevice"
      @editItem="editDevice"
      @removeItem="removeDevice"
      @trdbClicked="editDevice"
      :items="devices"
      :columns="columns"
      :idProperty="idProperty"
      :nameProperty="nameProperty"
      :hasRemove="isAdmin"
      :hasPage="true"
      :isSortable="true"
    >
      <template v-if="!isAssistant" slot="button" slot-scope="props">
        <button
          type="button"
          class="btn btn-outline-success py-2 px-4"
          style="border-color: transparent"
          @click="onCertificate"
        >
          <i class="fas fa-stamp"></i>
        </button>
        <button
          type="button"
          class="btn btn-outline-info btn-icon"
          @click="showElecInvoice(props.index)"
        >
          <i class="fas fa-file-invoice-dollar"></i>
        </button>
        <button
          v-if="isEngineer"
          type="button"
          class="btn btn-outline-warning btn-icon"
          @click="openChangeDevice(props.index)"
        >
          <i class="fas fa-key"></i>
        </button>
        <button
          v-if="isEngineer"
          type="button"
          class="btn btn-outline-danger btn-icon"
          @click="unbindUberEats(props.index)"
        >
          <i class="fas fa-handshake-slash"></i>
        </button>
        <button
          v-if="isAdmin || isEngineer"
          type="button"
          class="btn btn-outline-primary py-2 px-4 font-weight-bold"
          style="border-color: transparent"
          @click="onApiKey(props.index)"
        >
          API
        </button>
      </template>
    </pageTable>

    <modalPart
      :isShow="isShowModal"
      @onClosed="isShowModal = !isShowModal"
      @onSaved="saveDevice"
      :title="modalTitle"
      modalId="device"
      modalClass="modal-lg"
    >
      <div slot="item">
        <div class="row">
          <div class="col-md-6">
            <div class="form-group">
              <label for="deviceId">編號</label>
              <input
                type="text"
                class="form-control"
                id="deviceId"
                v-model="device.DeviceId"
                :disabled="!isAdd"
                required
              />
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label for="deviceName">名稱</label>
              <input
                type="text"
                class="form-control"
                id="deviceName"
                v-model="device.DeviceName"
                required
              />
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label for="cpuId">金鑰</label>
              <input
                type="text"
                class="form-control"
                id="cpuId"
                v-model="device.CpuId"
                :disabled="!isAdd"
                required
              />
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label for="statusType">狀態</label>
              <select
                class="form-control"
                id="statusType"
                v-model="device.StatusType"
              >
                <option
                  v-for="statusType in statusTypes"
                  :key="statusType.value"
                  :value="statusType.value"
                >
                  {{ statusType.text }}
                </option>
              </select>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label for="productType">產品</label>
              <select
                class="form-control"
                id="productType"
                v-model="device.ProductType"
              >
                <option
                  v-for="productType in productTypes"
                  :key="productType.value"
                  :value="productType.value"
                >
                  {{ productType.text }}
                </option>
              </select>
            </div>
          </div>
          <div v-if="business.HasOnlineOrder" class="col-md-6">
            <div class="form-group">
              <label for="tableOrderType">點餐模式</label>
              <select
                class="form-control"
                id="tableOrderType"
                v-model="device.TableOrderType"
              >
                <option
                  v-for="tableOrderType in tableOrderTypes"
                  :key="tableOrderType.value"
                  :value="tableOrderType.value"
                >
                  {{ tableOrderType.text }}
                </option>
              </select>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label for="iOSMPCCounts">子機數量</label>
              <input
                type="text"
                class="form-control"
                id="iOSMPCCounts"
                v-model="device.iOSMPCCounts"
              />
            </div>
          </div>
          <div class="col-12">
            <div class="form-group">
              <label for="deviceMemo">備註</label>
              <input
                type="text"
                class="form-control"
                id="deviceMemo"
                v-model="device.Memo"
              />
            </div>
          </div>
          <div v-if="business.HasOcard" class="col-md-6">
            <div class="form-group">
              <label for="ocardKey">Ocard Key</label>
              <input
                type="text"
                class="form-control"
                id="ocardKey"
                v-model="device.OcardKey"
                required
              />
            </div>
          </div>
          <div v-if="business.HasOcard" class="col-md-6">
            <div class="form-group">
              <label for="ocardSecret">Ocard Secret</label>
              <input
                type="text"
                class="form-control"
                id="ocardSecret"
                v-model="device.OcardSecret"
                required
              />
            </div>
          </div>
          <div class="col-md-12 row mb-2">
            <div class="col-4 col-lg-2">
              <div class="custom-control custom-checkbox">
                <input
                  type="checkbox"
                  class="custom-control-input"
                  id="isDining"
                  v-model="device.IsDining"
                />
                <label class="custom-control-label" for="isDining">後結</label>
              </div>
            </div>
            <div v-if="device.ProductType == 2" class="col-4 col-lg-2">
              <div class="custom-control custom-checkbox">
                <input
                  type="checkbox"
                  class="custom-control-input"
                  id="hasSaleOrder"
                  v-model="device.HasSaleOrder"
                />
                <label class="custom-control-label" for="hasSaleOrder"
                  >訂單</label
                >
              </div>
            </div>
            <div v-if="device.ProductType == 2" class="col-4 col-lg-2">
              <div class="custom-control custom-checkbox">
                <input
                  type="checkbox"
                  class="custom-control-input"
                  id="hasTableOrder"
                  v-model="device.HasTableOrder"
                />
                <label class="custom-control-label" for="hasTableOrder"
                  >桌邊點餐</label
                >
              </div>
            </div>
            <div v-if="device.ProductType == 2" class="col-4 col-lg-2">
              <div class="custom-control custom-checkbox">
                <input
                  type="checkbox"
                  class="custom-control-input"
                  id="hasLabel"
                  v-model="device.HasLabel"
                />
                <label class="custom-control-label" for="hasLabel"
                  >標籤列印</label
                >
              </div>
            </div>
            <div
              v-if="!device.IsDining && device.ProductType == 2"
              class="col-4 col-lg-2"
            >
              <div class="custom-control custom-checkbox">
                <input
                  type="checkbox"
                  class="custom-control-input"
                  id="hasBeforePrint"
                  v-model="device.HasBeforePrint"
                />
                <label class="custom-control-label" for="hasBeforePrint"
                  >先出單</label
                >
              </div>
            </div>
            <div
              v-if="device.IsDining && device.ProductType == 2"
              class="col-4 col-lg-2"
            >
              <div class="custom-control custom-checkbox">
                <input
                  type="checkbox"
                  class="custom-control-input"
                  id="hasReservation"
                  v-model="device.HasReservation"
                />
                <label class="custom-control-label" for="hasReservation"
                  >訂位</label
                >
              </div>
            </div>
          </div>
          <div class="col-md-12 row mb-2">
            <div class="col-4 col-lg-3">
              <div class="custom-control custom-checkbox">
                <input
                  type="checkbox"
                  class="custom-control-input"
                  id="hasLinePay"
                  v-model="device.HasLinePay"
                />
                <label class="custom-control-label" for="hasLinePay"
                  >LinePay</label
                >
              </div>
            </div>
            <div class="col-4 col-lg-3">
              <div class="custom-control custom-checkbox">
                <input
                  type="checkbox"
                  class="custom-control-input"
                  id="hasGama"
                  v-model="device.HasGama"
                />
                <label class="custom-control-label" for="hasGama"
                  >GamaPay</label
                >
              </div>
            </div>
            <div class="col-4 col-lg-3">
              <div class="custom-control custom-checkbox">
                <input
                  type="checkbox"
                  class="custom-control-input"
                  id="hasUberEats"
                  v-model="device.HasUberEats"
                />
                <label class="custom-control-label" for="hasUberEats"
                  >UberEats</label
                >
              </div>
            </div>
            <div class="col-4 col-lg-3">
              <div class="custom-control custom-checkbox">
                <input
                  type="checkbox"
                  class="custom-control-input"
                  id="hasPGTalk"
                  v-model="device.HasPGTalk"
                />
                <label class="custom-control-label" for="hasPGTalk"
                  >PGTalk</label
                >
              </div>
            </div>
            <div class="col-4 col-lg-3">
              <div class="custom-control custom-checkbox">
                <input
                  type="checkbox"
                  class="custom-control-input"
                  id="hasMyCard"
                  v-model="device.HasMyCard"
                />
                <label class="custom-control-label" for="hasMyCard"
                  >MyCard</label
                >
              </div>
            </div>
            <div class="col-4 col-lg-3">
              <div class="custom-control custom-checkbox">
                <input
                  type="checkbox"
                  class="custom-control-input"
                  id="hasPXPay"
                  v-model="device.HasPXPay"
                />
                <label class="custom-control-label" for="hasPXPay">PXPay</label>
              </div>
            </div>
            <div class="col-4 col-lg-3">
              <div class="custom-control custom-checkbox">
                <input
                  type="checkbox"
                  class="custom-control-input"
                  id="hasSurveyCake"
                  v-model="hasSurveyCake"
                />
                <label class="custom-control-label" for="hasSurveyCake"
                  >SurveyCake</label
                >
              </div>
            </div>
          </div>
          <div class="col-md-12 row mb-3">
            <div class="col-6 col-lg-4">
              <div class="custom-control custom-checkbox">
                <input
                  type="checkbox"
                  class="custom-control-input"
                  id="isNeedToCheckVersion"
                  v-model="device.IsNeedToCheckVersion"
                />
                <label class="custom-control-label" for="isNeedToCheckVersion"
                  >檢查程式版本</label
                >
              </div>
            </div>
          </div>

          <div class="col-12 px-0" v-if="device.HasLinePay">
            <hr />
          </div>

          <div class="col-md-6" v-if="device.HasLinePay">
            <div class="form-group">
              <label for="linePayId">LinePay ID</label>
              <input
                type="text"
                class="form-control"
                id="linePayId"
                v-model="device.LinePayId"
                required
              />
            </div>
          </div>
          <div class="col-md-6" v-if="device.HasLinePay">
            <div class="form-group">
              <label for="linePayKey">LinePay Key</label>
              <input
                type="text"
                class="form-control"
                id="linePayKey"
                v-model="device.LinePayKey"
                required
              />
            </div>
          </div>

          <div class="col-12 px-0" v-if="device.HasGama">
            <hr />
          </div>

          <div class="col-md-6" v-if="device.HasGama">
            <div class="form-group">
              <label for="gamaId">GamaPay ID</label>
              <input
                type="text"
                class="form-control"
                id="gamaId"
                v-model="device.GamaId"
                required
              />
            </div>
          </div>
          <div class="col-md-6" v-if="device.HasGama">
            <div class="form-group">
              <label for="gamaIV">GamaPay IV</label>
              <input
                type="text"
                class="form-control"
                id="gamaIV"
                v-model="device.GamaIV"
                required
              />
            </div>
          </div>
          <div class="col-md-6" v-if="device.HasGama">
            <div class="form-group">
              <label for="gamaKey">GamaPay Key</label>
              <input
                type="text"
                class="form-control"
                id="gamaKey"
                v-model="device.GamaKey"
                required
              />
            </div>
          </div>

          <div class="col-12 px-0" v-if="device.HasPGTalk">
            <hr />
          </div>

          <div class="col-md-6" v-if="device.HasPGTalk">
            <div class="form-group">
              <label for="PGTalkAppId">PGTalk App Id</label>
              <input
                type="text"
                class="form-control"
                id="PGTalkAppId"
                v-model="device.PGTalkAppId"
                required
              />
            </div>
          </div>
          <div class="col-md-6" v-if="device.HasPGTalk">
            <div class="form-group">
              <label for="PGTalkDataKey">PGTalk Data Key</label>
              <input
                type="text"
                class="form-control"
                id="PGTalkDataKey"
                v-model="device.PGTalkDataKey"
                required
              />
            </div>
          </div>
          <div class="col-md-6" v-if="device.HasPGTalk">
            <div class="form-group">
              <label for="PGTalkSecretKey">PGTalk Secret Key</label>
              <input
                type="text"
                class="form-control"
                id="PGTalkSecretKey"
                v-model="device.PGTalkSecretKey"
                required
              />
            </div>
          </div>

          <div class="col-12 px-0" v-if="device.HasMyCard">
            <hr />
          </div>

          <div class="col-md-6" v-if="device.HasMyCard">
            <div class="form-group">
              <label for="myCardFacId">MyCard FacId</label>
              <input
                type="text"
                class="form-control"
                id="myCardFacId"
                v-model="device.MyCardFacId"
                required
              />
            </div>
          </div>
          <div class="col-md-6" v-if="device.HasMyCard">
            <div class="form-group">
              <label for="myCardFacPwd">MyCard FacPwd</label>
              <input
                type="text"
                class="form-control"
                id="myCardFacPwd"
                v-model="device.MyCardFacPwd"
                required
              />
            </div>
          </div>

          <div class="col-12 px-0" v-if="device.HasPXPay">
            <hr />
          </div>

          <div class="col-md-6" v-if="device.HasPXPay">
            <div class="form-group">
              <label for="PXPayMerchantId">PXPay MerchantId </label>
              <input
                type="text"
                class="form-control"
                id="PXPayMerchantId"
                v-model="device.PXPayMerchantId"
                required
              />
            </div>
          </div>
          <div class="col-md-6" v-if="device.HasPXPay">
            <div class="form-group">
              <label for="PXPayKey">PXPay Key</label>
              <input
                type="text"
                class="form-control"
                id="PXPayKey"
                v-model="device.PXPayKey"
                required
              />
            </div>
          </div>

          <div class="col-12 px-0" v-if="hasSurveyCake">
            <hr />
          </div>

          <div class="col-md-6" v-if="hasSurveyCake">
            <div class="form-group">
              <label for="SurveyCakeId">SurveyCake Id</label>
              <input
                type="text"
                class="form-control"
                id="SurveyCakeId"
                v-model="device.SurveyCakeId"
                required
              />
            </div>
          </div>
          <div class="col-md-6" v-if="hasSurveyCake">
            <div class="form-group">
              <label for="PXPayKey">SurveyCake Hash Key</label>
              <input
                type="text"
                class="form-control"
                id="SurveyCakeHashKey"
                v-model="device.SurveyCakeHashKey"
                required
              />
            </div>
          </div>
          <div class="col-md-6" v-if="hasSurveyCake">
            <div class="form-group">
              <label for="SurveyCakeIVKey">SurveyCake IV Key</label>
              <input
                type="text"
                class="form-control"
                id="SurveyCakeIVKey"
                v-model="device.SurveyCakeIVKey"
                required
              />
            </div>
          </div>
        </div>
      </div>
    </modalPart>

    <modalPart
      :isShow="isShowCertificate"
      @onClosed="isShowCertificate = false"
      @onSaved="addCertificate"
      title="認證"
      modalId="deviceUV"
      modalClass="modal-lg"
    >
      <div slot="item" class="table-responsive custom-table">
        <table class="table table-hover">
          <thead>
            <tr>
              <th>選擇</th>
              <th style="width: 250px">編號</th>
              <th style="width: 150px">產品</th>
              <th style="width: 250px">金鑰</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="deviceUV in deviceUVs"
              :key="deviceUV.Id"
              @click="notSelectedClick(deviceUV.Id)"
            >
              <td>
                <input
                  class="checkbox"
                  type="checkbox"
                  :value="deviceUV.Id"
                  v-model="deviceUVIds"
                />
              </td>
              <td>{{ deviceUV.Id }}</td>
              <td>{{ deviceUV.ProductTypeText }}</td>
              <td>{{ deviceUV.KeyId }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </modalPart>

    <modalPart
      :isShow="isShowChangeDevice"
      @onClosed="isShowChangeDevice = false"
      @onSaved="changeDevice"
      :title="modalTitle"
      modalId="changeDevice"
      modalClass="modal-lg"
    >
      <div slot="item">
        <div class="row">
          <div class="col-12">
            <div class="form-group">
              <label for="changeCpuId">CPU ID</label>
              <input
                type="text"
                class="form-control"
                id="changeCpuId"
                v-model="cpuId"
                required
              />
            </div>
          </div>
        </div>
      </div>
    </modalPart>

    <device-elec-invoice
      :elecInvoice="elecInvoice"
      :formTrigger="elecInvoiceTrigger"
    ></device-elec-invoice>

    <apiKeyModal
      modalId="apiKey"
      :targetId="currentDeviceId"
      :isShow="isShowApiKeyModal"
      @onClosed="isShowApiKeyModal = !isShowApiKeyModal"
      title="Api Key"
      modalClass="modal-lg"
    >
    </apiKeyModal>
  </div>
</template>

<script>
import searchPart from "@/components/parts/searchPart";
import pageTable from "@/components/PageTable";
import itemModal from "@/components/ItemModal";
import modalPart from "@/components/parts/modalPart";
import deviceElecInvoice from "@/components/datas/DeviceElecInvoice";
import apiKeyModal from "@/components/datas/ApiKeyModal";

export default {
  data() {
    return {
      searchDTO: {},
      productTypes: [],
      tableOrderTypes: [],
      business: {},
      devices: [],
      device: {},
      pagination: {},
      isShowModal: false,
      isShowCertificate: false,
      isShowChangeDevice: false,
      isShowApiKeyModal: false,
      modalTitle: "",
      columns: [
        {
          name: "DeviceId",
        },
        {
          name: "DeviceName",
        },
        {
          name: "ProductType",
        },
        {
          name: "CpuId",
        },
        {
          name: "StatusType",
        },
        {
          name: "Memo",
          width: 200,
        },
        {
          name: "Version",
        },
        {
          name: "iOSMPCCounts",
        },
      ],
      statusTypes: [
        {
          value: 0,
          text: "未啟用",
        },
        {
          value: 1,
          text: "啟用",
        },
      ],
      idProperty: "DeviceId",
      nameProperty: "DeviceName",
      isAdd: false,
      deviceUVs: [],
      deviceUVIds: [],
      elecInvoice: {},
      elecInvoiceTrigger: false,
      hasSurveyCake: false,
      currentDeviceId: "",
      cpuId: "",
    };
  },
  watch: {
    productTypes: {
      handler() {
        this.searchDTO.ProductType = -1;
      },
      immediate: true,
    },
  },
  components: {
    searchPart,
    pageTable,
    itemModal,
    modalPart,
    deviceElecInvoice,
    apiKeyModal,
  },
  computed: {
    isEngineer() {
      return this.$enum.roleType.isEngineer();
    },
    isAssistant() {
      return this.$enum.roleType.isAssistant();
    },
    isAdmin() {
      return this.$enum.roleType.isAdmin();
    },
  },
  methods: {
    onApiKey(index) {
      this.currentDeviceId = this.devices[index].DeviceId;
      this.isShowApiKeyModal = !this.isShowApiKeyModal;
    },
    unbindUberEats(index) {
      const vm = this;
      this.$api.devices.get(this.devices[index].DeviceId).then((data) => {
        if (!data.UberEatsStoreId) {
          this.$func.message.alert("此終端機未綁定UberEats !");
          return;
        } else {
          this.$func.message
            .confirm("確定解除UberEats綁定嗎 ?")
            .then((result) => {
              if (result.isConfirmed) {
                vm.$api.uberEats
                  .deleteUberEats(vm.devices[index].DeviceId)
                  .then(() => {
                    vm.$func.message.success("解綁成功 !");
                  })
                  .catch((error) => {
                    vm.$func.message.error(error.data.Message);
                  });
              }
            });
        }
      });
    },
    openChangeDevice(index) {
      this.cpuId = "";
      this.currentDeviceId = this.devices[index].DeviceId;
      this.isShowChangeDevice = true;
      this.modalTitle = "更換金鑰";
    },
    changeDevice() {
      const vm = this;
      this.$api.devices
        .changeDevice(this.currentDeviceId, { CpuId: this.cpuId })
        .then(() => {
          vm.$func.message.success("金鑰更換成功 !");
          vm.isShowChangeDevice = false;
          vm.getDevices();
        })
        .catch((error) => {
          vm.$func.message.error(error.data.Message);
        });
    },
    setOptions() {
      this.productTypes = this.$enum.productType.getAll();
      this.tableOrderTypes = this.$enum.tableOrderType.getAll();
      this.productTypes.splice(0, 0, {
        value: -1,
        name: "All",
        text: "全部",
      });
    },
    notSelectedClick(id) {
      const index = this.deviceUVIds.indexOf(id);
      if (index >= 0) {
        this.deviceUVIds.splice(index, 1);
      } else {
        this.deviceUVIds.push(id);
      }
    },
    getDevices(dTO) {
      if (!this.business || !this.business.BusinessId) return;
      const vm = this;
      this.$api.devices
        .gets(vm.business.BusinessId, { ...dTO, ...this.searchDTO })
        .then((data) => {
          vm.devices = data.Datas;
          vm.pagination = data.Pagination;
        });
    },
    addDevice() {
      this.device = {};
      this.device.ProductType = 0;
      this.isAdd = true;
      this.isShowModal = !this.isShowModal;
      this.modalTitle = "新增終端機";
    },
    editDevice(item) {
      this.hasSurveyCake = false;
      this.isAdd = false;
      const vm = this;
      this.$api.devices.get(item.DeviceId).then((data) => {
        vm.device = data;
        if (vm.device.SurveyCakeId) {
          vm.hasSurveyCake = true;
        }
        vm.isShowModal = !vm.isShowModal;
        vm.modalTitle = "修改終端機";
      });
    },
    removeDevice(item) {
      this.$api.devices.remove(item.DeviceId).then(() => {
        this.getDevices();
      });
    },
    saveDevice() {
      this.device.BusinessId = this.business.BusinessId;
      const vm = this;
      if (!this.hasSurveyCake) {
        this.device.SurveyCakeId = null;
        this.device.SurveyCakeHashKey = null;
        this.device.SurveyCakeIVKey = null;
      }
      if (this.isAdd) {
        this.$api.devices
          .save(vm.device)
          .then(() => {
            vm.isShowModal = !vm.isShowModal;
            vm.getDevices();
          })
          .catch((error) => vm.$func.message.error(error.data.Message));
      } else {
        this.$api.devices
          .put(vm.device)
          .then(() => {
            vm.isShowModal = !vm.isShowModal;
            vm.getDevices();
          })
          .catch((error) => vm.$func.message.error(error.data.Message));
      }
    },
    onCertificate() {
      const vm = this;
      this.$api.deviceUVs.gets().then((data) => {
        vm.deviceUVs = data;
        vm.isShowCertificate = true;
      });
    },
    addCertificate() {
      if (this.deviceUVIds.length == 0) {
        this.$func.message.alert("請選擇終端機 !");
        return;
      }
      const vm = this;
      this.$api.deviceUVs
        .postDevices(vm.business.BusinessId, vm.deviceUVIds)
        .then(() => {
          vm.isShowCertificate = false;
          vm.getDevices();
        });
    },
    showElecInvoice(index) {
      const device = this.devices[index];
      const vm = this;
      this.$api.devices
        .getElecInvoice(device.DeviceId)
        .then((data) => {
          vm.elecInvoice = data;
          vm.elecInvoiceTrigger = !vm.elecInvoiceTrigger;
        })
        .catch((error) => {
          vm.$func.message.error(error.data.Message);
        });
    },
  },
  mounted() {
    this.setOptions();
    const vm = this;
    this.$api.businesses.get(vm.$route.params["id"]).then((data) => {
      if (!data) vm.$route.go(-1);
      vm.business = data;
      vm.getDevices();
    });
  },
};
</script>
