import { httpGet, httpPost, httpDelete } from "@/request/http";
import func from "@/funcs";

let path = "/warehouses";

export default {
  get(id) {
    return httpGet(`${path}/${id}`);
  },
  getName(id) {
    return httpGet(`${path}/${id}/name`);
  },
  getNames() {
    return httpGet(`${path}/names`);
  },
  getPages(dTO) {
    return httpGet(`${path}/pages`, dTO);
  },
  save(item) {
    return httpPost(path, item);
  },
  remove(id) {
    return httpDelete(`${path}/${id}`);
  },
  getCasio() {
    func.getTXT(`/casio/warehouses`, "whouse");
  },
  getByNumber(number) {
    return httpGet(`${path}/number/${number}`);
  },
  getBatches(warehouseId, foodId, dTO) {
    return httpGet(`${path}/${warehouseId}/foods/${foodId}/stockbatches`, dTO);
  },
  getFoodBatchNumbers(warehouseId, foodId, batchNumber) {
    return httpGet(
      `${path}/${warehouseId}/foods/${foodId}/batchnumbers/${batchNumber}`
    );
  },
};
