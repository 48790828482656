<template>
  <div>
    <page-table
      :items="ocardRules"
      :parentPage="pagination"
      :columns="columns"
      @getItems="getItems"
      @addItem="add"
      @editItem="edit"
      @removeItem="remove"
      :nameProperty="nameProperty"
      :getTrigger="getTrigger"
    >
      <template slot="button" slot-scope="props">
        <slot name="dataButtons" :index="props.index" :item="props.item">
          <button
            type="button"
            class="btn btn-outline-primary btn-icon"
            @click="openFoodSelects(props.item)"
          >
            <i class="fas fa-gift"></i>
          </button>
        </slot>
      </template>
    </page-table>
    <data-modal-part
      :displayTrigger="modalDisplayTrigger"
      @onClosed="onClosed"
      @onSaved="save"
      :title="modalTitle"
      modalClass="modal-lg"
    >
      <div slot="fieldArea">
        <div class="row">
          <div class="col-md-6">
            <div class="form-group">
              <label for="ocardRuleId">ID</label>
              <input
                type="text"
                class="form-control"
                id="ocardRuleId"
                v-model="ocardRule.OcardRuleId"
                required
                :readonly="isEdit"
              />
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label for="ocardRuleName">{{ $t("modal.name") }}</label>
              <input
                type="text"
                class="form-control"
                id="ocardRuleName"
                v-model="ocardRule.OcardRuleName"
                required
              />
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label for="ocardPromotionType">{{
                $t("modal.promotiontype")
              }}</label>
              <select
                class="form-control"
                id="ocardPromotionType"
                v-model="ocardRule.OcardPromotionType"
                required
              >
                <option
                  v-for="promotionType in ocardPromotionTypes"
                  :key="promotionType.value"
                  :value="promotionType.value"
                >
                  {{ promotionType.text }}
                </option>
              </select>
            </div>
          </div>
          <div
            class="col-md-6"
            v-if="
              this.ocardRule.OcardPromotionType == 1 ||
              this.ocardRule.OcardPromotionType == 2
            "
          >
            <div class="form-group">
              <label for="ocardPromotionValue"
                >{{ $t("modal.value")
                }}<span v-if="this.ocardRule.OcardPromotionType == 1">
                  (%)</span
                ></label
              >
              <input
                type="text"
                class="form-control"
                id="ocardPromotionValue"
                v-model="ocardRule.PromotionValue"
                @input="checkPromotionValue"
                required
              />
            </div>
          </div>
          <div class="col-md-6" v-if="ocardRule.OcardPromotionType == 3">
            <div class="form-group">
              <label for="extraPrice">{{ $t("modal.pricemarkup") }}</label>
              <input
                type="number"
                class="form-control"
                id="extraPrice"
                v-model="ocardRule.ExtraPrice"
                required
              />
            </div>
          </div>
        </div>
      </div>
    </data-modal-part>

    <food-selects
      :trigger="foodSelectTrigger"
      :isMultiple="true"
      :defaultItems="selectFoods"
      @onSaved="addFoodIds"
    ></food-selects>
  </div>
</template>

<script>
import pageTable from "@/components/PageTable";
import dataModalPart from "@/components/parts/dataModalPart";
import foodSelects from "@/components/selects/FoodSelects";

export default {
  data() {
    return {
      ocardRules: [],
      ocardRule: {},
      ocardPromotionTypes: [],
      pagination: {},
      columns: [
        { name: "OcardRuleId" },
        { name: "OcardRuleName" },
        { name: "OcardPromotionTypeText" },
        { name: "PromotionValue" },
        { name: "OcardRuleFoodNameText" },
        { name: "ExtraPrice" },
      ],
      modalDisplayTrigger: false,
      modalTitle: "",
      nameProperty: "OcardRuleName",
      getTrigger: false,
      foodSelectTrigger: false,
      isEdit: false,
      selectFoods: [],
    };
  },
  components: {
    pageTable,
    dataModalPart,
    foodSelects,
  },
  computed: {
    currentLang() {
      return this.$store.state.currentLang;
    },
  },
  watch: {
    currentLang() {
      this.setOptions();
    },
    "ocardRule.OcardPromotionType"(value) {
      if (value == 1 && this.ocardRule.PromotionValue > 99) {
        this.ocardRule.PromotionValue = 99;
      }
    },
  },
  methods: {
    setOptions() {
      this.ocardPromotionTypes = this.$enum.ocardPromotionType.getAll();
    },
    openFoodSelects(item) {
      this.selectFoods = [];
      this.ocardRule = item;

      const vm = this;
      if (item.OcardPromotionType == 3) {
        this.$api.ocardRules.get(item.OcardRuleId).then((data) => {
          vm.selectFoods = data.OcardRuleFoods;
          this.foodSelectTrigger = !this.foodSelectTrigger;
        });
      } else {
        this.$func.message.alert("此規則無法設定商品");
      }
    },
    addFoodIds(items) {
      const vm = this;
      const data = {
        FoodIds: [],
      };
      items.forEach((item) => {
        data.FoodIds.push(item.FoodId);
      });
      this.$api.ocardRules
        .saveFoodIds(this.ocardRule.OcardRuleId, data)
        .then(() => {
          vm.getTrigger = !vm.getTrigger;
        });
    },
    getItems(condition) {
      const vm = this;
      this.$api.ocardRules.getPages(condition).then((data) => {
        vm.ocardRules = data.Datas;
        vm.ocardRules.forEach((rule) => {
          rule.OcardPromotionTypeText = vm.$enum.ocardPromotionType.getByValue(
            rule.OcardPromotionType
          ).text;
        });
        vm.pagination = data.Pagination;
      });
    },
    save() {
      this.isEdit = false;
      if (this.ocardRule.OcardPromotionType != 3) {
        this.checkPromotionValue();
      } else {
        this.ocardRule.PromotionValue = 0;
      }
      const vm = this;
      this.$api.ocardRules
        .save(vm.ocardRule)
        .then(() => {
          vm.modalDisplayTrigger = !vm.modalDisplayTrigger;
          vm.getTrigger = !vm.getTrigger;
        })
        .catch((error) => {
          vm.$func.message.error(error.data.Message);
        });
    },
    add() {
      this.ocardRule = {
        OcardPromotionType: 1,
      };
      this.modalDisplayTrigger = !this.modalDisplayTrigger;
      this.modalTitle = this.$t("modal.addocardrule");
    },
    edit(rule) {
      const vm = this;
      this.$api.ocardRules.get(rule.OcardRuleId).then((data) => {
        vm.ocardRule = data;
        vm.modalDisplayTrigger = !vm.modalDisplayTrigger;
        vm.modalTitle = vm.$t("modal.editocardrule");
        vm.isEdit = true;
      });
    },
    remove(rule) {
      this.$api.ocardRules.remove(rule.OcardRuleId).then(() => {
        this.getTrigger = !this.getTrigger;
      });
    },
    checkPromotionValue() {
      if (this.ocardRule.PromotionValue == 0) {
        this.ocardRule.PromotionValue = 1;
      }
      if (this.ocardRule.PromotionValue) {
        this.ocardRule.PromotionValue =
          +this.ocardRule.PromotionValue.toString().replace(
            /^(0+)|[^\d]+/g,
            ""
          );
        if (
          this.ocardRule.OcardPromotionType == 1 &&
          this.ocardRule.PromotionValue > 99
        ) {
          this.ocardRule.PromotionValue = 99;
        }
      }
    },
    onClosed() {
      this.modalDisplayTrigger = !this.modalDisplayTrigger;
      this.isEdit = false;
    },
  },
  mounted() {
    this.setOptions();
  },
};
</script>
