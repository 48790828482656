export function getAll() {
  return [
    {
      value: -1,
      name: 'All',
      text: '全部',
    },
    {
      value: 0,
      name: 'None',
      text: '經銷商',
    },
    {
      value: 1,
      name: 'Taipei',
      text: '台北',
    },
    {
      value: 2,
      name: 'Taoyuan',
      text: '桃園',
    },
    {
      value: 3,
      name: 'Hsinchu',
      text: '新竹',
    },
    {
      value: 4,
      name: 'Taichung',
      text: '台中',
    },
    {
      value: 5,
      name: 'Tainan',
      text: '台南',
    },
    {
      value: 6,
      name: 'Kaohsiung',
      text: '高雄',
    },
  ]
}

export function getByValue(value) {
  const type = getAll().find(function (item) {
    return item.value == value
  })
  return type
}
