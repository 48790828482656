<template>
  <div>
    <condition-part
      :hasDate="true"
      :hasEmployee="true"
      :hasFood="true"
      :items="combinationTrade"
      :pagination="pagination"
      :columns="columns"
      @getItems="getItems"
      @editItem="edit"
      @removeItem="remove"
      :nameProperty="nameProperty"
      :getTrigger="getTrigger"
      :hasAdd="false"
      :hasEdit="authority.IsUpdate"
      :hasRemove="authority.IsRemove"
    />
  </div>
</template>

<script>
import conditionPart from "@/components/parts/conditionPart";

export default {
  data() {
    return {
      combinationTrade: [],
      pagination: {},
      columns: [
        { name: "SeperationTradeDate" },
        { name: "SeperationTradeNumber" },
        { name: "WarehouseName" },
        { name: "EmployeeName" },
        { name: "FoodNumber" },
        { name: "FoodName" },
        { name: "Qty" },
        { name: "Total" },
        { name: "Memo" },
      ],
      nameProperty: "CombinationTradeNumber",
      getTrigger: false,
      authority: {},
    };
  },
  components: {
    conditionPart,
  },
  methods: {
    getItems(condition) {
      condition.TradeType = 13;
      this.$api.combinationTrades.getPages(condition).then((data) => {
        this.combinationTrade = data.Datas;
        this.combinationTrade.forEach((c) => {
          c.SeperationTradeDate = c.CombinationTradeDate;
          c.SeperationTradeNumber = c.CombinationTradeNumber;
        });
        this.pagination = data.Pagination;
      });
    },
    edit(item) {
      this.$router.push({
        name: "SeperationTradeEdit",
        params: { id: item.CombinationTradeId },
      });
    },
    remove(item) {
      this.$api.combinationTrades.remove(item.CombinationTradeId).then(() => {
        this.getTrigger = !this.getTrigger;
      });
    },
  },
  created() {
    this.authority = this.$func.localStorage.getAuthByName(this.$route.name);
  },
};
</script>
