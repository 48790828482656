<template>
  <div class="row">
    <div class="col-md-6 col-lg-4" v-if="payData.rows.length > 0">
      <div class="card card-table">
        <div class="card-header">{{ $t("modal.paytype") }}</div>
        <div class="card-body">
          <ve-pie :data="payData"></ve-pie>
        </div>
      </div>
    </div>
    <div class="col-md-6 col-lg-4" v-if="foodKindData.rows.length > 0">
      <div class="card card-table">
        <div class="card-header">{{ $t("modal.foodkindrank") }}</div>
        <div class="card-body">
          <ve-pie :data="foodKindData"></ve-pie>
        </div>
      </div>
    </div>
    <div class="col-md-6 col-lg-4" v-if="foodData.rows.length > 0">
      <div class="card card-table">
        <div class="card-header">{{ $t("modal.foodrank") }}</div>
        <div class="card-body">
          <ve-pie :data="foodData"></ve-pie>
        </div>
      </div>
    </div>
    <div class="col-md-6 col-lg-4" v-if="foodData.rows.length > 0">
      <div class="card card-table">
        <div class="card-header">{{ $t("modal.storedata") }}</div>
        <div class="card-body">
          <ve-pie :data="storeData"></ve-pie>
        </div>
      </div>
    </div>
    <div class="col-md-6 col-lg-8" v-if="timeData.rows.length > 0">
      <div class="card card-table">
        <div class="card-header">{{ $t("modal.timedata") }}</div>
        <div class="card-body">
          <ve-histogram
            :data="timeData"
            :settings="timeSettings"
          ></ve-histogram>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      payData: {
        columns: ["PayTypeName", "Total"],
        rows: [],
      },
      foodKindData: {
        columns: ["FoodKindName", "Qty"],
        rows: [],
      },
      foodData: {
        columns: ["FoodName", "Qty"],
        rows: [],
      },
      storeData: {
        columns: ["StoreName", "Total"],
        rows: [],
      },
      timeSettings: {
        labelMap: {
          Total: this.$t("modal.total2"),
          People: this.$t("modal.people"),
          SaleCounts: this.$t("modal.salecounts"),
        },
        axisSite: { right: ["People", "SaleCounts"] },
        yAxisType: ["KMB", "normal"],
        yAxisName: [this.$t("modal.price"), this.$t("modal.qty")],
      },
      timeData: {
        columns: ["Time", "Total", "People", "SaleCounts"],
        rows: [],
      },
    };
  },
  mounted() {
    if (window.localStorage["roleType"] != 0) return;
    const vm = this;
    this.$api.home.getPayTypes().then((data) => {
      vm.payData.rows = data.filter((item) => {
        return item.Total != 0;
      });
    });
    this.$api.home.getFoodKinds().then((data) => {
      vm.foodKindData.rows = data.filter((item) => {
        return item.Qty != 0;
      });
    });
    this.$api.home.getFoods().then((data) => {
      vm.foodData.rows = data.filter((item) => {
        return item.Qty != 0;
      });
    });
    this.$api.home.getTimes().then((data) => {
      vm.timeData.rows = data;
    });
    this.$api.home.getStores().then((data) => {
      vm.storeData.rows = data.filter((item) => {
        return item.Total != 0;
      });
    });
  },
};
</script>
