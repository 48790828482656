<template>
  <div>
    <condition-part
      :hasWarehouse="true"
      :hasFoodKind="true"
      :hasFood="true"
      :hasAdd="false"
      :hasEdit="false"
      :hasRemove="false"
      :items="items"
      :pagination="pagination"
      :columns="columns"
      :getTrigger="getTrigger"
      @getItems="getItems"
    >
      <template slot="searchButtons">
        <import-part
          @onImport="onImport"
          :title="`${$t('modal.importsafeqty')}`"
        />
        <export-part @onExport="onExport" />
      </template>

      <template slot="th-before">
        <th style="width: 80px">{{ $t("columnName.modify") }}</th>
        <th class="text-right" style="width: 100px">
          {{ $t("columnName.safeqty") }}
        </th>
      </template>
      <template slot="td-before" slot-scope="props">
        <td>
          <button
            v-show="props.item.IsEdit"
            type="button"
            class="btn btn-outline-primary btn-icon"
            @click.stop="onSaved(props.item)"
          >
            <i class="fas fa-check"></i>
          </button>
          <button
            v-show="!props.item.IsEdit"
            type="button"
            class="btn btn-outline-success btn-icon"
            @click.stop="props.item.IsEdit = true"
          >
            <i class="fas fa-pen"></i>
          </button>
        </td>
        <td v-if="props.item.IsEdit == true">
          <input
            type="number"
            class="form-control text-right"
            v-model="props.item.SafeQty"
          />
        </td>
        <tdPart v-else :column="safeQty" :item="props.item"></tdPart>
      </template>
    </condition-part>
  </div>
</template>

<script>
import conditionPart from "@/components/parts/conditionPart";
import tdPart from "@/components/parts/tdPart";
import importPart from "@/components/ImportPart";
import exportPart from "@/components/ExportPart";

export default {
  data() {
    return {
      items: [],
      pagination: {},
      columns: [
        { name: "WarehouseNumber" },
        { name: "WarehouseName" },
        { name: "FoodNumber" },
        { name: "FoodName" },
        { name: "Qty" },
        { name: "Unit" },
      ],
      safeQty: {},
      getTrigger: false,
    };
  },
  components: {
    conditionPart,
    tdPart,
    importPart,
    exportPart,
  },
  methods: {
    getItems(condition) {
      condition.showMainFood = false;
      const vm = this;
      this.$api.stocks.getPages(condition).then((data) => {
        data.Datas.forEach((item) => (item.IsEdit = false));
        vm.items = data.Datas;
        vm.pagination = data.Pagination;
      });
    },
    onSaved(item) {
      this.$api.stocks.postSafeQty(item).then(() => {
        item.IsEdit = false;
      });
    },
    onImport(file) {
      let formData = new FormData();
      formData.append("file", file);
      const vm = this;
      this.$api.stocks.postSafeQtyExcel(formData).then(() => {
        vm.getTrigger = !vm.getTrigger;
      });
    },
    onExport() {
      this.$api.stocks.getSafeQtyExcel();
    },
  },
  mounted() {
    const column = this.$func.getColumnProperty("SafeQty");
    this.safeQty = {
      ColumnName: "SafeQty",
      Width: column.Width,
      class: column.class,
    };
  },
};
</script>
