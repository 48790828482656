<template>
  <div>
    <h2 v-if="supplier">{{ supplier.SupplierName }}</h2>
    <div class="row">
      <div class="col-md-6">
        <h5>{{ $t("title.unselectedfood") }}</h5>
        <div class="card card-table">
          <div class="card-header">
            <button
              type="button"
              class="btn btn-outline-primary btn-icon py-2 px-4"
              @click="onAdd"
            >
              <i class="fa fa-plus"></i>
            </button>
          </div>

          <div class="card-body">
            <div class="form-group row">
              <label for="foodKindId" class="col-sm-2 col-form-label"
                >{{ $t("modal.foodkind") }}:</label
              >
              <div class="col-sm-10">
                <select
                  id="foodKindId"
                  v-if="foodKinds"
                  class="form-control"
                  v-model="currentFoodKindId"
                >
                  <option
                    v-for="foodKind in foodKinds"
                    :key="foodKind.FoodKindId"
                    :value="foodKind.FoodKindId"
                  >
                    {{ foodKind.FoodKindName }}
                  </option>
                </select>
              </div>
            </div>
            <table class="table">
              <thead>
                <tr>
                  <th>
                    <div class="custom-control custom-checkbox mr-sm-2">
                      <input
                        type="checkbox"
                        class="custom-control-input"
                        id="allAdd"
                        v-model="allAdd"
                      />
                      <label class="custom-control-label" for="allAdd">{{
                        $t("columnName.add")
                      }}</label>
                    </div>
                  </th>
                  <th>{{ $t("columnName.foodnumber") }}</th>
                  <th>{{ $t("columnName.foodname") }}</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="notSelected in notSelecteds"
                  :key="notSelected.FoodId"
                  @click="notSelectedClick(notSelected.FoodId)"
                >
                  <td>
                    <input
                      class="checkbox"
                      type="checkbox"
                      :value="notSelected.FoodId"
                      v-model="addIds"
                    />
                  </td>
                  <td>{{ notSelected.FoodNumber }}</td>
                  <td>{{ notSelected.FoodName }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <h5>{{ $t("title.selectedfood") }}</h5>
        <div class="card card-table">
          <div class="card-header">
            <button
              type="button"
              class="btn btn-outline-danger btn-icon py-2 px-4"
              @click="onRemove"
            >
              <i class="fas fa-trash-alt"></i>
            </button>
          </div>
          <div class="card-body">
            <table class="table">
              <thead>
                <tr>
                  <th>
                    <div class="custom-control custom-checkbox mr-sm-2">
                      <input
                        type="checkbox"
                        class="custom-control-input"
                        id="allRemove"
                        v-model="allRemove"
                      />
                      <label class="custom-control-label" for="allRemove">{{
                        $t("columnName.remove")
                      }}</label>
                    </div>
                  </th>
                  <th>{{ $t("columnName.foodnumber") }}</th>
                  <th>{{ $t("columnName.foodname") }}</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="selected in selecteds"
                  :key="selected.FoodId"
                  @click="selectedClick(selected.FoodId)"
                >
                  <td>
                    <input
                      class="checkbox"
                      type="checkbox"
                      :value="selected.FoodId"
                      v-model="removeIds"
                    />
                  </td>
                  <td>{{ selected.FoodNumber }}</td>
                  <td>{{ selected.FoodName }}</td>
                  <td>{{ selected.Price }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      supplier: {},
      foodKinds: [],
      currentFoodKindId: "",
      notSelecteds: [],
      selecteds: [],
      addIds: [],
      removeIds: [],
      allAdd: false,
      allRemove: false,
    };
  },
  props: ["isShow"],
  watch: {
    currentFoodKindId() {
      this.updateFoods();
    },
    allAdd(value) {
      this.addIds = [];
      if (value) {
        this.notSelecteds.forEach((item) => this.addIds.push(item.FoodId));
      }
    },
    allRemove(value) {
      this.removeIds = [];
      if (value) {
        this.selecteds.forEach((item) => this.removeIds.push(item.FoodId));
      }
    },
  },
  methods: {
    notSelectedClick(id) {
      const index = this.addIds.indexOf(id);
      if (index >= 0) {
        this.addIds.splice(index, 1);
      } else {
        this.addIds.push(id);
      }
    },
    selectedClick(id) {
      const index = this.removeIds.indexOf(id);
      if (index >= 0) {
        this.removeIds.splice(index, 1);
      } else {
        this.removeIds.push(id);
      }
    },
    updateFoods() {
      const vm = this;
      this.$api.suppliers
        .getFoods(vm.supplier.SupplierId, vm.currentFoodKindId)
        .then((data) => {
          vm.addIds = [];
          vm.removeIds = [];
          vm.notSelecteds = data.NotSelecteds;
          vm.selecteds = data.Selecteds;
          vm.allAdd = false;
          vm.allRemove = false;
        });
    },
    onAdd() {
      if (this.addIds.length == 0) {
        this.$func.message.alert("請選擇商品 !");
        return;
      }
      const vm = this;
      this.$api.suppliers
        .postFoods({
          SupplierId: vm.supplier.SupplierId,
          FoodIds: vm.addIds,
        })
        .then(() => vm.updateFoods());
    },
    onRemove() {
      if (this.removeIds.length == 0) {
        this.$func.message.alert("請選擇商品 !");
        return;
      }
      const vm = this;
      this.$api.suppliers
        .postFoods({
          SupplierId: null,
          FoodIds: vm.removeIds,
        })
        .then(() => vm.updateFoods());
    },
  },
  created() {
    const vm = this;
    const id = vm.$route.params["id"];
    this.$api.suppliers.getName(id).then((data) => {
      if (!data) {
        vm.$func.message.alert("查無此廠商 !");
        vm.$router.go(-1);
      }
      vm.supplier = data;
    });
    this.$api.foodKinds.getNames().then((data) => {
      vm.foodKinds = data;
      if (vm.foodKinds.length > 0) {
        vm.currentFoodKindId = vm.foodKinds[0].FoodKindId;
      }
    });
  },
};
</script>
