<template>
  <input-inline-part
    :labelText="labelText"
    :gridDegree="gridDegree"
    :degree="degree"
  >
    <date-range-picker
      class="col-12 px-0"
      ref="picker"
      opens="right"
      :showWeekNumbers="false"
      :showDropdowns="true"
      :autoApply="true"
      :ranges="false"
      v-model="dateRange"
      @update="dateChanged"
    >
      <div slot="input" v-if="type == 1">
        {{ date.DeliveryBegin }} ~ {{ date.DeliveryEnd }}
      </div>
      <div slot="input" v-else>{{ date.DayBegin }} ~ {{ date.DayEnd }}</div>
    </date-range-picker>
  </input-inline-part>
</template>

<script>
import DateRangePicker from "vue2-daterange-picker";
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";
import date from "@/filters/date";
import inputInlinePart from "@/components/parts/inputInlinePart";

export default {
  data() {
    return {
      dateRange: {},
      ranges: {
        Today: this.$t("modal.today"),
      },
    };
  },
  computed: {
    labelText() {
      if (this.label) return this.label + ":";
      else return this.$t("modal.date") + ":";
    },
  },
  props: ["date", "label", "degree", "gridDegree", "type"],
  components: {
    DateRangePicker,
    inputInlinePart,
  },
  methods: {
    dateChanged(dateTime) {
      this.$emit(
        "dateChanged",
        date(dateTime.startDate),
        date(dateTime.endDate)
      );
    },
  },
};
</script>
