import { render, staticRenderFns } from "./ComboKinds.vue?vue&type=template&id=4dfa5d60&"
import script from "./ComboKinds.vue?vue&type=script&lang=js&"
export * from "./ComboKinds.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports