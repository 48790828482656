import { httpGet, httpPost, httpDelete } from '@/request/http';


let path = '/deviceuvs';

export default{
    gets(){
      return httpGet(`${path}/lists`);
    },
    postDevices(businessId, deviceIds){
      return httpPost(`${path}/adddevices`, {BusinessId: businessId, DeviceUVIds: deviceIds});
    }
}
