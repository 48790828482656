<template>
  <div>
    <condition-part
      :hasExcel="true"
      :hasEndDate="true"
      :hasPdf="true"
      :hasAdd="false"
      :hasEdit="false"
      :hasRemove="false"
      @getItems="getItems"
      @downloadExcel="downloadExcel"
      @downloadPdf="downloadPdf"
      @printPdf="printPdf"
      :items="receivables"
      :pagination="pagination"
      :columns="columns"
      :nameProperty="nameProperty"
      :getTrigger="getTrigger"
    />
  </div>
</template>

<script>
import conditionPart from "@/components/parts/conditionPart";

export default {
  data() {
    return {
      receivables: [],
      pagination: {},
      columns: [
        { name: "BusinessId" },
        { name: "BusinessName" },
        { name: "DeviceName" },
        { name: "StartDate" },
        { name: "EndDate" },
        { name: "Months" },
        { name: "MonthlyFee" },
        { name: "Total" }
      ],
      nameProperty: "DeviceName",
      getTrigger: false
    };
  },
  components: {
    conditionPart
  },
  methods: {
    getItems(condition) {
      const vm = this;
      this.$api.devices.getReceivablePages(condition).then(data => {
        vm.receivables = data.Datas;
        vm.pagination = data.Pagination;
      });
    },
    downloadExcel(condition) {
      this.$api.reportExcels.getPYReceivable(condition);
    },
    downloadPdf(condition) {
      this.$api.reportPdfs.getPYReceivable(condition);
    },
    printPdf(condition) {
      this.$api.reportPdfs.printPYReceivable(condition);
    }
  }
};
</script>
