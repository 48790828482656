<template>
  <div>
    <div class="card card-table">
      <div class="card-header">
        <button
          v-show="isSave"
          type="button"
          class="btn btn-outline-primary btn-icon"
          @click="save"
        >
          <i class="fas fa-check"></i>
        </button>
      </div>
      <div class="card-body">
        <div class="row">
          <div class="col-12 d-flex my-3">
            <div class="col-12 line-setting-title">LIFF</div>
          </div>
          <div class="col-12 d-flex mt-3">
            <label
              for="lineLiffId"
              class="col-4 col-sm-3 col-lg-2 col-form-label"
              >{{ $t("modal.liffid") }}</label
            >
            <input
              type="text"
              class="form-control"
              id="lineLiffId"
              v-model="lineLiff.LineOrderLiffId"
            />
          </div>
          <div class="col-12 d-flex mt-3">
            <label
              for="lineLiffId"
              class="col-4 col-sm-3 col-lg-2 col-form-label"
              >{{ $t("modal.liffid") }}</label
            >
            <input
              type="text"
              class="form-control"
              id="lineLiffId"
              v-model="lineLiff.LineMemberLiffId"
            />
          </div>
          <div class="col-12 d-flex mt-3">
            <label
              for="lineMessageToken"
              class="col-4 col-sm-3 col-lg-2 col-form-label"
              >{{ $t("modal.messagetoken") }}</label
            >

            <input
              type="text"
              class="form-control"
              id="lineMessageToken"
              v-model="lineLiff.LineMessageToken"
            />
          </div>
          <div class="col-12 d-flex my-3">
            <label
              for="lineQrcodeURL"
              class="col-4 col-sm-3 col-lg-2 col-form-label"
              >{{ $t("modal.qrcodeurl") }}</label
            >
            <input
              type="text"
              class="form-control"
              id="lineQrcodeURL"
              v-model="lineLiff.LineQrcodeURL"
            />
          </div>
          <div class="col-12 p-0"><hr /></div>
          <div class="col-12 d-flex my-3">
            <div class="col-12 line-setting-title">Line 登入</div>
          </div>
          <div class="col-12 d-flex mt-3">
            <label
              for="lineChannelId"
              class="col-4 col-sm-3 col-lg-2 col-form-label"
              >{{ $t("modal.channelid") }}</label
            >
            <input
              type="text"
              class="form-control"
              id="lineChannelId"
              v-model="lineLiff.LineChannelId"
            />
          </div>
          <div class="col-12 d-flex my-3">
            <label
              for="lineChannelSecret"
              class="col-4 col-sm-3 col-lg-2 col-form-label"
              >{{ $t("modal.channelsecret") }}</label
            >

            <input
              type="text"
              class="form-control"
              id="lineChannelSecret"
              v-model="lineLiff.LineChannelSecret"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      lineLiff: {},
      isSave: true,
    };
  },
  methods: {
    get(id) {
      const vm = this;
      this.$api.line.get(id).then((data) => {
        vm.lineLiff = data;
      });
    },
    save() {
      const vm = this;
      this.$api.line
        .save(vm.$route.params.id, vm.lineLiff)
        .then(() => {
          vm.$func.message.success(this.$t("funcMsg.updatesuccess"));
          vm.get(vm.$route.params.id);
        })
        .catch((error) => {
          vm.$func.message.error(error);
        });
    },
  },
  created() {
    this.get(this.$route.params.id);
  },
};
</script>
