<template>
  <basic-selects
    :trigger="trigger"
    :isMultiple="isMultiple"
    :columns="columns"
    :items="batches"
    :title="`${$t('title.selectfood')}`"
    idProperty="FoodId"
    nameProperty="FoodName"
    @onSaved="onSaved"
    :id="id"
    :pagination="pagination"
    @getItems="getItems"
    sortBy="batchNumber"
    :defaultItems="defaultItems"
    viewName="batchMainSelects"
  ></basic-selects>
</template>

<script>
import basicSelects from "./BasicSelects";

export default {
  data() {
    return {
      batches: [],
      columns: [{ name: "BatchNumber" }],
      id: "batchMainSelectModal",
      pagination: {},
    };
  },
  props: {
    trigger: false,
    isMultiple: false,
    modalId: undefined,
    parentItems: undefined,
    defaultItems: undefined,
    noSearchType: false,
    foodStockType: undefined,
    noPromotion: false,
    promotionModuleId: undefined,
    isFoodsFilter: false,
    selectedIds: undefined,
    warehouseId: undefined,
    foodId: undefined,
  },
  watch: {
    modalId: {
      handler(value) {
        if (value) this.id = value;
      },
      immediate: true,
    },
    trigger() {
      this.getItems();
    },
  },
  components: {
    basicSelects,
  },
  methods: {
    onSaved(item) {
      this.$emit("onSaved", item);
    },
    getItems() {
      const vm = this;
      this.$api.warehouses
        .getBatches(this.warehouseId, this.foodId)
        .then((data) => {
          vm.batches = data;
        });
    },
  },
};
</script>
