<template>
  <form
    :id="modalId"
    class="modal fade"
    role="dialog"
    data-backdrop="static"
    @submit.prevent="onSaved"
  >
    <div
      class="modal-dialog modal-dialog-centered"
      :class="modalClass"
      role="document"
    >
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">{{ title }}</h5>
          <button type="button" class="close" @click="onClosed">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="row" slot="item">
            <div class="col-md-4">
              <div class="card card-table">
                <div class="card-header">
                  <button
                    v-if="
                      !isVisionEditing &&
                      currentVisionIndex > -1 &&
                      customerVisions.length > 0
                    "
                    type="button"
                    class="btn btn-outline-danger btn-icon"
                    @click="removeVision"
                  >
                    <i class="fas fa-trash-alt"></i>
                  </button>
                </div>
                <div class="card-body">
                  <tablePart
                    :columns="visionColumns"
                    :items="customerVisions"
                    idProperty="CustomerVisionId"
                    :parentIndex="currentVisionIndex"
                    @currentIndexChanged="currentVisionIndexChanged"
                    tableName="customerVision"
                  >
                    <template slot="th-before">
                      <slot name="th-before" />
                    </template>
                    <template slot="th-after">
                      <slot name="th-after" />
                    </template>
                    <template slot="td-before" slot-scope="props">
                      <slot name="td-before" :item="props.item" />
                    </template>
                    <template slot="td-after" slot-scope="props">
                      <slot name="td-after" :item="props.item" />
                    </template>
                  </tablePart>
                </div>
              </div>
            </div>
            <div class="col-md-8">
              <div class="card card-table">
                <div class="card-header">
                  <button
                    :style="
                      isVisionEditing ? 'display: none;' : 'display: block;'
                    "
                    type="button"
                    class="btn btn-outline-primary btn-icon"
                    @click="addVision"
                  >
                    <i class="fas fa-plus"></i>
                  </button>
                  <button
                    v-if="currentVisionIndex > -1"
                    :style="
                      isVisionEditing ? 'display: none;' : 'display: block;'
                    "
                    type="button"
                    class="btn btn-outline-success btn-icon"
                    @click="editVision"
                  >
                    <i class="fas fa-pen"></i>
                  </button>
                  <button
                    :style="
                      isVisionEditing ? 'display: block;' : 'display: none;'
                    "
                    type="button"
                    class="btn btn-outline-primary btn-icon"
                    @click="saveVisionEdit"
                  >
                    <i class="fas fa-check"></i>
                  </button>
                  <button
                    :style="
                      isVisionEditing ? 'display: block;' : 'display: none;'
                    "
                    type="button"
                    class="btn btn-outline-danger btn-icon"
                    @click="cancelVisionEdit"
                  >
                    <i class="fas fa-times"></i>
                  </button>
                </div>
                <div class="card-body overflow-hidden">
                  <div class="row">
                    <div class="col-12 col-lg-6">
                      <div
                        class="form-group d-flex col-12 p-0"
                        style="width: auto"
                      >
                        <label
                          for="visionType"
                          class="col-4 col-lg-3 col-form-label"
                          >{{ $t("modal.visiontype") }}</label
                        >
                        <div class="col">
                          <select
                            v-if="isVisionEditing"
                            id="visionType"
                            class="form-control"
                            v-model="editingVision.VisionType"
                          >
                            <option
                              v-for="type in visionTypes"
                              :key="type.value"
                              :value="type.value"
                            >
                              {{ $t(`option.visionType.${type.name}`) }}
                            </option>
                          </select>
                          <input
                            v-else
                            id="visionType"
                            type="text"
                            class="form-control"
                            v-model="editingVision.VisionTypeText"
                            readonly
                          />
                        </div>
                      </div>
                    </div>
                    <div class="col-12 col-lg-6">
                      <div
                        v-if="editingVision.VisionType == 3"
                        class="form-group d-flex col-12 p-0"
                        style="width: auto"
                      >
                        <label for="visionMemo" class="col-4 col-form-label">{{
                          $t("modal.memo")
                        }}</label>
                        <div class="col">
                          <input
                            id="visionMemo"
                            type="text"
                            class="form-control"
                            v-model="editingVision.VisionMemo"
                            :readonly="!isVisionEditing"
                          />
                        </div>
                      </div>
                    </div>
                    <div class="col-12 col-lg-6">
                      <div
                        class="form-group d-flex col-12 p-0"
                        style="width: auto"
                      >
                        <label
                          for="builder"
                          class="col-4 col-lg-3 col-form-label"
                          >{{ $t("modal.builder") }}</label
                        >
                        <div class="col">
                          <input
                            id="builder"
                            type="text"
                            class="form-control"
                            v-model="editingVision.Builder"
                            :readonly="!isVisionEditing"
                          />
                        </div>
                      </div>
                    </div>
                    <div class="col-12 col-lg-6">
                      <div
                        class="form-group d-flex col-12 p-0"
                        style="width: auto"
                      >
                        <label for="builddate" class="col-4 col-form-label"
                          >{{ $t("modal.builddate")
                          }}<span style="color: red">*</span></label
                        >
                        <div class="col">
                          <input
                            id="builddate"
                            type="date"
                            class="form-control"
                            v-model="editingVision.BuildDate"
                            required
                            :readonly="!isVisionEditing"
                          />
                        </div>
                      </div>
                    </div>
                    <hr class="w-100" />
                    <div class="col-12 col-lg-6">
                      <div
                        class="form-group d-flex col-12 p-0"
                        style="width: auto"
                      >
                        <label for="RSPH" class="col-4 col-lg-3 col-form-label"
                          >R-SPH</label
                        >
                        <div class="col">
                          <input
                            id="RSPH"
                            type="text"
                            class="form-control"
                            v-model="editingVision.RSPH"
                            :readonly="!isVisionEditing"
                          />
                        </div>
                      </div>
                    </div>
                    <div class="col-12 col-lg-6">
                      <div
                        class="form-group d-flex col-12 p-0"
                        style="width: auto"
                      >
                        <label for="LSPH" class="col-4 col-lg-3 col-form-label"
                          >L-SPH</label
                        >
                        <div class="col">
                          <input
                            id="LSPH"
                            type="text"
                            class="form-control"
                            v-model="editingVision.LSPH"
                            :readonly="!isVisionEditing"
                          />
                        </div>
                      </div>
                    </div>
                    <div class="col-12 col-lg-6">
                      <div
                        class="form-group d-flex col-12 p-0"
                        style="width: auto"
                      >
                        <label for="RCYL" class="col-4 col-lg-3 col-form-label"
                          >R-CYL</label
                        >
                        <div class="col">
                          <input
                            id="RCYL"
                            type="text"
                            class="form-control"
                            v-model="editingVision.RCYL"
                            :readonly="!isVisionEditing"
                          />
                        </div>
                      </div>
                    </div>
                    <div class="col-12 col-lg-6">
                      <div
                        class="form-group d-flex col-12 p-0"
                        style="width: auto"
                      >
                        <label for="LCYL" class="col-4 col-lg-3 col-form-label"
                          >L-CYL</label
                        >
                        <div class="col">
                          <input
                            id="LCYL"
                            type="text"
                            class="form-control"
                            v-model="editingVision.LCYL"
                            :readonly="!isVisionEditing"
                          />
                        </div>
                      </div>
                    </div>
                    <div class="col-12 col-lg-6">
                      <div
                        class="form-group d-flex col-12 p-0"
                        style="width: auto"
                      >
                        <label for="RDH" class="col-4 col-lg-3 col-form-label"
                          >R-AX</label
                        >
                        <div class="col">
                          <input
                            id="RAX"
                            type="text"
                            class="form-control"
                            v-model="editingVision.RAX"
                            :readonly="!isVisionEditing"
                          />
                        </div>
                      </div>
                    </div>
                    <div class="col-12 col-lg-6">
                      <div
                        class="form-group d-flex col-12 p-0"
                        style="width: auto"
                      >
                        <label for="LAX" class="col-4 col-lg-3 col-form-label"
                          >L-AX</label
                        >
                        <div class="col">
                          <input
                            id="LAX"
                            type="text"
                            class="form-control"
                            v-model="editingVision.LAX"
                            :readonly="!isVisionEditing"
                          />
                        </div>
                      </div>
                    </div>
                    <div class="col-12 col-lg-6">
                      <div
                        class="form-group d-flex col-12 p-0"
                        style="width: auto"
                      >
                        <label for="RDH" class="col-4 col-lg-3 col-form-label"
                          >R-DH</label
                        >
                        <div class="col">
                          <input
                            id="RDH"
                            type="text"
                            class="form-control"
                            v-model="editingVision.RDH"
                            :readonly="!isVisionEditing"
                          />
                        </div>
                      </div>
                    </div>
                    <div class="col-12 col-lg-6">
                      <div
                        class="form-group d-flex col-12 p-0"
                        style="width: auto"
                      >
                        <label for="LDH" class="col-4 col-lg-3 col-form-label"
                          >L-DH</label
                        >
                        <div class="col">
                          <input
                            id="LDH"
                            type="text"
                            class="form-control"
                            v-model="editingVision.LDH"
                            :readonly="!isVisionEditing"
                          />
                        </div>
                      </div>
                    </div>
                    <div class="col-12 col-lg-6">
                      <div
                        class="form-group d-flex col-12 p-0"
                        style="width: auto"
                      >
                        <label for="RADD" class="col-4 col-lg-3 col-form-label"
                          >R-ADD</label
                        >
                        <div class="col">
                          <input
                            id="RADD"
                            type="text"
                            class="form-control"
                            v-model="editingVision.RADD"
                            :readonly="!isVisionEditing"
                          />
                        </div>
                      </div>
                    </div>
                    <div class="col-12 col-lg-6">
                      <div
                        class="form-group d-flex col-12 p-0"
                        style="width: auto"
                      >
                        <label for="LADD" class="col-4 col-lg-3 col-form-label"
                          >L-ADD</label
                        >
                        <div class="col">
                          <input
                            id="LADD"
                            type="text"
                            class="form-control"
                            v-model="editingVision.LADD"
                            :readonly="!isVisionEditing"
                          />
                        </div>
                      </div>
                    </div>
                    <div class="col-12 col-lg-6">
                      <div
                        class="form-group d-flex col-12 p-0"
                        style="width: auto"
                      >
                        <label for="PD" class="col-4 col-lg-3 col-form-label"
                          >PD</label
                        >
                        <div class="col">
                          <input
                            id="PD"
                            type="text"
                            class="form-control"
                            v-model="editingVision.PupillaryDistance"
                            :readonly="!isVisionEditing"
                          />
                        </div>
                      </div>
                    </div>
                    <div class="col-12 col-lg-6">
                      <div
                        class="form-group d-flex col-12 p-0"
                        style="width: auto"
                      >
                        <label
                          for="prescription"
                          class="col-4 col-lg-3 col-form-label"
                          >{{ $t("modal.prescription") }}</label
                        >
                        <div class="col">
                          <input
                            id="prescription"
                            type="text"
                            class="form-control"
                            v-model="editingVision.Prescription"
                            :readonly="!isVisionEditing"
                          />
                        </div>
                      </div>
                    </div>
                    <div class="col-12 col-lg-6">
                      <div
                        class="form-group d-flex col-12 p-0"
                        style="width: auto"
                      >
                        <label for="RPD" class="col-4 col-lg-3 col-form-label"
                          >R-PD</label
                        >
                        <div class="col">
                          <input
                            id="RPD"
                            type="text"
                            class="form-control"
                            v-model="editingVision.RPD"
                            :readonly="!isVisionEditing"
                          />
                        </div>
                      </div>
                    </div>
                    <div class="col-12 col-lg-6">
                      <div
                        class="form-group d-flex col-12 p-0"
                        style="width: auto"
                      >
                        <label for="LPD" class="col-4 col-lg-3 col-form-label"
                          >L-PD</label
                        >
                        <div class="col">
                          <input
                            id="LPD"
                            type="text"
                            class="form-control"
                            v-model="editingVision.LPD"
                            :readonly="!isVisionEditing"
                          />
                        </div>
                      </div>
                    </div>
                    <div class="col-12">
                      <div
                        class="form-group d-flex col-12 p-0"
                        style="width: auto"
                      >
                        <label
                          for="visionMemo"
                          class="col-4 custom-memo-col col-form-label"
                          >{{ $t("modal.memo") }}</label
                        >
                        <div class="col">
                          <input
                            id="visionMemo"
                            type="text"
                            class="form-control"
                            v-model="editingVision.Memo"
                            :readonly="!isVisionEditing"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>
</template>

<script>
import $ from "jquery";
import tablePart from "@/components/parts/tablePart";

export default {
  data() {
    return {
      visionTypes: this.$enum.visionType.getAll(),
      customerVisions: [],
      visionColumns: [{ name: "VisionTypeText" }, { name: "BuildDate" }],
      currentVisionIndex: -1,
      isVisionEditing: false,
      vision: {},
      editingVision: {},
      buildDate: "",
    };
  },
  props: {
    visionTrigger: false,
    customer: Object,
    title: String,
    modalId: {
      type: String,
      default: "itemModal",
    },
    modalClass: String,
  },
  components: {
    tablePart,
  },
  watch: {
    visionTrigger(value) {
      if (value) {
        $(`#${this.modalId}`).modal("toggle");
        window.onbeforeunload = () => {
          return true;
        };
        this.getCustomerVisions();
      } else {
        this.currentVisionIndex = -1;
        $(`#${this.modalId}`).modal("toggle");
        window.onbeforeunload = null;
      }
    },
    currentVisionIndex(value) {
      this.isVisionEditing = false;
      if (value > -1) {
        this.getVision();
      }
    },
  },
  methods: {
    getVision() {
      const vm = this;
      if (this.customerVisions.length > 0) {
        this.$api.customers
          .getVision(
            this.customerVisions[this.currentVisionIndex].CustomerVisionId
          )
          .then((data) => {
            vm.vision = data;
            vm.vision.VisionTypeText = vm.$enum.visionType.getByValue(
              vm.vision.VisionType
            ).text;
            vm.editingVision = vm.vision;
          })
          .catch((error) => vm.$func.message.error(error.data.Message));
      }
    },
    addVision() {
      this.isVisionEditing = true;
      this.editingVision = {};
    },
    editVision() {
      this.isVisionEditing = true;
    },
    saveVisionEdit() {
      if (!this.editingVision.BuildDate) {
        this.$func.message.alert("請選擇建立日期 !");
        return;
      }
      const vm = this;
      if (!this.editingVision.CustomerId) {
        this.editingVision.CustomerId = this.customer.CustomerId;
      }

      this.$api.customers
        .saveVision(this.editingVision)
        .then(() => {
          vm.$func.message.success("儲存成功 !");
          vm.isVisionEditing = false;
          vm.getCustomerVisions();
          vm.getVision();
        })
        .catch((error) => this.$func.message.error(error.data.Message));
    },
    cancelVisionEdit() {
      this.isVisionEditing = false;
      this.editingVision = this.vision;
    },
    removeVision() {
      const vm = this;
      this.$func.message.confirm("確定刪除此紀錄嗎 ?").then((result) => {
        if (result.isConfirmed) {
          vm.$api.customers
            .removeVision(vm.vision.CustomerVisionId)
            .then(() => {
              vm.$func.message.success("刪除成功 !");
              vm.getCustomerVisions();
              vm.currentVisionIndex = -1;
            })
            .catch((error) => this.$func.message.error(error.data.Message));
        }
      });
    },
    currentVisionIndexChanged(index) {
      this.currentVisionIndex = index;
    },
    onClosed() {
      this.$emit("onClosed");
    },
    getCustomerVisions() {
      this.isVisionEditing = false;
      const vm = this;
      if (this.customer.CustomerId) {
        vm.$api.customers.getVisions(vm.customer.CustomerId).then((data) => {
          vm.customerVisions = [];
          if (data.length > 0) {
            vm.customerVisions = data;
          } else {
            vm.currentVisionIndex = -1;
            vm.vision = {};
            vm.editingVision = {};
          }
        });
      }
    },
  },
};
</script>

<style lang="scss">
@media screen and(min-width: 992px) {
  .custom-memo-col {
    flex: 0 0 12%;
  }
}
</style>
