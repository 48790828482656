<template>
  <div>
    <condition-part
      :hasDate="true"
      :hasEmployee="true"
      :hasFood="true"
      :items="combinationTrades"
      :pagination="pagination"
      :columns="columns"
      @getItems="getItems"
      @addItem="add"
      @editItem="edit"
      @removeItem="remove"
      :nameProperty="nameProperty"
      :getTrigger="getTrigger"
      :hasAdd="authority.IsAdd"
      :hasEdit="false"
      :hasRemove="authority.IsRemove"
    >
      <template slot="dataButtons" slot-scope="props">
        <button
          type="button"
          class="btn btn-outline-secondary btn-icon"
          @click="rotate(props.index)"
        >
          <i class="fas fa-undo"></i>
        </button>
      </template>
    </condition-part>
  </div>
</template>

<script>
import conditionPart from "@/components/parts/conditionPart";

export default {
  data() {
    return {
      combinationTrades: [],
      pagination: {},
      columns: [
        { name: "CombinationTradeDate" },
        { name: "CombinationTradeNumber" },
        { name: "WarehouseName" },
        { name: "EmployeeName" },
        { name: "FoodNumber" },
        { name: "FoodName" },
        { name: "Qty" },
        { name: "Total" },
        { name: "Memo" },
      ],
      nameProperty: "CombinationTradeNumber",
      getTrigger: false,
      authority: {},
    };
  },
  components: {
    conditionPart,
  },
  methods: {
    getItems(condition) {
      condition.TradeType = 12;
      this.$api.combinationTrades.getPages(condition).then((data) => {
        this.combinationTrades = data.Datas;
        this.pagination = data.Pagination;
      });
    },
    add() {
      this.$router.push({ name: "CombinationTradeAdd" });
    },
    edit(item) {
      this.$router.push({
        name: "CombinationTradeEdit",
        params: { id: item.CombinationTradeId },
      });
    },
    remove(item) {
      this.$api.combinationTrades.remove(item.CombinationTradeId).then(() => {
        this.getTrigger = !this.getTrigger;
      });
    },
    rotate(index) {
      let trade = this.combinationTrades[index];
      this.$router.push({
        name: "CombinationTradeReturn",
        params: { id: trade.CombinationTradeId },
      });
    },
  },
  created() {
    this.authority = this.$func.localStorage.getAuthByName(this.$route.name);
  },
};
</script>
