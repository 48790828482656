<template>
  <itemModal
    :isShowModal="isShowModal"
    @onClosed="isShowModal = !isShowModal"
    @onSaved="onSaved"
    modalId="userChangePasswordModal"
    title="修改密碼"
  >
    <div slot="item">
      <div class="row">
        <div class="col-12">
          <div class="form-group">
            <label for="oldPassword">舊密碼</label>
            <input
              type="password"
              class="form-control"
              id="oldPassword"
              v-model="user.OldPassword"
              required
            />
          </div>
        </div>
        <div class="col-12">
          <div class="form-group">
            <label for="newPassword">新密碼</label>
            <input
              type="password"
              class="form-control"
              id="newPassword"
              v-model="user.NewPassword"
              required
            />
          </div>
        </div>
        <div class="col-12">
          <div class="form-group">
            <label for="checkPassword">確認密碼</label>
            <input
              type="password"
              class="form-control"
              id="checkPassword"
              v-model="checkPassword"
              required
            />
          </div>
        </div>
      </div>
    </div>
  </itemModal>
</template>

<script>
import itemModal from "@/components/ItemModal";

export default {
  data() {
    return {
      user: {},
      isShowModal: false,
      isEdit: false,
      checkPassword: "",
    };
  },
  components: {
    itemModal,
  },
  props: ["id", "trigger"],
  methods: {
    onSaved() {
      if (this.checkPassword != this.user.NewPassword) {
        this.$func.message.alert("請輸入相同密碼 !");
        return;
      }
      let dTO = {};
      dTO.OldPassword = this.$func.crypto.encrypt(this.user.OldPassword);
      dTO.NewPassword = this.$func.crypto.encrypt(this.user.NewPassword);
      const vm = this;
      this.$api.users
        .changePassword(dTO)
        .then(() => {
          vm.isShowModal = !vm.isShowModal;
        })
        .catch((error) => {
          vm.$func.message.error(error.data.Message);
        });
    },
  },
  watch: {
    trigger() {
      this.isShowModal = !this.isShowModal;
      this.user = {};
    },
  },
};
</script>
