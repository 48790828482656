<template>
  <div>
    <page-table
      :items="modules"
      :columns="columns"
      :idProperty="idProperty"
      :nameProperty="nameProperty"
      :getTrigger="getTrigger"
      :hasPage="false"
      @getItems="getModules"
      @addItem="addModule"
      @editItem="editModule"
      @removeItem="removeModule"
      @currentIndexChanged="currentIndexChanged"
    >
      <template slot="button">
        <button
          v-if="hasDevice"
          type="button"
          class="btn btn-outline-primary btn-icon"
          @click="onDevice"
        >
          <i class="fas fa-cash-register"></i>
        </button>
        <slot name="buttons" />
      </template>
    </page-table>

    <item-modal
      :isShowModal="isShowModal"
      @onClosed="isShowModal = !isShowModal"
      @onSaved="saveModule"
      :title="modalTitle"
    >
      <div slot="item">
        <div class="row">
          <div class="col">
            <div class="form-group">
              <label for="moduleName">名稱</label>
              <input
                type="text"
                class="form-control"
                id="moduleName"
                v-model="module.ModuleName"
                required
              />
            </div>
          </div>
        </div>
      </div>
    </item-modal>
  </div>
</template>

<script>
import pageTable from "@/components/PageTable";
import itemModal from "@/components/ItemModal";

export default {
  data() {
    return {
      modules: [],
      module: {},
      currentModuleId: "",
      isShowModal: false,
      modalTitle: "",
      columns: [
        {
          name: "ModuleName",
        },
      ],
      idProperty: "ModuleId",
      nameProperty: "ModuleName",
      getTrigger: false,
    };
  },
  props: {
    moduleType: {
      type: Number,
      default: 0,
    },
    hasDevice: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    pageTable,
    itemModal,
  },
  methods: {
    currentIndexChanged(index) {
      const module = this.modules[index];
      if (module) {
        this.currentModuleId = module.ModuleId;
        this.$emit("currentModuleIdChanged", this.currentModuleId);
      } else {
        this.$emit("currentModuleIdChanged", undefined);
      }
    },
    getModules() {
      const vm = this;
      this.$api.dirModules.getlist(this.moduleType).then((data) => {
        vm.modules = data;
      });
    },
    addModule() {
      this.module = {};
      this.isShowModal = !this.isShowModal;
      this.modalTitle = "新增模組";
    },
    editModule() {
      const vm = this;
      this.$api.dirModules.get(vm.currentModuleId).then((data) => {
        vm.module = data;
        vm.isShowModal = !vm.isShowModal;
        vm.modalTitle = "編輯模組";
      });
    },
    removeModule() {
      const vm = this;
      this.$api.dirModules.remove(vm.currentModuleId).then(() => {
        vm.getTrigger = !vm.getTrigger;
      });
    },
    saveModule() {
      const vm = this;
      this.module.ModuleType = this.moduleType;
      this.$api.dirModules.save(vm.module).then(() => {
        vm.isShowModal = !vm.isShowModal;
        vm.getTrigger = !vm.getTrigger;
      });
    },
    getCurrentId() {
      if (!this.currentModuleId || this.currentModuleId.length == 0) {
        this.$func.message.alert("請選擇一筆資料 !");
        return false;
      }
      return true;
    },
    onDevice() {
      if (!this.getCurrentId()) return;
      const vm = this;
      this.$router.push({
        name: "DirModuleDevice",
        params: { id: vm.currentModuleId },
      });
    },
  },
  watch: {
    moduleType() {
      this.getTrigger = !this.getTrigger;
    },
  },
  created() {
    if (this.moduleType == undefined) this.$router.go(-1);
  },
};
</script>
