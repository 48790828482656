import { httpGet, httpPost, httpDelete } from "@/request/http";

let path = "/functions";

export default {
  get(id) {
    return httpGet(`${path}/${id}`);
  },
  getAll() {
    return httpGet(`${path}/all`);
  },
  save(item) {
    return httpPost(path, item);
  },
  remove(id) {
    return httpDelete(`${path}/${id}`);
  },
};
