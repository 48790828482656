<template>
  <div>
    <search-and-table-part
      :pagination="pagination"
      @getItems="getFoodKinds"
      @addItem="addFoodKind"
      @editItem="editFoodKind"
      @removeItem="removeFoodKind"
      @trdbClicked="editFoodKind"
      :items="foodKinds"
      :columns="columns"
      :idProperty="idProperty"
      :nameProperty="nameProperty"
      :getTrigger="getTrigger"
      :hasAdd="authority.IsAdd && !hasShowmore && !hasDigiwin"
      :hasEdit="authority.IsUpdate && !hasDigiwin"
      :hasRemove="authority.IsRemove && !hasShowmore && !hasDigiwin"
      :isSortable="true"
      ><template slot="searchArea">
        <div class="row">
          <div class="col-lg-6">
            <div class="form-group row">
              <label
                for="searchFoodKindNumber"
                class="col-3 col-md-2 col-lg-3 col-form-label"
                >{{ $t("searchAndTablePart.foodkindnumber") }}:</label
              >
              <div class="col">
                <input
                  type="text"
                  class="form-control"
                  id="searchFoodKindNumber"
                  v-model="searchDTO.FoodKindNumber"
                />
              </div>
            </div>
          </div>
          <div class="col-lg-6">
            <div class="form-group row">
              <label
                for="searchFoodKindName"
                class="col-3 col-md-2 col-lg-3 col-form-label"
                >{{ $t("searchAndTablePart.foodkindname") }}:</label
              >
              <div class="col">
                <input
                  type="text"
                  class="form-control"
                  id="searchFoodKindName"
                  v-model="searchDTO.FoodKindName"
                />
              </div>
            </div>
          </div>
        </div>
      </template>
      <template slot="searchButtonArea">
        <import-part
          v-if="!hasShowmore && !hasDigiwin"
          @onImport="onImport"
          :title="`${$t('modal.importfoodkind')}`"
        />
        <export-part v-if="!hasShowmore && !hasDigiwin" @onExport="onExport" />
      </template>
      <template slot="button">
        <import-part
          v-if="authority.IsAdd && !hasShowmore && !hasDigiwin"
          @onImport="onImport"
          :title="`${$t('modal.importfoodkind')}`"
        />
        <export-part @onExport="onExport" />
      </template>
    </search-and-table-part>

    <itemModal
      :isShowModal="isShowModal"
      @onClosed="isShowModal = !isShowModal"
      @onSaved="saveFoodKind"
      :title="modalTitle"
      :modalClass="modalClass"
    >
      <div slot="item">
        <div class="row">
          <div class="col-md-6" :class="inputClass">
            <div class="form-group">
              <label for="foodKindNumber">{{ $t("modal.number") }}</label>
              <input
                type="text"
                class="form-control"
                id="foodKindNumber"
                v-model="foodKind.FoodKindNumber"
                required
                :readonly="hasShowmore || hasDigiwin"
              />
            </div>
          </div>
          <div class="col-md-6" :class="inputClass">
            <div class="form-group">
              <label for="foodKindName">{{ $t("modal.name") }}</label>
              <input
                type="text"
                class="form-control"
                id="foodKindName"
                v-model="foodKind.FoodKindName"
                required
              />
            </div>
          </div>
          <div class="col-md-6" :class="inputClass">
            <div class="form-group">
              <label for="foodMajorKindId">{{
                $t("modal.foodmajorkindid")
              }}</label>
              <select
                class="form-control"
                id="foodMajorKindId"
                v-model="foodKind.FoodMajorKindId"
              >
                <option
                  v-for="foodMajorKind in foodMajorKinds"
                  :value="foodMajorKind.FoodMajorKindId"
                  :key="foodMajorKind.FoodMajorKindId"
                >
                  {{ foodMajorKind.FoodMajorKindNumber }} -
                  {{ foodMajorKind.FoodMajorKindName }}
                </option>
              </select>
            </div>
          </div>
          <div class="col-md-6 col-lg-4" v-if="isShow">
            <div class="form-group">
              <label for="fontSize">{{ $t("modal.fontsize") }}</label>
              <input
                type="number"
                class="form-control"
                id="fontSize"
                v-model.number="foodKind.FontSize"
                required
              />
            </div>
          </div>
          <div class="col-md-6 col-lg-4" v-if="isShow">
            <div class="form-group">
              <label for="foreColor">{{ $t("modal.forecolor") }}</label>
              <input
                type="color"
                class="form-control"
                id="foreColor"
                v-model="foodKind.ForeColor"
              />
            </div>
          </div>
          <div class="col-md-6 col-lg-4" v-if="isShow">
            <div class="form-group">
              <label for="backColor">{{ $t("modal.backcolor") }}</label>
              <input
                type="color"
                class="form-control"
                id="backColor"
                v-model="foodKind.BackColor"
              />
            </div>
          </div>
          <div class="col-12 row">
            <div class="col-md-6 col-lg-4" v-if="isShow">
              <div class="custom-control custom-checkbox">
                <input
                  type="checkbox"
                  class="custom-control-input"
                  id="sameColor"
                  v-model="foodKind.SameColor"
                />
                <label class="custom-control-label" for="sameColor">{{
                  $t("modal.samecolor")
                }}</label>
              </div>
            </div>
            <div class="col-md-6 col-lg-4" v-if="isShow">
              <div class="custom-control custom-checkbox">
                <input
                  type="checkbox"
                  class="custom-control-input"
                  id="noSameColor"
                  v-model="foodKind.NoSameColor"
                />
                <label class="custom-control-label" for="noSameColor">{{
                  $t("modal.nosamecolor")
                }}</label>
              </div>
            </div>
            <div class="col-md-6 col-lg-4" v-if="isShow">
              <div class="custom-control custom-checkbox">
                <input
                  type="checkbox"
                  class="custom-control-input"
                  id="isFoodSameFontSize"
                  v-model="foodKind.IsFoodSameFontSize"
                />
                <label class="custom-control-label" for="isFoodSameFontSize">{{
                  $t("modal.isfoodsamefontsize")
                }}</label>
              </div>
            </div>
            <div class="col-md-6 col-lg-4" v-if="isShow">
              <div class="custom-control custom-checkbox">
                <input
                  type="checkbox"
                  class="custom-control-input"
                  id="noGiveIntegral"
                  v-model="foodKind.NoGiveIntegral"
                />
                <label class="custom-control-label" for="noGiveIntegral">{{
                  $t("modal.nogiveintegral")
                }}</label>
              </div>
            </div>
            <div class="col-md-6 col-lg-4" v-if="productType != 3">
              <div class="custom-control custom-checkbox">
                <input
                  type="checkbox"
                  class="custom-control-input"
                  id="noDiscount"
                  v-model="foodKind.NoDiscount"
                />
                <label class="custom-control-label" for="noDiscount">{{
                  $t("dataModalPart.nodiscount")
                }}</label>
              </div>
            </div>
            <ul
              class="col-12 nav nav-tabs mt-2 px-1"
              id="myTab"
              role="tablist"
              v-if="isMultiLanguage"
            >
              <li class="nav-item" v-if="isMultiLanguage">
                <a
                  class="nav-link show active"
                  data-toggle="tab"
                  href="#languageSetting"
                  role="tab"
                  aria-controls="languageSetting"
                  aria-selected="true"
                  >{{ $t("dataModalPart.multilanguage") }}</a
                >
              </li>
            </ul>
            <div
              class="col-12 tab-content mt-2"
              id="myTabContent"
              v-if="isMultiLanguage"
            >
              <div
                v-if="isMultiLanguage"
                class="tab-pane fade"
                :class="{ 'show active': isShow }"
                id="colorSetting"
                role="tabpanel"
                aria-labelledby="profile-tab"
              ></div>
              <div class="row">
                <div v-if="hasEnglish" class="col-12 row">
                  <div class="col-12 col-md-6 form-group">
                    <label for="english">{{
                      $t("dataModalPart.english")
                    }}</label>
                    <input
                      type="text"
                      class="form-control"
                      id="english"
                      v-model="foodKind.English"
                    />
                  </div>
                  <div v-if="hasJapanese" class="col-12 col-md-6 form-group">
                    <label for="japanese">{{
                      $t("dataModalPart.japanese")
                    }}</label>
                    <input
                      type="text"
                      class="form-control"
                      id="japanese"
                      v-model="foodKind.Japanese"
                    />
                  </div>
                  <div v-if="hasKorean" class="col-12 col-md-6 form-group">
                    <label for="korean">{{ $t("dataModalPart.korean") }}</label>
                    <input
                      type="text"
                      class="form-control"
                      id="korean"
                      v-model="foodKind.Korean"
                    />
                  </div>
                  <div v-if="hasFrench" class="col-12 col-md-6 form-group">
                    <label for="french">{{ $t("dataModalPart.french") }}</label>
                    <input
                      type="text"
                      class="form-control"
                      id="french"
                      v-model="foodKind.French"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </itemModal>
  </div>
</template>

<script>
import searchAndTablePart from "@/components/parts/searchAndTablePart";
import itemModal from "@/components/ItemModal";
import importPart from "@/components/ImportPart";
import exportPart from "@/components/ExportPart";

export default {
  data() {
    return {
      searchDTO: {},
      foodKinds: [],
      foodMajorKinds: [],
      foodKind: {},
      pagination: {},
      isShowModal: false,
      modalTitle: "",
      columns: [
        {
          name: "FoodKindNumber",
        },
        {
          name: "FoodKindName",
        },
        {
          name: "FoodMajorKindNumber",
        },
        {
          name: "FoodMajorKindName",
        },
      ],
      idProperty: "FoodKindId",
      nameProperty: "FoodKindName",
      getTrigger: false,
      authority: {},
      hasEnglish: window.localStorage["hasEnglish"] == "true",
      hasJapanese: window.localStorage["hasJapanese"] == "true",
      hasKorean: window.localStorage["hasKorean"] == "true",
      hasFrench: window.localStorage["hasFrench"] == "true",
    };
  },
  components: {
    searchAndTablePart,
    itemModal,
    importPart,
    exportPart,
  },
  computed: {
    isMultiLanguage() {
      return (
        this.hasEnglish || this.hasJapanese || this.hasKorean || this.hasFrench
      );
    },
    productType() {
      return parseInt(window.localStorage["productType"]);
    },
    currentLang() {
      return this.$store.state.currentLang;
    },
    isShow() {
      return window.localStorage["productType"] == 0;
    },
    modalClass() {
      return this.isShow ? "modal-lg" : "modal-md";
    },
    inputClass() {
      return this.isShow ? "col-lg-4" : "";
    },
    hasDigiwin() {
      return window.localStorage["erpType"] == 3;
    },
    hasShowmore() {
      return window.localStorage["hasShowmore"] == "true";
    },
  },
  watch: {
    currentLang() {
      this.setOptions();
    },
  },
  methods: {
    setOptions() {
      this.foodMajorKinds[0].FoodMajorKindName = this.$t("modal.none");
    },
    getFoodKinds(dTO) {
      const vm = this;
      this.$api.foodKinds
        .getPages({ ...dTO, ...this.searchDTO })
        .then((data) => {
          vm.foodKinds = data.Datas;
          vm.pagination = data.Pagination;
        });
    },
    addFoodKind() {
      this.foodKind = this.$func.defaultModel.getFoodKind();
      this.isShowModal = !this.isShowModal;
      this.modalTitle = this.$t("modal.addfoodkind");
    },
    editFoodKind(foodKind) {
      if (!this.authority.IsUpdate) {
        return;
      }
      const vm = this;
      this.$api.foodKinds.get(foodKind.FoodKindId).then((data) => {
        vm.foodKind = data;
        vm.isShowModal = !vm.isShowModal;
        vm.modalTitle = this.$t("modal.editfoodkind");
      });
    },
    removeFoodKind(foodKind) {
      const vm = this;
      this.$api.foodKinds.remove(foodKind.FoodKindId).then(() => {
        vm.getTrigger = !vm.getTrigger;
      });
    },
    saveFoodKind() {
      const vm = this;
      this.$api.foodKinds
        .save(vm.foodKind)
        .then(() => {
          vm.isShowModal = !vm.isShowModal;
          vm.getTrigger = !vm.getTrigger;
        })
        .catch((error) => vm.$func.message.error(error.data.Message));
    },
    onExport() {
      this.$api.foodKinds.getExcel();
    },
    onImport(file) {
      let formData = new FormData();
      formData.append("file", file);
      const vm = this;
      this.$api.foodKinds.postExcel(formData).then(() => {
        vm.getTrigger = !vm.getTrigger;
      });
    },
  },
  created() {
    const vm = this;
    this.$api.foodMajorKinds.getAll().then((data) => {
      vm.foodMajorKinds = data;
      vm.foodMajorKinds.splice(0, 0, {
        FoodMajorKindId: null,
        FoodMajorKindName: `${this.$t("modal.none")}`,
      });
    });

    this.authority = this.$func.localStorage.getAuthByName(this.$route.name);
  },
};
</script>
