import { httpGet, httpPost, httpDelete } from "@/request/http";
import func from "@/funcs";

let path = "/stocktransfers";

export default {
  get(id) {
    return httpGet(`${path}/${id}`);
  },
  getPages(params) {
    return httpGet(`${path}/pages`, params);
  },
  post(item) {
    return httpPost(path, item);
  },
  delete(id) {
    return httpDelete(`${path}/${id}`);
  },
  print(id) {
    func.printPdf.get(`${path}/${id}/report`);
  },
  getExcel() {
    func.getExcel.get(`${path}/foodexcel/example`, "匯入調撥單商品");
  },
  getExcelByPost(foods) {
    func.getExcel.post(`${path}/exportfood`, "匯入調撥單商品", foods);
  },
  postExcel(formData) {
    return func.postExcel(`${path}/importfood`, formData);
  },
  start(id) {
    return httpPost(`${path}/${id}/start`);
  },
  check(id, dTO) {
    return httpPost(`${path}/${id}/check`, dTO);
  },
  done(id) {
    return httpPost(`${path}/${id}/done`);
  },
};
