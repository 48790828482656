export function getAll() {
  return [
    {
      value: 0,
      name: "All",
    },
    {
      value: 1,
      name: "Class1",
    },
    {
      value: 2,
      name: "Class2",
    },
    {
      value: 3,
      name: "Class3",
    },
    {
      value: 4,
      name: "Class4",
    },
    {
      value: 5,
      name: "Class5",
    },
    {
      value: 6,
      name: "Class6",
    },
    {
      value: 7,
      name: "Class7",
    },
    {
      value: 8,
      name: "Class8",
    },
    {
      value: 9,
      name: "Class9",
    },
    {
      value: 10,
      name: "Class10",
    },
  ];
}
