<template>
  <div>
    <pageTable
      :parentPage="pagination"
      @getItems="getStores"
      @addItem="addStore"
      @editItem="editStore"
      @removeItem="removeStore"
      @trdbClicked="editStore"
      :items="stores"
      :columns="columns"
      :idProperty="idProperty"
      :nameProperty="nameProperty"
      :getTrigger="getTrigger"
      :hasAdd="authority.IsAdd"
      :hasEdit="authority.IsUpdate"
      :hasRemove="authority.IsRemove"
      :isSortable="true"
    >
    </pageTable>

    <store-data
      :isShow="isShowModal"
      :modalTitle="modalTitle"
      @close="isShowModal = !isShowModal"
      @save="save"
      :entity="store"
    ></store-data>
  </div>
</template>

<script>
import pageTable from "@/components/PageTable";
import storeData from "@/components/datas/StoreData";

export default {
  data() {
    return {
      stores: [],
      store: {},
      pagination: {},
      isShowModal: false,
      columns: [
        {
          name: "StoreNumber",
        },
        {
          name: "StoreName",
        },
        {
          name: "PrincipleNumber",
        },
        {
          name: "PrincipleName",
        },
      ],
      idProperty: "StoreId",
      nameProperty: "StoreName",
      getTrigger: false,
      authority: {},
      modalTitle: "",
    };
  },
  components: {
    pageTable,
    storeData,
  },
  methods: {
    getStores(dTO) {
      this.$api.stores.getPages(dTO).then((data) => {
        this.stores = data.Datas;
        this.pagination = data.Pagination;
      });
    },
    addStore() {
      this.store = {};
      this.isShowModal = !this.isShowModal;
      this.modalTitle = this.$t("modal.addstore");
    },
    editStore(store) {
      if (!this.authority.IsUpdate) {
        return;
      }
      this.$api.stores.get(store.StoreId).then((data) => {
        this.store = data;
        this.isShowModal = !this.isShowModal;
        this.modalTitle = this.$t("modal.editstore");
      });
    },
    save() {
      this.isShowModal = !this.isShowModal;
      this.getTrigger = !this.getTrigger;
    },
    removeStore(store) {
      this.$api.stores.remove(store.StoreId).then(() => {
        this.getTrigger = !this.getTrigger;
      });
    },
  },
  created() {
    this.authority = this.$func.localStorage.getAuthByName(this.$route.name);
  },
};
</script>
