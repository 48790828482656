import getViewName from "@/funcs/scripts/getViewName";
import func from "@/funcs";

let path = "/reports/excel";

function getExcel(condition, p, name) {
  if (!name) name = p;
  func.getExcel.post(`${path}/${p}`, getViewName(name), condition);
}

export default {
  getTimeStatistic(condition) {
    getExcel(condition, "timestatistic");
  },
  getEmployeeAnalysis(condition) {
    getExcel(condition, "employeeanalysis");
  },
  getFoodSaleStatistic(condition) {
    getExcel(condition, "foodsalestatistic");
  },
  getFoodSaleDetail(condition) {
    getExcel(condition, "foodsaledetail");
  },
  getFoodDayStatistic(condition) {
    getExcel(condition, "fooddaystatistic");
  },
  getFoodMajorKindStatistic(condition) {
    getExcel(condition, "foodmajorkindstatistic");
  },
  getFoodKindStatistic(condition) {
    getExcel(condition, "foodkindstatistic");
  },
  getFree(condition) {
    getExcel(
      condition,
      "free",
      condition.ReportType == "statistic" ? "freestatistic" : "freedetail"
    );
  },
  getCustomerFree(condition) {
    getExcel(condition, "customerfree");
  },
  getCollectionPayment(condition) {
    getExcel(condition, "collectionpayment", "collectionpaymentdetail");
  },
  getInvoice(condition) {
    getExcel(
      condition,
      "invoice",
      condition.ReportType == "simple" ? "invoicesimple" : "invoicedetail"
    );
  },
  getInvoicePrize(condition) {
    getExcel(condition, "invoiceprize", "reportinvoiceprize");
  },
  getWorkRecord(condition) {
    getExcel(
      condition,
      "workrecord",
      condition.ReportType == "simple" ? "workrecordsimple" : "workrecorddetail"
    );
  },
  getSale(condition) {
    getExcel(
      condition,
      "sale",
      condition.ReportType == "simple" ? "salesimple" : "saledetail"
    );
  },
  getSaler(condition) {
    getExcel(
      condition,
      "saler",
      condition.ReportType == "simple"
        ? "salesimplesaler"
        : condition.ReportType == "detail"
        ? "saledetailsaler"
        : "daystatisticsaler"
    );
  },
  getCustomer(condition) {
    getExcel(
      condition,
      "customer",
      condition.ReportType == "simple" ? "customersimple" : "customerdetail"
    );
  },
  getCustomerFoodSale(condition) {
    getExcel(condition, "customerfoodsale");
  },
  getCustomerSaleStatistic(condition) {
    getExcel(condition, "customersalestatistic");
  },
  getPGTalkDeposit(condition) {
    getExcel(condition, "pgtalkdeposit", "pgtalkdepositdetail");
  },

  getSupplier(condition) {
    getExcel(
      condition,
      "supplier",
      condition.ReportType == "simple" ? "suppliersimple" : "supplierdetail"
    );
  },
  getSupplierPurchaseOrder(condition) {
    getExcel(condition, "supplierpurchaseorder", "supplierpurchaseorderdetail");
  },
  getSupplierFoodSale(condition) {
    getExcel(condition, "supplierfoodsale");
  },
  getSupplierFoodSaleOriginal(condition) {
    getExcel(condition, "supplierfoodsaleoriginal");
  },
  getFoodPurchaseOrder(condition) {
    getExcel(condition, "foodpurchaseorder", "foodpurchaseorder");
  },
  getFoodPurchase(condition) {
    getExcel(condition, "foodpurchase", "foodpurchasedetail");
  },

  getStock(condition) {
    getExcel(condition, "stock");
  },
  getSafeQty(condition) {
    getExcel(condition, "safeqty");
  },
  getFoodMovement(condition) {
    getExcel(
      condition,
      "foodmovement",
      condition.ReportType == "simple"
        ? "foodmovementsimple"
        : "foodmovementdetail"
    );
  },
  getPayment(condition) {
    getExcel(
      condition,
      "payment",
      condition.ReportType == "detail" ? "paymentdetail" : "paymentsimple"
    );
  },
  getPayable(condition) {
    getExcel(
      condition,
      "payable",
      condition.ReportType == "detail"
        ? "payabledetail"
        : condition.ReportType == "simple"
        ? "payablesimple"
        : "payablestatistic"
    );
  },
  getReceipt(condition) {
    getExcel(
      condition,
      "receipt",
      condition.ReportType == "detail" ? "receiptdetail" : "receiptsimple"
    );
  },
  getReceivable(condition) {
    getExcel(
      condition,
      "receivable",
      condition.ReportType == "detail"
        ? "receivabledetail"
        : condition.ReportType == "simple"
        ? "receivablesimple"
        : "receivablestatistic"
    );
  },
  getPYReceivable(condition) {
    getExcel(condition, "pyreceivable");
  },
  getPay(condition) {
    getExcel(
      condition,
      "pay",
      condition.ReportType == "detail" ? "paydetail" : "paysimple"
    );
  },
  getSalePay(condition) {
    getExcel(
      condition,
      "salepay",
      condition.ReportType == "detail" ? "salepaydetail" : "salepaysimple"
    );
  },
  getTasteStatistic(condition) {
    getExcel(condition, "tastestatistic");
  },
  getDeposit(condition) {
    getExcel(
      condition,
      "deposit",
      condition.ReportType == "sale"
        ? "depositsaledetail"
        : "depositstoredetail"
    );
  },
  getInventoryPAndL(condition) {
    getExcel(
      condition,
      "inventorypandl",
      condition.ReportType == "statistic"
        ? "inventorypandlstatistic"
        : "inventorypandldetail"
    );
  },
  getClassSale(condition) {
    getExcel(
      condition,
      "classsale",
      condition.ReportType == "simple"
        ? "classsalesimple"
        : "classsalestatistic"
    );
  },
  getPurchase(condition) {
    getExcel(
      condition,
      "purchase",
      condition.ReportType == "detail" ? "purchasedetail" : "purchasesimple"
    );
  },
  getPurchaseReturn(condition) {
    getExcel(
      condition,
      "purchasereturn",
      condition.ReportType == "detail"
        ? "purchasereturndetail"
        : "purchasereturnsimple"
    );
  },
  getPurchaseOrder(condition) {
    getExcel(
      condition,
      "purchaseorder",
      condition.ReportType == "detail"
        ? "purchaseorderdetail"
        : "purchaseordersimple"
    );
  },
  getStockAdjust(condition) {
    getExcel(
      condition,
      "stockadjust",
      condition.ReportType == "detail"
        ? "stockadjustdetail"
        : "stockadjustsimple"
    );
  },

  getStockAdjustDirectory(condition) {
    getExcel(condition, "stockadjustdirectory", "reportstockadjustdirectory");
  },

  getStockTransfer(condition) {
    getExcel(
      condition,
      "stocktransfer",
      condition.ReportType == "detail"
        ? "stocktransferdetail"
        : "stocktransfersimple"
    );
  },
  getInventory(condition) {
    getExcel(
      condition,
      "inventory",
      condition.ReportType == "detail" ? "inventorydetail" : "inventorysimple"
    );
  },
  getPeople(condition) {
    getExcel(
      condition,
      "people",
      condition.ReportType == "detail"
        ? "peopledetail"
        : condition.ReportType == "simple"
        ? "peoplesimple"
        : "peoplestatistic"
    );
  },
  getIntegral(condition) {
    getExcel(
      condition,
      "integral",
      condition.ReportType == "detail"
        ? "integraltradedetail"
        : "integraltradestatistic"
    );
  },
  getSaleOrder(condition) {
    getExcel(
      condition,
      "saleorder",
      condition.ReportType == "detail" ? "saleorderdetail" : "saleordersimple"
    );
  },
  getOrderFoodStatistic(condition) {
    getExcel(condition, "orderfoodstatistic");
  },
  getCoupon(condition) {
    getExcel(condition, "coupon", "coupondetail");
  },
  getCombinationTrade(condition) {
    getExcel(
      condition,
      "combinationtrade",
      condition.ReportType == "detail"
        ? "combinationtradedetail"
        : "combinationtradesimple"
    );
  },
  getSeperationTrade(condition) {
    getExcel(
      condition,
      "seperationtrade",
      condition.ReportType == "detail"
        ? "seperationtradedetail"
        : "seperationtradesimple"
    );
  },
  getCustomerStock(condition) {
    getExcel(condition, "customerstock", "customerstock");
  },
  getCustomerStockMovement(condition) {
    getExcel(condition, "customerstockmovement", "customerstockmovementdetail");
  },
  getStockBatch(condition) {
    getExcel(condition, "stockbatch", "stockbatch");
  },
  getStockBatchMovement(condition) {
    getExcel(condition, "stockbatchmovement", "stockbatchmovement");
  },
  getSaleProfit(condition) {
    getExcel(condition, "saleprofit", "saleprofitdetail");
  },
  getERPSummary(condition) {
    getExcel(condition, "erpsummary", "erpsummary");
  },
  getCreditCard(condition) {
    getExcel(condition, "creditcard", "creditcard");
  },
};
