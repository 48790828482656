<template>
  <div>
    <search
      :title="`${$t('modal.foodmajorkind')}`"
      :items="foodMajorKinds"
      idProperty="FoodMajorKindId"
      nameProperty="FoodMajorKindName"
      @triggerClick="triggerClick"
      @remove="remove"
      :searchItems="searchItems"
    />

    <foodMajorKind-select
      :isMultiple="true"
      :trigger="trigger"
      :defaultItems="defaultItems"
      @onSaved="add"
    />
  </div>
</template>

<script>
import search from "./Search";
import foodMajorKindSelect from "@/components/selects/FoodMajorKindSelects";

export default {
  data() {
    return {
      trigger: false,
      foodMajorKinds: [],
      defaultItems: undefined,
    };
  },
  props: {
    searchItems: {
      type: Object,
      default: () => ({}),
    },
  },
  watch: {
    searchItems(value) {
      if (
        JSON.parse(sessionStorage.getItem("isSamePage")) &&
        value.FoodMajorKind
      ) {
        this.foodMajorKinds = value.FoodMajorKind;
      }
    },
  },
  components: {
    search,
    foodMajorKindSelect,
  },
  methods: {
    triggerClick() {
      this.defaultItems = [...this.foodMajorKinds];
      this.trigger = !this.trigger;
    },
    add(items) {
      this.foodMajorKinds = items;
      this.set();
    },
    remove(index) {
      if (!this.foodMajorKinds[index]) return;
      this.foodMajorKinds.splice(index, 1);
      this.set();
    },
    set() {
      this.$emit("set", this.foodMajorKinds);
    },
  },
};
</script>
