import i18n from "@/langs/i18n";

export function getAll() {
  return [
    {
      value: 0,
      name: "Authority",
      text: `${i18n.t("option.moduleType.Authority")}`,
    },
    {
      value: 1,
      name: "DayTime",
      text: `${i18n.t("option.moduleType.DayTime")}`,
    },
    {
      value: 2,
      name: "Invisible",
      text: `${i18n.t("option.moduleType.Invisible")}`,
    },
    {
      value: 3,
      name: "Function",
      text: `${i18n.t("option.moduleType.Function")}`,
    },
    {
      value: 6,
      name: "FoodSequence",
      text: `${i18n.t("option.moduleType.FoodSequence")}`,
    },
    {
      value: 7,
      name: "Promotion",
      text: `${i18n.t("option.moduleType.Promotion")}`,
    },
  ];
}
export function getByName(name) {
  const type = getAll().find(function (item) {
    return item.name.toLowerCase() == name.toLowerCase();
  });
  return type;
}
