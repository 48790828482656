<template>
  <div>
    <header
      class="navbar navbar-expand-md navbar-dark navbar-fixed-top bg-dark h-header"
    >
      <div>
        <router-link class="navbar-brand" to="/">
          <img
            src="@/imgs/Logo.png"
            width="40"
            height="40"
            class="d-inline-block align-top"
            alt
          />
        </router-link>
        <button
          class="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarNav"
        >
          <span class="navbar-toggler-icon"></span>
        </button>
      </div>
      <div class="collapse navbar-collapse" id="navbarNav">
        <ul class="navbar-nav">
          <template v-if="!isAdmin">
            <li class="nav-item dropdown">
              <a
                class="nav-link dropdown-toggle"
                href="#"
                id="basic"
                role="button"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
                @click="mobileNavClick"
                >{{ $t("navBasic.title") }}</a
              >
              <div class="dropdown-menu" aria-labelledby="basic">
                <router-link
                  class="dropdown-item"
                  v-if="isShow(76)"
                  to="/stores"
                  >{{ $t("navBasic.stores") }}</router-link
                >
                <router-link
                  class="dropdown-item"
                  v-if="isShow(6)"
                  to="/terminals"
                  >{{ $t("navBasic.terminals") }}</router-link
                >
                <router-link
                  class="dropdown-item"
                  v-if="isShow(116)"
                  to="/departments"
                  >{{ $t("navBasic.departments") }}</router-link
                >
                <router-link
                  class="dropdown-item"
                  v-if="isShow(2)"
                  to="/employees"
                  >{{ $t("navBasic.employees") }}</router-link
                >
                <router-link
                  class="dropdown-item"
                  v-if="isShow(107)"
                  to="/foodmajorkinds"
                  >{{ $t("navBasic.foodmajorkinds") }}</router-link
                >
                <router-link
                  class="dropdown-item"
                  v-if="isShow(0)"
                  to="/foodkinds"
                  >{{ $t("navBasic.foodkinds") }}</router-link
                >
                <router-link
                  class="dropdown-item"
                  v-if="isShow(1)"
                  to="/foods"
                  >{{ $t("navBasic.foods") }}</router-link
                >
                <router-link
                  class="dropdown-item"
                  v-if="isShow(37)"
                  to="/tastekinds"
                  >{{ $t("navBasic.tastekinds") }}</router-link
                >
                <router-link
                  class="dropdown-item"
                  v-if="isShow(38)"
                  to="/combokinds"
                  >{{ $t("navBasic.combokinds") }}</router-link
                >
                <router-link
                  class="dropdown-item"
                  v-if="isShow(11)"
                  to="/functions"
                  >{{ $t("navBasic.functions") }}</router-link
                >
                <router-link
                  class="dropdown-item"
                  v-if="isShow(87)"
                  to="/pays"
                  >{{ $t("navBasic.pays") }}</router-link
                >
                <router-link
                  class="dropdown-item"
                  v-if="isShow(12)"
                  to="/directorykinds"
                  >{{ $t("navBasic.directorykinds") }}</router-link
                >
                <router-link
                  class="dropdown-item"
                  v-if="isShow(141)"
                  to="/accounts"
                  >{{ $t("navBasic.accounts") }}</router-link
                >
                <router-link
                  class="dropdown-item"
                  v-if="isShow(68)"
                  to="/pricelabels"
                  >{{ $t("navBasic.pricelabels") }}</router-link
                >
                <router-link
                  class="dropdown-item"
                  v-if="isShow(227)"
                  to="/brands"
                  >{{ $t("navBasic.brands") }}</router-link
                >
                <div
                  class="dropdown-divider"
                  v-if="isERP | hasLY | hasDWAPI"
                ></div>
                <router-link
                  class="dropdown-item"
                  v-if="(isERP | hasLY | hasDWAPI) & isShow(5)"
                  to="/warehouses"
                  >{{ $t("navBasic.warehouses") }}</router-link
                >
                <router-link
                  class="dropdown-item"
                  v-if="isERP & isShow(4)"
                  to="/suppliers"
                  >{{ $t("navBasic.suppliers") }}</router-link
                >
              </div>
            </li>
            <li class="nav-item dropdown" v-if="productType != 3">
              <a
                class="nav-link dropdown-toggle"
                href="#"
                id="modules"
                role="button"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
                @click="mobileNavClick"
                >{{ $t("navModules.title") }}</a
              >
              <div class="dropdown-menu" aria-labelledby="modules">
                <router-link
                  class="dropdown-item"
                  v-if="isShow(26)"
                  to="/modules/authority"
                  >{{ $t("navModules.authority") }}</router-link
                >
                <router-link
                  class="dropdown-item"
                  v-if="isShow(83)"
                  to="/modules/function"
                  >{{ $t("navModules.function") }}</router-link
                >
                <router-link
                  class="dropdown-item"
                  v-if="isShow(40)"
                  to="/modules/invisible"
                  >{{ $t("navModules.invisible") }}</router-link
                >
                <router-link
                  class="dropdown-item"
                  v-if="isShow(42)"
                  to="/modules/sequence"
                  >{{ $t("navModules.sequence") }}</router-link
                >
                <router-link
                  class="dropdown-item"
                  v-if="isShow(43)"
                  to="/modules/promotion"
                  >{{ $t("navModules.promotion") }}</router-link
                >
                <router-link
                  class="dropdown-item"
                  v-if="isShow(61)"
                  to="/modules/day"
                  >{{ $t("navModules.day") }}</router-link
                >
                <router-link
                  class="dropdown-item"
                  v-if="isShow(150)"
                  to="/modules/stores"
                  >{{ $t("navModules.stores") }}</router-link
                >
              </div>
            </li>
            <li class="nav-item dropdown">
              <a
                class="nav-link dropdown-toggle"
                href="#"
                id="report"
                role="button"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
                @click="mobileNavClick"
                >{{ $t("navReport.title") }}</a
              >
              <div
                class="repo-dropdown-menu dropdown-menu row"
                aria-labelledby="report"
              >
                <div class="nav-flex">
                  <div class="repo-dropdown">
                    <span class="dropdown-title my-1 font-weight-bold">{{
                      $t("navReport.saletitle")
                    }}</span>
                    <router-link
                      class="dropdown-item"
                      v-if="isShow(90)"
                      to="/reports/sale"
                      >{{ $t("navReport.sale") }}</router-link
                    >
                    <router-link
                      class="dropdown-item"
                      v-if="isShow(114)"
                      to="/reports/classsale"
                      >{{ $t("navReport.classsale") }}</router-link
                    >
                    <router-link
                      class="dropdown-item"
                      v-if="isShow(19)"
                      to="/reports/invoice"
                      >{{ $t("navReport.invoice") }}</router-link
                    >
                    <router-link
                      class="dropdown-item"
                      v-if="isShow(176)"
                      to="/reports/invoiceprize"
                      >{{ $t("navReport.invoiceprize") }}</router-link
                    >
                    <router-link
                      class="dropdown-item"
                      v-if="isShow(18)"
                      to="/reports/employeeanalysis"
                      >{{ $t("navReport.employeeanalysis") }}</router-link
                    >
                    <router-link
                      class="dropdown-item"
                      v-if="isShow(110)"
                      to="/reports/workrecord"
                      >{{ $t("navReport.workrecord") }}</router-link
                    >
                    <router-link
                      class="dropdown-item"
                      v-if="isShow(120)"
                      to="/reports/saler"
                      >{{ $t("navReport.saler") }}</router-link
                    >
                    <router-link
                      class="dropdown-item"
                      v-if="isShow(21)"
                      to="/reports/people"
                      >{{ $t("navReport.people") }}</router-link
                    >
                    <router-link
                      class="dropdown-item"
                      v-if="isShow(153)"
                      to="/reports/coupon"
                      >{{ $t("navReport.coupon") }}</router-link
                    >
                    <router-link
                      class="dropdown-item"
                      v-if="isShow(140)"
                      to="/reports/saleorder"
                      >{{ $t("navReport.saleorder") }}</router-link
                    >
                    <router-link
                      class="dropdown-item"
                      v-if="isShow(22)"
                      to="/reports/collectionpayment"
                      >{{ $t("navReport.collectionpayment") }}</router-link
                    >
                    <router-link
                      class="dropdown-item"
                      v-if="isShow(207)"
                      to="/reports/saleprofit"
                      >{{ $t("navReport.saleprofit") }}</router-link
                    >
                    <router-link
                      class="dropdown-item"
                      v-if="isShow(225)"
                      to="/reports/creditcard"
                      >{{ $t("navReport.creditcard") }}</router-link
                    >
                    <div class="dropdown-hr dropdown-divider"></div>
                    <router-link
                      class="dropdown-item"
                      v-if="isShow(147)"
                      to="/reports/orderfoodstatistic"
                      >{{ $t("navReport.orderfoodstatistic") }}</router-link
                    >
                    <router-link
                      class="dropdown-item"
                      v-if="isShow(108)"
                      to="/reports/foodmajorkindstatistic"
                      >{{ $t("navReport.foodmajorkindstatistic") }}</router-link
                    >
                    <router-link
                      class="dropdown-item"
                      v-if="isShow(15)"
                      to="/reports/foodkindstatistic"
                      >{{ $t("navReport.foodkindstatistic") }}</router-link
                    >
                    <router-link
                      class="dropdown-item"
                      v-if="isShow(20)"
                      to="/reports/foodsalestatistic"
                      >{{ $t("navReport.foodsalestatistic") }}</router-link
                    >
                    <router-link
                      class="dropdown-item"
                      v-if="isShow(149)"
                      to="/reports/fooddaystatistic"
                      >{{ $t("navReport.fooddaystatistic") }}</router-link
                    >
                    <router-link
                      class="dropdown-item"
                      v-if="isShow(131)"
                      to="/reports/foodsale"
                      >{{ $t("navReport.foodsale") }}</router-link
                    >
                    <router-link
                      class="dropdown-item"
                      v-if="isShow(17)"
                      to="/reports/free"
                      >{{ $t("navReport.free") }}</router-link
                    >
                    <router-link
                      class="dropdown-item"
                      v-if="isShow(77)"
                      to="/reports/timestatistic"
                      >{{ $t("navReport.timestatistic") }}</router-link
                    >
                    <router-link
                      class="dropdown-item"
                      v-if="isShow(111)"
                      to="/reports/tastetatistic"
                      >{{ $t("navReport.tastetatistic") }}</router-link
                    >
                    <div
                      class="dropdown-hr dropdown-divider"
                      v-if="productType == 0 || productType == 2"
                    ></div>
                    <router-link
                      class="dropdown-item"
                      v-if="isShow(74)"
                      to="/reports/pay"
                      >{{ $t("navReport.pay") }}</router-link
                    >
                    <router-link
                      class="dropdown-item"
                      v-if="isShow(75) || productType == 2"
                      to="/reports/salepay"
                      >{{ $t("navReport.salepay") }}</router-link
                    >
                  </div>
                  <template v-if="isERP">
                    <div class="mob-dropdown-hr dropdown-divider" />
                    <div class="repo-dropdown com-dropdown-hr">
                      <span class="dropdown-title my-1 font-weight-bold">{{
                        $t("navReport.erptitle")
                      }}</span>
                      <router-link
                        class="dropdown-item"
                        v-if="isShow(63)"
                        to="/reports/supplier"
                        >{{ $t("navReport.supplier") }}</router-link
                      >
                      <router-link
                        class="dropdown-item"
                        v-if="isShow(177)"
                        to="/reports/supplierfoodsale"
                        >{{ $t("navReport.supplierfoodsale") }}</router-link
                      >

                      <router-link
                        class="dropdown-item"
                        v-if="isShow(190)"
                        to="/reports/supplierfoodsaleoriginal"
                        >{{
                          $t("navReport.supplierfoodsaleoriginal")
                        }}</router-link
                      >
                      <router-link
                        class="dropdown-item"
                        v-if="isShow(138)"
                        to="/reports/supplierpurchaseorder"
                        >{{
                          $t("navReport.supplierpurchaseorder")
                        }}</router-link
                      >
                      <router-link
                        class="dropdown-item"
                        v-if="isShow(194)"
                        to="/reports/foodpurchaseorder"
                        >{{ $t("navReport.foodpurchaseorder") }}</router-link
                      >
                      <router-link
                        class="dropdown-item"
                        v-if="isShow(132)"
                        to="/reports/foodpurchase"
                        >{{ $t("navReport.foodpurchase") }}</router-link
                      >
                      <router-link
                        class="dropdown-item"
                        v-if="isShow(158)"
                        to="/reports/erpsummary"
                        >{{ $t("navReport.erpsummary") }}</router-link
                      >
                      <router-link
                        class="dropdown-item"
                        v-if="isShow(137)"
                        to="/reports/purchaseorder"
                        >{{ $t("navReport.purchaseorder") }}</router-link
                      >
                      <router-link
                        class="dropdown-item"
                        v-if="isShow(24)"
                        to="/reports/purchase"
                        >{{ $t("navReport.purchase") }}</router-link
                      >
                      <router-link
                        class="dropdown-item"
                        v-if="isShow(25)"
                        to="/reports/purchasereturn"
                        >{{ $t("navReport.purchasereturn") }}</router-link
                      >
                      <router-link
                        class="dropdown-item"
                        v-if="isShow(71)"
                        to="/reports/stockadjust"
                        >{{ $t("navReport.stockadjust") }}</router-link
                      >
                      <router-link
                        class="dropdown-item"
                        v-if="isShow(187)"
                        to="/reports/stockadjustdirectory"
                        >{{ $t("navReport.stockadjustdirectory") }}</router-link
                      >
                      <router-link
                        class="dropdown-item"
                        v-if="isShow(173)"
                        to="/reports/stocktransfers"
                        >{{ $t("navReport.stocktransfers") }}</router-link
                      >
                      <router-link
                        class="dropdown-item"
                        v-if="isShow(152)"
                        to="/reports/inventory"
                        >{{ $t("navReport.inventory") }}</router-link
                      >
                      <router-link
                        class="dropdown-item"
                        v-if="isShow(69)"
                        to="/reports/stock"
                        >{{ $t("navReport.stock") }}</router-link
                      >
                      <router-link
                        class="dropdown-item"
                        v-if="isShow(70)"
                        to="/reports/safeqty"
                        >{{ $t("navReport.safeqty") }}</router-link
                      >
                      <router-link
                        class="dropdown-item"
                        v-if="isShow(115)"
                        to="/reports/inventorypandl"
                        >{{ $t("navReport.inventorypandl") }}</router-link
                      >
                      <router-link
                        class="dropdown-item"
                        v-if="isShow(23)"
                        to="/reports/foodmovement"
                        >{{ $t("navReport.foodmovement") }}</router-link
                      >
                      <router-link
                        class="dropdown-item"
                        v-if="isShow(205)"
                        to="/reports/stockbatch"
                        >{{ $t("navReport.stockbatch") }}</router-link
                      >
                      <router-link
                        class="dropdown-item"
                        v-if="isShow(206)"
                        to="/reports/stockbatchmovement"
                        >{{ $t("navReport.stockbatchmovement") }}</router-link
                      >
                      <div class="dropdown-hr dropdown-divider"></div>
                      <router-link
                        class="dropdown-item"
                        v-if="isShow(170)"
                        to="/reports/combinationtrade"
                        >{{ $t("navReport.combinationtrade") }}</router-link
                      >
                      <router-link
                        class="dropdown-item"
                        v-if="isShow(171)"
                        to="/reports/seperationtrade"
                        >{{ $t("navReport.seperationtrade") }}</router-link
                      >
                    </div>
                  </template>
                  <template v-if="isCustomer || productType == 3">
                    <div class="mob-dropdown-hr dropdown-divider"></div>
                    <div class="repo-dropdown com-dropdown-hr">
                      <span class="dropdown-title my-1 font-weight-bold">{{
                        $t("navReport.customertitle")
                      }}</span>
                      <router-link
                        class="dropdown-item"
                        v-if="isShow(89)"
                        to="/reports/customer"
                        >{{ $t("navReport.customer") }}</router-link
                      >
                      <router-link
                        class="dropdown-item"
                        v-if="isShow(186)"
                        to="/reports/customerfoodsale"
                        >{{ $t("navReport.customerfoodsale") }}</router-link
                      >
                      <router-link
                        class="dropdown-item"
                        v-if="isShow(166)"
                        to="/reports/customersalestatistic"
                        >{{
                          $t("navReport.customersalestatistic")
                        }}</router-link
                      >
                      <router-link
                        class="dropdown-item"
                        v-if="isCustomer & isShow(113)"
                        to="/reports/deposit"
                        >{{ $t("navReport.deposit") }}</router-link
                      >
                      <router-link
                        class="dropdown-item"
                        v-if="isCustomer & isShow(118)"
                        to="/reports/integral"
                        >{{ $t("navReport.integral") }}</router-link
                      >
                      <router-link
                        class="dropdown-item"
                        v-if="isCustomer & isShow(135)"
                        to="/reports/customerstock"
                        >{{ $t("navReport.customerstock") }}</router-link
                      >
                      <router-link
                        class="dropdown-item"
                        v-if="isCustomer & isShow(134)"
                        to="/reports/customerstockmovement"
                        >{{
                          $t("navReport.customerstockmovement")
                        }}</router-link
                      >
                      <router-link
                        class="dropdown-item"
                        v-if="isShow(210)"
                        to="/reports/customerfree"
                        >{{ $t("navReport.customerfree") }}</router-link
                      >
                      <router-link
                        class="dropdown-item"
                        v-if="isCustomer & hasPGTalk & isShow(195)"
                        to="/reports/pgtalkdeposit"
                        >{{ $t("navReport.pgtalkdeposit") }}</router-link
                      >
                    </div>
                  </template>
                </div>
              </div>
            </li>
            <li class="nav-item dropdown" v-if="isCustomer || productType == 3">
              <a
                class="nav-link dropdown-toggle"
                href="#"
                id="customer"
                role="button"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
                @click="mobileNavClick"
                >{{ $t("navCustomer.title") }}</a
              >
              <div class="dropdown-menu" aria-labelledby="customer">
                <router-link
                  class="dropdown-item"
                  v-if="isShow(3)"
                  to="/customers"
                  >{{ $t("navCustomer.customers") }}</router-link
                >
                <router-link
                  class="dropdown-item"
                  v-if="isShow(46)"
                  to="/membersetting"
                  >{{ $t("navCustomer.membersetting") }}</router-link
                >
                <router-link
                  class="dropdown-item"
                  v-if="isShow(189) && (productType == 0 || productType == 2)"
                  to="/integralrules"
                  >{{ $t("navCustomer.integralrules") }}</router-link
                >
                <router-link
                  class="dropdown-item"
                  v-if="isShow(192) && hasOcard"
                  to="/ocardrules"
                  >{{ $t("navCustomer.ocardrules") }}</router-link
                >
                <router-link
                  class="dropdown-item"
                  v-if="isShow(211) && hasMemberWeb"
                  to="/memberweb"
                  >{{ $t("navCustomer.memberweb") }}</router-link
                >
              </div>
            </li>
            <li
              class="nav-item dropdown"
              v-if="
                isERP |
                  hasLY |
                  hasDWAPI |
                  (productType == 3) |
                  hasCloudElecInvoice
              "
            >
              <a
                class="nav-link dropdown-toggle"
                href="#"
                id="erp"
                role="button"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
                @click="mobileNavClick"
                >{{ erpTagName }}</a
              >
              <div class="dropdown-menu" aria-labelledby="erp">
                <router-link
                  class="dropdown-item"
                  v-if="
                    isShow(27) &
                    (isERP || productType == 3 || hasCloudElecInvoice)
                  "
                  to="/sals"
                  >{{ $t("navERP.sals") }}</router-link
                >
                <router-link
                  class="dropdown-item"
                  v-if="isShow(28) & isERP"
                  to="/salreturns"
                  >{{ $t("navERP.salreturns") }}</router-link
                >
                <div class="dropdown-divider" v-if="isERP"></div>
                <router-link
                  class="dropdown-item"
                  v-if="isShow(125) & isERP"
                  to="/purchaseorders"
                  >{{ $t("navERP.purchaseorders") }}</router-link
                >
                <router-link
                  class="dropdown-item"
                  v-if="isShow(29) & isERP"
                  to="/purchases"
                  >{{ $t("navERP.purchases") }}</router-link
                >
                <router-link
                  class="dropdown-item"
                  v-if="isShow(30) & isERP"
                  to="/purchasereturns"
                  >{{ $t("navERP.purchasereturns") }}</router-link
                >
                <div class="dropdown-divider" v-if="isERP"></div>
                <router-link
                  class="dropdown-item"
                  v-if="isShow(31) & isERP"
                  to="/stockadjusts"
                  >{{ $t("navERP.stockadjusts") }}</router-link
                >
                <router-link
                  class="dropdown-item"
                  v-if="isShow(92) & (isERP | hasLY)"
                  to="/stocktransfers"
                  >{{ $t("navERP.stocktransfers") }}</router-link
                >
                <div class="dropdown-divider" v-if="isERP"></div>
                <router-link
                  class="dropdown-item"
                  v-if="isShow(32) & isERP"
                  to="/inventorys"
                  >{{ $t("navERP.inventorys") }}</router-link
                >
                <router-link
                  class="dropdown-item"
                  v-if="(inventoryMachineType > 0) & isERP"
                  to="/inventorymachine"
                  >{{ $t("navERP.inventorymachine") }}</router-link
                >
                <div class="dropdown-divider" v-if="isERP"></div>
                <router-link
                  class="dropdown-item"
                  v-if="isShow(33) & isERP"
                  to="/safeqty"
                  >{{ $t("navERP.safeqty") }}</router-link
                >
                <router-link
                  class="dropdown-item"
                  v-if="isShow(145) & hasLY"
                  to="/lystock"
                  >{{ $t("navERP.lystock") }}</router-link
                >
                <router-link
                  class="dropdown-item"
                  v-if="isShow(145) & hasDWAPI"
                  to="/dwstock"
                  >{{ $t("navERP.dwstock") }}</router-link
                >
              </div>
            </li>
            <li class="nav-item dropdown" v-if="isERP">
              <a
                class="nav-link dropdown-toggle"
                href="#"
                id="combination"
                role="button"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
                @click="mobileNavClick"
                >{{ $t("navCombination.title") }}</a
              >
              <div class="dropdown-menu" aria-labelledby="combinations">
                <router-link
                  class="dropdown-item"
                  v-if="isShow(167)"
                  to="/combinations"
                  >{{ $t("navCombination.combinations") }}</router-link
                >
                <router-link
                  class="dropdown-item"
                  v-if="isShow(168)"
                  to="/combinationtrades"
                  >{{ $t("navCombination.combinationtrades") }}</router-link
                >
                <router-link
                  class="dropdown-item"
                  v-if="isShow(169)"
                  to="/seperationtrades"
                  >{{ $t("navCombination.seperationtrades") }}</router-link
                >
              </div>
            </li>
            <li
              class="nav-item dropdown"
              v-if="
                productType == 0 ||
                productType == 2 ||
                productType == 3 ||
                isERP
              "
            >
              <a
                class="nav-link dropdown-toggle"
                href="#"
                id="account"
                role="button"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
                @click="mobileNavClick"
                >{{ $t("navAccount.title") }}</a
              >
              <div class="dropdown-menu" aria-labelledby="account">
                <router-link
                  class="dropdown-item"
                  v-if="isShow(67)"
                  to="/receivables"
                  >{{ $t("navAccount.receivables") }}</router-link
                >
                <router-link
                  class="dropdown-item"
                  v-if="isShow(36)"
                  to="/receipts"
                  >{{ $t("navAccount.receipts") }}</router-link
                >
                <div class="dropdown-divider" v-if="isERP"></div>
                <router-link
                  class="dropdown-item"
                  v-if="isShow(65) & isERP"
                  to="/payables"
                  >{{ $t("navAccount.payables") }}</router-link
                >
                <router-link
                  class="dropdown-item"
                  v-if="isShow(35) & isERP"
                  to="/payments"
                  >{{ $t("navAccount.payments") }}</router-link
                >
              </div>
            </li>
            <li
              class="nav-item dropdown"
              v-if="
                (hasOnlineOrder || hasUberEats) &&
                (productType == 0 || productType == 2)
              "
            >
              <a
                class="nav-link dropdown-toggle"
                href="#"
                id="onlineOrder"
                role="button"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
                @click="mobileNavClick"
                >{{ $t("navOnlineOrder.title") }}</a
              >
              <div class="dropdown-menu" aria-labelledby="onlineOrder">
                <router-link
                  class="dropdown-item"
                  to="/onlineorder-store"
                  v-if="hasOnlineOrder"
                  >{{ $t("navOnlineOrder.onlineorderstore") }}</router-link
                >
                <router-link
                  class="dropdown-item"
                  to="/onlineorder-work"
                  v-if="hasOnlineOrder"
                  >{{ $t("navOnlineOrder.onlineorderwork") }}</router-link
                >
                <router-link
                  class="dropdown-item"
                  to="/modules/onlineorder-food"
                  v-if="hasOnlineOrder"
                  >{{ $t("navOnlineOrder.onlineorderfood") }}</router-link
                >
                <router-link
                  class="dropdown-item"
                  to="/ubereats"
                  v-if="hasUberEats"
                  >{{ $t("navOnlineOrder.ubereats") }}</router-link
                >
              </div>
            </li>
            <li class="nav-item dropdown" v-if="productType != 3">
              <a
                class="nav-link dropdown-toggle"
                href="#"
                id="download"
                role="button"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
                @click="mobileNavClick"
                >{{ $t("navDownload.title") }}</a
              >
              <div class="dropdown-menu" aria-labelledby="download">
                <router-link
                  class="dropdown-item"
                  v-if="isShow(81)"
                  to="/downloads"
                  >{{ $t("navDownload.downloads") }}</router-link
                >
              </div>
            </li>
          </template>
          <li class="nav-item dropdown" v-if="isAdmin">
            <a
              class="nav-link dropdown-toggle"
              href="#"
              id="admindata"
              role="button"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
              >基本資料</a
            >
            <div class="dropdown-menu" aria-labelledby="admindata">
              <router-link class="dropdown-item" to="/businesses"
                >營業人</router-link
              >
              <router-link class="dropdown-item" to="/users"
                >使用者</router-link
              >
              <router-link class="dropdown-item" to="/utai-forms"
                >友泰申請單</router-link
              >

              <router-link class="dropdown-item" to="/ushow-forms"
                >百揚需求信</router-link
              >
              <router-link class="dropdown-item" to="/invoiceprizes"
                >中獎名單</router-link
              >
            </div>
          </li>
          <li class="nav-item dropdown" v-if="hasLog">
            <a
              class="nav-link dropdown-toggle"
              href="#"
              id="log"
              role="button"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
              >Log</a
            >
            <div class="dropdown-menu" aria-labelledby="log">
              <router-link class="dropdown-item" to="/errormessage"
                >錯誤訊息</router-link
              >
              <router-link class="dropdown-item" to="/thirdparty"
                >第三方</router-link
              >
              <router-link class="dropdown-item" to="/system">系統</router-link>
              <router-link class="dropdown-item" to="/notification"
                >推播</router-link
              >
              <router-link class="dropdown-item" to="/webhook"
                >回呼</router-link
              >
            </div>
          </li>
          <li class="nav-item dropdown" v-if="isAccountant">
            <a
              class="nav-link dropdown-toggle"
              href="#"
              id="admindata"
              role="button"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
              >帳款</a
            >
            <div class="dropdown-menu" aria-labelledby="admindata">
              <router-link class="dropdown-item" to="/pyreceivables"
                >應收帳款</router-link
              >
              <!-- <router-link class="dropdown-item" to="/users">使用者</router-link> -->
            </div>
          </li>
        </ul>
      </div>
      <!-- <div class="btn-group">
        <a
          class="nav-link dropdown-toggle"
          style="
            color: rgba(255, 255, 255, 0.5);
            cursor: pointer;
            font-weight: bold;
            text-decoration: none;
          "
          href="#"
          role="button"
          id="languageMenu"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
          >{{ $t("langOption.lang_title") }}</a
        >
        <div
          class="dropdown-menu dropdown-menu-right"
          aria-labelledby="languageMenu"
        >
          <a class="dropdown-item" href="#" @click.prevent="setLang('tw')">{{
            $t("langOption.lang_tw")
          }}</a>
          <a class="dropdown-item" href="#" @click.prevent="setLang('en')">{{
            $t("langOption.lang_en")
          }}</a>
        </div>
      </div> -->
      <div class="ml-auto btn-group">
        <a
          class="username dropdown-toggle"
          href="#"
          role="button"
          id="dropdownMenuLink"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
          @click="mobileNavClick"
          >{{ userName }}</a
        >

        <div
          class="dropdown-menu dropdown-menu-right"
          aria-labelledby="dropdownMenuLink"
        >
          <a
            v-if="!isAdmin && isShow(226)"
            class="dropdown-item"
            href="#"
            @click.prevent="businessModalTrigger = !businessModalTrigger"
            >{{ $t("adminMenu.company") }}</a
          >
          <a
            class="dropdown-item"
            href="#"
            @click.prevent="userModalTrigger = !userModalTrigger"
            >{{ $t("adminMenu.user") }}</a
          >
          <a
            class="dropdown-item"
            href="#"
            @click.prevent="passwordModalTrigger = !passwordModalTrigger"
            >{{ $t("adminMenu.password") }}</a
          >
          <a class="dropdown-item" href="#" @click.prevent="onLogout">{{
            $t("adminMenu.logout")
          }}</a>
        </div>
      </div>
    </header>
    <business-part
      :isAdmin="false"
      :trigger="businessModalTrigger"
    ></business-part>

    <user-data
      :hasAdmin="false"
      :trigger="userModalTrigger"
      :id="userId"
      modalId="navUser"
      @onSaved="userSave"
    ></user-data>

    <user-password :trigger="passwordModalTrigger" :id="userId"></user-password>
  </div>
</template>

<script>
import userData from "@/components/datas/UserData";
import userPassword from "@/components/datas/UserPassword";
import businessPart from "@/components/datas/BusinessPart";

import $ from "jquery";

export default {
  data() {
    return {
      isCustomer: window.localStorage["isCustomer"] == "true",
      isERP: window.localStorage["isERP"] == "true",
      authoritys: [],
      userModalTrigger: false,
      passwordModalTrigger: false,
      businessModalTrigger: false,
      userId: window.localStorage["userId"],
      userName: window.localStorage["userName"],
      hasOnlineOrder: window.localStorage["hasOnlineOrder"] == "true",
      hasUberEats: window.localStorage["hasUberEats"] == "true",
      hasOcard: window.localStorage["hasOcard"] == "true",
      hasCloudElecInvoice: window.localStorage["hasCloudElecInvoice"] == "true",
      hasLineOrder: window.localStorage["hasLineOrder"] == "true",
    };
  },
  components: {
    userData,
    userPassword,
    businessPart,
  },
  computed: {
    isAdmin() {
      return !(this.roleType == 0);
    },
    isAccountant() {
      return this.roleType == 3;
    },
    hasLog() {
      return this.roleType == 2 || this.roleType == 6;
    },
    productType() {
      return parseInt(window.localStorage["productType"]);
    },
    roleType() {
      return window.localStorage["roleType"];
    },
    inventoryMachineType() {
      return this.$store.state.inventoryMachineType;
    },
    erpTagName() {
      return this.hasLY
        ? this.$t("navERP.titleLYERP")
        : this.hasDWAPI
        ? this.$t("navERP.titleDWERP")
        : this.isERP
        ? this.$t("navERP.titleERP")
        : this.$t("navERP.titleInvoice");
    },
    hasLY() {
      return window.localStorage["erpType"] == 1;
    },
    hasDWAPI() {
      return window.localStorage["erpType"] == 3;
    },
    hasPGTalk() {
      return window.localStorage["hasPGTalk"] == "true";
    },
    hasMemberWeb() {
      return window.localStorage["hasMemberWeb"] == "true";
    },
    currentPath() {
      return this.$route.path;
    },
  },
  watch: {
    currentPath(path) {
      this.checkPath(path);
    },
  },
  methods: {
    checkPath(path) {
      const isData = path.includes("data");
      if (isData) {
        window.onbeforeunload = () => {
          return true;
        };
      } else {
        window.onbeforeunload = null;
      }
    },
    setLang(value) {
      this.$i18n.locale = value;
      localStorage.setItem("lang", value);
      this.$store.dispatch("setLanguage", value);
    },
    userSave() {
      location.reload();
    },
    isShow(id) {
      const authority = this.authoritys.find(function (data) {
        return data.AuthItemType == id;
      });
      if (authority) {
        if (authority.IsList) return true;
      }
      return false;
    },
    onLogout() {
      this.$api.userAccounts.logout();
      this.$router.push("/Login");
    },
    onUser() {},
    mobileNavClick(event) {
      const nav = document.querySelector(".navbar-fixed-top");
      if (window.innerWidth < 768) {
        if (event.target.id == "dropdownMenuLink") {
          nav.classList.remove("overflow-auto");
        } else {
          nav.classList.add("overflow-auto");
        }
      } else {
        nav.classList.remove("overflow-auto");
      }
    },
  },
  mounted() {
    this.checkPath(this.$route.path);
    $("body").on("hidden.bs.modal", function (e) {
      if ($(".modal:visible").length) {
        $("body").addClass("modal-open");
      }
    });
  },
  created() {
    const vm = this;
    if (window.localStorage.token) {
      this.$api.employees.getAuthoritys().then((data) => {
        vm.authoritys = data.Authoritys;
        window.localStorage["roleType"] = data.RoleType;
        vm.$func.localStorage.setAuth(data.Authoritys);
        vm.$store.dispatch("setStoreId", data.StoreId);
        vm.$store.dispatch("setProductType", data.ProductType);
        vm.$store.dispatch(
          "setInventoryMachineType",
          data.InventoryMachineType
        );
      });
    }
  },
};
</script>
