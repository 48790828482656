import { httpGet, httpPost, httpDelete } from "@/request/http";
import func from "@/funcs";

let path = "/stocks";

export default {
  getPages(dTO) {
    return httpGet(`${path}/pages`, dTO);
  },
  postSafeQty(item) {
    return httpPost(`${path}/safeqty`, item);
  },
  postSafeQtyExcel(formData) {
    return func.postExcel(`${path}/safeqty/excel`, formData);
  },
  getSafeQtyExcel() {
    func.getExcel.get(`${path}/safeqty/excel`, "安全存量");
  },
  getLY(dTO) {
    return httpGet(`${path}/ly`, dTO);
  },
  getDW(dTO) {
    return httpGet(`${path}/dw`, dTO);
  },
  getStocks(warehouseId, foodIds) {
    return httpGet(`${path}/foods`, {
      warehouseId,
      foodIds,
    });
  },
};
