export function getAll() {
  return [
    {
      value: 0,
      name: "Round",
      text: "四捨五入"
    },
    {
      value: 1,
      name: "Up",
      text: "無條件進位"
    },
    {
      value: 2,
      name: "Down",
      text: "無條件捨去"
    }
  ];
}
