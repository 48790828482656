<template>
  <div :id="id" class="modal fade" role="dialog">
    <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">{{ $t("title.columnsetting") }}</h5>
          <button type="button" class="close" @click="closed">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body row">
          <div class="col-md-6">
            <h5>{{ $t("modal.hide") }}</h5>
            <div class="card">
              <div class="card-header">
                <button
                  type="button"
                  class="btn btn-outline-primary btn-icon"
                  @click="onAdd"
                >
                  <i class="fa fa-plus"></i>
                </button>
              </div>

              <div class="card-body">
                <table class="table">
                  <thead>
                    <tr>
                      <th>
                        <div class="custom-control custom-checkbox mr-sm-2">
                          <input
                            v-if="modalId"
                            type="checkbox"
                            class="custom-control-input"
                            :id="`${modalId}${viewName}${tableName}-allAdd`"
                            v-model="allAdd"
                          />
                          <input
                            v-else
                            type="checkbox"
                            class="custom-control-input"
                            :id="`${viewName}${tableName}-allAdd`"
                            v-model="allAdd"
                          />
                          <label class="custom-control-label" for="allAdd">{{
                            $t("columnName.selectall")
                          }}</label>
                        </div>
                      </th>
                      <th>{{ $t("columnName.name") }}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="notSelected in notSelecteds"
                      :key="notSelected.ColumnName"
                      @click="notSelectedClick(notSelected.ColumnName)"
                    >
                      <td>
                        <input
                          class="checkbox"
                          type="checkbox"
                          :value="notSelected.ColumnName"
                          v-model="addIds"
                        />
                      </td>
                      <td>{{ notSelected.text }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <h5>{{ $t("modal.show") }}</h5>
            <div class="card">
              <div class="card-header">
                <button
                  type="button"
                  class="btn btn-outline-danger btn-icon"
                  @click="onRemove"
                >
                  <i class="fas fa-trash-alt"></i>
                </button>
                <button
                  type="button"
                  class="btn btn-outline-primary py-2 px-4"
                  style="border-color: transparent"
                  @click="onBefore"
                >
                  <i class="fa fa-arrow-up"></i>
                </button>
                <button
                  type="button"
                  class="btn btn-outline-primary py-2 px-4"
                  style="border-color: transparent"
                  @click="onAfter"
                >
                  <i class="fa fa-arrow-down"></i>
                </button>
              </div>
              <div class="card-body">
                <table class="table">
                  <thead>
                    <tr>
                      <th>
                        <div class="custom-control custom-checkbox mr-sm-2">
                          <input
                            v-if="modalId"
                            type="checkbox"
                            class="custom-control-input"
                            :id="`${modalId}${viewName}${tableName}-allRemove`"
                            v-model="allRemove"
                          />
                          <input
                            v-else
                            type="checkbox"
                            class="custom-control-input"
                            :id="`${viewName}${tableName}-allRemove`"
                            v-model="allRemove"
                          />
                          <label class="custom-control-label" for="allRemove">{{
                            $t("columnName.selectall")
                          }}</label>
                        </div>
                      </th>
                      <th>{{ $t("columnName.name") }}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="selected in selecteds"
                      :key="selected.ColumnName"
                      @click="selectedClick(selected.ColumnName)"
                    >
                      <td>
                        <input
                          class="checkbox"
                          type="checkbox"
                          :value="selected.ColumnName"
                          v-model="removeIds"
                        />
                      </td>
                      <td>{{ selected.text }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-primary" @click="saved">
            {{ $t("button.ok") }}
          </button>
          <button type="button" class="btn btn-secondary" @click="closed">
            {{ $t("button.cancel") }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import $ from "jquery";
import tablePart from "@/components/parts/tablePart";

export default {
  data() {
    return {
      notSelecteds: [],
      selecteds: [],
      addIds: [],
      removeIds: [],
      allAdd: false,
      allRemove: false,
    };
  },
  props: {
    trigger: Boolean,
    viewName: String,
    tableName: String,
    columns: Array,
    modalId: String,
  },
  components: {
    tablePart,
  },
  computed: {
    id() {
      return `${this.viewName}-${this.tableName}`;
    },
  },
  watch: {
    trigger() {
      this.addIds = [];
      this.removeIds = [];
      this.allAdd = false;
      this.allRemove = false;
      $(`#${this.id}`).modal("toggle");
    },
    columns: {
      handler: function () {
        this.updateSelect();
      },
      deep: true,
    },
  },
  methods: {
    updateSelect() {
      let copy = this.$func.copy(this.columns);
      this.selecteds = [];
      this.notSelecteds = [];
      copy.forEach((column) => {
        if (column.IsVisible) {
          this.selecteds.push(column);
        } else {
          this.notSelecteds.push(column);
        }
      });
      this.addIds = [];
      this.removeIds = [];
    },
    notSelectedClick(id) {
      const index = this.addIds.indexOf(id);
      if (index >= 0) {
        this.addIds.splice(index, 1);
      } else {
        this.addIds.push(id);
      }
    },
    selectedClick(id) {
      const index = this.removeIds.indexOf(id);
      if (index >= 0) {
        this.removeIds.splice(index, 1);
      } else {
        this.removeIds.push(id);
      }
    },
    onAdd() {
      if (this.addIds.length == 0) {
        this.$func.message.alert("請選擇欄位 !");
        return;
      }

      let indexes = [];
      this.notSelecteds.forEach((det, index) => {
        if (this.addIds.findIndex((id) => id == det.ColumnName) >= 0) {
          indexes.push(index);
          det.IsVisible = true;
          this.selecteds.push(det);
        }
      });
      for (let i = indexes.length - 1; i >= 0; i--) {
        this.notSelecteds.splice(indexes[i], 1);
      }
      this.addIds = [];
    },
    onRemove() {
      if (this.removeIds.length == 0) {
        this.$func.message.alert("請選擇欄位 !");
        return;
      }

      let indexes = [];
      this.selecteds.forEach((det, index) => {
        if (this.removeIds.findIndex((id) => id == det.ColumnName) >= 0) {
          indexes.push(index);
          det.IsVisible = false;
          this.notSelecteds.push(det);
        }
      });
      for (let i = indexes.length - 1; i >= 0; i--) {
        this.selecteds.splice(indexes[i], 1);
      }
      this.removeIds = [];
    },
    onBefore() {
      if (this.removeIds.length == 0) {
        this.$func.message.alert("請選擇欄位 !");
        return;
      }
      let indexes = [];
      this.removeIds.forEach((id) => {
        let index = this.selecteds.findIndex((det) => det.ColumnName == id);
        if (index >= 0) indexes.push(index);
      });
      indexes = indexes.sort();
      this.selecteds.splice(0, 0, { ColumnName: "empty" });

      indexes.forEach((index) => {
        const det = this.selecteds[index + 1];
        this.selecteds.splice(index + 1, 1);
        this.selecteds.splice(index, 0, det);
      });

      const emptyIndex = this.selecteds.findIndex(
        (d) => d.ColumnName == "empty"
      );
      this.selecteds.splice(emptyIndex, 1);
    },
    onAfter() {
      if (this.removeIds.length == 0) {
        this.$func.message.alert("請選擇欄位 !");
        return;
      }
      let indexes = [];
      this.removeIds.forEach((id) => {
        let index = this.selecteds.findIndex((det) => det.ColumnName == id);
        if (index >= 0) indexes.push(index);
      });
      indexes = indexes.sort().reverse();
      this.selecteds.push({
        ColumnName: "empty",
      });

      indexes.forEach((index) => {
        const det = this.selecteds[index];
        this.selecteds.splice(index, 1);
        this.selecteds.splice(index + 1, 0, det);
      });

      const emptyIndex = this.selecteds.findIndex(
        (d) => d.ColumnName == "empty"
      );
      this.selecteds.splice(emptyIndex, 1);
    },
    saved() {
      if (this.selecteds.length == 0) {
        this.$func.message.alert("最少需設定一個欄位 !");
        return;
      }
      $(`#${this.id}`).modal("toggle");
      let position = 1;
      this.selecteds.forEach((det) => {
        det.Position = position;
        position = position + 1;
      });
      this.$emit("saved", this.selecteds.concat(this.notSelecteds));
    },
    closed() {
      this.updateSelect();
      $(`#${this.id}`).modal("toggle");
    },
  },
};
</script>
