<template>
  <div>
    <div class="card">
      <div class="card-header">
        <button
          type="button"
          class="btn btn-outline-primary btn-icon"
          @click="onSaved"
        >
          <i class="fas fa-check"></i>
        </button>
      </div>
      <div class="card-body overflow-hidden">
        <div class="row">
          <div class="col-12">
            <div class="d-flex p-0 col-12 col-xl-6 col-lg-10 col-md-9">
              <label
                for="defaultDegree"
                class="col-4 col-lg-2 col-md-3 col-form-label"
                >{{ $t("modal.customerdegree") }}</label
              >
              <div class="col-6 col-lg-8 col-md-7">
                <select
                  class="form-control"
                  id="defaultDegree"
                  v-model="memberSetting.DefaultDegree"
                >
                  <option
                    v-for="degree in memberDegree"
                    :key="degree.value"
                    :value="degree.value"
                  >
                    {{ degree.text }}
                  </option>
                </select>
              </div>
            </div>
          </div>
          <div class="col-12 mt-3">
            <div class="d-flex p-0 col-12 col-xl-6 col-lg-10 col-md-9">
              <label
                for="customerNumber"
                class="col-4 col-lg-2 col-md-3 col-form-label"
                >{{ $t("modal.customeridfilltype") }}</label
              >
              <div class="col-6 col-lg-8 col-md-7">
                <select
                  class="form-control"
                  id="customerNumber"
                  v-model="memberSetting.CustomerIdFillType"
                >
                  <option
                    v-for="customerIdFillType in customerIdFillTypes"
                    :key="customerIdFillType.value"
                    :value="customerIdFillType.value"
                  >
                    {{ customerIdFillType.text }}
                  </option>
                </select>
              </div>
            </div>
          </div>
          <div class="col-12 col-xl-2 col-md-3 col-sm-6">
            <div class="px-3 pt-3">
              <div class="custom-control custom-checkbox">
                <input
                  type="checkbox"
                  class="custom-control-input"
                  id="isAddStore"
                  v-model="memberSetting.IsAddStore"
                />
                <label class="custom-control-label" for="isAddStore">{{
                  $t("modal.memberspecificstore")
                }}</label>
              </div>
            </div>
          </div>
          <div class="col-12 col-xl-2 col-md-3 col-sm-6">
            <div class="px-3 pt-3">
              <div class="custom-control custom-checkbox">
                <input
                  type="checkbox"
                  class="custom-control-input"
                  id="hasAutoUpgradeDegree"
                  v-model="memberSetting.HasAutoUpgradeDegree"
                />
                <label
                  class="custom-control-label"
                  for="hasAutoUpgradeDegree"
                  >{{ $t("modal.memberupgrade") }}</label
                >
              </div>
            </div>
          </div>
          <div class="col-12 p-0">
            <hr />
          </div>

          <div class="col-12">
            <div class="col-12 pt-3 pb-4 font-weight-bold membersetting-title">
              {{ $t("modal.point") }}
            </div>
            <div class="col-12 col-xl-6 col-lg-10 col-md-9">
              <div class="form-group row">
                <label for="integralFreePoint" class="col-2 col-form-label">{{
                  $t("modal.exchange")
                }}</label>
                <div class="col">
                  <input
                    type="number"
                    class="form-control"
                    id="integralFreePoint"
                    v-model="memberSetting.IntegralFreePoint"
                  />
                </div>
                <label for="integralFreePoint" class="col-2 col-form-label">{{
                  $t("modal.candiscount")
                }}</label>
                <div class="col">
                  <input
                    type="number"
                    class="form-control"
                    id="integralFreePrice"
                    v-model="memberSetting.IntegralFreePrice"
                  />
                </div>
                <label for="integralFreePrice" class="col-2 col-form-label">{{
                  $t("modal.ntd")
                }}</label>
              </div>
              <div class="form-group row">
                <label for="integralFreeFoodId" class="col-2 col-form-label">{{
                  $t("modal.customizefood")
                }}</label>
                <div class="col">
                  <input
                    type="text"
                    class="form-control"
                    id="integralFreeFoodId"
                    :placeholder="`${$t('placeholder.dbselectfood')}`"
                    :value="memberSetting.IntegralFreeFoodName"
                    readonly
                    @dblclick="selectFoodClick"
                  />
                </div>
                <div class="col-2">
                  <button
                    type="button"
                    class="btn btn-outline-danger"
                    @click="clearFood"
                  >
                    {{ $t("button.clear") }}
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div class="col-12">
            <div class="col-12 pt-3 pb-4 font-weight-bold membersetting-title">
              {{ $t("modal.nogiveintegral") }}
            </div>
          </div>
          <div class="col-6 col-md-3 col-lg-2 col-xl-1 col-sm-4">
            <div class="px-3 pt-3">
              <div class="custom-control custom-checkbox">
                <input
                  type="checkbox"
                  class="custom-control-input"
                  id="noGiveIntegralForCash"
                  v-model="memberSetting.NoGiveIntegralForCash"
                />
                <label
                  class="custom-control-label"
                  for="noGiveIntegralForCash"
                  >{{ $t("modal.nogiveintegralforcash") }}</label
                >
              </div>
            </div>
          </div>
          <div class="col-6 col-md-3 col-lg-2 col-xl-1 col-sm-4">
            <div class="px-3 pt-3">
              <div class="custom-control custom-checkbox">
                <input
                  type="checkbox"
                  class="custom-control-input"
                  id="noGiveIntegralForCreditCard"
                  v-model="memberSetting.NoGiveIntegralForCreditCard"
                />
                <label
                  class="custom-control-label"
                  for="noGiveIntegralForCreditCard"
                  >{{ $t("modal.nogiveintegralforcreditcard") }}</label
                >
              </div>
            </div>
          </div>
          <div class="col-6 col-md-3 col-lg-2 col-xl-1 col-sm-4">
            <div class="px-3 pt-3">
              <div class="custom-control custom-checkbox">
                <input
                  type="checkbox"
                  class="custom-control-input"
                  id="noGiveIntegralForPayType1"
                  v-model="memberSetting.NoGiveIntegralForPayType1"
                />
                <label
                  class="custom-control-label"
                  for="noGiveIntegralForPayType1"
                  >{{ $t("modal.nogiveintegralforpaytype1") }}</label
                >
              </div>
            </div>
          </div>
          <div class="col-6 col-md-3 col-lg-2 col-xl-1 col-sm-4">
            <div class="px-3 pt-3">
              <div class="custom-control custom-checkbox">
                <input
                  type="checkbox"
                  class="custom-control-input"
                  id="noGiveIntegralForPayType2"
                  v-model="memberSetting.NoGiveIntegralForPayType2"
                />
                <label
                  class="custom-control-label"
                  for="noGiveIntegralForPayType2"
                  >{{ $t("modal.nogiveintegralforpaytype2") }}</label
                >
              </div>
            </div>
          </div>
          <div class="col-6 col-md-3 col-lg-2 col-xl-1 col-sm-4">
            <div class="px-3 pt-3">
              <div class="custom-control custom-checkbox">
                <input
                  type="checkbox"
                  class="custom-control-input"
                  id="noGiveIntegralForReceivable"
                  v-model="memberSetting.NoGiveIntegralForReceivable"
                />
                <label
                  class="custom-control-label"
                  for="noGiveIntegralForReceivable"
                  >{{ $t("modal.nogiveintegralforreceivable") }}</label
                >
              </div>
            </div>
          </div>
          <div class="col-6 col-md-3 col-lg-2 col-xl-1 col-sm-4">
            <div class="px-3 pt-3">
              <div class="custom-control custom-checkbox">
                <input
                  type="checkbox"
                  class="custom-control-input"
                  id="noGiveIntegralForDiscount"
                  v-model="memberSetting.NoGiveIntegralForDiscount"
                />
                <label
                  class="custom-control-label"
                  for="noGiveIntegralForDiscount"
                  >{{ $t("modal.nogiveintegralfordiscount") }}</label
                >
              </div>
            </div>
          </div>

          <div class="col-12 p-0">
            <hr />
          </div>

          <div class="col-12">
            <div class="col-12 pt-3 pb-4 font-weight-bold membersetting-title">
              {{ $t("modal.customergrading") }}
            </div>
            <div class="col-12 col-xl-6 col-lg-10 col-md-9">
              <div class="form-group row">
                <label
                  for="customerSaleType"
                  class="col-3 col-xl-2 col-form-label"
                  >{{ $t("modal.discountkind") }}</label
                >
                <div class="col">
                  <select
                    class="form-control"
                    id="customerSaleType"
                    v-model="memberSetting.CustomerSaleType"
                  >
                    <option
                      v-for="type in customerSaleTypes"
                      :key="type.value"
                      :value="type.value"
                    >
                      {{ type.text }}
                    </option>
                  </select>
                </div>
                <div class="col-3 col-xl-2">
                  <button
                    type="button"
                    class="btn btn-primary"
                    @click="customerSaleTypeClicked"
                  >
                    {{ $t("button.setting") }}
                  </button>
                </div>
              </div>
              <div class="row mb-1 pb-4">
                <div class="col-12 col-sm-5 col-xl-4">
                  <div class="custom-control custom-checkbox">
                    <input
                      type="checkbox"
                      class="custom-control-input"
                      id="noPromotionCombo"
                      v-model="memberSetting.NoPromotionCombo"
                    />
                    <label
                      class="custom-control-label"
                      for="noPromotionCombo"
                      >{{ $t("modal.nopromotioncombo") }}</label
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <food-selects :trigger="foodModalTrigger" @onSaved="setFood" />
      <customer-levels
        :trigger="trigger"
        :customerSaleType="memberSetting.CustomerSaleType"
        @onSaved="levelSaved"
      />
    </div>

    <div v-if="hasAutoUpgradeDegree">
      <div class="col-12 px-0 pt-3 font-weight-bold membersetting-title">
        {{ $t("modal.memberupgradesetting") }}
      </div>
      <pageTable
        :parentPage="pagination"
        @getItems="getRules"
        @addItem="addRule"
        @editItem="editRule"
        @removeItem="removeRule"
        :items="memberUpgradeRules"
        :columns="columns"
        :idProperty="idProperty"
        :nameProperty="nameProperty"
        :getTrigger="getTrigger"
        :hasAdd="true"
        :hasEdit="true"
        :hasRemove="true"
        :isSortable="true"
      >
      </pageTable>
    </div>

    <item-modal
      modalId="memberUpgradeSetting"
      :isShowModal="isShow"
      :title="modalTitle"
      @onSaved="save"
      @onClosed="close"
    >
      <div slot="item">
        <div class="row">
          <div class="col-md-6 form-group">
            <label class="col-form-label" for="memberUpgradeRuleId">{{
              $t("modal.degree")
            }}</label>
            <select
              class="form-control"
              id="memberUpgradeRuleId"
              v-model="memberUpgradeRule.MemberUpgradeRuleId"
              :disabled="!isAdd"
            >
              <option
                v-for="levelType in memberUpgradeLevelTypes"
                :key="levelType.value"
                :value="levelType.value"
              >
                {{ levelType.text }}
              </option>
            </select>
          </div>
          <div class="col-md-6 form-group">
            <label class="col-form-label" for="memberUpgradeRuleValidMonths">{{
              $t("modal.validmonths")
            }}</label>
            <input
              type="number"
              class="form-control"
              id="memberUpgradeRuleValidMonths"
              v-model="memberUpgradeRule.ValidMonths"
              @change="checkValidMonthsValue"
            />
          </div>
          <div class="col-md-6 form-group">
            <label class="col-form-label" for="memberUpgradeRuleMonths">{{
              $t("modal.accumulatedmonths")
            }}</label>
            <input
              type="number"
              class="form-control"
              id="memberUpgradeRuleMonths"
              v-model="memberUpgradeRule.Months"
              @change="checkMonthsValue"
            />
          </div>
          <div class="col-md-6 form-group">
            <label class="col-form-label" for="memberUpgradeRuleTotal">{{
              $t("modal.accumulatedamounts")
            }}</label>
            <input
              type="number"
              class="form-control"
              id="memberUpgradeRuleTotal"
              v-model="memberUpgradeRule.Total"
              @change="checkTotalValue"
            />
          </div>
          <div class="col-md-6 form-group">
            <label class="col-form-label" for="memberUpgradeRuleKeepMonths">{{
              $t("modal.keepmonths")
            }}</label>
            <input
              type="number"
              class="form-control"
              id="memberUpgradeRuleKeepMonths"
              v-model="memberUpgradeRule.KeepMonths"
              @change="checkKeepMonthsValue"
            />
          </div>
          <div class="col-md-6 form-group">
            <label class="col-form-label" for="memberUpgradeRuleKeepTotal">{{
              $t("modal.keepamounts")
            }}</label>
            <input
              type="number"
              class="form-control"
              id="memberUpgradeRuleKeepTotal"
              v-model="memberUpgradeRule.KeepTotal"
              @change="checkKeepTotalValue"
            />
          </div>
        </div>
      </div>
    </item-modal>
  </div>
</template>

<script>
import customerLevels from "./CustomerLevels";
import foodSelects from "@/components/selects/FoodSelects";
import pageTable from "@/components/PageTable";
import itemModal from "@/components/ItemModal";

export default {
  data() {
    return {
      memberSetting: {},
      memberDegree: [],
      customerSaleTypes: [],
      trigger: false,
      foodModalTrigger: false,
      memberUpgradeRules: [],
      memberUpgradeRule: {},
      pagination: {},
      columns: [
        {
          name: "MemberUpgradeRuleText",
          headerText: "等級",
        },
        {
          name: "ValidMonths",
        },
        {
          name: "Months",
          headerText: "累積月份",
        },
        {
          name: "Total",
          headerText: "累積金額",
        },
        {
          name: "KeepMonths",
        },
        {
          name: "KeepTotal",
        },
      ],
      memberUpgradeLevelTypes: this.$enum.customerLevelType.getAll(),
      customerIdFillTypes: this.$enum.customerIdFillType.getAll(),
      idProperty: "MemberUpgradeRuleId",
      nameProperty: "MemberUpgradeRuleText",
      getTrigger: false,
      isShow: false,
      isAdd: true,
      modalTitle: "",
      hasAutoUpgradeDegree: false,
    };
  },
  components: {
    foodSelects,
    customerLevels,
    pageTable,
    itemModal,
  },
  computed: {
    currentLang() {
      return this.$store.state.currentLang;
    },
  },
  watch: {
    currentLang() {
      this.setOptions();
    },
  },
  methods: {
    setOptions() {
      this.memberDegree = this.$enum.degreeType.getAll();
      this.customerSaleTypes = this.$enum.customerSaleType.getAll();
    },
    customerSaleTypeClicked() {
      if (
        this.memberSetting.CustomerSaleType &&
        this.memberSetting.CustomerSaleType > 0
      ) {
        this.trigger = !this.trigger;
      } else {
        this.$func.message.alert("請選擇優惠種類 !");
      }
    },
    getMemberSetting() {
      const vm = this;
      this.$api.memberSettings.get().then((data) => {
        vm.memberSetting = data;
        vm.hasAutoUpgradeDegree = vm.memberSetting.HasAutoUpgradeDegree;
      });
    },
    onSaved() {
      const vm = this;
      this.$api.memberSettings.save(vm.memberSetting).then(() => {
        vm.$func.message.success("儲存成功");
        vm.getMemberSetting();
      });
    },
    levelSaved() {
      this.trigger = !this.trigger;
    },
    selectFoodClick() {
      this.foodModalTrigger = !this.foodModalTrigger;
    },
    setFood(item) {
      this.memberSetting.IntegralFreeFoodName = item.FoodName;
      this.memberSetting.IntegralFreeFoodId = item.FoodId;
    },
    clearFood() {
      this.memberSetting.IntegralFreeFoodName = null;
      this.memberSetting.IntegralFreeFoodId = null;
    },
    getRules(dTO) {
      this.$api.memberUpgradeRules.getPages(dTO).then((data) => {
        this.memberUpgradeRules = data.Datas;
        this.pagination = data.Pagination;
      });
    },
    addRule() {
      this.memberUpgradeRule = {
        MemberUpgradeRuleId: 1,
      };
      this.isAdd = true;
      this.modalTitle = this.$t("modal.addmemberupgraderule");
      this.isShow = !this.isShow;
    },
    editRule(rule) {
      this.$api.memberUpgradeRules
        .get(rule.MemberUpgradeRuleId)
        .then((data) => {
          this.memberUpgradeRule = data;
          this.isAdd = false;
          this.modalTitle = this.$t("modal.editmemberupgraderule");
          this.isShow = !this.isShow;
        });
    },
    removeRule(rule) {
      this.$api.memberUpgradeRules.remove(rule.MemberUpgradeRuleId).then(() => {
        this.getTrigger = !this.getTrigger;
      });
    },
    save() {
      this.$api.memberUpgradeRules
        .save(this.memberUpgradeRule)
        .then(() => {
          this.isShow = !this.isShow;
          this.getTrigger = !this.getTrigger;
        })
        .catch((error) => this.$func.message.error(error.data.Message));
    },
    close() {
      this.isShow = !this.isShow;
    },
    checkValidMonthsValue() {
      if (this.memberUpgradeRule.ValidMonths < 0) {
        this.memberUpgradeRule.ValidMonths = 0;
      }
    },
    checkMonthsValue() {
      if (this.memberUpgradeRule.Months < 0) {
        this.memberUpgradeRule.Months = 0;
      }
    },
    checkTotalValue() {
      if (this.memberUpgradeRule.Total < 0) {
        this.memberUpgradeRule.Total = 0;
      }
    },
    checkKeepMonthsValue() {
      if (this.memberUpgradeRule.KeepMonths < 0) {
        this.memberUpgradeRule.KeepMonths = 0;
      }
    },
    checkKeepTotalValue() {
      if (this.memberUpgradeRule.KeepTotal < 0) {
        this.memberUpgradeRule.KeepTotal = 0;
      }
    },
  },
  mounted() {
    this.setOptions();
  },
  created() {
    this.getMemberSetting();
    this.memberUpgradeLevelTypes.splice(0, 1);
  },
};
</script>
