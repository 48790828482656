<template>
  <basic-selects
    :trigger="trigger"
    :isMultiple="isMultiple"
    :columns="columns"
    :items="menus"
    :title="`${$t('title.selectmenu')}`"
    idProperty="OnlineOrderMenuId"
    nameProperty="OnlineOrderMenuName"
    allSelectId="menuAllSelectBox"
    @onSaved="onSaved"
    :id="id"
    :modalId="modalId"
    :pagination="pagination"
    @getItems="getItems"
    sortBy="OnlineOrderMenuName"
    :defaultItems="defaultItems"
    viewName="MenuSelects"
  ></basic-selects>
</template>

<script>
import basicSelects from "./BasicSelects";

export default {
  data() {
    return {
      menus: [],
      columns: [
        {
          name: "OnlineOrderMenuName",
        },
        {
          name: "DayModuleName",
        },
        {
          name: "OnlineOrderCategoryText",
          headerText: "類別",
        },
      ],
      id: "menuSelectModal",
      pagination: {},
      searchTypes: [],
    };
  },
  props: {
    trigger: false,
    isMultiple: false,
    modalId: undefined,
    parentItems: undefined,
    defaultItems: undefined,
    noSearchType: false,
  },
  watch: {
    modalId: {
      handler(value) {
        if (value) this.id = value;
      },
      immediate: true,
    },
    trigger() {
      if (this.parentItems && this.parentItems.length > 0)
        this.menus = this.parentItems;
      else this.getItems();
    },
  },
  components: {
    basicSelects,
  },
  methods: {
    onSaved(item) {
      this.$emit("onSaved", item);
    },
    getItems() {
      const vm = this;
      this.$api.uberEats.getMenus(1).then((data) => {
        vm.menus = data;
      });
    },
  },
};
</script>
