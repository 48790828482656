<template>
  <div>
    <condition-part
      :hasDeliveryDate="true"
      :hasTradeStatusType="true"
      :hasFoodStatusType="true"
      :hasStore="true"
      :hasEmployee="true"
      :hasFoodKind="true"
      :hasFood="true"
      :hasTerminal="true"
      :hasExcel="true"
      :hasPdf="true"
      :hasAdd="false"
      :hasEdit="false"
      :hasRemove="false"
      :hasRankType="true"
      :columns="columns"
      :items="items"
      :pagination="pagination"
      :isSortable="true"
      @getItems="getItems"
      @downloadExcel="downloadExcel"
      @downloadPdf="downloadPdf"
      @printPdf="printPdf"
    >
      <div class="row" v-if="chartData.rows.length > 0" slot="middleArea">
        <div class="col-md-6">
          <div class="card card-table card-body">
            <ve-histogram :data="chartData" :settings="chartSettings" />
          </div>
        </div>
        <div class="col-md-6">
          <div class="card card-table card-body">
            <ve-pie :data="chartData"></ve-pie>
          </div>
        </div>
      </div>
    </condition-part>
  </div>
</template>

<script>
import conditionPart from "@/components/parts/conditionPart";

export default {
  data() {
    return {
      items: [],
      pagination: {},
      columns: [
        { name: "FoodKindNumber" },
        { name: "FoodKindName" },
        { name: "FoodNumber" },
        { name: "FoodName" },
        { name: "Qty" },
        { name: "PickedQty" },
        { name: "NotPickedQty" },
        { name: "Unit" },
        { name: "Total" },
        { name: "GrossProfit" },
        { name: "GrossMargin" },
        { name: "SaleProportion" },
        { name: "TotalCost" },
        { name: "NTaxPrice" },
        { name: "ZTaxPrice" },
      ],
      chartSettings: {
        labelMap: {
          Qty: "數量",
          Total: "金額",
        },
        axisSite: { right: ["Total"] },
        yAxisType: ["normal", "KMB"],
        yAxisName: ["數量", "金額"],
      },
      chartData: {
        columns: ["FoodName", "Qty", "Total"],
        rows: [],
      },
    };
  },
  components: {
    conditionPart,
  },
  methods: {
    downloadExcel(condition) {
      this.$api.reportExcels.getOrderFoodStatistic(condition);
    },
    downloadPdf(condition) {
      this.$api.reportPdfs.getOrderFoodStatistic(condition);
    },
    printPdf(condition) {
      this.$api.reportPdfs.printOrderFoodStatistic(condition);
    },
    getItems(condition) {
      const vm = this;
      this.$api.reports.getOrderFoodStatistic(condition).then((data) => {
        vm.items = data.Datas;
        vm.pagination = data.Pagination;
        vm.chartData.rows = data.Datas;
      });
    },
  },
};
</script>
