<template>
  <item-modal
    @onClosed="modalTrigger = !modalTrigger"
    @onSaved="onSaved"
    :title="modalTitle"
    :trigger="modalTrigger"
    modalId="promotionDet"
  >
    <div slot="item">
      <div class="row">
        <div class="col-md-6">
          <div class="form-group">
            <label for="warehouseNumber">{{ $t("modal.number") }}</label>
            <input
              type="text"
              class="form-control"
              id="warehouseNumber"
              v-model="warehouse.WarehouseNumber"
              required
            />
          </div>
        </div>
        <div class="col-md-6">
          <div class="form-group">
            <label for="warehouseName">{{ $t("modal.name") }}</label>
            <input
              type="text"
              class="form-control"
              id="warehouseName"
              v-model="warehouse.WarehouseName"
              required
            />
          </div>
        </div>
        <div class="col-12">
          <div class="form-group">
            <label for="memo">{{ $t("modal.memo") }}</label>
            <input
              type="text"
              class="form-control"
              id="memo"
              v-model="warehouse.Memo"
            />
          </div>
        </div>
      </div>
    </div>
  </item-modal>
</template>

<script>
import itemModal from "@/components/ItemModal";

export default {
  data() {
    return {
      warehouse: {},
      modalTrigger: false,
    };
  },
  props: ["trigger", "id", "modalTitle"],
  watch: {
    trigger() {
      if (!this.id || this.id.length == 0) {
        this.warehouse = {};
      } else {
        const vm = this;
        this.$api.warehouses.get(this.id).then((data) => {
          vm.warehouse = data;
        });
      }
      this.modalTrigger = !this.modalTrigger;
    },
  },
  components: {
    itemModal,
  },
  methods: {
    onSaved() {
      const vm = this;
      this.$api.warehouses
        .save(vm.warehouse)
        .then(() => {
          vm.modalTrigger = !vm.modalTrigger;
          vm.$emit("onSaved");
        })
        .catch((error) => vm.$func.message.error(error.data.Message));
    },
  },
};
</script>
