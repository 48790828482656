import i18n from "@/langs/i18n";

export function getAll() {
  var items = [
    {
      value: 0,
      name: "All",
      text: `${i18n.t("option.qtyType.All")}`,
    },
    {
      value: 1,
      name: "GreaterThanZero",
      text: `${i18n.t("option.qtyType.GreaterThanZero")}`,
    },
    {
      value: 2,
      name: "LessThanZero",
      text: `${i18n.t("option.qtyType.LessThanZero")}`,
    },
    {
      value: 3,
      name: "Zero",
      text: `${i18n.t("option.qtyType.Zero")}`,
    },
    {
      value: 4,
      name: "GreaterAndEqualZero",
      text: `${i18n.t("option.qtyType.GreaterAndEqualZero")}`,
    },
  ];
  return items;
}

export function getByName(name) {
  const type = getAll().find(function (item) {
    return item.name.toLowerCase() == name.toLowerCase();
  });
  return type;
}

export function getByValue(value) {
  const type = getAll().find(function (item) {
    return item.value == value;
  });
  return type;
}
