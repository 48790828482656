<template>
  <div id="inventorySelectModal" class="modal fade" role="dialog">
    <div class="modal-dialog modal-dialog-centered modal-xl" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">{{ $t("title.selectinventory") }}</h5>
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body row">
          <div class="col-12" style="border-right: 1px solid #dee2e6">
            <data-body-part
              :columns="columns"
              :items="items"
              :noInitialLoading="true"
              @click="multipleSelectClick"
              :viewName="'InventorySelects'"
              initialWidth="100"
              :hasPage="false"
            >
              <th slot="th-before" style="width: 100px">
                <div class="custom-control custom-checkbox mr-sm-2">
                  <input
                    type="checkbox"
                    class="custom-control-input"
                    id="InventoryAllSelectBox"
                    v-model="isAllSelect"
                    @change="clickAllSelect"
                  />
                  <label
                    class="custom-control-label"
                    for="InventoryAllSelectBox"
                    >{{ $t("columnName.select") }}</label
                  >
                </div>
              </th>
              <td slot="td-before" slot-scope="props">
                <input
                  type="checkbox"
                  class="checkbox"
                  :value="props.item[idProperty]"
                  v-model="selectIds"
                />
              </td>
            </data-body-part>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-primary" @click="onSaved">
            {{ $t("button.ok") }}
          </button>
          <button type="button" class="btn btn-secondary" @click="onClosed">
            {{ $t("button.cancel") }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import dataBodyPart from "@/components/parts/dataBodyPart";
import $ from "jquery";

export default {
  data() {
    return {
      columns: [
        {
          name: "InventoryDate",
        },
        {
          name: "InventoryNumber",
        },
        {
          name: "EmployeeNumber",
        },
        {
          name: "EmployeeName",
        },
        {
          name: "WarehouseNumber",
        },
        {
          name: "WarehouseName",
        },
        {
          name: "Memo",
        },
      ],
      selects: [],
      isAllSelect: false,
      idProperty: "InventoryId",
    };
  },
  props: {
    items: undefined,
    trigger: false,
    isMultiple: false,
    modalId: undefined,
  },
  computed: {
    selectIds() {
      return this.selects.map((x) => x[this.idProperty]);
    },
  },
  watch: {
    modalId: {
      handler(value) {
        if (value) this.id = value;
      },
      immediate: true,
    },
    trigger() {
      this.isAllSelect = false;
      this.selects = [];
      $("#inventorySelectModal").modal("toggle");
    },
    selects() {
      this.checkIsAllSelect();
    },
  },
  components: {
    dataBodyPart,
  },
  methods: {
    checkIsAllSelect() {
      const vm = this;
      const currentPageSelects = this.selects.filter((select) => {
        const findResult = vm.items.some((item) => {
          return select[vm.idProperty] === item[vm.idProperty];
        });
        return findResult;
      });
      if (currentPageSelects.length === this.items.length) {
        this.isAllSelect = true;
      } else {
        this.isAllSelect = false;
      }
    },
    clickAllSelect() {
      const vm = this;
      if (this.isAllSelect) {
        this.items.forEach((item) => {
          const checkExist = vm.selects.every((select) => {
            return item[vm.idProperty] !== select[vm.idProperty];
          });
          if (checkExist) {
            vm.selects.push(item);
          }
        });
      } else {
        this.items.forEach((item) => {
          const index = vm.selects.findIndex((select) => {
            return select[vm.idProperty] === item[vm.idProperty];
          });
          vm.selects.splice(index, 1);
        });
      }
    },
    multipleSelectClick(item) {
      const index = this.selects.findIndex(
        (x) => x[this.idProperty] == item[this.idProperty]
      );
      if (index >= 0) this.selects.splice(index, 1);
      else this.selects.push(item);
    },
    onSaved() {
      if (this.selects.length == 0) {
        this.$func.message.alert("請選擇資料 !");
        return;
      }
      this.$func.message
        .confirm("相同商品數量會相加<br>確定將已選擇盤點單轉調整單嗎 ?")
        .then((result) => {
          if (result.isConfirmed) {
            this.$emit("onSaved", this.selectIds);
            this.onClosed();
          }
        });
    },
    onClosed() {
      $("#inventorySelectModal").modal("toggle");
    },
  },
};
</script>
