<template>
  <div class="form-group" :class="container" :style="containerStyle">
    <a
      class="date-source dropdown-toggle col-3 col-form-label"
      :class="labelColumn"
      href="#"
      id="multiDate"
      role="button"
      data-toggle="dropdown"
      aria-haspopup="true"
      aria-expanded="false"
      >{{ $enum.dateSourceType.getByValue(dateSourceType).text
      }}<label for="multidaterange"></label
    ></a>
    <div
      class="date-source-dropdown-menu dropdown-menu row"
      aria-labelledby="multiDate"
    >
      <div class="dropdown-item" @click="changeDateSourceType(0)">
        {{ $t("option.dateSourceType.Trade") }}
      </div>
      <div
        v-if="hasAccountDate"
        class="dropdown-item"
        @click="changeDateSourceType(1)"
      >
        {{ $t("option.dateSourceType.Account") }}
      </div>
      <div
        v-if="hasDeliveryDate"
        class="dropdown-item"
        @click="changeDateSourceType(4)"
      >
        {{ $t("option.dateSourceType.Delivery") }}
      </div>
    </div>

    <div class="col">
      <date-range-picker
        class="col-12 px-0"
        id="multidaterange"
        ref="picker"
        opens="right"
        :showWeekNumbers="false"
        :showDropdowns="true"
        :autoApply="true"
        :ranges="false"
        v-model="multiDateRange"
        @update="multiDateChanged"
      >
        <div slot="input">{{ date.DayBegin }} ~ {{ date.DayEnd }}</div>
      </date-range-picker>
    </div>
  </div>
</template>

<script>
import DateRangePicker from "vue2-daterange-picker";
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";
import date from "@/filters/date";

export default {
  data() {
    return {
      multiDateRange: {},
      ranges: {
        Today: this.$t("modal.today"),
      },
      dateSourceType: 0,
    };
  },
  props: {
    date: Object,
    degree: Number,
    gridDegree: Number,
    id: String,
    colType: Number,
    hasDeliveryDate: Boolean,
    hasAccountDate: Boolean,
  },
  components: { DateRangePicker },
  computed: {
    container() {
      switch (this.gridDegree) {
        case 12:
          return "d-flex col-12 col-lg-6 p-0";
        case 8:
          return "d-flex col-12 col-lg-4 col-md-6 p-0";
        default:
          return "row";
      }
    },
    containerStyle() {
      switch (this.gridDegree) {
        case 12:
          return "width: auto;";
        default:
          return;
      }
    },
    labelColumn() {
      switch (this.gridDegree) {
        case 12: {
          switch (this.degree) {
            case 12:
              return "col-sm-2 col-md-2 col-lg-2";
            default:
              return "col-md-2 col-lg-3";
          }
        }
        case 11: {
          switch (this.degree) {
            default:
              return "col-md-2 col-lg-3";
          }
        }
        case 8: {
          switch (this.degree) {
            default:
              return "col-md-3";
          }
        }
        case 6: {
          switch (this.degree) {
            case 4:
              return "col-md-3";
            case 12:
              return "col-md-1";
            default:
              return "col-md-2 col-lg-3";
          }
        }
        default: {
          switch (this.degree) {
            case 4:
              return "col-sm-2 col-md-3";
            case 12:
              return "col-sm-2 col-lg-1";
            default:
              return "col-sm-2 col-md-2";
          }
        }
      }
    },
  },
  methods: {
    changeDateSourceType(type) {
      this.dateSourceType = type;
      this.$emit("dateSourceTypeChanged", this.dateSourceType);
    },
    multiDateChanged(dateTime) {
      this.$emit(
        "multiDateChanged",
        date(dateTime.startDate),
        date(dateTime.endDate),
        this.dateSourceType
      );
    },
  },
};
</script>

<style lang="scss">
.date-source {
  color: #212529;
  &:hover {
    text-decoration: none;
  }
}
.date-source-dropdown-menu {
  top: -10px !important;
  left: 14px !important;
  box-shadow: 0 0 5px 3px rgba(70, 70, 70, 0.1);
  .dropdown-item {
    cursor: pointer;
  }
}
</style>
