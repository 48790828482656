<template>
  <div>
    <search
      :title="getTitle"
      :items="users"
      idProperty="UserId"
      nameProperty="UserName"
      @triggerClick="triggerClick"
      @remove="remove"
      :searchItems="searchItems"
    />

    <user-select
      :isMultiple="true"
      :trigger="trigger"
      :defaultItems="defaultItems"
      @onSaved="add"
      :roleType="roleType"
    />
  </div>
</template>

<script>
import search from "./Search";
import userSelect from "@/components/selects/UserSelects";

export default {
  data() {
    return {
      trigger: false,
      users: [],
      defaultItems: undefined,
    };
  },
  props: {
    title: String,
    roleType: null,
    searchItems: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    getTitle() {
      if (this.title) return this.title;
      else return "使用者";
    },
  },
  watch: {
    searchItems(value) {
      if (JSON.parse(sessionStorage.getItem("isSamePage")) && value.User) {
        this.users = value.User;
      }
    },
  },
  components: {
    search,
    userSelect,
  },
  methods: {
    triggerClick() {
      this.defaultItems = [...this.users];
      this.trigger = !this.trigger;
    },
    add(items) {
      this.users = items;
      this.set();
    },
    remove(index) {
      if (!this.users[index]) return;
      this.users.splice(index, 1);
      this.set();
    },
    set() {
      this.$emit("set", this.users);
    },
  },
};
</script>
