<template>
  <div>
    <pageTable
      :parentPage="pagination"
      @getItems="getRules"
      @addItem="addRule"
      @editItem="editRule"
      @removeItem="removeRule"
      :items="integralRules"
      :columns="columns"
      :idProperty="idProperty"
      :nameProperty="nameProperty"
      :getTrigger="getTrigger"
      :hasAdd="true"
      :hasEdit="true"
      :hasRemove="true"
      :isSortable="true"
    >
      <template slot="button">
        <slot name="dataButtons">
          <button
            type="button"
            class="btn btn-outline-primary btn-icon"
            @click="setFoodKind"
          >
            <i class="fas fa-project-diagram"></i>
          </button>
          <button
            type="button"
            class="btn btn-outline-primary btn-icon"
            @click="setFood"
          >
            <i class="fas fa-hamburger"></i>
          </button>
        </slot>
      </template>
    </pageTable>

    <item-modal
      :isShowModal="isShow"
      :title="modalTitle"
      @onSaved="save"
      @onClosed="close"
    >
      <div slot="item">
        <div class="row">
          <div class="col-md-12">
            <div class="form-group row">
              <label class="col-md-2 col-form-label" for="integralRuleDegree">{{
                $t("modal.degree")
              }}</label>
              <div class="col-md-10">
                <select
                  class="form-control"
                  id="integralRuleDegree"
                  v-model="integralRule.Degree"
                >
                  <option
                    v-for="levelType in customerLevelTypes"
                    :value="levelType.value"
                    :key="levelType.name"
                  >
                    {{ levelType.text }}
                  </option>
                </select>
              </div>
            </div>
          </div>
          <div class="col-12">
            <div class="form-group row">
              <label for="integralRuleTotal" class="col-md-2 col-form-label">{{
                $t("modal.satisfy")
              }}</label>
              <div class="col-md-3">
                <input
                  type="number"
                  class="form-control"
                  id="integralRuleTotal"
                  v-model="integralRule.Total"
                />
              </div>
              <label for="integralRulePoint" class="col-md-2 col-form-label">{{
                $t("modal.ntdtoget")
              }}</label>
              <div class="col-md-3">
                <input
                  type="number"
                  class="form-control"
                  id="integralRulePoint"
                  v-model="integralRule.Integral"
                />
              </div>
              <label for="integralRulePoint" class="col-md-2 col-form-label">{{
                $t("modal.p")
              }}</label>
            </div>
          </div>
        </div>
      </div>
    </item-modal>
  </div>
</template>

<script>
import pageTable from "@/components/PageTable";
import itemModal from "@/components/ItemModal";

export default {
  data() {
    return {
      integralRules: [],
      integralRule: {},
      pagination: {},
      columns: [
        {
          name: "CustomerLevelTypeText",
        },
        {
          name: "Total",
        },
        {
          name: "Integral",
        },
      ],
      customerLevelTypes: [],
      idProperty: "IntegralRuleId",
      nameProperty: "CustomerLevelTypeText",
      getTrigger: false,
      modalTitle: "",
      isShow: false,
    };
  },
  components: {
    pageTable,
    itemModal,
  },
  computed: {
    currentLang() {
      return this.$store.state.currentLang;
    },
  },
  watch: {
    currentLang() {
      this.setOptions();
    },
  },
  methods: {
    setFoodKind() {
      this.$router.push({
        name: "IntegralFoodKind",
      });
    },
    setFood() {
      this.$router.push({
        name: "IntegralFood",
      });
    },
    setOptions() {
      this.customerLevelTypes = this.$enum.customerLevelType.getAll();
    },
    getRules(dTO) {
      this.$api.integralRules.getPages(dTO).then((data) => {
        this.integralRules = data.Datas;
        this.pagination = data.Pagination;
      });
    },
    addRule() {
      this.integralRule = {
        Degree: 0,
      };
      this.modalTitle = this.$t("modal.addintegralrule");
      this.isShow = !this.isShow;
    },
    editRule(rule) {
      this.$api.integralRules.get(rule.IntegralRuleId).then((data) => {
        this.integralRule = data;
        this.modalTitle = this.$t("modal.editintegralrule");
        this.isShow = !this.isShow;
      });
    },
    removeRule(rule) {
      this.$api.integralRules.remove(rule.IntegralRuleId).then(() => {
        this.getTrigger = !this.getTrigger;
      });
    },
    save() {
      this.$api.integralRules
        .save(this.integralRule)
        .then(() => {
          this.isShow = !this.isShow;
          this.getTrigger = !this.getTrigger;
        })
        .catch((error) => this.$func.message.error(error.data.Message));
    },
    close() {
      this.isShow = !this.isShow;
    },
  },
  mounted() {
    this.setOptions();
  },
};
</script>
