import { httpGet, httpPost, httpDelete } from '@/request/http';

let path = '/downloaddets';

export default{
    get(id){
        return httpGet(`${path}/${id}`);
    },
    getEmpty(){
        return httpGet(`${path}/empty`);
    }
}
