import i18n from "@/langs/i18n";

export function getAll() {
  return [
    {
      value: 0,
      name: "None",
      text: `${i18n.t("option.rankType.None")}`,
    },
    {
      value: 1,
      name: "Qty",
      text: `${i18n.t("option.rankType.Qty")}`,
    },
    {
      value: 2,
      name: "Total",
      text: `${i18n.t("option.rankType.Total")}`,
    },
  ];
}
