<template>
  <form class="card card-table" @submit.prevent="onSaved">
    <div class="card-header">
      <button
        type="button"
        class="btn btn-outline-primary btn-icon"
        @click="onSaved"
      >
        <i class="fas fa-check"></i>
      </button>
      <button
        type="button"
        class="btn btn-outline-danger btn-icon"
        @click="onClosed"
      >
        <i class="fas fa-times"></i>
      </button>
    </div>
    <div class="card-body">
      <div class="row">
        <input-part
          id="combinationTradeDate"
          :labelText="`${$t('modal.date')}:`"
        >
          <input
            type="date"
            class="form-control"
            id="combinationTradeDate"
            v-model="seperationTrade.CombinationTradeDate"
            required
          />
        </input-part>
        <input-part
          id="combinationTradeNumber"
          :labelText="`${$t('modal.number2')}:`"
        >
          <input
            type="text"
            class="form-control"
            id="combinationTradeNumber"
            v-model="seperationTrade.CombinationTradeNumber"
            readonly
          />
        </input-part>

        <input-part id="employeeName" :labelText="`${$t('modal.specialist')}:`">
          <input
            type="text"
            class="form-control"
            id="employeeName"
            readonly
            :value="seperationTrade.EmployeeName"
          />
        </input-part>
        <input-part id="combination" :labelText="`${$t('modal.combination')}:`">
          <input
            type="text"
            class="form-control"
            id="combination"
            :value="seperationTrade.FoodName"
            readonly
          />
        </input-part>
        <input-part id="warehouse" :labelText="`${$t('modal.warehouse')}:`">
          <input
            type="text"
            class="form-control"
            id="warehouse"
            :value="seperationTrade.WarehouseName"
            readonly
          />
        </input-part>
        <input-part id="qty" :labelText="`${$t('modal.qty')}:`">
          <input
            type="number"
            class="form-control"
            id="qty"
            v-model="seperationTrade.Qty"
            @change="updateQty"
            required
          />
        </input-part>
        <input-part id="batchNumber" :labelText="`${$t('modal.batchnumber')}:`">
          <input
            type="number"
            class="form-control"
            id="batchNumber"
            v-model="seperationTrade.BatchNumber"
            readonly
          />
        </input-part>

        <input-part id="memo" :labelText="`${$t('modal.memo')}:`" :degree="12">
          <input
            type="text"
            class="form-control"
            id="memo"
            v-model="seperationTrade.Memo"
          />
        </input-part>

        <div class="col-12">
          <table-part :columns="columns" :noHide="true">
            <tbody slot="tbody">
              <tr
                v-for="det in seperationTradeDets"
                :key="det.CombinationTradeDetId"
              >
                <td>{{ det.FoodNumber }}</td>
                <td>{{ det.FoodName }}</td>
                <td>
                  <input
                    class="form-control"
                    type="text"
                    v-model="det.WarehouseNumber"
                    @change="updateWarehouse(det)"
                    required
                  />
                </td>
                <td>{{ det.WarehouseName }}</td>
                <td class="text-right">{{ det.Qty }}</td>
                <td class="text-right">{{ det.Cost }}</td>
                <td class="text-right">{{ det.Total }}</td>
                <td>{{ det.Unit }}</td>
                <td>
                  <input
                    class="form-control"
                    type="text"
                    v-model="det.BatchNumber"
                    readonly
                  />
                </td>
              </tr>
              <tr>
                <td>{{ $t("modal.total") }}:</td>
                <td></td>
                <td></td>
                <td></td>
                <td class="text-right">{{ totalQty }}</td>
                <td></td>
                <td class="text-right">{{ seperationTrade.Total }}</td>
                <td></td>
                <td></td>
              </tr>
            </tbody>
          </table-part>
        </div>
      </div>
    </div>
  </form>
</template>

<script>
import inputPart from "@/components/parts/inputPart";
import tablePart from "@/components/parts/tablePart";

export default {
  data() {
    return {
      combinationTradeGroup: {},
      seperationTrade: {},
      seperationTradeDets: [],
      warehouse: {},
    };
  },
  computed: {
    totalQty() {
      if (this.seperationTradeDets.length > 0)
        return this.seperationTradeDets
          .map((item) => {
            return item.Qty;
          })
          .reduce((t, q) => {
            return parseInt(t) + parseInt(q);
          });
      else return 0;
    },
    columns() {
      return [
        { name: "FoodNumber", headerText: "子件品號" },
        { name: "FoodName", headerText: "子件品名" },
        { name: "WarehouseNumber" },
        { name: "WarehouseName" },
        { name: "Qty" },
        { name: "Cost" },
        { name: "Total" },
        { name: "Unit" },
        { name: "BatchNumber" },
      ];
    },
  },
  components: {
    inputPart,
    tablePart,
  },
  methods: {
    updateQty() {
      const times =
        this.seperationTrade.Qty /
        this.combinationTradeGroup.CombinationTrade.Qty;
      this.combinationTradeGroup.CombinationTradeDets.forEach((det) => {
        let tradeDet = this.seperationTradeDets.find(
          (e) => det.FoodId == e.FoodId
        );
        if (tradeDet) {
          tradeDet.Qty = det.Qty * times;
        }
      });
      this.calculate();
    },
    onSaved() {
      this.seperationTradeDets.forEach((det) => {
        if (!det.WarehouseId) {
          this.$func.message.alert("請選擇倉庫 !");
          return;
        }
      });
      const vm = this;
      this.$api.combinationTrades
        .save({
          CombinationTrade: vm.seperationTrade,
          CombinationTradeDets: vm.seperationTradeDets,
        })
        .then(() =>
          this.$router.push({
            name: "SeperationTrades",
          })
        )
        .catch((error) => {
          vm.$func.message.error(error.data.Message);
        });
    },
    onClosed() {
      this.$router.go(-1);
    },

    addFoods(items) {
      const vm = this;
      items.forEach(function (item) {
        vm.addDet(item);
      });
    },
    calculate() {
      let total = 0;
      this.seperationTradeDets.forEach((det) => {
        if (det.Qty != undefined && det.Cost != undefined) {
          det.Total = this.$func.math.mul(det.Qty, det.Cost);
          total = this.$func.math.add(det.Total, total);
        }
      });
      this.seperationTrade.Total = total;
    },
    updateWarehouse(det) {
      if (det.WarehouseNumber) {
        this.$api.warehouses
          .getByNumber(det.WarehouseNumber)
          .then((warehouse) => {
            this.setWarehouse(det, warehouse);
          })
          .catch(() => {
            this.$func.message.alert("查無此資料 !");
            this.setWarehouse(det, undefined);
          });
      } else {
        this.setWarehouse(det, undefined);
      }
    },
    setWarehouse(det, warehouse) {
      if (warehouse) {
        det.WarehouseId = warehouse.WarehouseId;
        det.WarehouseNumber = warehouse.WarehouseNumber;
        det.WarehouseName = warehouse.WarehouseName;
      } else {
        det.WarehouseId = undefined;
        det.WarehouseNumber = undefined;
        det.WarehouseName = undefined;
      }
      this.calculate();
    },
  },
  created() {
    const id = this.$route.params["id"];
    if (id) {
      this.$api.combinationTrades
        .get(id)
        .then((data) => {
          this.combinationTradeGroup = data;
          this.seperationTrade = Object.assign({}, data.CombinationTrade);
          if (this.seperationTrade.TradeType != 13) {
            this.seperationTrade.RelationId =
              this.seperationTrade.CombinationTradeId;
            this.seperationTrade.CombinationTradeId = this.$func.uuid.get();
            this.seperationTrade.CombinationTradeDate = this.$func.getDate();
            this.seperationTrade.CombinationTradeNumber = null;
            this.seperationTrade.TradeType = 13;
            data.CombinationTradeDets.forEach((det) => {
              det.CombinationTradeId = this.seperationTrade.CombinationTradeId;
              det.CombinationTradeDetId = this.$func.uuid.get();
            });
          }
          this.seperationTradeDets = [];
          data.CombinationTradeDets.forEach((det) => {
            const c = Object.assign({}, det);
            this.seperationTradeDets.push(c);
          });
        })
        .catch(() => {
          vm.$func.message.alert("查無此筆單據 !");
          vm.$router.go(-1);
        });
    } else {
      this.$router.push({ name: "SeperationTrades" });
    }
  },
};
</script>
