export function getAll() {
  return [
    {
      value: 0,
      name: "All",
      text: "全部",
    },
    {
      value: 3,
      name: "NotFinishedYet",
      text: "未結案",
    },
    {
      value: 4,
      name: "Finished",
      text: "已結案",
    },
  ];
}
