export function getAll() {
  var items = [
    {
      value: 0,
      name: "Default",
      text: "預設",
    },
    {
      value: 1,
      name: "Showmore",
      text: "Showmore",
    },
  ];
  return items;
}

export function getByName(name) {
  const type = getAll().find(function (item) {
    return item.name.toLowerCase() == name.toLowerCase();
  });
  return type;
}

export function getByValue(value) {
  const type = getAll().find(function (item) {
    return item.value == value;
  });
  return type;
}
