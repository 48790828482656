<template>
  <div>
    <itemModal
      :isShowModal="modalDisplayTrigger"
      :title="title"
      @onSaved="onSaved"
      @onClosed="onClosed"
      modalClass="modal-lg"
    >
      <div slot="item">
        <div class="row">
          <div class="col-md-6 col-lg-4">
            <div class="form-group">
              <label for="dataCustomerNumber">{{
                $t("dataModalPart.number")
              }}</label>
              <input
                type="text"
                class="form-control"
                id="dataCustomerNumber"
                v-model="customer.CustomerNumber"
                required
                :readonly="isEdit"
              />
            </div>
          </div>
          <div class="col-md-6 col-lg-4">
            <div class="form-group">
              <label for="dataCustomerName">{{
                $t("dataModalPart.name")
              }}</label>
              <input
                type="text"
                class="form-control"
                id="dataCustomerName"
                v-model="customer.CustomerName"
                required
              />
            </div>
          </div>
          <div class="col-md-6 col-lg-4">
            <div class="form-group">
              <label for="storeId">{{ $t("dataModalPart.store") }}</label>
              <select
                id="storeId"
                class="form-control"
                v-model="customer.StoreId"
              >
                <option
                  v-for="store in stores"
                  :key="store.StoreId"
                  :value="store.StoreId"
                >
                  {{ store.StoreName }}
                </option>
              </select>
            </div>
          </div>
          <div class="col-md-6 col-lg-4">
            <div class="form-group">
              <label for="birth">{{ $t("dataModalPart.birth") }}</label>
              <input
                type="date"
                class="form-control"
                id="birth"
                v-model="customer.Birth"
              />
            </div>
          </div>
          <div class="col-md-6 col-lg-4">
            <div class="form-group">
              <label for="countryCode">{{
                $t("dataModalPart.countrycode")
              }}</label>
              <input
                type="text"
                class="form-control"
                id="countryCode"
                v-model="customer.CountryCode"
              />
            </div>
          </div>
          <div class="col-md-6 col-lg-4">
            <div class="form-group">
              <label for="cellphone">{{ $t("dataModalPart.cellphone") }}</label>
              <input
                type="text"
                class="form-control"
                id="cellphone"
                v-model="customer.Cellphone"
              />
            </div>
          </div>
          <div class="col-md-6 col-lg-4">
            <div class="form-group">
              <label for="phone1">{{ $t("dataModalPart.phone1") }}</label>
              <input
                type="text"
                class="form-control"
                id="phone1"
                v-model="customer.Phone1"
              />
            </div>
          </div>
          <div class="col-md-6 col-lg-4">
            <div class="form-group">
              <label for="phone2">{{ $t("dataModalPart.phone2") }}</label>
              <input
                type="text"
                class="form-control"
                id="phone2"
                v-model="customer.Phone2"
              />
            </div>
          </div>
          <div class="col-md-6 col-lg-4">
            <div class="form-group">
              <label for="email">{{ $t("dataModalPart.email") }}</label>
              <input
                type="email"
                class="form-control"
                id="email"
                v-model="customer.Email"
              />
            </div>
          </div>
          <div class="col-md-6 col-lg-4">
            <div class="form-group">
              <label for="identifyCard">{{
                $t("dataModalPart.identifycard")
              }}</label>
              <input
                type="text"
                class="form-control"
                id="identifyCard"
                v-model="customer.IdentifyCard"
              />
            </div>
          </div>
          <div class="col-md-6 col-lg-4">
            <div class="form-group">
              <label for="gender">{{ $t("dataModalPart.gender") }}</label>
              <input
                type="text"
                class="form-control"
                id="gender"
                v-model="customer.Gender"
              />
            </div>
          </div>
          <div class="col-md-6 col-lg-4">
            <div class="form-group">
              <label for="uniformNumber">{{
                $t("dataModalPart.uniformnumber")
              }}</label>
              <input
                type="text"
                class="form-control"
                id="uniformNumber"
                v-model="customer.UniformNumber"
              />
            </div>
          </div>
          <div class="col-md-6 col-lg-4">
            <div class="form-group">
              <label for="vehicleNumber">{{
                $t("dataModalPart.vehiclenumber")
              }}</label>
              <input
                type="text"
                class="form-control"
                id="vehicleNumber"
                v-model="customer.VehicleNumber"
              />
            </div>
          </div>
          <div class="col-md-6 col-lg-4">
            <div class="form-group">
              <label for="degree">{{
                $t("dataModalPart.customerdegree")
              }}</label>
              <select
                class="form-control"
                id="degree"
                v-model="customer.Degree"
              >
                <option
                  v-for="degree in degreeTypes"
                  :key="degree.value"
                  :value="degree.value"
                >
                  {{ degree.text }}
                </option>
              </select>
            </div>
          </div>
          <div class="col-md-6 col-lg-4">
            <div class="form-group">
              <label for="dataContactPerson">{{
                $t("dataModalPart.contactperson")
              }}</label>
              <input
                type="text"
                class="form-control"
                id="dataContactPerson"
                v-model="customer.ContactPerson"
              />
            </div>
          </div>
          <div class="col-md-6 col-lg-4">
            <div class="form-group">
              <label for="salerId">{{ $t("dataModalPart.salesperson") }}</label>
              <select
                id="salerId"
                class="form-control"
                v-model="customer.SalerId"
              >
                <option
                  v-for="employee in employees"
                  :key="employee.EmployeeId"
                  :value="employee.EmployeeId"
                >
                  {{ employee.EmployeeName }}
                </option>
              </select>
            </div>
          </div>
          <div class="col-md-6 col-lg-4">
            <div class="form-group">
              <label for="faxNumber">{{ $t("dataModalPart.faxnumber") }}</label>
              <input
                type="text"
                class="form-control"
                id="faxNumber"
                v-model="customer.FaxNumber"
              />
            </div>
          </div>
          <div class="col-12">
            <div class="form-group">
              <label for="dataAddress">{{ $t("dataModalPart.address") }}</label>
              <input
                type="text"
                class="form-control"
                id="dataAddress"
                v-model="customer.Address"
              />
            </div>
          </div>
          <div class="col-12">
            <div class="form-group">
              <label for="dataMemo">{{ $t("dataModalPart.memo") }}</label>
              <input
                type="text"
                class="form-control"
                id="dataMemo"
                v-model="customer.Memo"
              />
            </div>
          </div>
          <div class="col-12">
            <div class="form-group">
              <label for="pickupAddress">{{
                $t("dataModalPart.pickupaddress")
              }}</label>
              <input
                type="text"
                class="form-control"
                id="pickupAddress"
                v-model="customer.PickupAddress"
              />
            </div>
          </div>

          <div v-if="hasOcard" class="col-12">
            <div class="custom-control custom-checkbox">
              <input
                type="checkbox"
                class="custom-control-input"
                id="noOcard"
                v-model="customer.NoOcard"
              />
              <label class="custom-control-label" for="noOcard">{{
                $t("dataModalPart.noocard")
              }}</label>
            </div>
          </div>
        </div>
      </div>
    </itemModal>
  </div>
</template>

<script>
import itemModal from "@/components/ItemModal";

export default {
  data() {
    return {
      stores: [],
      employees: [],
      degreeTypes: [],
      customer: {},
    };
  },
  props: ["title", "isEdit", "item", "modalDisplayTrigger"],
  components: {
    itemModal,
  },
  computed: {
    currentLang() {
      return this.$store.state.currentLang;
    },
    hasOcard() {
      return window.localStorage["hasOcard"] == "true";
    },
  },
  watch: {
    modalDisplayTrigger(value) {
      this.customer = {};
      if (value) {
        this.customer = this.item;
      }
    },
    currentLang() {
      this.stores[0].StoreName = this.$t("option.None");
      this.employees[0].EmployeeName = this.$t("option.None");
      this.setOptions();
    },
  },
  methods: {
    setOptions() {
      this.degreeTypes = this.$enum.degreeType.getAll();
      this.degreeTypes.splice(0, 0, {
        value: -1,
        name: "None",
        text: `${this.$t("option.degreeType.None")}`,
      });
    },
    onSaved() {
      this.$emit("onSaved", this.customer);
    },
    onClosed() {
      this.$emit("onClosed");
    },
  },
  mounted() {
    this.setOptions();
    const vm = this;
    this.$api.stores.getNames().then((data) => {
      data.splice(0, 0, {
        StoreId: null,
        StoreName: vm.$t("option.None"),
      });
      vm.stores = data;
    });
    this.$api.employees.getNames().then((data) => {
      data.splice(0, 0, {
        EmployeeId: null,
        EmployeeName: vm.$t("option.None"),
      });
      vm.employees = data;
    });
  },
};
</script>
