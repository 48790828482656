import getColumnProperty from "@/funcs/scripts/getColumnProperty";
import getViewName from "@/funcs/scripts/getViewName";
import * as getExcel from "@/funcs/scripts/getExcel";
import * as getPdf from "./scripts/getPdf";
import * as printPdf from "./scripts/printPdf";
import postExcel from "@/funcs/scripts/postExcel";
import * as defaultModel from "@/funcs/scripts/defaultModel";
import getDate from "./getDate";
import getReportTypeName from "@/funcs/scripts/getReportTypeName";
import { calculateTax } from "./scripts/calculateTax";
import * as math from "@/funcs/scripts/math";
import * as crypto from "@/funcs/scripts/crypto";
import getTXT from "@/funcs/scripts/getTXT";
import postFile from "@/funcs/scripts/postFile";
import * as localStorage from "@/funcs/scripts/localStorage";
import * as message from "@/funcs/scripts/message";
import uuid from "@/funcs/scripts/uuid";
import copy from "@/funcs/scripts/copy";
import * as inputValidate from "@/funcs/scripts/inputValidate";
import * as regExp from "@/funcs/scripts/regExp";
import * as validateResult from "@/funcs/scripts/validateResult";

export default {
  getColumnProperty,
  getViewName,
  getExcel,
  getPdf,
  printPdf,
  postExcel,
  defaultModel,
  getDate,
  getReportTypeName,
  calculateTax,
  math,
  crypto,
  getTXT,
  postFile,
  localStorage,
  message,
  uuid,
  copy,
  inputValidate,
  regExp,
  validateResult,
};
