export default function (name) {
  switch (name.toLowerCase()) {
    case "simple":
      return "簡要";
    case "detail":
      return "明細";
    case "summary":
      return "彙總";
    case "all":
      return "全部";
    case "sale":
      return "消費";
    case "store":
      return "儲值";
    case "statistic":
      return "統計";
    case "daystatistic":
      return "日統計";
    case "warehouse":
      return "倉庫";
    case "food":
      return "商品";
    case "inventory":
      return "盤點";
    case "new":
      return "新增資料";
    case "import":
      return "匯入資料";
  }
}
