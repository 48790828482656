<template>
  <div>
    <condition-part
      :hasDate="true"
      :hasExcel="true"
      :hasPdf="true"
      :hasAdd="false"
      :hasEdit="false"
      :hasRemove="false"
      :columns="columns"
      :items="items"
      :pagination="pagination"
      :isSortable="true"
      @getItems="getItems"
      @downloadExcel="downloadExcel"
      @downloadPdf="downloadPdf"
      @printPdf="printPdf"
    />
  </div>
</template>

<script>
import conditionPart from "@/components/parts/conditionPart";

export default {
  data() {
    return {
      items: [],
      pagination: {},
      columns: [
        { name: "CouponName" },
        { name: "CreateTime" },
        { name: "Price" },
        { name: "ExpireDate" },
        { name: "SourceTerminalName" },
        { name: "SourceSalNumber" },
        { name: "SourceSalTime" },
        { name: "DiscountTerminalName" },
        { name: "DiscountSalNumber" },
        { name: "DiscountSalTime" },
      ],
    };
  },
  components: {
    conditionPart,
  },
  methods: {
    downloadExcel(condition) {
      this.$api.reportExcels.getCoupon(condition);
    },
    downloadPdf(condition) {
      this.$api.reportPdfs.getCoupon(condition);
    },
    printPdf(condition) {
      this.$api.reportPdfs.printCoupon(condition);
    },
    getItems(condition) {
      const vm = this;
      this.$api.reports.getCoupon(condition).then((data) => {
        vm.items = data.Datas;
        vm.pagination = data.Pagination;
      });
    },
  },
};
</script>
