<template>
  <div class="row">
    <div class="col-md-6">
      <h5>{{ $t("title.tastekind") }}</h5>
      <page-table
        :parentPage="pagination"
        :items="tasteKinds"
        :columns="columns"
        :idProperty="idProperty"
        :nameProperty="nameProperty"
        @getItems="getTasteKinds"
        @addItem="addTasteKind"
        @editItem="editTasteKind"
        @removeItem="removeTasteKind"
        @trdbClicked="editTasteKind"
        @currentIndexChanged="currentIndexChanged"
        :getTrigger="getTrigger"
        :changeIndex="currentIndex"
        :hasAdd="authority.IsAdd"
        :hasEdit="authority.IsUpdate"
        :hasRemove="authority.IsRemove"
        :isSortable="true"
      >
        <template slot="button">
          <button
            type="button"
            class="btn btn-outline-info py-2 px-4"
            style="border-color: transparent"
            v-if="authority.IsUpdate"
            @click="showFoodKindTaste"
          >
            <i class="fa fa-project-diagram"></i>
          </button>
          <button
            type="button"
            class="btn btn-outline-info py-2 px-4"
            style="border-color: transparent"
            v-if="authority.IsUpdate"
            @click="showFoodTaste"
          >
            <i class="fas fa-hamburger"></i>
          </button>
          <button
            type="button"
            class="btn btn-outline-primary py-2 px-4"
            style="border-color: transparent"
            v-if="authority.IsUpdate"
            @click="onBefore"
          >
            <i class="fa fa-arrow-up"></i>
          </button>
          <button
            type="button"
            class="btn btn-outline-primary py-2 px-4"
            style="border-color: transparent"
            v-if="authority.IsUpdate"
            @click="onAfter"
          >
            <i class="fa fa-arrow-down"></i>
          </button>
        </template>
      </page-table>
    </div>
    <div class="col-md-6">
      <h5>{{ $t("title.taste") }}</h5>
      <taste :tasteKindId="currentKindId" />
    </div>

    <itemModal
      :isShowModal="isShowModal"
      @onClosed="isShowModal = !isShowModal"
      @onSaved="saveTasteKind"
      :title="modalTitle"
    >
      <div slot="item">
        <div class="row">
          <div class="form-group col-12">
            <label for="tasteKindName">{{ $t("modal.name") }}</label>
            <input
              type="text"
              class="form-control"
              id="tasteKindName"
              v-model="tasteKind.TasteKindName"
              required
            />
          </div>
          <div v-if="hasEnglish" class="form-group col-12 col-md-6">
            <label for="tasteKindNameEN">{{ $t("modal.english") }}</label>
            <input
              type="text"
              class="form-control"
              id="tasteKindNameEN"
              v-model="tasteKind.English"
            />
          </div>
          <div v-if="hasJapanese" class="form-group col-12 col-md-6">
            <label for="tasteKindNameJP">{{ $t("modal.japanese") }}</label>
            <input
              type="text"
              class="form-control"
              id="tasteKindNameJP"
              v-model="tasteKind.Japanese"
            />
          </div>
          <div v-if="hasKorean" class="form-group col-12 col-md-6">
            <label for="tasteKindNameKR">{{ $t("modal.korean") }}</label>
            <input
              type="text"
              class="form-control"
              id="tasteKindNameKR"
              v-model="tasteKind.Korean"
            />
          </div>
          <div v-if="hasFrench" class="form-group col-12 col-md-6">
            <label for="tasteKindNameFR">{{ $t("modal.french") }}</label>
            <input
              type="text"
              class="form-control"
              id="tasteKindNameFR"
              v-model="tasteKind.French"
            />
          </div>
        </div>
      </div>
    </itemModal>
  </div>
</template>

<script>
import pageTable from "@/components/PageTable";
import itemModal from "@/components/ItemModal";
import taste from "@/components/datas/Tastes";
import foodTaste from "@/components/datas/FoodTastes";

export default {
  data() {
    return {
      tasteKinds: [],
      tasteKind: {},
      currentKindId: "",
      pagination: {},
      isShowModal: false,
      isShowFood: false,
      modalTitle: "",
      columns: [
        {
          name: "TasteKindName",
        },
      ],
      idProperty: "TasteKindId",
      nameProperty: "TasteKindName",
      getTrigger: false,
      currentIndex: -1,
      authority: {},
      hasEnglish: window.localStorage["hasEnglish"] == "true",
      hasJapanese: window.localStorage["hasJapanese"] == "true",
      hasKorean: window.localStorage["hasKorean"] == "true",
      hasFrench: window.localStorage["hasFrench"] == "true",
    };
  },
  components: {
    pageTable,
    itemModal,
    taste,
    foodTaste,
  },
  methods: {
    currentIndexChanged(index) {
      this.currentIndex = index;
      if (this.currentIndex < 0) {
        this.currentKindId = null;
      }
      const tasteKind = this.tasteKinds[this.currentIndex];
      if (!tasteKind) {
        this.currentKindId = null;
      } else {
        this.currentKindId = tasteKind.TasteKindId;
      }
    },
    getCurrentId() {
      if (this.currentIndex < 0) {
        this.$func.message.alert("請選擇口味類別 !");
        return undefined;
      }
      const tasteKind = this.tasteKinds[this.currentIndex];
      if (!tasteKind) {
        return undefined;
      } else {
        return tasteKind.TasteKindId;
      }
    },
    getTasteKinds(dTO) {
      const vm = this;
      this.$api.tasteKinds.getPages(dTO).then((data) => {
        vm.tasteKinds = data.Datas;
        vm.pagination = data.Pagination;
      });
    },
    addTasteKind() {
      this.tasteKind = {};
      this.isShowModal = !this.isShowModal;
      this.modalTitle = this.$t("modal.addtastekind");
    },
    editTasteKind(tasteKind) {
      if (!this.authority.IsUpdate) {
        return;
      }
      const vm = this;
      this.$api.tasteKinds.get(tasteKind.TasteKindId).then((data) => {
        vm.tasteKind = data;
        vm.isShowModal = !vm.isShowModal;
        vm.modalTitle = this.$t("modal.edittastekind");
      });
    },
    removeTasteKind(tasteKind) {
      const vm = this;
      this.$api.tasteKinds.remove(tasteKind.TasteKindId).then(() => {
        vm.getTrigger = !vm.getTrigger;
      });
    },
    saveTasteKind() {
      const vm = this;
      this.$api.tasteKinds
        .save(vm.tasteKind)
        .then(() => {
          vm.isShowModal = !vm.isShowModal;
          vm.getTrigger = !vm.getTrigger;
        })
        .catch((error) => vm.$func.message.error(error.data.Message));
    },
    showFoodKindTaste() {
      if (this.currentKindId.length == 0) {
        this.$func.message.alert("請選擇一筆資料 !");
        return;
      }
      this.$router.push({
        name: "FoodKindTastes",
        params: { id: this.currentKindId },
      });
    },
    showFoodTaste() {
      if (this.currentKindId.length == 0) {
        this.$func.message.alert("請選擇一筆資料 !");
        return;
      }
      this.$router.push({
        name: "FoodTastes",
        params: { id: this.currentKindId },
      });
    },
    onBefore() {
      const id = this.getCurrentId();
      if (!id) {
        return;
      }
      const vm = this;
      this.$api.tasteKinds
        .before({ Id: id, PageSize: this.pagination.PageSize })
        .then((data) => {
          if (data.Pagination.CurrentPage < vm.pagination.CurrentPage) {
            vm.tasteKinds = data.Datas;
            vm.currentIndexChanged(data.Pagination.PageSize - 1);
            vm.pagination = data.Pagination;
          } else {
            vm.currentIndexChanged(vm.currentIndex - 1);
          }
          vm.tasteKinds = data.Datas;
          vm.pagination = data.Pagination;
        })
        .catch((error) => vm.$func.message.error(error.data.Message));
    },
    onAfter() {
      const id = this.getCurrentId();
      if (!id) {
        return;
      }
      const vm = this;
      this.$api.tasteKinds
        .after({ Id: id, PageSize: this.pagination.PageSize })
        .then((data) => {
          if (data.Pagination.CurrentPage > vm.pagination.CurrentPage) {
            vm.tasteKinds = data.Datas;
            vm.currentIndexChanged(0);
            vm.pagination = data.Pagination;
          } else {
            vm.currentIndexChanged(vm.currentIndex + 1);
          }
          vm.tasteKinds = data.Datas;
          vm.pagination = data.Pagination;
        })
        .catch((error) => vm.$func.message.error(error.data.Message));
    },
  },
  created() {
    this.authority = this.$func.localStorage.getAuthByName(this.$route.name);
  },
};
</script>
