<template>
  <itemModal
    :isShowModal="isShowModal"
    @onClosed="isShowModal = !isShowModal"
    @onSaved="onSaved"
    :modalId="modalId"
    :title="title"
  >
    <div slot="item">
      <div class="row">
        <div class="col-md-6">
          <div class="form-group">
            <label :for="userName">名稱</label>
            <input
              type="text"
              class="form-control"
              :id="userName"
              v-model="user.UserName"
              required
            />
          </div>
          <div class="form-group">
            <label :for="account">帳號</label>
            <input
              type="text"
              class="form-control"
              :id="account"
              v-model="user.Account"
              required
              :readonly="isEdit == true"
            />
          </div>
          <div class="form-group" v-if="!isEdit">
            <label :for="passwordId">密碼</label>
            <input
              type="password"
              class="form-control"
              :id="passwordId"
              v-model="password"
              required
            />
          </div>
        </div>
        <div class="col-md-6">
          <div class="form-group">
            <label :for="email">信箱</label>
            <input
              type="text"
              class="form-control"
              :id="email"
              v-model="user.Email"
            />
          </div>
          <div class="form-group" v-if="hasAdmin">
            <label :for="roleType">角色</label>
            <select
              class="form-control"
              :id="roleType"
              v-model="user.RoleType"
              :disabled="!isAdmin"
            >
              <option
                v-for="roleType in roleTypes"
                :key="roleType.value"
                :value="roleType.value"
              >
                {{ roleType.text }}
              </option>
            </select>
          </div>
        </div>
      </div>
    </div>
  </itemModal>
</template>

<script>
import itemModal from "@/components/ItemModal";

export default {
  data() {
    return {
      user: {},
      roleTypes: this.$enum.roleType.getAll(),
      title: "",
      isShowModal: false,
      isEdit: false,
      password: "",
    };
  },
  computed: {
    userName() {
      return `${this.modalId}userName`;
    },
    account() {
      return `${this.modalId}account`;
    },
    roleType() {
      return `${this.modalId}roleType`;
    },
    email() {
      return `${this.modalId}email`;
    },
    passwordId() {
      return `${this.modalId}password`;
    },
    isAdmin() {
      return this.$enum.roleType.isAdmin();
    },
  },
  components: {
    itemModal,
  },
  props: ["hasAdmin", "id", "trigger", "modalId"],
  methods: {
    onSaved() {
      this.user.UserId = this.id;
      this.user.Password = this.$func.crypto.encrypt(this.password);
      const vm = this;
      if (!this.isEdit) {
        this.$api.users
          .save(vm.user)
          .then(() => {
            vm.isShowModal = !vm.isShowModal;
            vm.$emit("onSaved");
          })
          .catch((error) => {
            vm.$func.message.error(error.data.Message);
          });
      } else {
        if (this.isAdmin) {
          this.$api.users
            .updateAdmin(vm.user.UserId, vm.user)
            .then(() => {
              vm.isShowModal = !vm.isShowModal;
              if (window.localStorage["userId"] == vm.user.UserId)
                window.localStorage["userName"] = vm.user.UserName;
              vm.$emit("onSaved", vm.user.UserId);
            })
            .catch((error) => {
              vm.$func.message.error(error.data.Message);
            });
        } else {
          this.$api.users
            .update(vm.user.UserId, vm.user)
            .then(() => {
              vm.isShowModal = !vm.isShowModal;
              if (window.localStorage["userId"] == vm.user.UserId)
                window.localStorage["userName"] = vm.user.UserName;
              vm.$emit("onSaved", vm.user.UserId);
            })
            .catch((error) => {
              vm.$func.message.error(error.data.Message);
            });
        }
      }
    },
  },
  watch: {
    trigger() {
      this.isEdit = false;
      this.user = {};
      if (!this.id || this.id == "") {
        this.title = "新增使用者";
        this.isShowModal = !this.isShowModal;
      } else {
        const vm = this;
        this.$api.users
          .get(vm.id)
          .then((data) => {
            this.title = "編輯使用者";
            vm.user = data;
            vm.isEdit = true;
            vm.isShowModal = !vm.isShowModal;
          })
          .catch((error) => {
            if (error.status == "404") {
              vm.title = "新增使用者";
              vm.user = {};
              vm.isShowModal = !vm.isShowModal;
            } else {
              vm.$func.message.error(error.data.Message);
            }
          });
      }
    },
  },
};
</script>
