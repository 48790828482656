<template>
  <div>
    <div
      id="module-name"
      v-if="module"
      style="font-size: 32px; font-weight: 500; line-height: 1.2"
    >
      {{ module.ModuleName }}
    </div>
    <div id="card" class="card card-table">
      <div
        id="fixed-header"
        style="
          width: 100%;
          position: fixed;
          top: 0;
          left: 0;
          padding: 0 15px;
          display: none;
          align-items: center;
          z-index: 10;
        "
      >
        <div
          style="
            width: 100%;
            padding: 8px 10px;
            min-height: 58px;
            background-color: #f7f7f7;
            border-radius: 0.25rem 0.25rem 0 0;
            border: 1px solid #d8d8d8;
            border-top: 0;
          "
        >
          <button
            type="button"
            class="btn btn-outline-primary py-2 px-4"
            style="border-color: transparent"
            @click="onBefore"
          >
            <i class="fa fa-arrow-up"></i>
          </button>
          <button
            type="button"
            class="btn btn-outline-primary py-2 px-4"
            style="border-color: transparent"
            @click="onAfter"
          >
            <i class="fa fa-arrow-down"></i>
          </button>
        </div>
      </div>
      <div id="sequence-controller" class="card-header">
        <button
          v-if="!pagination.Keyword"
          type="button"
          class="btn btn-outline-primary py-2 px-4"
          style="border-color: transparent"
          @click="onBefore"
        >
          <i class="fa fa-arrow-up"></i>
        </button>
        <button
          v-if="!pagination.Keyword"
          type="button"
          class="btn btn-outline-primary py-2 px-4"
          style="border-color: transparent"
          @click="onAfter"
        >
          <i class="fa fa-arrow-down"></i>
        </button>
      </div>
      <div class="card-body card-table-body">
        <div class="form-group">
          <div class="col-12">
            <div class="row py-1">
              <label
                for="foodKindId"
                class="col-3 col-md-2 col-lg-1 col-xl-1 col-form-label"
                >{{ $t("modal.foodkind") }}:</label
              >
              <select
                id="foodKindId"
                v-if="foodKinds"
                class="form-control col-6 col-lg-4 col-xl-3"
                v-model="currentFoodKindId"
              >
                <option
                  v-for="foodKind in foodKinds"
                  :key="foodKind.FoodKindId"
                  :value="foodKind.FoodKindId"
                >
                  {{ foodKind.FoodKindName }}
                </option>
              </select>
            </div>
          </div>
          <div class="col-12">
            <div class="row py-1">
              <label
                for="foodName"
                class="col-3 col-md-2 col-lg-1 col-xl-1 col-form-label"
                >{{ $t("modal.keyword") }}:</label
              >
              <input
                id="foodName"
                type="text"
                class="form-control col-6 col-lg-4 col-xl-3"
                v-model="keyword"
              />
              <div class="col-3 col-sm-2 px-1">
                <button
                  class="btn btn-primary"
                  style="border-color: transparent"
                  @click="updateFoods"
                >
                  {{ $t("button.select") }}
                </button>
              </div>
            </div>
          </div>
        </div>
        <div class="row mb-2 px-3">
          <div class="form-inline">
            <label for="pageSize" class="mx-2">Show</label>
            <select
              class="form-control-sm"
              v-model="pagination.PageSize"
              @change="sizeChanged"
            >
              <option v-for="size in sizes" :key="size" :value="size">
                {{ size }}
              </option>
            </select>

            <label for="pageSize" class="mx-2">entries</label>
          </div>
        </div>
        <table-part
          :items="items"
          :columns="columns"
          :idProperty="idProperty"
          @currentIndexChanged="currentIndexChanged"
          :changeIndex="currentIndex"
        >
          <template slot="th-before">
            <th style="width: 100px">{{ $t("columnName.sequence") }}</th>
          </template>
          <template slot="td-before" slot-scope="props">
            <td v-if="currentIndex == props.index">
              <input
                type="tel"
                class="form-control"
                v-model="newSequence"
                @change="changeSequence(props.index)"
              />
            </td>
            <td v-else>{{ props.item.Sequence }}</td>
          </template>
        </table-part>
        <pagination-part
          style="margin-top: 10px"
          :pagination="pagination"
          @pageChanged="pageChanged"
        ></pagination-part>
      </div>
    </div>
  </div>
</template>

<script>
import tablePart from "@/components/parts/tablePart";
import paginationPart from "@/components/parts/paginationPart";

export default {
  data() {
    return {
      pagination: {
        CurrentPage: 1,
        PageNumber: 0,
        PageSize: 100,
        HasPrevious: false,
        HasNext: false,
        Keyword: "",
      },
      module: {},
      foodKinds: [],
      currentFoodKindId: "",
      items: [],
      columns: [{ name: "FoodNumber" }, { name: "FoodName" }],
      currentIndex: -1,
      idProperty: "FoodId",
      showFoods: [],
      keyword: "",
      searchCount: 0,
      results: [],
      sizes: [10, 25, 50, 100, 250, 500],
      newSequence: null,
    };
  },
  watch: {
    currentFoodKindId() {
      if (this.module.ModuleId) {
        this.updateFoods();
      }
    },
  },
  components: {
    tablePart,
    paginationPart,
  },
  methods: {
    changeSequence(index) {
      this.$api.foods
        .position({
          ModuleId: this.module.ModuleId,
          FoodId: this.items[index].FoodId,
          Position: parseInt(this.newSequence),
          PageSize: this.pagination.PageSize,
        })
        .then((data) => {
          this.$func.message.success("排序更新成功");
          this.items = data.Datas;
          this.pagination = data.Pagination;
          this.keyword = "";
          const newIndex = this.items.findIndex((item) => {
            return item.Sequence == this.newSequence;
          });
          this.currentIndexChanged(newIndex);
        })
        .catch((err) => {
          this.$func.message.error(err.data.Message);
        });
    },
    currentIndexChanged(index) {
      this.newSequence = this.items[index].Sequence;
      this.currentIndex = index;
    },
    updateFoods() {
      if (!this.module) return;
      if (!this.currentFoodKindId || this.currentFoodKindId.length == 0) return;
      if (this.keyword) {
        this.pagination.Keyword = this.keyword;
      }
      const vm = this;
      this.$api.foods
        .getSequencesByKind(vm.module.ModuleId, vm.currentFoodKindId, {
          Page: vm.pagination.CurrentPage,
          PageSize: vm.pagination.PageSize,
          Keyword: vm.pagination.Keyword,
        })
        .then((data) => {
          vm.items = data.Datas;
          vm.pagination = data.Pagination;
          vm.currentIndexChanged(0);
        });
    },
    getCurrentId() {
      if (this.currentIndex < 0) {
        this.$func.message.alert("請選擇商品 !");
        return undefined;
      }
      const food = this.items[this.currentIndex];
      if (!food) {
        return undefined;
      } else {
        return food.FoodId;
      }
    },
    onBefore() {
      const id = this.getCurrentId();
      if (!id) {
        return;
      }
      const vm = this;
      this.$api.foods
        .before(vm.module.ModuleId, id, vm.pagination.PageSize)
        .then((data) => {
          if (data.Pagination.CurrentPage < vm.pagination.CurrentPage) {
            vm.items = data.Datas;
            vm.currentIndexChanged(data.Pagination.PageSize - 1);
            vm.pagination = data.Pagination;
            return;
          } else {
            vm.currentIndexChanged(vm.currentIndex - 1);
          }
          vm.items = data.Datas;
          vm.pagination = data.Pagination;
        });
    },
    onAfter() {
      const id = this.getCurrentId();
      if (!id) {
        return;
      }
      const vm = this;
      this.$api.foods
        .after(vm.module.ModuleId, id, vm.pagination.PageSize)
        .then((data) => {
          if (data.Pagination.CurrentPage > vm.pagination.CurrentPage) {
            vm.items = data.Datas;
            vm.currentIndexChanged(0);
            vm.pagination = data.Pagination;
            return;
          } else {
            vm.currentIndexChanged(vm.currentIndex + 1);
          }
          vm.items = data.Datas;
          vm.pagination = data.Pagination;
        });
    },
    pageChanged(page) {
      this.pagination.CurrentPage = page;
      this.updateFoods();
    },
    sizeChanged() {
      this.pagination.CurrentPage = 1;
      this.updateFoods();
    },
  },
  created() {
    const vm = this;
    this.$api.modules.get(this.$route.params["id"]).then((data) => {
      if (!data) {
        vm.$func.message.alert("查無此模組 !");
        vm.$router.go(-1);
      }
      vm.module = data;
      vm.updateFoods();
    });
    this.$api.foodKinds.getNames().then((data) => {
      vm.foodKinds = data;
      if (vm.foodKinds.length > 0) {
        vm.currentFoodKindId = vm.foodKinds[0].FoodKindId;
      }
    });
  },
};
</script>

<style lang="scss">
.show-fixed-header {
  display: flex !important;
}
.custom-table {
  // height: 82%;
  border-top: 1px solid #dee2e6;
  thead {
    position: sticky;
    top: 0;
    background-color: white;
    th {
      border-top: 0;
    }
  }
}
</style>
