<template>
  <div>
    <condition-part
      :hasDate="true"
      :hasStore="true"
      :hasEmployee="true"
      :hasFoodKind="true"
      :hasFoodSupplier="true"
      :hasFood="true"
      :hasExcel="true"
      :hasPdf="true"
      :hasAdd="false"
      :hasEdit="false"
      :hasRemove="false"
      :columns="columns"
      :items="items"
      :pagination="pagination"
      :isSortable="true"
      :reportTypes="reportTypes"
      @getItems="getItems"
      @downloadExcel="downloadExcel"
      @downloadPdf="downloadPdf"
      @printPdf="printPdf"
    />
  </div>
</template>

<script>
import conditionPart from "@/components/parts/conditionPart";

export default {
  data() {
    return {
      items: [],
      reportTypes: ["detail", "statistic"],
      reportType: "detail",
      pagination: {},
    };
  },
  computed: {
    columns() {
      if (this.reportType == "detail") {
        return [
          { name: "WarehouseNumber" },
          { name: "WarehouseName" },
          { name: "InventoryNumber" },
          { name: "InventoryDate" },
          { name: "EmployeeNumber" },
          { name: "EmployeeName" },
          { name: "Memo" },
          { name: "FoodNumber" },
          { name: "FoodName" },
          { name: "BeforeStock" },
          { name: "Difference" },
          { name: "Cost" },
          { name: "Total" },
        ];
      } else if (this.reportType == "statistic") {
        return [
          { name: "FoodNumber" },
          { name: "FoodName" },
          { name: "Barcode" },
          { name: "SupplierNumber" },
          { name: "SupplierName" },
          { name: "StockQty" },
          { name: "InventoryQty" },
          { name: "Difference" },
          { name: "Cost" },
          { name: "TotalCost" },
          { name: "SalPrice" },
          { name: "Total" },
          { name: "DifferenceTotalCost" },
          { name: "DifferenceTotal" },
        ];
      } else {
        return undefined;
      }
    },
  },
  components: {
    conditionPart,
  },
  methods: {
    downloadExcel(condition) {
      this.$api.reportExcels.getInventoryPAndL(condition);
    },
    downloadPdf(condition) {
      this.$api.reportPdfs.getInventoryPAndL(condition);
    },
    printPdf(condition) {
      this.$api.reportPdfs.printInventoryPAndL(condition);
    },
    getItems(condition) {
      this.reportType = condition.ReportType;
      const vm = this;
      this.$api.reports.getInventoryPAndL(condition).then((data) => {
        vm.items = data.Datas;
        vm.pagination = data.Pagination;
      });
    },
  },
};
</script>
