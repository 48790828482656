<template>
  <item-modal
    :isShowModal="isShow"
    :title="modifierGroup.OnlineOrderModifierGroupId ? '編輯類別' : '新增類別'"
    @onSaved="save"
    @onClosed="close"
  >
    <div slot="item">
      <div class="row">
        <div class="col-12">
          <div class="form-group">
            <label for="modifierGroupName">類別名稱</label>
            <input
              id="modifierGroupName"
              type="text"
              class="form-control"
              v-model="modifierGroup.OnlineOrderModifierGroupName"
              required
            />
          </div>
        </div>
        <div class="col-md-6">
          <div class="form-group">
            <label for="minQty">最少</label>
            <input
              id="minQty"
              type="number"
              class="form-control"
              v-model="modifierGroup.MinQty"
              required
            />
          </div>
        </div>
        <div class="col-md-6">
          <div class="form-group">
            <label for="maxQty">最多</label>
            <input
              id="maxQty"
              type="number"
              class="form-control"
              v-model="modifierGroup.MaxQty"
              required
            />
          </div>
        </div>
      </div>
    </div>
  </item-modal>
</template>

<script>
import itemModal from "@/components/ItemModal";

export default {
  data() {
    return {
      modifierGroup: {},
    };
  },
  props: ["isShow", "entity", "onlineOrderType"],
  watch: {
    entity(val) {
      this.modifierGroup = val;
    },
    onlineOrderType(val) {
      this.modifierGroup.OnlineOrderType = val;
    },
  },
  components: {
    itemModal,
  },
  methods: {
    save() {
      if (this.modifierGroup.MaxQty < this.modifierGroup.MinQty) {
        this.$func.message.alert("最多選擇數量不得小於最少選擇數量 !");
        return;
      }
      const vm = this;
      this.$api.onlineOrderModifierGroups
        .post(vm.modifierGroup)
        .then(() => {
          vm.$emit("save");
        })
        .catch((error) => vm.$func.message.error(error.data.Message));
    },
    close() {
      this.$emit("close");
    },
  },
};
</script>
