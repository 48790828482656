import i18n from "@/langs/i18n";

export function getAll() {
  return [
    {
      value: 0,
      name: "NormalCost",
      text: `${i18n.t("option.costType.NormalCost")}`,
    },
    {
      value: 1,
      name: "AvgCost",
      text: `${i18n.t("option.costType.AvgCost")}`,
    },
    {
      value: 2,
      name: "LastCost",
      text: `${i18n.t("option.costType.LastCost")}`,
    },
  ];
}
