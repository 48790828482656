import { httpGet, httpPost, httpDelete } from "@/request/http";

let path = "/combinationtrades";

export default {
  getPages(dTO) {
    return httpGet(`${path}/pages`, dTO);
  },
  get(id) {
    return httpGet(`${path}/${id}`);
  },
  save(item) {
    return httpPost(path, item);
  },
  remove(id) {
    return httpDelete(`${path}/${id}`);
  }
};
