// http.js
import axios from "axios";
import router from "../router";
import store from "../store";
axios.defaults.timeout = 600000;
axios.defaults.baseURL = process.env.VUE_APP_API_URL;

// 请求拦截器
axios.interceptors.request.use(
  (config) => {
    let pathName = location.pathname;
    if (localStorage.token) {
      if (pathName != "/login") {
        config.headers.Authorization = `Bearer ${localStorage.getItem(
          "token"
        )}`;
      }
    }
    return config;
  },
  (error) => {
    return Promise.error(error);
  }
);

// 响应拦截器
axios.interceptors.response.use(
  (response) => {
    if (response.status === 200) {
      return Promise.resolve(response);
    } else {
      return Promise.reject(response);
    }
  },
  (error) => {
    if (error.response) {
      switch (error.response.status) {
        case 401:
          localStorage.removeItem("token");
          if (location.pathname != "/login") {
            router.replace({
              path: "/login",
            });
          }
      }
      return Promise.reject(error.response);
    }
    if (error.message) {
      return Promise.reject({ data: { Message: error.message } });
    } else return Promise.reject(error);
  }
);

export function httpGet(url, params, config) {
  if (config) config.params = params;
  else config = { params };
  return new Promise((resolve, reject) => {
    store.dispatch("updateLoading", true);
    axios
      .get(url, config)
      .then((response) => {
        store.dispatch("updateLoading", false);
        if (response != undefined && response.data != undefined)
          resolve(response.data);
        else resolve(response);
      })
      .catch((repsonse) => {
        store.dispatch("updateLoading", false);
        reject(repsonse);
      });
  });
}

export function httpPost(url, params, config) {
  return new Promise((resolve, reject) => {
    store.dispatch("updateLoading", true);
    axios
      .post(url, params, config)
      .then((response) => {
        store.dispatch("updateLoading", false);
        if (response != undefined && response.data != undefined)
          resolve(response.data);
        else resolve(response);
      })
      .catch((repsonse) => {
        store.dispatch("updateLoading", false);
        reject(repsonse);
      });
  });
}

export function httpPut(url, params) {
  return new Promise((resolve, reject) => {
    store.dispatch("updateLoading", true);
    axios
      .put(url, params)
      .then((response) => {
        store.dispatch("updateLoading", false);
        resolve(response.data);
      })
      .catch((repsonse) => {
        store.dispatch("updateLoading", false);
        reject(repsonse);
      });
  });
}

export function httpPatch(url, params) {
  return new Promise((resolve, reject) => {
    store.dispatch("updateLoading", true);
    axios
      .patch(url, params)
      .then((response) => {
        store.dispatch("updateLoading", false);
        if (response != undefined && response.data != undefined)
          resolve(response.data);
        else resolve(response);
      })
      .catch((repsonse) => {
        store.dispatch("updateLoading", false);
        reject(repsonse);
      });
  });
}

export function httpDelete(url, params) {
  return new Promise((resolve, reject) => {
    store.dispatch("updateLoading", true);
    -axios
      .delete(url, { params })
      .then((response) => {
        store.dispatch("updateLoading", false);
        if (response != undefined && response.data != undefined)
          resolve(response.data);
        else resolve(response);
      })
      .catch((repsonse) => {
        store.dispatch("updateLoading", false);
        reject(repsonse);
      });
  });
}
