import { httpGet, httpPost, httpDelete } from '@/request/http';

let path = '/membersettings';

export default{
    get(){
      return httpGet(path);
    },
    save(item){
      return httpPost(path , item);
    },
}
