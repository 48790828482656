<template>
  <div>
    <search
      :title="`${$t('modal.directory')}`"
      :items="directorys"
      idProperty="DirectoryId"
      nameProperty="DirectoryName"
      @triggerClick="triggerClick"
      @remove="remove"
      :searchItems="searchItems"
    />
    <directory-select
      :isMultiple="true"
      :trigger="trigger"
      :defaultItems="defaultItems"
      @onSaved="add"
    />
  </div>
</template>

<script>
import search from "./Search";
import directorySelect from "@/components/selects/DirectorySelects";

export default {
  data() {
    return {
      trigger: false,
      directorys: [],
      defaultItems: undefined,
    };
  },
  props: {
    searchItems: {
      type: Object,
      default: () => ({}),
    },
  },
  watch: {
    searchItems(value) {
      if (JSON.parse(sessionStorage.getItem("isSamePage")) && value.Directory) {
        this.directorys = value.Directory;
      }
    },
  },
  components: {
    search,
    directorySelect,
  },
  methods: {
    triggerClick() {
      this.defaultItems = [...this.directorys];
      this.trigger = !this.trigger;
    },
    add(items) {
      this.directorys = items;
      this.set();
    },
    remove(index) {
      if (!this.directorys[index]) return;
      this.directorys.splice(index, 1);
      this.set();
    },
    set() {
      this.$emit("set", this.directorys);
    },
  },
};
</script>
