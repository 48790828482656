export function getAll() {
  return [
    {
      value: 0,
      name: "AvgPrice",
      text: "平均單價",
    },
    {
      value: 1,
      name: "OriginalPrice",
      text: "原單價",
    },
  ];
}
