import { httpPost } from "@/request/http";

export default {
  clearSale(id) {
    return httpPost(`/businesses/${id}/initialization/sale`);
  },
  clearERP(id) {
    return httpPost(`/businesses/${id}/initialization/erp`);
  }
};
