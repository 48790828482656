<template>
  <div>
    <pageTable
      :parentPage="pagination"
      @getItems="getPays"
      @addItem="addPay"
      @editItem="editPay"
      @removeItem="removePay"
      @trdbClicked="editPay"
      :items="pays"
      :columns="columns"
      :idProperty="idProperty"
      :nameProperty="nameProperty"
      :getTrigger="getTrigger"
      @currentIndexChanged="currentIndexChanged"
      :changeIndex="currentIndex"
      :hasAdd="authority.IsAdd"
      :hasEdit="authority.IsUpdate"
      :hasRemove="authority.IsRemove"
      :isSortable="true"
    >
      <template slot="button">
        <button
          type="button"
          class="btn btn-outline-primary py-2 px-4"
          style="border-color: transparent"
          v-if="authority.IsUpdate"
          @click="onBefore"
        >
          <i class="fa fa-arrow-up"></i>
        </button>
        <button
          type="button"
          class="btn btn-outline-primary py-2 px-4"
          style="border-color: transparent"
          v-if="authority.IsUpdate"
          @click="onAfter"
        >
          <i class="fa fa-arrow-down"></i>
        </button>
      </template>
    </pageTable>

    <itemModal
      :isShowModal="isShowModal"
      @onClosed="isShowModal = !isShowModal"
      @onSaved="savePay"
      :title="modalTitle"
      modalClass="modal-lg"
    >
      <div slot="item" v-if="pay">
        <div class="row">
          <div class="col-md-6 col-lg-4">
            <div class="form-group">
              <label for="payType">{{ $t("modal.paytype") }}</label>
              <select
                class="form-control"
                id="payType"
                v-model="pay.PayType"
                required
                @change="nameDoubleClick"
              >
                <option
                  v-for="payType in payTypes"
                  :key="payType.value"
                  :value="payType.value"
                >
                  {{ payType.text }}
                </option>
              </select>
            </div>
          </div>
          <div class="col-md-6 col-lg-4">
            <div class="form-group">
              <label for="payName">{{ $t("modal.name") }}</label>
              <input
                type="text"
                class="form-control"
                id="payName"
                v-model="pay.PayName"
                required
              />
            </div>
          </div>
          <div class="col-md-6 col-lg-4" v-if="hasTotal && hasPrice">
            <div class="form-group">
              <label for="price">{{ $t("modal.price") }}</label>
              <input
                type="number"
                class="form-control"
                id="price"
                v-model.number="pay.Price"
                required
              />
            </div>
          </div>
          <div class="col-md-6 col-lg-4" v-if="productType == 0">
            <div class="form-group">
              <label for="fontSize">{{ $t("modal.fontsize") }}</label>
              <input
                type="number"
                class="form-control"
                id="fontSize"
                v-model.number="pay.FontSize"
                required
              />
            </div>
          </div>
          <div class="col-md-6 col-lg-4" v-if="productType == 0">
            <div class="form-group">
              <label for="foreColor">{{ $t("modal.forecolor") }}</label>
              <input
                type="color"
                class="form-control"
                id="foreColor"
                v-model="pay.ForeColor"
              />
            </div>
          </div>
          <div class="col-md-6 col-lg-4" v-if="productType == 0">
            <div class="form-group">
              <label for="backColor">{{ $t("modal.backcolor") }}</label>
              <input
                type="color"
                class="form-control"
                id="backColor"
                v-model="pay.BackColor"
              />
            </div>
          </div>
          <div class="col-md-6 col-lg-4" v-if="hasOverPayType">
            <div class="form-group">
              <label for="overPayType">{{ $t("modal.overpaytype") }}</label>
              <select
                class="form-control"
                id="overPayType"
                v-model="pay.OverPayType"
                required
              >
                <option
                  v-for="overPayType in overPayTypes"
                  :key="overPayType.value"
                  :value="overPayType.value"
                >
                  {{ overPayType.text }}
                </option>
              </select>
            </div>
          </div>
          <div class="col-md-6 col-lg-4">
            <div class="form-group">
              <label for="accountId">{{ $t("modal.account") }}</label>
              <select
                class="form-control"
                id="accountId"
                v-model="pay.AccountId"
              >
                <option
                  v-for="(account, index) in accountIds"
                  :key="index"
                  :value="account.AccountId"
                >
                  {{ account.AccountName }}
                </option>
              </select>
            </div>
          </div>
        </div>
      </div>
    </itemModal>
  </div>
</template>

<script>
import pageTable from "@/components/PageTable";
import itemModal from "@/components/ItemModal";

export default {
  data() {
    return {
      pays: [],
      pay: {},
      accountIds: [],
      pagination: {},
      isShowModal: false,
      modalTitle: "",
      payTypes: [],
      overPayTypes: [],
      columns: [
        {
          name: "PayType",
        },
        {
          name: "PayName",
        },
        {
          name: "Price",
          filter: "currency",
        },
        {
          name: "AccountName",
        },
      ],
      idProperty: "PayId",
      nameProperty: "PayName",
      getTrigger: false,
      currentIndex: -1,
      hasOverPayType: false,
      hasTotal: true,
      authority: {},
    };
  },
  components: {
    pageTable,
    itemModal,
  },
  computed: {
    productType() {
      return parseInt(window.localStorage["productType"]);
    },
    hasPrice() {
      return (
        window.localStorage["productType"] != 4 &&
        this.pay.PayType != 1 &&
        this.pay.PayType != 2 &&
        this.pay.PayType != 9 &&
        this.pay.PayType != 11 &&
        this.pay.PayType != 12 &&
        this.pay.PayType != 13
      );
    },
    currentLang() {
      return this.$store.state.currentLang;
    },
  },
  watch: {
    currentLang() {
      this.setOptions();
    },
    isShowModal(value) {
      if (value) {
        this.getAccountIds();
      }
    },
    "pay.PayType"(value) {
      if (this.productType != 4) {
        this.hasOverPayType = value == 4 || value == 5;
      }
      this.hasTotal = value != 10;
      if (
        value == 1 ||
        value == 2 ||
        value == 9 ||
        value == 11 ||
        value == 12 ||
        value == 13
      ) {
        this.pay.Price = 0;
      }
    },
    hasTotal(value) {
      if (value && window.localStorage["productType"] == 4)
        this.hasTotal = false;
    },
  },
  methods: {
    setOptions() {
      this.payTypes = this.$enum.payType.getForProductType(this.productType);
      if (
        this.productType != 0 ||
        window.localStorage["hasPGTalk"] == "false"
      ) {
        let PGTalk = this.payTypes.find((i) => i.value == 14);
        const index = this.payTypes.indexOf(PGTalk);
        if (index >= 0) this.payTypes.splice(index, 1);
      }
      if (window.localStorage["hasPXPay"] == "false") {
        let PXPay = this.payTypes.find((i) => i.value == 12);
        const index = this.payTypes.indexOf(PXPay);
        if (index >= 0) this.payTypes.splice(index, 1);
      }
      this.overPayTypes = this.$enum.overPayType.getAll();
    },
    currentIndexChanged(index) {
      this.currentIndex = index;
      this.pay = this.pays[index];
    },
    nameDoubleClick() {
      const vm = this;
      const payType = this.payTypes.find(function (item) {
        return item.value == vm.pay.PayType;
      });
      if (payType) this.pay.PayName = payType.text;
    },
    getPays(page, pageSize) {
      const vm = this;
      this.$api.pays.getPages(page, pageSize).then((data) => {
        vm.pays = data.Datas;
        vm.pagination = data.Pagination;
        if (vm.currentIndex >= 0) vm.pay = vm.pays[vm.currentIndex];
      });
    },
    addPay() {
      this.pay = this.$func.defaultModel.getPay();
      this.isShowModal = !this.isShowModal;
      this.modalTitle = this.$t("modal.addpay");
    },
    editPay(pay) {
      if (!this.authority.IsUpdate) {
        return;
      }
      const vm = this;
      this.$api.pays.get(pay.PayId).then((data) => {
        vm.pay = data;
        vm.isShowModal = !vm.isShowModal;
        vm.modalTitle = this.$t("modal.editpay");
      });
    },
    removePay(pay) {
      const vm = this;
      this.$api.pays.remove(pay.PayId).then(() => {
        vm.getTrigger = !vm.getTrigger;
      });
    },
    savePay() {
      const vm = this;
      this.$api.pays
        .save(vm.pay)
        .then(() => {
          vm.isShowModal = !vm.isShowModal;
          vm.getTrigger = !vm.getTrigger;
        })
        .catch((error) => vm.$func.message.error(error.data.Message));
    },
    onBefore() {
      if (!this.pay) {
        return;
      }
      const vm = this;
      this.$api.pays.before(this.pay.PayId).then(() => {
        vm.getTrigger = !vm.getTrigger;
        if (vm.currentIndex > 0) {
          vm.currentIndexChanged(vm.currentIndex - 1);
        }
      });
    },
    onAfter() {
      if (!this.pay) {
        return;
      }
      const vm = this;
      this.$api.pays.after(this.pay.PayId).then(() => {
        vm.getTrigger = !vm.getTrigger;
        if (vm.currentIndex < vm.pays.length) {
          vm.currentIndexChanged(vm.currentIndex + 1);
        }
      });
    },
    getAccountIds() {
      const vm = this;
      this.$api.accounts.getNames().then((data) => {
        vm.accountIds = data;
        vm.accountIds.splice(0, 0, {
          AccountId: null,
          AccountName: this.$t("modal.none"),
        });
      });
    },
  },
  mounted() {
    this.setOptions();
  },
  created() {
    this.authority = this.$func.localStorage.getAuthByName(this.$route.name);
  },
};
</script>
