export function getAll() {
  return [
    {
      value: 0,
      name: "None",
      text: "無",
    },
    {
      value: 1,
      name: "Catering",
      text: "餐飲業",
    },
    {
      value: 2,
      name: "Retail",
      text: "零售業",
    },
  ];
}
