<template>
  <div v-if="hasStore">
    <search
      :title="`${$t('modal.store')}`"
      :items="stores"
      idProperty="StoreId"
      nameProperty="StoreName"
      @triggerClick="triggerClick"
      @remove="remove"
      :searchItems="searchItems"
    />

    <store-select
      :isMultiple="true"
      :trigger="trigger"
      :defaultItems="defaultItems"
      @onSaved="add"
    />
  </div>
</template>

<script>
import search from "./Search";
import storeSelect from "@/components/selects/StoreSelects";

export default {
  data() {
    return {
      trigger: false,
      stores: [],
      defaultItems: undefined,
    };
  },
  computed: {
    hasStore() {
      return (
        this.$store.state.storeId == undefined ||
        this.$store.state.storeId == null
      );
    },
  },
  props: {
    searchItems: {
      type: Object,
      default: () => ({}),
    },
  },
  watch: {
    searchItems(value) {
      if (JSON.parse(sessionStorage.getItem("isSamePage")) && value.Store) {
        this.stores = value.Store;
      }
    },
  },
  components: {
    search,
    storeSelect,
  },
  methods: {
    triggerClick() {
      this.defaultItems = [...this.stores];
      this.trigger = !this.trigger;
    },
    add(items) {
      this.stores = items;
      this.set();
    },
    remove(index) {
      if (!this.stores[index]) return;
      this.stores.splice(index, 1);
      this.set();
    },
    set() {
      this.$emit("set", this.stores);
    },
  },
};
</script>
