// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import Vue from "vue";
import axios from "axios";
import VueAxios from "vue-axios";
import VCharts from "v-charts";
import Vuex from "vuex";
import * as VeeValidate from "vee-validate";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import "bootstrap";
import Print from "vue-print-nb";
import QRCode from "qrcode";
import JSZip from "jszip";
import { saveAs } from "file-saver";

Vue.use(VueAxios, axios);
Vue.use(Vuex);
Vue.use(VCharts);
Vue.use(Print);
Vue.use(QRCode);
Vue.use(JSZip);

//font
import "@fortawesome/fontawesome-free/css/all.css";
import "@fortawesome/fontawesome-free/js/all.js";

import App from "./App";
import router from "./router";

import store from "./store";
import currencyFilter from "./filters/currency";
import zhTW from "vee-validate/dist/locale/zh_TW";

import enums from "@/enums";
import apis from "@/request/api";
import funcs from "@/funcs";

import VueSweetalert2 from "vue-sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";

import i18n from "@/langs/i18n";

// import VConsole from "vconsole";

// const vConsole = new VConsole();

Vue.use(VueSweetalert2);

Vue.prototype.$enum = enums;
Vue.prototype.$api = apis;
Vue.prototype.$func = funcs;
Vue.prototype.$fileSaver = saveAs;

Vue.component("Loading", Loading);
Vue.filter("currency", currencyFilter);

Vue.directive("focus", {
  inserted(el) {
    el.focus();
  },
});

Vue.use(VeeValidate, {
  i18n,
  dictionary: {
    zhTW,
  },
});

// axios.defaults.withCredentials = true;

Vue.config.productionTip = false;

Vue.config.devtools = true;

/* eslint-disable no-new */
new Vue({
  // vConsole,
  i18n,
  el: "#app",
  components: { App },
  template: "<App/>",
  router,
  store,
  render: (h) => h(App),
});

// router.beforeEach((to, from, next) => {
//   if (!localStorage.token) {
//     next("/login");
//   } else {
//     next();
//   }
// });
