<template>
  <div>
    <condition-part
      :hasDate="true"
      :hasSupplier="true"
      :hasExcel="true"
      :hasPdf="true"
      :hasAdd="false"
      :hasEdit="false"
      :hasRemove="false"
      :isPurchaseOrder="true"
      :columns="columns"
      :items="items"
      :pagination="pagination"
      :isSortable="true"
      @getItems="getItems"
      @downloadExcel="downloadExcel"
      @downloadPdf="downloadPdf"
      @printPdf="printPdf"
    />
  </div>
</template>

<script>
import conditionPart from "@/components/parts/conditionPart";

export default {
  data() {
    return {
      items: [],
      pagination: {},
      reportType: "",
      columns: [
        { name: "SupplierNumber" },
        { name: "SupplierName" },
        { name: "PurchaseDate" },
        { name: "PurchaseNumber" },
        { name: "BuyerNumber" },
        { name: "BuyerName" },
        { name: "FoodNumber" },
        { name: "FoodName" },
        { name: "Qty" },
        { name: "SalPrice" },
        { name: "Total" },
        { name: "TaxPrice" },
      ],
    };
  },
  components: {
    conditionPart,
  },
  methods: {
    downloadExcel(condition) {
      this.$api.reportExcels.getSupplierPurchaseOrder(condition);
    },
    downloadPdf(condition) {
      this.$api.reportPdfs.getSupplierPurchaseOrder(condition);
    },
    printPdf(condition) {
      this.$api.reportPdfs.printSupplierPurchaseOrder(condition);
    },
    getItems(condition) {
      const vm = this;
      this.$api.reports.getSupplierPurchaseOrder(condition).then((data) => {
        vm.items = data.Datas;
        vm.pagination = data.Pagination;
      });
    },
  },
};
</script>
