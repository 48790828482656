//timer
//position: 'top', 'top-start', 'top-end', 'center', 'center-start', 'center-end', 'bottom', 'bottom-start', or 'bottom-end'
//title *html
//titleText
//html
//text
//footer *html or text
//showConfirmButton  defualt true
//showDenyButton default false
//showCancelButton default false
//confirmButtonText default 'OK'
//denyButtonText default 'No'
//cancelButtonText default 'Cancel'
//confirmButtonColor
//denyButtonColor
//cancelButtonColor
import Vue from "vue";

export function alert(message) {
  let text = message;
  Vue.swal({
    confirmButtonColor: "rgb(22, 65, 143)",
    confirmButtonText: "確定",
    cancelButtonColor: "#727272",
    cancelButtonText: "取消",
    title: text,
    icon: "warning",
  });
}

export function importCheck(firstMessage, secondMessage) {
  let title = firstMessage;
  let text = secondMessage;
  return Vue.swal({
    confirmButtonColor: "rgb(22, 65, 143)",
    confirmButtonText: "確定",
    denyButtonColor: "#dc3741",
    denyButtonText: "取消",
    showDenyButton: true,
    title: title,
    text: text,
    icon: "question",
  });
}

export function confirm(firstMessage, secondMessage) {
  let title = firstMessage;
  let text = secondMessage;
  return Vue.swal({
    confirmButtonColor: "rgb(22, 65, 143)",
    confirmButtonText: "是",
    denyButtonColor: "#dc3741",
    denyButtonText: "否",
    showDenyButton: true,
    title: title,
    text: text,
    icon: "question",
  });
}
export function emphasizeConfirm(firstMessage, secondMessage) {
  let title = firstMessage;
  let text = secondMessage;
  return Vue.swal({
    confirmButtonColor: "rgb(22, 65, 143)",
    confirmButtonText: "是",
    denyButtonColor: "#dc3741",
    denyButtonText: "否",
    showDenyButton: true,
    title: title,
    text: text,
    icon: "warning",
  });
}

export function success(firstMessage, secondMessage) {
  let title = firstMessage;
  let text = secondMessage;
  Vue.swal({
    confirmButtonColor: "rgb(22, 65, 143)",
    confirmButtonText: "確定",
    cancelButtonColor: "#727272",
    cancelButtonText: "取消",
    title: title,
    text: text,
    icon: "success",
  });
}

export function apiKeySuccess(firstMessage, secondMessage, data) {
  let title = firstMessage;
  let text = secondMessage;
  let key = data;
  Vue.swal({
    confirmButtonColor: "rgb(22, 65, 143)",
    confirmButtonText: "確定",
    denyButtonColor: "#727272",
    denyButtonText: "複製",
    showDenyButton: true,
    title: title,
    text: text,
    icon: "success",
  }).then((result) => {
    if (result.isConfirmed) {
      navigator.clipboard.writeText(key);
    } else if (result.isDenied) {
      navigator.clipboard.writeText(key).then(() => {
        Vue.swal({
          confirmButtonColor: "rgb(22, 65, 143)",
          title: "已複製 Api Key",
        });
      });
    }
  });
}

export function error(message) {
  let text = message;
  Vue.swal({
    confirmButtonColor: "rgb(22, 65, 143)",
    confirmButtonText: "確定",
    cancelButtonColor: "#727272",
    cancelButtonText: "取消",
    title: text,
    icon: "error",
  });
}
