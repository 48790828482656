import { httpGet, httpPost, httpDelete } from "@/request/http";
import func from "@/funcs";

let path = "/suppliers";

export default {
  get(id) {
    return httpGet(`${path}/${id}`);
  },
  getByNumber(number) {
    return httpGet(`${path}/number/${number}`);
  },
  getName(id) {
    return httpGet(`${path}/${id}/name`);
  },
  getNames() {
    return httpGet(`${path}/names`);
  },
  getPages(dTO) {
    return httpGet(`${path}/pages`, dTO);
  },
  save(item) {
    return httpPost(path, item);
  },
  remove(id) {
    return httpDelete(`${path}/${id}`);
  },
  getExcel() {
    func.getExcel.get(`${path}/excel`, "廠商");
  },
  postExcel(formData) {
    return func.postExcel(`${path}/excel`, formData, 600000);
  },
  getFoods(supplierId, foodKindId) {
    return httpGet(`${path}/${supplierId}/foodkinds/${foodKindId}/foods`);
  },
  postFoods(dTO) {
    return httpPost(`${path}/foods`, dTO);
  },
  getCasio() {
    func.getTXT(`/casio/suppliers`, "supplier");
  },
};
