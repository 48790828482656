<template>
  <form class="card" @submit.prevent="onSaved">
    <div class="card-header">
      <button
        type="button"
        class="btn btn-outline-primary btn-icon"
        @click="onSaved"
      >
        <i class="fas fa-check"></i>
      </button>
      <button
        type="button"
        class="btn btn-outline-danger btn-icon"
        @click="onClosed"
      >
        <i class="fas fa-times"></i>
      </button>
      <import-part @onImport="onImport" :title="`${$t('modal.importfood')}`" />
      <export-part @onExport="onExport" />
    </div>
    <div class="card-body">
      <div class="row">
        <input-part id="stockAdjustDate" :labelText="`${$t('modal.date')}:`">
          <input
            type="date"
            class="form-control"
            id="stockAdjustDate"
            v-model="stockAdjust.StockAdjustDate"
            required
          />
        </input-part>
        <input-part
          id="stockAdjustNumber"
          :labelText="`${$t('modal.stockadjustnumber')}:`"
        >
          <input
            type="text"
            class="form-control"
            id="stockAdjustNumber"
            v-model="stockAdjust.StockAdjustNumber"
            readonly
          />
        </input-part>
        <input-part
          id="stockAdjustType"
          :labelText="`${$t('modal.stockadjusttype')}:`"
        >
          <select
            class="form-control"
            id="stockAdjustType"
            v-model="stockAdjust.StockAdjustType"
            required
          >
            <option
              v-for="stockAdjustType in stockAdjustTypes"
              :key="stockAdjustType.value"
              :value="stockAdjustType.value"
            >
              {{ stockAdjustType.text }}
            </option>
          </select>
        </input-part>
        <input-part id="directory" :labelText="`${$t('modal.directory')}:`">
          <input
            type="text"
            class="form-control"
            id="directory"
            readonly
            :placeholder="`${$t('placeholder.dbselectdirectory')}`"
            :value="stockAdjust.DirectoryName"
            @dblclick="directoryModalTrigger = !directoryModalTrigger"
          />
        </input-part>
        <input-part
          id="warehouse"
          :labelText="`*${$t('modal.defaultwarehouse')}:`"
        >
          <div class="row px-3">
            <input
              type="text"
              class="form-control col-12 col-md-6"
              id="warehouseNumber"
              :placeholder="`${$t('placeholder.inputwarehousenumberordb')}`"
              v-model="addWarehouseNumber"
              @keyup.enter.exact="selectWarehouseByKeyword"
              @dblclick="warehouseModalTrigger = !warehouseModalTrigger"
            />
            <input
              type="text"
              class="form-control col-12 col-md-6"
              id="warehouseName"
              readonly
              :value="warehouse.WarehouseName"
            />
          </div>
        </input-part>
        <input-part id="employeeName" :labelText="`${$t('modal.writername')}:`">
          <input
            type="text"
            readonly
            class="form-control"
            id="employeeName"
            :value="employeeName"
          />
        </input-part>
        <input-part id="memo" :labelText="`${$t('modal.memo')}:`" :degree="12">
          <input
            type="text"
            class="form-control"
            id="memo"
            v-model="stockAdjust.Memo"
          />
        </input-part>
      </div>
      <div class="col-12 pl-0">
        <table-part :columns="columns" :noHide="true">
          <template slot="th-before">
            <th style="width: 80px">{{ $t("columnName.remove") }}</th>
            <th style="width: 80px">{{ $t("columnName.index") }}</th>
          </template>
          <tbody slot="tbody">
            <tr
              v-for="(det, index) in stockAdjustDets"
              :key="det.StockAdjustDetId"
            >
              <td>
                <button
                  type="button"
                  class="btn btn-outline-danger btn-icon"
                  @click="removeDet(index)"
                >
                  <i class="fas fa-trash-alt"></i>
                </button>
              </td>
              <td>{{ index + 1 }}</td>
              <td>{{ det.FoodNumber }}</td>
              <td>{{ det.FoodName }}</td>
              <td>
                <input
                  class="form-control"
                  type="text"
                  v-model="det.WarehouseNumber"
                  @change="updateWarehouse(det)"
                  required
                />
              </td>
              <td>{{ det.WarehouseName }}</td>
              <td>
                <input
                  class="form-control text-right"
                  type="number"
                  v-model.number="det.Qty"
                  @blur="setValue(det)"
                  :readonly="
                    stockAdjust.StockAdjustType != 0 &&
                    stockAdjust.StockAdjustType != 3
                  "
                />
              </td>

              <td>
                <input
                  class="form-control text-right"
                  type="number"
                  v-model.number="det.Cost"
                  :readonly="
                    stockAdjust.StockAdjustType != 1 &&
                    stockAdjust.StockAdjustType != 3
                  "
                />
              </td>
              <td>
                <input
                  class="form-control text-right"
                  type="number"
                  v-model.number="det.Total"
                  :readonly="stockAdjust.StockAdjustType != 2"
                />
              </td>
              <td v-if="hasBatchNumber">
                <input
                  class="form-control"
                  type="text"
                  v-model="det.BatchNumber"
                  :readonly="!det.HasBatchNumber"
                  @dblclick="openBatchSelector(det, index)"
                />
              </td>
              <td>
                <input class="form-control" type="text" v-model="det.Memo" />
              </td>
            </tr>
            <tr>
              <td colspan="10">
                <input
                  class="form-control"
                  type="text"
                  :placeholder="`${$t('placeholder.inputfoodnumberorbarcode')}`"
                  v-model="addFoodNumber"
                  @keyup.enter.exact="selectFoodByKeyword"
                  @dblclick="foodModalTrigger = !foodModalTrigger"
                />
              </td>
            </tr>
            <tr>
              <td>{{ $t("columnName.total") }}</td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td class="text-right">{{ totalQty }}</td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
            </tr>
          </tbody>
        </table-part>
      </div>
      <food-selects
        :trigger="foodModalTrigger"
        :isMultiple="true"
        @onSaved="addFoods"
        :parentItems="foodsByKeyword"
        :noSearchType="!hasFoodSearchType"
      />
      <warehouse-selects
        :trigger="warehouseModalTrigger"
        :isMultiple="false"
        @onSaved="setDefaultWarehouse"
      ></warehouse-selects>
      <directory-selects
        :trigger="directoryModalTrigger"
        :isMultiple="false"
        @onSaved="setDirectory"
      ></directory-selects>
      <batch-selects
        :trigger="batchModalTrigger"
        :isMultiple="false"
        :warehouseId="currentWarehouseId"
        :foodId="currentFoodId"
        @onSaved="setBatchNumber"
      />
    </div>
  </form>
</template>

<script>
import inputPart from "@/components/parts/inputPart";

import importPart from "@/components/ImportPart";
import exportPart from "@/components/ExportPart";

import tablePart from "@/components/parts/tablePart";

import foodSelects from "@/components/selects/FoodSelects";
import warehouseSelects from "@/components/selects/WarehouseSelects";
import directorySelects from "@/components/selects/DirectorySelects";
import batchSelects from "@/components/selects/BatchSelects";

export default {
  data() {
    return {
      stockAdjust: {
        EmployeeId: window.localStorage["userId"],
        StockAdjustDate: this.$func.getDate(),
        StockAdjustType: 0,
      },
      stockAdjustDets: [],
      employeeName: window.localStorage["userName"],
      addFoodNumber: "",
      addWarehouseNumber: "",
      foodModalTrigger: false,
      warehouseModalTrigger: false,
      directoryModalTrigger: false,
      batchModalTrigger: false,
      foodsByKeyword: [],
      hasFoodSearchType: true,
      stockAdjustTypes: this.$enum.stockAdjustType.getAll(),
      warehouse: {
        WarehouseName: null,
      },
      currentWarehouseId: "",
      currentFoodId: "",
      currentFoodIndex: null,
    };
  },
  computed: {
    hasBatchNumber() {
      return window.localStorage["hasBatchNumber"] == "true";
    },
    totalQty() {
      if (this.stockAdjustDets.length > 0)
        return this.stockAdjustDets
          .map((item) => {
            return item.Qty;
          })
          .reduce((t, q) => {
            if (!t) {
              t = 0;
            }
            if (!q) {
              q = 0;
            }
            return parseInt(t) + parseInt(q);
          });
      else return 0;
    },
    columns() {
      let columns = [
        { name: "FoodNumber" },
        { name: "FoodName" },
        { name: "WarehouseNumber" },
        { name: "WarehouseName" },
        { name: "Qty" },
        { name: "Cost" },
        { name: "Total" },
        { name: "Memo" },
      ];
      if (this.hasBatchNumber) {
        columns.splice(-1, 0, { name: "BatchNumber" });
      }
      return columns;
    },
  },
  components: {
    inputPart,
    foodSelects,
    warehouseSelects,
    directorySelects,
    batchSelects,
    importPart,
    exportPart,
    tablePart,
  },
  watch: {
    "stockAdjust.StockAdjustType"(val) {
      this.stockAdjustDets.forEach((det) => {
        switch (val) {
          case 0:
            det.Cost = undefined;
            det.Total = undefined;
            break;
          case 1:
            det.Qty = undefined;
            det.Total = undefined;
            break;
          case 2:
            det.Qty = undefined;
            det.Cost = undefined;
            break;
          case 3:
            det.Total = undefined;
            break;
        }
      });
    },
  },
  methods: {
    setValue(det) {
      if (
        isNaN(det.Qty) ||
        det.Qty === "" ||
        det.Qty === null ||
        det.Qty == 0
      ) {
        this.$func.message.alert("數量不可為0 !");
        det.Qty = 1;
      }
    },
    selectWarehouseByKeyword() {
      if (!this.addWarehouseNumber || this.addWarehouseNumber.length == 0) {
        this.$func.message.alert("請輸入倉庫編號");
        return;
      }
      const vm = this;
      this.$api.warehouses
        .getByNumber(this.addWarehouseNumber)
        .then((data) => {
          if (data) {
            vm.setDefaultWarehouse(data);
          }
        })
        .catch(() => {
          this.$func.message.error("查無此倉庫 !");
          this.setDefaultWarehouse({
            WarehouseId: null,
            WarehouseNumber: null,
            WarehouseName: null,
          });
        });
    },
    setBatchNumber(item) {
      this.stockAdjustDets[this.currentFoodIndex].BatchNumber =
        item.BatchNumber;
    },
    openBatchSelector(det, index) {
      if (!det.HasBatchNumber) {
        return;
      }
      this.currentWarehouseId = det.WarehouseId;
      this.currentFoodId = det.FoodId;
      this.currentFoodIndex = index;
      this.batchModalTrigger = !this.batchModalTrigger;
    },
    showFoodSelect() {
      this.foodsByKeyword = undefined;
      this.hasFoodSearchType = true;
      this.foodModalTrigger = !this.foodModalTrigger;
    },
    addDet(item) {
      this.addFoodNumber = "";
      let det = {
        FoodId: item.FoodId,
        FoodNumber: item.FoodNumber,
        FoodName: item.FoodName,
        WarehouseName: "",
        HasBatchNumber: item.HasBatchNumber,
        BatchNumber: null,
      };
      if (this.warehouse.WarehouseName) {
        det.WarehouseId = this.warehouse.WarehouseId;
        det.WarehouseNumber = this.warehouse.WarehouseNumber;
        det.WarehouseName = this.warehouse.WarehouseName;
      }

      this.stockAdjustDets.push(det);
    },
    selectFoodByKeyword() {
      if (!this.addFoodNumber || this.addFoodNumber.length == 0) {
        this.$func.message.alert("請輸入商品編號或條碼");
        return;
      }
      const vm = this;
      this.$api.foods
        .getByKeyword({ IsMatterStock: true, Keyword: this.addFoodNumber })
        .then((data) => {
          if (data) {
            if (data.length > 1) {
              vm.foodsByKeyword = data;
              vm.hasFoodSearchType = false;
              vm.foodModalTrigger = !vm.foodModalTrigger;
            } else {
              vm.addDet(data[0]);
            }
          }
        })
        .catch(() => this.$func.message.error("查無此商品 !"));
    },
    removeDet(index) {
      this.stockAdjustDets.splice(index, 1);
    },
    onSaved() {
      this.stockAdjust.WarehouseId = this.warehouse.WarehouseId;
      if (
        !this.stockAdjust.WarehouseId ||
        this.stockAdjust.WarehouseId.length == 0
      ) {
        this.$func.message.alert("請選擇倉庫 !");
        return;
      }
      if (this.stockAdjustDets.length == 0) {
        this.$func.message.alert("請選擇商品 !");
        return;
      }

      const checkWarehouseId = this.stockAdjustDets.some((det) => {
        return !det.WarehouseId;
      });
      if (checkWarehouseId) {
        this.$func.message.alert("請選擇倉庫 !");
        return;
      }
      if (this.stockAdjust.StockAdjustType == 0) {
        if (this.stockAdjustDets.some((det) => !det.Qty)) {
          this.$func.message.alert("商品數量為必填 !");
          return;
        }
      }
      if (this.stockAdjust.StockAdjustType == 1) {
        if (this.stockAdjustDets.some((det) => !det.Cost)) {
          this.$func.message.alert("商品成本為必填 !");
          return;
        }
      }
      if (this.stockAdjust.StockAdjustType == 2) {
        if (this.stockAdjustDets.some((det) => !det.TotalCost)) {
          this.$func.message.alert("商品小計為必填 !");
          return;
        }
      }
      if (this.stockAdjust.StockAdjustType == 3) {
        if (this.stockAdjustDets.some((det) => !det.Qty || !det.Cost)) {
          this.$func.message.alert("商品數量及成本為必填 !");
          return;
        }
      }

      let emptyBatch = this.stockAdjustDets.some((det) => {
        return det.HasBatchNumber && !det.BatchNumber;
      });
      if (emptyBatch) {
        this.$func.message.alert("請設定商品批號 !");
        return;
      }

      this.stockAdjust.EmployeeId = window.localStorage["userId"];
      const vm = this;
      this.$api.stockAdjusts
        .post({
          StockAdjust: vm.stockAdjust,
          StockAdjustDets: vm.stockAdjustDets,
        })
        .then((data) => {
          if (vm.$route.name == "StockAdjustAdd") {
            vm.$func.message.success(
              `單號為${data.StockAdjust.StockAdjustNumber}`
            );
          }
          this.$store.dispatch("setSaveOrClose", true);
          this.$router.push({ name: "StockAdjusts" });
        })
        .catch((error) => {
          vm.$func.message.error(error.data.Message);
        });
    },
    onClosed() {
      this.$store.dispatch("setSaveOrClose", true);
      this.$router.go(-1);
    },
    updateWarehouse(det) {
      this.$api.warehouses
        .getByNumber(det.WarehouseNumber)
        .then((data) => {
          if (data) {
            this.setWarehouse(det, data);
          } else {
            this.setWarehouse(det, undefined);
          }
        })
        .catch(() => {
          this.$func.message.alert("查無此資料 !");
          this.setWarehouse(det, undefined);
        });
    },
    setWarehouse(det, warehouse) {
      if (warehouse) {
        det.WarehouseId = warehouse.WarehouseId;
        det.WarehouseNumber = warehouse.WarehouseNumber;
        det.WarehouseName = warehouse.WarehouseName;
      } else {
        det.WarehouseId = undefined;
        det.WarehouseNumber = undefined;
        det.WarehouseName = undefined;
      }
    },
    setDefaultWarehouse(item) {
      this.addWarehouseNumber = item.WarehouseNumber;
      this.warehouse.WarehouseId = item.WarehouseId;
      this.warehouse.WarehouseNumber = item.WarehouseNumber;
      this.warehouse.WarehouseName = item.WarehouseName;
    },
    setDirectory(item) {
      this.stockAdjust.DirectoryId = item.DirectoryId;
      this.stockAdjust.DirectoryName = item.DirectoryName;
    },
    addFoods(items) {
      const vm = this;
      items.forEach(function (item) {
        vm.addDet(item);
      });
    },
    onImport(file) {
      let formData = new FormData();
      formData.append("file", file);
      const vm = this;
      this.$api.stockAdjusts.postExcel(formData).then((data) => {
        if (data) {
          data.Items.forEach((item) => {
            vm.pushItem(item);
          });
        }
      });
    },
    onExport() {
      this.$api.stockAdjusts.getExcelByPost(this.stockAdjustDets);
    },
    pushItem(item) {
      let exist = this.stockAdjustDets.find((i) => i.FoodId == item.FoodId);
      if (exist) {
        const index = this.stockAdjustDets.indexOf(exist);
        if (index > -1) {
          this.stockAdjustDets.splice(index, 1);
        }
      }
      this.stockAdjustDets.push(item);
    },
  },
  beforeRouteLeave(to, from, next) {
    const isSaveOrClose = this.$store.state.isSaveOrClose;
    if (isSaveOrClose) {
      next();
    } else {
      const result = confirm("資料尚未儲存，確定離開當前頁面嗎 ?");
      if (result) {
        next();
      } else {
        next(false);
      }
    }
  },
  mounted() {
    const id = this.$route.params["id"];
    const vm = this;
    if (id) {
      this.$api.stockAdjusts
        .get(id)
        .then((data) => {
          vm.stockAdjust = data.StockAdjust;
          vm.stockAdjustDets = data.StockAdjustDets;
        })
        .catch(() => {
          vm.$func.message.alert("查無此筆單據 !");
          vm.$router.go(-1);
        });
    }
    this.$api.employees.get(window.localStorage["userId"]).then((data) => {
      if (data.DefaultWarehouseNumber) {
        this.addWarehouseNumber = data.DefaultWarehouseNumber;
        this.warehouse.WarehouseId = data.DefaultWarehouseId;
        this.warehouse.WarehouseNumber = data.DefaultWarehouseNumber;
        this.warehouse.WarehouseName = data.DefaultWarehouseName;
      }
    });
  },
};
</script>
