<template>
  <div :class="[isMultiple ? 'col-12 col-md-4' : '']">
    <div class="card card-table">
      <div class="card-header">
        <slot name="dataButtonAreaFront" :index="currentIndex"></slot>
        <button
          v-if="hasAdd"
          type="button"
          class="btn btn-outline-primary btn-icon"
          @click="addItem"
        >
          <i class="fa fa-plus"></i>
        </button>
        <button
          v-if="hasEdit"
          type="button"
          class="btn btn-outline-success btn-icon"
          @click="editItem"
        >
          <i class="fas fa-pen"></i>
        </button>
        <button
          v-if="hasRemove"
          type="button"
          class="btn btn-outline-danger btn-icon"
          @click="removeItem"
        >
          <i class="fas fa-trash-alt"></i>
        </button>
        <slot name="dataButtonArea" :index="currentIndex"></slot>
      </div>
      <table-page-part
        class="card-body card-table-body"
        :pagination="pagination"
        :columns="columns"
        :items="items"
        :idProperty="idProperty"
        :nameProperty="nameProperty"
        :getTrigger="getTrigger"
        :searchTypes="searchTypes"
        @getItems="getItems"
        @currentIndexChanged="currentIndexChanged"
        @trdbClicked="trdbClicked"
        :isSortable="isSortable"
      >
        <template slot="th-before">
          <slot name="th-before" />
        </template>
        <template slot="th-after">
          <slot name="th-after" />
        </template>
        <template slot="td-before" slot-scope="props">
          <slot name="td-before" :item="props.item" />
        </template>
        <template slot="td-after" slot-scope="props">
          <slot name="td-after" :item="props.item" />
        </template>
      </table-page-part>
    </div>
  </div>
</template>

<script>
import tablePagePart from "@/components/parts/tablePagePart";

export default {
  data() {
    return {
      currentIndex: -1,
      pageDTO: {},
    };
  },
  props: {
    pagination: Object,
    hasAdd: {
      type: Boolean,
      default: true,
    },
    hasEdit: {
      type: Boolean,
      default: true,
    },
    hasRemove: {
      type: Boolean,
      default: true,
    },
    items: Array,
    columns: Array,
    idProperty: String,
    nameProperty: String,
    getTrigger: {
      type: Boolean,
      default: false,
    },
    searchTypes: Array,
    isSortable: {
      type: Boolean,
      default: false,
    },
    isMultiple: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    tablePagePart,
  },
  computed: {
    getCurrentItem() {
      return this.items[this.currentIndex];
    },
  },
  watch: {
    items(value) {
      if (value.length == 0 && this.pageDTO.page > 1) {
        let newDTO = this.pageDTO;
        newDTO.page -= 1;
        this.$emit("getItems", newDTO);
      }
    },
  },
  methods: {
    checkItemDetail() {
      this.$emit("");
    },
    getItems(dTO) {
      this.pageDTO = dTO;
      this.$emit("getItems", this.pageDTO);
    },
    addItem() {
      this.$emit("addItem");
    },
    editItem() {
      let item = this.checkItem();
      if (item) {
        this.$emit("editItem", item);
      }
    },
    removeItem() {
      let item = this.checkItem();
      if (item) {
        const vm = this;

        this.$func.message
          .confirm(`確定刪除「${item[this.nameProperty]}」嗎 ?`)
          .then((result) => {
            if (result.isConfirmed) {
              vm.$emit("removeItem", item);
            }
          });
      }
    },
    checkItem() {
      let item = this.items[this.currentIndex];
      if (item) {
        return item;
      } else {
        this.$func.message.alert("請選擇一筆資料 !");
        return undefined;
      }
    },
    currentIndexChanged(index) {
      this.currentIndex = index;
      this.$emit("currentIndexChanged", index);
    },
    trdbClicked(item) {
      this.$emit("trdbClicked", item);
    },
  },
};
</script>
