<template>
  <div>
    <pageTable
      :hasPage="false"
      :items="items"
      :columns="columns"
      :idProperty="idProperty"
      :nameProperty="nameProperty"
      :hasEdit="false"
      @getItems="getFunctions"
      @addItem="addFunction"
      @removeItem="removeFunction"
      :getTrigger="getTrigger"
      :isSortable="true"
    >
    </pageTable>

    <itemModal
      :isShowModal="isShowModal"
      @onClosed="isShowModal = !isShowModal"
      @onSaved="saveFunction"
      :title="modalTitle"
    >
      <div slot="item">
        <div class="row">
          <div class="col-md-6">
            <div class="form-group">
              <label for="functionName">{{ $t("modal.name") }}</label>
              <input
                type="text"
                class="form-control"
                id="functionName"
                v-model="item.FunctionName"
                required
              />
            </div>
            <div class="form-group">
              <label for="value">{{ $t("modal.value") }}</label>
              <input
                type="number"
                class="form-control"
                id="value"
                v-model.number="item.Value"
              />
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label for="type">{{ $t("modal.functiontype") }}</label>
              <select
                class="form-control"
                v-model="item.FunctionType"
                id="type"
              >
                <option
                  v-for="functionType in functionTypes"
                  :key="functionType.value"
                  :value="functionType.value"
                >
                  {{ functionType.text }}
                </option>
              </select>
            </div>
          </div>
        </div>
      </div>
    </itemModal>
  </div>
</template>

<script>
import pageTable from "@/components/PageTable";
import itemModal from "@/components/ItemModal";

export default {
  data() {
    return {
      functionTypes: [],
      items: [],
      item: {},
      isShowModal: false,
      modalTitle: "",
      columns: [
        {
          name: "FunctionName",
        },
        {
          name: "Value",
          class: "text-right",
        },
      ],
      idProperty: "FunctionId",
      nameProperty: "FunctionName",
      getTrigger: false,
    };
  },
  components: {
    pageTable,
    itemModal,
  },
  computed: {
    currentLang() {
      return this.$store.state.currentLang;
    },
  },
  watch: {
    currentLang() {
      this.setOptions();
    },
  },
  methods: {
    setOptions() {
      if (window.localStorage["productType"] == 2) {
        this.functionTypes = [
          {
            value: 60,
            text: this.$t("modal.totaldiscount"),
          },
          {
            value: 53,
            text: this.$t("modal.totaladdition"),
          },
          {
            value: 61,
            text: this.$t("modal.totalallowance"),
          },
        ];
      } else {
        this.functionTypes = [
          {
            value: 36,
            text: this.$t("modal.singlediscount"),
          },
          {
            value: 60,
            text: this.$t("modal.totaldiscount"),
          },
          {
            value: 64,
            text: this.$t("modal.singleaddition"),
          },
          {
            value: 53,
            text: this.$t("modal.totaladdition"),
          },
          {
            value: 70,
            text: this.$t("modal.singleallowance"),
          },
          {
            value: 61,
            text: this.$t("modal.totalallowance"),
          },
          {
            value: 73,
            text: this.$t("modal.parttotaldiscount"),
          },
        ];
      }
    },
    getFunctions() {
      const vm = this;
      this.$api.functions.getAll().then((data) => {
        vm.items = data;
      });
    },
    addFunction() {
      this.item = {};
      this.isShowModal = !this.isShowModal;
      this.modalTitle = this.$t("modal.addfunction");
    },
    removeFunction(item) {
      if (!item.CanDelete) {
        this.$func.message.alert("此功能無法刪除 !");
        return;
      }
      const vm = this;
      this.$api.functions.remove(item.FunctionId).then(() => {
        vm.getTrigger = !vm.getTrigger;
      });
    },
    saveFunction() {
      const vm = this;
      this.$api.functions
        .save(vm.item)
        .then(() => {
          vm.isShowModal = !vm.isShowModal;
          vm.getTrigger = !vm.getTrigger;
        })
        .catch((error) => vm.$func.message.error(error.data.Message));
    },
  },
  mounted() {
    this.setOptions();
  },
};
</script>
