import {
  httpGet,
  httpPost,
  httpPut,
  httpPatch,
  httpDelete,
} from "@/request/http";
import func from "@/funcs";

let path = "/utai";

export default {
  getPages(dTO) {
    return httpGet(`${path}/applications/pages`, dTO);
  },
  get(id) {
    return httpGet(`${path}/applications/${id}`);
  },
  put(id, item) {
    return httpPut(`${path}/applications/${id}`, item);
  },
  patchStatus(id, type) {
    return httpPatch(`${path}/applications/${id}/statustype/${type}`);
  },
  remove(id) {
    return httpDelete(`${path}/applications/${id}`);
  },
  print(id) {
    func.printPdf.post(`${path}/applications/${id}/print`);
  },
};
