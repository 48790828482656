<template>
  <div>
    <item-modal
      :isShowModal="isShow"
      :title="modalTitle"
      @onSaved="save"
      @onClosed="close"
    >
      <div slot="item">
        <div class="row">
          <div class="col-md-6">
            <div class="form-group">
              <label for="itemName">{{ $t("modal.name") }}</label>
              <input
                type="text"
                class="form-control"
                id="itemName"
                v-model="item.OnlineOrderItemName"
                required
              />
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label for="salPrice">{{ $t("modal.price") }}</label>
              <input
                type="number"
                class="form-control"
                id="salPrice"
                v-model="item.SalPrice"
                required
              />
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label for="memo">{{ $t("modal.memo") }}</label>
              <input
                type="text"
                class="form-control"
                id="memo"
                v-model="item.Memo"
              />
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label for="foodName">{{ $t("modal.corresponditem") }}</label>
              <input
                type="text"
                class="form-control"
                id="foodName"
                :value="foodName"
                :placeholder="`${$t('placeholder.dbselectfood')}`"
                @dblclick="isFoodSelectShow = !isFoodSelectShow"
                readonly
                required
              />
            </div>
          </div>
        </div>
      </div>
    </item-modal>

    <food-select
      modalId="correspondItem"
      :trigger="isFoodSelectShow"
      :isMultiple="false"
      @onSaved="addFood"
    >
    </food-select>
  </div>
</template>

<script>
import itemModal from "@/components/ItemModal";
import foodSelect from "@/components/selects/FoodSelects";

export default {
  data() {
    return {
      item: {},
      foodName: "",
      isFoodSelectShow: false,
    };
  },
  props: ["isShow", "isEdit", "entity", "onlineOrderType", "modalTitle"],
  watch: {
    entity(val) {
      this.foodName = "";
      this.item = val;
      if (this.isEdit) {
        this.$api.foods.get(this.item.FoodId).then((food) => {
          this.foodName = food.FoodName;
        });
      }
    },
    onlineOrderType(val) {
      this.category.OnlineOrderType = val;
    },
  },
  components: {
    itemModal,
    foodSelect,
  },
  methods: {
    addFood(food) {
      this.foodName = food.FoodName;
      if (!this.isEdit && !this.item.OnlineOrderItemName) {
        this.item.OnlineOrderItemName = food.FoodName;
      }
      if (!this.isEdit && !this.item.SalPrice) {
        this.item.SalPrice = food.SalPrice1;
      }
      this.item.FoodId = food.FoodId;
    },
    save() {
      const vm = this;
      if (!this.foodName) {
        this.$func.message.alert("請選擇對應商品 !");
        return;
      }
      this.$api.onlineOrderItems
        .post(vm.item)
        .then(() => {
          vm.$emit("save");
        })
        .catch((error) => vm.$func.message.error(error.data.Message));
    },
    close() {
      this.$emit("close");
    },
  },
};
</script>
