import {
  httpGet,
  httpPost,
  httpPut,
  httpPatch,
  httpDelete,
} from "@/request/http";

let path = "/businesses";

export default {
  get(id) {
    return httpGet(`${path}/${id}`);
  },
  getPart() {
    return httpGet(`/businesspart`);
  },
  getPages(dTO) {
    return httpGet(`${path}/pages`, dTO);
  },
  post(item) {
    return httpPost(`${path}`, item, { timeout: 180000 });
  },
  postPart(item) {
    return httpPost(`/businesspart`, item);
  },
  put(item) {
    return httpPut(`${path}/${item.BusinessId}`, item);
  },
  patchValid(id, params) {
    return httpPatch(`${path}/valid/${id}`, params);
  },
  remove(id) {
    return httpDelete(`${path}/${id}`);
  },
  initializeSale(id) {
    return httpPost(`${path}/${id}/initialization/sale`);
  },
  initializeErp(id) {
    return httpPost(`${path}/${id}/initialization/erp`);
  },
  searchVoid(id, search) {
    return httpGet(`${path}/${id}/sals/canvoid/${search}`);
  },
  cancelVoid(businessId, id) {
    return httpPost(`${path}/${businessId}/sals/${id}/void/cancel`);
  },
  getImage() {
    return httpGet(`${path}/member-web/image`, null, { responseType: "blob" });
  },
  postImage(formData) {
    return httpPost(`${path}/member-web/image`, formData);
  },
  deleteImage() {
    return httpDelete(`${path}/member-web/image`);
  },
  getMemberWeb() {
    return httpGet(`${path}/member`);
  },
  saveWebMember(item) {
    return httpPost(`${path}/member`, item);
  },
  saveLogo(item) {
    return httpPut(`${path}/member/logo`, item);
  },
  deleteLogo() {
    return httpDelete(`${path}/member/logo`);
  },
  saveBackgroundImage(item) {
    return httpPut(`${path}/member/background`, item);
  },
  deleteBackgroundImage() {
    return httpDelete(`${path}/member/background`);
  },
  resetDeposit(id, formData) {
    return httpPost(`${path}/${id}/customers/deposit/reupload`, formData);
  },
  resetIntegral(id, formData) {
    return httpPost(`${path}/${id}/customers/integral/reupload`, formData);
  },
  getApiKeys(id) {
    return httpGet(`${path}/${id}/credentials`);
  },
  postApiKey(item) {
    return httpPost(`/credentials`, item);
  },
  deleteApiKey(id) {
    return httpDelete(`/credentials/${id}`);
  },
};
