export function getAll(){
  return [
    {
      value: 0,
      name: "Default",
      text: "預設"
    },
    {
      value: 1,
      name: "SalPrice1",
      text: "售價1"
    },
    {
      value: 2,
      name: "SalPrice2",
      text: "售價2"
    },
    {
      value: 3,
      name: "SalPrice3",
      text: "售價3"
    },
    {
      value: 4,
      name: "SalPrice4",
      text: "售價4"
    },
    {
      value: 5,
      name: "SalPrice5",
      text: "售價5"
    },
    {
      value: 6,
      name: "SalPrice6",
      text: "售價6"
    },
  ]
}
export function getByName(name){
  const type = getAll().find(function(item){
    return item.name.toLowerCase() == name.toLowerCase();
  });
  return type;
}
export function getByValue(value){
  const type = getAll().find(function(item){
    return item.value == value;
  });
  return type;
}
