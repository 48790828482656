<template>
  <form
    id="invoicefunctionform"
    class="modal fade"
    tabindex="-1"
    role="dialog"
    data-backdrop="static"
    @submit.prevent="onSaved"
  >
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">{{ $t("title.invoice") }}</h5>
          <button type="button" class="close" @click="onClosed">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="form-group row">
            <label for="receiver" class="col-sm-3 col-form-label"
              >{{ $t("modal.email") }}:</label
            >
            <div class="col-sm-9">
              <input
                type="email"
                class="form-control"
                id="receiver"
                v-model="receiver"
              />
            </div>
          </div>
          <div>
            <div class="form-check form-check-inline">
              <input
                class="form-check-input"
                type="radio"
                id="format1"
                value="0"
                v-model="sheetType"
                checked
              />
              <label class="form-check-label" for="format1">{{
                $t("modal.format1")
              }}</label>
            </div>
            <div v-if="!isB2C" class="form-check form-check-inline">
              <input
                class="form-check-input"
                type="radio"
                id="format2"
                value="1"
                v-model="sheetType"
              />
              <label class="form-check-label" for="format2">{{
                $t("modal.format2")
              }}</label>
            </div>
            <div v-if="!isB2C" class="form-check form-check-inline">
              <input
                class="form-check-input"
                type="radio"
                id="format3"
                value="2"
                v-model="sheetType"
              />
              <label class="form-check-label" for="format3">{{
                $t("modal.format3")
              }}</label>
            </div>
            <div class="form-check form-check-inline">
              <input
                class="form-check-input"
                type="radio"
                id="format4"
                value="3"
                v-model="sheetType"
              />
              <label class="form-check-label" for="format4">{{
                $t("modal.format4")
              }}</label>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button
            v-if="!hasVehicle"
            type="button"
            class="btn btn-primary"
            @click="onPrint"
          >
            <div style="display: inline-block">
              <i class="fas fa-print"></i>
            </div>
            {{ $t("button.print") }}
          </button>
          <button
            v-if="isB2C"
            type="button"
            class="btn btn-primary"
            @click="onDetailPrint"
          >
            <div style="display: inline-block">
              <i class="fas fa-print"></i>
            </div>
            {{ $t("button.detail") }}
          </button>
          <button type="button" class="btn btn-success" @click="onSendEmail">
            <div style="display: inline-block">
              <i class="fas fa-envelope"></i>
            </div>
            {{ $t("button.sendemail") }}
          </button>
          <button type="button" class="btn btn-danger" @click="onVoid">
            <i class="fas fa-trash-alt"></i>
            {{ $t("button.void") }}
          </button>
        </div>
      </div>
    </div>
  </form>
</template>

<script>
import $ from "jquery";
import inputPart from "@/components/parts/inputPart";

export default {
  data() {
    return {
      receiver: undefined,
      sheetType: 0,
    };
  },
  props: {
    invoiceTrigger: false,
    sal: {},
  },
  watch: {
    invoiceTrigger() {
      this.receiver = undefined;
      $(`#invoicefunctionform`).modal("toggle");
      if (this.sal) {
        if (this.sal.InvoiceEmail) {
          this.receiver = this.sal.InvoiceEmail;
        } else if (this.sal.CustomerId && this.sal.CustomerId != null) {
          this.$api.customers.get(this.sal.CustomerId).then((data) => {
            this.receiver = data.Email;
          });
        }
      }
    },
  },
  computed: {
    isB2C: function () {
      if (this.sal && this.sal.UniformNumber) {
        return false;
      } else {
        return true;
      }
    },
    hasVehicle: function () {
      if (this.sal && this.sal.VehicleType != 0) {
        return true;
      } else {
        return false;
      }
    },
  },
  components: {
    inputPart,
  },
  methods: {
    onPrint() {
      if (this.isB2C) {
        this.$api.sals.printElecInvoice(this.sal.SalId, this.sheetType);
      } else {
        this.$api.sals.printElecInvoiceB2B(this.sal.SalId, this.sheetType);
      }
    },
    onDetailPrint() {
      this.$api.sals.printElecInvoiceDetail(this.sal.SalId);
    },
    onSendEmail() {
      if (!this.receiver || this.receiver.count == 0) {
        this.$func.message.alert("請輸入收件人 !");
        return;
      }
      const vm = this;
      this.$api.sals
        .emailElecInvoice({
          SalId: this.sal.SalId,
          Email: this.receiver,
          SheetType: this.sheetType,
        })
        .then(() => {
          $(`#invoicefunctionform`).modal("toggle");
          vm.$emit("invoiceSaved");
        })
        .catch((error) => {
          vm.$func.message.error(error.data.Message);
        });
    },
    onVoid() {
      const vm = this;
      this.$func.message.confirm("確定作廢嗎?").then((result) => {
        if (result.isConfirmed) {
          var item = {};
          item.OldSalId = vm.sal.SalId;
          vm.$api.sals
            .elecInvoiceVoid(item)
            .then(() => {
              $(`#invoicefunctionform`).modal("toggle");
              vm.$emit("invoiceSaved");
            })
            .catch((error) => vm.$func.message.error(error.data.Message));
        }
      });
    },
    onClosed() {
      $(`#invoicefunctionform`).modal("toggle");
      this.$emit("invoiceSaved");
    },
  },
};
</script>
