import { httpGet, httpPost, httpDelete } from "@/request/http";

let path = "/combos";

export default {
  get(id) {
    return httpGet(`${path}/${id}`);
  },
  getPages(dTO) {
    return httpGet(`${path}/pages`, dTO);
  },
  getPagesByKind(id, dTO) {
    return httpGet(`/combokinds/${id}/combos/pages`, dTO);
  },
  save(item) {
    return httpPost(path, item);
  },
  remove(id) {
    return httpDelete(`${path}/${id}`);
  },
  getFoods(comboKindId, foodKindId) {
    return httpGet(`/combokinds/${comboKindId}/foodkinds/${foodKindId}/combos`);
  },
  getFoodsBySelect(comboKindId) {
    return httpGet(`/combokinds/${comboKindId}/combos`);
  },
  postFoods(item) {
    return httpPost(`${path}/addbatch`, item);
  },
  removeFoods(dto) {
    return httpPost(`${path}/deletebatch`, dto);
  },
  postBatchPrice(dto) {
    return httpPost(`${path}/batchprice`, dto);
  },
  before(item) {
    return httpPost(`${path}/sequence/before`, item);
  },
  after(item) {
    return httpPost(`${path}/sequence/after`, item);
  },
};
