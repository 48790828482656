<template>
  <div>
    <page-table
      :pagination="pagination"
      :hasAdd="false"
      :hasEdit="false"
      :hasRemove="false"
      @getItems="getTables"
      @trdbClicked="checkQRcode"
      :items="tableMaps"
      :columns="columns"
      :idProperty="idProperty"
      :nameProperty="nameProperty"
      :getTrigger="getTrigger"
      :isSortable="false"
    >
      <template slot="button" slot-scope="props">
        <button
          type="button"
          class="btn btn-outline-primary py-2 px-4"
          style="border-color: transparent"
          @click="checkQRcode(props.item)"
        >
          <i class="fas fa-qrcode"></i>
        </button>
        <button
          v-if="$route.params.type == 1"
          type="button"
          class="btn btn-outline-primary py-2 px-4"
          style="border-color: transparent"
          @click="downloadQRcodes()"
        >
          <i class="fas fa-download"></i>
        </button>
      </template>
    </page-table>

    <itemModal
      :isShowModal="isShowModal"
      :submitName="submitName"
      @onClosed="isShowModal = !isShowModal"
      @onSaved="downloadQRcode"
      :title="modalTitle"
      :modalClass="modalClass"
    >
      <div slot="item">
        <div class="row">
          <div class="col-12 d-flex justify-content-center">
            <canvas
              ref="qrcodeCanvas"
              width="500"
              height="500"
              style="display: none"
            ></canvas>
            <img
              ref="qrcodeImg"
              src=""
              alt=""
              style="width: 480px; max-width: 100%"
            />
          </div>
        </div>
      </div>
    </itemModal>
  </div>
</template>

<script>
import pageTable from "@/components/PageTable";
import itemModal from "@/components/ItemModal";
import importPart from "@/components/ImportPart";
import exportPart from "@/components/ExportPart";
import QRCode from "qrcode";
import JSZip from "jszip";

export default {
  data() {
    return {
      searchDTO: {},
      tableMaps: [],
      tableMap: {},
      qrcodeUrl: "",
      pagination: {},
      isShowModal: false,
      modalTitle: "",
      columns: [
        {
          name: "TableMapName",
        },
        {
          name: "PartId",
        },
      ],
      idProperty: "FoodKindId",
      nameProperty: "FoodKindName",
      getTrigger: false,
      authority: {},
      submitName: "download",
      qrcodeDataUrl: null,
    };
  },
  components: {
    pageTable,
    itemModal,
    importPart,
    exportPart,
  },
  computed: {
    productType() {
      return parseInt(window.localStorage["productType"]);
    },
    currentLang() {
      return this.$store.state.currentLang;
    },
    isShow() {
      return window.localStorage["productType"] == 0;
    },
    modalClass() {
      return this.isShow ? "modal-lg" : "modal-md";
    },
    inputClass() {
      return this.isShow ? "col-lg-4" : "";
    },
  },
  watch: {
    currentLang() {
      this.setOptions();
    },
  },
  methods: {
    setOptions() {},
    downloadQRcode() {
      const base64Data = this.qrcodeDataUrl.split(",")[1];
      const binaryData = this.base64ToBinary(base64Data);
      const blob = new Blob([binaryData], { type: "image/png" });
      const fileName = this.tableMap.TableMapName;
      this.$fileSaver(blob, fileName);
    },
    base64ToBinary(base64Data) {
      const binaryString = atob(base64Data);
      const byteArray = new Uint8Array(binaryString.length);

      for (let i = 0; i < binaryString.length; i++) {
        byteArray[i] = binaryString.charCodeAt(i);
      }

      return byteArray;
    },
    downloadQRcodes() {
      this.$api.tableMaps
        .getTables(this.$route.params.id)
        .then(async (data) => {
          const tables = data;
          const zipFile = await this.createZip(tables);

          this.$fileSaver(zipFile, "QRcodes.zip");
        });
    },
    async createZip(tables) {
      const zip = new JSZip();

      for (let i = 0; i < tables.length; i++) {
        const dataUrl = await this.generateQRcodeForZip(tables[i].Url);
        const base64Data = dataUrl.split(",")[1];
        const fileName = tables[i].TableMapName;

        zip.file(fileName + ".png", base64Data, { base64: true });
      }

      return zip.generateAsync({ type: "blob" });
    },
    async generateQRcodeForZip(url) {
      return new Promise((resolve) => {
        const canvas = this.$refs.qrcodeCanvas;
        const ctx = canvas.getContext("2d");

        const logoSize = 50;
        const qrCodeSize = 500;

        QRCode.toDataURL(url, {
          errorCorrectionLevel: "M",
          width: qrCodeSize,
        }).then((qrCodeDataUrl) => {
          const qrCodeImg = new Image();
          qrCodeImg.src = qrCodeDataUrl;

          qrCodeImg.onload = () => {
            ctx.clearRect(0, 0, 500, 500);
            ctx.drawImage(qrCodeImg, 0, 0, qrCodeSize, qrCodeSize);

            const logoUrl = require("@/imgs/LogoCircle.png");
            const logoImg = new Image();
            logoImg.src = logoUrl;

            logoImg.onload = () => {
              const centerX = (qrCodeSize - logoSize) / 2;
              const centerY = (qrCodeSize - logoSize) / 2;
              ctx.drawImage(logoImg, centerX, centerY, logoSize, logoSize);

              const dataUrl = canvas.toDataURL("image/png");
              resolve(dataUrl);
            };
          };
        });
      });
    },
    getTables() {
      const vm = this;
      this.$api.tableMaps.getPages(this.$route.params.id).then((data) => {
        vm.tableMaps = data;
      });
    },
    async generateQRCodeWithLogo(url) {
      const canvas = this.$refs.qrcodeCanvas;
      const img = this.$refs.qrcodeImg;
      const ctx = canvas.getContext("2d");

      // const now = new Date();
      // const year = now.getFullYear();
      // const month = String(now.getMonth() + 1).padStart(2, "0");
      // const day = String(now.getDate()).padStart(2, "0");

      const text = url;
      const logoUrl = require("@/imgs/LogoCircle.png");

      // 生成 QR Code
      const qrCodeDataUrl = await QRCode.toDataURL(text, {
        errorCorrectionLevel: "M",
        width: 500,
      });

      const qrCodeImg = new Image();
      qrCodeImg.src = qrCodeDataUrl;

      qrCodeImg.onload = () => {
        const qrCodeSize = canvas.width * 1;
        const logoSize = qrCodeSize * 0.2;
        const qrCodeX = (canvas.width - qrCodeSize) / 2;
        const qrCodeY = (canvas.height - qrCodeSize) / 2;
        const logoX = (canvas.width - logoSize) / 2;
        const logoY = (canvas.height - logoSize) / 2;

        ctx.drawImage(qrCodeImg, qrCodeX, qrCodeY, qrCodeSize, qrCodeSize);

        const logoImg = new Image();
        logoImg.src = logoUrl;

        logoImg.onload = () => {
          ctx.drawImage(logoImg, logoX, logoY, logoSize, logoSize);
          const dataUrl = canvas.toDataURL("image/png");
          this.qrcodeDataUrl = dataUrl;
          img.src = dataUrl;
        };
      };
    },
    checkQRcode(item) {
      this.tableMap = item;
      if (this.$route.params.type != 1) {
        this.$func.message.alert("此收銀台非桌位點餐 !");
        return;
      }
      this.modalTitle = this.$t("modal.qrcode");
      this.isShowModal = !this.isShowModal;
      this.$api.tableMaps
        .getQRcode(this.$route.params.id, this.tableMap.PartId)
        .then((data) => {
          this.generateQRCodeWithLogo(data);
        });
    },
    onExport() {
      this.$api.foodKinds.getExcel();
    },
    onImport(file) {
      let formData = new FormData();
      formData.append("file", file);
      const vm = this;
      this.$api.foodKinds.postExcel(formData).then(() => {
        vm.getTrigger = !vm.getTrigger;
      });
    },
  },
  created() {
    this.authority = this.$func.localStorage.getAuthByName(this.$route.name);
  },
};
</script>
