<template>
  <div>
    <condition-part
      :hasDate="true"
      :hasDirectory="true"
      :items="stockAdjusts"
      :pagination="pagination"
      :columns="columns"
      @getItems="getItems"
      @addItem="add"
      @editItem="edit"
      @removeItem="remove"
      :nameProperty="nameProperty"
      :getTrigger="getTrigger"
      :hasAdd="authority.IsAdd"
      :hasEdit="authority.IsUpdate"
      :hasRemove="authority.IsRemove"
      :isSortable="true"
    />
  </div>
</template>

<script>
import conditionPart from "@/components/parts/conditionPart";

export default {
  data() {
    return {
      stockAdjusts: [],
      pagination: {},
      columns: [
        {
          name: "StockAdjustNumber",
        },
        {
          name: "StockAdjustDate",
        },
        {
          name: "StockAdjustTypeText",
        },
        {
          name: "DirectoryName",
        },
        {
          name: "EmployeeName",
        },
        {
          name: "Memo",
        },
        {
          name: "CreateTime",
        },
        {
          name: "UpdateTime",
        },
      ],
      nameProperty: "StockAdjustNumber",
      getTrigger: false,
      authority: {},
    };
  },
  components: {
    conditionPart,
  },
  methods: {
    getItems(condition) {
      const vm = this;
      this.$api.stockAdjusts.getPages(condition).then((data) => {
        vm.stockAdjusts = data.Datas;
        vm.pagination = data.Pagination;
      });
    },
    add() {
      this.$router.push({ name: "StockAdjustAdd" });
    },
    edit(item) {
      const vm = this;
      this.canModify(item.StockAdjustId).then((value) => {
        if (!value) {
          vm.$func.message.alert("此筆單無法修改 !");
        } else {
          vm.$router.push({
            name: "StockAdjustEdit",
            params: { id: item.StockAdjustId },
          });
        }
      });
    },
    remove(item) {
      const vm = this;
      this.$api.stockAdjusts.delete(item.StockAdjustId).then(() => {
        vm.getTrigger = !vm.getTrigger;
      });
    },
    canModify(id) {
      return this.$api.stockAdjusts
        .getCanModify(id)
        .then((data) => {
          return data;
        })
        .catch(() => {
          return false;
        });
    },
  },
  created() {
    this.authority = this.$func.localStorage.getAuthByName(this.$route.name);
  },
};
</script>
