<template>
  <div>
    <h2 v-if="food">{{ food.FoodName }}</h2>
    <div class="row">
      <div class="col-md-6">
        <h5>{{ $t("title.unselectedbrand") }}</h5>
        <div class="card card-table">
          <div class="card-header">
            <button
              type="button"
              class="btn btn-outline-primary py-2 px-4"
              style="border-color: transparent"
              @click="onChange('add')"
            >
              <i class="fa fa-plus"></i>
            </button>
          </div>

          <div class="card-body">
            <table class="table">
              <thead>
                <tr>
                  <th>
                    <div class="custom-control custom-checkbox mr-sm-2">
                      <input
                        type="checkbox"
                        class="custom-control-input"
                        id="allAdd"
                        v-model="allAdd"
                      />
                      <label class="custom-control-label" for="allAdd">{{
                        $t("columnName.add")
                      }}</label>
                    </div>
                  </th>
                  <th>{{ $t("columnName.brandnumber") }}</th>
                  <th>{{ $t("columnName.brandname") }}</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="notSelected in notSelecteds"
                  :key="notSelected.BrandId"
                  @click="notSelectedClick(notSelected.BrandId)"
                >
                  <td>
                    <input
                      class="checkbox"
                      type="checkbox"
                      :value="notSelected.BrandId"
                      v-model="addIds"
                    />
                  </td>
                  <td>{{ notSelected.BrandNumber }}</td>
                  <td>{{ notSelected.BrandName }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <h5>{{ $t("title.selectedbrand") }}</h5>
        <div class="card card-table">
          <div class="card-header">
            <button
              type="button"
              class="btn btn-outline-danger btn-icon"
              @click="onChange('remove')"
            >
              <i class="fas fa-trash-alt"></i>
            </button>
          </div>
          <div class="card-body">
            <table class="table">
              <thead>
                <tr>
                  <th>
                    <div class="custom-control custom-checkbox mr-sm-2">
                      <input
                        type="checkbox"
                        class="custom-control-input"
                        id="allRemove"
                        v-model="allRemove"
                      />
                      <label class="custom-control-label" for="allRemove">{{
                        $t("columnName.remove")
                      }}</label>
                    </div>
                  </th>
                  <th>{{ $t("columnName.brandnumber") }}</th>
                  <th>{{ $t("columnName.brandname") }}</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="selected in selecteds"
                  :key="selected.BrandId"
                  @click="selectedClick(selected.BrandId)"
                >
                  <td>
                    <input
                      class="checkbox"
                      type="checkbox"
                      :value="selected.BrandId"
                      v-model="removeIds"
                    />
                  </td>
                  <td>{{ selected.BrandNumber }}</td>
                  <td>{{ selected.BrandName }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      food: {},
      notSelecteds: [],
      selecteds: [],
      addIds: [],
      removeIds: [],
      allAdd: false,
      allRemove: false,
    };
  },
  watch: {
    allAdd(value) {
      this.addIds = [];
      if (value) {
        this.notSelecteds.forEach((item) => this.addIds.push(item.BrandId));
      }
    },
    allRemove(value) {
      this.removeIds = [];
      if (value) {
        this.selecteds.forEach((item) => this.removeIds.push(item.BrandId));
      }
    },
  },
  methods: {
    notSelectedClick(id) {
      const index = this.addIds.indexOf(id);
      if (index >= 0) {
        this.addIds.splice(index, 1);
      } else {
        this.addIds.push(id);
      }
    },
    selectedClick(id) {
      const index = this.removeIds.indexOf(id);
      if (index >= 0) {
        this.removeIds.splice(index, 1);
      } else {
        this.removeIds.push(id);
      }
    },
    updateBrands() {
      const vm = this;
      this.$api.foods.getFoodBrands(vm.food.FoodId).then((data) => {
        vm.addIds = [];
        vm.removeIds = [];
        vm.notSelecteds = data.NotSelecteds;
        vm.selecteds = data.Selecteds;
        vm.allAdd = false;
        vm.allRemove = false;
      });
    },
    onChange(type) {
      if (
        (type == "add" && this.addIds.length == 0) ||
        (type == "remove" && this.removeIds.length == 0)
      ) {
        this.$func.message.alert("請選擇品牌 !");
        return;
      }
      this.selecteds = this.selecteds.filter(
        (selected) => !this.removeIds.includes(selected.BrandId)
      );
      let newIds = [];
      this.selecteds.forEach((selected) => {
        newIds.push(selected.BrandId);
      });

      const vm = this;
      this.$api.foods
        .saveFoodBrands({
          FoodId: vm.food.FoodId,
          BrandIds: [...vm.addIds, ...newIds],
        })
        .then(() => vm.updateBrands());
    },
  },
  created() {
    const vm = this;
    this.$api.foods.get(this.$route.params["id"]).then((data) => {
      if (!data) {
        vm.$func.message.alert("查無此商品 !");
        vm.$router.go(-1);
      }
      vm.food = data;
      vm.updateBrands();
    });
  },
};
</script>
