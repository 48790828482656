<template>
  <form
    :id="modalId"
    class="modal fade"
    role="dialog"
    data-backdrop="static"
    @submit.prevent="onSaved"
  >
    <!-- tabindex="-1" -->
    <div
      class="modal-dialog modal-dialog-centered"
      :class="modalClass"
      role="document"
    >
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">{{ title }}</h5>
          <button type="button" class="close" @click="onClosed">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <slot name="item" />
        </div>
        <div class="modal-footer">
          <button type="submit" class="btn btn-primary">
            {{ $t(`button.${submitName}`) }}
          </button>
          <button type="button" class="btn btn-secondary" @click="onClosed">
            {{ $t("button.cancel") }}
          </button>
        </div>
      </div>
    </div>
  </form>
</template>

<script>
import $ from "jquery";

export default {
  props: {
    isShowModal: Boolean,
    title: String,
    modalId: {
      type: String,
      default: "itemModal",
    },
    modalClass: String,
    trigger: false,
    submitName: {
      type: String,
      default: "save",
    },
  },
  watch: {
    isShowModal(value) {
      $(`#${this.modalId}`).modal("toggle");
      if (value) {
        window.onbeforeunload = () => {
          return true;
        };
      } else {
        window.onbeforeunload = null;
      }
    },
    trigger(value) {
      $(`#${this.modalId}`).modal("toggle");
      if (value) {
        window.onbeforeunload = () => {
          return true;
        };
      } else {
        window.onbeforeunload = null;
      }
    },
  },
  methods: {
    onSaved() {
      this.$emit("onSaved");
    },
    onClosed() {
      $(`#${this.modalId}`).modal("toggle");
      this.$emit("onClosed");
    },
  },
};
</script>
