import { httpGet, httpPost, httpPatch } from "@/request/http";

function replaceAddEdit(viewName) {
  return viewName.replace("Add", "").replace("Edit", "");
}

export default {
  get(viewName, tableName) {
    let checkViewName = viewName;
    if (viewName.includes("Add") || viewName.includes("Edit")) {
      checkViewName = replaceAddEdit(viewName);
    }
    return httpGet(`/views/${checkViewName}/tables/${tableName}/columns`);
  },
  save(viewName, tableName, columns) {
    let checkViewName = viewName;
    if (viewName.includes("Add") || viewName.includes("Edit")) {
      checkViewName = replaceAddEdit(viewName);
    }
    return httpPost(
      `/views/${checkViewName}/tables/${tableName}/columns`,
      columns
    );
  },
  saveWidth(viewName, tableName, columnName, width) {
    let checkViewName = viewName;
    if (viewName.includes("Add") || viewName.includes("Edit")) {
      checkViewName = replaceAddEdit(viewName);
    }
    return httpPatch(
      `/views/${checkViewName}/tables/${tableName}/columns/${columnName}/${width}`
    );
  },
};
