import i18n from "@/langs/i18n";

export function getAll() {
  var items = [
    {
      value: 0,
      name: "None",
      text: `${i18n.t("option.systemLogType.None")}`,
    },
    {
      value: 1,
      name: "DeviceChangeCpu",
      text: `${i18n.t("option.systemLogType.DeviceChangeCpu")}`,
    },
    {
      value: 2,
      name: "UserChangePassword",
      text: `${i18n.t("option.systemLogType.UserChangePassword")}`,
    },
    {
      value: 3,
      name: "SystemChangeUserPassword",
      text: `${i18n.t("option.systemLogType.SystemChangeUserPassword")}`,
    },
    {
      value: 4,
      name: "InitailizeSale",
      text: `${i18n.t("option.systemLogType.InitailizeSale")}`,
    },
    {
      value: 5,
      name: "InitailizeERP",
      text: `${i18n.t("option.systemLogType.InitailizeERP")}`,
    },
    {
      value: 6,
      name: "DeleteUberEats",
      text: `${i18n.t("option.systemLogType.DeleteUberEats")}`,
    },
  ];
  return items;
}

export function getByName(name) {
  const type = getAll().find(function (item) {
    return item.name.toLowerCase() == name.toLowerCase();
  });
  return type;
}

export function getByValue(value) {
  const type = getAll().find(function (item) {
    return item.value == value;
  });
  return type;
}
