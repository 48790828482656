<template>
  <item-modal
    :isShowModal="isShow"
    :title="modalTitle"
    @onSaved="save"
    @onClosed="close"
  >
    <div slot="item">
      <div class="row">
        <div class="col-12">
          <div class="form-group">
            <label for="categoryName">{{ $t("modal.categoryname") }}</label>
            <input
              id="categoryName"
              type="text"
              class="form-control"
              v-model="category.OnlineOrderCategoryName"
              required
            />
          </div>
        </div>
      </div>
    </div>
  </item-modal>
</template>

<script>
import itemModal from "@/components/ItemModal";

export default {
  data() {
    return {
      category: {},
    };
  },
  props: ["isShow", "entity", "onlineOrderType", "modalTitle"],
  watch: {
    entity(val) {
      this.category = val;
    },
    onlineOrderType(val) {
      this.category.OnlineOrderType = val;
    },
  },
  components: {
    itemModal,
  },
  methods: {
    save() {
      const vm = this;
      this.$api.onlineOrderCategories
        .post(vm.category)
        .then(() => {
          vm.$emit("save");
        })
        .catch((error) => vm.$func.message.error(error.data.Message));
    },
    close() {
      this.$emit("close");
    },
  },
};
</script>
