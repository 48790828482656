<template>
  <div>
    <h2 v-if="module">{{ module.ModuleName }}</h2>
    <div class="row">
      <div class="col-md-6">
        <h5>未選收銀台</h5>
        <div class="card card-table">
          <div class="card-header">
            <button
              type="button"
              class="btn btn-outline-primary py-2 px-4"
              style="border-color: transparent"
              @click="onAdd"
            >
              <i class="fa fa-plus"></i>
            </button>
          </div>

          <div class="card-body">
            <table class="table">
              <thead>
                <tr>
                  <th>
                    <div class="custom-control custom-checkbox mr-sm-2">
                      <input
                        type="checkbox"
                        class="custom-control-input"
                        id="allAdd"
                        v-model="allAdd"
                      />
                      <label class="custom-control-label" for="allAdd"
                        >新增</label
                      >
                    </div>
                  </th>
                  <th>收銀機名稱</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="notSelected in notSelecteds"
                  :key="notSelected.DeviceId"
                  @click="notSelectedClick(notSelected.DeviceId)"
                >
                  <td>
                    <input
                      class="checkbox"
                      type="checkbox"
                      :value="notSelected.DeviceId"
                      v-model="addIds"
                    />
                  </td>
                  <td>{{ notSelected.DeviceName }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <h5>已選收銀台</h5>
        <div class="card card-table">
          <div class="card-header">
            <button
              type="button"
              class="btn btn-outline-danger btn-icon"
              @click="onRemove"
            >
              <i class="fas fa-trash-alt"></i>
            </button>
          </div>
          <div class="card-body">
            <table class="table">
              <thead>
                <tr>
                  <th>
                    <div class="custom-control custom-checkbox mr-sm-2">
                      <input
                        type="checkbox"
                        class="custom-control-input"
                        id="allRemove"
                        v-model="allRemove"
                      />
                      <label class="custom-control-label" for="allRemove"
                        >移除</label
                      >
                    </div>
                  </th>
                  <th>收銀機名稱</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="selected in selecteds"
                  :key="selected.DeviceId"
                  @click="selectedClick(selected.DeviceId)"
                >
                  <td>
                    <input
                      class="checkbox"
                      type="checkbox"
                      :value="selected.DeviceId"
                      v-model="removeIds"
                    />
                  </td>
                  <td>{{ selected.DeviceName }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      module: {},
      notSelecteds: [],
      selecteds: [],
      addIds: [],
      removeIds: [],
      allAdd: false,
      allRemove: false,
    };
  },
  watch: {
    allAdd(value) {
      this.addIds = [];
      if (value) {
        this.notSelecteds.forEach((item) => this.addIds.push(item.DeviceId));
      }
    },
    allRemove(value) {
      this.removeIds = [];
      if (value) {
        this.selecteds.forEach((item) => this.removeIds.push(item.DeviceId));
      }
    },
  },
  methods: {
    notSelectedClick(id) {
      const index = this.addIds.indexOf(id);
      if (index >= 0) {
        this.addIds.splice(index, 1);
      } else {
        this.addIds.push(id);
      }
    },
    selectedClick(id) {
      const index = this.removeIds.indexOf(id);
      if (index >= 0) {
        this.removeIds.splice(index, 1);
      } else {
        this.removeIds.push(id);
      }
    },
    updateDevices() {
      const vm = this;
      this.$api.dirModules.getDevices(vm.module.ModuleId).then((data) => {
        vm.addIds = [];
        vm.removeIds = [];
        vm.notSelecteds = data.NotSelecteds;
        vm.selecteds = data.Selecteds;
        vm.allAdd = false;
        vm.allRemove = false;
      });
    },
    onAdd() {
      if (this.addIds.length == 0) {
        this.$func.message.alert("請選擇收銀機 !");
        return;
      }
      const vm = this;
      this.$api.dirModules
        .postDevices({
          ModuleId: vm.module.ModuleId,
          DeviceIds: vm.addIds,
        })
        .then(() => vm.updateDevices());
    },
    onRemove() {
      if (this.removeIds.length == 0) {
        this.$func.message.alert("請選擇收銀機 !");
        return;
      }
      const vm = this;
      this.$api.dirModules
        .deleteDevices({
          ModuleId: vm.module.ModuleId,
          DeviceIds: vm.removeIds,
        })
        .then(() => vm.updateDevices());
    },
  },
  created() {
    const vm = this;
    this.$api.dirModules.get(this.$route.params["id"]).then((data) => {
      if (!data) {
        vm.$func.message.alert("查無此模組 !");
        vm.$router.go(-1);
      }
      vm.module = data;
      vm.updateDevices();
    });
  },
};
</script>
