<template>
  <div>
    <pageTable
      :parentPage="pagination"
      @getItems="getEmployees"
      @addItem="addEmployee"
      @editItem="editEmployee"
      @removeItem="removeEmployee"
      @trdbClicked="editEmployee"
      :items="employees"
      :columns="columns"
      :idProperty="idProperty"
      :nameProperty="nameProperty"
      :getTrigger="getTrigger"
      :hasAdd="authority.IsAdd"
      :hasEdit="authority.IsUpdate"
      :hasRemove="authority.IsRemove"
      :isSortable="true"
    >
      <template slot="button" slot-scope="props">
        <button
          type="button"
          class="btn btn-outline-primary py-2 px-4"
          style="border-color: transparent"
          v-if="hasUserAuth"
          @click="onUser(props.index)"
        >
          <i class="fa fa-user"></i>
        </button>
      </template>
    </pageTable>

    <employee-data
      :isShow="isShowModal"
      :modalTitle="modalTitle"
      @close="isShowModal = !isShowModal"
      @save="save"
      :entity="employee"
    >
    </employee-data>

    <user-data
      :hasAdmin="false"
      :trigger="modalTrigger"
      :id="employeeId"
      modalId="employeeUser"
      @onSaved="userSave"
    ></user-data>
  </div>
</template>

<script>
import pageTable from "@/components/PageTable";
import employeeData from "@/components/datas/EmployeeData";
import userData from "@/components/datas/UserData";

export default {
  data() {
    return {
      employees: [],
      employee: {},
      pagination: {},
      isShowModal: false,
      columns: [
        { name: "EmployeeNumber" },
        { name: "EmployeeName" },
        { name: "Cellphone" },
        { name: "StoreNumber" },
        { name: "StoreName" },
        { name: "ModuleStoreName" },
        { name: "DepartmentNumber" },
        { name: "DepartmentName" },
        { name: "CreateTime" },
        { name: "UpdateTime" },
      ],
      idProperty: "EmployeeId",
      nameProperty: "EmployeeName",
      modalTrigger: false,
      employeeId: "",
      getTrigger: false,
      authority: {},
      modalTitle: "",
    };
  },
  components: {
    pageTable,
    employeeData,
    userData,
  },
  computed: {
    hasUserAuth() {
      return this.$func.localStorage.getAuth(156).IsList;
    },
  },
  methods: {
    getEmployees(dTO) {
      const vm = this;
      this.$api.employees.getPages(dTO).then((data) => {
        vm.employees = data.Datas;
        vm.pagination = data.Pagination;
      });
    },
    addEmployee() {
      this.employee = {};
      this.isShowModal = !this.isShowModal;
      this.modalTitle = this.$t("modal.addemployee");
    },
    editEmployee(item) {
      if (!this.authority.IsUpdate) {
        return;
      }
      const vm = this;
      this.$api.employees.get(item[vm.idProperty]).then((data) => {
        vm.employee = data;
        this.isShowModal = !this.isShowModal;
        vm.modalTitle = this.$t("modal.editemployee");
      });
    },
    removeEmployee(item) {
      const vm = this;
      this.$api.employees.remove(item[vm.idProperty]).then(() => {
        vm.getTrigger = !vm.getTrigger;
      });
    },
    save() {
      this.isShowModal = !this.isShowModal;
      this.getTrigger = !this.getTrigger;
    },
    onUser(index) {
      const employee = this.employees[index];
      if (employee) {
        this.modalTrigger = !this.modalTrigger;
        this.employeeId = employee.EmployeeId;
      } else {
        this.$func.message.alert("請選擇一筆資料 !");
      }
    },
    userSave(id) {
      if (window.localStorage["userId"] == id) {
        location.reload();
      }
    },
  },
  created() {
    this.authority = this.$func.localStorage.getAuthByName(this.$route.name);
  },
};
</script>
