import { httpGet, httpPost, httpDelete } from '@/request/http';

let path = '/pays';

export default{
    get(id){
      return httpGet(`${path}/${id}`);
    },
    getPages(dTO){
      return httpGet(`${path}/pages`, dTO);
    },
    save(item){
      return httpPost(path , item);
    },
    remove(id){
      return httpDelete(`${path}/${id}`);
    },
    before(payId) {
      return httpPost(`${path}/sequences/before`, {
        payId
      });
    },
    after(payId) {
      return httpPost(`${path}/sequences/after`, {
        payId
      });
    }
}
