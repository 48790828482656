export function getAll() {
  return [
    {
      value: 0,
      name: "UShow",
      text: "UShow",
    },
    {
      value: 1,
      name: "UShowMini",
      text: "UShowMini",
    },
    {
      value: 2,
      name: "UShowPad",
      text: "UShowPad",
    },
    {
      value: 3,
      name: "UShowWeb",
      text: "UShowWeb",
    },
    {
      value: 4,
      name: "UShowLite",
      text: "UShowLite",
    },
  ];
}

export function getByValue(value) {
  const type = getAll().find(function (item) {
    return item.value == value;
  });
  return type;
}
