import i18n from "@/langs/i18n";

export function getAll() {
  return [
    {
      value: 0,
      name: "Green",
      text: `${i18n.t("option.lineOrderImageColorType.Green")}`,
    },
    {
      value: 1,
      name: "Blue",
      text: `${i18n.t("option.lineOrderImageColorType.Blue")}`,
    },
    {
      value: 2,
      name: "Yellow",
      text: `${i18n.t("option.lineOrderImageColorType.Yellow")}`,
    },
    {
      value: 3,
      name: "Orange",
      text: `${i18n.t("option.lineOrderImageColorType.Orange")}`,
    },
  ];
}
export function getByValue(value) {
  const type = getAll().find(function (item) {
    return item.value == value;
  });
  return type;
}
