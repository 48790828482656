<template>
  <div class="form-group" :class="container" :style="containerStyle">
    <label :for="id" class="col-3 col-form-label" :class="labelColumn">{{
      labelText
    }}</label>
    <div class="col">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    gridDegree: Number,
    degree: Number,
    id: String,
    labelText: String,
    colType: Number,
  },
  computed: {
    container() {
      switch (this.gridDegree) {
        case 12:
          return "d-flex col-12 col-lg-6 p-0";
        case 8:
          return "d-flex col-12 col-lg-4 col-md-6 p-0";
        case 6:
          return "d-flex col-12 p-0";
        default:
          return "row";
      }
    },
    containerStyle() {
      switch (this.gridDegree) {
        case 12:
          return "width: auto;";
        default:
          return;
      }
    },
    labelColumn() {
      switch (this.gridDegree) {
        case 12: {
          switch (this.degree) {
            case 12:
              return "col-sm-2 col-md-2 col-lg-2";
            default:
              return "col-md-2 col-lg-3";
          }
        }
        case 11: {
          switch (this.degree) {
            default:
              return "col-md-2 col-lg-3";
          }
        }
        case 8: {
          switch (this.degree) {
            default:
              return "col-md-3";
          }
        }
        case 6: {
          switch (this.degree) {
            case 4:
              return "col-md-3";
            case 6:
              return "col-sm-3 col-md-3";
            case 12:
              return "col-md-1";
            default:
              return "col-md-4 col-lg-3 col-xl-2";
          }
        }
        default: {
          switch (this.degree) {
            case 4:
              return "col-sm-2 col-md-3";
            case 6:
              return "col-sm-3 col-md-2 col-lg-3";
            case 12:
              return "col-sm-2 col-lg-1";
            default:
              return "col-sm-2 col-md-2";
          }
        }
      }
    },
  },
};
</script>
