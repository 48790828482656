<template>
  <form class="card card-table" @submit.prevent="onSaved">
    <div class="card-header">
      <button
        type="button"
        class="btn btn-outline-primary btn-icon"
        @click="onSaved"
      >
        <i class="fas fa-check"></i>
      </button>
      <button
        type="button"
        class="btn btn-outline-danger btn-icon"
        @click="onClosed"
      >
        <i class="fas fa-times"></i>
      </button>
    </div>
    <div class="card-body">
      <div class="row">
        <input-part
          id="combinationTradeDate"
          :labelText="`${$t('modal.date')}:`"
        >
          <input
            type="date"
            class="form-control"
            id="combinationTradeDate"
            v-model="combinationTrade.CombinationTradeDate"
            required
          />
        </input-part>
        <input-part
          id="combinationTradeNumber"
          :labelText="`${$t('modal.number2')}:`"
        >
          <input
            type="text"
            class="form-control"
            id="combinationTradeNumber"
            v-model="combinationTrade.CombinationTradeNumber"
            readonly
          />
        </input-part>

        <input-part id="employeeName" :labelText="`${$t('modal.specialist')}:`">
          <input
            type="text"
            class="form-control"
            id="employeeName"
            readonly
            :value="combinationTrade.EmployeeName"
          />
        </input-part>
        <input-part id="combination" :labelText="`${$t('modal.combination')}:`">
          <input
            type="text"
            class="form-control"
            id="combination"
            readonly
            :placeholder="`${$t('placeholder.dbselectcombination')}`"
            :value="combinationTrade.FoodName"
            @dblclick="foodModalTrigger = !foodModalTrigger"
          />
        </input-part>
        <input-part id="warehouse" :labelText="`${$t('modal.warehouse')}:`">
          <input
            type="text"
            class="form-control"
            id="warehouse"
            readonly
            :placeholder="`${$t('placeholder.dbselectwarehouse')}`"
            :value="combinationTrade.WarehouseName"
            @dblclick="warehouseModalTrigger = !warehouseModalTrigger"
          />
        </input-part>
        <input-part id="qty" :labelText="`${$t('modal.qty')}:`">
          <input
            type="number"
            class="form-control"
            id="qty"
            v-model="combinationTrade.Qty"
            @change="updateQty"
            required
          />
        </input-part>
        <input-part id="batchNumber" :labelText="`${$t('modal.batchnumber')}:`">
          <input
            type="number"
            class="form-control"
            id="batchNumber"
            v-model="combinationTrade.BatchNumber"
            :readonly="!combinationTrade.HasBatchNumber"
            @dblclick="openBatchMainSelector(combinationTrade)"
          />
        </input-part>

        <input-part id="memo" :labelText="`${$t('modal.memo')}:`" :degree="12">
          <input
            type="text"
            class="form-control"
            id="memo"
            v-model="combinationTrade.Memo"
          />
        </input-part>

        <div class="col-12">
          <table-part :columns="columns" :noHide="true">
            <tbody slot="tbody">
              <tr
                v-for="det in combinationTradeDets"
                :key="det.CombinationTradeDetId"
              >
                <td>{{ det.FoodNumber }}</td>
                <td>{{ det.FoodName }}</td>
                <td>
                  <input
                    class="form-control"
                    type="text"
                    v-model="det.WarehouseNumber"
                    @change="updateWarehouse(det)"
                    required
                  />
                </td>
                <td>{{ det.WarehouseName }}</td>
                <td class="text-right">{{ det.Qty }}</td>
                <td class="text-right">{{ det.Cost }}</td>
                <td class="text-right">{{ det.Total }}</td>
                <td>{{ det.Unit }}</td>
                <td v-if="hasBatchNumber">
                  <input
                    class="form-control"
                    type="text"
                    v-model="det.BatchNumber"
                    :readonly="!det.HasBatchNumber"
                    @dblclick="openBatchSelector(det)"
                  />
                </td>
              </tr>
              <tr>
                <td>{{ $t("modal.total") }}:</td>
                <td></td>
                <td></td>
                <td></td>
                <td class="text-right">{{ totalQty }}</td>
                <td></td>
                <td class="text-right">{{ combinationTrade.Total }}</td>
                <td></td>
                <td></td>
              </tr>
            </tbody>
          </table-part>
        </div>
      </div>
      <food-selects
        :trigger="foodModalTrigger"
        :isMultiple="false"
        @onSaved="updateCombination"
        foodStockType="1"
      />
      <warehouse-selects
        :trigger="warehouseModalTrigger"
        :isMultiple="false"
        @onSaved="setCombinationWarehouse"
      ></warehouse-selects>
      <batch-main-selects
        :trigger="batchMainModalTrigger"
        :isMultiple="false"
        :warehouseId="currentWarehouseId"
        :foodId="currentFoodId"
        @onSaved="setBatchMainNumber"
      />
      <batch-selects
        :trigger="batchModalTrigger"
        :isMultiple="false"
        :warehouseId="currentWarehouseId"
        :foodId="currentFoodId"
        @onSaved="setBatchNumber"
      />
    </div>
  </form>
</template>

<script>
import inputPart from "@/components/parts/inputPart";
import foodSelects from "@/components/selects/FoodSelects";
import warehouseSelects from "@/components/selects/WarehouseSelects";
import batchMainSelects from "@/components/selects/BatchMainSelects";
import batchSelects from "@/components/selects/BatchSelects";
import tablePart from "@/components/parts/tablePart";

export default {
  data() {
    return {
      combinationGroup: {},
      combinationTrade: this.$func.defaultModel.getCombinationTrade(12),
      combinationTradeDets: [],
      foodModalTrigger: false,
      warehouseModalTrigger: false,
      batchMainModalTrigger: false,
      batchModalTrigger: false,
      warehouse: {},
      currentWarehouseId: "",
      currentFoodId: "",
    };
  },
  computed: {
    hasBatchNumber() {
      return window.localStorage["hasBatchNumber"] == "true";
    },
    totalQty() {
      if (this.combinationTradeDets.length > 0)
        return this.combinationTradeDets
          .map((item) => {
            return item.Qty;
          })
          .reduce((t, q) => {
            return parseInt(t) + parseInt(q);
          });
      else return 0;
    },
    columns() {
      let c = [
        { name: "FoodNumber", headerText: "子件品號" },
        { name: "FoodName", headerText: "子件品名" },
        { name: "WarehouseNumber" },
        { name: "WarehouseName" },
        { name: "Qty" },
        { name: "Cost" },
        { name: "Total" },
        { name: "Unit" },
      ];
      if (this.hasBatchNumber) {
        columns.push({ name: "BatchNumber" });
      }
      return c;
    },
  },
  components: {
    inputPart,
    foodSelects,
    warehouseSelects,
    batchMainSelects,
    batchSelects,
    tablePart,
  },
  methods: {
    setBatchMainNumber(item) {
      this.combinationTrade.BatchNumber = item.BatchNumber;
    },
    setBatchNumber(item) {
      let index = this.combinationTradeDets.findIndex((det) => {
        return det.FoodId == this.currentFoodId;
      });
      this.combinationTradeDets[index].BatchNumber = item.BatchNumber;
    },
    openBatchMainSelector(item) {
      if (!item.HasBatchNumber) {
        return;
      }
      this.currentWarehouseId = item.WarehouseId;
      this.currentFoodId = item.FoodId;
      this.batchMainModalTrigger = !this.batchMainModalTrigger;
    },
    openBatchSelector(item) {
      if (!item.HasBatchNumber) {
        return;
      }
      this.currentWarehouseId = item.WarehouseId;
      this.currentFoodId = item.FoodId;
      this.batchModalTrigger = !this.batchModalTrigger;
    },
    updateQty() {
      if (this.combinationTrade.Qty) {
        const times =
          this.combinationTrade.Qty / this.combinationGroup.Combination.Qty;
        if (!Number.isInteger(times)) {
          this.$func.message.alert("數量需為最小單位的倍數 !");
          this.combinationTrade.Qty = this.combinationGroup.Combination.Qty;
          this.combinationTrade.Memo = this.combinationTrade.Qty;
          this.updateQty();
          return;
        }
        this.combinationGroup.CombinationDets.forEach((det) => {
          let tradeDet = this.combinationTradeDets.find(
            (e) => det.FoodId == e.FoodId
          );
          if (tradeDet) {
            tradeDet.Qty = det.Qty * times;
          }
        });
      } else {
        this.combinationTradeDets.forEach((det) => (det.Qty = 0));
      }
      this.calculate();
    },
    updateCombination(item) {
      if (item) {
        this.$api.combinations.get(item.FoodId).then((data) => {
          if (data.Combination) {
            this.combinationGroup = {};
            this.combinationTradeDets = [];
            this.combinationTrade.FoodId = null;
            this.combinationTrade.FoodNumber = null;
            this.combinationTrade.FoodName = null;

            this.combinationTrade.FoodId = data.Combination.CombinationId;
            this.combinationTrade.FoodNumber = data.Combination.FoodNumber;
            this.combinationTrade.FoodName = data.Combination.FoodName;
            this.combinationTrade.Qty = data.Combination.Qty;
            this.combinationTrade.Unit = data.Combination.Unit;
            this.combinationTrade.HasBatchNumber =
              data.Combination.HasBatchNumber;
            this.combinationTrade.BatchNumber = null;
            this.combinationGroup = data;
            for (let i = 0; i < data.CombinationDets.length; i++) {
              let e = data.CombinationDets[i];
              let det = {
                FoodId: e.FoodId,
                FoodNumber: e.FoodNumber,
                FoodName: e.FoodName,
                WarehouseId: undefined,
                WarehouseNumber: undefined,
                WarehouseName: undefined,
                Qty: e.Qty,
                Cost: undefined,
                Unit: e.Unit,
                HasBatchNumber: e.HasBatchNumber,
                BatchNumber: null,
              };
              this.combinationTradeDets.push(det);
            }
            if (this.warehouse.WarehouseId) {
              this.setCostByWarehouse(this.warehouse);
            }
          } else {
            this.$func.message.alert("此商品未設定子件品 !");
            return;
          }
          this.calculate();
        });
      } else this.updateQty();
    },
    onSaved() {
      if (!this.combinationTrade.WarehouseId) {
        this.$func.message.alert("請選擇倉庫 !");
        return;
      }
      this.combinationTradeDets.forEach((det) => {
        if (!det.WarehouseId) {
          this.$func.message.alert("請選擇倉庫 !");
          return;
        }
      });
      if (this.combinationTradeDets.length == 0) {
        this.$func.message.alert("此組合品無子件品 !");
        return;
      }

      if (
        this.combinationTrade.HasBatchNumber &&
        !this.combinationTrade.BatchNumber
      ) {
        this.$func.message.alert("請設定主件品商品批號 !");
        return;
      }

      let emptyBatch = this.combinationTradeDets.some((det) => {
        return det.HasBatchNumber && !det.BatchNumber;
      });
      if (emptyBatch) {
        this.$func.message.alert("請設定子件品商品批號 !");
        return;
      }

      const vm = this;
      this.$api.combinationTrades
        .save({
          CombinationTrade: vm.combinationTrade,
          CombinationTradeDets: vm.combinationTradeDets,
        })
        .then(() =>
          this.$router.push({
            name: "CombinationTrades",
          })
        )
        .catch((error) => {
          vm.$func.message.error(error.data.Message);
        });
    },
    onClosed() {
      this.$router.go(-1);
    },
    calculate() {
      let total = 0;
      this.combinationTradeDets.forEach((det) => {
        if (det.Qty != undefined && det.Cost != undefined) {
          det.Total = this.$func.math.mul(det.Qty, det.Cost);
          total = this.$func.math.add(det.Total, total);
        } else det.Total = undefined;
      });
      this.combinationTrade.Total = total;
    },
    setCostByWarehouse(warehouse) {
      if (!this.combinationGroup.CombinationDets) return;
      var foodIds = this.combinationGroup.CombinationDets.map((e) => e.FoodId);
      this.$api.stocks
        .getStocks(warehouse.WarehouseId, foodIds)
        .then((stockes) => {
          stockes.forEach((stock) => {
            let combinationTradeDet = this.combinationTradeDets.find(
              (d) => d.FoodId == stock.FoodId
            );
            if (!combinationTradeDet.WarehouseId) {
              combinationTradeDet.WarehouseId = warehouse.WarehouseId;
              combinationTradeDet.WarehouseNumber = warehouse.WarehouseNumber;
              combinationTradeDet.WarehouseName = warehouse.WarehouseName;
              combinationTradeDet.Cost = stock.AvgCost;
            }
          });
          this.calculate();
        });
    },
    setCombinationWarehouse(warehouse) {
      if (warehouse) {
        this.combinationTrade.WarehouseId = warehouse.WarehouseId;
        this.combinationTrade.WarehouseNumber = warehouse.WarehouseNumber;
        this.combinationTrade.WarehouseName = warehouse.WarehouseName;
        this.warehouse = warehouse;
        this.setCostByWarehouse(warehouse);
      } else {
        this.combinationTrade.WarehouseId = undefined;
        this.combinationTrade.WarehouseNumber = undefined;
        this.combinationTrade.WarehouseName = undefined;
        this.warehouse = {};
      }
    },
    updateWarehouse(det) {
      if (det.WarehouseNumber) {
        this.$api.foods
          .getStockByNumber(det.WarehouseNumber, det.FoodId)
          .then((stock) => {
            this.setWarehouse(det, stock);
          })
          .catch(() => {
            this.$func.message.alert("查無此資料 !");
            this.setWarehouse(det, undefined);
          });
      } else {
        this.setWarehouse(det, undefined);
      }
    },
    setWarehouse(det, stock) {
      if (stock) {
        det.WarehouseId = stock.WarehouseId;
        det.WarehouseNumber = stock.WarehouseNumber;
        det.WarehouseName = stock.WarehouseName;
        det.Cost = stock.AvgCost;
      } else {
        det.WarehouseId = undefined;
        det.WarehouseNumber = undefined;
        det.WarehouseName = undefined;
        det.Cost = undefined;
      }
      this.calculate();
    },
  },
  created() {
    // const id = this.$route.params["id"];
    // if (id) {
    //   this.$api.combinationTrades
    //     .get(id)
    //     .then((data) => {
    //       this.combinationTrade = data.CombinationTrade;
    //       this.combinationTradeDets = data.CombinationTradeDets;
    //     })
    //     .catch(() => {
    //       this.$func.message.alert("查無此筆單據 !");
    //       this.$router.go(-1);
    //     });
    // } else
    {
      this.$api.employees.get(window.localStorage["userId"]).then((data) => {
        this.combinationTrade.EmployeeId = data.EmployeeId;
        this.combinationTrade.EmployeeName = data.EmployeeName;
        this.setCombinationWarehouse({
          WarehouseId: data.DefaultWarehouseId,
          WarehouseNumber: data.DefaultWarehouseNumber,
          WarehouseName: data.DefaultWarehouseName,
        });
      });
    }
  },
};
</script>
