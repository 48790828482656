export function getAll() {
  return [
    {
      value: 3,
      name: "May",
      text: "胡秀琴",
    },
    {
      value: 5,
      name: "Jacket",
      text: "許家源",
    },
    {
      value: 6,
      name: "Huang",
      text: "黃聖雯",
    },
    {
      value: 13,
      name: "Charlie",
      text: "李佳璋",
    },
    {
      value: 15,
      name: "Hsu",
      text: "許晉瑞",
    },
    {
      value: 44,
      name: "Vic",
      text: "吳承陽",
    },
    {
      value: 68,
      name: "Yoyu",
      text: "賴幼羽",
    },
    {
      value: 70,
      name: "Steven",
      text: "陳軒弘",
    },
    {
      value: 75,
      name: "Grace",
      text: "王儷萍",
    },
    {
      value: 89,
      name: "Tea",
      text: "鄭揚弘",
    },
    {
      value: 91,
      name: "Joyce",
      text: "廖嘉惠",
    },
    {
      value: 92,
      name: "Vita",
      text: "胡秀玲",
    },
    {
      value: 94,
      name: "Angela",
      text: "沈冠妤",
    },
    {
      value: 95,
      name: "Jervis",
      text: "楊振維",
    },
    {
      value: 97,
      name: "Jackey",
      text: "洪立智",
    },
    {
      value: 99,
      name: "Chenhul",
      text: "陳錦惠",
    },
    {
      value: 104,
      name: "Shane",
      text: "沈昱瑋",
    },
    {
      value: 120,
      name: "Cater",
      text: "楊舜菘",
    },
    {
      value: 123,
      name: "XMing",
      text: "李祥銘",
    },
    {
      value: 124,
      name: "Yang",
      text: "楊柏育",
    },
    {
      value: 126,
      name: "Jeff",
      text: "陳天佑",
    },
    {
      value: 0,
      name: "Else",
      text: "其他",
    },
  ];
}

export function getByValue(value) {
  const type = getAll().find(function (item) {
    return item.value == value;
  });
  return type;
}
