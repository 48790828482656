export function getAll(){
  return [
    {
      value: 0,
      name: "Hour",
      text: "小時"
    },
    {
      value: 1,
      name: "Day",
      text: "日"
    },
    {
      value: 2,
      name: "Month",
      text: "月"
    },
    {
      value: 3,
      name: "Season",
      text: "季"
    },
    {
      value: 4,
      name: "Year",
      text: "年"
    },
  ];
}
