import { httpGet, httpPost } from '@/request/http'
import printJS from 'print-js'
import func from '@/funcs'

export function get(url, params) {
  httpGet(url, params, { responseType: 'blob' })
    .then((data) => {
      const pdfFile = new Blob([data], {
        type: 'application/pdf',
      })
      const pdfUrl = URL.createObjectURL(pdfFile)
      printJS(pdfUrl)
    })
    .catch(() => {
      func.message.error('執行失敗')
    })
}

export function post(url, params) {
  httpPost(url, params, { responseType: 'blob' })
    .then((data) => {
      const pdfFile = new Blob([data], {
        type: 'application/pdf',
      })
      const pdfUrl = URL.createObjectURL(pdfFile)
      printJS(pdfUrl)
    })
    .catch(() => {
      func.message.error('執行失敗')
    })
}
