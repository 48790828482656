<template>
  <div :class="classCol" class="form-group">
    <label :for="id">{{labelText}}</label>
    <slot />
  </div>
</template>

<script>
export default {
  props: {
    degree: Number,
    id: String,
    labelText: String
  },
  computed: {
    classCol() {
      switch (this.degree) {
        case 6:
          return "col-md-6";
        case 12:
          return "col-12";
        default:
          return "col-md-6 col-lg-4";
      }
    }
  }
};
</script>