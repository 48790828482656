<template>
  <div class="d-inline-block">
    <button
      v-if="btnName"
      class="btn btn-success py-2 px-4"
      style="border-color: transparent"
      :data-original-title="`${$t('button.import')}`"
      data-target="#importModal"
      type="button"
      @click="showModal"
    >
      {{ btnName }}
    </button>
    <button
      v-else
      class="btn btn-outline-success py-2 px-4"
      style="border-color: transparent"
      :data-original-title="`${$t('button.import')}`"
      data-target="#importModal"
      type="button"
      @click="showModal"
    >
      <i class="fas fa-file-upload"></i>
    </button>
    <div
      class="modal fade"
      id="importModal"
      tabindex="-1"
      role="dialog"
      aria-labelledby="exampleModalCenterTitle"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">{{ title }}</h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
              @click="resetFile"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <form>
              <div class="fallback">
                <input
                  name="file"
                  ref="fileref"
                  type="file"
                  @change="fileChange"
                  @click="removeFile"
                />
                <button type="button" @click="onImport">
                  {{ $t("button.upload") }}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import $ from "jquery";

export default {
  data() {
    return {
      file: "",
    };
  },
  props: ["title", "btnName", "nesWarehouse", "warehouse"],
  methods: {
    removeFile(e) {
      e.target.value = null;
    },
    showModal() {
      if (this.nesWarehouse && !this.warehouse) {
        this.$func.message.alert("請選擇倉庫 !");
        return;
      }
      $("#importModal").modal("toggle");
    },
    fileChange(e) {
      this.file = e.target.files[0];
    },
    onImport() {
      this.$func.message
        .importCheck(
          "匯入資料量較多時，請耐心等候<br>「切勿重新整理」，以免重複匯入 !",
          "確定匯入檔案嗎 ?"
        )
        .then((result) => {
          if (result.isConfirmed) {
            this.$emit("onImport", this.file);
          }
        });
      this.resetFile();
    },
    resetFile() {
      $("#importModal").modal("toggle");
      setTimeout(() => {
        this.$refs.fileref.value = null;
      }, 500);
    },
  },
};
</script>
