<template>
  <div class="bg">
    <img class="bg-img" src="~@/imgs/LoginBackGround.png" />
    <form class="login" @submit.prevent="login">
      <div class="login-header">
        <img class="login-logo" src="~@/imgs/LoginLogo.png" />
      </div>
      <div class="login-body">
        <input
          type="text"
          v-model="account"
          class="form-control my-2"
          placeholder="Account"
          required
        />
        <input
          type="password"
          v-model="password"
          class="form-control"
          placeholder="Password"
          required
        />
        <button class="btn btn-lg btn-primary btn-block mt-4" type="submit">
          Sign in
        </button>

        <p class="mt-4 text-muted">
          Copyright &copy; 2022
          <br />百揚資訊科技有限公司 <br />{{ version }}
        </p>
      </div>
    </form>
  </div>
</template>

<script>
export default {
  data() {
    return {
      account: "",
      password: "",
      version: require("@/../package.json").version,
    };
  },
  methods: {
    login() {
      const password = this.$func.crypto.encrypt(this.password);
      const vm = this;
      this.$api.userAccounts
        .login(vm.account, password, vm.businessId)
        .then(() => {
          vm.$router.push("/");
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.bg {
  position: fixed;
  width: 100%;
  height: 100%;
  color: black;
}
.bg-img {
  width: 100%;
  height: 100%;
  position: absolute;
}
.login {
  top: 80px;
  position: relative;
  width: 320px;
  padding: 20px;
  background-color: white;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  border-radius: 25px;
  color: white;
  .login-header {
    .login-logo {
      height: 150px;
    }
  }
  .login-body {
    padding: 20px;
    padding-bottom: 0;
    input {
      font-size: 18px;
    }
  }
}
</style>
