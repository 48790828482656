import i18n from "@/langs/i18n";

export function getAll() {
  return [
    {
      value: 0,
      name: "None",
      text: `${i18n.t("option.customerSaleType.None")}`,
    },
    {
      value: 1,
      name: "SalPrice",
      text: `${i18n.t("option.customerSaleType.SalPrice")}`,
    },
    {
      value: 2,
      name: "FreePrice",
      text: `${i18n.t("option.customerSaleType.FreePrice")}`,
    },
    {
      value: 3,
      name: "Discount",
      text: `${i18n.t("option.customerSaleType.Discount")}`,
    },
  ];
}

export function getByName(name) {
  const type = getAll().find(function (item) {
    return item.name.toLowerCase() == name.toLowerCase();
  });
  return type;
}

export function getByValue(value) {
  const type = getAll().find(function (item) {
    return item.value == value;
  });
  return type;
}
