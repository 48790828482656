<template>
  <basic-selects
    :trigger="trigger"
    :isMultiple="isMultiple"
    :columns="columns"
    :items="suppliers"
    :title="`${$t('title.selectsupplier')}`"
    idProperty="SupplierId"
    nameProperty="SupplierName"
    allSelectId="supplierAllSelectBox"
    @onSaved="onSaved"
    :id="id"
    :pagination="pagination"
    @getItems="getItems"
    sortBy="SupplierNumber"
    :defaultItems="defaultItems"
    viewName="SupplierSelects"
    :searchTypes="searchTypes"
  ></basic-selects>
</template>

<script>
import basicSelects from "./BasicSelects";

export default {
  data() {
    return {
      suppliers: [],
      columns: [
        { name: "SupplierNumber" },
        { name: "SupplierName" },
        { name: "BusinessNumber" },
        { name: "Telephone" },
      ],
      id: "supplierSelectModal",
      searchTypes: this.$enum.supplierSearchType.getAll(),
      pagination: {},
    };
  },
  props: {
    trigger: false,
    isMultiple: false,
    modalId: undefined,
    defaultItems: undefined,
  },
  watch: {
    modalId: {
      handler(value) {
        if (value) this.id = value;
      },
      immediate: true,
    },
    trigger() {
      this.getItems();
    },
  },
  components: {
    basicSelects,
  },
  methods: {
    onSaved(item) {
      this.$emit("onSaved", item);
    },
    getItems(dTO) {
      const vm = this;
      this.$api.suppliers.getPages(dTO).then((data) => {
        vm.suppliers = data.Datas;
        vm.pagination = data.Pagination;
      });
    },
  },
};
</script>
