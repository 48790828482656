import i18n from "@/langs/i18n";

export function getAll() {
  return [
    {
      value: 1,
      name: "YTax",
      text: `${i18n.t("option.taxType.YTax")}`,
    },
    {
      value: 2,
      name: "ZTax",
      text: `${i18n.t("option.taxType.ZTax")}`,
    },
    {
      value: 3,
      name: "NTax",
      text: `${i18n.t("option.taxType.NTax")}`,
    },
  ];
}

export function getAllWithAll() {
  var types = getAll();
  types.splice(0, 0, {
    value: -1,
    name: "All",
    text: `${i18n.t("option.All")}`,
  });
  types.splice(1, 0, {
    value: 0,
    name: "NoInvoice",
    text: `${i18n.t("option.taxType.NoInvoice")}`,
  });
  return types;
}

export function getAllWithFood() {
  var types = getAll();
  types.splice(0, 0, {
    value: 0,
    name: "NoInvoice",
    text: `${i18n.t("option.taxType.NoInvoice")}`,
  });
  return types;
}

export function getByName(name) {
  const type = getAll().find(function (item) {
    return item.name.toLowerCase() == name.toLowerCase();
  });
  return type;
}
