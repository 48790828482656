<template>
  <div>
    <search-and-table-part
      :pagination="pagination"
      @getItems="getForms"
      @editItem="editForm"
      :items="forms"
      :columns="columns"
      :idProperty="idProperty"
      :hasAdd="false"
      :hasEdit="true"
      :hasRemove="false"
      :hasCheckDetail="true"
      :getTrigger="getTrigger"
      @trdbClicked="checkFormData"
      :isSortable="true"
    >
      <template slot="searchArea">
        <div class="row">
          <div class="col-lg-6">
            <div class="form-group row">
              <label
                for="searchInquiryId"
                class="col-3 col-md-2 col-lg-3 col-form-label"
                >ID:</label
              >
              <div class="col">
                <input
                  type="text"
                  class="form-control"
                  id="searchInquiryId"
                  v-model="searchInquiryId"
                />
              </div>
            </div>
          </div>
          <div class="col-lg-6">
            <div class="form-group row">
              <label
                for="searchName"
                class="col-3 col-md-2 col-lg-3 col-form-label"
                >名稱:</label
              >
              <div class="col">
                <input
                  type="text"
                  class="form-control"
                  id="searchName"
                  v-model="searchName"
                />
              </div>
            </div>
          </div>
          <div class="col-lg-6">
            <div class="form-group row">
              <label
                for="searchPhone"
                class="col-3 col-md-2 col-lg-3 col-form-label"
                >電話:</label
              >
              <div class="col">
                <input
                  type="text"
                  class="form-control"
                  id="searchPhone"
                  v-model="searchPhone"
                />
              </div>
            </div>
          </div>
          <div class="col-lg-6">
            <div class="form-group row">
              <label
                for="searchCompanyName"
                class="col-3 col-md-2 col-lg-3 col-form-label"
                >公司名稱:</label
              >
              <div class="col">
                <input
                  type="text"
                  class="form-control"
                  id="searchCompanyName"
                  v-model="searchCompanyName"
                />
              </div>
            </div>
          </div>
          <div class="col-lg-6">
            <div class="form-group row">
              <label
                for="searchStatusType"
                class="col-3 col-md-2 col-lg-3 col-form-label"
                >狀態:</label
              >
              <div class="col">
                <select
                  class="form-control"
                  id="searchStatusType"
                  v-model="searchStatusType"
                >
                  <option
                    v-for="statusType in statusTypes"
                    :key="statusType.value"
                    :value="statusType.value"
                  >
                    {{ statusType.text }}
                  </option>
                </select>
              </div>
            </div>
          </div>
          <div class="col-lg-6">
            <div class="form-group row">
              <label
                for="searchSalerType"
                class="col-3 col-md-2 col-lg-3 col-form-label"
                >業務員:</label
              >
              <div class="col">
                <select
                  class="form-control"
                  id="searchSalerType"
                  v-model="searchSalerType"
                >
                  <option
                    v-for="salerType in salerTypes"
                    :key="salerType.value"
                    :value="salerType.value"
                  >
                    {{ salerType.text }}
                  </option>
                </select>
              </div>
            </div>
          </div>
          <div class="col-lg-6">
            <div class="form-group row">
              <label
                for="searchSourceType"
                class="col-3 col-md-2 col-lg-3 col-form-label"
                >來源:</label
              >
              <div class="col">
                <select
                  class="form-control"
                  id="searchSourceType"
                  v-model="searchSourceType"
                >
                  <option
                    v-for="sourceType in sourceTypes"
                    :key="sourceType.value"
                    :value="sourceType.value"
                  >
                    {{ sourceType.text }}
                  </option>
                </select>
              </div>
            </div>
          </div>
        </div>
      </template>
      <template slot="dataButtonAreaFront" slot-scope="props">
        <button
          class="btn btn-outline-primary btn-icon"
          type="button"
          @click="checkFormData(forms[props.index])"
        >
          <i class="fas fas fa-eye"></i>
        </button>
      </template>
      <template slot="dataButtonArea" slot-scope="props">
        <button
          id="records"
          type="button"
          class="btn btn-outline-secondary btn-icon py-2 px-4"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
          @click="checkInquiry(forms[props.index])"
        >
          <i class="fa fa-list"></i>
        </button>
        <div class="dropdown-menu" aria-labelledby="records">
          <a
            v-if="isNew"
            class="dropdown-item"
            @click="checkRecords(forms[props.index], 0, '查看記錄')"
            >查看記錄</a
          >
          <a
            v-if="isWorkable"
            class="dropdown-item"
            @click="checkRecords(forms[props.index], 1, '回電')"
            >回電</a
          >
          <a
            v-if="isWorkable"
            class="dropdown-item"
            @click="checkRecords(forms[props.index], 2, '成交')"
            >成交</a
          >
          <a
            v-if="isWorkable"
            class="dropdown-item"
            @click="checkRecords(forms[props.index], 3, '刪除')"
            >刪除</a
          >
          <a
            v-if="isWorkable"
            class="dropdown-item"
            @click="checkRecords(forms[props.index], 4, '拒絕')"
            >拒絕</a
          >
        </div>
      </template>
    </search-and-table-part>

    <modal-part
      modalId="recordsDetail"
      modalClass="modal-md"
      :title="recordTitle"
      :hasButtons="hasButtons"
      :isShow="isRecordsShow"
      @onClosed="closeRecordsDetail"
      @onSaved="saveRecords"
    >
      <div slot="item">
        <div v-if="isRecordsEdit" class="row">
          <div class="col-12 form-group">
            <label for="date">{{ recordTitle }}日期</label>
            <input
              id="date"
              type="date"
              class="form-control"
              v-model="newRecords.Date"
              onclick="this.showPicker()"
            />
          </div>
          <div class="col-12 form-group">
            <label for="memo">備註</label>
            <input
              id="memo"
              type="text"
              class="form-control"
              v-model="newRecords.Memo"
            />
          </div>
        </div>
        <div v-else class="row">
          <div
            class="col-12 p-0"
            v-for="(record, index) in records"
            :key="index"
          >
            <div class="d-flex">
              <label for="status" class="col-3">處理記錄:</label>
              <span class="ml-2 col-9">{{ record.StatusTypeText }}</span>
            </div>
            <div class="d-flex">
              <label for="date" class="col-3">日期:</label>
              <span class="ml-2 col-9">{{ record.Date }}</span>
            </div>
            <div class="d-flex">
              <label for="memo" class="col-3">備註:</label>
              <span class="ml-2 col-9">{{ record.Memo }}</span>
            </div>
            <div v-if="index + 1 < records.length" class="col-12 p-0">
              <hr class="mt-2" />
            </div>
          </div>
        </div>
      </div>
    </modal-part>

    <ushowFormData
      :item="form"
      :isShow="isShow"
      :isEdit="isEdit"
      @onSaved="onSaved"
      @onClosed="onClosed"
    ></ushowFormData>
  </div>
</template>

<script>
import searchAndTablePart from "@/components/parts/searchAndTablePart";
import ushowFormData from "@/components/datas/UShowFormData";
import modalPart from "@/components/parts/modalPart";
import date from "@/filters/date";

export default {
  data() {
    return {
      salerTypes: this.$enum.salerType.getAll(),
      statusTypes: this.$enum.ushowStatusType.getAll(),
      sourceTypes: this.$enum.sourceType.getAll(),
      searchInquiryId: null,
      searchName: null,
      searchPhone: null,
      searchSalerType: -1,
      searchStatusType: -1,
      searchSourceType: -1,
      searchCompanyName: null,
      forms: [],
      form: {},
      columns: [
        { name: "InquiryId" },
        { name: "StatusTypeText" },
        { name: "CreateTimeText" },
        { name: "SalerText" },
        { name: "Name" },
        { name: "Phone" },
        { name: "Gender" },
        { name: "Email" },
        { name: "CompanyName" },
        { name: "Address" },
        { name: "SourceTypeText" },
        { name: "SourceMemo" },
        { name: "BusinessTypeText" },
        { name: "BusinessMemo" },
        { name: "Memo" },
      ],
      pagination: {},
      idProperty: "InquiryId",
      getTrigger: false,
      isShow: false,
      isEdit: false,
      isWorkable: false,
      records: [],
      newRecords: {
        Date: date(Date.now()),
      },
      recordTitle: "",
      isRecordsShow: false,
      isRecordsEdit: false,
      hasButtons: false,
      isNew: false,
    };
  },
  components: {
    searchAndTablePart,
    ushowFormData,
    modalPart,
  },
  methods: {
    checkFormData(form) {
      const vm = this;
      if (form) {
        this.$api.ushow
          .get(form.InquiryId)
          .then((data) => {
            vm.form = data;
            vm.isShow = !vm.isShow;
          })
          .catch(() => vm.$func.message.alert("查無此資料 !"));
      } else {
        this.$func.message.alert("請選擇一筆資料 !");
      }
    },
    editForm(form) {
      if (form.StatusType != 0 && form.StatusType != 1) {
        this.$func.message.alert(`無法編輯已${form.StatusTypeText}的需求信`);
        return;
      }
      this.isEdit = true;
      this.checkFormData(form);
    },
    getForms(dTO) {
      if (this.searchInquiryId) {
        dTO.Id = parseInt(this.searchInquiryId);
      } else {
        dTO.Id = null;
      }
      if (this.searchName) {
        dTO.Name = this.searchName;
      } else {
        dTO.Name = null;
      }
      if (this.searchPhone) {
        dTO.Phone = this.searchPhone;
      } else {
        dTO.Phone = null;
      }
      if (this.searchCompanyName) {
        dTO.CompanyName = this.searchCompanyName;
      } else {
        dTO.CompanyName = null;
      }
      dTO.Saler = this.searchSalerType;
      dTO.StatusType = this.searchStatusType;
      dTO.SourceType = this.searchSourceType;

      const vm = this;
      this.$api.ushow.getPages(dTO).then((data) => {
        vm.forms = data.Datas;
        vm.forms.forEach((form) => {
          form.StatusTypeText = vm.$enum.ushowStatusType.getByValue(
            form.StatusType
          ).text;
          if (form.SourceType >= 0) {
            form.SourceTypeText = vm.$enum.sourceType.getByValue(
              form.SourceType
            ).text;
          }
          if (form.BusinessType >= 0) {
            form.BusinessTypeText = vm.$enum.businessType.getByValue(
              form.BusinessType
            ).text;
          }
          if (form.Saler == 0) {
            form.SalerText = form.SalerMemo;
          } else {
            form.SalerText = vm.$enum.salerType.getByValue(form.Saler).text;
          }
        });
        vm.pagination = data.Pagination;
      });
    },
    checkInquiry(form) {
      if (form) {
        if (form.StatusType == 0) {
          this.isNew = false;
        } else {
          this.isNew = true;
        }
        if (form.StatusType == 0 || form.StatusType == 1) {
          this.isWorkable = true;
        } else {
          this.isWorkable = false;
        }
      } else {
        this.$func.message.alert("請選擇一筆資料 !");
      }
    },
    checkRecords(form, status, title) {
      if (status == 0) {
        const vm = this;
        this.isRecordsEdit = false;
        this.hasButtons = false;
        this.$api.ushow.getRecords(form.InquiryId).then((data) => {
          vm.records = data;
          vm.records.forEach((record) => {
            record.StatusTypeText = vm.$enum.ushowStatusType.getByValue(
              record.StatusType
            ).text;
          });
          this.isRecordsShow = !this.isRecordsShow;
        });
      } else {
        const n = new Date();
        this.newRecords = {
          StatusType: status,
          InquiryId: form.InquiryId,
          Date: `${n.getFullYear()}-${(n.getMonth() + 1)
            .toString()
            .padStart(2, "0")}-${n.getDate().toString().padStart(2, "0")}`,
          Memo: "",
        };
        this.isRecordsEdit = true;
        this.hasButtons = true;
        this.isRecordsShow = !this.isRecordsShow;
      }
      this.currentStatus = status;
      this.recordTitle = title;
    },
    closeRecordsDetail() {
      this.isRecordsShow = !this.isRecordsShow;
    },
    saveRecords() {
      const vm = this;
      this.$func.message
        .confirm(
          `確定將此需求信狀態改為"${
            vm.$enum.ushowStatusType.getByValue(vm.newRecords.StatusType).text
          }"嗎 ?`
        )
        .then((result) => {
          if (result.isConfirmed) {
            vm.$api.ushow.saveRecords(vm.newRecords).then(() => {
              vm.closeRecordsDetail();
              vm.getTrigger = !vm.getTrigger;
              vm.$func.message.success("儲存成功");
            });
          }
        });
    },
    onSaved() {
      this.isShow = !this.isShow;
      this.isEdit = false;
      this.getTrigger = !this.getTrigger;
    },
    onClosed() {
      this.isShow = !this.isShow;
      this.isEdit = false;
    },
  },
  mounted() {
    this.salerTypes.splice(0, 0, { value: -1, name: "All", text: "全部" });
  },
};
</script>
