import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  strict: true,
  state: {
    isLoading: false,
    productType: 0,
    inventoryMachineType: 0,
    breadcrumbs: [],
    storeId: undefined,
    currentLang: window.localStorage["lang"] || "tw",
    isSaveOrClose: false,
  },
  actions: {
    setLanguage(context, status) {
      context.commit("LANGUAGE", status);
    },
    updateLoading(context, status) {
      context.commit("LOADING", status);
    },
    setProductType(context, type) {
      context.commit("PRODUCT", type);
    },
    setInventoryMachineType(context, type) {
      context.commit("INVENTORYMACHINE", type);
    },
    setStoreId(context, id) {
      context.commit("STOREID", id);
    },
    addBreadCrumb(context, item) {
      context.commit("PUSHBREADCRUMB", item);
    },
    clearBreadCrumbs(context) {
      context.commit("CLEARBREADCRUMB");
    },
    setSaveOrClose(context, value) {
      context.commit("SAVEORCLOSE", value);
    },
  },
  mutations: {
    LANGUAGE(state, lang) {
      state.currentLang = lang;
    },
    LOADING(state, status) {
      state.isLoading = status;
    },
    PRODUCT(state, type) {
      state.productType = type;
    },
    INVENTORYMACHINE(state, type) {
      state.inventoryMachineType = type;
    },
    PUSHBREADCRUMB(state, item) {
      state.breadcrumbs.push(item);
    },
    STOREID(state, id) {
      state.storeId = id;
    },
    CLEARBREADCRUMB(state) {
      state.breadcrumbs = [];
    },
    SAVEORCLOSE(state, value) {
      state.isSaveOrClose = value;
    },
  },
});
