import { httpGet, httpPost } from "@/request/http";

let path = "/reports";

export default {
  getTimeStatistic(params) {
    return httpPost(`${path}/timestatistic`, params);
  },
  getEmployeeAnalysis(params) {
    return httpPost(`${path}/employeeanalysis`, params);
  },
  getFoodSaleStatistic(params) {
    return httpPost(`${path}/foodsalestatistic`, params);
  },
  getFoodSaleDetail(params) {
    return httpPost(`${path}/foodsaledetail`, params);
  },
  getFoodDayStatistic(params) {
    return httpPost(`${path}/fooddaystatistic`, params);
  },
  getFoodMajorKindStatistic(params) {
    return httpPost(`${path}/foodmajorkindstatistic`, params);
  },
  getFoodKindStatistic(params) {
    return httpPost(`${path}/foodkindstatistic`, params);
  },
  getFree(params) {
    return httpPost(`${path}/free`, params);
  },
  getCustomerFree(params) {
    return httpPost(`${path}/customerfree`, params);
  },
  getInvoice(params) {
    return httpPost(`${path}/invoice`, params);
  },
  getInvoicePrize(params) {
    return httpPost(`${path}/invoiceprize`, params);
  },
  getWorkRecord(params) {
    return httpPost(`${path}/workrecord`, params);
  },
  getSale(params) {
    return httpPost(`${path}/sale`, params);
  },
  getSaler(params) {
    return httpPost(`${path}/saler`, params);
  },
  getCustomer(params) {
    return httpPost(`${path}/customer`, params);
  },
  getCustomerFoodSale(params) {
    return httpPost(`${path}/customerfoodsale`, params);
  },
  getCustomerSaleStatistic(params) {
    return httpPost(`${path}/customersalestatistic`, params);
  },
  getPGTalkDeposit(params) {
    return httpPost(`${path}/pgtalkdeposit`, params);
  },

  getSupplier(params) {
    return httpPost(`${path}/supplier`, params);
  },
  getSupplierFoodSale(params) {
    return httpPost(`${path}/supplierfoodsale`, params);
  },
  getSupplierFoodSaleOriginal(params) {
    return httpPost(`${path}/supplierfoodsaleoriginal`, params);
  },
  getSupplierPurchaseOrder(params) {
    return httpPost(`${path}/supplierpurchaseorder`, params);
  },
  getFoodPurchaseOrder(params) {
    return httpPost(`${path}/foodpurchaseorder`, params);
  },
  getFoodPurchase(params) {
    return httpPost(`${path}/foodpurchase`, params);
  },
  getStock(params) {
    return httpPost(`${path}/stock`, params);
  },
  getSafeQty(params) {
    return httpPost(`${path}/safeqty`, params);
  },
  getFoodMovement(params) {
    return httpPost(`${path}/foodmovement`, params);
  },
  getPay(params) {
    return httpPost(`${path}/pay`, params);
  },
  getSalePay(params) {
    return httpPost(`${path}/salepay`, params);
  },
  getTasteStatistic(params) {
    return httpPost(`${path}/tastestatistic`, params);
  },
  getDeposit(params) {
    return httpPost(`${path}/deposit`, params);
  },
  getInventoryPAndL(params) {
    return httpPost(`${path}/inventorypandl`, params);
  },
  getClassSale(params) {
    return httpPost(`${path}/classsale`, params);
  },
  getPurchase(params) {
    return httpPost(`${path}/purchase`, params);
  },
  getPurchaseReturn(params) {
    return httpPost(`${path}/purchasereturn`, params);
  },
  getPurchaseOrder(params) {
    return httpPost(`${path}/purchaseorder`, params);
  },
  getStockAdjust(params) {
    return httpPost(`${path}/stockadjust`, params);
  },
  getStockAdjustDirectory(params) {
    return httpPost(`${path}/stockadjustdirectory`, params);
  },
  getStockTransfer(params) {
    return httpPost(`${path}/stocktransfer`, params);
  },
  getInventory(params) {
    return httpPost(`${path}/inventory`, params);
  },
  getPeople(params) {
    return httpPost(`${path}/people`, params);
  },
  getIntegral(params) {
    return httpPost(`${path}/integral`, params);
  },
  getSaleOrder(params) {
    return httpPost(`${path}/saleorder`, params);
  },
  getOrderFoodStatistic(params) {
    return httpPost(`${path}/orderfoodstatistic`, params);
  },
  getCoupon(params) {
    return httpPost(`${path}/coupon`, params);
  },
  getCollectionPayment(params) {
    return httpPost(`${path}/collectionpayment`, params);
  },
  getCombinationTrade(params) {
    return httpPost(`${path}/combinationTrade`, params);
  },
  getSeperationTrade(params) {
    return httpPost(`${path}/seperationTrade`, params);
  },
  getCustomerStock(params) {
    return httpPost(`${path}/customerstock`, params);
  },
  getCustomerStockMovement(params) {
    return httpPost(`${path}/customerstockmovement`, params);
  },
  getStockBatch(params) {
    return httpPost(`${path}/stockbatch`, params);
  },
  getStockBatchMovement(params) {
    return httpPost(`${path}/stockbatchmovement`, params);
  },
  getSaleProfit(params) {
    return httpPost(`${path}/saleprofit`, params);
  },
  getERPSummary(params) {
    return httpPost(`${path}/erpsummary`, params);
  },
  getCreditCard(params) {
    return httpPost(`${path}/creditcard`, params);
  },
};
