export function getAll() {
  return [
    {
      value: 0,
      name: "None",
      text: "無",
    },
    {
      value: 1,
      name: "Table",
      text: "桌位",
    },
    {
      value: 2,
      name: "Sale",
      text: "銷貨單",
    },
  ];
}
