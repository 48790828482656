import { httpGet, httpPut, httpPost } from "@/request/http";

let path = "/paiyang";

export default {
  getPages(dTO) {
    return httpGet(`${path}/inquiries/pages`, dTO);
  },
  get(id) {
    return httpGet(`${path}/inquiries/${id}`);
  },
  put(id, item) {
    return httpPut(`${path}/inquiries/${id}`, item);
  },
  getRecords(id) {
    return httpGet(`${path}/inquiries/${id}/records`);
  },
  saveRecords(item) {
    return httpPost(`${path}/inquiry/records`, item);
  },
};
