<template>
  <div class="row">
    <div class="col-md-6">
      <div class="card card-table">
        <div class="card-header">{{ $t("modal.export") }}</div>
        <div class="card-body mb-3">
          <button class="btn btn-primary" type="button" @click="getFoods">
            {{ $t("button.food") }}
          </button>
          <button
            v-if="inventoryMachineType == 1"
            class="btn btn-primary"
            type="button"
            @click="getSuppliers"
          >
            {{ $t("button.supplier") }}
          </button>
          <button
            v-if="inventoryMachineType == 1"
            class="btn btn-primary"
            type="button"
            @click="getWarehouses"
          >
            {{ $t("button.warehouse") }}
          </button>
        </div>
      </div>
    </div>
    <div class="col-md-6" v-if="inventoryMachineType == 1">
      <div class="card card-table">
        <div class="card-header">{{ $t("modal.import") }}</div>
        <div class="card-body mb-3">
          <import-part
            @onImport="postTrade"
            :title="`${$t('modal.importinventory')}`"
            :btnName="`${$t('button.inventorylist')}`"
          />
        </div>
      </div>
    </div>
    <warehouse-select
      :trigger="warehouseTrigger"
      @onSaved="onWarehouseSelect"
    ></warehouse-select>
  </div>
</template>

<script>
import importPart from "@/components/ImportPart";
import warehouseSelect from "@/components/selects/WarehouseSelects";

export default {
  data() {
    return {
      warehouseTrigger: false,
      warehouse: {},
    };
  },
  components: {
    importPart,
    warehouseSelect,
  },
  computed: {
    inventoryMachineType() {
      return this.$store.state.inventoryMachineType;
    },
  },
  methods: {
    onWarehouseSelect(item) {
      this.warehouse = item;
      if (!this.warehouse) {
        this.$func.message.alert("請選擇倉庫");
        return;
      }
      if (this.inventoryMachineType == 1) {
        this.$api.foods.getCasio(this.warehouse.WarehouseId);
      } else {
        this.$api.foods.getTYSSO(this.warehouse.WarehouseId);
      }
    },
    getFoods() {
      this.warehouseTrigger = !this.warehouseTrigger;
    },
    getSuppliers() {
      this.$api.suppliers.getCasio();
    },
    getWarehouses() {
      this.$api.warehouses.getCasio();
    },
    postTrade(file) {
      let formData = new FormData();
      formData.append("file", file);
      this.$api.inventorys.postCasio(formData);
    },
  },
};
</script>
