export function getAll() {
  return [
    {
      value: 0,
      name: "Qty",
      text: "數量"
    },
    {
      value: 1,
      name: "Cost",
      text: "成本"
    },
    {
      value: 2,
      name: "TotalCost",
      text: "總成本"
    },
    {
      value: 3,
      name: "QtyAndCost",
      text: "數量和成本"
    }
  ];
}
