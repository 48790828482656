<template>
  <basic-selects
    :hasSequence="hasSequence"
    :trigger="trigger"
    :isMultiple="isMultiple"
    :columns="columns"
    :items="onlineOrderCategorys"
    :title="`${$t('title.selectfoodkind')}`"
    idProperty="OnlineOrderCategoryId"
    nameProperty="OnlineOrderCategoryName"
    allSelectId="onlineOrderCategoryAllSelectBox"
    @onSaved="onSaved"
    :id="id"
    :pagination="pagination"
    @getItems="getItems"
    sortBy="OnlineOrderCategoryNumber"
    :defaultItems="defaultItems"
    viewName="OnlineOrderCategorySelects"
  ></basic-selects>
</template>

<script>
import basicSelects from "./BasicSelects";

export default {
  data() {
    return {
      onlineOrderCategorys: [],
      columns: [
        {
          name: "OnlineOrderCategoryName",
        },
      ],
      id: "onlineOrderCategorySelectModal",
      pagination: {},
    };
  },
  props: {
    trigger: false,
    isMultiple: false,
    modalId: undefined,
    defaultItems: undefined,
    onlineOrderType: undefined,
    hasSequence: false,
  },
  watch: {
    modalId: {
      handler(value) {
        if (value) this.id = value;
      },
      immediate: true,
    },
    trigger() {
      this.getItems();
    },
  },
  components: {
    basicSelects,
  },
  methods: {
    onSaved(item) {
      this.$emit("onSaved", item);
    },
    getItems(dTO) {
      if (!this.onlineOrderType) {
        this.$func.message.alert("系統異常 !");
        return;
      }
      if (!dTO) {
        dTO = {};
      }
      dTO.type = this.onlineOrderType;
      const vm = this;
      this.$api.onlineOrderCategories.getPages(dTO).then((data) => {
        vm.onlineOrderCategorys = data.Datas;
        vm.pagination = data.Pagination;
      });
    },
  },
};
</script>
