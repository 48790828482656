import { httpGet, httpPost, httpDelete } from '@/request/http';

let path = '/customerlevels';

export default{
    get(id){
      return httpGet(`${path}/${id}`);
    },
    gets(){
      return httpGet(path);
    },
    postBatch(item){
      return httpPost(`${path}/batch` , item);
    }
}
