export function getAll() {
  return [
    {
      value: 0,
      name: "In",
      text: "內用",
    },
    {
      value: 1,
      name: "Out",
      text: "外帶",
    },
    {
      value: 2,
      name: "Delivery",
      text: "外送",
    },
    {
      value: 3,
      name: "PickSelf",
      text: "自取",
    },
  ];
}
