<template>
  <item-modal
    :isShowModal="isShow"
    :title="modalTitle"
    @onSaved="save"
    @onClosed="close"
  >
    <div slot="item">
      <div class="row">
        <div class="col-md-6">
          <div class="form-group">
            <label for="accountNumber">{{ $t("modal.number") }}</label>
            <input
              type="text"
              class="form-control"
              id="accountNumber"
              v-model="account.AccountNumber"
              required
            />
          </div>
        </div>
        <div class="col-md-6">
          <div class="form-group">
            <label for="accountName">{{ $t("modal.name") }}</label>
            <input
              type="text"
              class="form-control"
              id="accountName"
              v-model="account.AccountName"
              required
            />
          </div>
        </div>
        <div class="col-md-6">
          <div class="form-group">
            <label for="memo">{{ $t("modal.memo") }}</label>
            <input
              type="text"
              class="form-control"
              id="memo"
              v-model="account.Memo"
            />
          </div>
        </div>
      </div>
    </div>
  </item-modal>
</template>

<script>
import itemModal from "@/components/ItemModal";

export default {
  data() {
    return {
      account: {},
    };
  },
  props: ["isShow", "entity", "modalTitle"],
  watch: {
    entity(val) {
      this.account = val;
    },
  },
  components: {
    itemModal,
  },
  methods: {
    save() {
      const vm = this;
      this.$api.accounts
        .save(vm.account)
        .then(() => {
          vm.$emit("save");
        })
        .catch((error) => vm.$func.message.error(error.data.Message));
    },
    close() {
      this.$emit("close");
    },
  },
};
</script>
