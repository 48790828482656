<template>
  <div>
    <alert></alert>
    <navbar />
    <div class="container-fluid h-body">
      <div class="row">
        <main id="main-container" class="col-12 pt-3">
          <nav id="breadcrumb" aria-label="breadcrumb" v-if="hasBreads">
            <ol class="breadcrumb bg-transparent py-0">
              <li class="breadcrumb-item active">
                <router-link to="/">{{ $t("viewName.home") }}</router-link>
              </li>
              <li
                class="breadcrumb-item"
                v-for="(bread, index) in breadcrumbs"
                :key="index"
              >
                <router-link
                  :to="bread.path"
                  v-if="index != breadcrumbs.length - 1"
                  >{{ getName(bread.name) }}</router-link
                >
                <span v-else>{{ getName(bread.name) }}</span>
              </li>
            </ol>
          </nav>
          <router-view :key="$route.fullPath"></router-view>
        </main>
      </div>
    </div>
  </div>
</template>

<script>
import navbar from "@/components/Navbar";
import alert from "@/components/AlertMessage";

export default {
  components: {
    navbar,
    alert,
  },
  computed: {
    hasBreads() {
      return this.$route.name != "Home";
    },
    breadcrumbs() {
      return this.$store.state.breadcrumbs;
    },
  },
  watch: {
    $route(to) {
      if (
        !to.params["id"] &&
        to.name != "IntegralFoodKind" &&
        to.name != "IntegralFood"
      )
        this.$store.dispatch("clearBreadCrumbs");
      this.$store.dispatch("addBreadCrumb", to);
    },
  },
  methods: {
    getName(name) {
      return this.$t(`viewName.${name.toLowerCase()}`);
    },
  },
  created() {
    if (this.$store.state.breadcrumbs.length == 0)
      this.$store.dispatch("addBreadCrumb", this.$route);
  },
};
</script>

<style lang="scss" scoped>
body {
  font-size: 0.875rem;
}

// .breadcrumb {
//   display: flex;
//   align-items: center;
// }

// .breadcrumb-item:last-child {
//   font-size: 20px;
// }
</style>
