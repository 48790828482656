import { httpGet } from "@/request/http";

let EMpath = "/error";
let TPpath = "/third-party";
let Spath = "/system";
let Npath = "/notification";
let Wpath = "/webhook";

export default {
  getErrorMessages(dTO) {
    return httpGet(`${EMpath}/logs`, dTO);
  },
  getErrorMessage(id) {
    return httpGet(`${EMpath}/log/${id}`);
  },
  getThirdParties(dTO) {
    return httpGet(`${TPpath}/logs`, dTO);
  },
  getThirdParty(id) {
    return httpGet(`${TPpath}/log/${id}`);
  },
  getSystemLogs(dTO) {
    return httpGet(`${Spath}/logs`, dTO);
  },
  getSystemLog(id) {
    return httpGet(`${Spath}/log/${id}`);
  },
  getNotificationLogs(dTO) {
    return httpGet(`${Npath}/logs`, dTO);
  },
  getNotificationLog(id) {
    return httpGet(`${Npath}/log/${id}`);
  },
  getWebhookLogs(dTO) {
    return httpGet(`${Wpath}/logs`, dTO);
  },
  getWebhookLog(id) {
    return httpGet(`${Wpath}/log/${id}`);
  },
};
