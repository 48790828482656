import { httpGet, httpPost, httpDelete } from "@/request/http";

let path = "/foodpromotions";

export default {
  getFoods(promotionId) {
    return httpGet(`promotions/${promotionId}/foods`);
  },
  getFoodsByDet(promotionDetId) {
    return httpGet(`promotiondets/${promotionDetId}/foods`);
  },
  postFoods(dto) {
    return httpPost(`${path}/addbatch`, dto);
  },
  removeFoods(dto) {
    return httpPost(`${path}/deletebatch`, dto);
  }
};
