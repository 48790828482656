<template>
  <div class="card card-table">
    <div class="card-header">
      <button
        v-if="!hasShowmore && !hasDigiwin"
        type="button"
        class="btn btn-outline-primary py-2 px-4"
        style="border-color: transparent"
        @click="saveFoods"
      >
        <i class="fas fa-check"></i>
      </button>
      <button
        v-if="!hasShowmore && !hasDigiwin"
        type="button"
        class="btn btn-outline-secondary py-2 px-4"
        style="border-color: transparent"
        @click="autoFoodName"
      >
        <i class="fas fa-pencil-alt"></i>
      </button>
    </div>
    <div class="card-body card-table-body">
      <table-part
        :columns="columns"
        :idProperty="idProperty"
        tableName="SpecificationFoods"
        :noHide="true"
      >
        <!-- <template slot="th-before">
            <th style="width: 80px">{{ $t("columnName.remove") }}</th>
          </template> -->
        <tbody slot="tbody">
          <tr v-for="(spec, index) in specificationFoods" :key="index">
            <!-- <td>
                <button type="button" class="btn btn-outline-danger btn-icon">
                  <i class="fas fa-trash-alt"></i>
                </button>
              </td> -->
            <td>{{ spec.SpecificationName }}</td>
            <td>
              <input
                class="form-control"
                type="text"
                v-model="spec.FoodNumber"
                :readonly="hasShowmore || hasDigiwin"
              />
            </td>
            <td>
              <input
                class="form-control"
                type="text"
                v-model="spec.FoodName"
                :readonly="hasShowmore || hasDigiwin"
              />
            </td>
            <td>
              <input
                class="form-control"
                type="number"
                v-model="spec.SalPrice1"
                :readonly="hasShowmore || hasDigiwin"
              />
            </td>
            <td>
              <input
                class="form-control"
                type="number"
                v-model="spec.SalPrice2"
                :readonly="hasShowmore || hasDigiwin"
              />
            </td>
            <td>
              <input
                class="form-control"
                type="number"
                v-model="spec.SalPrice3"
                :readonly="hasShowmore || hasDigiwin"
              />
            </td>
            <td>
              <input
                class="form-control"
                type="number"
                v-model="spec.SalPrice4"
                :readonly="hasShowmore || hasDigiwin"
              />
            </td>
            <td>
              <input
                class="form-control"
                type="number"
                v-model="spec.SalPrice5"
                :readonly="hasShowmore || hasDigiwin"
              />
            </td>
            <td>
              <input
                class="form-control"
                type="number"
                v-model="spec.SalPrice6"
                :readonly="hasShowmore || hasDigiwin"
              />
            </td>
            <td>
              <input
                class="form-control"
                type="text"
                v-model="spec.Barcode"
                :readonly="hasShowmore || hasDigiwin"
              />
            </td>
            <td>
              <input
                class="form-control"
                type="text"
                placeholder="請輸入商品備註"
                v-model="spec.Memo"
                :readonly="hasShowmore || hasDigiwin"
              />
            </td>
          </tr>
        </tbody>
      </table-part>
    </div>
  </div>
</template>

<script>
import tablePart from "@/components/parts/tablePart";

export default {
  data() {
    return {
      specificationFoods: [],
      originSpecifications: [],
      pagination: {},
      columns: [
        {
          name: "SpecificationName",
        },
        {
          name: "FoodNumber",
        },
        {
          name: "FoodName",
        },
        {
          name: "SalPrice1",
        },
        {
          name: "SalPrice2",
        },
        {
          name: "SalPrice3",
        },
        {
          name: "SalPrice4",
        },
        {
          name: "SalPrice5",
        },
        {
          name: "SalPrice6",
        },
        {
          name: "Barcode",
        },
        {
          name: "Memo",
        },
      ],
      idProperty: "SpecificationId",
      nameProperty: "SpecificationName",
      getTrigger: false,
      currentIndex: -1,
    };
  },
  props: ["specifications"],
  components: {
    tablePart,
  },
  computed: {
    hasDigiwin() {
      return window.localStorage["erpType"] == 3;
    },
    hasShowmore() {
      return window.localStorage["hasShowmore"] == "true";
    },
  },
  watch: {
    specifications(value) {
      this.specificationFoods = [];
      this.originSpecifications = value;
      this.originSpecifications.SpecificationFoods.forEach((food) => {
        food.Specifications = [];
        food.SpecificationIds.forEach((id) => {
          let matchGroup = this.originSpecifications.SpecificationGroups.find(
            (group) =>
              group.Specifications.some((gs) => gs.SpecificationId == id)
          );
          if (matchGroup) {
            food.Specifications.push({
              SpecificationId: matchGroup.Specifications.find(
                (item) => item.SpecificationId == id
              ).SpecificationId,
              SpecificationName: matchGroup.Specifications.find(
                (item) => item.SpecificationId == id
              ).SpecificationName,
              SpecificationTypeId: matchGroup.Specifications.find(
                (item) => item.SpecificationId == id
              ).SpecificationTypeId,
            });
          }
        });
      });
      this.originSpecifications.SpecificationFoods.forEach((food) => {
        food.Specifications.sort(this.sortSpecification);
      });
      this.originSpecifications.SpecificationFoods.sort(function (a, b) {
        var numA = parseInt(a.Food.FoodNumber.split("-")[0], 10);
        var numB = parseInt(b.Food.FoodNumber.split("-")[0], 10);
        if (numA === numB) {
          var suffixA = parseInt(a.Food.FoodNumber.split("-")[1], 10) || 0;
          var suffixB = parseInt(b.Food.FoodNumber.split("-")[1], 10) || 0;
          return suffixA - suffixB;
        }
        return numA - numB;
      });

      if (this.originSpecifications.SpecificationGroups.length > 0) {
        this.generateSpecification(
          this.originSpecifications.SpecificationGroups,
          0,
          [],
          this.specificationFoods
        );
      }
    },
  },
  methods: {
    autoFoodName() {
      this.specificationFoods.forEach((food) => {
        food.FoodName =
          this.originSpecifications.MainSpecificationFood.FoodName +
          ` (${food.SpecificationName})`;
      });
    },
    checkTypes(currentSpecification) {
      return this.originSpecifications.SpecificationFoods.findIndex((food) => {
        const foodIds = food.SpecificationIds.map((id) => id);
        const newIds = currentSpecification.map((spec) => spec.SpecificationId);
        if (JSON.stringify(foodIds.sort()) === JSON.stringify(newIds.sort())) {
          return true;
        }
      });
    },
    sortSpecification(itemA, itemB) {
      let indexA = this.originSpecifications.SpecificationGroups.findIndex(
        (group) =>
          group.SpecificationType.SpecificationTypeId ===
          itemA.SpecificationTypeId
      );
      let indexB = this.originSpecifications.SpecificationGroups.findIndex(
        (group) =>
          group.SpecificationType.SpecificationTypeId ===
          itemB.SpecificationTypeId
      );
      return indexA - indexB;
    },
    generateSpecification(
      arrays,
      index,
      currentSpecification,
      specificationFoods
    ) {
      if (index === arrays.length) {
        let spec = {
          Specifications: currentSpecification.slice(),
          SpecificationName: currentSpecification
            .slice()
            .map((obj) => obj.SpecificationName)
            .join(", "),
          FoodNumber: "",
          FoodName: "",
          SalPrice1: "",
          SalPrice2: "",
          SalPrice3: "",
          SalPrice4: "",
          SalPrice5: "",
          SalPrice6: "",
          Barcode: "",
          Memo: "",
        };
        const allTypesExistIndex = this.checkTypes(currentSpecification);
        if (allTypesExistIndex > -1) {
          spec.FoodNumber =
            this.originSpecifications.SpecificationFoods[
              allTypesExistIndex
            ].Food.FoodNumber;
          spec.FoodName =
            this.originSpecifications.SpecificationFoods[
              allTypesExistIndex
            ].Food.FoodName;
          spec.SalPrice1 =
            this.originSpecifications.SpecificationFoods[
              allTypesExistIndex
            ].Food.SalPrice1;
          spec.SalPrice2 =
            this.originSpecifications.SpecificationFoods[
              allTypesExistIndex
            ].Food.SalPrice2;
          spec.SalPrice3 =
            this.originSpecifications.SpecificationFoods[
              allTypesExistIndex
            ].Food.SalPrice3;
          spec.SalPrice4 =
            this.originSpecifications.SpecificationFoods[
              allTypesExistIndex
            ].Food.SalPrice4;
          spec.SalPrice5 =
            this.originSpecifications.SpecificationFoods[
              allTypesExistIndex
            ].Food.SalPrice5;
          spec.SalPrice6 =
            this.originSpecifications.SpecificationFoods[
              allTypesExistIndex
            ].Food.SalPrice6;
          spec.Barcode =
            this.originSpecifications.SpecificationFoods[
              allTypesExistIndex
            ].Food.Barcode;
          spec.Memo =
            this.originSpecifications.SpecificationFoods[
              allTypesExistIndex
            ].Food.Memo;
        } else {
          spec.FoodNumber =
            this.originSpecifications.MainSpecificationFood.FoodNumber + "-";
          spec.FoodName =
            this.originSpecifications.MainSpecificationFood.FoodName;
          spec.SalPrice1 =
            this.originSpecifications.MainSpecificationFood.SalPrice1;
          spec.Barcode = "";
          spec.Memo = "";
        }
        specificationFoods.push(spec);
        return;
      }

      for (let i = 0; i < arrays[index].Specifications.length; i++) {
        currentSpecification.push(arrays[index].Specifications[i]);
        this.generateSpecification(
          arrays,
          index + 1,
          currentSpecification,
          specificationFoods
        );
        currentSpecification.pop();
      }
    },
    currentIndexChanged(index) {
      this.currentIndex = index;
    },
    saveFoods() {
      const check = new Set();
      let duplicate = false;

      for (const food of this.specificationFoods) {
        if (check.has(food.FoodNumber)) {
          duplicate = true;
          break;
        }
        check.add(food.FoodNumber);
      }

      if (duplicate) {
        this.$func.message.alert("請確認所有商品編號設定均未重複");
        return;
      }

      this.$api.foods
        .saveFoodSpecifications(
          this.originSpecifications.MainSpecificationFood.FoodId,
          {
            MainSpecificationFoodId:
              this.originSpecifications.MainSpecificationFood.FoodId,
            SpecificationFoods: this.specificationFoods,
          }
        )
        .then(() => {
          this.$func.message.success("更新規格子商品成功 !");
          this.$emit("getItems");
        });
    },
  },
};
</script>
