import func from "@/funcs";

function getWithTax(price) {
  return Math.round(price * 1.05);
}

function getTax(price) {
  return Math.round(price * 0.05);
}

function getTaxByITax(price) {
  return Math.round((price / 1.05) * 0.05);
}

export function calculateTax(
  trade,
  tradeDets,
  roundType = 0,
  detailRoundType = 0,
  isFocused = false
) {
  let tradeTotal = 0;
  tradeDets.forEach((item) => {
    let originalPrice = item.OriginalPrice ? item.OriginalPrice : 0;
    if (!isFocused) {
      switch (detailRoundType) {
        case 1:
          item.Total = Math.round(func.math.mulFixed(item.Qty, originalPrice));
          break;
        case 2:
          item.Total = Math.ceil(func.math.mulFixed(item.Qty, originalPrice));
          break;
        case 3:
          item.Total = Math.floor(func.math.mulFixed(item.Qty, originalPrice));
          break;
        default:
          item.Total = func.math.mulFixed(item.Qty, originalPrice);
          break;
      }
    }
    item.TaxType = trade.TaxType;
    tradeTotal = func.math.addFixed(tradeTotal, item.Total);
  });

  if (!isFocused) {
    switch (roundType) {
      case 1:
        trade.Total = Math.ceil(tradeTotal);
        break;
      case 2:
        trade.Total = Math.floor(tradeTotal);
        break;
      default:
        trade.Total = Math.round(tradeTotal);
        break;
    }
  } else {
    trade.Total = tradeTotal;
  }

  trade.TaxPrice = 0;
  trade.YTaxPrice = 0;
  trade.NTaxPrice = 0;
  trade.ZTaxPrice = 0;
  switch (trade.TaxType) {
    case 1:
      if (trade.IsTaxAddition) {
        trade.YTaxPrice = trade.Total;
        trade.TaxPrice = getTax(trade.YTaxPrice);
        trade.Total = func.math.addFixed(trade.YTaxPrice, trade.TaxPrice);
      } else {
        trade.TaxPrice = getTaxByITax(trade.Total);
        trade.YTaxPrice = func.math.subFixed(trade.Total, trade.TaxPrice);
      }
      break;
    case 2:
      trade.ZTaxPrice = trade.Total;
      break;
    case 3:
      trade.NTaxPrice = trade.Total;
      break;
  }
  let tradeTaxPrice = trade.TaxPrice;
  tradeDets.forEach((item) => {
    if (trade.TaxType == 1) {
      if (trade.IsTaxAddition) {
        let exTotal = item.Total;
        item.Total = getWithTax(exTotal);
        item.TaxPrice = getTax(exTotal);
      } else {
        item.TaxPrice = getTaxByITax(item.Total);
      }
    } else item.TaxPrice = 0;
    tradeTaxPrice -= item.TaxPrice;
  });
  if (tradeTaxPrice != 0) {
    if (tradeDets.length > 0) {
      let det = tradeDets[0];
      tradeDets.forEach((item) => {
        if (det.TaxPrice < item.TaxPrice) det = item;
      });
      det.TaxPrice += tradeTaxPrice;
      if (trade.IsTaxAddition)
        det.Total = func.math.addFixed(det.Total, tradeTaxPrice);
    }
  }

  tradeTotal = trade.Total;
  tradeDets.forEach((item) => {
    tradeTotal = func.math.subFixed(tradeTotal, item.Total);
  });

  if (tradeDets.length > 0) {
    tradeDets[0].Total = func.math.addFixed(tradeDets[0].Total, tradeTotal);
  }

  tradeDets.forEach((item) => {
    if (item.Qty == 0) item.SalPrice = 0;
    else item.SalPrice = Math.ceil((item.Total / item.Qty) * 100) / 100;
  });
}
