<template>
  <div>
    <div
      class="container-fluid d-flex flex-column justify-content-center align-items-center"
      style="padding: 50px 0"
    >
      <div class="card" style="max-width: 450px; width: 100%">
        <div
          class="card-header d-flex justify-content-center"
          style="font-weight: 600"
        >
          選擇 Ushow 終端機
        </div>
        <div class="card-body">
          <div class="table-responsive custom-table">
            <table class="table">
              <thead>
                <tr>
                  <th style="width: 80px">選擇</th>
                  <th>終端機名稱</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(item, index) in terminals"
                  :key="item.TerminalId"
                  @click="terminalIndex = index"
                >
                  <td>
                    <div class="form-check form-check-inline">
                      <input
                        class="form-check-input"
                        style="margin-left: 10px"
                        type="radio"
                        :value="item.TerminalId"
                        v-model="currentTerminalId"
                      />
                    </div>
                  </td>
                  <td>{{ item.TerminalName }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div
          class="card-header d-flex justify-content-center"
          style="font-weight: 600; border-top: 1px solid rgba(0, 0, 0, 0.125)"
        >
          選擇 UberEats 終端機
        </div>
        <div class="card-body">
          <div class="table-responsive custom-table">
            <table class="table">
              <thead>
                <tr>
                  <th style="width: 80px">選擇</th>
                  <th>Uber商店名稱</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(item, index) in uberStores"
                  :key="item.StoreId"
                  @click="uberStoreIndex = index"
                >
                  <td>
                    <div class="form-check form-check-inline">
                      <input
                        class="form-check-input"
                        style="margin-left: 10px"
                        type="radio"
                        :value="item.StoreId"
                        v-model="currentUberStoreId"
                      />
                    </div>
                  </td>
                  <td>{{ item.StoreName }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div class="d-flex">
        <button class="btn btn-primary px-4 mr-2" @click="save">綁定</button>
        <button class="btn btn-secondary px-4" @click="back">返回</button>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      accessToken: "",
      refreshToken: "",
      terminals: [],
      uberStores: [],
      terminalIndex: -1,
      uberStoreIndex: -1,
    };
  },
  computed: {
    currentTerminalId() {
      if (this.terminalIndex < 0) return "";
      else return this.terminals[this.terminalIndex].TerminalId;
    },
    currentUberStoreId() {
      if (this.uberStoreIndex < 0) return "";
      else return this.uberStores[this.uberStoreIndex].StoreId;
    },
  },
  methods: {
    save() {
      if (this.uberStoreIndex < 0 || this.terminalIndex < 0) {
        alert("請選擇資料 !");
        return;
      }
      this.$api.uberEats
        .postIntegration({
          AccessToken: this.accessToken,
          RefreshToken: this.refreshToken,
          DeviceId: this.currentTerminalId,
          StoreId: this.currentUberStoreId,
        })
        .then(() => {
          this.$func.message.success("綁定成功 !");
        })
        .catch((error) => {
          this.$func.message.error(error.data.Message);
        });
    },
    back() {
      this.$func.message.confirm("確定離開此頁嗎 ?").then((result) => {
        if (result.isConfirmed) {
          this.$router.push({
            name: "UberEats",
          });
        }
      });
    },
  },
  mounted() {
    this.$api.uberEats
      .postAccessToken({
        Code: this.$route.query.code,
        Url: `${window.location.protocol}//${window.location.host}${window.location.pathname}`,
      })
      .then((data) => {
        this.accessToken = data.AccessToken;
        this.refreshToken = data.RefreshToken;
        this.uberStores = data.UberStores;
        this.terminals = data.Terminals;
        this.uberStoreIndex = -1;
        this.terminalIndex = -1;
      })
      .catch((error) => {
        this.$func.message.error(error.data.Message);
      });
  },
};
</script>
