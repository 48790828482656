import { httpGet, httpPost, httpPut, httpDelete } from "@/request/http";

let path = "/users";

export default {
  get(id) {
    return httpGet(`${path}/${id}`);
  },
  getSalers() {
    return httpGet(`${path}/salers`);
  },
  getPages(dTO) {
    return httpGet(`${path}/pages`, dTO);
  },
  save(item) {
    return httpPost(path, item);
  },
  update(id, item) {
    return httpPut(`${path}/${id}`, item);
  },
  updateAdmin(id, item) {
    return httpPut(`${path}/admin/${id}`, item);
  },
  changePassword(item) {
    return httpPost(`${path}/change-password`, item);
  },
  remove(id) {
    return httpDelete(`${path}/${id}`);
  },
  adminChangePassword(item) {
    return httpPost(`${path}/admin/change-password`, item);
  },
};
