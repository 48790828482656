<template>
  <div
    :id="modalId"
    class="modal fade"
    tabindex="-1"
    role="dialog"
    data-backdrop="static"
  >
    <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">
            {{ $t(`modal.${modalId.toLowerCase()}`) }}
          </h5>
          <button type="button" class="close" @click="onClosed">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="row">
            <div
              v-for="(item, index) in log"
              :key="`first${index}`"
              class="col-md-6"
            >
              <div class="form-group log-group">
                <label class="log-label" :for="item.name">{{
                  $t(`columnName.${item.name.toLowerCase()}`)
                }}</label>
                <span class="log-review-value">{{ item[item.name] }}</span>
              </div>
            </div>
            <div v-if="logBody.length > 0" class="col-12 mt-2 mb-3">
              <hr class="log-line" />
            </div>
            <div
              v-for="(item, index) in logBody"
              :key="`second${index}`"
              class="col-12"
            >
              <div class="form-group log-group">
                <label class="log-label" :for="item.name">{{
                  $t(`columnName.${item.name.toLowerCase()}`)
                }}</label>
                <span class="log-review-value" v-html="item[item.name]"></span>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" @click="onClosed">
            {{ $t("button.ok") }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import $ from "jquery";
import modalPart from "@/components/parts/modalPart";

export default {
  data() {
    return {
      log: [],
      logBody: [],
    };
  },
  props: ["columns", "item", "isShow", "modalId"],
  components: {
    modalPart,
  },
  watch: {
    item(value) {
      this.log = [];
      this.logBody = [];
      this.columns.forEach((col) => {
        let obj = {
          name: col.name,
          [col.name]: value[col.name],
        };
        this.log.push(obj);
      });
      switch (this.modalId) {
        case "ErrorMessage":
          this.logBody.push({
            name: "RequestContent",
            RequestContent: value.RequestContent,
          });
          this.logBody.push({
            name: "ResponseContent",
            ResponseContent: value.ResponseContent,
          });
          return;
        case "ThirdParty":
          this.logBody.push({
            name: "RequestBody",
            RequestBody: value.RequestBody,
          });
          this.logBody.push({
            name: "ResponseContent",
            ResponseContent: value.ResponseContent,
          });
          return;
        case "Webhook":
          this.logBody.push({
            name: "RequestBody",
            RequestBody: value.RequestBody,
          });
          return;
        case "System":
        case "Notification":
          this.logBody.push({
            name: "Body",
            Body: value.Body,
          });
          this.logBody.push({
            name: "ThirdPartyBody",
            ThirdPartyBody: value.ThirdPartyBody,
          });
          return;
      }
    },
    isShow(value) {
      if (value) {
        $(`#${this.modalId}`).modal("toggle");
        window.onbeforeunload = () => {
          return true;
        };
      } else {
        $(`#${this.modalId}`).modal("toggle");
        window.onbeforeunload = null;
      }
    },
  },
  methods: {
    onClosed() {
      this.$emit("onClosed");
    },
  },
};
</script>
