import { httpGet, httpPost, httpDelete } from '@/request/http';

let path = '/floormaps';

export default{
    getByStore(id){
      return httpGet(`stores/${id}/floormaps`);
    },
    save(item){
      return httpPost(path , item);
    },
    remove(id){
      return httpDelete(`${path}/${id}`);
    }
}
