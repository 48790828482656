export function getAll() {
  return [
    {
      value: 0,
      name: "None",
      text: "無"
    },
    {
      value: 1,
      name: "Casio",
      text: "Casio"
    },
    {
      value: 2,
      name: "TYSSO",
      text: "大碩"
    }
  ];
}
