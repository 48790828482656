<template>
  <div class="h-100">
    <loading :active.sync="isLoading"></loading>
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: "App",
  computed: {
    isLoading() {
      return this.$store.state.isLoading;
    },
  },
  watch: {
    isLoading(value) {
      if (value) {
        this.banRefresh();
      } else {
        this.unbanRefresh();
      }
    },
  },
  methods: {
    banRefresh() {
      window.addEventListener("keydown", this.refresh);
      window.addEventListener("contextmenu", this.refresh);
    },
    unbanRefresh() {
      const plsWait = document.querySelector(".waitContainer");
      if (plsWait) {
        const loading = document.querySelector(".vld-overlay");
        loading.removeChild(plsWait);
      }
      window.removeEventListener("keydown", this.refresh);
      window.removeEventListener("contextmenu", this.refresh);
    },
    refresh(e) {
      e.preventDefault();
      const plsWait = document.createElement("div");
      plsWait.classList.add("waitContainer");
      plsWait.innerHTML = `<h4 class="waitMsg">處理中，請勿重新整理</h4>`;
      if (!document.querySelector(".waitContainer")) {
        document.querySelector(".vld-overlay").appendChild(plsWait);
      }
    },
  },
  beforeCreate() {
    if (this.$route.name == "PrivacyPolicy") return;
    if (!localStorage.token) this.$router.push({ name: "Login" });
  },
};
</script>

<style lang="scss">
@import "./assets/all";
.waitContainer {
  position: absolute;
  margin-top: 120px;
}
.waitMsg {
  color: red;
  font-weight: 700 !important;
}
</style>
