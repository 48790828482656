import i18n from "@/langs/i18n";

export function getAll() {
  return [
    {
      value: -1,
      name: "All",
      text: `${i18n.t("option.reportSourceType.All")}`,
    },
    {
      value: 0,
      name: "Local",
      text: `${i18n.t("option.reportSourceType.Local")}`,
    },
    {
      value: 1,
      name: "Web",
      text: `${i18n.t("option.reportSourceType.Web")}`,
    },
  ];
}

export function getByValue(value) {
  const type = getAll().find(function (item) {
    return item.value == value;
  });
  return type;
}
