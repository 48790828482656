import { httpGet, httpPost, httpDelete } from "@/request/http";

let path = "/onlineordermenus";

export default {
  get(id) {
    return httpGet(`${path}/${id}`);
  },
  getPages(type, dTO) {
    return httpGet(`onlineorder/${type}/menus/pages`, dTO);
  },
  getAll(type) {
    return httpGet(`onlineorder/${type}/menus`, dTO);
  },
  getCategories(menuId) {
    return httpGet(`${path}/${menuId}/onlineordercategories`);
  },
  postCategories(menuId, dTO) {
    return httpPost(`${path}/${menuId}/onlineordercategories`, dTO);
  },
  post(item) {
    return httpPost(`${path}`, item);
  },
  remove(id) {
    return httpDelete(`${path}/${id}`);
  },
};
