<template>
  <div>
    <h2 v-if="tasteKind">{{ tasteKind.TasteKindName }}</h2>
    <div class="row">
      <div class="col-lg-5">
        <h5>{{ $t("title.unselectedfood") }}</h5>
        <div class="card card-table">
          <div class="card-header">
            <button
              type="button"
              class="btn btn-outline-primary btn-icon"
              @click="onAdd"
            >
              <i class="fa fa-plus"></i>
            </button>
          </div>

          <div class="card-body card-table-body">
            <div class="form-group row" v-if="!isFoodBySelect">
              <label for="foodKindId" class="col-3 col-sm-2 col-form-label"
                >{{ $t("modal.kind2") }}:</label
              >
              <div class="col-9 col-sm-10">
                <select
                  id="foodKindId"
                  v-if="foodKinds"
                  class="form-control"
                  v-model="currentFoodKindId"
                >
                  <option
                    v-for="foodKind in foodKinds"
                    :key="foodKind.FoodKindId"
                    :value="foodKind.FoodKindId"
                  >
                    {{ foodKind.FoodKindName }}
                  </option>
                </select>
              </div>
            </div>
            <button
              class="btn btn-primary mb-3"
              @click="foodModalTrigger = !foodModalTrigger"
              v-else
            >
              {{ $t("button.selectfood") }}
            </button>
            <div class="form-group row">
              <label for="notMin" class="col-3 col-sm-2 col-form-label"
                >{{ $t("modal.min") }}:</label
              >
              <div class="col-3 col-sm-4">
                <input
                  id="notMin"
                  type="number"
                  class="form-control"
                  v-model="notMin"
                />
              </div>
              <label for="notMax" class="col-3 col-sm-2 col-form-label"
                >{{ $t("modal.max") }}:</label
              >
              <div class="col-3 col-sm-4">
                <input
                  id="notMax"
                  type="number"
                  class="form-control"
                  v-model="notMax"
                />
              </div>
            </div>
            <div class="table-responsive custom-table">
              <table class="table">
                <thead>
                  <tr>
                    <th>
                      <div class="custom-control custom-checkbox mr-sm-2">
                        <input
                          type="checkbox"
                          class="custom-control-input"
                          id="allAdd"
                          v-model="allAdd"
                        />
                        <label class="custom-control-label" for="allAdd">{{
                          $t("columnName.alladd")
                        }}</label>
                      </div>
                    </th>
                    <th>{{ $t("columnName.foodnumber") }}</th>
                    <th>{{ $t("columnName.foodname") }}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="notSelected in notSelecteds"
                    :key="notSelected.FoodId"
                    @click="notSelectedClick(notSelected.FoodId)"
                  >
                    <td>
                      <input
                        class="checkbox"
                        type="checkbox"
                        :value="notSelected.FoodId"
                        v-model="addIds"
                      />
                    </td>
                    <td>{{ notSelected.FoodNumber }}</td>
                    <td>{{ notSelected.FoodName }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-7">
        <h5>{{ $t("title.selectedfood") }}</h5>
        <div class="card card-table">
          <div class="card-header">
            <button
              type="button"
              class="btn btn-outline-success btn-icon"
              @click="onEdit"
            >
              <i class="fas fa-pen"></i>
            </button>
            <button
              type="button"
              class="btn btn-outline-danger btn-icon"
              @click="onRemove"
            >
              <i class="fas fa-trash-alt"></i>
            </button>
          </div>
          <div class="card-body card-table-body">
            <div class="form-group row">
              <label for="min" class="col-3 col-sm-2 col-form-label"
                >{{ $t("modal.min") }}:</label
              >
              <div class="col-3 col-sm-4">
                <input
                  id="min"
                  type="number"
                  class="form-control"
                  v-model="min"
                />
              </div>
              <label for="max" class="col-3 col-sm-2 col-form-label"
                >{{ $t("modal.max") }}:</label
              >
              <div class="col-3 col-sm-4">
                <input
                  id="max"
                  type="number"
                  class="form-control"
                  v-model="max"
                />
              </div>
            </div>
            <div class="table-responsive custom-table">
              <table class="table">
                <thead>
                  <tr>
                    <th>
                      <div class="custom-control custom-checkbox mr-sm-2">
                        <input
                          type="checkbox"
                          class="custom-control-input"
                          id="allRemove"
                          v-model="allRemove"
                        />
                        <label class="custom-control-label" for="allRemove">{{
                          $t("columnName.alladd")
                        }}</label>
                      </div>
                    </th>
                    <th>{{ $t("columnName.foodnumber") }}</th>
                    <th>{{ $t("columnName.foodname") }}</th>
                    <th>{{ $t("columnName.min") }}</th>
                    <th>{{ $t("columnName.max") }}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="selected in selecteds"
                    :key="selected.FoodId"
                    @click="selectedClick(selected.FoodId)"
                  >
                    <td>
                      <input
                        class="checkbox"
                        type="checkbox"
                        :value="selected.FoodId"
                        v-model="removeIds"
                      />
                    </td>
                    <td>{{ selected.FoodNumber }}</td>
                    <td>{{ selected.FoodName }}</td>
                    <td>{{ selected.MinQty }}</td>
                    <td>{{ selected.MaxQty }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
    <food-selects
      :selectedIds="selectedIds"
      :isFoodsFilter="true"
      :defaultItems="notSelecteds"
      :trigger="foodModalTrigger"
      :isMultiple="true"
      @onSaved="addFoods"
    ></food-selects>
  </div>
</template>

<script>
import foodSelects from "@/components/selects/FoodSelects";

export default {
  data() {
    return {
      tasteKind: {},
      foodKinds: [],
      currentFoodKindId: "",
      notSelecteds: [],
      notMin: 0,
      notMax: 0,
      selecteds: [],
      min: 0,
      max: 0,
      addIds: [],
      removeIds: [],
      allAdd: false,
      allRemove: false,
      isFoodBySelect: window.localStorage["isFoodBySelect"] == "true",
      foodModalTrigger: false,
    };
  },
  props: ["isShow"],
  computed: {
    selectedIds() {
      let ids = [];
      this.selecteds.forEach((item) => {
        ids.push(item.FoodId);
      });
      return ids;
    },
  },
  watch: {
    currentFoodKindId() {
      this.updateFoods();
    },
    allAdd(value) {
      this.addIds = [];
      if (value) {
        this.notSelecteds.forEach((item) => this.addIds.push(item.FoodId));
      }
    },
    allRemove(value) {
      this.removeIds = [];
      if (value) {
        this.selecteds.forEach((item) => this.removeIds.push(item.FoodId));
      }
    },
  },
  components: {
    foodSelects,
  },
  methods: {
    notSelectedClick(id) {
      const index = this.addIds.indexOf(id);
      if (index >= 0) {
        this.addIds.splice(index, 1);
      } else {
        this.addIds.push(id);
      }
    },
    selectedClick(id) {
      const index = this.removeIds.indexOf(id);
      if (index >= 0) {
        this.removeIds.splice(index, 1);
      } else {
        this.removeIds.push(id);
      }
    },
    updateFoods() {
      const vm = this;
      if (this.isFoodBySelect) {
        this.$api.foodTastes
          .getFoodsBySelect(this.$route.params.id)
          .then((data) => {
            vm.addIds = [];
            vm.removeIds = [];
            vm.notSelecteds = data.NotSelecteds;
            vm.selecteds = data.Selecteds;
            vm.allAdd = false;
            vm.allRemove = false;
          });
      } else {
        this.$api.foodTastes
          .getFoods(this.$route.params.id, this.currentFoodKindId)
          .then((data) => {
            vm.addIds = [];
            vm.removeIds = [];
            vm.notSelecteds = data.NotSelecteds;
            vm.selecteds = data.Selecteds;
            vm.allAdd = false;
            vm.allRemove = false;
          });
      }
    },
    onAdd() {
      if (this.addIds.length == 0) {
        this.$func.message.alert("請選擇商品 !");
        return;
      }
      if (this.notMax != 0 && this.notMin > this.notMax) {
        this.$func.message.alert("數量輸入錯誤 !");
        return;
      }
      const vm = this;
      this.$api.foodTastes
        .postFoods({
          TasteKindId: vm.tasteKind.TasteKindId,
          FoodIds: vm.addIds,
          MinQty: vm.notMin,
          MaxQty: vm.notMax,
        })
        .then(() => {
          vm.updateFoods();
          vm.notMin = 0;
          vm.notMax = 0;
        });
    },
    onEdit() {
      if (this.removeIds.length == 0) {
        this.$func.message.alert("請選擇商品 !");
        return;
      }
      if (this.max != 0 && this.min > this.max) {
        this.$func.message.alert("數量輸入錯誤 !");
        return;
      }
      const vm = this;
      this.$api.foodTastes
        .postFoods({
          TasteKindId: vm.tasteKind.TasteKindId,
          FoodIds: vm.removeIds,
          MinQty: vm.min,
          MaxQty: vm.max,
        })
        .then(() => {
          vm.updateFoods();
          vm.min = 0;
          vm.max = 0;
        });
    },
    onRemove() {
      if (this.removeIds.length == 0) {
        this.$func.message.alert("請選擇商品 !");
        return;
      }
      const vm = this;
      this.$api.foodTastes
        .removeFoods({
          TasteKindId: vm.tasteKind.TasteKindId,
          FoodIds: vm.removeIds,
        })
        .then(() => vm.updateFoods());
    },
    addFoods(items) {
      const vm = this;
      items.forEach((item) => {
        let exist = vm.notSelecteds.findIndex((d) => {
          return d.FoodId == item.FoodId;
        });
        if (exist == -1) {
          vm.notSelecteds.push(item);
          vm.addIds.push(item.FoodId);
        }
      });
    },
  },
  created() {
    const tasteKindId = this.$route.params.id;
    const vm = this;
    this.$api.tasteKinds.get(tasteKindId).then((data) => (vm.tasteKind = data));
    this.$api.foodKindTastes.getFoodKinds(tasteKindId).then((data) => {
      vm.foodKinds = data.NotSelecteds;
      if (vm.foodKinds.length > 0) {
        vm.currentFoodKindId = vm.foodKinds[0].FoodKindId;
      }
    });
  },
};
</script>
