<template>
  <form
    :id="modalId"
    class="modal fade"
    tabindex="-1"
    role="dialog"
    data-backdrop="static"
    @submit.prevent="saveApiKey"
  >
    <div
      class="modal-dialog modal-dialog-centered"
      :class="modalClass"
      role="document"
    >
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Api Key</h5>
          <button type="button" class="close" @click="onClosed">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <page-table
            :items="items"
            :columns="columns"
            :idProperty="idProperty"
            :nameProperty="nameProperty"
            @addItem="addApiKey"
            @removeItem="removeApikey"
            @currentIndexChanged="currentIndexChanged"
            :hasAdd="true"
            :hasEdit="false"
            :hasRemove="true"
            tableName="apiKeys"
          ></page-table>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" @click="onClosed">
            返回
          </button>
        </div>
      </div>
    </div>
    <div class="add-api-key-background display-none">
      <div class="add-api-key">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">新增 Api Key</h5>
            <button
              type="button"
              class="close"
              @click="showAddModal = !showAddModal"
            >
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="col-12 form-group">
              <label class="col-form-label" for="appType">App Type</label>
              <select
                class="form-control"
                id="appType"
                v-model="newItem.AppType"
              >
                <option
                  v-for="appType in appTypes"
                  :key="appType.value"
                  :value="appType.value"
                >
                  {{ appType.text }}
                </option>
              </select>
            </div>
            <div class="col-12 form-group">
              <label class="col-form-label" for="memo">備註</label>
              <input
                type="text"
                class="form-control"
                id="memo"
                v-model="newItem.Memo"
                required
              />
            </div>
          </div>
          <div class="modal-footer">
            <button type="submit" class="btn btn-primary">新增</button
            ><button
              type="button"
              class="btn btn-secondary"
              @click="showAddModal = !showAddModal"
            >
              取消
            </button>
          </div>
        </div>
      </div>
    </div>
  </form>
</template>

<script>
import pageTable from "@/components/PageTable";
import itemModal from "@/components/ItemModal";
import $ from "jquery";

export default {
  data() {
    return {
      items: [],
      appTypes: this.$enum.appType.getAll(),
      idProperty: "Id",
      nameProperty: "Id",
      currentIndex: -1,
      showAddModal: false,
      newItem: {},
    };
  },
  props: {
    isShow: false,
    title: String,
    modalId: String,
    targetId: String,
    modalClass: String,
  },
  components: {
    pageTable,
    itemModal,
  },
  computed: {
    columns() {
      let c = [
        {
          name: "Id",
        },
        {
          name: "AppTypeText",
        },
        {
          name: "Memo",
          width: 400,
        },
      ];
      if (this.$route.name == "Businesses") {
        c.splice(1, 0, {
          name: "BusinessId",
        });
      } else if (this.$route.name == "Devices") {
        c.splice(1, 0, {
          name: "DeviceId",
        });
      }
      return c;
    },
  },
  watch: {
    showAddModal(value) {
      this.newItem = {
        AppType: 0,
        Memo: "",
      };
      const modal = document.querySelector(".add-api-key-background");
      if (value) {
        if (this.$route.name == "Businesses") {
          this.newItem.BusinessId = this.targetId;
        } else if (this.$route.name == "Devices") {
          this.newItem.BusinessId = this.$route.params.id;
          this.newItem.DeviceId = this.targetId;
        }
        modal.classList.remove("display-none");
      } else {
        modal.classList.add("display-none");
      }
    },
    isShow(value) {
      if (value) {
        this.items = [];
        this.getApiKeys();
        $(`#${this.modalId}`).modal("toggle");
        window.onbeforeunload = () => {
          return true;
        };
      } else {
        $(`#${this.modalId}`).modal("toggle");
        window.onbeforeunload = null;
      }
    },
  },
  methods: {
    getApiKeys() {
      if (this.$route.name == "Businesses") {
        this.$api.businesses.getApiKeys(this.targetId).then((data) => {
          this.items = data;
          this.items.forEach((item) => {
            item.AppTypeText = this.$enum.appType.getByValue(item.AppType).text;
          });
        });
      } else if (this.$route.name == "Devices") {
        this.$api.devices.getApiKeys(this.targetId).then((data) => {
          this.items = data;
          this.items.forEach((item) => {
            item.AppTypeText = this.$enum.appType.getByValue(item.AppType).text;
          });
        });
      }
    },
    addApiKey() {
      this.showAddModal = !this.showAddModal;
    },
    saveApiKey() {
      this.$func.message.confirm("確定要新增 Api Key 嗎 ?").then((result) => {
        if (result.isConfirmed) {
          this.$api.businesses
            .postApiKey(this.newItem)
            .then((data) => {
              this.$func.message.apiKeySuccess(
                data,
                "請確實保存此金鑰，關閉後不再顯示 !",
                data
              );
              const swalModal = document.querySelector(".swal2-modal");
              swalModal.removeAttribute("tabindex");
              this.showAddModal = !this.showAddModal;
              this.getApiKeys();
            })
            .catch((error) => {
              this.$func.message.error(error.data.message);
            });
        }
      });
    },
    removeApikey() {
      this.$api.businesses
        .deleteApiKey(this.items[this.currentIndex].Id)
        .then(() => {
          this.$func.message.success("Api Key 刪除成功 !");
          this.getApiKeys();
        })
        .catch((error) => {
          this.$func.message.error(error.data.message);
        });
    },
    currentIndexChanged(index) {
      this.currentIndex = index;
    },
    onClosed() {
      this.$emit("onClosed");
    },
  },
};
</script>

<style lang="scss">
.add-api-key-background {
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.3);
  display: flex;
  justify-content: center;
  align-items: center;
}
.add-api-key {
  width: 80%;
  max-width: 500px;
}
.display-none {
  display: none !important;
}
</style>
