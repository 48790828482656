import { httpGet, httpPost, httpDelete } from '@/request/http';


let path = '/depositrecords';

export default{
    get(id){
      return httpGet(`${path}/${id}`);
    },
    save(item){
      return httpPost(path, item);
    },
    remove(id){
      return httpDelete(`${path}/${id}`);
    }
}
