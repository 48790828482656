import { httpGet, httpPost, httpDelete } from '@/request/http';

let path = '/functionbuttons';

export default{
    get(id){
        return httpGet(`${path}/${id}`);
    },
    gets(id){
        return httpGet(`/modules/${id}/functionbuttons`);
    },
    save(item){
        return httpPost(`${path}`, item);
    },
    posts(item){
        return httpPost(`${path}/addbatch`, item);
    },
    deletes(item){
        return httpPost(`${path}/deletebatch`, item);
    },
    before(id){
        return httpPost(`${path}/${id}/before`);
    },
    after(id){
        return httpPost(`${path}/${id}/after`);
    }
}
