import { httpGet, httpPost, httpDelete } from "@/request/http";

let path = "/integralRules";

export default {
  get(id) {
    return httpGet(`${path}/${id}`);
  },
  getPages(dTO) {
    return httpGet(`${path}/pages`, dTO);
  },
  save(item) {
    return httpPost(path, item);
  },
  remove(id) {
    return httpDelete(`${path}/${id}`);
  },
  getFoodKinds() {
    return httpGet(`/integrals/exclude/foodkinds`);
  },
  postFoodKinds(foodkinds) {
    return httpPost(`/integrals/exclude/foodkinds/add`, foodkinds);
  },
  deleteFoodKinds(foodkinds) {
    return httpPost(`/integrals/exclude/foodkinds/delete`, foodkinds);
  },
  getFoods(foodKindId, dTO) {
    return httpGet(`/integrals/exclude/foodkinds/${foodKindId}/foods`, dTO);
  },
  postFoods(foods) {
    return httpPost(`/integrals/exclude/foods/add`, foods);
  },
  deleteFoods(foods) {
    return httpPost(`/integrals/exclude/foods/delete`, foods);
  },
};
