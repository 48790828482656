<template>
  <div class="row">
    <div class="col-md-6">
      <h5>{{ $t("title.combokind") }}</h5>
      <pageTable
        :parentPage="pagination"
        :items="comboKinds"
        :columns="columns"
        :idProperty="idProperty"
        :nameProperty="nameProperty"
        @getItems="getComboKinds"
        @addItem="addComboKind"
        @editItem="editComboKind"
        @removeItem="removeComboKind"
        @trdbClicked="editComboKind"
        @currentIndexChanged="currentIndexChanged"
        :getTrigger="getTrigger"
        :changeIndex="currentIndex"
        :hasAdd="authority.IsAdd"
        :hasEdit="authority.IsUpdate"
        :hasRemove="authority.IsRemove"
        :isSortable="true"
      >
        <template slot="button">
          <button
            type="button"
            class="btn btn-outline-info btn-icon"
            v-if="authority.IsUpdate"
            @click="showFoodCombo"
          >
            <i class="fas fa-hamburger"></i>
          </button>
          <button
            type="button"
            class="btn btn-outline-primary py-2 px-4"
            style="border-color: transparent"
            v-if="authority.IsUpdate"
            @click="onBefore"
          >
            <i class="fa fa-arrow-up"></i>
          </button>
          <button
            type="button"
            class="btn btn-outline-primary py-2 px-4"
            style="border-color: transparent"
            v-if="authority.IsUpdate"
            @click="onAfter"
          >
            <i class="fa fa-arrow-down"></i>
          </button>
        </template>
      </pageTable>
    </div>
    <div class="col-md-6">
      <h5>{{ $t("title.combo") }}</h5>
      <combo :comboKindId="currentKindId" />
    </div>

    <itemModal
      :isShowModal="isShowModal"
      @onClosed="isShowModal = !isShowModal"
      @onSaved="saveComboKind"
      :title="modalTitle"
    >
      <div slot="item">
        <div class="row">
          <div class="form-group col-12">
            <label for="comboKindName">{{ $t("modal.name") }}</label>
            <input
              type="text"
              class="form-control"
              id="comboKindName"
              v-model="comboKind.ComboKindName"
              required
            />
          </div>
          <div v-if="hasEnglish" class="form-group col-12 col-md-6">
            <label for="comboKindNameEN">{{ $t("modal.english") }}</label>
            <input
              type="text"
              class="form-control"
              id="comboKindNameEN"
              v-model="comboKind.English"
            />
          </div>
          <div v-if="hasJapanese" class="form-group col-12 col-md-6">
            <label for="comboKindNameJP">{{ $t("modal.japanese") }}</label>
            <input
              type="text"
              class="form-control"
              id="comboKindNameJP"
              v-model="comboKind.Japanese"
            />
          </div>
          <div v-if="hasKorean" class="form-group col-12 col-md-6">
            <label for="comboKindNameKR">{{ $t("modal.korean") }}</label>
            <input
              type="text"
              class="form-control"
              id="comboKindNameKR"
              v-model="comboKind.Korean"
            />
          </div>
          <div v-if="hasFrench" class="form-group col-12 col-md-6">
            <label for="comboKindNameFR">{{ $t("modal.french") }}</label>
            <input
              type="text"
              class="form-control"
              id="comboKindNameFR"
              v-model="comboKind.French"
            />
          </div>
        </div>
      </div>
    </itemModal>
  </div>
</template>

<script>
import pageTable from "@/components/PageTable";
import itemModal from "@/components/ItemModal";
import combo from "@/components/datas/Combos";

export default {
  data() {
    return {
      comboKinds: [],
      comboKind: {},
      currentKindId: "",
      pagination: {},
      isShowModal: false,
      modalTitle: "",
      columns: [
        {
          name: "ComboKindName",
        },
      ],
      idProperty: "ComboKindId",
      nameProperty: "ComboKindName",
      getTrigger: false,
      currentIndex: -1,
      authority: {},
      hasEnglish: window.localStorage["hasEnglish"] == "true",
      hasJapanese: window.localStorage["hasJapanese"] == "true",
      hasKorean: window.localStorage["hasKorean"] == "true",
      hasFrench: window.localStorage["hasFrench"] == "true",
    };
  },
  components: {
    pageTable,
    itemModal,
    combo,
  },
  methods: {
    currentIndexChanged(index) {
      this.currentIndex = index;
      if (this.currentIndex < 0) {
        this.currentKindId = null;
      }
      const comboKind = this.comboKinds[this.currentIndex];
      if (!comboKind) {
        this.currentKindId = null;
      } else {
        this.currentKindId = comboKind.ComboKindId;
      }
    },
    getCurrentId() {
      if (this.currentIndex < 0) {
        this.$func.message.alert("請選擇套餐類別 !");
        return undefined;
      }
      const comboKind = this.comboKinds[this.currentIndex];
      if (!comboKind) {
        return undefined;
      } else {
        return comboKind.ComboKindId;
      }
    },
    getComboKinds(dTO) {
      const vm = this;
      this.$api.comboKinds.getPages(dTO).then((data) => {
        vm.comboKinds = data.Datas;
        vm.pagination = data.Pagination;
      });
    },
    addComboKind() {
      this.comboKind = {};
      this.isShowModal = !this.isShowModal;
      this.modalTitle = this.$t("modal.addcombokind");
    },
    editComboKind(comboKind) {
      if (!this.authority.IsUpdate) {
        return;
      }
      const vm = this;
      this.$api.comboKinds.get(comboKind.ComboKindId).then((data) => {
        vm.comboKind = data;
        vm.isShowModal = !vm.isShowModal;
        vm.modalTitle = this.$t("modal.editcombokind");
      });
    },
    removeComboKind(comboKind) {
      const vm = this;
      this.$api.comboKinds.remove(comboKind.ComboKindId).then(() => {
        vm.getTrigger = !vm.getTrigger;
      });
    },
    saveComboKind() {
      const vm = this;
      this.$api.comboKinds
        .save(vm.comboKind)
        .then(() => {
          vm.isShowModal = !vm.isShowModal;
          vm.getTrigger = !vm.getTrigger;
        })
        .catch((error) => vm.$func.message.error(error.data.Message));
    },
    showFoodCombo() {
      if (this.currentKindId.length == 0) {
        this.$func.message.alert("請選擇一筆資料 !");
        return;
      }
      this.$router.push({
        name: "FoodCombos",
        params: { id: this.currentKindId },
      });
    },
    onBefore() {
      const id = this.getCurrentId();
      if (!id) {
        return;
      }
      const vm = this;
      this.$api.comboKinds
        .before({ Id: id, PageSize: this.pagination.PageSize })
        .then((data) => {
          if (data.Pagination.CurrentPage < vm.pagination.CurrentPage) {
            vm.comboKinds = data.Datas;
            vm.currentIndexChanged(data.Pagination.PageSize - 1);
            vm.pagination = data.Pagination;
          } else {
            vm.currentIndexChanged(vm.currentIndex - 1);
          }
          vm.comboKinds = data.Datas;
          vm.pagination = data.Pagination;
        })
        .catch((error) => vm.$func.message.error(error.data.Message));
    },
    onAfter() {
      const id = this.getCurrentId();
      if (!id) {
        return;
      }
      const vm = this;
      this.$api.comboKinds
        .after({ Id: id, PageSize: this.pagination.PageSize })
        .then((data) => {
          if (data.Pagination.CurrentPage > vm.pagination.CurrentPage) {
            vm.comboKinds = data.Datas;
            vm.currentIndexChanged(0);
            vm.pagination = data.Pagination;
          } else {
            vm.currentIndexChanged(vm.currentIndex + 1);
          }
          vm.comboKinds = data.Datas;
          vm.pagination = data.Pagination;
        })
        .catch((error) => vm.$func.message.error(error.data.Message));
    },
  },
  created() {
    this.authority = this.$func.localStorage.getAuthByName(this.$route.name);
  },
};
</script>
