<template>
  <form
    id="invoiceform"
    class="modal fade"
    tabindex="-1"
    role="dialog"
    data-backdrop="static"
    @submit.prevent="onSaved"
  >
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">{{ $t("title.sendinvoice") }}</h5>
          <button type="button" class="close" @click="onClosed">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="form-group row">
            <label for="invoiceTargetType" class="col-sm-3 col-form-label">{{
              $t("modal.invoicetargettype")
            }}</label>
            <div class="col-sm-9">
              <select
                id="invoiceTargetType"
                class="form-control"
                v-model="invoice.InvoiceTargetType"
              >
                <option
                  v-for="invoiceTargetType in invoiceTargetTypes"
                  :value="invoiceTargetType.value"
                  :key="invoiceTargetType.value"
                >
                  {{ invoiceTargetType.text }}
                </option>
              </select>
            </div>
          </div>
          <div class="form-group row" v-if="!isB2B">
            <label for="vehicleType" class="col-sm-3 col-form-label">{{
              $t("modal.vehicletype")
            }}</label>
            <div class="col-sm-9">
              <select
                id="vehicleType"
                class="form-control"
                v-model="invoice.VehicleType"
              >
                <option
                  v-for="vehicleType in vehicleTypes"
                  :value="vehicleType.value"
                  :key="vehicleType.value"
                >
                  {{ vehicleType.text }}
                </option>
              </select>
            </div>
          </div>
          <div class="form-group row" v-if="invoice.VehicleType == 4">
            <label
              for="vehicleTypeNumber"
              class="col-sm-3 col-form-label pr-0"
              >{{ $t("modal.vehicletypenumber") }}</label
            >
            <div class="col-sm-9">
              <input
                type="text"
                class="form-control"
                id="vehicleTypeNumber"
                v-model="invoice.VehicleTypeNumber"
                maxlength="6"
                required
              />
            </div>
          </div>
          <div class="form-group row" v-if="!isB2B">
            <label for="vehicleNumber" class="col-sm-3 col-form-label">{{
              $t("modal.vehiclenumber")
            }}</label>
            <div class="col-sm-9">
              <input
                type="text"
                class="form-control"
                id="vehicleNumber"
                v-model="invoice.VehicleNumber"
                :readonly="invoice.VehicleType == 0"
                :required="invoice.VehicleType == 1 || invoice.VehicleType == 4"
              />
            </div>
          </div>
          <div class="form-group row" v-if="isB2B">
            <label for="businessId" class="col-sm-3 col-form-label">{{
              $t("modal.uniformnumber")
            }}</label>
            <div class="col-sm-9">
              <input
                type="text"
                class="form-control"
                id="businessId"
                v-model="invoice.UniformNumber"
              />
            </div>
          </div>
          <div class="form-group row" v-if="isB2B">
            <label for="invoiceTitle" class="col-sm-3 col-form-label">{{
              $t("modal.invoicetitle")
            }}</label>
            <div class="col-sm-9">
              <input
                type="text"
                class="form-control"
                id="invoiceTitle"
                v-model="invoice.InvoiceTitle"
              />
            </div>
          </div>
          <div class="form-group row" v-if="isElecInvoiceCanPreviou">
            <label
              for="isElecInvoiceCanPreviou"
              class="col-sm-3 col-form-label"
              >{{ $t("modal.invoicedate") }}</label
            >
            <div
              class="d-flex justify-content-center align-items-center col-sm-9"
            >
              <input
                id="endDate"
                type="date"
                class="form-control invoice-date-input"
                v-model="selectDate"
              />
              <select
                class="form-control invoice-clock-input"
                id="selectHour"
                v-model="selectHour"
              >
                <option
                  v-for="hour in hours"
                  :key="hour.value"
                  :value="hour.value"
                >
                  {{ hour.value }}
                </option>
              </select>
              <label class="invoice-clock-label" for="selectHour">點</label>
              <select
                class="form-control invoice-clock-input"
                id="selectMinute"
                v-model="selectMinute"
              >
                <option
                  v-for="minute in minutes"
                  :key="minute.value"
                  :value="minute.value"
                >
                  {{ minute.value }}
                </option>
              </select>
              <label class="invoice-clock-label" for="selectMinute">分</label>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button type="submit" class="btn btn-primary">
            {{ $t("button.send") }}
          </button>
          <button type="button" class="btn btn-secondary" @click="onClosed">
            {{ $t("button.cancel") }}
          </button>
        </div>
      </div>
    </div>
  </form>
</template>

<script>
import $ from "jquery";

import inputInlinePart from "@/components/parts/inputInlinePart";

export default {
  data() {
    return {
      invoiceTargetTypes: [],
      vehicleTypes: this.$enum.vehicleType.getAll(),
      invoice: {
        InvoiceTargetType: 0,
        VehicleType: 0,
        VehicleNumber: null,
        UniformNumber: null,
      },
      months: [
        { start: 1, end: 2 },
        { start: 3, end: 4 },
        { start: 5, end: 6 },
        { start: 7, end: 8 },
        { start: 9, end: 10 },
        { start: 11, end: 12 },
      ],
      hours: [],
      minutes: [],
      selectDate: this.$func.getDate(),
      selectHour: null,
      selectMinute: null,
    };
  },
  components: {
    inputInlinePart,
  },
  props: {
    invoiceTrigger: false,
    sal: {},
  },
  watch: {
    invoiceTrigger() {
      if (this.isElecInvoiceCanPreviou) {
        let d = new Date();
        const day = document.getElementById("endDate");
        this.selectDate = this.$func.getDate();
        day.max = this.$func.getDate(d.getTime() + 86400000 * 30);
        day.min = this.$func.getDate(d.getTime() - 86400000 * 30);

        this.selectHour = d.getHours();
        this.selectMinute = d.getMinutes();

        // const currentMonth = d.getMonth() + 1;
        // let currentPeriod;
        // for (var i = 0; i < this.months.length; i++) {
        //   if (
        //     currentMonth >= this.months[i].start &&
        //     currentMonth <= this.months[i].end
        //   ) {
        //     currentPeriod = i;
        //   }
        // }
        // const minDate = this.$func.getDate(
        //   new Date(d.getFullYear(), this.months[currentPeriod].start - 1, 1)
        // );

        // day.min = minDate;
      }

      this.invoice.InvoiceTargetType = 0;
      this.invoice.VehicleType = 0;
      this.invoice.VehicleNumber = null;
      this.invoice.UniformNumber = null;
      this.invoice.InvoiceTitle = this.sal.CustomerName;
      $(`#invoiceform`).modal("toggle");
      const vm = this;
      if (this.sal.UniformNumber != null) {
        vm.invoice.InvoiceTargetType = 1;
        vm.invoice.UniformNumber = vm.sal.UniformNumber;
      } else if (this.sal.VehicleNumber != null) {
        vm.invoice.VehicleType = 1;
        vm.invoice.VehicleNumber = vm.sal.VehicleNumber;
      } else if (this.sal.CustomerId && this.sal.CustomerId != null) {
        this.$api.customers.get(this.sal.CustomerId).then((data) => {
          if (data.UniformNumber != null) {
            vm.invoice.InvoiceTargetType = 1;
            vm.invoice.UniformNumber = data.UniformNumber;
            vm.invoice.InvoiceTitle = data.CustomerName;
          } else if (data.VehicleNumber != null) {
            vm.invoice.VehicleType = 1;
            vm.invoice.VehicleNumber = data.VehicleNumber;
          }
        });
      }
    },
    invoice: {
      handler: function (newValue, oldValue) {
        if (newValue.VehicleType == 0 && oldValue.VehicleNumber != null) {
          this.invoice.VehicleNumber = null;
        }
      },
      deep: true,
      immediate: false,
    },
    currentLang() {
      this.setOptions();
    },
  },
  computed: {
    isElecInvoiceCanPreviou() {
      return window.localStorage["isElecInvoiceCanPreviou"] == "true";
    },
    currentLang() {
      return this.$store.state.currentLang;
    },
    isB2B: function () {
      return this.invoice.InvoiceTargetType
        ? this.invoice.InvoiceTargetType == 1
        : false;
    },
  },
  methods: {
    setOptions() {
      this.invoiceTargetTypes = this.$enum.invoiceTargetType.getAll();
      for (let i = 0; i < 24; i++) {
        this.hours.push({ value: `${i}` });
      }
      for (let j = 0; j < 60; j++) {
        this.minutes.push({ value: `${j}` });
      }
      let d = new Date();
      this.selectHour = d.getHours();
      this.selectMinute = d.getMinutes();
    },
    onSaved() {
      const vm = this;
      this.invoice.SalId = this.sal.SalId;
      if (this.isElecInvoiceCanPreviou)
        this.invoice.InvoiceTime = `${this.selectDate} ${this.selectHour}:${this.selectMinute}`;
      this.$api.sals
        .elecInvoice(this.invoice)
        .then(() => {
          $(`#invoiceform`).modal("toggle");
          vm.$emit("invoiceSaved");
        })
        .catch((error) => vm.$func.message.error(error.data.Message));
    },
    onClosed() {
      $(`#invoiceform`).modal("toggle");
    },
  },
  mounted() {
    this.setOptions();
  },
};
</script>
