import { httpGet, httpPost, httpDelete } from "@/request/http";
import func from "@/funcs";

let path = "/stockadjusts";

export default {
  get(id) {
    return httpGet(`${path}/${id}`);
  },
  getCanModify(id) {
    return httpGet(`${path}/${id}/canmodify`);
  },
  getPages(params) {
    return httpGet(`${path}/pages`, params);
  },
  post(item) {
    return httpPost(path, item);
  },
  delete(id) {
    return httpDelete(`${path}/${id}`);
  },
  getExcel() {
    func.getExcel.get(`${path}/foodexcel/example`, "匯入調整單商品");
  },
  getExcelByPost(foods) {
    func.getExcel.post(`${path}/exportfood`, "匯入調整單商品", foods);
  },
  postExcel(formData) {
    return func.postExcel(`${path}/importfood`, formData);
  },
};
