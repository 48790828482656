<template>
  <item-modal
    @onClosed="modalTrigger = !modalTrigger"
    @onSaved="onSaved"
    :title="modalTitle"
    :trigger="modalTrigger"
    modalId="promotionDet"
  >
    <div slot="item">
      <div class="row">
        <div class="col-md-6">
          <div class="form-group">
            <label for="supplierNumber">{{ $t("modal.number") }}</label>
            <input
              type="text"
              class="form-control"
              id="supplierNumber"
              v-model="supplier.SupplierNumber"
              required
            />
          </div>
        </div>
        <div class="col-md-6">
          <div class="form-group">
            <label for="supplierName">{{ $t("modal.name") }}</label>
            <input
              type="text"
              class="form-control"
              id="supplierName"
              v-model="supplier.SupplierName"
              required
            />
          </div>
        </div>
        <div class="col-md-6">
          <div class="form-group">
            <label for="businessNumber">{{ $t("modal.uniformnumber") }}</label>
            <input
              type="text"
              class="form-control"
              id="businessNumber"
              v-model="supplier.BusinessNumber"
            />
          </div>
        </div>
        <div class="col-md-6">
          <div class="form-group">
            <label for="cellphone">{{ $t("modal.cellphone") }}</label>
            <input
              type="text"
              class="form-control"
              id="cellphone"
              v-model="supplier.Cellphone"
            />
          </div>
        </div>
        <div class="col-md-6">
          <div class="form-group">
            <label for="telephone">{{ $t("modal.telephone") }}</label>
            <input
              type="text"
              class="form-control"
              id="telephone"
              v-model="supplier.Telephone"
            />
          </div>
        </div>
        <div class="col-md-6">
          <div class="form-group">
            <label for="email">{{ $t("modal.email") }}</label>
            <input
              type="email"
              class="form-control"
              id="email"
              v-model="supplier.Email"
            />
          </div>
        </div>
        <div class="col-md-6">
          <div class="form-group">
            <label for="contactPerson">{{ $t("modal.contactperson") }}</label>
            <input
              type="text"
              class="form-control"
              id="contactPerson"
              v-model="supplier.ContactPerson"
            />
          </div>
        </div>
        <div class="col-md-6">
          <div class="form-group">
            <label for="faxNumber">{{ $t("modal.faxnumber") }}</label>
            <input
              type="text"
              class="form-control"
              id="faxNumber"
              v-model="supplier.FaxNumber"
            />
          </div>
        </div>
        <div class="col-12">
          <div class="form-group">
            <label for="address">{{ $t("modal.address") }}</label>
            <input
              type="text"
              class="form-control"
              id="address"
              v-model="supplier.Address"
            />
          </div>
        </div>
        <div class="col-12">
          <div class="form-group">
            <label for="memo">{{ $t("modal.memo") }}</label>
            <input
              type="text"
              class="form-control"
              id="memo"
              v-model="supplier.Memo"
            />
          </div>
        </div>
      </div>
    </div>
  </item-modal>
</template>

<script>
import itemModal from "@/components/ItemModal";

export default {
  data() {
    return {
      supplier: {},
      modalTrigger: false,
    };
  },
  props: ["trigger", "id", "number", "modalTitle"],
  watch: {
    trigger() {
      if (!this.id || this.id.length == 0) {
        this.supplier = {};
        if (this.number) {
          this.supplier.SupplierNumber = this.number;
        }
      } else {
        const vm = this;
        this.$api.suppliers.get(this.id).then((data) => {
          vm.supplier = data;
        });
      }
      this.modalTrigger = !this.modalTrigger;
    },
  },
  components: {
    itemModal,
  },
  methods: {
    onSaved() {
      const vm = this;
      this.$api.suppliers
        .save(vm.supplier)
        .then(() => {
          vm.modalTrigger = !vm.modalTrigger;
          vm.$emit("onSaved");
        })
        .catch((error) => vm.$func.message.error(error.data.Message));
    },
  },
};
</script>
