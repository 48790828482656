<template>
  <basic-selects
    :trigger="trigger"
    :isMultiple="isMultiple"
    :columns="columns"
    :items="employees"
    :title="`${$t('title.selectemployee')}`"
    idProperty="EmployeeId"
    nameProperty="EmployeeName"
    allSelectId="employeeAllSelectBox"
    @onSaved="onSaved"
    :id="id"
    :pagination="pagination"
    @getItems="getItems"
    sortBy="EmployeeNumber"
    :defaultItems="defaultItems"
    viewName="EmployeeSelects"
  ></basic-selects>
</template>

<script>
import basicSelects from "./BasicSelects";

export default {
  data() {
    return {
      employees: [],
      columns: [
        {
          name: "EmployeeNumber",
        },
        {
          name: "EmployeeName",
        },
      ],
      id: "employeeSelectModal",
      pagination: {},
    };
  },
  props: {
    trigger: false,
    isMultiple: false,
    modalId: undefined,
    defaultItems: undefined,
  },
  watch: {
    modalId: {
      handler(value) {
        if (value) this.id = value;
      },
      immediate: true,
    },
    trigger() {
      this.getItems();
    },
  },
  components: {
    basicSelects,
  },
  methods: {
    onSaved(item) {
      this.$emit("onSaved", item);
    },
    getItems(dTO) {
      const vm = this;
      this.$api.employees.getPages(dTO).then((data) => {
        vm.employees = data.Datas;
        vm.pagination = data.Pagination;
      });
    },
  },
};
</script>
