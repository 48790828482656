<template>
  <div>
    <pageTable
      :parentPage="pagination"
      @getItems="getTastes"
      @addItem="addTaste"
      @editItem="editTaste"
      @removeItem="removeTaste"
      @trdbClicked="editTaste"
      :items="tastes"
      :columns="columns"
      :idProperty="idProperty"
      :nameProperty="nameProperty"
      @currentIndexChanged="currentIndexChanged"
      :getTrigger="getTrigger"
      :changeIndex="currentIndex"
      :hasAdd="authority.IsAdd"
      :hasEdit="authority.IsUpdate"
      :hasRemove="authority.IsRemove"
    >
      <template slot="button">
        <button
          type="button"
          class="btn btn-outline-primary py-2 px-4"
          style="border-color: transparent"
          v-if="authority.IsUpdate"
          @click="onBefore"
        >
          <i class="fa fa-arrow-up"></i>
        </button>
        <button
          type="button"
          class="btn btn-outline-primary py-2 px-4"
          style="border-color: transparent"
          v-if="authority.IsUpdate"
          @click="onAfter"
        >
          <i class="fa fa-arrow-down"></i>
        </button>
      </template>
      <th class="text-center" slot="th-after" style="width: 120px">
        {{ $t("columnName.isselect") }}
      </th>
      <td class="text-center" slot="td-after" slot-scope="props">
        <span v-if="props.item.IsSelect">V</span>
      </td>
    </pageTable>
    <itemModal
      modalId="kindModal"
      :isShowModal="isShowModal"
      @onClosed="isShowModal = !isShowModal"
      @onSaved="saveTaste"
      :title="modalTitle"
    >
      <div slot="item">
        <div class="row">
          <div class="form-group col-12">
            <label for="tasteName">{{ $t("modal.name") }}</label>
            <input
              type="text"
              class="form-control"
              id="tasteName"
              v-model="taste.TasteName"
              required
            />
          </div>
          <div v-if="hasEnglish" class="form-group col-12 col-md-6">
            <label for="tasteNameEN">{{ $t("modal.english") }}</label>
            <input
              type="text"
              class="form-control"
              id="tasteNameEN"
              v-model="taste.English"
            />
          </div>
          <div v-if="hasJapanese" class="form-group col-12 col-md-6">
            <label for="tasteNameJP">{{ $t("modal.japanese") }}</label>
            <input
              type="text"
              class="form-control"
              id="tasteNameJP"
              v-model="taste.Japanese"
            />
          </div>
          <div v-if="hasKorean" class="form-group col-12 col-md-6">
            <label for="tasteNameKR">{{ $t("modal.korean") }}</label>
            <input
              type="text"
              class="form-control"
              id="tasteNameKR"
              v-model="taste.Korean"
            />
          </div>
          <div v-if="hasFrench" class="form-group col-12 col-md-6">
            <label for="tasteNameFR">{{ $t("modal.french") }}</label>
            <input
              type="text"
              class="form-control"
              id="tasteNameFR"
              v-model="taste.French"
            />
          </div>
          <div class="form-group col-12 col-md-6">
            <label for="price">{{ $t("modal.pricemarkup") }}</label>
            <input
              type="number"
              class="form-control"
              id="price"
              v-model.number="taste.Price"
            />
          </div>
          <div class="form-group col-12">
            <div class="custom-control custom-checkbox">
              <input
                type="checkbox"
                class="custom-control-input"
                id="isSelect"
                v-model="taste.IsSelect"
              />
              <label class="custom-control-label" for="isSelect">{{
                $t("modal.isselect")
              }}</label>
            </div>
          </div>
        </div>
      </div>
    </itemModal>
  </div>
</template>

<script>
import pageTable from "@/components/PageTable";
import itemModal from "@/components/ItemModal";

export default {
  data() {
    return {
      tastes: [],
      taste: {},
      pagination: {},
      isShowModal: false,
      modalTitle: "",
      columns: [
        {
          name: "TasteName",
        },
        {
          name: "Price",
          headerText: "加價",
        },
      ],
      idProperty: "TasteId",
      nameProperty: "TasteName",
      getTrigger: false,
      currentIndex: -1,
      authority: {},
      hasEnglish: window.localStorage["hasEnglish"] == "true",
      hasJapanese: window.localStorage["hasJapanese"] == "true",
      hasKorean: window.localStorage["hasKorean"] == "true",
      hasFrench: window.localStorage["hasFrench"] == "true",
    };
  },
  props: ["tasteKindId"],
  watch: {
    tasteKindId() {
      this.getTrigger = !this.getTrigger;
    },
  },
  components: {
    pageTable,
    itemModal,
  },
  methods: {
    currentIndexChanged(index) {
      this.currentIndex = index;
    },
    getCurrentId() {
      if (this.currentIndex < 0) {
        this.$func.message.alert("請選擇口味 !");
        return undefined;
      }
      const taste = this.tastes[this.currentIndex];
      if (!taste) {
        return undefined;
      } else {
        return taste.TasteId;
      }
    },
    getTastes(dTO) {
      if (!this.tasteKindId || this.tasteKindId.length == 0) return;
      const vm = this;
      this.$api.tastes.getPagesByKind(vm.tasteKindId, dTO).then((data) => {
        vm.tastes = data.Datas;
        vm.pagination = data.Pagination;
      });
    },
    addTaste() {
      if (this.tasteKindId == undefined || this.tasteKindId.length == 0) {
        this.$func.message.alert("請選擇口味類別 !");
        return;
      }
      this.taste = {};
      this.isShowModal = !this.isShowModal;
      this.modalTitle = this.$t("modal.addtaste");
    },
    editTaste(taste) {
      const vm = this;
      this.$api.tastes.get(taste.TasteId).then((data) => {
        vm.taste = data;
        vm.isShowModal = !vm.isShowModal;
        vm.modalTitle = this.$t("modal.edittaste");
      });
    },
    removeTaste(taste) {
      const vm = this;
      this.$api.tastes.remove(taste.TasteId).then(() => {
        vm.getTrigger = !vm.getTrigger;
      });
    },
    saveTaste() {
      const vm = this;
      vm.taste.TasteKindId = vm.tasteKindId;
      this.$api.tastes
        .save(vm.taste)
        .then(() => {
          vm.isShowModal = !vm.isShowModal;
          vm.getTrigger = !vm.getTrigger;
        })
        .catch((error) => vm.$func.message.error(error.data.Message));
    },
    onBefore() {
      const id = this.getCurrentId();
      if (!id) {
        return;
      }
      const vm = this;
      this.$api.tastes
        .before({ Id: id, PageSize: this.pagination.PageSize })
        .then((data) => {
          if (data.Pagination.CurrentPage < vm.pagination.CurrentPage) {
            vm.tastes = data.Datas;
            vm.currentIndexChanged(data.Pagination.PageSize - 1);
            vm.pagination = data.Pagination;
          } else {
            vm.currentIndexChanged(vm.currentIndex - 1);
          }
          vm.tastes = data.Datas;
          vm.pagination = data.Pagination;
        })
        .catch((error) => vm.$func.message.error(error.data.Message));
    },
    onAfter() {
      const id = this.getCurrentId();
      if (!id) {
        return;
      }
      const vm = this;
      this.$api.tastes
        .after({ Id: id, PageSize: this.pagination.PageSize })
        .then((data) => {
          if (data.Pagination.CurrentPage > vm.pagination.CurrentPage) {
            vm.tastes = data.Datas;
            vm.currentIndexChanged(0);
            vm.pagination = data.Pagination;
          } else {
            vm.currentIndexChanged(vm.currentIndex + 1);
          }
          vm.tastes = data.Datas;
          vm.pagination = data.Pagination;
        })
        .catch((error) => vm.$func.message.error(error.data.Message));
    },
  },
  created() {
    this.authority = this.$func.localStorage.getAuthByName(this.$route.name);
  },
};
</script>
