export default function (name) {
  switch (name.toLowerCase()) {
    case "stores":
      return "門市";
    case "businesses":
      return "營業人";
    case "utaiforms":
      return "申請表單";
    case "invoiceprizes":
      return "中獎名單";
    case "devices":
      return "終端機";
    case "users":
      return "使用者";
    case "terminals":
      return "收銀台";
    case "employees":
      return "員工";
    case "customers":
      return "會員";
    case "membersetting":
      return "會員設定";
    case "memberupgraderules":
      return "會員升等規則";
    case "integralrules":
      return "積分規則";
    case "ocardrules":
      return "Ocard 促銷規則";
    case "foodmajorkinds":
      return "商品大類別";
    case "foodkinds":
      return "商品類別";
    case "foods":
      return "商品";
    case "departments":
      return "部門";
    case "tastekinds":
      return "口味";
    case "foodkindtastes":
      return "商品類別口味";
    case "foodtastes":
      return "商品口味";
    case "combokinds":
      return "套餐";
    case "foodcombos":
      return "商品套餐";
    case "functions":
      return "功能鍵資料";
    case "comboadd":
      return "新增套餐";
    case "downloads":
      return "資料下傳";
    case "downloadadd":
      return "新增下傳";
    case "moduleauthority":
      return "權限模組";
    case "modulefoodkind":
      return "商品類別模組";
    case "modulefood":
      return "商品模組";
    case "modulestore":
      return "門市模組";
    case "modulestoreselect":
      return "門市";
    case "dirmoduledevice":
    case "moduleterminal":
      return "收銀台模組";
    case "moduleemployee":
      return "員工模組";
    case "moduleinvisible":
      return "商品隱藏模組";
    case "modulesequence":
      return "商品順序模組";
    case "modulefunction":
      return "功能鍵模組";
    case "authority":
      return "權限";
    case "foodkindsequence":
      return "商品類別順序";
    case "foodsequence":
      return "商品順序";
    case "functionbutton":
      return "功能鍵";
    case "functionbuttonadd":
      return "新增功能鍵";
    case "reportsale":
      return "銷售報表";
    case "reportsaldata":
      return "銷售單";
    case "reportinvoice":
      return "發票報表";
    case "reportinvoiceprize":
      return "中獎發票報表";
    case "reportcustomer":
      return "會員報表";
    case "customersimple":
      return "會員交易簡要表";
    case "customerdetail":
      return "會員交易明細表";
    case "reportsupplier":
      return "廠商報表";
    case "suppliersimple":
      return "廠商交易簡要表";
    case "supplierdetail":
      return "廠商交易明細表";
    case "reportsupplierfoodsale":
      return "廠商商品銷售表";
    case "reportsupplierfoodsaleoriginal":
      return "廠商商品銷售表(原價)";
    case "supplierfoodsale":
      return "廠商商品銷售統計表";
    case "supplierfoodsaleoriginal":
      return "廠商商品銷售統計表(原價)";
    case "invoicedetail":
      return "發票明細表";
    case "invoicesimple":
      return "發票簡要表";
    case "reportworkrecord":
      return "員工出勤表";
    case "workrecorddetail":
      return "員工出勤明細表";
    case "workrecordsimple":
      return "員工出勤簡要表";
    case "saledetail":
      return "銷售明細表";
    case "salesimple":
      return "銷售簡要表";
    case "saleorderdetail":
      return "訂單明細表";
    case "saleordersimple":
      return "訂單簡要表";
    case "salesummary":
      return "銷售彙總表";
    case "foodsalestatistic":
      return "商品銷售統計表";
    case "fooddaystatistic":
      return "商品日統計表";
    case "foodsaledetail":
      return "商品銷售明細表";
    case "reportfoodsale":
      return "商品銷售表";
    case "reportcollectionpayment":
      return "代收代支表";
    case "collectionpaymentdetail":
      return "代收代支明細表";
    case "foodkindstatistic":
      return "類別統計表";
    case "foodmajorkindstatistic":
      return "大類統計表";
    case "reportfree":
      return "招待報表";
    case "freestatistic":
      return "招待統計表";
    case "freedetail":
      return "招待明細表";
    case "reportcustomerfree":
      return "會員招待報表";
    case "customerfreestatistic":
      return "會員招待統計表";
    case "timestatistic":
      return "時段統計表";
    case "employeeanalysis":
      return "員工分析表";
    case "modulepromotion":
      return "促銷模組";
    case "directorykinds":
      return "科目";
    case "pays":
      return "付款別";
    case "promotion":
      return "促銷設定";
    case "sales":
      return "銷貨單";
    case "salereturns":
      return "銷貨退出單";
    case "inventorys":
      return "盤點單";
    case "inventoryadd":
      return "新增盤點單";
    case "inventoryedit":
      return "編輯盤點單";
    case "stockadjusts":
      return "調整單";
    case "stockadjustadd":
      return "新增調整單";
    case "stockadjustedit":
      return "編輯調整單";
    case "stocktransfers":
      return "調撥單";
    case "stocktransferadd":
      return "新增調撥單";
    case "stocktransferedit":
      return "編輯調撥單";
    case "stocktransfercheck":
      return "點收調撥單";
    case "sals":
      return "銷貨單";
    case "saladd":
      return "新增銷貨單";
    case "saledit":
      return "編輯銷貨單";
    case "salreturns":
      return "銷貨退出單";
    case "salreturnadd":
      return "新增銷退單";
    case "salreturnedit":
      return "編輯銷退單";
    case "purchases":
      return "進貨單";
    case "purchaseorderturn":
    case "purchaseadd":
      return "新增進貨單";
    case "purchaseedit":
      return "編輯進貨單";
    case "purchasereturns":
      return "進貨退出單";
    case "purchasereturnadd":
      return "新增進退單";
    case "purchasereturnedit":
      return "編輯進退單";
    case "purchaseorders":
      return "採購單";
    case "purchaseorderadd":
      return "新增採購單";
    case "purchaseorderedit":
      return "編輯採購單";
    case "payments":
      return "付款單";
    case "paymentadd":
      return "新增付款單";
    case "paymentedit":
      return "編輯付款單";
    case "receivables":
      return "應收帳款";
    case "receipts":
      return "收款單";
    case "receiptadd":
      return "新增收款單";
    case "receiptedit":
      return "編輯收款單";
    case "stock":
      return "庫存現量表";
    case "payables":
      return "應付帳款";
    case "reportfoodmovement":
      return "商品異動表";
    case "foodmovementsimple":
      return "商品異動簡要表";
    case "foodmovementdetail":
      return "商品異動明細表";
    case "paymentsimple":
      return "付款單簡要表";
    case "paymentdetail":
      return "付款單明細表";
    case "payablesimple":
      return "應付帳款簡要表";
    case "payabledetail":
      return "應付帳款明細表";
    case "receiptsimple":
      return "收款單簡要表";
    case "receiptdetail":
      return "收款單明細表";
    case "receivablestatistic":
      return "應收帳款統計表";
    case "receivablesimple":
      return "應收帳款簡要表";
    case "receivabledetail":
      return "應收帳款明細表";
    case "paydetail":
      return "付款類別明細表";
    case "paysimple":
      return "付款類別簡要表";
    case "reportpay":
      return "付款類別報表";
    case "salepaydetail":
      return "付款別明細表";
    case "salepaysimple":
      return "付款別簡要表";
    case "reportsalepay":
      return "付款別報表";
    case "dirday":
    case "daymodule":
      return "時間模組";
    case "day":
      return "時間";
    case "warehouses":
      return "倉庫";
    case "suppliers":
      return "廠商";
    case "tastestatistic":
      return "口味統計表";
    case "pricelabels":
      return "價格標";
    case "safeqty":
      return "安全存量";
    case "reportdeposit":
      return "儲值交易報表";
    case "depositsaledetail":
      return "儲值消費明細表";
    case "depositstoredetail":
      return "儲值加值明細表";
    case "reportsafeqty":
      return "低於安全庫存量表";
    case "supplierfoods":
      return "廠商商品";
    case "pyreceivables":
      return "應收帳款";
    case "reportinventorypandl":
      return "盤點盈虧表";
    case "inventorypandldetail":
      return "盤點盈虧明細表";
    case "inventorypandlstatistic":
      return "盤點盈虧統計表";
    case "reportclasssale":
      return "班別銷貨報表";
    case "classsalesimple":
      return "班別銷貨簡要表";
    case "classsalestatistic":
      return "班別銷貨統計表";
    case "salesimplesaler":
      return "銷貨簡要表(業務員)";
    case "saledetailsaler":
      return "銷貨明細表(業務員)";
    case "daystatisticsaler":
      return "日統計表(業務員)";
    case "reportsaler":
      return "業務員報表";
    case "reportpurchase":
      return "進貨報表";
    case "reportpurchasereturn":
      return "進退報表";
    case "reportpurchaseorder":
      return "採購報表";
    case "purchasesimple":
      return "進貨簡要表";
    case "purchasedetail":
      return "進貨明細表";
    case "purchasereturnsimple":
      return "進退簡要表";
    case "purchasereturndetail":
      return "進退明細表";
    case "purchaseordersimple":
      return "採購簡要表";
    case "purchaseorderdetail":
      return "採購明細表";
    case "reportsupplierpurchaseorder":
    case "supplierpurchaseorderdetail":
      return "廠商採購明細表";
    case "reportfoodpurchaseorder":
      return "商品採購表";
    case "foodpurchaseorder":
      return "商品採購表";
    case "foodpurchaseorderdetail":
      return "商品採購明細表";
    case "reportfoodpurchase":
      return "商品進貨表";
    case "foodpurchase":
      return "商品進貨表";
    case "foodpurchasedetail":
      return "商品進貨明細表";
    case "reportstockadjust":
      return "調整單報表";
    case "stockadjustsimple":
      return "調整單簡要表";
    case "stockadjustdetail":
      return "調整單明細表";
    case "reportstockadjustdirectory":
      return "調整單科目統計表";
    case "reportstocktransfers":
      return "調撥單報表";
    case "stocktransfersimple":
      return "調撥單簡要表";
    case "stocktransferdetail":
      return "調撥單明細表";
    case "peopledetail":
      return "人數明細表";
    case "peoplesimple":
      return "人數簡要表";
    case "peoplestatistic":
      return "人數統計表";
    case "reportpeople":
      return "人數報表";
    case "reportintegral":
      return "積點交易報表";
    case "integraltradedetail":
      return "積點交易明細表";
    case "integraltradestatistic":
      return "積點交易統計表";
    case "lystock":
      return "凌越庫存";
    case "reportsaleorder":
      return "訂單報表";
    case "reportorderfoodstatistic":
      return "訂單商品統計表";
    case "inventorymachine":
      return "盤點機";
    case "reportinventory":
      return "盤點單報表";
    case "inventorysimple":
      return "盤點單簡要表";
    case "inventorydetail":
      return "盤點單明細表";
    case "accounts":
      return "帳戶";
    case "coupondetail":
      return "折價券明細表";
    case "reportcoupon":
      return "折價券報表";
    case "onlineorderstore":
      return "店家設定";
    case "onlineorderwork":
      return "營業時間";
    case "moduleonlineorderfood":
      return "商品設定";
    case "ubereats":
      return "UberEats";
    case "lineordersetting":
      return "LINE點餐設定";
    case "combinations":
      return "組合品";
    case "combinationedit":
      return "編輯組合品";
    case "combinationtrades":
      return "組合單";
    case "combinationtradeadd":
      return "新增組合單";
    case "combinationtradeedit":
      return "編輯組合單";
    case "seperationtrades":
      return "拆解單";
    case "seperationtradeadd":
      return "新增拆解單";
    case "combinationtradereturn":
      return "新增拆解單";
    case "seperationtradeedit":
      return "編輯拆解單";
    case "reportcombinationtrade":
      return "組合單報表";
    case "reportseperationtrade":
      return "拆解單報表";
    case "reportcustomerstock":
      return "會員庫存表";
    case "reportcustomerstockmovement":
      return "會員庫存異動表";
    case "reportcustomerfoodsale":
      return "會員商品銷售表";
    case "reportcustomersalestatistic":
      return "會員銷售統計表";
    case "customerstock":
      return "會員庫存表";
    case "customerstockmovementdetail":
      return "會員庫存異動表";
    case "reportpgtalkdeposit":
      return "PGTalk儲值交易表";
    case "pgtalkdepositdetail":
      return "PGTalk儲值交易明細表";
    case "reportstockbatch":
      return "批號庫存現量表";
    case "reportstockbatchmovement":
      return "批號庫存異動表";
    case "reporterpsummary":
      return "商品進銷存彙總表";
    case "changeprice":
      return "變更售價別";
    case "memberweb":
      return "會員網站";
    case "specifications":
      return "商品款式";
    case "errormessage":
      return "錯誤訊息";
    case "thirdparty":
      return "第三方";
    case "system":
      return "系統";
    case "notification":
      return "推播";
    case "webhook":
      return "回呼";
    case "tablemaps":
      return "桌位";
    case "brands":
      return "品牌";
    case "foodbrands":
      return "商品品牌";
  }
}
