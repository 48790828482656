import { render, staticRenderFns } from "./FoodMajorKinds.vue?vue&type=template&id=9a5a43a8&"
import script from "./FoodMajorKinds.vue?vue&type=script&lang=js&"
export * from "./FoodMajorKinds.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports