<template>
  <form @submit.prevent="onSave">
    <div class="row">
      <h3 class="col">{{ $t("title.authority") }}</h3>
      <div class="col-auto">
        <button type="submit" class="btn btn-primary update">
          <i class="fas fa-check"></i>
        </button>
      </div>
    </div>
    <div class="accordion mt-1" id="accordionAuth">
      <div class="card my-0">
        <div
          class="card-header"
          id="headingOne"
          data-toggle="collapse"
          data-target="#collapse-front"
          aria-expanded="true"
          aria-controls="collapseOne"
        >
          <h2 class="mb-0">
            <button class="btn btn-link" type="button">
              {{ $t("button.frontendfunctions") }}
            </button>
          </h2>
        </div>
        <div
          id="collapse-front"
          class="collapse show"
          aria-labelledby="headingOne"
          data-parent="#accordionAuth"
        >
          <div class="card-body">
            <table class="table table-hover">
              <thead>
                <tr>
                  <th :width="nameWidth">{{ $t("columnName.name") }}</th>
                  <th>
                    <div class="custom-control custom-checkbox mr-sm-2">
                      <input
                        type="checkbox"
                        class="custom-control-input"
                        id="allFront"
                        v-model="allFront"
                      />
                      <label class="custom-control-label" for="allFront">{{
                        $t("columnName.use")
                      }}</label>
                    </div>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="authority in getByKind(3)"
                  :key="authority.AuthItemType"
                  @click="authority.IsList = !authority.IsList"
                >
                  <td>{{ authority.ViewName }}</td>
                  <td>
                    <input
                      class="checkbox"
                      type="checkbox"
                      v-model="authority.IsList"
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div class="card my-0">
        <div
          class="card-header"
          id="headingOne"
          data-toggle="collapse"
          data-target="#collapse-basic"
          aria-expanded="true"
          aria-controls="collapseOne"
        >
          <h2 class="mb-0">
            <button class="btn btn-link" type="button">
              {{ $t("button.basic") }}
            </button>
          </h2>
        </div>
        <div
          id="collapse-basic"
          class="collapse"
          aria-labelledby="headingTwo"
          data-parent="#accordionAuth"
        >
          <div class="card-body">
            <table class="table table-hover">
              <thead>
                <tr>
                  <th :width="nameWidth">{{ $t("columnName.name") }}</th>
                  <th>
                    <div class="custom-control custom-checkbox mr-sm-2">
                      <input
                        type="checkbox"
                        class="custom-control-input"
                        id="allBasic"
                        v-model="allBasic"
                      />
                      <label class="custom-control-label" for="allBasic">{{
                        $t("columnName.use")
                      }}</label>
                    </div>
                  </th>
                  <th>
                    <div class="custom-control custom-checkbox mr-sm-2">
                      <input
                        type="checkbox"
                        class="custom-control-input"
                        id="allBasicAdd"
                        v-model="allBasicAdd"
                      />
                      <label class="custom-control-label" for="allBasicAdd">{{
                        $t("button.add")
                      }}</label>
                    </div>
                  </th>
                  <th>
                    <div class="custom-control custom-checkbox mr-sm-2">
                      <input
                        type="checkbox"
                        class="custom-control-input"
                        id="allBasicUpdate"
                        v-model="allBasicUpdate"
                      />
                      <label
                        class="custom-control-label"
                        for="allBasicUpdate"
                        >{{ $t("button.edit") }}</label
                      >
                    </div>
                  </th>
                  <th>
                    <div class="custom-control custom-checkbox mr-sm-2">
                      <input
                        type="checkbox"
                        class="custom-control-input"
                        id="allBasicRemove"
                        v-model="allBasicRemove"
                      />
                      <label
                        class="custom-control-label"
                        for="allBasicRemove"
                        >{{ $t("button.remove") }}</label
                      >
                    </div>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="authority in getByKind(4)"
                  :key="authority.AuthItemType"
                >
                  <td>{{ authority.ViewName }}</td>
                  <td @click="authority.IsList = !authority.IsList">
                    <input
                      class="checkbox"
                      type="checkbox"
                      v-model="authority.IsList"
                    />
                  </td>
                  <template v-if="authority.AuthItemType != 156">
                    <td @click="authority.IsAdd = !authority.IsAdd">
                      <input
                        class="checkbox"
                        type="checkbox"
                        v-model="authority.IsAdd"
                      />
                    </td>
                    <td @click="authority.IsUpdate = !authority.IsUpdate">
                      <input
                        class="checkbox"
                        type="checkbox"
                        v-model="authority.IsUpdate"
                      />
                    </td>
                    <td @click="authority.IsRemove = !authority.IsRemove">
                      <input
                        class="checkbox"
                        type="checkbox"
                        v-model="authority.IsRemove"
                      />
                    </td>
                  </template>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div class="card my-0">
        <div
          class="card-header"
          id="headingOne"
          data-toggle="collapse"
          data-target="#collapse-module"
          aria-expanded="true"
          aria-controls="collapseOne"
        >
          <h2 class="mb-0">
            <button class="btn btn-link" type="button">
              {{ $t("button.modules") }}
            </button>
          </h2>
        </div>
        <div
          id="collapse-module"
          class="collapse"
          aria-labelledby="headingThree"
          data-parent="#accordionAuth"
        >
          <div class="card-body">
            <table class="table table-hover">
              <thead>
                <tr>
                  <th :width="nameWidth">{{ $t("columnName.name") }}</th>
                  <th>
                    <div class="custom-control custom-checkbox mr-sm-2">
                      <input
                        type="checkbox"
                        class="custom-control-input"
                        id="allModules"
                        v-model="allModules"
                      />
                      <label class="custom-control-label" for="allModules">{{
                        $t("columnName.use")
                      }}</label>
                    </div>
                  </th>
                  <th>
                    <div class="custom-control custom-checkbox mr-sm-2">
                      <input
                        type="checkbox"
                        class="custom-control-input"
                        id="allModulesAdd"
                        v-model="allModulesAdd"
                      />
                      <label class="custom-control-label" for="allModulesAdd">{{
                        $t("button.add")
                      }}</label>
                    </div>
                  </th>
                  <th>
                    <div class="custom-control custom-checkbox mr-sm-2">
                      <input
                        type="checkbox"
                        class="custom-control-input"
                        id="allModulesUpdate"
                        v-model="allModulesUpdate"
                      />
                      <label
                        class="custom-control-label"
                        for="allModulesUpdate"
                        >{{ $t("button.edit") }}</label
                      >
                    </div>
                  </th>
                  <th>
                    <div class="custom-control custom-checkbox mr-sm-2">
                      <input
                        type="checkbox"
                        class="custom-control-input"
                        id="allModulesRemove"
                        v-model="allModulesRemove"
                      />
                      <label
                        class="custom-control-label"
                        for="allModulesRemove"
                        >{{ $t("button.remove") }}</label
                      >
                    </div>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="authority in getByKind(7)"
                  :key="authority.AuthItemType"
                >
                  <td>{{ authority.ViewName }}</td>
                  <td @click="authority.IsList = !authority.IsList">
                    <input
                      class="checkbox"
                      type="checkbox"
                      v-model="authority.IsList"
                    />
                  </td>
                  <td @click="authority.IsAdd = !authority.IsAdd">
                    <input
                      class="checkbox"
                      type="checkbox"
                      v-model="authority.IsAdd"
                    />
                  </td>
                  <td @click="authority.IsUpdate = !authority.IsUpdate">
                    <input
                      class="checkbox"
                      type="checkbox"
                      v-model="authority.IsUpdate"
                    />
                  </td>
                  <td @click="authority.IsRemove = !authority.IsRemove">
                    <input
                      class="checkbox"
                      type="checkbox"
                      v-model="authority.IsRemove"
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div class="card my-0">
        <div
          class="card-header"
          id="headingOne"
          data-toggle="collapse"
          data-target="#collapse-report"
          aria-expanded="true"
          aria-controls="collapseOne"
        >
          <h2 class="mb-0">
            <button class="btn btn-link" type="button">
              {{ $t("button.reports") }}
            </button>
          </h2>
        </div>
        <div
          id="collapse-report"
          class="collapse"
          aria-labelledby="headingThree"
          data-parent="#accordionAuth"
        >
          <div class="card-body">
            <table class="table table-hover">
              <thead>
                <tr>
                  <th :width="nameWidth">{{ $t("columnName.name") }}</th>
                  <th>
                    <div class="custom-control custom-checkbox mr-sm-2">
                      <input
                        type="checkbox"
                        class="custom-control-input"
                        id="allReport"
                        v-model="allReport"
                      />
                      <label class="custom-control-label" for="allReport">{{
                        $t("columnName.use")
                      }}</label>
                    </div>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="authority in getByKind(5)"
                  :key="authority.AuthItemType"
                  @click="authority.IsList = !authority.IsList"
                >
                  <td>{{ authority.ViewName }}</td>
                  <td>
                    <input
                      class="checkbox"
                      type="checkbox"
                      v-model="authority.IsList"
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div class="card my-0" v-if="isCustomer">
        <div
          class="card-header"
          id="headingOne"
          data-toggle="collapse"
          data-target="#collapse-customer"
          aria-expanded="true"
          aria-controls="collapseOne"
        >
          <h2 class="mb-0">
            <button class="btn btn-link" type="button">
              {{ $t("button.customers") }}
            </button>
          </h2>
        </div>
        <div
          id="collapse-customer"
          class="collapse"
          aria-labelledby="headingThree"
          data-parent="#accordionAuth"
        >
          <div class="card-body">
            <table class="table table-hover">
              <thead>
                <tr>
                  <th :width="nameWidth">{{ $t("columnName.name") }}</th>
                  <th>
                    <div class="custom-control custom-checkbox mr-sm-2">
                      <input
                        type="checkbox"
                        class="custom-control-input"
                        id="allCustomer"
                        v-model="allCustomer"
                      />
                      <label class="custom-control-label" for="allCustomer">{{
                        $t("columnName.use")
                      }}</label>
                    </div>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="authority in getByKind(8)"
                  :key="authority.AuthItemType"
                  @click="authority.IsList = !authority.IsList"
                >
                  <td>{{ authority.ViewName }}</td>
                  <td>
                    <input
                      class="checkbox"
                      type="checkbox"
                      v-model="authority.IsList"
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div class="card my-0" v-if="isERP | hasLY">
        <div
          class="card-header"
          id="headingOne"
          data-toggle="collapse"
          data-target="#collapse-erp"
          aria-expanded="true"
          aria-controls="collapseOne"
        >
          <h2 class="mb-0">
            <button class="btn btn-link" type="button">
              {{ $t("button.erp") }}
            </button>
          </h2>
        </div>
        <div
          id="collapse-erp"
          class="collapse"
          aria-labelledby="headingThree"
          data-parent="#accordionAuth"
        >
          <div class="card-body">
            <table class="table table-hover">
              <thead>
                <tr>
                  <th :width="nameWidth">{{ $t("columnName.name") }}</th>
                  <th>
                    <div class="custom-control custom-checkbox mr-sm-2">
                      <input
                        type="checkbox"
                        class="custom-control-input"
                        id="allERP"
                        v-model="allERP"
                      />
                      <label class="custom-control-label" for="allERP">{{
                        $t("columnName.use")
                      }}</label>
                    </div>
                  </th>
                  <th>
                    <div class="custom-control custom-checkbox mr-sm-2">
                      <input
                        type="checkbox"
                        class="custom-control-input"
                        id="allERPAdd"
                        v-model="allERPAdd"
                      />
                      <label class="custom-control-label" for="allERPAdd">{{
                        $t("button.add")
                      }}</label>
                    </div>
                  </th>
                  <th>
                    <div class="custom-control custom-checkbox mr-sm-2">
                      <input
                        type="checkbox"
                        class="custom-control-input"
                        id="allERPUpdate"
                        v-model="allERPUpdate"
                      />
                      <label class="custom-control-label" for="allERPUpdate">{{
                        $t("button.edit")
                      }}</label>
                    </div>
                  </th>
                  <th>
                    <div class="custom-control custom-checkbox mr-sm-2">
                      <input
                        type="checkbox"
                        class="custom-control-input"
                        id="allERPRemove"
                        v-model="allERPRemove"
                      />
                      <label class="custom-control-label" for="allERPRemove">{{
                        $t("button.remove")
                      }}</label>
                    </div>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="authority in getByKind(10)"
                  :key="authority.AuthItemType"
                >
                  <td>{{ authority.ViewName }}</td>
                  <td @click="authority.IsList = !authority.IsList">
                    <input
                      class="checkbox"
                      type="checkbox"
                      v-model="authority.IsList"
                    />
                  </td>
                  <td @click="authority.IsAdd = !authority.IsAdd">
                    <input
                      class="checkbox"
                      type="checkbox"
                      v-model="authority.IsAdd"
                    />
                  </td>
                  <td @click="authority.IsUpdate = !authority.IsUpdate">
                    <input
                      class="checkbox"
                      type="checkbox"
                      v-model="authority.IsUpdate"
                    />
                  </td>
                  <td @click="authority.IsRemove = !authority.IsRemove">
                    <input
                      class="checkbox"
                      type="checkbox"
                      v-model="authority.IsRemove"
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div class="card my-0">
        <div
          class="card-header"
          id="headingOne"
          data-toggle="collapse"
          data-target="#collapse-account"
          aria-expanded="true"
          aria-controls="collapseOne"
        >
          <h2 class="mb-0">
            <button class="btn btn-link" type="button">
              {{ $t("button.accounts") }}
            </button>
          </h2>
        </div>
        <div
          id="collapse-account"
          class="collapse"
          aria-labelledby="headingThree"
          data-parent="#accordionAuth"
        >
          <div class="card-body">
            <table class="table table-hover">
              <thead>
                <tr>
                  <th :width="nameWidth">{{ $t("columnName.name") }}</th>
                  <th>
                    <div class="custom-control custom-checkbox mr-sm-2">
                      <input
                        type="checkbox"
                        class="custom-control-input"
                        id="allAccount"
                        v-model="allAccount"
                      />
                      <label class="custom-control-label" for="allAccount">{{
                        $t("columnName.use")
                      }}</label>
                    </div>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="authority in getByKind(12)"
                  :key="authority.AuthItemType"
                  @click="authority.IsList = !authority.IsList"
                >
                  <td>{{ authority.ViewName }}</td>
                  <td>
                    <input
                      class="checkbox"
                      type="checkbox"
                      v-model="authority.IsList"
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div class="card my-0">
        <div
          class="card-header"
          id="headingOne"
          data-toggle="collapse"
          data-target="#collapse-download"
          aria-expanded="true"
          aria-controls="collapseOne"
        >
          <h2 class="mb-0">
            <button class="btn btn-link" type="button">
              {{ $t("button.cloud") }}
            </button>
          </h2>
        </div>
        <div
          id="collapse-download"
          class="collapse"
          aria-labelledby="headingThree"
          data-parent="#accordionAuth"
        >
          <div class="card-body">
            <table class="table table-hover">
              <thead>
                <tr>
                  <th :width="nameWidth">{{ $t("columnName.name") }}</th>
                  <th>
                    <div class="custom-control custom-checkbox mr-sm-2">
                      <input
                        type="checkbox"
                        class="custom-control-input"
                        id="allDownload"
                        v-model="allDownload"
                      />
                      <label class="custom-control-label" for="allDownload">{{
                        $t("columnName.use")
                      }}</label>
                    </div>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="authority in getByKind(9)"
                  :key="authority.AuthItemType"
                  @click="authority.IsList = !authority.IsList"
                >
                  <td>{{ authority.ViewName }}</td>
                  <td>
                    <input
                      class="checkbox"
                      type="checkbox"
                      v-model="authority.IsList"
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </form>
</template>

<script>
export default {
  data() {
    return {
      moduleId: "",
      isCustomer: window.localStorage["isCustomer"] == "true",
      isERP: window.localStorage["isERP"] == "true",
      authoritys: [],
      nameWidth: 350,
      allBasic: false,
      allBasicAdd: false,
      allBasicUpdate: false,
      allBasicRemove: false,
      allModules: false,
      allModulesAdd: false,
      allModulesUpdate: false,
      allModulesRemove: false,
      allReport: false,
      allCustomer: false,
      allAccount: false,
      allERP: false,
      allERPAdd: false,
      allERPUpdate: false,
      allERPRemove: false,
      allDownload: false,
      allFront: false,
    };
  },
  computed: {
    hasLY() {
      return window.localStorage["erpType"] == 1;
    },
  },
  watch: {
    allBasic(value) {
      this.setCheckType(4, value);
    },
    allBasicAdd(value) {
      this.authoritys.forEach((item) => {
        if (item.AuthKindType == 4) item.IsAdd = value;
      });
    },
    allBasicUpdate(value) {
      this.authoritys.forEach((item) => {
        if (item.AuthKindType == 4) item.IsUpdate = value;
      });
    },
    allBasicRemove(value) {
      this.authoritys.forEach((item) => {
        if (item.AuthKindType == 4) item.IsRemove = value;
      });
    },
    allModules(value) {
      this.setCheckType(7, value);
    },
    allModulesAdd(value) {
      this.authoritys.forEach((item) => {
        if (item.AuthKindType == 7) item.IsAdd = value;
      });
    },
    allModulesUpdate(value) {
      this.authoritys.forEach((item) => {
        if (item.AuthKindType == 7) item.IsUpdate = value;
      });
    },
    allModulesRemove(value) {
      this.authoritys.forEach((item) => {
        if (item.AuthKindType == 7) item.IsRemove = value;
      });
    },
    allReport(value) {
      this.setCheckType(5, value);
    },
    allCustomer(value) {
      this.setCheckType(8, value);
    },
    allAccount(value) {
      this.setCheckType(12, value);
    },
    allERP(value) {
      this.setCheckType(10, value);
    },
    allERPAdd(value) {
      this.authoritys.forEach((item) => {
        if (item.AuthKindType == 10) item.IsAdd = value;
      });
    },
    allERPUpdate(value) {
      this.authoritys.forEach((item) => {
        if (item.AuthKindType == 10) item.IsUpdate = value;
      });
    },
    allERPRemove(value) {
      this.authoritys.forEach((item) => {
        if (item.AuthKindType == 10) item.IsRemove = value;
      });
    },
    allDownload(value) {
      this.setCheckType(9, value);
    },
    allFront(value) {
      this.setCheckType(3, value);
    },
  },
  methods: {
    setCheckType(type, value) {
      this.authoritys.forEach((item) => {
        if (item.AuthKindType == type) item.IsList = value;
      });
    },
    getByKind(type) {
      return this.authoritys.filter(function (item) {
        return item.AuthKindType == type;
      });
    },
    onSave() {
      const vm = this;
      this.$api.modules
        .postAuthoritys(vm.moduleId, vm.authoritys)
        .then(vm.$router.push({ name: "ModuleAuthority" }));
    },
  },
  mounted() {
    this.moduleId = this.$route.params["id"];
    const vm = this;
    this.$api.modules.getAuthoritys(vm.moduleId).then((data) => {
      vm.authoritys = data.Authoritys;
    });
  },
};
</script>

<style lang="scss" scoped>
.update {
  font-size: 24px;
  position: fixed;
  right: 25px;
  bottom: 25px;
  z-index: 1000;
  width: 60px;
  height: 60px;
  border-radius: 60px;
}
</style>
