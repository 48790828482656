import { httpPost } from "@/request/http";
import func from "@/funcs";
import i18n from "@/langs/i18n";

export default function (url, formData, timeout) {
  let headers = { "Content-Type": "multipart/form-data" };
  if (timeout != undefined) headers.timeout = timeout;
  return new Promise((resolve, reject) => {
    httpPost(url, formData, headers)
      .then((data) => {
        let errortext = "";
        if (data.ErrorText) {
          errortext = `\n\n` + data.ErrorText;
        }
        func.message.success(
          `${i18n.t("funcMsg.importsuccess")} !` + errortext
        );
        resolve(data);
      })
      .catch((response) => {
        func.message.error(response.data.Message);
        reject(response);
      });
  });
}
