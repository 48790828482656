<template>
  <div>
    <condition-part
      :hasCustomer="true"
      :hasExcel="true"
      :hasPdf="true"
      :hasAdd="false"
      :hasEdit="true"
      :hasRemove="false"
      :hasInvoicePeriod="true"
      :columns="columns"
      :items="items"
      :pagination="pagination"
      :getTrigger="getTrigger"
      :isSortable="true"
      @getItems="getItems"
      @editItem="editItem"
      @downloadExcel="downloadExcel"
      @downloadPdf="downloadPdf"
      @printPdf="printPdf"
    />
    <invoice-prize-data
      :isShow="isShowModal"
      :invoice="item"
      @save="saveItem"
      @close="isShowModal = !isShowModal"
    >
    </invoice-prize-data>
  </div>
</template>

<script>
import conditionPart from "@/components/parts/conditionPart";
import invoicePrizeData from "@/components/datas/InvoicePrizeData";

export default {
  data() {
    return {
      items: [],
      item: {},
      isShowModal: false,
      pagination: {},
      columns: [
        {
          name: "SalNumber",
        },
        {
          name: "InvoiceNumber",
        },
        {
          name: "Period",
        },
        {
          name: "CustomerNumber",
        },
        {
          name: "CustomerName",
        },
        {
          name: "Telephone",
        },
        {
          name: "Cellphone",
        },
        {
          name: "Address",
        },
        {
          name: "InvoiceEmail",
        },
        {
          name: "InvoicePrizeType",
        },
        {
          name: "InvoicePrizeAmount",
        },
        {
          name: "InvoicePrizeNumber",
        },
      ],
      getTrigger: false,
      condition: {},
    };
  },
  components: {
    conditionPart,
    invoicePrizeData,
  },
  computed: {},
  methods: {
    downloadExcel(condition) {
      this.$api.reportExcels.getInvoicePrize(condition);
    },
    downloadPdf(condition) {
      this.$api.reportPdfs.getInvoicePrize(condition);
    },
    printPdf(condition) {
      this.$api.reportPdfs.printInvoicePrize(condition);
    },
    getItems(condition) {
      const vm = this;
      this.$api.reports.getInvoicePrize(condition).then((data) => {
        vm.items = data.Datas;
        vm.pagination = data.Pagination;
      });
    },
    editItem(item) {
      this.item = {
        CustomerName: item.CustomerName,
        Cellphone: item.Cellphone,
        Telephone: item.Telephone,
        Address: item.Address,
      };
      this.isShowModal = !this.isShowModal;
    },
    saveItem() {
      this.isShowModal = !this.isShowModal;
      this.getTrigger = !this.getTrigger;
    },
  },
};
</script>
