import i18n from "@/langs/i18n";

export function getAll() {
  return [
    {
      value: 0,
      name: "Trade",
      text: `${i18n.t("option.dateSourceType.Trade")}`,
    },
    {
      value: 1,
      name: "Account",
      text: `${i18n.t("option.dateSourceType.Account")}`,
    },
    {
      value: 4,
      name: "Delivery",
      text: `${i18n.t("option.dateSourceType.Delivery")}`,
    },
  ];
}

export function getByValue(value) {
  const type = getAll().find(function (item) {
    return item.value == value;
  });
  return type;
}
