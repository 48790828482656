<template>
  <div>
    <condition-part
      :hasDate="true"
      :hasStore="true"
      :hasCustomer="true"
      :hasFoodKind="true"
      :hasFood="true"
      :hasExcel="true"
      :hasPdf="true"
      :hasAdd="false"
      :hasEdit="false"
      :hasRemove="false"
      :columns="columns"
      :items="items"
      :pagination="pagination"
      :isSortable="true"
      @getItems="getItems"
      @downloadExcel="downloadExcel"
      @downloadPdf="downloadPdf"
      @printPdf="printPdf"
    >
    </condition-part>
  </div>
</template>

<script>
import conditionPart from "@/components/parts/conditionPart";

export default {
  data() {
    return {
      items: [],
      pagination: {},
    };
  },
  computed: {
    columns() {
      return [
        { name: "TradeNumber" },
        { name: "TradeType" },
        { name: "TradeTime" },
        { name: "CustomerNumber" },
        { name: "CustomerName" },
        { name: "EmployeeNumber" },
        { name: "EmployeeName" },
        { name: "FoodNumber" },
        { name: "FoodName" },
        { name: "Qty" },
        { name: "Memo" },
      ];
    },
  },
  components: {
    conditionPart,
  },
  methods: {
    downloadExcel(condition) {
      this.$api.reportExcels.getCustomerStockMovement(condition);
    },
    downloadPdf(condition) {
      this.$api.reportPdfs.getCustomerStockMovement(condition);
    },
    printPdf(condition) {
      this.$api.reportPdfs.printCustomerStockMovement(condition);
    },
    getItems(condition) {
      const vm = this;
      this.$api.reports.getCustomerStockMovement(condition).then((data) => {
        vm.items = data.Datas;
        vm.pagination = data.Pagination;
      });
    },
  },
};
</script>
