import { httpGet, httpPost, httpDelete } from "@/request/http";

let path = "/foodcombos";

export default {
  getFoods(comboKindId, foodKindId) {
    return httpGet(`combokinds/${comboKindId}/foodkinds/${foodKindId}/foods`);
  },
  getFoodsBySelect(comboKindId) {
    return httpGet(`combokinds/${comboKindId}/foods`);
  },
  postFoods(dto) {
    return httpPost(`${path}/addbatch`, dto);
  },
  removeFoods(dto) {
    return httpPost(`${path}/deletebatch`, dto);
  },
};
