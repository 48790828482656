import { httpGet, httpPost, httpPut, httpPatch } from "@/request/http";

let path = "/digiwin";

export default {
  postConnectionTest(dTO) {
    return httpPost(`${path}/connectionTest`, dTO);
  },
  postDigiwin() {
    return httpPost(`${path}/warehouses/sync`);
  },
};
