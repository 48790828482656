export function getAll() {
    return [
        {
            value: 0,
            name: 'Sal',
            text: '銷貨單(前台)',
        },
        {
            value: 1,
            name: 'SalReturn',
            text: '銷退單(前台)',
        },
        {
            value: 2,
            name: 'Purchase',
            text: '進貨單',
        },
        {
            value: 3,
            name: 'StockAdjust',
            text: '調整單',
        },
        {
            value: 4,
            name: 'PurchaseReturn',
            text: '進退單',
        },
        {
            value: 5,
            name: 'BackSal',
            text: '銷貨單(後台)',
        },
        {
            value: 6,
            name: 'BackSalReturn',
            text: '銷退單(後台)',
        },
        {
            value: 7,
            name: 'PurchaseOrder',
            text: '採購單',
        },
    ]
}

export function get(val) {
    const type = getAll().find(function(item) {
        return item.value == val
    })
    return type
}

export function getByName(name) {
    const type = getAll().find(function(item) {
        return item.name.toLowerCase() == name.toLowerCase()
    })
    return type
}
