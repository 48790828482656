import { httpGet, httpPost, httpDelete } from "@/request/http";

let path = "/employees";

export default {
  get(id) {
    return httpGet(`${path}/${id}`);
  },
  getByNumber(number) {
    return httpGet(`${path}/number/${number}`);
  },
  getName(id) {
    return httpGet(`${path}/${id}/name`);
  },
  getNames() {
    return httpGet(`${path}/names`);
  },
  getPages(dTO) {
    return httpGet(`${path}/pages`, dTO);
  },
  save(item) {
    return httpPost(`${path}`, item);
  },
  remove(id) {
    return httpDelete(`${path}/${id}`);
  },
  getAuthoritys() {
    return httpGet(`${path}/authoritys`);
  },
  getStoreId() {
    return httpGet(`${path}/storeid`);
  },
};
