export function getAll() {
  return [
    {
      value: 0,
      name: "TotalPriceForFullQty",
      text: "滿數量改變總價"
    },
    {
      value: 1,
      name: "FreeQtyForFullQty",
      text: "滿數量贈送"
    },
    {
      value: 2,
      name: "TotalPiceForMatch",
      text: "紅配綠"
    },
    {
      value: 3,
      name: "DiscountForFullQty",
      text: "滿數量打折"
    },
    {
      value: 4,
      name: "SalPriceForFullQty",
      text: "滿數量改變單價"
    },
    {
      value: 5,
      name: "DiscountSalPrice",
      text: "促銷單價"
    },
    {
      value: 6,
      name: "FreePriceForFullPrice",
      text: "滿額折價"
    },
    {
      value: 7,
      name: "DiscountForFullPrice",
      text: "滿額打折"
    },
    {
      value: 8,
      name: "DiscountForTheQty",
      text: "第N件打折"
    },
    {
      value: 9,
      name: "FreePriceForFullQty",
      text: "滿數量折價"
    },
    {
      value: 10,
      name: "DiscountForGreaterThanQty",
      text: "N件以上打折"
    }
  ];
}
export function getByName(name) {
  const type = getAll().find(function(item) {
    return item.name.toLowerCase() == name.toLowerCase();
  });
  return type;
}
export function getByValue(value) {
  const type = getAll().find(function(item) {
    return item.value == value;
  });
  return type;
}
