import i18n from "@/langs/i18n";

export function getAll() {
  return [
    {
      value: 0,
      name: "General",
      text: `${i18n.t("option.employeeType.General")}`,
    },
    {
      value: 1,
      name: "Saler",
      text: `${i18n.t("option.employeeType.Saler")}`,
    },
    // {
    //   value: 2,
    //   name: "Doctor",
    //   text: `${i18n.t("option.employeeType.Doctor")}`,
    // },
  ];
}
