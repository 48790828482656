import i18n from "@/langs/i18n";

export function getAll() {
  return [
    {
      value: 5,
      name: "5 minutes",
      text: `${i18n.t("option.waitingIntervalType.Waiting5Mins")}`,
    },
    {
      value: 10,
      name: "10 minutes",
      text: `${i18n.t("option.waitingIntervalType.Waiting10Mins")}`,
    },
    {
      value: 15,
      name: "15 minutes",
      text: `${i18n.t("option.waitingIntervalType.Waiting15Mins")}`,
    },
    {
      value: 20,
      name: "20 minutes",
      text: `${i18n.t("option.waitingIntervalType.Waiting20Mins")}`,
    },
    {
      value: 30,
      name: "30 minutes",
      text: `${i18n.t("option.waitingIntervalType.Waiting30Mins")}`,
    },
    {
      value: 60,
      name: "60 minutes",
      text: `${i18n.t("option.waitingIntervalType.Waiting60Mins")}`,
    },
    {
      value: 0,
      name: "Customize",
      text: `${i18n.t("option.waitingIntervalType.Customize")}`,
    },
  ];
}
