<template>
  <basic-selects
    :trigger="trigger"
    :isMultiple="isMultiple"
    :columns="columns"
    :items="directorys"
    :title="`${$t('title.selectdirectory')}`"
    idProperty="DirectoryId"
    nameProperty="DirectoryName"
    allSelectId="directoryAllSelectBox"
    @onSaved="onSaved"
    :id="id"
    :directoryKinds="directoryKinds"
    :directoryKind="directoryKind"
    :pagination="pagination"
    @getItems="getItems"
    sortBy="DirectoryName"
    :defaultItems="defaultItems"
    viewName="DirectorySelects"
  ></basic-selects>
</template>

<script>
import basicSelects from "./BasicSelects";

export default {
  data() {
    return {
      directoryKinds: [],
      directoryKind: "",
      directorys: [],
      columns: [{ name: "DirectoryName" }],
      id: "directorySelectModal",
      pagination: {},
    };
  },
  props: {
    trigger: false,
    isMultiple: false,
    modalId: undefined,
    defaultItems: undefined,
  },
  watch: {
    modalId: {
      handler(value) {
        if (value) this.id = value;
      },
      immediate: true,
    },
    trigger() {
      this.getItems();
    },
  },
  components: {
    basicSelects,
  },
  methods: {
    onSaved(item) {
      this.$emit("onSaved", item);
    },
    getItems(dTO) {
      const vm = this;
      if (dTO) {
        this.$api.directorys
          .getPages(dTO.directoryKindId, {
            page: dTO.page,
            pagesize: dTO.pagesize,
            sortName: dTO.sortName,
            isDesc: dTO.isDesc,
          })
          .then((data) => {
            vm.directorys = data.Datas;
            vm.pagination = data.Pagination;
            vm.directoryKind = dTO.directoryKindId;
          });
      } else {
        this.$api.directorys.getPages(this.directoryKind).then((data) => {
          vm.directorys = data.Datas;
          vm.pagination = data.Pagination;
        });
      }
    },
  },
  mounted() {
    const vm = this;
    this.$api.directoryKinds.getByType(10).then((data) => {
      data.forEach((item) => {
        vm.directoryKinds.push(item);
      });
    });
  },
};
</script>
