<template>
  <form class="card" @submit.prevent="onSaved">
    <div class="card-header">
      <button
        type="button"
        class="btn btn-outline-primary btn-icon"
        @click="onSaved"
      >
        <i class="fas fa-check"></i>
      </button>
      <button
        type="button"
        class="btn btn-outline-danger btn-icon"
        @click="onClosed"
      >
        <i class="fas fa-times"></i>
      </button>
      <button
        v-if="!isData"
        type="button"
        class="btn btn-outline-success btn-icon"
        @click="checkAll"
      >
        <i class="fa-solid fa-list-check"></i>
      </button>
      <import-part
        v-if="isData"
        @onImport="onImport"
        :title="`${$t('modal.importfood')}`"
      />
      <export-part v-if="isData" @onExport="onExport" />
    </div>
    <div class="card-body">
      <div class="row">
        <div class="form-group col-md-6 col-lg-4">
          <label for="stockTransferDate" class="col-form-label"
            >{{ $t("modal.stocktransferdate") }}:</label
          >
          <div v-if="isData">
            <input
              type="date"
              class="form-control"
              id="stockTransferDate"
              v-model="stockTransfer.StockTransferDate"
              required
            />
          </div>
          <label
            v-else
            class="col-12 px-0 pt-0 col-form-label on-check-style"
            >{{ stockTransfer.StockTransferDate }}</label
          >
        </div>
        <div class="form-group col-md-6 col-lg-4">
          <label for="stockTransferNumber" class="col-form-label"
            >{{ $t("modal.stocktransfernumber") }}:</label
          >
          <div v-if="isData">
            <input
              type="text"
              class="form-control"
              id="stockTransferNumber"
              v-model="stockTransfer.StockTransferNumber"
              readonly
            />
          </div>
          <label
            v-else
            class="col-12 px-0 pt-0 col-form-label on-check-style"
            >{{ stockTransfer.StockTransferNumber }}</label
          >
        </div>
        <div class="form-group col-md-6 col-lg-4">
          <label for="fromwarehouse" class="col-form-label"
            >{{ $t("modal.fromwarehouse") }}:</label
          >
          <div v-if="isData" class="row px-3">
            <input
              type="text"
              class="form-control col-12 col-md-6"
              id="fromwarehouse"
              :placeholder="`${$t('placeholder.inputwarehousenumberordb')}`"
              v-model="addFromWarehouseNumber"
              @keyup.enter.exact="selectWarehouseByKeyword('from')"
              @dblclick="fromWarehouseModalTrigger = !fromWarehouseModalTrigger"
            />
            <input
              type="text"
              class="form-control col-12 col-md-6"
              id="fromWarehouseName"
              readonly
              :value="stockTransfer.FromWarehouseName"
            />
          </div>
          <label
            v-else
            class="col-12 px-0 pt-0 col-form-label on-check-style"
            >{{ stockTransfer.FromWarehouseName }}</label
          >
        </div>
        <div class="form-group col-md-6 col-lg-4">
          <label for="towarehouse" class="col-form-label"
            >{{ $t("modal.towarehouse") }}:</label
          >
          <div v-if="isData" class="row px-3">
            <input
              type="text"
              class="form-control col-12 col-md-6"
              id="towarehouse"
              :placeholder="`${$t('placeholder.inputwarehousenumberordb')}`"
              v-model="addToWarehouseNumber"
              @keyup.enter.exact="selectWarehouseByKeyword('to')"
              @dblclick="toWarehouseModalTrigger = !toWarehouseModalTrigger"
            />
            <input
              type="text"
              class="form-control col-12 col-md-6"
              id="toWarehouseName"
              readonly
              :value="stockTransfer.ToWarehouseName"
            />
          </div>
          <label
            v-else
            class="col-12 px-0 pt-0 col-form-label on-check-style"
            >{{ stockTransfer.ToWarehouseName }}</label
          >
        </div>
        <div class="form-group col-md-6 col-lg-4">
          <label for="employeeName" class="col-form-label"
            >{{ $t("modal.writername") }}:</label
          >
          <div v-if="isData">
            <input
              type="text"
              class="form-control"
              id="employeeName"
              :value="stockTransfer.EmployeeName"
              readonly
            />
          </div>
          <label
            v-else
            class="col-12 px-0 pt-0 col-form-label on-check-style"
            >{{ stockTransfer.EmployeeName }}</label
          >
        </div>
        <div v-if="!isData" class="form-group col-md-6 col-lg-4">
          <label for="employeeName" class="col-form-label"
            >{{ $t("modal.checkername") }}:</label
          >
          <label class="col-12 px-0 pt-0 col-form-label on-check-style">{{
            employeeName
          }}</label>
        </div>

        <div class="form-group col-12">
          <label for="memo" class="col-form-label"
            >{{ $t("modal.memo") }}:</label
          >
          <div v-if="isData">
            <input
              type="text"
              class="form-control"
              id="memo"
              v-model="stockTransfer.Memo"
            />
          </div>
          <label
            v-else
            class="col-12 px-0 pt-0 col-form-label on-check-style"
            >{{ stockTransfer.Memo }}</label
          >
        </div>
      </div>
      <div class="col-12 pl-0">
        <table-part :columns="columns" :noHide="true">
          <template v-if="isData" slot="th-before">
            <th style="width: 80px">{{ $t("columnName.remove") }}</th>
            <th style="width: 80px">{{ $t("columnName.index") }}</th>
          </template>
          <tbody slot="tbody">
            <tr
              v-for="(det, index) in stockTransferDets"
              :key="det.StockTransferDetId"
            >
              <td v-if="isData">
                <button
                  type="button"
                  class="btn btn-outline-danger btn-icon"
                  @click="removeDet(index)"
                >
                  <i class="fas fa-trash-alt"></i>
                </button>
              </td>
              <td>{{ index + 1 }}</td>
              <td class="td-align">
                {{ det.FoodNumber }}
              </td>
              <td class="td-align">
                {{ det.FoodName }}
              </td>
              <td class="td-align">
                <input
                  v-if="isData"
                  class="form-control text-right"
                  type="number"
                  v-model.number="det.Qty"
                  required
                />
                <div class="text-right" v-else>
                  {{ det.Qty }}
                </div>
              </td>
              <td v-if="!isData" class="td-align">
                <input
                  class="form-control text-right"
                  type="number"
                  v-model.number="det.CheckQty"
                  @input="checkQty(det)"
                />
              </td>
              <td v-if="hasBatchNumber">
                <input
                  class="form-control"
                  type="text"
                  v-model="det.BatchNumber"
                  :readonly="!det.HasBatchNumber"
                  @dblclick="openBatchSelector(det, index)"
                />
              </td>
              <td class="td-align">
                <input
                  v-if="isData"
                  class="form-control"
                  type="text"
                  v-model="det.Memo"
                />
                <div v-else>{{ det.Memo }}</div>
              </td>
            </tr>
            <tr>
              <td v-if="isData" colspan="6">
                <input
                  class="form-control"
                  type="text"
                  :placeholder="`${$t('placeholder.inputfoodnumberorbarcode')}`"
                  v-model="addFoodNumber"
                  @keyup.enter.exact="selectFoodByKeyword"
                  @dblclick="foodModalTrigger = !foodModalTrigger"
                />
              </td>
            </tr>
            <tr>
              <td>{{ $t("columnName.total") }}:</td>
              <td v-if="isData"></td>
              <td></td>
              <td></td>
              <td class="text-right">{{ totalQty }}</td>
              <td v-if="!isData" class="text-right">{{ totalCheckQty }}</td>
              <td></td>
              <td></td>
            </tr>
          </tbody>
        </table-part>
      </div>
      <food-selects
        :trigger="foodModalTrigger"
        :isMultiple="true"
        @onSaved="addFoods"
        :parentItems="foodsByKeyword"
        :noSearchType="!hasFoodSearchType"
      />

      <warehouse-selects
        :trigger="fromWarehouseModalTrigger"
        :isMultiple="false"
        @onSaved="setFromWarehouse"
        modalId="fromWarehouse"
      ></warehouse-selects>

      <warehouse-selects
        :trigger="toWarehouseModalTrigger"
        :isMultiple="false"
        @onSaved="setToWarehouse"
        modalId="toWarehouse"
      ></warehouse-selects>

      <batch-selects
        :trigger="batchModalTrigger"
        :isMultiple="false"
        :warehouseId="currentWarehouseId"
        :foodId="currentFoodId"
        @onSaved="setBatchNumber"
      />
    </div>
  </form>
</template>

<script>
import inputPart from "@/components/parts/inputPart";

import importPart from "@/components/ImportPart";
import exportPart from "@/components/ExportPart";

import tablePart from "@/components/parts/tablePart";

import foodSelects from "@/components/selects/FoodSelects";
import warehouseSelects from "@/components/selects/WarehouseSelects";
import batchSelects from "@/components/selects/BatchSelects";

export default {
  data() {
    return {
      stockTransfer: {
        EmployeeId: window.localStorage["userId"],
        EmployeeName: window.localStorage["userName"],
        StockTransferDate: this.$func.getDate(),
        FromWarehouseName: "",
        ToWarehouseName: "",
      },
      stockTransferDets: [],
      employeeName: window.localStorage["userName"],
      addFoodNumber: "",
      addFromWarehouseNumber: "",
      addToWarehouseNumber: "",
      foodModalTrigger: false,
      fromWarehouseModalTrigger: false,
      toWarehouseModalTrigger: false,
      batchModalTrigger: false,
      foodsByKeyword: [],
      hasFoodSearchType: true,
      currentWarehouseId: "",
      currentFoodId: "",
      currentFoodIndex: null,
    };
  },
  components: {
    inputPart,
    foodSelects,
    warehouseSelects,
    batchSelects,
    importPart,
    exportPart,
    tablePart,
  },
  computed: {
    hasBatchNumber() {
      return window.localStorage["hasBatchNumber"] == "true";
    },
    totalQty() {
      if (this.stockTransferDets.length > 0)
        return this.stockTransferDets
          .map((item) => {
            return item.Qty;
          })
          .reduce((t, q) => {
            if (!t) t = 0;
            if (!q) q = 0;
            return parseInt(t) + parseInt(q);
          });
      else return 0;
    },
    totalCheckQty() {
      if (this.stockTransferDets.length > 0)
        return this.stockTransferDets
          .map((item) => {
            return item.CheckQty;
          })
          .reduce((t, q) => {
            if (!t) t = 0;
            if (!q) q = 0;
            return parseInt(t) + parseInt(q);
          });
      else return 0;
    },
    columns() {
      let cols = [
        { name: "FoodNumber" },
        { name: "FoodName" },
        { name: "Qty" },
        { name: "Memo" },
      ];
      if (!this.isData) {
        cols.splice(3, 0, { name: "CheckQty" });
      }
      if (this.hasBatchNumber) {
        cols.splice(-1, 0, { name: "BatchNumber" });
      }
      return cols;
    },
    isData() {
      return this.$route.name != "StockTransferCheck";
    },
  },
  methods: {
    selectWarehouseByKeyword(way) {
      let warehouseNumber = null;
      if (way == "from") {
        warehouseNumber = this.addFromWarehouseNumber;
      } else {
        warehouseNumber = this.addToWarehouseNumber;
      }
      if (!warehouseNumber || warehouseNumber.length == 0) {
        this.$func.message.alert("請輸入倉庫編號");
        return;
      }
      const vm = this;
      this.$api.warehouses
        .getByNumber(warehouseNumber)
        .then((data) => {
          if (data) {
            if (way == "from") {
              vm.setFromWarehouse(data);
            } else {
              vm.setToWarehouse(data);
            }
          }
        })
        .catch(() => {
          this.$func.message.error("查無此倉庫 !");
          if (way == "from") {
            vm.setFromWarehouse({
              WarehouseId: null,
              WarehouseNumber: null,
              WarehouseName: null,
            });
          } else {
            vm.setToWarehouse({
              WarehouseId: null,
              WarehouseNumber: null,
              WarehouseName: null,
            });
          }
        });
    },
    setBatchNumber(item) {
      this.stockTransferDets[this.currentFoodIndex].BatchNumber =
        item.BatchNumber;
    },
    openBatchSelector(det, index) {
      if (!det.HasBatchNumber) {
        return;
      }
      this.currentWarehouseId = this.stockTransfer.FromWarehouseId;
      this.currentFoodId = det.FoodId;
      this.currentFoodIndex = index;
      this.batchModalTrigger = !this.batchModalTrigger;
    },
    showFoodSelect() {
      this.foodsByKeyword = undefined;
      this.hasFoodSearchType = true;
      this.foodModalTrigger = !this.foodModalTrigger;
    },
    addDet(item) {
      this.addFoodNumber = "";
      this.stockTransferDets.push({
        FoodId: item.FoodId,
        FoodNumber: item.FoodNumber,
        FoodName: item.FoodName,
        HasBatchNumber: item.HasBatchNumber,
        BatchNumber: null,
      });
    },
    selectFoodByKeyword() {
      if (!this.addFoodNumber || this.addFoodNumber.length == 0) {
        this.$func.message.alert("請輸入商品編號或條碼");
        return;
      }
      const vm = this;
      this.$api.foods
        .getByKeyword({ IsMatterStock: true, Keyword: this.addFoodNumber })
        .then((data) => {
          if (data) {
            if (data.length > 1) {
              vm.foodsByKeyword = data;
              vm.hasFoodSearchType = false;
              vm.foodModalTrigger = !vm.foodModalTrigger;
            } else {
              vm.addDet(data[0]);
            }
          }
        })
        .catch(() => this.$func.message.error("查無此商品 !"));
    },
    removeDet(index) {
      this.stockTransferDets.splice(index, 1);
    },
    onSaved() {
      if (
        !this.stockTransfer.FromWarehouseId ||
        this.stockTransfer.FromWarehouseId.length == 0
      ) {
        this.$func.message.alert("請選擇調出倉庫 !");
        return;
      }
      if (
        !this.stockTransfer.ToWarehouseId ||
        this.stockTransfer.ToWarehouseId.length == 0
      ) {
        this.$func.message.alert("請選擇調入倉庫 !");
        return;
      }
      if (
        this.stockTransfer.FromWarehouseId == this.stockTransfer.ToWarehouseId
      ) {
        this.$func.message.alert("調出調入倉庫不可相同 !");
        return;
      }
      if (this.stockTransferDets.length == 0) {
        this.$func.message.alert("請選擇商品 !");
        return;
      }
      if (
        this.stockTransferDets.some((det) => !det.Qty) ||
        this.stockTransferDets.some((det) => det.Qty <= 0)
      ) {
        this.$func.message.alert("商品數量需大於0 !");
        return;
      }
      let emptyBatch = this.stockTransferDets.some((det) => {
        return det.HasBatchNumber && !det.BatchNumber;
      });
      if (emptyBatch) {
        this.$func.message.alert("請設定商品批號 !");
        return;
      }

      const vm = this;

      if (!this.isData) {
        this.$api.stockTransfers
          .check(vm.stockTransfer.StockTransferId, {
            CheckerId: window.localStorage["userId"],
            StockTransferDets: vm.stockTransferDets,
          })
          .then(() => {
            this.$store.dispatch("setSaveOrClose", true);
            vm.$router.push({ name: "StockTransfers" });
          })
          .catch((error) => {
            vm.$func.message.error(error.data.Message);
          });
        return;
      }

      this.stockTransfer.EmployeeId = window.localStorage["userId"];
      this.$api.stockTransfers
        .post({
          StockTransfer: vm.stockTransfer,
          StockTransferDets: vm.stockTransferDets,
        })
        .then((data) => {
          if (vm.$route.name == "StockTransferAdd") {
            vm.$func.message.success(
              `單號為${data.StockTransfer.StockTransferNumber}`,
              "此筆單為新單，庫存尚未異動，請注意 !"
            );
          }
          this.$store.dispatch("setSaveOrClose", true);
          this.$router.push({ name: "StockTransfers" });
        })
        .catch((error) => {
          vm.$func.message.error(error.data.Message);
        });
    },
    onClosed() {
      this.$store.dispatch("setSaveOrClose", true);
      this.$router.go(-1);
    },
    setFromWarehouse(item) {
      this.addFromWarehouseNumber = item.WarehouseNumber;
      this.stockTransfer.FromWarehouseId = item.WarehouseId;
      this.stockTransfer.FromWarehouseName = item.WarehouseName;
    },
    setToWarehouse(item) {
      this.addToWarehouseNumber = item.WarehouseNumber;
      this.stockTransfer.ToWarehouseId = item.WarehouseId;
      this.stockTransfer.ToWarehouseName = item.WarehouseName;
    },
    addFoods(items) {
      const vm = this;
      items.forEach(function (item) {
        vm.addDet(item);
      });
    },
    onExport() {
      this.$api.stockTransfers.getExcelByPost(this.stockTransferDets);
    },
    onImport(file) {
      let formData = new FormData();
      formData.append("file", file);
      const vm = this;
      this.$api.stockTransfers.postExcel(formData).then((data) => {
        if (data) {
          data.Items.forEach((item) => {
            vm.pushItem(item);
          });
        }
      });
    },
    pushItem(item) {
      let exist = this.stockTransferDets.find((i) => i.FoodId == item.FoodId);
      if (exist) {
        const index = this.stockTransferDets.indexOf(exist);
        if (index > -1) {
          this.stockTransferDets.splice(index, 1);
        }
      }
      this.stockTransferDets.push(item);
    },
    checkQty(item) {
      if (item.CheckQty > item.Qty) {
        item.CheckQty = item.Qty;
      }
    },
    checkAll() {
      this.stockTransferDets.forEach((det) => {
        det.CheckQty = det.Qty;
      });
    },
  },
  beforeRouteLeave(to, from, next) {
    const isSaveOrClose = this.$store.state.isSaveOrClose;
    if (isSaveOrClose) {
      next();
    } else {
      const result = confirm("資料尚未儲存，確定離開當前頁面嗎 ?");
      if (result) {
        next();
      } else {
        next(false);
      }
    }
  },
  mounted() {
    const id = this.$route.params["id"];
    const vm = this;
    if (id) {
      this.$api.stockTransfers
        .get(id)
        .then((data) => {
          vm.stockTransfer = data.StockTransfer;
          vm.stockTransferDets = data.StockTransferDets;
          vm.warehouseName = data.WarehouseName;
        })
        .catch(() => {
          vm.$func.message.alert("查無此筆單據 !");
          vm.$router.go(-1);
        });
    }
  },
};
</script>
