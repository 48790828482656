import { httpGet, httpPost, httpDelete } from '@/request/http';

let path = '/stores';

export default{
    get(id){
      return httpGet(`${path}/${id}`);
    },
    getPages(dTO){
      return httpGet(`${path}/pages` , dTO);
    },
    getName(id){
      return httpGet(`${path}/${id}/name`);
    },
    getNames(){
      return httpGet(`${path}/names`);
    },
    save(item){
      return httpPost(path, item);
    },
    remove(id){
      return httpDelete(`${path}/${id}`);
    }
}
