<template>
  <div>
    <condition-part
      :hasDate="true"
      :hasWarehouse="true"
      :items="stockTransfers"
      :pagination="pagination"
      :columns="columns"
      @getItems="getItems"
      @addItem="add"
      @editItem="edit"
      @removeItem="remove"
      :nameProperty="nameProperty"
      :getTrigger="getTrigger"
      :hasAdd="authority.IsAdd"
      :hasEdit="authority.IsUpdate"
      :hasRemove="authority.IsRemove"
      :isSortable="true"
    >
      <template slot="dataButtons" slot-scope="props">
        <button
          type="button"
          class="btn btn-outline-secondary btn-icon"
          @click="print(props.index)"
        >
          <i class="fas fa-print"></i>
        </button>
      </template>
      <template v-if="isCheck" slot="th-before">
        <th style="width: 200px">{{ $t("columnName.changestatus") }}</th>
        <th style="width: 100px">{{ $t("columnName.status") }}</th>
      </template>
      <template v-if="isCheck" slot="td-before" slot-scope="props">
        <td>
          <button
            class="btn btn-outline-primary font-weight-bold mr-1"
            v-if="props.item.TransferStatusType === 0"
            @click="start(props.item)"
          >
            {{ $t("button.start") }}
          </button>
          <button
            class="btn btn-outline on-check-btn font-weight-bold mr-1"
            v-if="
              props.item.TransferStatusType === 1 ||
              props.item.TransferStatusType === 2
            "
            @click="check(props.item)"
          >
            {{ $t("button.check") }}
          </button>
          <button
            class="btn btn-outline-success font-weight-bold"
            v-if="props.item.TransferStatusType !== 3"
            @click="done(props.item)"
          >
            {{ $t("button.done") }}
          </button>
        </td>
        <td>
          {{ props.item.TransferStatusTypeText }}
        </td>
      </template>
    </condition-part>
  </div>
</template>

<script>
import conditionPart from "@/components/parts/conditionPart";

export default {
  data() {
    return {
      stockTransfers: [],
      pagination: {},
      columns: [
        { name: "StockTransferNumber" },
        { name: "StockTransferDate" },
        { name: "EmployeeName", headerText: "填單人" },
        { name: "CheckerName" },
        { name: "FromWarehouseNumber" },
        { name: "FromWarehouseName" },
        { name: "ToWarehouseNumber" },
        { name: "ToWarehouseName" },
        { name: "Memo" },
        { name: "CreateTime" },
        { name: "UpdateTime" },
      ],
      nameProperty: "StockTransferNumber",
      getTrigger: false,
      currentIndex: -1,
      authority: {},
    };
  },
  components: {
    conditionPart,
  },
  computed: {
    isCheck() {
      return window.localStorage["erpType"] != 1;
    },
  },
  methods: {
    getItems(condition) {
      const vm = this;
      this.$api.stockTransfers.getPages(condition).then((data) => {
        vm.stockTransfers = data.Datas;
        vm.pagination = data.Pagination;
      });
    },
    add() {
      this.$router.push({ name: "StockTransferAdd" });
    },
    edit(item) {
      if (item.TransferStatusType != 0) {
        this.$func.message.alert("此狀態無法編輯 !");
        return;
      }
      this.$router.push({
        name: "StockTransferEdit",
        params: { id: item.StockTransferId },
      });
    },
    remove(item) {
      const vm = this;
      if (item.TransferStatusType != 0) {
        this.$func.message.alert("此狀態無法刪除 !");
        return;
      }
      this.$api.stockTransfers.delete(item.StockTransferId).then(() => {
        vm.getTrigger = !vm.getTrigger;
      });
    },
    print(index) {
      let stockTransfer = this.stockTransfers[index];
      if (!stockTransfer) {
        this.$func.message.alert("請選擇一筆調撥單 !");
      } else {
        this.$api.stockTransfers.print(stockTransfer.StockTransferId);
      }
    },
    start(item) {
      const vm = this;
      this.$func.message
        .confirm(
          "確定「開始」調撥嗎 ?",
          "商品將立即出庫，待您完成調撥單後才會入庫 !"
        )
        .then((result) => {
          if (result.isConfirmed) {
            vm.$api.stockTransfers
              .start(item.StockTransferId)
              .then(() => {
                vm.getTrigger = !vm.getTrigger;
              })
              .catch((error) => {
                vm.$func.message.error(error.data.Message);
              });
          }
        });
    },
    check(item) {
      this.$router.push({
        name: "StockTransferCheck",
        params: { id: item.StockTransferId },
      });
    },
    done(item) {
      const vm = this;
      this.$func.message
        .confirm("確定「完成」調撥嗎 ?", "商品將立即入庫 !")
        .then((result) => {
          if (result.isConfirmed) {
            vm.$api.stockTransfers
              .done(item.StockTransferId)
              .then(() => {
                vm.getTrigger = !vm.getTrigger;
              })
              .catch((error) => {
                vm.$func.message.error(error.data.message);
              });
          }
        });
    },
  },
  created() {
    this.authority = this.$func.localStorage.getAuthByName(this.$route.name);
  },
};
</script>
