<template>
  <div class="lineorder-page-container row p-0">
    <div class="lineorder-tabs col w-100">
      <div class="lineorder-tabs-container col-12 w-100">
        <div
          class="tab-container"
          :class="{ tabSelected: this.componentId == 'account' }"
          @click.prevent="accountClick"
        >
          <h5>LINE帳號設定</h5>
        </div>
        <div
          class="tab-container"
          :class="{ tabSelected: this.componentId == 'imageMenu' }"
          @click.prevent="imageMenuClick"
        >
          <h5>圖文選單</h5>
        </div>
      </div>
    </div>
    <div class="lineorder-table-container col w-100">
      <div class="lineorder-table row">
        <div class="lineorder-table-title col-12">
          <h4 class="d-inline-block align-middle" v-if="title">
            {{ title }}
          </h4>
        </div>
        <component
          class="col-12"
          :is="componentId"
          v-if="componentId"
          :componentId="componentId"
        ></component>
      </div>
    </div>
  </div>
</template>
<script>
import account from "@/components/onlineOrders/LineAccount";
import imageMenu from "@/components/onlineOrders/LineImageMenu";

export default {
  data() {
    return {
      componentId: "",
      title: "",
    };
  },
  components: {
    account,
    imageMenu,
  },
  methods: {
    accountClick() {
      this.componentId = "account";
      this.title = "LINE帳號設定";
    },
    imageMenuClick() {
      this.componentId = "imageMenu";
      this.title = "圖文選單";
    },
  },
  mounted() {
    this.accountClick();
  },
};
</script>
