<template>
  <div>
    <condition-part
      :hasDate="true"
      :hasSupplier="true"
      :hasExcel="true"
      :hasPdf="true"
      :hasAdd="true"
      :hasEdit="true"
      :hasRemove="true"
      @getItems="getItems"
      @downloadExcel="downloadExcel"
      @downloadPdf="downloadPdf"
      @printPdf="printPdf"
      :items="payments"
      :pagination="pagination"
      :columns="columns"
      @addItem="add"
      @editItem="edit"
      @removeItem="remove"
      :nameProperty="nameProperty"
      :getTrigger="getTrigger"
    />
  </div>
</template>

<script>
import conditionPart from "@/components/parts/conditionPart";

export default {
  data() {
    return {
      payments: [],
      pagination: {},
      columns: [
        {
          name: "PaymentNumber",
        },
        {
          name: "PaymentDate",
        },
        {
          name: "SupplierNumber",
        },
        {
          name: "SupplierName",
        },
        {
          name: "Total",
        },
        {
          name: "Memo",
        },
      ],
      nameProperty: "PaymentNumber",
      getTrigger: false,
    };
  },
  components: {
    conditionPart,
  },
  methods: {
    getItems(condition) {
      const vm = this;
      this.$api.payments.getPages(condition).then((data) => {
        vm.payments = data.Datas;
        vm.pagination = data.Pagination;
      });
    },
    downloadExcel(condition) {
      this.$api.reportExcels.getPayment(condition);
    },
    downloadPdf(condition) {
      this.$api.reportPdfs.getPayment(condition);
    },
    printPdf(condition) {
      this.$api.reportPdfs.printPayment(condition);
    },
    add() {
      this.$router.push({ name: "PaymentAdd" });
    },
    edit(item) {
      this.$router.push({
        name: "PaymentEdit",
        params: { id: item.PaymentId },
      });
    },
    remove(item) {
      const vm = this;
      this.$api.payments.delete(item.PaymentId).then(() => {
        vm.getTrigger = !vm.getTrigger;
      });
    },
  },
};
</script>
