<template>
  <div :id="id" class="modal fade" role="dialog">
    <div
      class="modal-dialog modal-dialog-centered"
      :class="[isMultiple || isLg ? 'modal-lg' : '']"
      role="document"
    >
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">{{ title }}</h5>
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body row">
          <div :class="[isMultiple ? 'col-12 col-md-8' : 'col-12']">
            <data-body-part
              :columns="columns"
              :items="items"
              :modalId="modalId"
              :searchTypes="searchTypes"
              :directoryKinds="directoryKinds"
              :qtyTypes="qtyTypes"
              :currentDirectoryKind="directoryKind"
              :parentPage="pagination"
              @getItems="getItems"
              :noInitialLoading="true"
              @click="multipleSelectClick"
              :viewName="viewName"
              initialWidth="100"
            >
              <th slot="th-before" style="width: 100px">
                <div
                  v-if="isMultiple"
                  class="custom-control custom-checkbox mr-sm-2"
                >
                  <input
                    type="checkbox"
                    class="custom-control-input"
                    :id="allSelectId"
                    v-model="isAllSelect"
                    @change="clickAllSelect"
                  />
                  <label class="custom-control-label" :for="allSelectId">{{
                    $t("columnName.select")
                  }}</label>
                </div>
                <span v-else>{{ $t("columnName.select") }}</span>
              </th>
              <td slot="td-before" slot-scope="props" v-if="isMultiple">
                <input
                  type="checkbox"
                  class="checkbox"
                  :value="props.item[idProperty]"
                  v-model="selectIds"
                />
              </td>
              <td
                slot="td-before"
                slot-scope="props"
                v-else
                @click="select = props.item"
              >
                <div class="custom-control custom-radio">
                  <input
                    class="custom-control-input"
                    type="radio"
                    :id="props.item[idProperty]"
                    :value="props.item"
                    v-model="select"
                  />
                  <label
                    class="custom-control-label"
                    :for="props.item[idProperty]"
                  ></label>
                </div>
              </td>
            </data-body-part>
          </div>
          <div class="col d-flex flex-column" v-if="isMultiple && hasSequence">
            <div class="d-flex justify-content-center align-items-center">
              <button
                type="button"
                class="btn btn-primary mx-1 py-2 px-4"
                style="border-color: transparent"
                @click="onBefore"
              >
                <i class="fa fa-arrow-up"></i>
              </button>
              <button
                type="button"
                class="btn btn-primary mx-1 py-2 px-4"
                style="border-color: transparent"
                @click="onAfter"
              >
                <i class="fa fa-arrow-down"></i>
              </button>
            </div>
            <div
              class="d-flex flex-column justify-content-start align-items-start"
            >
              <div
                class="d-flex justify-content-start align-items-center"
                v-for="(item, index) in selects"
                :key="item[idProperty]"
              >
                <input
                  class="checkbox"
                  type="checkbox"
                  :value="item[idProperty]"
                  v-model="sequenceIds"
                />
                <select2-part
                  :index="index"
                  :name="item[nameProperty]"
                  @onClicked="remove"
                  class="ml-2 my-1"
                />
              </div>
            </div>
          </div>
          <div class="col" v-else-if="isMultiple">
            <select2-part
              v-for="(item, index) in selects"
              :key="item[idProperty]"
              :index="index"
              :name="item[nameProperty]"
              @onClicked="remove"
              class="my-1"
            />
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-primary" @click="onSaved">
            {{ $t("button.ok") }}
          </button>
          <button type="button" class="btn btn-secondary" @click="onClosed">
            {{ $t("button.cancel") }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import dataBodyPart from "@/components/parts/dataBodyPart";
import select2Part from "@/components/parts/select2Part";

import $ from "jquery";

export default {
  data() {
    return {
      selects: [],
      select: {},
      isAllSelect: false,
      sequenceIds: [],
    };
  },
  props: {
    trigger: false,
    isMultiple: false,
    columns: undefined,
    items: undefined,
    title: undefined,
    idProperty: undefined,
    nameProperty: undefined,
    allSelectId: undefined,
    id: undefined,
    searchTypes: undefined,
    directoryKinds: undefined,
    directoryKind: undefined,
    pagination: undefined,
    sortBy: undefined,
    defaultItems: undefined,
    viewName: undefined,
    modalId: undefined,
    qtyTypes: undefined,
    hasSequence: false,
  },
  computed: {
    selectIds() {
      return this.selects.map((x) => x[this.idProperty]);
    },
    isLg() {
      return this.viewName == "CustomerSelects";
    },
  },
  watch: {
    trigger() {
      if (this.defaultItems) {
        this.selects = [...this.defaultItems];
      } else {
        this.selects = [];
      }
      $(`#${this.id}`).modal("toggle");
      this.isAllSelect = false;
      this.sequenceIds = [];
    },
    selects(value) {
      this.checkIsAllSelect();
    },
    items() {
      this.checkIsAllSelect();
    },
  },
  components: {
    dataBodyPart,
    select2Part,
  },
  methods: {
    onBefore() {
      if (this.sequenceIds.length == 0) {
        this.$func.message.alert("請選擇欄位 !");
        return;
      }
      let indexes = [];
      this.sequenceIds.forEach((id) => {
        let index = this.selects.findIndex((det) => det[this.idProperty] == id);
        if (index >= 0) indexes.push(index);
      });
      indexes = indexes.sort();
      this.selects.splice(0, 0, { [this.idProperty]: "empty" });

      indexes.forEach((index) => {
        const det = this.selects[index + 1];
        this.selects.splice(index + 1, 1);
        this.selects.splice(index, 0, det);
      });

      const emptyIndex = this.selects.findIndex(
        (d) => d[this.idProperty] == "empty"
      );
      this.selects.splice(emptyIndex, 1);
    },
    onAfter() {
      if (this.sequenceIds.length == 0) {
        this.$func.message.alert("請選擇欄位 !");
        return;
      }
      let indexes = [];
      this.sequenceIds.forEach((id) => {
        let index = this.selects.findIndex((det) => det[this.idProperty] == id);
        if (index >= 0) indexes.push(index);
      });
      indexes = indexes.sort().reverse();
      this.selects.push({
        [this.idProperty]: "empty",
      });

      indexes.forEach((index) => {
        const det = this.selects[index];
        this.selects.splice(index, 1);
        this.selects.splice(index + 1, 0, det);
      });

      const emptyIndex = this.selects.findIndex(
        (d) => d[this.idProperty] == "empty"
      );
      this.selects.splice(emptyIndex, 1);
    },
    checkIsAllSelect() {
      const vm = this;
      const currentPageSelects = this.selects.filter((select) => {
        const findResult = vm.items.some((item) => {
          return select[vm.idProperty] === item[vm.idProperty];
        });
        return findResult;
      });
      if (currentPageSelects.length === this.items.length) {
        this.isAllSelect = true;
      } else {
        this.isAllSelect = false;
      }
    },
    clickAllSelect() {
      const vm = this;
      if (this.isAllSelect) {
        this.items.forEach((item) => {
          const checkExist = vm.selects.every((select) => {
            return item[vm.idProperty] !== select[vm.idProperty];
          });
          if (checkExist) {
            vm.selects.push(item);
          }
        });
      } else {
        this.items.forEach((item) => {
          const index = vm.selects.findIndex((select) => {
            return select[vm.idProperty] === item[vm.idProperty];
          });
          vm.selects.splice(index, 1);
        });
      }
    },
    remove(index) {
      this.selects.splice(index, 1);
    },
    multipleSelectClick(item) {
      if (this.isMultiple) {
        const index = this.selects.findIndex(
          (x) => x[this.idProperty] == item[this.idProperty]
        );
        if (index >= 0) this.selects.splice(index, 1);
        else this.selects.push(item);
      } else {
        this.select = item;
      }
    },
    getItems(dTO) {
      this.$emit("getItems", dTO);
    },
    onSaved() {
      if (this.isMultiple) {
        if (this.selects.length == 0) {
          this.$func.message.alert("請選擇資料 !");
          return;
        }
        if (!this.hasSequence && this.sortBy) {
          const vm = this;
          this.selects.sort((a, b) => {
            if (a[vm.sortBy] < b[vm.sortBy]) {
              return -1;
            } else if (a[vm.sortBy] > b[vm.sortBy]) {
              return 1;
            } else {
              return 0;
            }
          });
        }
        this.$emit("onSaved", this.selects);
      } else {
        if (Object.keys(this.select).length == 0) {
          this.$func.message.alert("請選擇資料 !");
          return;
        }
        this.$emit("onSaved", this.select);
      }
      this.onClosed();
    },
    onClosed() {
      if (this.id == "batchSelectModal") this.select = {};
      $(`#${this.id}`).modal("toggle");
      this.$emit("onClosed");
    },
  },
};
</script>
