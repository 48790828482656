<template>
  <div>
    <search-part
      :hasExcel="hasExcel"
      :hasPdf="hasPdf"
      :hasDate="hasDate"
      :hasStore="hasStore"
      :hasEmployee="hasEmployee"
      :hasSupplier="hasSupplier"
      :hasCustomer="hasCustomer"
      :hasWarehouse="hasWarehouse"
      :hasFoodKind="hasFoodKind"
      :hasEatType="hasEatType"
      :hasTimeType="hasTimeType"
      @getItems="searchClick"
      @downloadExcel="downloadExcel"
      @downloadPdf="downloadPdf"
      @printPdf="printPdf"
    >
      <template slot="buttonArea">
        <slot name="searchButtonArea" />
      </template>
      <template slot="searchArea">
        <slot name="searchArea" />
      </template>
    </search-part>

    <slot name="middleArea"></slot>

    <div :class="[isMultiple ? 'row' : '']">
      <data-table-part
        v-if="columns"
        v-show="!noPage"
        :hasAdd="hasAdd"
        :hasEdit="hasEdit"
        :hasRemove="hasRemove"
        :items="items"
        :pagination="pagination"
        :columns="columns"
        @getItems="pageClick"
        @addItem="addItem"
        @editItem="editItem"
        @removeItem="removeItem"
        :nameProperty="nameProperty"
        :getTrigger="getTrigger"
        @trdbClicked="trdbClicked"
        :isSortable="isSortable"
        @currentIndexChanged="currentIndexChanged"
        :isMultiple="isMultiple"
      >
        <template slot="dataButtonAreaFront" slot-scope="props">
          <slot name="dataButtonAreaFront" :index="props.index"></slot>
        </template>
        <template slot="dataButtonArea" slot-scope="props">
          <slot name="dataButtonArea" :index="props.index"></slot>
        </template>
        <template slot="th-before">
          <slot name="th-before" />
        </template>
        <template slot="th-after">
          <slot name="th-after" />
        </template>
        <template slot="td-before" slot-scope="props">
          <slot name="td-before" :item="props.item" />
        </template>
        <template slot="td-after" slot-scope="props">
          <slot name="td-after" :item="props.item" />
        </template>
      </data-table-part>

      <slot name="secondTablePart"></slot>
    </div>
  </div>
</template>

<script>
import searchPart from "./searchPart";
import dataTablePart from "./dataTablePart";

export default {
  data() {
    return {
      searchDTO: {},
      pageDTO: {},
    };
  },
  props: [
    "hasExcel",
    "hasPdf",
    "hasAdd",
    "hasEdit",
    "hasRemove",
    "hasDate",
    "hasStore",
    "hasEmployee",
    "hasSupplier",
    "hasCustomer",
    "hasWarehouse",
    "hasFoodKind",
    "hasFood",
    "hasEatType",
    "hasTimeType",
    "columns",
    "items",
    "pagination",
    "nameProperty",
    "getTrigger",
    "noPage",
    "isSortable",
    "isMultiple",
  ],
  components: {
    searchPart,
    dataTablePart,
  },
  computed: {
    conditionDTO() {
      return { ...this.searchDTO, ...this.pageDTO };
    },
  },
  methods: {
    searchClick(dTO) {
      this.pageDTO.page = 1;
      this.searchDTO = dTO;
      this.getItems();
    },
    pageClick(dTO) {
      this.pageDTO = dTO;
      this.getItems();
    },
    getItems() {
      this.$emit("getItems", this.conditionDTO);
    },
    downloadExcel() {
      this.$emit("downloadExcel", this.condition);
    },
    downloadPdf() {
      this.$emit("downloadPdf", this.condition);
    },
    printPdf() {
      this.$emit("printPdf", this.condition);
    },
    dateChanged(begin, end) {
      this.condition.DayBegin = begin;
      this.condition.DayEnd = end;
    },
    storeChanged(ids) {
      this.condition.StoreIds = ids;
    },
    employeeChanged(ids) {
      this.condition.EmployeeIds = ids;
    },
    supplierChanged(ids) {
      this.condition.SupplierIds = ids;
    },
    customerChanged(ids) {
      this.condition.CustomerIds = ids;
    },
    warehouseChanged(ids) {
      this.condition.WarehouseIds = ids;
    },
    foodKindChanged(ids) {
      this.condition.FoodKindIds = ids;
    },
    addItem() {
      this.$emit("addItem");
    },
    editItem(item) {
      this.$emit("editItem", item);
    },
    removeItem(item) {
      this.$emit("removeItem", item);
    },
    trdbClicked(item) {
      this.$emit("trdbClicked", item);
    },
    currentIndexChanged(index) {
      this.$emit("currentIndexChanged", index);
    },
  },
};
</script>
