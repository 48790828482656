import { getByName } from "@/enums/taxType";
import date from "@/filters/date";
import func from "@/funcs";

export function getFoodKind() {
  return {
    FontSize: 16,
    ForeColor: "#FFFFFF",
    BackColor: "#6297FF",
  };
}

export function getFood() {
  return {
    FontSize: 16,
    ForeColor: "#FFFFFF",
    BackColor: "#F29A2B",
    TaxType: getByName("YTax").value,
  };
}

export function getPay() {
  return {
    FontSize: 16,
    ForeColor: "#FFFFFF",
    BackColor: "#16418F",
    Price: 0,
    AccountId: null,
  };
}

export function getPage() {
  return {
    CurrentPage: 1,
    PageNumber: 0,
    PageSize: 25,
    HasPrevious: false,
    HasNext: false,
  };
}

export function getDayModule() {
  return {
    DayBegin: null,
    DayEnd: null,
    Sunday: true,
    Monday: true,
    Tuesday: true,
    Wednesday: true,
    Thursday: true,
    Friday: true,
    Saturday: true,
    HourBegin: 0,
    HourEnd: 0,
    MinuteBegin: 0,
    MinuteEnd: 0,
  };
}

export function getPurchase() {
  return {
    PurchaseDate: func.getDate(),
    WarehouseName: null,
    BuyerName: null,
    SupplierName: null,
    TaxType: 1,
    IsTaxAddition: false,
    InvoiceNumber: "",
  };
}

export function getSal() {
  return {
    BusinessDay: func.getDate(),
    WarehouseName: null,
    CustomerName: null,
    SalerName: null,
    TaxType: 1,
    IsTaxAddition: false,
    Address: "",
  };
}

export function getCombinationTrade(tradeType) {
  return {
    CombinationTradeDate: func.getDate(),
    FoodName: null,
    WarehouseName: null,
    TradeType: tradeType,
    Qty: null,
    Total: 0,
    Memo: null,
    HasBatchNumber: false,
    BatchNumber: null,
  };
}

export function getOnlineOrderMenu(type) {
  return {
    OnlineOrderType: type,
  };
}

export function getOnlineOrderCategory(type) {
  return {
    OnlineOrderType: type,
  };
}

export function getOnlineOrderModifierGroup(type) {
  return {
    OnlineOrderType: type,
  };
}

export function getOnlineOrderItem(type) {
  return {
    OnlineOrderType: type,
  };
}
