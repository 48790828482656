export function getAll() {
  return [
    {
      value: 0,
      name: "None",
      text: "無",
    },
    {
      value: 1,
      name: "High",
      text: "無上限",
    },
    {
      value: 2,
      name: "Medium",
      text: "500張",
    },
    {
      value: 3,
      name: "Low",
      text: "250張",
    },
  ];
}

export function getByValue(value) {
  const type = getAll().find(function (item) {
    return item.value == value;
  });
  return type;
}
