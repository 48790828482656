import { httpGet, httpPost, httpDelete } from "@/request/http";

export default {
  get() {
    return new Promise((resolve, reject) => {
      httpGet(`/values`, { timeout: 10000 })
        .then(data => resolve(data))
        .catch(response => {
          reject(response.data);
        });
    });
  },
  post() {
    return new Promise((resolve, reject) => {
      httpPost(`/values`, undefined, { timeout: 20000 })
        .then(data => resolve(data))
        .catch(response => {
          reject(response.data);
        });
    });
  }
};
