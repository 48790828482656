<template>
  <div>
    <pageTable
      :parentPage="pagination"
      @getItems="getItems"
      @editItem="editItem"
      :items="items"
      :columns="columns"
      :idProperty="idProperty"
      :nameProperty="nameProperty"
      :getTrigger="getTrigger"
      :hasAdd="false"
      :hasEdit="true"
      :hasRemove="false"
      @currentIndexChanged="currentIndexChanged"
      :tableName="componentId"
    >
      <template slot="button" slot-scope="props">
        <button
          type="button"
          class="btn btn-outline-info py-2 px-4"
          style="border-color: transparent"
          @click="uberEatsMenuSetting(props.item)"
        >
          <i class="fas fa-clipboard-list"></i>
        </button>
        <button
          type="button"
          class="btn btn-outline-primary py-2 px-4"
          style="border-color: transparent"
          @click="uploadUberEatsMenu(props.item)"
        >
          <i class="fas fa-cloud-upload-alt"></i>
        </button>
        <!-- <button
          type="button"
          class="btn btn-outline-primary py-2 px-4"
          style="border-color: transparent"
          @click="downloadUberEatsMenu(props.item)"
        >
          <i class="fas fa-cloud-download-alt"></i>
        </button> -->
      </template>
    </pageTable>

    <item-data
      :modalTitle="modalTitle"
      :isShow="isShowModal"
      :isEdit="isEdit"
      :entity="item"
      :id="terminalId"
      @save="saveItem"
      @close="onClosed"
    ></item-data>

    <menu-select
      :defaultItems="currentMenus"
      :trigger="menuModalTrigger"
      :isMultiple="true"
      @onSaved="addMenus"
    ></menu-select>
  </div>
</template>

<script>
import pageTable from "@/components/PageTable";
import itemData from "@/components/onlineOrders/OnlineOrderDeviceData";
import menuSelect from "@/components/selects/MenuSelects";

export default {
  data() {
    return {
      items: [],
      item: {},
      pagination: {},
      isShowModal: false,
      isEdit: false,
      columns: [
        {
          name: "TerminalName",
          headerText: "終端機名稱",
        },
        {
          name: "OnlineOrderMenuText",
        },
      ],
      idProperty: "OnlineOrderDeviceId",
      nameProperty: "OnlineOrderDeviceName",
      getTrigger: false,
      authority: {},
      currentIndex: -1,
      modalTitle: "",
      terminalId: "",
      currentMenus: [],
      menuModalTrigger: false,
    };
  },
  props: ["componentId", "onlineOrderType"],
  components: {
    pageTable,
    itemData,
    menuSelect,
  },
  methods: {
    addMenus(item) {
      let ids = [];
      item.forEach((i) => {
        ids.push(i.OnlineOrderMenuId);
      });
      this.$api.uberEats
        .postMenus(this.terminalId, { OnlineOrderMenuIds: ids })
        .then(() => {
          this.$func.message.success("菜單加入成功 !");
        })
        .catch((error) => this.$func.message.error(error.data.Message));
    },
    uberEatsMenuSetting(item) {
      this.terminalId = item.TerminalId;
      this.currentMenus = item.OnlineOrderMenus;
      this.menuModalTrigger = !this.menuModalTrigger;
    },
    uploadUberEatsMenu(item) {
      if (this.currentIndex < 0) {
        this.$func.message.alert("請選擇一筆終端機 !");
        return;
      }
      this.$api.uberEats
        .uploadMenu(item.TerminalId)
        .then(() => {
          this.$func.message.success("上傳成功 !");
        })
        .catch((error) => this.$func.message.error(error.data.Message));
    },
    downloadUberEatsMenu(item) {
      if (this.currentIndex < 0) {
        this.$func.message.alert("請選擇一筆終端機 !");
        return;
      }
      this.$api.uberEats
        .downloadMenu(item.TerminalId)
        .then(() => {
          this.$func.message.success("下載成功 !");
        })
        .catch((error) => this.$func.message.error(error.data.Message));
    },
    currentIndexChanged(index) {
      this.currentIndex = index;
    },
    getItems() {
      const vm = this;
      this.$api.uberEats.getUberEatsDevices().then((data) => {
        vm.items = data;
      });
    },
    editItem(item) {
      const vm = this;
      this.$api.uberEats.getUberEatsDevice(item.TerminalId).then((data) => {
        vm.terminalId = item.TerminalId;
        vm.item = data;
        vm.isEdit = true;
        vm.isShowModal = !vm.isShowModal;
        vm.modalTitle = "終端機設定";
      });
    },
    saveItem() {
      this.getTrigger = !this.getTrigger;
      this.onClosed();
    },
    onClosed() {
      this.isShowModal = !this.isShowModal;
    },
  },
  created() {
    this.authority = this.$func.localStorage.getAuthByName(this.$route.name);
  },
};
</script>
