import { httpGet, httpPost, httpDelete } from "@/request/http";
import func from "@/funcs";

let path = "/customers";

export default {
  get(id) {
    return httpGet(`${path}/${id}`);
  },
  getByNumber(number) {
    return httpGet(`${path}/number/${number}`);
  },
  getName(id) {
    return httpGet(`${path}/${id}/name`);
  },
  getNames() {
    return httpGet(`${path}/names`);
  },
  getPages(dTO) {
    return httpGet(`${path}/pages`, dTO);
  },
  save(item) {
    return httpPost(path, item);
  },
  remove(id) {
    return httpDelete(`${path}/${id}`);
  },
  getNextNumber(number) {
    return httpGet(`${path}/number/${number}/nextnumber`);
  },
  getExcel() {
    func.getExcel.get(`${path}/excel`, "會員");
  },
  postExcel(formData) {
    return func.postExcel(`${path}/excel`, formData, 180000);
  },
  getVisions(id) {
    return httpGet(`${path}/${id}/visions`);
  },
  getVision(id) {
    return httpGet(`/customervisions/${id}`);
  },
  saveVision(item) {
    return httpPost("/customervisions", item);
  },
  removeVision(id) {
    return httpDelete(`/customervisions/${id}`);
  },
  getDepositSample() {
    func.getExcel.get(`${path}/deposit/reupload/sample`, "儲值金範例");
  },
  getIntegralSample() {
    func.getExcel.get(`${path}/integral/reupload/sample`, "積分範例");
  },
};
