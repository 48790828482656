export function getAll() {
  return [
    {
      value: 0,
      name: 'Business',
      text: '營業人',
    },
    {
      value: 1,
      name: 'Saler',
      text: '業務',
    },
    {
      value: 2,
      name: 'Engineer',
      text: '工程師',
    },
    {
      value: 3,
      name: 'Accountant',
      text: '會計師',
    },
    {
      value: 5,
      name: 'Assistant',
      text: '業助',
    },
    {
      value: 6,
      name: 'Admin',
      text: '管理員',
    },
  ]
}

export function isSaler(type) {
  if (!type) type = window.localStorage['roleType']
  return type == 1
}

export function isEngineer(type) {
  if (!type) type = window.localStorage['roleType']
  return type == 2 || type == 6
}

export function isAccountant(type) {
  if (!type) type = window.localStorage['roleType']
  return type == 3
}

export function isAssistant(type) {
  if (!type) type = window.localStorage['roleType']
  return type == 5
}

export function isAdmin(type) {
  if (!type) type = window.localStorage['roleType']
  return type == 6
}
