<template>
  <div class="row">
    <div class="col-md-6">
      <h5>消費者會員網站</h5>
      <div class="card card-table">
        <div v-if="isEdit" class="card-header">
          <button
            type="button"
            class="btn btn-outline-primary btn-icon"
            @click="onSaved"
          >
            <div>
              <i class="fas fa-check"></i>
            </div>
          </button>
          <button
            type="button"
            class="btn btn-outline-danger btn-icon"
            @click="onClosed"
          >
            <div>
              <i class="fas fa-times"></i>
            </div>
          </button>
        </div>
        <div v-else class="card-header">
          <button
            type="button"
            class="btn btn-outline-success btn-icon"
            @click="onEdit"
          >
            <div>
              <i class="fas fa-pen"></i>
            </div>
          </button>
        </div>
        <div class="card-body overflow-hidden">
          <div class="col col-md-11 d-flex my-3">
            <label
              for="memberWebURL"
              class="col-3 col-lg-2 col-md-3 col-sm-2 col-form-label px-0"
              >店家網址</label
            >
            <input
              type="text"
              class="col-7 col-lg-8 col-md-7 col-sm-8 form-control"
              id="memberWebURL"
              v-model="store.MemberWebURL"
              disabled
            />
            <div class="col-auto">
              <button
                class="btn btn-outline-primary"
                type="button"
                @click="copyUrl"
              >
                <i class="fas fa-copy"></i>
              </button>
            </div>
          </div>
          <div class="row">
            <div class="member-shop-container mt-3">
              <div class="member-shop">
                <div class="store-background">
                  <img
                    v-if="store.MemberBackgroundImageURL"
                    :src="store.MemberBackgroundImageURL"
                    class="member-background-image"
                    alt="memberBackgroundImage"
                  />
                  <label
                    v-if="!isEdit"
                    class="background-image-label"
                    for="backgroundImage"
                  >
                    <div class="member-background-image-btn">
                      <i class="fas fa-camera"></i>變更封面
                    </div>
                    <input
                      type="file"
                      id="backgroundImage"
                      name="backgroundImage"
                      accept="image/png, image/jpeg"
                      hidden
                      @change="updateBackgroundImage"
                    />
                  </label>
                  <div class="member-top-section">
                    <div class="member-empty-block"></div>
                    <div class="member-logo-container">
                      <label v-if="!isEdit" for="logo">
                        <div class="member-logo">
                          <div class="member-logo-btn">
                            <i class="fas fa-camera"></i>
                          </div>
                          <img
                            v-if="store.MemberLogoURL"
                            :src="store.MemberLogoURL"
                            style="height: 100%"
                            alt=""
                          />
                        </div>
                        <input
                          type="file"
                          id="logo"
                          name="logo"
                          accept="image/png, image/jpeg"
                          hidden
                          @change="updateLogo"
                        />
                      </label>
                      <div v-else class="member-logo-empty">
                        <img
                          v-if="store.MemberLogoURL"
                          :src="store.MemberLogoURL"
                          style="height: 100%"
                          alt=""
                        />
                      </div>
                      <div class="member-store-name">
                        <input
                          v-if="isEdit"
                          type="text"
                          id="memberStoreName"
                          class="form-control"
                          v-model="memberStoreName"
                          placeholder="店家名稱"
                        />
                        <div v-else>{{ store.MemberStoreName }}</div>
                      </div>
                    </div>
                    <div class="member-empty-block"></div>
                  </div>
                  <div class="member-tags-container"></div>
                </div>
                <div class="member-info-container">
                  <div class="member-info">
                    <input
                      v-if="isEdit"
                      type="text"
                      id="memberTitle"
                      class="form-control"
                      v-model="memberTitle"
                      placeholder="品牌資訊"
                    />
                    <div v-else class="member-title">
                      {{ store.MemberTitle }}
                    </div>
                    <textarea
                      v-if="isEdit"
                      id="memberContent"
                      class="form-control"
                      rows="20"
                      v-model="memberContent"
                      placeholder="介紹內文"
                    />
                    <div id="memberContentReadonly">
                      {{ store.MemberContent }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-6">
      <h5>店家會員表單</h5>
      <div class="card card-table">
        <div class="card-header"></div>
        <div class="card-body">
          <div class="col col-md-11 pl-0">
            <div class="col-12 d-flex my-3">
              <label
                for="memberBusinessTitle"
                class="col-3 col-lg-2 col-md-3 col-sm-3 col-form-label px-0"
                >表單店名</label
              >
              <input
                type="text"
                class="col-8 col-lg-9 col-md-8 col-sm-8 form-control"
                id="memberBusinessTitle"
                v-model="store.MemberBusinessTitle"
                :readonly="!isEdit"
              />
            </div>
            <div class="col-12 d-flex my-3">
              <label
                for="memberBusinessBackgroundImage"
                class="col-3 col-lg-2 col-md-3 col-sm-3 col-form-label px-0"
                >表單封面</label
              >
              <div
                name="memberWebImage"
                class="btn btn-outline-secondary btn-icon btn-img w-100 col-8 col-lg-9 col-md-8 col-sm-8"
                @click="onImage"
              >
                <i class="far fa-image"></i>
              </div>
            </div>
            <div class="col-12 d-flex my-3">
              <label
                for="memberBusinessWebURL"
                class="col-3 col-lg-2 col-md-3 col-sm-3 col-form-label px-0"
                >表單網址</label
              >
              <input
                type="text"
                class="col-8 col-lg-9 col-md-8 col-sm-8 form-control"
                id="memberBusinessWebURL"
                v-model="store.MemberBusinessWebURL"
                disabled
              />
              <div class="col-auto">
                <button
                  class="btn btn-outline-primary"
                  type="button"
                  @click="copyUrl('business')"
                >
                  <i class="fas fa-copy"></i>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <member-web-image :trigger="imageTrigger" @onClosed="onImageClosed" />
  </div>
</template>

<script>
import memberWebImage from "@/components/datas/MemberWebImageModal";

export default {
  data() {
    return {
      store: {},
      memberStoreName: "",
      memberTitle: "",
      memberContent: "",
      isEdit: false,
      newBGI: "",
      memberBackgroundImageFile: "",
      memberLogoFile: "",
      imageTrigger: false,
    };
  },
  components: {
    memberWebImage,
  },
  methods: {
    getMemberWeb() {
      this.store = {};
      this.$api.businesses.getMemberWeb().then((data) => {
        this.store = data;
        this.memberStoreName = this.store.MemberStoreName;
        this.memberTitle = this.store.MemberTitle;
        this.memberContent = this.store.MemberContent;
        this.memberContent = this.memberContent.replaceAll("<br/>", "\n");
        const textarea = document.getElementById("memberContentReadonly");
        textarea.innerHTML = this.store.MemberContent;
        textarea.classList.remove("hide-member-content");
        if (!this.store.MemberTitle) {
          this.store.MemberTitle = "品牌資訊";
        }
      });
    },
    onSaved() {
      this.store.MemberStoreName = this.memberStoreName;
      this.store.MemberTitle = this.memberTitle;
      this.store.MemberContent = this.memberContent;
      this.$api.businesses
        .saveWebMember(this.store)
        .then(() => {
          this.$func.message.success("資料更新成功 !");
          this.getMemberWeb();
          this.isEdit = false;
        })
        .catch((error) => this.$func.message.error(error.data.Message));
    },
    onClosed() {
      this.isEdit = false;
      const textarea = document.getElementById("memberContentReadonly");
      textarea.innerHTML = this.store.MemberContent;
      textarea.classList.remove("hide-member-content");
    },
    onEdit() {
      this.isEdit = true;
      const textarea = document.getElementById("memberContentReadonly");
      textarea.classList.add("hide-member-content");
    },
    updateLogo(e) {
      if (e.target.files[0].size > 512000) {
        this.$func.message.alert("檔案大小不可超過500KB !");
        return;
      }
      this.memberLogoFile = e.target.files[0];

      let formData = new FormData();
      formData.append("file", this.memberLogoFile);
      this.$api.businesses.saveLogo(formData).then(() => {
        this.$func.message.success("Logo更新成功 !");
        this.getMemberWeb();
      });
    },
    deleteLogo() {
      this.$api.businesses.deleteLogo().then(() => {
        this.$func.message.success("Logo刪除成功 !");
        this.getMemberWeb();
      });
    },
    updateBackgroundImage(e) {
      if (e.target.files[0].size > 1024000) {
        this.$func.message.alert("檔案大小不可超過1MB !");
        return;
      }
      this.memberBackgroundImageFile = e.target.files[0];

      let formData = new FormData();
      formData.append("file", this.memberBackgroundImageFile);
      this.$api.businesses.saveBackgroundImage(formData).then(() => {
        this.$func.message.success("封面更新成功 !");
        this.getMemberWeb();
      });
    },
    deleteBackgroundImage() {
      this.$api.businesses.deleteBackgroundImage().then(() => {
        this.$func.message.success("封面刪除成功 !");
        this.getMemberWeb();
      });
    },
    copyUrl(type) {
      let url = this.store.MemberWebURL;
      if (type == "business") {
        url = this.store.MemberBusinessWebURL;
      }
      navigator.clipboard.writeText(url);
      this.$func.message.success("網址複製成功 !");
    },
    onImage() {
      this.imageTrigger = !this.imageTrigger;
    },
    onImageClosed() {
      this.imageTrigger = !this.imageTrigger;
    },
  },
  mounted() {
    this.getMemberWeb();
  },
};
</script>
<style lang="scss">
#memberStoreName {
  text-align: center;
  color: white;
  background-color: rgba(255, 255, 255, 0.5);
}
#memberTitle {
  text-align: center;
  margin-bottom: 5px;
  font-weight: 600;
}
.hide-member-content {
  display: none !important;
}
.img-logo {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 120px;
  height: 120px;
  border-radius: 60px;
  overflow: hidden;
  img {
    height: 100%;
  }
}
.img-background {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 160px;
  overflow: hidden;
  img {
    min-width: 100%;
    min-height: 100%;
  }
}
.store-background {
  position: relative;
  top: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 768px;
  height: 160px;
  color: white;
  background-color: #aaa;
  background-size: cover;
  background-position: center;
  &::before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.5);
  }
  .background-image-label {
    position: absolute;
    top: 10px;
    right: 10px;
    z-index: 5;
  }
}
.join-member-btn {
  cursor: pointer;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  bottom: -32px;
  width: 80%;
  max-width: 350px;
  height: 64px;
  border-radius: 10px;
  background-color: white;
  font-size: 24px;
  color: #3e5ba9;
  box-shadow: 0px 0px 5px 1px rgba(0, 0, 0, 0.1);
  .join-member-content {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 3px;
    transition: all 0.2s;
  }
  .join-member-icon {
    margin-bottom: 2px;
  }
  .join-member-text {
    margin-left: 10px;
  }
  &:hover {
    transition: all 0.2s;
    .join-member-content {
      transition: all 0.2s;
      transform: scale(1.1);
    }
  }
}
.member-shop-container {
  width: 100%;
  padding: 0 20px;
}
.member-shop {
  position: relative;
  width: 100%;
  min-height: 800px;
  max-width: 414px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  background-color: #f7f6f6;
}
.member-background-image {
  position: absolute;
  object-fit: cover;
  max-width: 768px;
  width: 100%;
  height: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 0;
  label {
    width: 100%;
  }
  filter: brightness(50%);
}
.member-background-image-btn {
  cursor: pointer;
  padding: 0 10px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 110px;
  height: 30px;
  color: white;
  background-color: rgba(200, 200, 200, 0.5);
  border-radius: 5px;
}
.member-top-section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding-bottom: 30px;
  z-index: 1;
}
.member-empty-block {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-weight: 100;
  margin-left: 20px;
  padding-top: 5px;
}
.member-logo-container {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.member-logo {
  width: 72px;
  height: 72px;
  background-color: white;
  border-radius: 36px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}
.member-logo-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 48px;
  right: 5px;
  width: 24px;
  height: 24px;
  color: white;
  font-size: 15px;
  border-radius: 12px;
  background-color: rgba(200, 200, 200, 0.5);
}
.member-logo-empty {
  width: 72px;
  height: 72px;
  background-color: white;
  border-radius: 36px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}
.member-store-name {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 3px 0 0 5px;
}
.member-tags-container {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  bottom: -32px;
  width: 90%;
  max-width: 350px;
  height: 64px;
  border-radius: 10px;
  background-color: white;
  color: black;
  box-shadow: 0px 0px 5px 1px rgba(0, 0, 0, 0.1);
}

.member-info-container {
  box-sizing: border-box;
  width: 100%;
  margin-top: 50px;
  margin-bottom: 80px;
}
.member-info {
  box-sizing: border-box;
  padding: 0 32px;
}
.member-title {
  text-align: center;
  padding: 10px 0;
  font-size: 22px;
  font-weight: 600;
}
#memberContentReadonly {
  text-align: start;
}
</style>
