import { httpGet } from "@/request/http";

let path = "/home";

export default {
  getPayTypes() {
    return httpGet(`${path}/report/paytypes`);
  },
  getFoodKinds() {
    return httpGet(`${path}/report/foodkinds`);
  },
  getFoods() {
    return httpGet(`${path}/report/foods`);
  },
  getTimes() {
    return httpGet(`${path}/report/times`);
  },
  getStores() {
    return httpGet(`${path}/report/stores`);
  },
};
