<template>
  <div>
    <pageTable
      :parentPage="pagination"
      @getItems="getItems"
      @addItem="add"
      @editItem="edit"
      @removeItem="remove"
      @trdbClicked="edit"
      :items="departments"
      :columns="columns"
      :idProperty="idProperty"
      :nameProperty="nameProperty"
      :getTrigger="getTrigger"
      :hasAdd="authority.IsAdd"
      :hasEdit="authority.IsUpdate"
      :hasRemove="authority.IsRemove"
      :isSortable="true"
    >
    </pageTable>

    <itemModal
      :isShowModal="isShowModal"
      @onClosed="isShowModal = !isShowModal"
      @onSaved="save"
      :title="modalTitle"
      :modalClass="modalClass"
    >
      <div slot="item">
        <div class="row">
          <div class="col-md-6" :class="inputClass">
            <div class="form-group">
              <label for="departmentNumber">{{ $t("modal.number") }}</label>
              <input
                type="text"
                class="form-control"
                id="departmentNumber"
                v-model="department.DepartmentNumber"
                required
              />
            </div>
          </div>
          <div class="col-md-6" :class="inputClass">
            <div class="form-group">
              <label for="departmentName">{{ $t("modal.name") }}</label>
              <input
                type="text"
                class="form-control"
                id="departmentName"
                v-model="department.DepartmentName"
                required
              />
            </div>
          </div>
        </div>
      </div>
    </itemModal>
  </div>
</template>

<script>
import pageTable from "@/components/PageTable";
import itemModal from "@/components/ItemModal";

export default {
  data() {
    return {
      departments: [],
      department: {},
      pagination: {},
      isShowModal: false,
      modalTitle: "",
      columns: [
        {
          name: "DepartmentNumber",
        },
        {
          name: "DepartmentName",
        },
      ],
      idProperty: "DepartmentId",
      nameProperty: "DepartmentName",
      getTrigger: false,
      authority: {},
    };
  },
  components: {
    pageTable,
    itemModal,
  },
  computed: {
    modalClass() {
      return this.isShow ? "modal-lg" : "modal-md";
    },
    inputClass() {
      return this.isShow ? "col-lg-4" : "";
    },
  },
  methods: {
    getItems(dTO) {
      const vm = this;
      this.$api.departments.getPages(dTO).then((data) => {
        vm.departments = data.Datas;
        vm.pagination = data.Pagination;
      });
    },
    add() {
      this.department = {};
      this.isShowModal = !this.isShowModal;
      this.modalTitle = this.$t("modal.adddepartment");
    },
    edit(department) {
      if (!this.authority.IsUpdate) {
        return;
      }
      const vm = this;
      this.$api.departments.get(department.DepartmentId).then((data) => {
        vm.department = data;
        vm.isShowModal = !vm.isShowModal;
        vm.modalTitle = this.$t("modal.editdepartment");
      });
    },
    remove(department) {
      const vm = this;
      this.$api.departments.remove(department.DepartmentId).then(() => {
        vm.getTrigger = !vm.getTrigger;
      });
    },
    save() {
      const vm = this;
      this.$api.departments
        .save(vm.department)
        .then(() => {
          vm.isShowModal = !vm.isShowModal;
          vm.getTrigger = !vm.getTrigger;
        })
        .catch((error) => vm.$func.message.error(error.data.Message));
    },
  },
  created() {
    this.authority = this.$func.localStorage.getAuthByName(this.$route.name);
  },
};
</script>
