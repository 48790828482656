<template>
  <div>
    <modules
      :moduleType="moduleType"
      :hasDevice="true"
      @currentModuleIdChanged="currentModuleIdChanged"
    >
      <template slot="buttons">
        <button
          type="button"
          class="btn btn-outline-primary btn-icon"
          @click="dayClick"
        >
          <i class="fas fa-calendar-alt"></i>
        </button>
      </template>
    </modules>

    <router-view />
  </div>
</template>

<script>
import modules from "@/components/modules/DirectoryModules";

export default {
  data() {
    return {
      moduleType: 0,
      currentModuleId: "",
    };
  },
  components: {
    modules,
  },
  methods: {
    isSelected() {
      if (this.currentModuleId && this.currentModuleId.length > 0) {
        return true;
      } else {
        this.$func.message.alert("請選擇一筆模組 !");
        return false;
      }
    },
    currentModuleIdChanged(id) {
      this.currentModuleId = id;
    },
    dayClick() {
      if (!this.isSelected()) {
        return;
      }
      this.$router.push({
        name: "DirDay",
        params: { id: this.currentModuleId },
      });
    },
  },
};
</script>
