<template>
  <item-modal
    :isShowModal="isShow"
    :title="'編輯'"
    @onSaved="save"
    @onClosed="close"
  >
    <div slot="item">
      <div class="row">
        <div class="col-md-6">
          <div class="form-group">
            <label for="customerName">客戶名稱:</label>
            <input
              type="text"
              class="form-control"
              id="customerName"
              v-model="item.CustomerName"
              required
            />
          </div>
        </div>
        <div class="col-md-6">
          <div class="form-group">
            <label for="cellphone">手機:</label>
            <input
              type="text"
              class="form-control"
              id="cellphone"
              v-model="item.Cellphone"
              required
            />
          </div>
        </div>
        <div class="col-md-6">
          <div class="form-group">
            <label for="telephone">電話:</label>
            <input
              type="text"
              class="form-control"
              id="telephone"
              v-model="item.Telephone"
              required
            />
          </div>
        </div>
        <div class="col-md-6">
          <div class="form-group">
            <label for="invoicePrizeAddress">地址:</label>
            <input
              type="text"
              class="form-control"
              id="invoicePrizeAddress"
              v-model="item.Address"
              required
            />
          </div>
        </div>
      </div>
    </div>
  </item-modal>
</template>

<script>
import itemModal from "@/components/ItemModal";

export default {
  data() {
    return {
      item: {},
    };
  },
  props: ["isShow", "invoice"],
  watch: {
    invoice(value) {
      this.item = value;
    },
  },
  components: {
    itemModal,
  },
  methods: {
    save() {
      const vm = this;
      this.$api.sals
        .patch(vm.item)
        .then(() => {
          vm.$emit("save");
        })
        .catch((error) => vm.$func.message.error(error.data.Message));
    },
    close() {
      this.$emit("close");
    },
  },
};
</script>
