<template>
  <div>
    <condition-part
      :hasCustomer="true"
      :hasExcel="true"
      :hasPdf="true"
      :hasAdd="false"
      :hasEdit="false"
      :hasRemove="false"
      :columns="columns"
      :items="items"
      :pagination="pagination"
      :isSortable="true"
      @getItems="getItems"
      @downloadExcel="downloadExcel"
      @downloadPdf="downloadPdf"
      @printPdf="printPdf"
    />
  </div>
</template>

<script>
import conditionPart from "@/components/parts/conditionPart";

export default {
  data() {
    return {
      columns: [
        { name: "CustomerNumber" },
        { name: "CustomerName" },
        { name: "Degree", headerText: "會員等級" },
        { name: "Integral", headerText: "剩餘積分" },
        { name: "IntegralMinus", headerText: "使用積分" },
        { name: "Deposit", headerText: "剩餘儲值金", width: 150 },
        { name: "DepositMinus" },
        { name: "Total" },
      ],
      items: [],
      pagination: {},
    };
  },
  components: {
    conditionPart,
  },
  methods: {
    downloadExcel(condition) {
      this.$api.reportExcels.getCustomerSaleStatistic(condition);
    },
    downloadPdf(condition) {
      this.$api.reportPdfs.getCustomerSaleStatistic(condition);
    },
    printPdf(condition) {
      this.$api.reportPdfs.printCustomerSaleStatistic(condition);
    },
    getItems(condition) {
      const vm = this;
      this.$api.reports.getCustomerSaleStatistic(condition).then((data) => {
        vm.items = data.Datas;
        vm.pagination = data.Pagination;
      });
    },
  },
};
</script>
