<template>
  <item-modal
    :isShowModal="isShow"
    :title="modalTitle"
    @onSaved="save"
    @onClosed="close"
  >
    <div slot="item">
      <div class="row">
        <div class="col-md-6">
          <div class="form-group">
            <label for="storeNumber">{{ $t("modal.number") }}</label>
            <input
              type="text"
              class="form-control"
              id="storeNumber"
              v-model="store.StoreNumber"
              required
            />
          </div>
        </div>
        <div class="col-md-6">
          <div class="form-group">
            <label for="storeName">{{ $t("modal.name") }}</label>
            <input
              type="text"
              class="form-control"
              id="storeName"
              v-model="store.StoreName"
              required
            />
          </div>
        </div>
        <div class="col-md-6">
          <div class="form-group">
            <label for="employeeId">{{ $t("modal.principal") }}:</label>
            <select
              class="form-control"
              id="employeeId"
              v-model="store.PrincipleId"
            >
              <option
                v-for="employee in employees"
                :value="employee.EmployeeId"
                :key="employee.EmployeeId"
              >
                {{ employee.EmployeeName }}
              </option>
            </select>
          </div>
        </div>
      </div>
    </div>
  </item-modal>
</template>

<script>
import itemModal from "@/components/ItemModal";

export default {
  data() {
    return {
      store: {},
      employees: [],
    };
  },
  props: ["isShow", "entity", "modalTitle"],
  watch: {
    entity(val) {
      this.store = val;
    },
  },
  components: {
    itemModal,
  },
  methods: {
    save() {
      const vm = this;
      this.$api.stores
        .save(vm.store)
        .then(() => {
          vm.$emit("save");
        })
        .catch((error) => vm.$func.message.error(error.data.Message));
    },
    close() {
      this.$emit("close");
    },
  },
  mounted() {
    const vm = this;
    this.$api.employees.getNames().then((data) => (vm.employees = data));
  },
};
</script>
