import { httpGet, httpPost, httpDelete } from "@/request/http";
import func from "@/funcs";

let path = "/inventorys";

export default {
  getPages(params) {
    return httpGet(`${path}/pages`, params);
  },
  post(item) {
    return httpPost(`${path}`, item);
  },
  get(id) {
    return httpGet(`${path}/${id}`);
  },
  delete(id) {
    return httpDelete(`${path}/${id}`);
  },
  getExcel() {
    func.getExcel.get(`${path}/foodexcel/export`, "匯入盤點單商品");
  },
  getExcelByPost(foods) {
    func.getExcel.post(`${path}/exportfood`, "匯入盤點單商品", foods);
  },
  postExcel(warehouseId, formData) {
    return func.postExcel(
      `${path}/warehouses/${warehouseId}/importfood`,
      formData
    );
  },
  getFood(warehouseId, foodId) {
    return httpGet(`/warehouses/${warehouseId}/foods/${foodId}/inventorydet`);
  },
  getFoods(warehouseId, foodIds) {
    var body = {};
    body.WarehouseId = warehouseId;
    body.FoodIds = foodIds;
    return httpPost(`${path}/batchfood`, body);
  },
  getInventorys() {
    return httpGet(`${path}/turnstockadjust`);
  },
  turnStockAdjust(ids) {
    return httpPost(`${path}/turnstockadjust`, ids);
  },
  postCasio(formData) {
    return func.postFile(`/casio/inventorys`, formData);
  },
  postTYSSO(warehouseId, formData) {
    return func.postFile(
      `${path}/warehouses/${warehouseId}/tysso/importfood`,
      formData
    );
  },
  print(id) {
    func.printPdf.get(`${path}/${id}/report`);
  },
};
