<template>
  <form class="card" @submit.prevent="onSaved">
    <div class="card-header">
      <button
        type="button"
        class="btn btn-outline-primary btn-icon"
        @click="onSaved"
      >
        <i class="fas fa-check"></i>
      </button>
      <button
        type="button"
        class="btn btn-outline-danger btn-icon"
        @click="onClosed"
      >
        <i class="fas fa-times"></i>
      </button>
      <import-part @onImport="onImport" :title="`${$t('modal.importfood')}`" />
      <export-part @onExport="onExport" />
    </div>
    <div class="card-body">
      <div class="row">
        <input-part id="businessDay" :labelText="`${$t('modal.date')}:`">
          <input
            type="date"
            class="form-control"
            id="businessDay"
            v-model="sal.BusinessDay"
            required
          />
        </input-part>
        <input-part id="salNumber" :labelText="`${$t('modal.number2')}:`">
          <input
            type="text"
            class="form-control"
            id="salNumber"
            v-model="sal.SalNumber"
            readonly
          />
        </input-part>
        <input-part id="customerName" :labelText="`${$t('modal.customer')}:`">
          <div class="row px-3">
            <input
              type="text"
              class="form-control col-12 col-md-6"
              id="customerNumber"
              :placeholder="`${$t('placeholder.inputcustomernumberordb')}`"
              v-model="addCustomerNumber"
              @keyup.enter.exact="selectCustomerByKeyword"
              @dblclick="customerModalTrigger = !customerModalTrigger"
            />
            <input
              type="text"
              class="form-control col-12 col-md-6"
              id="customerName"
              readonly
              :value="sal.CustomerName"
            />
          </div>
        </input-part>
        <input-part
          id="contactPerson"
          :labelText="`${$t('modal.contactperson')}:`"
        >
          <input
            type="text"
            class="form-control"
            id="contactPerson"
            v-model="sal.ContactPerson"
          />
        </input-part>
        <input-part id="telephone" :labelText="`${$t('modal.telephone')}:`">
          <input
            type="tel"
            class="form-control"
            id="telephone"
            v-model="sal.Telephone"
          />
        </input-part>
        <input-part id="saler" :labelText="`${$t('modal.salesperson')}:`">
          <div class="row px-3">
            <input
              type="text"
              class="form-control col-12 col-md-6"
              id="salerNumber"
              :placeholder="`${$t('placeholder.inputsalernumberordb')}`"
              v-model="addSalerNumber"
              @keyup.enter.exact="selectSalerByKeyword"
              @dblclick="salerModalTrigger = !salerModalTrigger"
            />
            <input
              type="text"
              class="form-control col-12 col-md-6"
              id="salerName"
              readonly
              :value="sal.SalerName"
            />
          </div>
        </input-part>
        <input-part
          v-if="isERP"
          id="defaultWarehouse"
          :labelText="`*${$t('modal.defaultwarehouse')}:`"
        >
          <div class="row px-3">
            <input
              type="text"
              class="form-control col-12 col-md-6"
              id="warehouseNumber"
              :placeholder="`${$t('placeholder.inputwarehousenumberordb')}`"
              v-model="addWarehouseNumber"
              @keyup.enter.exact="selectWarehouseByKeyword"
              @dblclick="warehouseModalTrigger = !warehouseModalTrigger"
            />
            <input
              type="text"
              class="form-control col-12 col-md-6"
              id="warehouseName"
              readonly
              :value="warehouse.WarehouseName"
            />
          </div>
        </input-part>
        <input-part
          id="address"
          :labelText="`${$t('modal.address')}:`"
          :degree="12"
        >
          <input
            type="text"
            class="form-control"
            id="address"
            v-model="sal.Address"
          />
        </input-part>
        <input-part id="memo" :labelText="`${$t('modal.memo')}:`" :degree="12">
          <input
            type="text"
            class="form-control"
            id="memo"
            v-model="sal.Memo"
          />
        </input-part>
        <input-part id="taxType" :labelText="`${$t('modal.taxtype')}:`">
          <div class="row">
            <div class="col-7">
              <select
                class="form-control"
                id="taxType"
                v-model="sal.TaxType"
                required
              >
                <option
                  v-for="taxType in taxTypes"
                  :key="taxType.value"
                  :value="taxType.value"
                >
                  {{ $t(`option.taxType.${taxType.name}`) }}
                </option>
              </select>
            </div>
            <div class="col-3 custom-control custom-checkbox">
              <input
                type="checkbox"
                class="custom-control-input"
                id="IsTaxAddition"
                v-model="sal.IsTaxAddition"
              />
              <label class="custom-control-label" for="IsTaxAddition">{{
                $t("modal.istaxaddition")
              }}</label>
            </div>
          </div>
        </input-part>
        <input-part
          v-if="hasCloudElecInvoice"
          id="payType"
          :labelText="`${$t('modal.paytype')}:`"
        >
          <select class="form-control" id="payType" v-model="payType" required>
            <option
              v-for="payType in payTypes"
              :key="payType.value"
              :value="payType.value"
            >
              {{ payType.text }}
            </option>
          </select>
        </input-part>
      </div>
      <div class="col-12 pl-0">
        <table-part :columns="columns" :noHide="true">
          <template slot="th-before">
            <th style="width: 80px">{{ $t("columnName.remove") }}</th>
            <th style="width: 80px">{{ $t("columnName.index") }}</th>
          </template>
          <tbody slot="tbody">
            <tr v-for="(det, index) in salDets" :key="det.SalDetId">
              <td>
                <button
                  type="button"
                  class="btn btn-outline-danger btn-icon"
                  @click="removeDet(index)"
                >
                  <i class="fas fa-trash-alt"></i>
                </button>
              </td>
              <td>{{ index + 1 }}</td>
              <td>{{ det.FoodNumber }}</td>
              <td>
                <input
                  class="form-control"
                  type="text"
                  v-model="det.FoodName"
                  @focus="saveDefaultName(det.FoodName)"
                  @blur="checkNewName(det)"
                  required
                />
              </td>
              <td v-if="isERP">
                <input
                  class="form-control"
                  type="text"
                  v-model="det.WarehouseNumber"
                  @change="updateWarehouse(det)"
                  required
                />
              </td>
              <td v-if="isERP">{{ det.WarehouseName }}</td>
              <td>
                <input
                  class="form-control text-right"
                  type="number"
                  v-model.number="det.Qty"
                  @input="calculate"
                  @blur="setValue(det, 'Qty')"
                  required
                />
              </td>
              <td>
                <input
                  class="form-control text-right"
                  type="number"
                  v-model.number="det.OriginalPrice"
                  @input="calculate"
                  @blur="setValue(det, 'OriginalPrice')"
                  required
                />
              </td>
              <td class="text-right">{{ det.SalPrice }}</td>
              <td>
                <input
                  class="form-control text-right"
                  type="number"
                  v-model.number="det.Total"
                  @input="reCalPrice(det, index)"
                  @focus="isFocused = true"
                  @blur="isFocused = false"
                  required
                />
              </td>
              <td class="text-right">{{ det.TaxPrice }}</td>
              <td v-if="hasBatchNumber">
                <input
                  class="form-control"
                  type="text"
                  v-model="det.BatchNumber"
                  :readonly="!det.HasBatchNumber"
                  @dblclick="openBatchSelector(det, index)"
                />
              </td>
              <td>
                <input class="form-control" type="text" v-model="det.Memo" />
              </td>
            </tr>
            <tr>
              <td colspan="12">
                <input
                  class="form-control"
                  type="text"
                  :placeholder="`${$t('placeholder.inputfoodnumberorbarcode')}`"
                  v-model="addFoodNumber"
                  @keyup.enter.exact="selectFoodByKeyword"
                  @dblclick="
                    foodModalTrigger = !foodModalTrigger;
                    foodsByKeyword = [];
                  "
                />
              </td>
            </tr>
            <tr>
              <td>{{ $t("columnName.total") }}:</td>
              <td></td>
              <td></td>
              <td></td>
              <td v-if="isERP"></td>
              <td v-if="isERP"></td>
              <td class="text-right">{{ totalQty }}</td>
              <td></td>
              <td></td>
              <td class="text-right">{{ sal.Total }}</td>
              <td class="text-right">{{ sal.TaxPrice }}</td>
              <td></td>
              <td></td>
            </tr>
          </tbody>
        </table-part>
      </div>
      <food-selects
        :trigger="foodModalTrigger"
        :isMultiple="true"
        @onSaved="addFoods"
        :parentItems="foodsByKeyword"
        :noSearchType="!hasFoodSearchType"
      />
      <customer-selects
        :trigger="customerModalTrigger"
        :isMultiple="false"
        @onSaved="setCustomer"
      ></customer-selects>
      <warehouse-selects
        v-if="isERP"
        :trigger="warehouseModalTrigger"
        :isMultiple="false"
        @onSaved="setDefaultWarehouse"
      ></warehouse-selects>
      <employee-selects
        :trigger="salerModalTrigger"
        :isMultiple="false"
        @onSaved="setSaler"
      ></employee-selects>
      <batch-selects
        :trigger="batchModalTrigger"
        :isMultiple="false"
        :warehouseId="currentWarehouseId"
        :foodId="currentFoodId"
        @onSaved="setBatchNumber"
      />
      <customer-data
        :item="customer"
        :modalDisplayTrigger="modalDisplayTrigger"
        @onClosed="modalDisplayTrigger = !modalDisplayTrigger"
        @onSaved="saveCustomer"
        :title="modalTitle"
        :isEdit="isEdit"
      ></customer-data>
    </div>
  </form>
</template>

<script>
import inputPart from "@/components/parts/inputPart";
import tablePart from "@/components/parts/tablePart";

import importPart from "@/components/ImportPart";
import exportPart from "@/components/ExportPart";

import foodSelects from "@/components/selects/FoodSelects";
import customerSelects from "@/components/selects/CustomerSelects";
import warehouseSelects from "@/components/selects/WarehouseSelects";
import employeeSelects from "@/components/selects/EmployeeSelects";
import batchSelects from "@/components/selects/BatchSelects";

import customerData from "@/components/datas/CustomerData";

export default {
  data() {
    return {
      taxTypes: this.$enum.taxType.getAll(),
      sal: this.$func.defaultModel.getSal(),
      salDets: [],
      addFoodNumber: null,
      addCustomerNumber: null,
      addSalerNumber: null,
      addWarehouseNumber: null,
      foodModalTrigger: false,
      customerModalTrigger: false,
      warehouseModalTrigger: false,
      buyerModalTrigger: false,
      salerModalTrigger: false,
      batchModalTrigger: false,
      isReturn: false,
      foodsByKeyword: [],
      hasFoodSearchType: true,
      payTypes: this.$enum.payType.getForSale(),
      payType: 0,
      warehouse: {
        WarehouseName: null,
      },
      roundType: 0,
      detailRoundType: 0,
      currentWarehouseId: "",
      currentFoodId: "",
      currentFoodIndex: null,
      isFocused: false,
      customer: {},
      modalDisplayTrigger: false,
      modalTitle: "",
      stores: [],
      isEdit: false,
    };
  },
  watch: {
    "sal.TaxType"(value) {
      if (value != 1) this.sal.IsTaxAddition = 0;
      this.calculate();
    },
    "sal.IsTaxAddition"() {
      this.calculate();
    },
    currentLang() {
      this.stores[0].StoreName = this.$t("option.None");
    },
  },
  computed: {
    currentLang() {
      return this.$store.state.currentLang;
    },
    hasBatchNumber() {
      return window.localStorage["hasBatchNumber"] == "true";
    },
    totalQty() {
      if (this.salDets.length > 0)
        return this.salDets
          .map((item) => {
            return item.Qty;
          })
          .reduce((t, q) => {
            if (!parseInt(t)) t = 0;
            if (!parseInt(q)) q = 0;
            return parseInt(t) + parseInt(q);
          });
      else return 0;
    },
    hasCloudElecInvoice() {
      return (
        window.localStorage["productType"] == 3 ||
        window.localStorage["hasCloudElecInvoice"]
      );
    },
    isERP() {
      return window.localStorage["isERP"] == "true";
    },
    columns() {
      let columns = [
        { name: "FoodNumber" },
        { name: "FoodName" },
        { name: "Qty" },
        { name: "OriginalPrice" },
        { name: "SalPrice", headerText: "平均單價" },
        { name: "Total" },
        { name: "TaxPrice" },
        { name: "Memo" },
      ];
      if (this.isERP) {
        columns.splice(2, 0, { name: "WarehouseNumber" });
        columns.splice(3, 0, { name: "WarehouseName" });
      }
      if (this.hasBatchNumber) {
        columns.splice(-1, 0, { name: "BatchNumber" });
      }
      return columns;
    },
  },
  components: {
    foodSelects,
    customerSelects,
    warehouseSelects,
    employeeSelects,
    batchSelects,
    inputPart,
    importPart,
    exportPart,
    tablePart,
    customerData,
  },
  methods: {
    saveCustomer(customer) {
      const vm = this;
      if (customer.CountryCode) {
        customer.CountryCode = customer.CountryCode.replace(/[^0-9]/g, "");
      }
      this.$api.customers
        .save(customer)
        .then(() => {
          vm.modalDisplayTrigger = !vm.modalDisplayTrigger;
          vm.$api.customers
            .getByNumber(customer.CustomerNumber)
            .then((data) => {
              vm.setCustomer(data);
            });
        })
        .catch((error) => vm.$func.message.error(error.data.Message));
    },
    setValue(det, type) {
      if (type == "Qty") {
        if (
          isNaN(det.Qty) ||
          det.Qty === "" ||
          det.Qty === null ||
          det.Qty == 0
        ) {
          this.$func.message.alert("數量不可為0 !");
          det.Qty = 1;
          this.calculate();
        }
      } else if (type == "OriginalPrice") {
        if (
          isNaN(det.OriginalPrice) ||
          det.OriginalPrice === "" ||
          det.OriginalPrice === null
        ) {
          det.OriginalPrice = 0;
        }
      }
    },
    selectCustomerByKeyword() {
      if (!this.addCustomerNumber || this.addCustomerNumber.length == 0) {
        this.$func.message.alert("請輸入會員編號");
        return;
      }
      const vm = this;
      this.$api.customers
        .getByNumber(this.addCustomerNumber)
        .then((data) => {
          if (data) {
            vm.setCustomer(data);
          }
        })
        .catch(() => {
          this.$func.message
            .confirm("查無此會員 !", "是否新增此會員 ?")
            .then((result) => {
              if (result.isConfirmed) {
                this.customer.CustomerNumber = this.addCustomerNumber;
                this.customer.CountryCode = "886";
                this.modalDisplayTrigger = !this.modalDisplayTrigger;
                this.modalTitle = this.$t("modal.addcustomer");
              } else {
                this.setCustomer({
                  CustomerNumber: null,
                  CustomerId: null,
                  CustomerName: null,
                  ContactPerson: null,
                  Phone1: null,
                  SalPriceType: null,
                });
              }
            });
        });
    },
    selectSalerByKeyword() {
      if (!this.addSalerNumber || this.addSalerNumber.length == 0) {
        this.$func.message.alert("請輸入業務員編號");
        return;
      }
      const vm = this;
      this.$api.employees
        .getByNumber(this.addSalerNumber)
        .then((data) => {
          if (data) {
            vm.setSaler(data);
          }
        })
        .catch(() => {
          this.$func.message.error("查無此業務員 !");
          this.setSaler({
            EmployeeNumber: null,
            EmployeeId: null,
            EmployeeName: null,
            DefaultWarehouseId: null,
            DefaultWarehouseNumber: null,
            DefaultWarehouseName: null,
          });
        });
    },
    selectWarehouseByKeyword() {
      if (!this.addWarehouseNumber || this.addWarehouseNumber.length == 0) {
        this.$func.message.alert("請輸入倉庫編號");
        return;
      }
      const vm = this;
      this.$api.warehouses
        .getByNumber(this.addWarehouseNumber)
        .then((data) => {
          if (data) {
            vm.setDefaultWarehouse(data);
          }
        })
        .catch(() => {
          this.$func.message.error("查無此倉庫 !");
          this.setDefaultWarehouse({
            WarehouseId: null,
            WarehouseNumber: null,
            WarehouseName: null,
          });
        });
    },
    setBatchNumber(item) {
      this.salDets[this.currentFoodIndex].BatchNumber = item.BatchNumber;
    },
    openBatchSelector(det, index) {
      if (!det.HasBatchNumber) {
        return;
      }
      this.currentWarehouseId = det.WarehouseId;
      this.currentFoodId = det.FoodId;
      this.currentFoodIndex = index;
      this.batchModalTrigger = !this.batchModalTrigger;
    },
    saveDefaultName(name) {
      this.defaultFoodName = name;
    },
    checkNewName(det) {
      if (!det.FoodName) {
        this.$func.message.alert("請輸入商品名稱");
        det.FoodName = this.defaultFoodName;
      }
    },
    showFoodSelect() {
      this.foodsByKeyword = undefined;
      this.hasFoodSearchType = true;
      this.foodModalTrigger = !this.foodModalTrigger;
    },
    addDet(item) {
      this.addFoodNumber = "";
      let salDet = {
        FoodId: item.FoodId,
        FoodNumber: item.FoodNumber,
        FoodName: item.FoodName,
        WarehouseName: "",
        Qty: 1,
        Cost: item.Cost,
        IsStock: item.IsStock,
        HasBatchNumber: item.HasBatchNumber,
        BatchNumber: null,
        SalPrices: [
          item.SalPrice1,
          item.SalPrice2,
          item.SalPrice3,
          item.SalPrice4,
          item.SalPrice5,
          item.SalPrice6,
        ],
      };

      if (this.sal.SalPriceType == 0 || !this.sal.SalPriceType) {
        salDet.OriginalPrice = salDet.SalPrices[0];
      } else {
        salDet.OriginalPrice = salDet.SalPrices[this.sal.SalPriceType - 1];
      }

      if (this.isERP) {
        if (this.warehouse.WarehouseName) {
          salDet.WarehouseId = this.warehouse.WarehouseId;
          salDet.WarehouseNumber = this.warehouse.WarehouseNumber;
          salDet.WarehouseName = this.warehouse.WarehouseName;
        }
      }
      this.salDets.push(salDet);
      this.calculate();
    },
    selectFoodByKeyword() {
      if (!this.addFoodNumber || this.addFoodNumber.length == 0) {
        this.$func.message.alert("請輸入商品編號或條碼");
        return;
      }
      const vm = this;
      this.$api.foods
        .getByKeyword({ Keyword: this.addFoodNumber })
        .then((data) => {
          if (data) {
            if (data.length > 1) {
              vm.foodsByKeyword = data;
              vm.hasFoodSearchType = false;
              vm.foodModalTrigger = !vm.foodModalTrigger;
            } else {
              vm.addDet(data[0]);
            }
          }
        })
        .catch(() => this.$func.message.error("查無此商品 !"));
    },
    removeDet(index) {
      this.salDets.splice(index, 1);
      this.calculate();
    },
    onSaved() {
      if (this.isERP) {
        if (!this.sal.CustomerId || this.sal.CustomerId.length == 0) {
          this.$func.message.alert("請選擇客戶 !");
          return;
        }

        const checkWarehouseId = this.salDets.some((det) => {
          return !det.WarehouseId;
        });
        if (checkWarehouseId) {
          this.$func.message.alert("請選擇倉庫 !");
          return;
        }
      }

      if (this.salDets.length == 0) {
        this.$func.message.alert("請選擇商品 !");
        return;
      }

      let emptyBatch = this.salDets.some((det) => {
        return det.HasBatchNumber && !det.BatchNumber;
      });
      if (emptyBatch) {
        this.$func.message.alert("請設定商品批號 !");
        return;
      }

      if (!Number.isInteger(this.sal.Total)) {
        this.$func.message.alert("總計必須為整數 !");
        return;
      }

      this.sal.TradeType = this.$enum.tradeType.getByName(
        this.isReturn ? "BackSalReturn" : "BackSal"
      ).value;

      this.sal.Cash = 0;
      this.sal.CreditCardPrice = 0;
      this.sal.PayType1 = 0;
      this.sal.PayType2 = 0;
      this.sal.Receivable = 0;
      this.sal.SourceType = 4;

      if (this.payType == 6) {
        this.sal.Receivable = this.sal.Total;
      } else if (this.payType == 1) {
        this.sal.CreditCardPrice = this.sal.Total;
      } else if (this.payType == 4) {
        this.sal.PayType1 = this.sal.Total;
      } else if (this.payType == 5) {
        this.sal.PayType2 = this.sal.Total;
      } else if (this.payType == 0) {
        this.sal.Cash = this.sal.Total;
      }
      const vm = this;
      this.$api.sals
        .post({
          Sal: vm.sal,
          SalDets: vm.salDets,
        })
        .then((data) => {
          if (vm.$route.name == "SalAdd" || vm.$route.name == "SalReturnAdd") {
            vm.$func.message.success(`單號為${data.Sal.SalNumber}`);
          }
          this.$store.dispatch("setSaveOrClose", true);
          this.$router.push({ name: vm.isReturn ? "SalReturns" : "Sals" });
        })
        .catch((error) => {
          vm.$func.message.error(error.data.Message);
        });
    },
    onClosed() {
      this.$store.dispatch("setSaveOrClose", true);
      this.$router.go(-1);
    },
    setCustomer(item) {
      this.addCustomerNumber = item.CustomerNumber;
      this.sal.CustomerId = item.CustomerId;
      this.sal.CustomerName = item.CustomerName;
      this.sal.ContactPerson = item.ContactPerson;
      this.sal.Telephone = item.Phone1;
      this.sal.SalPriceType = item.SalPriceType;
      if (item.Address) {
        if (this.sal.Address) {
          this.$func.message
            .confirm("是否以會員地址取代目前輸入地址 ?")
            .then((result) => {
              if (result.isConfirmed) {
                this.sal.Address = item.Address;
              }
            });
        } else {
          this.sal.Address = item.Address;
        }
      }
    },
    setSaler(item) {
      this.addSalerNumber = item.EmployeeNumber;
      this.sal.SalerId = item.EmployeeId;
      this.sal.SalerName = item.EmployeeName;
      this.warehouse.WarehouseId = item.DefaultWarehouseId;
      this.warehouse.WarehouseNumber = item.DefaultWarehouseNumber;
      this.warehouse.WarehouseName = item.DefaultWarehouseName;

      if (item.DefaultWarehouseId) {
        this.salDets.forEach((det) => {
          if (!det.WarehouseId) {
            this.setWarehouse(det, this.warehouse);
          }
        });
      }
    },
    addFoods(items) {
      const vm = this;
      items.forEach(function (item) {
        vm.addDet(item);
      });
    },
    calculate() {
      this.$func.calculateTax(
        this.sal,
        this.salDets,
        this.roundType,
        this.detailRoundType,
        this.isFocused
      );
    },
    reCalPrice(det) {
      det.OriginalPrice = Math.ceil((det.Total / det.Qty) * 100) / 100;
      this.calculate();
    },
    onExport() {
      this.$api.sals.getExcel();
    },
    onImport(file) {
      let formData = new FormData();
      formData.append("file", file);
      this.$api.sals.postExcel(formData).then((data) => {
        if (data) {
          data.Items.forEach((item) => {
            this.salDets.push(item);
          });
          this.calculate();
        }
      });
    },
    updateWarehouse(det) {
      this.$api.warehouses
        .getByNumber(det.WarehouseNumber)
        .then((data) => {
          if (data) {
            this.setWarehouse(det, data);
          } else {
            this.setWarehouse(det, undefined);
          }
        })
        .catch(() => {
          this.$func.message.alert("查無此資料 !");
          this.setWarehouse(det, undefined);
        });
    },
    setWarehouse(det, warehouse) {
      if (warehouse) {
        det.WarehouseId = warehouse.WarehouseId;
        det.WarehouseNumber = warehouse.WarehouseNumber;
        det.WarehouseName = warehouse.WarehouseName;
      } else {
        det.WarehouseId = undefined;
        det.WarehouseNumber = undefined;
        det.WarehouseName = undefined;
      }
    },
    setDefaultWarehouse(item) {
      this.addWarehouseNumber = item.WarehouseNumber;
      this.warehouse.WarehouseId = item.WarehouseId;
      this.warehouse.WarehouseNumber = item.WarehouseNumber;
      this.warehouse.WarehouseName = item.WarehouseName;
    },
  },
  mounted() {
    const vm = this;
    this.$api.stores.getNames().then((data) => {
      data.splice(0, 0, {
        StoreId: null,
        StoreName: vm.$t("option.None"),
      });
      vm.stores = data;
    });
  },
  beforeRouteLeave(to, from, next) {
    const isSaveOrClose = this.$store.state.isSaveOrClose;
    if (isSaveOrClose) {
      next();
    } else {
      const result = confirm("資料尚未儲存，確定離開當前頁面嗎 ?");
      if (result) {
        next();
      } else {
        next(false);
      }
    }
  },
  created() {
    this.payType = this.isERP ? 6 : 0;
    if (this.$route.name.toLowerCase().includes("return")) this.isReturn = true;
    const id = this.$route.params["id"];
    if (id) {
      this.$api.sals
        .get(id)
        .then((data) => {
          this.sal = data.Sal;
          this.addSalerNumber = data.Sal.EmployeeNumber;
          if (data.Sal.CustomerId) {
            this.addCustomerNumber = data.Sal.CustomerNumber;
            this.$api.customers
              .get(this.sal.CustomerId)
              .then((data) => {
                this.sal.SalPriceType = data.SalPriceType;
              })
              .catch((err) => {
                this.$func.message.error(err.data.Message);
              });
          }
          this.salDets = data.SalDets;
          this.calculate();
          if (this.sal.Cash > 0) {
            this.payType = 0;
          } else if (this.sal.CreditCardPrice > 0) {
            this.payType = 1;
          } else if (this.sal.PayType1 > 0) {
            this.payType = 4;
          } else if (this.sal.PayType2 > 0) {
            this.payType = 5;
          } else if (this.sal.Receivable > 0) {
            this.payType = 6;
          }
          this.$api.employees.get(data.Sal.SalerId).then((item) => {
            if (item.DefaultWarehouseNumber) {
              this.addWarehouseNumber = item.DefaultWarehouseNumber;
              this.warehouse.WarehouseId = item.DefaultWarehouseId;
              this.warehouse.WarehouseNumber = item.DefaultWarehouseNumber;
              this.warehouse.WarehouseName = item.DefaultWarehouseName;
            }
          });
        })
        .catch(() => {
          this.$func.message.alert("查無此筆單據 !");
          this.$router.go(-1);
        });
    } else {
      this.$api.employees.get(window.localStorage["userId"]).then((data) => {
        this.addSalerNumber = data.EmployeeNumber;
        this.addWarehouseNumber = data.DefaultWarehouseNumber;
        this.sal.EmployeeId = data.EmployeeId;
        this.sal.EmployeeName = data.EmployeeName;
        this.sal.SalerId = data.EmployeeId;
        this.sal.SalerName = data.EmployeeName;
        this.warehouse.WarehouseId = data.DefaultWarehouseId;
        this.warehouse.WarehouseNumber = data.DefaultWarehouseNumber;
        this.warehouse.WarehouseName = data.DefaultWarehouseName;
      });
    }

    this.$api.businesses.getPart().then((data) => {
      if (data.PayType1) this.payTypes[2].text = data.PayType1;
      if (data.PayType2) this.payTypes[3].text = data.PayType2;
      this.roundType = data.RoundType;
      this.detailRoundType = data.DetailRoundType;
      this.sal.IsTaxAddition = data.IsTaxAddition;
    });
  },
};
</script>
