<template>
  <div>
    <search-and-table-part
      :pagination="pagination"
      @getItems="getInvoicePrizes"
      :items="invoicePrizes"
      :columns="columns"
      :idProperty="idProperty"
      :hasAdd="false"
      :hasEdit="false"
      :hasRemove="false"
      :hasCheckDetail="true"
      :getTrigger="getTrigger"
      @trdbClicked="checkInvoicePrizeDetail"
      :isSortable="true"
    >
      <template slot="searchArea">
        <div class="row">
          <div class="col-12">
            <div class="form-group row">
              <label
                for="searchUniformNumber"
                class="col-4 col-md-2 col-lg-1 col-form-label"
                >統一編號:</label
              >
              <div class="col">
                <input
                  type="text"
                  class="form-control"
                  id="searchUniformNumber"
                  v-model="searchUniformNumber"
                />
              </div>
            </div>
          </div>
          <div class="col-12">
            <div class="form-group row">
              <label
                for="searchPeriod"
                class="col-4 col-md-2 col-lg-1 col-form-label"
                >期別:</label
              >
              <div class="col">
                <select
                  class="form-control"
                  id="searchPeriod"
                  v-model="searchPeriod"
                >
                  <option
                    v-for="invoicePeriod in invoicePeriods"
                    :key="invoicePeriod.value"
                    :value="invoicePeriod.text"
                  >
                    {{ invoicePeriod.text }}
                  </option>
                </select>
              </div>
            </div>
          </div>
        </div>
      </template>
      <template slot="dataButtonAreaFront" slot-scope="props">
        <button
          class="btn btn-outline-primary btn-icon"
          type="button"
          @click="checkInvoicePrizeDetail(invoicePrizes[props.index])"
        >
          <i class="fas fas fa-eye"></i>
        </button>
      </template>
    </search-and-table-part>

    <invoicePrizeDetail
      :item="invoicePrize"
      :isShow="isShow"
      @onClosed="onClosed"
    ></invoicePrizeDetail>
  </div>
</template>

<script>
import searchAndTablePart from "@/components/parts/searchAndTablePart";
import invoicePrizeDetail from "@/components/datas/InvoicePrizeDetail";
export default {
  data() {
    return {
      invoicePeriods: [
        {
          value: 1,
          text: undefined,
        },
        {
          value: 2,
          text: undefined,
        },
        {
          value: 3,
          text: undefined,
        },
        {
          value: 4,
          text: undefined,
        },
        {
          value: 5,
          text: undefined,
        },
        {
          value: 6,
          text: undefined,
        },
      ],
      searchUniformNumber: undefined,
      searchPeriod: undefined,
      invoicePrizes: [],
      invoicePrize: {},
      columns: [
        { name: "BusinessId" },
        { name: "Period" },
        { name: "InvoiceNumber" },
        { name: "Receiver" },
        { name: "PostalCode" },
        { name: "Address" },
        { name: "Phone" },
      ],
      pagination: {},
      idProperty: "InvoicePrizeId",
      getTrigger: false,
      isShow: false,
    };
  },
  components: {
    searchAndTablePart,
    invoicePrizeDetail,
  },
  methods: {
    checkInvoicePrizeDetail(item) {
      if (item) {
        this.$api.invoicePrizes
          .get(item.SalId)
          .then((data) => {
            this.invoicePrize = data;
            this.isShow = !this.isShow;
          })
          .catch(() => this.$func.message.alert("查無此資料 !"));
      } else {
        this.$func.message.alert("請選擇一筆名單 !");
      }
    },
    getInvoicePrizes(dTO) {
      if (this.searchUniformNumber) {
        dTO.UniformNumber = this.searchUniformNumber;
      } else {
        dTO.UniformNumber = null;
      }
      dTO.Period = parseInt(this.searchPeriod);
      this.$api.invoicePrizes.getPages(dTO).then((data) => {
        this.invoicePrizes = data.Datas;
        this.pagination = data.Pagination;
      });
    },
    onClosed() {
      this.isShow = !this.isShow;
    },
  },
  created() {
    const n = new Date();
    let year = n.getFullYear();
    let month = n.getMonth() + 1;
    const mod = month % 2;
    if (mod !== 0) {
      month += 1;
    }
    for (let i = 0; i < this.invoicePeriods.length; i++) {
      if (month - 2 * this.invoicePeriods[i].value > 0) {
        if (month - 2 * this.invoicePeriods[i].value < 10) {
          this.invoicePeriods[i].text = `${year - 1911}0${
            month - 2 * this.invoicePeriods[i].value
          }`;
        } else {
          this.invoicePeriods[i].text = `${year - 1911}${
            month - 2 * this.invoicePeriods[i].value
          }`;
        }
      } else {
        if (month - 2 * this.invoicePeriods[i].value + 12 < 10) {
          this.invoicePeriods[i].text = `${year - 1912}0${
            month - 2 * this.invoicePeriods[i].value + 12
          }`;
        } else {
          this.invoicePeriods[i].text = `${year - 1912}${
            month - 2 * this.invoicePeriods[i].value + 12
          }`;
        }
      }
    }
    this.searchPeriod = this.invoicePeriods[0].text;
  },
};
</script>
