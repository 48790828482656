import userAccounts from "@/request/apis/userAccountApi";
import accounts from "@/request/apis/accountApi";
import businesses from "@/request/apis/businessApi";
import stores from "@/request/apis/storeApi";
import terminals from "@/request/apis/terminalApi";
import employees from "@/request/apis/employeeApi";
import foodKinds from "@/request/apis/foodKindApi";
import foodMajorKinds from "@/request/apis/foodMajorKindApi";
import foods from "@/request/apis/foodApi";
import comboKinds from "@/request/apis/comboKindApi";
import combos from "@/request/apis/comboApi";
import foodCombos from "@/request/apis/foodComboApi";
import tasteKinds from "@/request/apis/tasteKindApi";
import tastes from "@/request/apis/tasteApi";
import foodKindTastes from "@/request/apis/foodKindTasteApi";
import foodTastes from "@/request/apis/foodTasteApi";
import modules from "@/request/apis/moduleApi";
import functions from "@/request/apis/functionApi";
import functionButtons from "@/request/apis/functionButtonApi";
import downloads from "@/request/apis/downloadApi";
import downloadDets from "@/request/apis/downloadDetApi";
import users from "@/request/apis/userApi";
import deviceUVs from "@/request/apis/deviceUVApi";
import devices from "@/request/apis/deviceApi";
import pays from "@/request/apis/payApi";
import directoryKinds from "@/request/apis/directoryKindApi";
import directorys from "@/request/apis/directoryApi";
import priceLabels from "@/request/apis/priceLabelApi";
import brands from "@/request/apis/brandApi";
import departments from "@/request/apis/departmentApi";
import logs from "@/request/apis/logApi";

import warehouses from "@/request/apis/warehouseApi";
import suppliers from "@/request/apis/supplierApi";

import promotions from "@/request/apis/promotionApi";
import promotionDets from "@/request/apis/promotionDetApi";
import foodPromotions from "@/request/apis/foodPromotionApi";

import reports from "@/request/apis/reportApi";
import reportExcels from "@/request/apis/reportExcelApi";
import reportPdfs from "@/request/apis/reportPdfApi";

import values from "@/request/apis/valueApi";

import stockAdjusts from "@/request/apis/stockAdjustApi";
import stockTransfers from "@/request/apis/stockTransferApi";
import purchases from "@/request/apis/purchaseApi";
import purchaseOrders from "@/request/apis/purchaseOrderApi";
import sals from "@/request/apis/salApi";
import inventorys from "@/request/apis/inventoryApi";
import stocks from "@/request/apis/stockApi";

//Account
import payments from "@/request/apis/paymentApi";
import payables from "@/request/apis/payableApi";
import receipts from "@/request/apis/receiptApi";
import receivables from "@/request/apis/receivableApi";

//Customer
import customers from "@/request/apis/customerApi";
import memberSettings from "@/request/apis/memberSettingApi";
import memberUpgradeRules from "@/request/apis/memberUpgradeRuleApi";
import depostRecords from "@/request/apis/depositRecordApi";
import customerLevels from "@/request/apis/customerLevelApi";
import integralRules from "@/request/apis/integralRuleApi";
import ocardRules from "@/request/apis/ocardRuleApi";

//Dining
import floorMaps from "@/request/apis/floorMapApi";
import tableMaps from "@/request/apis/tableMapApi";

import home from "@/request/apis/homeApi";

import lingYueh from "@/request/apis/lingYuehApi";
import digiwin from "@/request/apis/digiwinApi";

//Unit
import initialization from "@/request/apis/initializationApi";

//OnlineOrder
import dirModules from "@/request/apis/dirModuleApi";
import onlineOrderMenus from "@/request/apis/onlineOrderMenuApi";
import onlineOrderCategories from "@/request/apis/onlineOrderCategoryApi";
import onlineOrderItems from "@/request/apis/onlineOrderItemApi";
import onlineOrderModifierGroups from "@/request/apis/onlineOrderModifierGroupApi";
import uberEats from "@/request/apis/uberEatApi";
import line from "@/request/apis/lineApi";

//Combination
import combinations from "@/request/apis/combinationApi";
import combinationTrades from "@/request/apis/combinationTradeApi";

import columnProperties from "@/request/apis/columnPropertyApi";

import utai from "@/request/apis/utaiApi";
import invoicePrizes from "@/request/apis/invoicePrizeApi";
import ushow from "@/request/apis/ushowApi";

import changePrices from "@/request/apis/changePriceApi";

export default {
  accounts,
  userAccounts,
  businesses,
  stores,
  terminals,
  employees,
  customers,
  memberSettings,
  memberUpgradeRules,
  depostRecords,
  customerLevels,
  integralRules,
  ocardRules,
  foodKinds,
  foodMajorKinds,
  foods,
  comboKinds,
  combos,
  foodCombos,
  tasteKinds,
  tastes,
  foodKindTastes,
  foodTastes,
  modules,
  functions,
  functionButtons,
  reports,
  reportExcels,
  reportPdfs,
  downloads,
  downloadDets,
  users,
  devices,
  deviceUVs,
  pays,
  directoryKinds,
  directorys,
  promotions,
  promotionDets,
  foodPromotions,
  warehouses,
  suppliers,
  stockAdjusts,
  stockTransfers,
  values,
  purchases,
  purchaseOrders,
  sals,
  inventorys,
  payments,
  payables,
  receipts,
  receivables,
  floorMaps,
  tableMaps,
  home,
  priceLabels,
  stocks,
  lingYueh,
  digiwin,
  departments,
  initialization,
  dirModules,
  onlineOrderMenus,
  onlineOrderCategories,
  onlineOrderItems,
  onlineOrderModifierGroups,
  uberEats,
  line,
  combinations,
  combinationTrades,
  columnProperties,
  utai,
  invoicePrizes,
  ushow,
  changePrices,
  logs,
  brands,
};
