<template>
  <form class="card" @submit.prevent="onSaved">
    <div class="card-header">
      <button
        type="button"
        class="btn btn-outline-primary btn-icon"
        @click="onSaved"
      >
        <i class="fas fa-check"></i>
      </button>
      <button
        type="button"
        class="btn btn-outline-danger btn-icon"
        @click="onClosed"
      >
        <i class="fas fa-times"></i>
      </button>
      <import-part @onImport="onImport" :title="`${$t('modal.importfood')}`" />
      <export-part @onExport="onExport" />
    </div>
    <div class="card-body">
      <div class="row">
        <input-part id="purchaseDate" :labelText="`${$t('modal.date')}:`">
          <input
            type="date"
            class="form-control"
            id="purchaseDate"
            v-model="purchase.PurchaseDate"
            required
          />
        </input-part>
        <input-part
          id="purchaseNumber"
          :labelText="
            $route.path.includes('purchasedata')
              ? `${$t('modal.purchasenumber')}:`
              : `${$t('modal.purchasereturnnumber')}`
          "
        >
          <input
            type="text"
            class="form-control"
            id="purchaseNumber"
            v-model="purchase.PurchaseNumber"
            readonly
          />
        </input-part>
        <input-part id="accountDate" :labelText="`${$t('modal.accountdate')}:`">
          <input
            type="date"
            class="form-control"
            id="accountDate"
            v-model="purchase.AccountDate"
            required
          />
        </input-part>
        <input-part id="supplier" :labelText="`${$t('modal.supplier')}:`">
          <div class="row px-3">
            <input
              type="text"
              class="form-control col-12 col-md-6"
              id="supplierNumber"
              :placeholder="`${$t('placeholder.inputsuppliernumberordb')}`"
              v-model="addSupplierNumber"
              @keyup.enter.exact="selectSupplierByKeyword"
              @dblclick="supplierModalTrigger = !supplierModalTrigger"
            />
            <input
              type="text"
              class="form-control col-12 col-md-6"
              id="supplierName"
              readonly
              :value="purchase.SupplierName"
            />
          </div>
        </input-part>
        <input-part id="buyer" :labelText="`${$t('modal.buyer')}:`">
          <div class="row px-3">
            <input
              type="text"
              class="form-control col-12 col-md-6"
              id="buyerNumber"
              :placeholder="`${$t('placeholder.inputbuyernumberordb')}`"
              v-model="addBuyerNumber"
              @keyup.enter.exact="selectBuyerByKeyword"
              @dblclick="buyerModalTrigger = !buyerModalTrigger"
            />
            <input
              type="text"
              class="form-control col-12 col-md-6"
              id="buyerName"
              readonly
              :value="purchase.BuyerName"
            />
          </div>
        </input-part>
        <input-part
          id="defaultWarehouse"
          :labelText="`*${$t('modal.defaultwarehouse')}:`"
        >
          <div class="row px-3">
            <input
              type="text"
              class="form-control col-12 col-md-6"
              id="warehouseNumber"
              :placeholder="`${$t('placeholder.inputwarehousenumberordb')}`"
              v-model="addWarehouseNumber"
              @keyup.enter.exact="selectWarehouseByKeyword"
              @dblclick="warehouseModalTrigger = !warehouseModalTrigger"
            />
            <input
              type="text"
              class="form-control col-12 col-md-6"
              id="warehouseName"
              readonly
              :value="warehouse.WarehouseName"
            />
          </div>
        </input-part>
        <input-part
          id="contactPerson"
          :labelText="`${$t('modal.contactperson')}:`"
        >
          <input
            type="text"
            class="form-control"
            id="contactPerson"
            v-model="purchase.ContactPerson"
          />
        </input-part>
        <input-part id="telephone" :labelText="`${$t('modal.telephone')}:`">
          <input
            type="text"
            class="form-control"
            id="telephone"
            v-model="purchase.Telephone"
          />
        </input-part>
        <input-part
          id="invoiceNumber"
          :labelText="`${$t('modal.invoicenumber')}:`"
        >
          <input
            type="text"
            class="form-control"
            id="invoiceNumber"
            v-model="purchase.InvoiceNumber"
          />
        </input-part>
        <input-part id="invoiceDate" :labelText="`${$t('modal.invoicedate')}:`">
          <input
            type="date"
            class="form-control"
            id="invoiceDate"
            v-model="purchase.InvoiceDate"
          />
        </input-part>
        <input-part id="memo" :labelText="`${$t('modal.memo')}:`" :degree="12">
          <input
            type="text"
            class="form-control"
            id="memo"
            v-model="purchase.Memo"
          />
        </input-part>
        <input-part id="taxType" :labelText="`${$t('modal.taxtype')}:`">
          <div class="row">
            <div class="col-9">
              <select
                class="form-control"
                id="taxType"
                v-model="purchase.TaxType"
                required
              >
                <option
                  v-for="taxType in taxTypes"
                  :key="taxType.value"
                  :value="taxType.value"
                >
                  {{ $t(`option.taxType.${taxType.name}`) }}
                </option>
              </select>
            </div>
            <div class="col-3 custom-control custom-checkbox">
              <input
                type="checkbox"
                class="custom-control-input"
                id="IsTaxAddition"
                v-model="purchase.IsTaxAddition"
              />
              <label class="custom-control-label" for="IsTaxAddition">{{
                $t("modal.istaxaddition")
              }}</label>
            </div>
          </div>
        </input-part>
        <div
          class="form-group col-3 d-flex justify-content-start align-items-end"
        >
          <button
            v-if="hasBatchNumber"
            class="btn btn-outline-primary"
            @click.prevent="autoBatchNumber"
          >
            自動產生批號
          </button>
        </div>
        <div class="col-12">
          <table-part :columns="columns" :noHide="true">
            <template slot="th-before">
              <th style="width: 80px">{{ $t("columnName.remove") }}</th>
              <th style="width: 80px">{{ $t("columnName.index") }}</th>
            </template>
            <tbody slot="tbody">
              <tr v-for="(det, index) in purchaseDets" :key="det.PurchaseDetId">
                <td>
                  <button
                    type="button"
                    class="btn btn-outline-danger btn-icon"
                    @click="removeDet(index)"
                  >
                    <i class="fas fa-trash-alt"></i>
                  </button>
                </td>
                <td>{{ index + 1 }}</td>
                <td>{{ det.FoodNumber }}</td>
                <td>
                  <input
                    class="form-control"
                    type="text"
                    v-model="det.FoodName"
                    @focus="saveDefaultName(det.FoodName)"
                    @blur="checkNewName(det)"
                    required
                  />
                </td>
                <td>
                  <input
                    class="form-control"
                    type="text"
                    v-model="det.WarehouseNumber"
                    @change="updateWarehouse(det)"
                    required
                  />
                </td>
                <td>{{ det.WarehouseName }}</td>
                <td>
                  <input
                    class="form-control text-right"
                    type="number"
                    v-model.number="det.Qty"
                    @input="calculate"
                    @blur="setValue(det, 'Qty')"
                    required
                  />
                </td>
                <td>
                  <input
                    class="form-control text-right"
                    type="number"
                    v-model.number="det.OriginalPrice"
                    @input="calculate"
                    @blur="setValue(det, 'OriginalPrice')"
                    required
                  />
                </td>
                <td class="text-right">{{ det.SalPrice }}</td>
                <td>
                  <input
                    class="form-control text-right"
                    type="number"
                    v-model.number="det.Total"
                    @input="reCalPrice(det)"
                    @focus="isFocused = true"
                    @blur="isFocused = false"
                    required
                  />
                </td>
                <td class="text-right">{{ det.TaxPrice }}</td>
                <td v-if="hasBatchNumber">
                  <input
                    class="form-control"
                    type="text"
                    v-model="det.BatchNumber"
                    :readonly="!det.HasBatchNumber"
                    @dblclick="openBatchSelector(det, index)"
                  />
                </td>
                <td>
                  <input class="form-control" type="text" v-model="det.Memo" />
                </td>
              </tr>
              <tr>
                <td colspan="12">
                  <input
                    class="form-control"
                    type="text"
                    :placeholder="`${$t(
                      'placeholder.inputfoodnumberorbarcode'
                    )}`"
                    v-model="addFoodNumber"
                    @keyup.enter.exact="selectFoodByKeyword"
                    @dblclick="foodModalTrigger = !foodModalTrigger"
                  />
                </td>
              </tr>
              <tr>
                <td>{{ $t("columnName.total") }}:</td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td class="text-right">{{ totalQty }}</td>
                <td></td>
                <td></td>
                <td class="text-right">{{ purchase.Total }}</td>
                <td class="text-right">{{ purchase.TaxPrice }}</td>
                <td></td>
                <td></td>
              </tr>
            </tbody>
          </table-part>
        </div>
      </div>
      <food-selects
        :trigger="foodModalTrigger"
        :isMultiple="true"
        @onSaved="addFoods"
        :parentItems="foodsByKeyword"
        :noSearchType="!hasFoodSearchType"
      />
      <supplier-selects
        :trigger="supplierModalTrigger"
        :isMultiple="false"
        @onSaved="setSupplier"
      ></supplier-selects>
      <warehouse-selects
        :trigger="warehouseModalTrigger"
        :isMultiple="false"
        @onSaved="setDefaultWarehouse"
      ></warehouse-selects>
      <employee-selects
        :trigger="buyerModalTrigger"
        :isMultiple="false"
        @onSaved="setBuyer"
      ></employee-selects>
      <batch-selects
        :trigger="batchModalTrigger"
        :isMultiple="false"
        :warehouseId="currentWarehouseId"
        :foodId="currentFoodId"
        @onSaved="setBatchNumber"
      />
    </div>
    <supplier-data
      :number="supplierNumber"
      :modalTitle="modalTitle"
      :trigger="modalTrigger"
      @onSaved="saveSupplier"
    ></supplier-data>
  </form>
</template>

<script>
import inputPart from "@/components/parts/inputPart";

import importPart from "@/components/ImportPart";
import exportPart from "@/components/ExportPart";

import tablePart from "@/components/parts/tablePart";

import foodSelects from "@/components/selects/FoodSelects";
import supplierSelects from "@/components/selects/SupplierSelects";
import warehouseSelects from "@/components/selects/WarehouseSelects";
import employeeSelects from "@/components/selects/EmployeeSelects";
import batchSelects from "@/components/selects/BatchSelects";

import supplierData from "@/components/datas/SupplierData";

export default {
  data() {
    return {
      taxTypes: this.$enum.taxType.getAll(),
      purchase: this.$func.defaultModel.getPurchase(),
      purchaseDets: [],
      addFoodNumber: "",
      addSupplierNumber: "",
      addBuyerNumber: "",
      addWarehouseNumber: "",
      foodModalTrigger: false,
      supplierModalTrigger: false,
      warehouseModalTrigger: false,
      buyerModalTrigger: false,
      batchModalTrigger: false,
      isReturn: false,
      foodsByKeyword: [],
      hasFoodSearchType: true,
      warehouse: {
        WarehouseName: null,
      },
      roundType: 0,
      detailRoundType: 0,
      currentWarehouseId: "",
      currentFoodId: "",
      currentFoodIndex: null,
      isFocused: false,
      supplier: {},
      modalTrigger: false,
      modalTitle: "",
      supplierNumber: "",
    };
  },
  watch: {
    "purchase.TaxType"(value) {
      if (value != 1) this.purchase.IsTaxAddition = 0;
      this.calculate();
    },
    "purchase.IsTaxAddition"() {
      this.calculate();
    },
  },
  computed: {
    hasBatchNumber() {
      return window.localStorage["hasBatchNumber"] == "true";
    },
    totalQty() {
      if (this.purchaseDets.length > 0)
        return this.purchaseDets
          .map((item) => {
            return item.Qty;
          })
          .reduce((t, q) => {
            return parseInt(t) + parseInt(q);
          });
      else return 0;
    },
    columns() {
      let columns = [
        { name: "FoodNumber" },
        { name: "FoodName" },
        { name: "WarehouseNumber" },
        { name: "WarehouseName" },
        { name: "Qty" },
        { name: "OriginalPrice" },
        { name: "SalPrice", headerText: "平均單價" },
        { name: "Total" },
        { name: "TaxPrice" },
        { name: "Memo" },
      ];
      if (this.hasBatchNumber) {
        columns.splice(-1, 0, { name: "BatchNumber" });
      }
      return columns;
    },
  },
  components: {
    inputPart,
    foodSelects,
    supplierSelects,
    warehouseSelects,
    employeeSelects,
    batchSelects,
    importPart,
    exportPart,
    tablePart,
    supplierData,
  },
  methods: {
    saveSupplier() {
      const vm = this;
      this.$api.suppliers
        .getByNumber(this.supplierNumber)
        .then((data) => {
          vm.setSupplier(data);
        })
        .catch((error) => vm.$func.message.error(error.data.Message));
    },
    setValue(det, type) {
      if (type == "Qty") {
        if (
          isNaN(det.Qty) ||
          det.Qty === "" ||
          det.Qty === null ||
          det.Qty == 0
        ) {
          this.$func.message.alert("數量不可為0 !");
          det.Qty = 1;
          this.calculate();
        }
      } else if (type == "OriginalPrice") {
        if (
          isNaN(det.OriginalPrice) ||
          det.OriginalPrice === "" ||
          det.OriginalPrice === null
        ) {
          det.OriginalPrice = 0;
        }
      }
    },
    selectSupplierByKeyword() {
      if (!this.addSupplierNumber || this.addSupplierNumber.length == 0) {
        this.$func.message.alert("請輸入廠商編號");
        return;
      }
      const vm = this;
      this.$api.suppliers
        .getByNumber(this.addSupplierNumber)
        .then((data) => {
          if (data) {
            vm.setSupplier(data);
          }
        })
        .catch(() => {
          this.$func.message
            .confirm("查無此廠商 !", "是否新增此廠商 ?")
            .then((result) => {
              if (result.isConfirmed) {
                this.supplierNumber = this.addSupplierNumber;
                this.modalTrigger = !this.modalTrigger;
                this.modalTitle = this.$t("modal.addsupplier");
              } else {
                this.setSupplier({
                  SupplierNumber: null,
                  SupplierId: null,
                  SupplierName: null,
                  ContactPerson: null,
                  Telephone: null,
                });
              }
            });
        });
    },
    selectBuyerByKeyword() {
      if (!this.addBuyerNumber || this.addBuyerNumber.length == 0) {
        this.$func.message.alert("請輸入業務員編號");
        return;
      }
      const vm = this;
      this.$api.employees
        .getByNumber(this.addBuyerNumber)
        .then((data) => {
          if (data) {
            vm.setBuyer(data);
          }
        })
        .catch(() => {
          this.$func.message.error("查無此業務員 !");
          this.setBuyer({
            EmployeeNumber: null,
            EmployeeId: null,
            EmployeeName: null,
            DefaultWarehouseId: null,
            DefaultWarehouseNumber: null,
            DefaultWarehouseName: null,
          });
        });
    },
    selectWarehouseByKeyword() {
      if (!this.addWarehouseNumber || this.addWarehouseNumber.length == 0) {
        this.$func.message.alert("請輸入倉庫編號");
        return;
      }
      const vm = this;
      this.$api.warehouses
        .getByNumber(this.addWarehouseNumber)
        .then((data) => {
          if (data) {
            vm.setDefaultWarehouse(data);
          }
        })
        .catch(() => {
          this.$func.message.error("查無此倉庫 !");
          this.setDefaultWarehouse({
            WarehouseId: null,
            WarehouseNumber: null,
            WarehouseName: null,
          });
        });
    },
    autoBatchNumber() {
      if (this.purchaseDets.length > 0) {
        this.purchaseDets.forEach((det) => {
          if (det.HasBatchNumber) {
            det.BatchNumber = this.purchase.PurchaseDate.replace(/-/g, "");
          }
        });
      }
    },
    setBatchNumber(item) {
      this.purchaseDets[this.currentFoodIndex].BatchNumber = item.BatchNumber;
    },
    openBatchSelector(det, index) {
      if (!det.HasBatchNumber) {
        return;
      }
      this.currentWarehouseId = det.WarehouseId;
      this.currentFoodId = det.FoodId;
      this.currentFoodIndex = index;
      this.batchModalTrigger = !this.batchModalTrigger;
    },
    saveDefaultName(name) {
      this.defaultFoodName = name;
    },
    checkNewName(det) {
      if (!det.FoodName) {
        this.$func.message.alert("請輸入商品名稱");
        det.FoodName = this.defaultFoodName;
      }
    },
    showFoodSelect() {
      this.foodsByKeyword = undefined;
      this.hasFoodSearchType = true;
      this.foodModalTrigger = !this.foodModalTrigger;
    },
    addDet(item) {
      this.addFoodNumber = "";
      let det = {
        FoodId: item.FoodId,
        FoodNumber: item.FoodNumber,
        FoodName: item.FoodName,
        WarehouseName: "",
        Qty: 1,
        OriginalPrice: item.Cost,
        HasBatchNumber: item.HasBatchNumber,
        BatchNumber: null,
      };
      if (this.warehouse.WarehouseName) {
        this.setWarehouse(det, this.warehouse);
      }
      this.purchaseDets.push(det);
      this.calculate();
    },
    selectFoodByKeyword() {
      if (!this.addFoodNumber || this.addFoodNumber.length == 0) {
        this.$func.message.alert("請輸入商品編號或條碼");
        return;
      }
      const vm = this;
      this.$api.foods
        .getByKeyword({ IsMatterStock: true, Keyword: this.addFoodNumber })
        .then((data) => {
          if (data) {
            if (data.length > 1) {
              vm.foodsByKeyword = data;
              vm.hasFoodSearchType = false;
              vm.foodModalTrigger = !vm.foodModalTrigger;
            } else {
              vm.addDet(data[0]);
            }
          }
        })
        .catch(() => this.$func.message.error("查無此商品 !"));
    },
    removeDet(index) {
      this.purchaseDets.splice(index, 1);
      this.calculate();
    },
    onSaved() {
      if (!this.purchase.SupplierId || this.purchase.SupplierId.length == 0) {
        this.$func.message.alert("請選擇廠商 !");
        return;
      }
      if (!this.purchase.BuyerId || this.purchase.BuyerId.length == 0) {
        this.$func.message.alert("請選擇採購人 !");
        return;
      }
      if (
        this.purchase.InvoiceNumber &&
        this.purchase.InvoiceNumber.length != 10
      ) {
        this.$func.message.alert("發票格式有誤 !");
        return;
      }
      if (this.purchase.InvoiceNumber == "") {
        this.purchase.InvoiceNumber = null;
      }
      if (this.purchaseDets.length == 0) {
        this.$func.message.alert("請選擇商品 !");
        return;
      }
      const checkWarehouseId = this.purchaseDets.some((det) => {
        return !det.WarehouseId;
      });
      if (checkWarehouseId) {
        this.$func.message.alert("請選擇倉庫 !");
        return;
      }

      let emptyBatch = this.purchaseDets.some((det) => {
        return det.HasBatchNumber && !det.BatchNumber;
      });
      if (emptyBatch) {
        this.$func.message.alert("請設定商品批號 !");
        return;
      }

      if (!Number.isInteger(this.purchase.Total)) {
        this.$func.message.alert("總計必須為整數 !");
        return;
      }

      this.purchase.TradeType = this.$enum.tradeType.getByName(
        this.isReturn ? "PurchaseReturn" : "Purchase"
      ).value;
      const vm = this;
      this.$api.purchases
        .post({
          Purchase: vm.purchase,
          PurchaseDets: vm.purchaseDets,
        })
        .then((data) => {
          if (
            vm.$route.name == "PurchaseAdd" ||
            vm.$route.name == "PurchaseReturnAdd"
          ) {
            vm.$func.message.success(`單號為${data.Purchase.PurchaseNumber}`);
          }
          this.$store.dispatch("setSaveOrClose", true);
          this.$router.push({
            name: this.isReturn ? "PurchaseReturns" : "Purchases",
          });
        })
        .catch((error) => {
          vm.$func.message.error(error.data.Message);
        });
    },
    onClosed() {
      this.$store.dispatch("setSaveOrClose", true);
      this.$router.go(-1);
    },
    setSupplier(item) {
      this.addSupplierNumber = item.SupplierNumber;
      this.purchase.SupplierId = item.SupplierId;
      this.purchase.SupplierName = item.SupplierName;
      this.purchase.ContactPerson = item.ContactPerson;
      this.purchase.Telephone = item.Telephone;
    },
    setBuyer(item) {
      this.addBuyerNumber = item.EmployeeNumber;
      this.purchase.BuyerId = item.EmployeeId;
      this.purchase.BuyerName = item.EmployeeName;
      if (item.DefaultWarehouseId) {
        this.addWarehouseNumber = item.DefaultWarehouseNumber;
        this.warehouse.WarehouseId = item.DefaultWarehouseId;
        this.warehouse.WarehouseNumber = item.DefaultWarehouseNumber;
        this.warehouse.WarehouseName = item.DefaultWarehouseName;
      }

      if (item.DefaultWarehouseId) {
        this.purchaseDets.forEach((det) => {
          if (!det.WarehouseId) {
            this.setWarehouse(det, this.warehouse);
          }
        });
      }
    },
    setWarehouse(item) {
      this.addWarehouseNumber = item.WarehouseNumber;
      this.warehouse.WarehouseId = item.WarehouseId;
      this.warehouse.WarehouseNumber = item.WarehouseNumber;
      this.warehouse.WarehouseName = item.WarehouseName;
    },
    addFoods(items) {
      const vm = this;
      items.forEach(function (item) {
        vm.addDet(item);
      });
    },
    calculate() {
      this.$func.calculateTax(
        this.purchase,
        this.purchaseDets,
        this.roundType,
        this.detailRoundType,
        this.isFocused
      );
    },
    reCalPrice(det) {
      det.OriginalPrice = Math.ceil((det.Total / det.Qty) * 100) / 100;
      this.calculate();
    },
    onExport() {
      this.$api.purchases.getExcelByPost(this.purchaseDets);
    },
    onImport(file) {
      let formData = new FormData();
      formData.append("file", file);
      const vm = this;
      this.$api.purchases.postExcel(formData).then((data) => {
        if (data) {
          data.Items.forEach((item) => {
            vm.pushItem(item);
          });
          vm.calculate();
        }
      });
    },
    pushItem(item) {
      let exist = this.purchaseDets.find((i) => i.FoodId == item.FoodId);
      if (exist) {
        const index = this.purchaseDets.indexOf(exist);
        if (index > -1) {
          this.purchaseDets.splice(index, 1);
        }
      }
      this.purchaseDets.push(item);
    },
    updateWarehouse(det) {
      this.$api.warehouses
        .getByNumber(det.WarehouseNumber)
        .then((data) => {
          if (data) {
            this.setWarehouse(det, data);
          } else {
            this.setWarehouse(det, undefined);
          }
        })
        .catch(() => {
          this.$func.message.alert("查無此資料 !");
          this.setWarehouse(det, undefined);
        });
    },
    setWarehouse(det, warehouse) {
      if (warehouse) {
        det.WarehouseId = warehouse.WarehouseId;
        det.WarehouseNumber = warehouse.WarehouseNumber;
        det.WarehouseName = warehouse.WarehouseName;
      } else {
        det.WarehouseId = undefined;
        det.WarehouseNumber = undefined;
        det.WarehouseName = undefined;
      }
    },
    setDefaultWarehouse(item) {
      this.addWarehouseNumber = item.WarehouseNumber;
      this.warehouse.WarehouseId = item.WarehouseId;
      this.warehouse.WarehouseNumber = item.WarehouseNumber;
      this.warehouse.WarehouseName = item.WarehouseName;
    },
  },
  beforeRouteLeave(to, from, next) {
    const isSaveOrClose = this.$store.state.isSaveOrClose;
    if (isSaveOrClose) {
      next();
    } else {
      const result = confirm("資料尚未儲存，確定離開當前頁面嗎 ?");
      if (result) {
        next();
      } else {
        next(false);
      }
    }
  },
  created() {
    if (this.$route.name.toLowerCase().includes("return")) this.isReturn = true;
    const id = this.$route.params["id"];
    const orderId = this.$route.params["orderid"];
    if (id || orderId) {
      if (id) {
        const vm = this;
        this.$api.purchases
          .get(id)
          .then((data) => {
            vm.addSupplierNumber = data.Purchase.SupplierNumber;
            vm.purchase = data.Purchase;
            vm.purchaseDets = data.PurchaseDets;
            vm.calculate();
            vm.$api.employees.get(data.Purchase.BuyerId).then((item) => {
              vm.addBuyerNumber = item.EmployeeNumber;
              if (item.DefaultWarehouseNumber) {
                vm.addWarehouseNumber = item.DefaultWarehouseNumber;
                vm.warehouse.WarehouseId = item.DefaultWarehouseId;
                vm.warehouse.WarehouseNumber = item.DefaultWarehouseNumber;
                vm.warehouse.WarehouseName = item.DefaultWarehouseName;
              }
            });
          })
          .catch(() => {
            vm.$func.message.alert("查無此筆單據 !");
            vm.$router.go(-1);
          });
      } else if (orderId) {
        const vm = this;
        this.$api.purchases
          .get(orderId)
          .then((data) => {
            vm.addSupplierNumber = data.Purchase.SupplierNumber;
            vm.addBuyerNumber = data.Purchase.BuyerNumber;
            data.Purchase.OrderId = data.Purchase.PurchaseId;
            data.Purchase.PurchaseId = null;
            data.Purchase.Memo = `${this.$t("modal.purchasenumber")}:${
              data.Purchase.PurchaseNumber
            }`;
            data.Purchase.PurchaseNumber = null;
            data.PurchaseDets.forEach((item) => {
              item.PurchaseDetId = null;
            });
            vm.purchase = data.Purchase;
            vm.purchaseDets = data.PurchaseDets;
            vm.calculate();
            vm.$api.employees.get(data.Purchase.BuyerId).then((item) => {
              if (item.DefaultWarehouseNumber) {
                vm.addWarehouseNumber = item.DefaultWarehouseNumber;
                vm.warehouse.WarehouseId = item.DefaultWarehouseId;
                vm.warehouse.WarehouseNumber = item.DefaultWarehouseNumber;
                vm.warehouse.WarehouseName = item.DefaultWarehouseName;
              }
            });
          })
          .catch(() => {
            vm.$func.message.alert("查無此筆採購單 !");
            vm.$router.go(-1);
          });
      }
    } else {
      this.$api.employees.get(window.localStorage["userId"]).then((data) => {
        this.addBuyerNumber = data.EmployeeNumber;
        this.addWarehouseNumber = data.DefaultWarehouseNumber;
        this.purchase.EmployeeId = data.EmployeeId;
        this.purchase.EmployeeName = data.EmployeeName;
        this.purchase.BuyerId = data.EmployeeId;
        this.purchase.BuyerName = data.EmployeeName;
        this.warehouse.WarehouseId = data.DefaultWarehouseId;
        this.warehouse.WarehouseNumber = data.DefaultWarehouseNumber;
        this.warehouse.WarehouseName = data.DefaultWarehouseName;
      });
    }
    this.$api.businesses.getPart().then((data) => {
      this.roundType = data.RoundType;
      this.detailRoundType = data.DetailRoundType;
      this.purchase.IsTaxAddition = data.IsTaxAddition;
    });
  },
};
</script>
