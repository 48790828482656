<template>
  <itemModal
    :isShowModal="isShow"
    :title="modalTitle"
    @onSaved="save"
    @onClosed="close"
    modalClass="modal-lg"
  >
    <div slot="item">
      <div class="row">
        <div class="col-md-6 col-lg-4">
          <div class="form-group">
            <label for="employeeNumber">{{ $t("modal.number") }}</label>
            <input
              type="text"
              class="form-control"
              id="employeeNumber"
              v-model="employee.EmployeeNumber"
              required
            />
          </div>
        </div>
        <div class="col-md-6 col-lg-4">
          <div class="form-group">
            <label for="employeeName">{{ $t("modal.name") }}</label>
            <input
              type="text"
              class="form-control"
              id="employeeName"
              v-model="employee.EmployeeName"
              required
            />
          </div>
        </div>
        <div class="col-md-6 col-lg-4">
          <div class="form-group">
            <label for="storeId">{{ $t("modal.store") }}</label>
            <select
              id="storeId"
              class="form-control"
              v-model="employee.StoreId"
            >
              <option
                v-for="store in stores"
                :key="store.StoreId"
                :value="store.StoreId"
              >
                {{ store.StoreName }}
              </option>
            </select>
          </div>
        </div>
        <div class="col-md-6 col-lg-4">
          <div class="form-group">
            <label for="moduleStoreId">{{ $t("modal.modulestore") }}</label>
            <select
              id="moduleStoreId"
              class="form-control"
              v-model="employee.ModuleStoreId"
            >
              <option
                v-for="moduleStore in moduleStores"
                :key="moduleStore.ModuleId"
                :value="moduleStore.ModuleId"
              >
                {{ moduleStore.ModuleName }}
              </option>
            </select>
          </div>
        </div>
        <div class="col-md-6 col-lg-4" v-if="!isUshowWeb">
          <div class="form-group">
            <label for="password">{{ $t("modal.password") }}</label>
            <input
              type="password"
              class="form-control"
              id="password"
              v-model="employee.Password"
              required
            />
          </div>
        </div>
        <div class="col-md-6 col-lg-4">
          <div class="form-group">
            <label for="birth">{{ $t("modal.birth") }}</label>
            <input
              type="date"
              class="form-control"
              id="birth"
              v-model="employee.Birth"
            />
          </div>
        </div>
        <div class="col-md-6 col-lg-4">
          <div class="form-group">
            <label for="cellphone">{{ $t("modal.cellphone") }}</label>
            <input
              type="text"
              class="form-control"
              id="cellphone"
              v-model="employee.Cellphone"
            />
          </div>
        </div>
        <div class="col-md-6 col-lg-4">
          <div class="form-group">
            <label for="telephone">{{ $t("modal.telephone") }}</label>
            <input
              type="text"
              class="form-control"
              id="telephone"
              v-model="employee.Telephone"
            />
          </div>
        </div>
        <div class="col-md-6 col-lg-4">
          <div class="form-group">
            <label for="email">{{ $t("modal.email") }}</label>
            <input
              type="email"
              class="form-control"
              id="email"
              v-model="employee.Email"
            />
          </div>
        </div>
        <div class="col-md-6 col-lg-4">
          <div class="form-group">
            <label for="identifyCard">{{ $t("modal.identifycard") }}</label>
            <input
              type="text"
              class="form-control"
              id="identifyCard"
              v-model="employee.IdentifyCard"
            />
          </div>
        </div>
        <div class="col-md-6 col-lg-4">
          <div class="form-group">
            <label for="gender">{{ $t("modal.gender") }}</label>
            <input
              type="text"
              class="form-control"
              id="gender"
              v-model="employee.Gender"
            />
          </div>
        </div>
        <div class="col-md-6 col-lg-4">
          <div class="form-group">
            <label for="departmentId">{{ $t("modal.departmentid") }}</label>
            <select
              id="departmentId"
              class="form-control"
              v-model="employee.DepartmentId"
            >
              <option
                v-for="department in departments"
                :key="department.DepartmentId"
                :value="department.DepartmentId"
              >
                {{ department.DepartmentName }}
              </option>
            </select>
          </div>
        </div>
        <div class="col-md-6 col-lg-4" v-if="isERP">
          <div class="form-group">
            <label for="defaultWarehouseId">{{
              $t("modal.defaultwarehouse")
            }}</label>
            <select
              id="defaultWarehouseId"
              class="form-control"
              v-model="employee.DefaultWarehouseId"
            >
              <option
                v-for="warehouse in warehouses"
                :key="warehouse.WarehouseId"
                :value="warehouse.WarehouseId"
              >
                {{ warehouse.WarehouseName }}
              </option>
            </select>
          </div>
        </div>
        <div class="col-md-6 col-lg-4">
          <div class="form-group">
            <label for="maxDiscount">{{ $t("modal.maxdiscount") }}</label>
            <input
              type="number"
              class="form-control"
              id="maxDiscount"
              v-model="employee.MaxDiscount"
              @blur="checkMaxDiscount"
            />
          </div>
        </div>
        <div class="col-md-6 col-lg-4">
          <div class="form-group">
            <label for="maxFree">{{ $t("modal.maxfree") }}</label>
            <input
              type="number"
              class="form-control"
              id="maxFree"
              v-model="employee.MaxFree"
              @blur="checkMaxFree"
            />
          </div>
        </div>
        <div class="col-12">
          <div class="form-group">
            <label for="address">{{ $t("modal.address") }}</label>
            <input
              type="text"
              class="form-control"
              id="address"
              v-model="employee.Address"
            />
          </div>
        </div>
        <div class="col-md-6 col-lg-4" v-if="hasCloudElecInvoice || isUshowWeb">
          <div class="form-group">
            <label for="cloudTerminalId">{{
              $t("modal.cloudterminalid")
            }}</label>
            <select
              id="cloudTerminalId"
              class="form-control"
              v-model="employee.CloudTerminalId"
            >
              <option
                v-for="terminal in terminals"
                :key="terminal.TerminalId"
                :value="terminal.TerminalId"
              >
                {{ terminal.TerminalName }}
              </option>
            </select>
          </div>
        </div>
        <div class="col-md-6 col-lg-4">
          <div class="form-group">
            <label for="employeeType">{{
              $t("dataModalPart.employeetype")
            }}</label>
            <select
              id="employeeType"
              class="form-control"
              v-model="employee.EmployeeType"
            >
              <option
                v-for="employeeType in employeeTypes"
                :key="employeeType.value"
                :value="employeeType.value"
              >
                {{ employeeType.text }}
              </option>
            </select>
          </div>
        </div>
      </div>
    </div>
  </itemModal>
</template>

<script>
import itemModal from "@/components/ItemModal";

export default {
  data() {
    return {
      employee: {},
      stores: [],
      departments: [],
      moduleStores: [],
      terminals: [],
      warehouses: [],
      isERP: window.localStorage["isERP"] == "true",
      employeeTypes: [],
    };
  },
  props: ["isShow", "entity", "modalTitle"],
  watch: {
    currentLang() {
      this.setOptions();
    },
    entity(val) {
      this.employee = val;
    },
  },
  components: {
    itemModal,
  },
  computed: {
    currentLang() {
      return this.$store.state.currentLang;
    },
    isUshowWeb() {
      return window.localStorage["productType"] == 3;
    },
    hasCloudElecInvoice() {
      return window.localStorage["hasCloudElecInvoice"] == "true";
    },
  },
  methods: {
    checkMaxDiscount() {
      if (
        !/[^0-9]/.test(this.employee.MaxDiscount) ||
        this.employee.MaxDiscount < 0 ||
        this.employee.MaxDiscount > 100
      ) {
        this.$func.message.alert("最大折扣需輸入 0 ~ 100 之間的數字");
        this.employee.MaxDiscount = 0;
        return;
      }
    },
    checkMaxFree() {
      if (!/[^0-9]/.test(this.employee.MaxFree) || this.employee.MaxFree < 0) {
        this.$func.message.alert("最大折讓不可小於 0");
        this.employee.MaxFree = 0;
        return;
      }
    },
    setOptions() {
      this.stores[0].StoreName = this.$t("modal.none");
      this.departments[0].DepartmentName = this.$t("modal.none");
      this.moduleStores[0].ModuleName = this.$t("modal.none");
      this.terminals[0].TerminalName = this.$t("modal.none");
      this.warehouses[0].WarehouseName = this.$t("modal.none");
    },
    save() {
      if (this.isUshowWeb) {
        this.employee.Password = this.employee.EmployeeNumber;
      }
      const vm = this;
      this.$api.employees
        .save(vm.employee)
        .then(() => {
          vm.$emit("save");
        })
        .catch((error) => vm.$func.message.error(error.data.Message));
    },
    close() {
      this.$emit("close");
    },
  },
  created() {
    this.employeeTypes = this.$enum.employeeType.getAll();
  },
  mounted() {
    const vm = this;
    this.$api.stores.getNames().then((data) => {
      let none = { StoreId: null, StoreName: `${this.$t("modal.none")}` };
      data.splice(0, 0, none);
      vm.stores = data;
    });
    this.$api.departments.getNames().then((data) => {
      let none = {
        Departmentd: null,
        DepartmentName: `${this.$t("modal.none")}`,
      };
      data.splice(0, 0, none);
      vm.departments = data;
    });
    this.$api.modules.getByType(8).then((data) => {
      let none = { ModuleId: null, ModuleName: `${this.$t("modal.none")}` };
      data.splice(0, 0, none);
      vm.moduleStores = data;
    });
    this.$api.terminals.getNames().then((data) => {
      let none = {
        TemrinalId: null,
        TerminalName: `${this.$t("modal.none")}`,
      };
      data.splice(0, 0, none);
      vm.terminals = data;
    });
    this.$api.warehouses.getNames().then((data) => {
      let none = {
        WarehouseId: null,
        WarehouseName: `${this.$t("modal.none")}`,
      };
      data.splice(0, 0, none);
      vm.warehouses = data;
    });
  },
};
</script>
