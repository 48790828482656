<template>
  <div>
    <h2 v-if="module">{{ module.ModuleName }}</h2>
    <div class="row">
      <div class="col-md-6">
        <h5>{{ $t("title.unselectedfood") }}</h5>
        <div class="card card-table">
          <div class="card-header">
            <button
              type="button"
              class="btn btn-outline-primary btn-icon py-2 px-4"
              @click="onAdd"
            >
              <i class="fa fa-plus"></i>
            </button>
          </div>
          <div class="card-body card-table-body">
            <div class="form-group row px-2">
              <div class="col-12 col-xl-6">
                <div class="row py-1">
                  <label
                    for="foodKindId"
                    class="col-3 col-sm-2 col-md-3 col-lg-2 col-xl-3 col-form-label"
                    >{{ $t("modal.foodkind") }}:</label
                  >
                  <select
                    id="foodKindId"
                    v-if="foodKinds"
                    class="form-control col-7 col-sm-9 col-md-7 col-lg-8 col-xl-9"
                    v-model="currentFoodKindId"
                  >
                    <option
                      v-for="foodKind in foodKinds"
                      :key="foodKind.FoodKindId"
                      :value="foodKind.FoodKindId"
                    >
                      {{ foodKind.FoodKindName }}
                    </option>
                  </select>
                </div>
              </div>
              <div class="col-12 col-xl-6">
                <div class="row py-1">
                  <label
                    for="unselectedFood"
                    class="col-3 col-sm-2 col-md-3 col-lg-2 col-xl-3 col-form-label"
                    >{{ $t("modal.search") }}:</label
                  >
                  <input
                    type="text"
                    v-model="searchUnselectedFood"
                    @keyup.enter.exact="searchUnselectedFoodClick"
                    @blur="searchUnselectedFoodClick"
                    class="col-7 col-sm-9 col-md-7 col-xl-7 form-control"
                  />
                  <div class="col-2 col-sm-1 col-md-2 col-xl-2 px-1">
                    <button
                      class="btn btn-outline-primary"
                      style="border-color: transparent"
                      @click="searchUnselectedFoodClick"
                    >
                      <i class="fas fa-search"></i>
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <!-- <div class="row mb-2 px-3">
              <div class="form-inline">
                <label for="pageSize" class="mx-2">Show</label>
                <select
                  class="form-control-sm"
                  v-model="pagination.PageSize"
                  @change="sizeChanged"
                >
                  <option v-for="size in sizes" :key="size" :value="size">
                    {{ size }}
                  </option>
                </select>

                <label for="pageSize" class="mx-2">entries</label>
              </div>
            </div> -->
            <div class="table-responsive custom-table">
              <table class="table">
                <thead class="thead-responsive">
                  <tr>
                    <th style="width: 20%; border-top: 0">
                      <div class="custom-control custom-checkbox mr-sm-2">
                        <input
                          type="checkbox"
                          class="custom-control-input"
                          id="allAdd"
                          v-model="allAdd"
                        />
                        <label class="custom-control-label" for="allAdd">{{
                          $t("columnName.alladd")
                        }}</label>
                      </div>
                    </th>
                    <th style="width: 35%; border-top: 0">
                      {{ $t("columnName.foodnumber") }}
                    </th>
                    <th style="width: 45%; border-top: 0">
                      {{ $t("columnName.foodname") }}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="notSelected in showFoods"
                    :key="notSelected.FoodId"
                    @click="notSelectedClick(notSelected.FoodId)"
                  >
                    <td>
                      <input
                        class="checkbox"
                        type="checkbox"
                        :value="notSelected.FoodId"
                        v-model="addIds"
                      />
                    </td>
                    <td>{{ notSelected.FoodNumber }}</td>
                    <td>{{ notSelected.FoodName }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <!-- <pagination-part
              style="margin-top: 10px"
              :pagination="pagination"
              @pageChanged="pageChanged"
            ></pagination-part> -->
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <h5>{{ $t("title.selectedfood") }}</h5>
        <div class="card card-table">
          <div class="card-header">
            <button
              type="button"
              class="btn btn-outline-danger btn-icon py-2 px-4"
              @click="onRemove"
            >
              <i class="fas fa-trash-alt"></i>
            </button>
          </div>
          <div class="card-body card-table-body">
            <div class="form-group row px-2">
              <label
                for="foodNumber"
                class="col-3 col-sm-2 col-md-3 col-lg-3 col-xl-2 col-form-label"
                >{{ $t("modal.search") }}:</label
              >
              <input
                type="text"
                v-model="searchSelectedFood"
                @keyup.enter.exact="searchSelectedFoodClick"
                @blur="searchSelectedFoodClick"
                class="col-7 col-sm-9 col-md-7 col-xl-9 form-control"
              />
              <div class="col-2 col-sm-1 col-md-2 col-xl-1 px-1">
                <button
                  class="btn btn-outline-primary"
                  style="border-color: transparent"
                  @click="searchSelectedFoodClick"
                >
                  <i class="fas fa-search"></i>
                </button>
              </div>
            </div>
            <div
              class="table-responsive custom-table"
              :style="
                removeFoods.length > 0
                  ? 'min-height: 111px;'
                  : 'min-height: 66px;'
              "
            >
              <table class="table">
                <thead class="thead-responsive">
                  <tr>
                    <th style="width: 20%">
                      <div class="custom-control custom-checkbox p-0">已選</div>
                    </th>
                    <th style="width: 35%">
                      {{ $t("columnName.foodnumber") }}
                    </th>
                    <th style="width: 45%">{{ $t("columnName.foodname") }}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="removeFood in removeFoods"
                    :key="removeFood.FoodId"
                    @click="unSelectedClick(removeFood)"
                  >
                    <td style="width: 20%">
                      <input
                        class="checkbox"
                        type="checkbox"
                        :value="removeFood.FoodId"
                        checked="checked"
                      />
                    </td>
                    <td style="width: 35%">{{ removeFood.FoodNumber }}</td>
                    <td style="width: 45%">{{ removeFood.FoodName }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div
              class="table-responsive custom-table"
              :style="
                searchSelectedFoods.length > 0
                  ? 'min-height: 111px;'
                  : 'min-height: 66px;'
              "
            >
              <table class="table">
                <thead class="thead-responsive">
                  <tr>
                    <th style="width: 20%">
                      <div class="custom-control custom-checkbox p-0">未選</div>
                    </th>
                    <th style="width: 35%">
                      {{ $t("columnName.foodnumber") }}
                    </th>
                    <th style="width: 45%">{{ $t("columnName.foodname") }}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="searchFood in searchSelectedFoods"
                    :key="searchFood.FoodId"
                    @click="selectedClick(searchFood)"
                  >
                    <td>
                      <input
                        class="checkbox"
                        type="checkbox"
                        :value="searchFood.FoodId"
                      />
                    </td>
                    <td>{{ searchFood.FoodNumber }}</td>
                    <td>{{ searchFood.FoodName }}</td>
                    <!-- <td>{{ searchFood.Price }}</td> -->
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- <food-selects
      :selectedIds="selectedIds"
      :isFoodsFilter="true"
      :defaultItems="notSelecteds"
      :trigger="foodModalTrigger"
      :isMultiple="true"
      @onSaved="addFoods"
    ></food-selects> -->
  </div>
</template>

<script>
// import paginationPart from "@/components/parts/paginationPart";
import foodSelects from "@/components/selects/FoodSelects";

export default {
  data() {
    return {
      pagination: {
        CurrentPage: 1,
        PageNumber: 0,
        PageSize: 100,
        HasPrevious: false,
        HasNext: false,
        Keyword: "",
      },
      sizes: [10, 25, 50, 100, 250, 500],
      showFoods: [],
      module: {},
      foodKinds: [],
      currentFoodKindId: "",
      currentFoodName: null,
      notSelecteds: [],
      addIds: [],
      searchSelectedFoods: [],
      selectedFoods: [],
      removeFoods: [],
      allAdd: false,
      foodModalTrigger: false,
      searchSelectedFood: "",
      searchUnselectedFood: "",
      keyword: "",
    };
  },
  props: ["isShow"],
  components: {
    // paginationPart,
    foodSelects,
  },
  watch: {
    currentFoodKindId() {
      this.updateFoods();
    },
    allAdd(value) {
      this.addIds = [];
      if (value) {
        this.notSelecteds.forEach((item) => this.addIds.push(item.FoodId));
      }
    },
  },
  methods: {
    notSelectedClick(id) {
      const index = this.addIds.indexOf(id);
      if (index >= 0) {
        this.addIds.splice(index, 1);
      } else {
        this.addIds.push(id);
      }
    },
    selectedClick(item) {
      const index = this.searchSelectedFoods.findIndex((food) => {
        return food.FoodId == item.FoodId;
      });
      const index2 = this.selectedFoods.findIndex((food) => {
        return food.FoodId == item.FoodId;
      });
      if (index >= 0) {
        this.searchSelectedFoods.splice(index, 1);
        this.selectedFoods.splice(index2, 1);
        this.removeFoods.push(item);
      }
    },
    unSelectedClick(item) {
      const index = this.removeFoods.findIndex((food) => {
        return food.FoodId == item.FoodId;
      });
      if (index >= 0) {
        this.removeFoods.splice(index, 1);
        this.selectedFoods.push(item);
        if (this.keyword) {
          if (
            item.FoodNumber.toLowerCase().includes(this.keyword) ||
            item.FoodName.toLowerCase().includes(this.keyword)
          ) {
            this.searchSelectedFoods.push(item);
          }
        } else {
          this.searchSelectedFoods.push(item);
        }
      }
    },
    updateFoods() {
      if (this.keyword) {
        this.pagination.Keyword = this.keyword;
      }
      const vm = this;
      this.$api.modules
        .getFoods(vm.module.ModuleId, vm.currentFoodKindId, {
          FoodName: vm.currentFoodName,
          FoodNumber: vm.currentFoodName,
        })
        .then((data) => {
          vm.addIds = [];
          vm.removeIds = [];
          vm.notSelecteds = data.NotSelecteds;
          vm.selectedFoods = [...data.Selecteds];
          vm.searchSelectedFoods = [];
          vm.selectedFoods.forEach((selected) => {
            const index = vm.removeFoods.findIndex((food) => {
              return food.FoodId == selected.FoodId;
            });

            if (vm.keyword) {
              const hasKeyword =
                selected.FoodNumber.toLowerCase().includes(vm.keyword) ||
                selected.FoodName.toLowerCase().includes(vm.keyword);
              if (index < 0 && hasKeyword) {
                vm.searchSelectedFoods.push(selected);
              }
            } else {
              if (index < 0) {
                vm.searchSelectedFoods.push(selected);
              }
            }
          });
          vm.allAdd = false;
          vm.getShowFoods(vm.pagination.CurrentPage);
        });
    },
    onAdd() {
      if (this.addIds.length == 0) {
        this.$func.message.alert("請選擇商品 !");
        return;
      }
      const vm = this;
      this.$api.modules
        .postFoods({
          ModuleId: vm.module.ModuleId,
          FoodIds: vm.addIds,
        })
        .then(() => vm.updateFoods());
    },
    onRemove() {
      if (this.removeFoods.length == 0) {
        this.$func.message.alert("請選擇商品 !");
        return;
      }
      let ids = [];
      this.removeFoods.forEach((food) => {
        ids.push(food.FoodId);
      });
      const vm = this;
      this.$api.modules
        .deleteFoods({
          ModuleId: vm.module.ModuleId,
          FoodIds: ids,
        })
        .then(() => {
          vm.updateFoods();
          vm.removeFoods = [];
        });
    },
    addFoods(items) {
      const vm = this;
      items.forEach((item) => {
        let exist = vm.notSelecteds.findIndex((d) => {
          return d.FoodId == item.FoodId;
        });
        if (exist == -1) {
          vm.notSelecteds.push(item);
          vm.addIds.push(item.FoodId);
        }
      });
    },
    searchSelectedFoodClick() {
      let keyword = this.searchSelectedFood;
      if (keyword) {
        this.searchSelectedFoods = [];
        this.selectedFoods.forEach((food) => {
          if (
            food.FoodNumber.toLowerCase().includes(keyword) ||
            food.FoodName.toLowerCase().includes(keyword)
          ) {
            this.searchSelectedFoods.push(food);
          }
        });
      } else {
        this.searchSelectedFoods = [...this.selectedFoods];
      }
    },
    searchUnselectedFoodClick() {
      let keyword = this.searchUnselectedFood;
      if (keyword) {
        this.showFoods = [];
        this.notSelecteds.forEach((food) => {
          if (
            food.FoodNumber.toLowerCase().includes(keyword) ||
            food.FoodName.toLowerCase().includes(keyword)
          ) {
            this.showFoods.push(food);
          }
        });
      } else {
        this.showFoods = [...this.notSelecteds];
      }
    },
    pageChanged(page) {
      this.pagination.CurrentPage = page;
      this.updateFoods();
    },
    sizeChanged() {
      this.pagination.CurrentPage = 1;
      this.updateFoods();
    },
    getShowFoods(currentPage) {
      this.showFoods = [];
      let start = 0;
      let pageSize = this.pagination.PageSize * currentPage;

      if (currentPage > 1) {
        start = (currentPage - 1) * this.pagination.PageSize;
      }

      if (this.notSelecteds.length > 0) {
        this.pagination.PageNumber = Math.floor(
          this.notSelecteds.length / this.pagination.PageSize
        );
      } else {
        this.pagination.PageNumber = 0;
      }

      if (this.notSelecteds.length % this.pagination.PageSize != 0) {
        this.pagination.PageNumber += 1;
      }

      if (
        currentPage == this.pagination.PageNumber &&
        this.notSelecteds.length % this.pagination.PageSize > 0
      ) {
        pageSize = this.notSelecteds.length;
      }
      if (this.notSelecteds.length > 0) {
        for (let i = start; i < pageSize; i++) {
          this.showFoods.push(this.notSelecteds[i]);
        }
      }

      if (this.pagination.PageNumber <= 1) {
        this.pagination.HasPrevious = false;
        this.pagination.HasNext = false;
      } else {
        if (currentPage == this.pagination.PageNumber)
          this.pagination.HasNext = false;
        else this.pagination.HasNext = true;

        if (currentPage == 1) this.pagination.HasPrevious = false;
        else this.pagination.HasPrevious = true;
      }
    },
  },
  created() {
    const vm = this;
    const id = vm.$route.params["id"];
    this.$api.modules.get(id).then((data) => {
      if (!data) {
        vm.$func.message.alert("查無此模組 !");
        vm.$router.go(-1);
      }
      vm.module = data;
      vm.$api.modules.getFoodKinds(id).then((item) => {
        if (!item) {
          vm.$func.message.alert("查無此模組 !");
          vm.$router.go(-1);
        }
        vm.foodKinds = item.NotSelecteds;
        if (vm.foodKinds.length > 0) {
          vm.currentFoodKindId = vm.foodKinds[0].FoodKindId;
        }
      });
    });
  },
};
</script>
