<template>
  <basic-selects
    :trigger="trigger"
    :isMultiple="isMultiple"
    :columns="columns"
    :items="items"
    title="選擇使用者"
    idProperty="UserId"
    nameProperty="UserName"
    allSelectId="userAllSelectBox"
    @onSaved="onSaved"
    :id="id"
    :pagination="pagination"
    @getItems="getItems"
    :defaultItems="defaultItems"
    viewName="UserSelects"
  ></basic-selects>
</template>

<script>
import basicSelects from "./BasicSelects";

export default {
  data() {
    return {
      items: [],
      columns: [
        {
          name: "UserName",
        },
      ],
      id: "userSelectModal",
      pagination: {},
    };
  },
  props: {
    trigger: false,
    isMultiple: false,
    modalId: undefined,
    defaultItems: undefined,
    roleType: undefined,
  },
  watch: {
    modalId: {
      handler(value) {
        if (value) this.id = value;
      },
      immediate: true,
    },
    trigger() {
      this.getItems();
    },
  },
  components: {
    basicSelects,
  },
  methods: {
    onSaved(item) {
      this.$emit("onSaved", item);
    },
    getItems(dTO) {
      if (this.roleType) {
        dTO = dTO ?? {};
        dTO.RoleType = this.roleType;
      }
      this.$api.users.getPages(dTO).then((data) => {
        this.items = data.Datas;
        this.pagination = data.Pagination;
      });
    },
  },
};
</script>
