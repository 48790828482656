export function get(name) {
  return window.localStorage[name];
}

export function set(name, value) {
  window.localStorage[name] = value;
}

export function setAuth(object) {
  window.localStorage["authorities"] = JSON.stringify(object);
}

export function getAuth(type) {
  let auth = window.localStorage["authorities"];
  if (auth) {
    let ob = JSON.parse(auth);
    for (var i = 0; i < ob.length; i++) {
      if (ob[i].AuthItemType == type) {
        return ob[i];
      }
    }
  }
  return {
    IsList: true,
    IsAdd: true,
    IsUpdete: true,
    IsRemove: true
  };
}

export function getAuthByName(name) {
  let auth = window.localStorage["authorities"];
  if (auth) {
    let ob = JSON.parse(auth);

    let type = -1;
    switch (name) {
      case "FoodKinds":
        type = 0;
        break;
      case "Foods":
        type = 1;
        break;
      case "Employees":
        type = 2;
        break;
      case "Customers":
        type = 3;
        break;
      case "Suppliers":
        type = 4;
        break;
      case "Warehouses":
        type = 5;
        break;
      case "Terminals":
        type = 6;
        break;
      case "Functions":
        type = 11;
        break;
      case "DirectoryKinds":
        type = 12;
        break;
      case "FoodKindStatistic":
        type = 15;
        break;
      case "ReportFree":
        type = 17;
        break;
      case "EmployeeAnalysis":
        type = 18;
        break;
      case "ReportInvoice":
        type = 19;
        break;
      case "FoodSaleStatistic":
        type = 20;
        break;
      case "ReportPeople":
        type = 21;
        break;
      case "ReportCollectionPayment":
        type = 22;
        break;
      case "ReportFoodMovement":
        type = 23;
        break;
      case "ReportPurchase":
        type = 24;
        break;
      case "ReportPurchaseReturn":
        type = 25;
        break;
      case "ModuleAuthority":
        type = 26;
        break;
      case "Sals":
        type = 27;
        break;
      case "SalReturns":
        type = 28;
        break;
      case "Purchases":
        type = 29;
        break;
      case "PurchaseReturns":
        type = 30;
        break;
      case "StockAdjusts":
        type = 31;
        break;
      case "Inventorys":
        type = 32;
        break;
      case "SafeQty":
        type = 33;
        break;
      case "Payments":
        type = 35;
        break;
      case "Receipts":
        type = 36;
        break;
      case "TasteKinds":
        type = 37;
        break;
      case "ComboKinds":
        type = 38;
        break;
      case "ModuleInvisible":
        type = 40;
        break;
      case "ModuleSequence":
        type = 42;
        break;
      case "ModulePromotion":
        type = 43;
        break;
      case "MemberSetting":
        type = 46;
        break;
      case "DayModule":
        type = 61;
        break;
      case "ReportSupplier":
        type = 63;
        break;
      case "Payables":
        type = 65;
        break;
      case "Receivables":
        type = 67;
        break;
      case "PriceLabels":
        type = 68;
        break;
      case "Stock":
        type = 69;
        break;
      case "ReportSafeQty":
        type = 70;
        break;
      case "ReportStockAdjust":
        type = 71;
        break;
      case "ReportPay":
        type = 74;
        break;
      case "ReportSalePay":
        type = 75;
        break;
      case "Stores":
        type = 76;
        break;
      case "TimeStatistic":
        type = 77;
        break;
      case "ModuleFunction":
        type = 83;
        break;
      case "Pays":
        type = 87;
        break;
      case "ReportCustomer":
        type = 89;
        break;
      case "ReportSale":
        type = 90;
        break;
      case "StockTransfers":
        type = 92;
        break;
      case "FoodMajorKinds":
        type = 107;
        break;
      case "FoodMajorKindStatistic":
        type = 108;
        break;
      case "ReportWorkRecord":
        type = 110;
        break;
      case "TasteStatistic":
        type = 111;
        break;
      case "ReportDeposit":
        type = 113;
        break;
      case "ReportClassSale":
        type = 114;
        break;
      case "ReportInventoryPAndL":
        type = 115;
        break;
      case "Departments":
        type = 116;
        break;
      case "ReportIntegral":
        type = 118;
        break;
      case "ReportSaler":
        type = 120;
        break;
      case "PurchaseOrders":
        type = 125;
        break;
      case "ReportFoodSale":
        type = 131;
        break;
      case "ReportPurchaseOrder":
        type = 137;
        break;
      case "ReportSupplierPurchaseOrder":
        type = 138;
        break;
      case "ReportSaleOrder":
        type = 140;
        break;
      case "Accounts":
        type = 141;
        break;
      case "ReportOrderFoodStatistic":
        type = 147;
        break;
      case "FoodDayStatistic":
        type = 149;
        break;
      case "ModuleStore":
        type = 150;
        break;
      case "ReportInventory":
        type = 152;
        break;
      case "ReportCoupon":
        type = 153;
        break;
    }

    if (type >= 0) {
      for (var i = 0; i < ob.length; i++) {
        if (ob[i].AuthItemType == type) {
          return ob[i];
        }
      }
    }
  }

  return {
    IsList: true,
    IsAdd: true,
    IsUpdete: true,
    IsRemove: true
  };
}

export function removeAll() {
  window.localStorage.clear();
}
