import { httpGet, httpPost, httpPut, httpDelete } from "@/request/http";

let path = "/line";

export default {
  get(id) {
    return httpGet(`/devices/${id}${path}-setting`);
  },
  save(id, item) {
    return httpPut(`/devices/${id}${path}-setting`, item);
  },
  remove(id) {
    return httpDelete(`/devices/${id}${path}-image`);
  },
  getBusiness() {
    return httpGet(`/businesses${path}-setting`);
  },
  saveBusiness(item) {
    return httpPut(`/businesses${path}-setting`, item);
  },
  getImage(id) {
    return httpGet(`/devices/${id}${path}-image`);
  },
  saveImage(id, item) {
    return httpPost(`/devices/${id}${path}-image`, item);
  },
};
