<template>
  <form
    :id="modalId"
    class="modal fade"
    tabindex="-1"
    role="dialog"
    data-backdrop="static"
    @submit.prevent="onSaved"
  >
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">{{ $t("title.memberwebimage") }}</h5>
          <button type="button" class="close" @click="onClosed">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body px-4">
          <div class="row">
            <!-- <div class="m-auto w-100 d-flex justify-content-center">
              <span>＊建議圖片大小不超過100KB＊</span>
            </div> -->
            <div class="m-auto py-2">
              <label for="imageFile">
                <div id="upload_zone">
                  <img class="img-web" :src="img" alt="" v-if="img" />
                  <div
                    v-else
                    class="img-web bd-gray bgc-gray d-flex flex-column justify-content-center align-items-center"
                  >
                    <i class="fas fa-image"></i>
                    <div>拖曳或點擊以選擇上傳圖片</div>
                  </div>
                  <input
                    id="imageFile"
                    type="file"
                    @change="onFileChanged"
                    hidden
                  />
                </div>
              </label>
            </div>
            <button type="submit" class="btn btn-primary btn-lg btn-block">
              {{ $t("button.save") }}
            </button>
            <button
              type="button"
              class="btn btn-secondary btn-lg btn-block"
              @click="onCleared"
            >
              {{ $t("button.clear") }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </form>
</template>

<script>
import $ from "jquery";

export default {
  data() {
    return {
      img: "",
      file: "",
      modalId: "memberWebImage",
    };
  },
  props: {
    trigger: false,
  },
  watch: {
    trigger(value) {
      $(`#${this.modalId}`).modal("toggle");
      if (value) {
        window.onbeforeunload = () => {
          return true;
        };
      } else {
        window.onbeforeunload = null;
      }
    },
  },
  methods: {
    onFileChanged(e) {
      if (e.target.files[0].size > 1024000) {
        this.$func.message.alert("圖片大小不可超過1MB !");
        return;
      }

      this.file = e.target.files[0];
      let reader = new FileReader();
      reader.readAsDataURL(e.target.files[0]);
      reader.onload = () => {
        this.img = reader.result;
      };
    },
    loadFile(file) {
      this.file = file;
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        this.img = reader.result;
      };
    },
    onSaved() {
      let formData = new FormData();
      formData.append("file", this.file);
      const vm = this;
      this.$api.businesses.postImage(formData).then(() => {
        vm.$func.message.success("圖片上傳成功 !");
        vm.$emit("onClosed");
      });
    },
    onClosed() {
      $(`#${this.modalId}`).modal("toggle");
      this.$emit("onClosed");
    },
    onCleared() {
      const vm = this;
      this.$api.businesses.deleteImage().then(() => {
        vm.img = "";
        vm.$func.message.success("圖片清除成功 !");
        $(`#${this.modalId}`).modal("toggle");
        vm.$emit("onClosed");
      });
    },
    dropFile(e) {
      e.stopPropagation();
      e.preventDefault();

      const dt = e.dataTransfer;
      const files = dt.files;

      this.file = files[0];
      let reader = new FileReader();
      reader.readAsDataURL(files[0]);
      reader.onload = () => {
        this.img = reader.result;
      };
    },
  },
  mounted() {
    const vm = this;
    this.$api.businesses.getImage().then((data) => {
      if (data.size > 0) {
        vm.file = data;
        let reader = new FileReader();
        reader.readAsDataURL(data);
        reader.onload = () => {
          vm.img = reader.result;
        };
      }
    });
    window.onbeforeunload = () => {
      return true;
    };
    function dragenter(e) {
      e.stopPropagation();
      e.preventDefault();
    }

    function dragover(e) {
      e.stopPropagation();
      e.preventDefault();
    }

    // function drop(e) {
    // }
    const dropbox = document.getElementById("upload_zone");

    dropbox.addEventListener("dragenter", dragenter, false);
    dropbox.addEventListener("dragover", dragover, false);
    dropbox.addEventListener("drop", this.dropFile, false);
  },
};
</script>

<style lang="scss">
.show-modal {
  display: block;
}
.hide-modal {
  display: none !important;
}
</style>
