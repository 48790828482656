export default function (num) {
  if (!num) return null
  let n = parseInt(num)
  if (n == 0 && num == 0) return '$0'
  let hasMinus = false
  if (n < 0) {
    hasMinus = true
    n = -n
  }
  const str = num.toString()
  const deIndex = str.indexOf('.')
  let de = ''
  if (deIndex >= 0) {
    de = str.substring(deIndex, str.length)
  }

  let value = `${n.toFixed(0).replace(/./g, (c, i, a) => {
    const currency = i && c !== '.' && (a.length - i) % 3 === 0 ? `, ${c}`.replace(/\s/g, '') : c
    return currency
  })}`
  if (hasMinus) value = '-' + value

  return '$' + value + de
}
