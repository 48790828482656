<template>
  <div>
    <pageTable
      :parentPage="pagination"
      :items="combos"
      :columns="columns"
      :idProperty="idProperty"
      :nameProperty="nameProperty"
      @currentIndexChanged="currentIndexChanged"
      @getItems="getCombos"
      @addItem="addCombo"
      @editItem="editCombo"
      @removeItem="removeCombo"
      @trdbClicked="editCombo"
      :getTrigger="getTrigger"
      :changeIndex="currentIndex"
      :hasAdd="authority.IsAdd"
      :hasEdit="authority.IsUpdate"
      :hasRemove="authority.IsRemove"
      viewName="combos"
    >
      <template slot="button">
        <button
          type="button"
          class="btn btn-outline-info btn-icon"
          v-if="authority.IsUpdate"
          @click="isShowBatchPriceModal = !isShowBatchPriceModal"
        >
          <i class="fas fa-dollar-sign"></i>
        </button>
        <button
          type="button"
          class="btn btn-outline-primary py-2 px-4"
          style="border-color: transparent"
          v-if="authority.IsUpdate"
          @click="onBefore"
        >
          <i class="fa fa-arrow-up"></i>
        </button>
        <button
          type="button"
          class="btn btn-outline-primary py-2 px-4"
          style="border-color: transparent"
          v-if="authority.IsUpdate"
          @click="onAfter"
        >
          <i class="fa fa-arrow-down"></i>
        </button>
      </template>
      <th class="text-center" slot="th-after" style="width: 120px">
        {{ $t("columnName.isselect") }}
      </th>
      <td class="text-center" slot="td-after" slot-scope="props">
        <span v-if="props.item.IsSelect">V</span>
      </td>
    </pageTable>
    <item-modal
      modalId="kindModal"
      :isShowModal="isShowModal"
      @onClosed="isShowModal = !isShowModal"
      @onSaved="saveCombo"
      :title="combo.FoodName"
    >
      <div slot="item">
        <div class="row">
          <div class="col">
            <div class="form-group row">
              <label for="price" class="col-sm-2 col-form-label">{{
                $t("modal.pricemarkup")
              }}</label>
              <div class="col-sm-10">
                <input
                  type="number"
                  class="form-control"
                  id="price"
                  v-model.number="combo.Price"
                />
              </div>
            </div>
            <div class="form-group row">
              <label for="price" class="col-sm-2 col-form-label">{{
                $t("modal.qty")
              }}</label>
              <div class="col-sm-7">
                <input
                  type="number"
                  class="form-control"
                  id="qty"
                  v-model.number="combo.Qty"
                />
              </div>
              <div class="col-sm-3">
                <div class="form-check form-check-inline">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    id="isSelect"
                    v-model="combo.IsSelect"
                  />
                  <label class="form-check-label" for="isSelect">{{
                    $t("modal.isselect")
                  }}</label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </item-modal>

    <item-modal
      modalId="comboBatchPriceModal"
      :isShowModal="isShowBatchPriceModal"
      @onSaved="saveBatchPrice"
      @onClosed="isShowBatchPriceModal = !isShowBatchPriceModal"
      :title="`${$t('modal.batchprice')}`"
    >
      <div slot="item">
        <div class="row">
          <div class="col">
            <div class="form-group row">
              <label for="salPriceType" class="col-sm-2 col-form-label">{{
                $t("modal.price")
              }}</label>
              <div class="col-sm-10">
                <select
                  class="form-control"
                  id="salPriceType"
                  v-model="batchPrice.SalPriceType"
                >
                  <option
                    v-for="priceType in salePriceTypes"
                    :key="priceType.value"
                    :value="priceType.value"
                  >
                    {{ priceType.text }}
                  </option>
                </select>
              </div>
            </div>
            <div class="form-group row">
              <label for="discountPrice" class="col-sm-2 col-form-label">{{
                $t("modal.discountprice")
              }}</label>
              <div class="col-sm-9">
                <input
                  type="number"
                  class="form-control"
                  id="discountPrice"
                  v-model.number="batchPrice.DiscountPrice"
                />
              </div>
              <label for="discountPrice" class="col-sm-1 col-form-label">{{
                $t("modal.ntd")
              }}</label>
            </div>
          </div>
        </div>
      </div>
    </item-modal>
  </div>
</template>

<script>
import pageTable from "@/components/PageTable";
import itemModal from "@/components/ItemModal";

export default {
  data() {
    return {
      combos: [],
      combo: {},
      pagination: {},
      isShowModal: false,
      isShowBatchPriceModal: false,
      modalTitle: "",
      columns: [
        {
          name: "FoodName",
        },
        {
          name: "FoodNumber",
        },
        {
          name: "Price",
          headerText: "加價",
        },
        {
          name: "Qty",
          headerText: "預選數量",
          class: "text-right",
        },
      ],
      idProperty: "ComboId",
      nameProperty: "FoodName",
      getTrigger: false,
      batchPrice: {},
      salePriceTypes: [],
      currentIndex: -1,
      authority: {},
    };
  },
  props: ["comboKindId"],
  computed: {
    currentLang() {
      return this.$store.state.currentLang;
    },
  },
  watch: {
    currentLang() {
      this.setOptions();
    },
    comboKindId() {
      this.getTrigger = !this.getTrigger;
    },
  },
  components: {
    pageTable,
    itemModal,
  },
  methods: {
    currentIndexChanged(index) {
      this.currentIndex = index;
    },
    getCurrentId() {
      if (this.currentIndex < 0) {
        this.$func.message.alert("請選擇套餐 !");
        return undefined;
      }
      const combo = this.combos[this.currentIndex];
      if (!combo) {
        return undefined;
      } else {
        return combo.ComboId;
      }
    },
    setOptions() {
      const vm = this;
      this.salePriceTypes = this.$enum.salPriceType.getAll();
      this.salePriceTypes.forEach((type) => {
        type.text = vm.$t(`option.${type.name}`);
      });
    },
    getCombos(dTO) {
      if (this.comboKindId == undefined || this.comboKindId.length == 0) return;
      const vm = this;
      this.$api.combos.getPagesByKind(vm.comboKindId, dTO).then((data) => {
        vm.combos = data.Datas;
        vm.pagination = data.Pagination;
      });
    },
    addCombo() {
      if (this.comboKindId == undefined || this.comboKindId.length == 0) {
        this.$func.message.alert("請選擇套餐類別 !");
        return;
      }
      this.$router.push(`/combokinds/${this.comboKindId}/comboadd`);
    },
    editCombo(combo) {
      const vm = this;
      this.$api.combos.get(combo.ComboId).then((data) => {
        vm.combo = data;
        vm.isShowModal = !vm.isShowModal;
        vm.modalTitle = "編輯套餐";
      });
    },
    removeCombo(combo) {
      const vm = this;
      this.$api.combos.remove(combo.ComboId).then(() => {
        vm.getTrigger = !vm.getTrigger;
      });
    },
    saveCombo() {
      const vm = this;
      vm.combo.ComboKindId = vm.comboKindId;
      this.$api.combos
        .save(vm.combo)
        .then(() => {
          vm.isShowModal = !vm.isShowModal;
          vm.getTrigger = !vm.getTrigger;
        })
        .catch((error) => vm.$func.message.error(error.data.Message));
    },
    saveBatchPrice() {
      this.batchPrice.ComboKindId = this.comboKindId;
      const vm = this;
      this.$api.combos
        .postBatchPrice(this.batchPrice)
        .then(() => {
          vm.isShowBatchPriceModal = !vm.isShowBatchPriceModal;
          vm.getTrigger = !vm.getTrigger;
        })
        .catch((error) => vm.$func.message.error(error.data.Message));
    },
    onBefore() {
      const id = this.getCurrentId();
      if (!id) {
        return;
      }
      const vm = this;
      this.$api.combos
        .before({ Id: id, PageSize: this.pagination.PageSize })
        .then((data) => {
          if (data.Pagination.CurrentPage < vm.pagination.CurrentPage) {
            vm.combos = data.Datas;
            vm.currentIndexChanged(data.Pagination.PageSize - 1);
            vm.pagination = data.Pagination;
          } else {
            vm.currentIndexChanged(vm.currentIndex - 1);
          }
          vm.combos = data.Datas;
          vm.pagination = data.Pagination;
        })
        .catch((error) => vm.$func.message.error(error.data.Message));
    },
    onAfter() {
      const id = this.getCurrentId();
      if (!id) {
        return;
      }
      const vm = this;
      this.$api.combos
        .after({ Id: id, PageSize: this.pagination.PageSize })
        .then((data) => {
          if (data.Pagination.CurrentPage > vm.pagination.CurrentPage) {
            vm.combos = data.Datas;
            vm.currentIndexChanged(0);
            vm.pagination = data.Pagination;
          } else {
            vm.currentIndexChanged(vm.currentIndex + 1);
          }
          vm.combos = data.Datas;
          vm.pagination = data.Pagination;
        })
        .catch((error) => vm.$func.message.error(error.data.Message));
    },
  },
  mounted() {
    this.setOptions();
  },
  created() {
    this.authority = this.$func.localStorage.getAuthByName(this.$route.name);
  },
};
</script>
