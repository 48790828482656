<template>
  <item-modal
    :trigger="trigger"
    :title="`${$t('title.customerlevels')}`"
    @onSaved="onSaved"
  >
    <div slot="item">
      <div
        class="form-group row"
        v-for="level in customerLevels"
        :key="level.CustomerLevelId"
      >
        <label
          v-if="currentLang == 'tw'"
          :for="level.CustomerLevelId"
          class="col-sm-2 col-form-label"
          >{{ level.CustomerLevelId }}級:</label
        >
        <label
          v-else-if="currentLang == 'en'"
          :for="level.CustomerLevelId"
          class="col-sm-2 col-form-label"
          >Level{{ level.CustomerLevelId }}:</label
        >
        <div class="col-sm-10">
          <div v-if="type == 'SalPrice'">
            <select
              class="form-control"
              :id="level.CustomerLevelId"
              v-model="level.SalPriceType"
            >
              <option
                v-for="priceType in salePriceTypes"
                :key="priceType.value"
                :value="priceType.value"
              >
                {{ $t(`option.${priceType.name}`) }}
              </option>
            </select>
          </div>
          <div v-else-if="type == 'FreePrice'">
            <input
              class="form-control"
              type="number"
              required
              v-model="level.FreePrice"
              :id="level.CustomerLevelId"
            />
          </div>
          <div v-else-if="type == 'Discount'" class="row">
            <input
              class="form-control col-10"
              type="number"
              required
              v-model="level.Discount"
              :id="level.CustomerLevelId"
            />
            <label class="col-1 col-form-label" :for="level.CustomerLevelId"
              >%</label
            >
          </div>
        </div>
      </div>
    </div>
  </item-modal>
</template>

<script>
import itemModal from "@/components/ItemModal";

export default {
  data() {
    return {
      customerLevels: [],
      salePriceTypes: [],
      type: "",
    };
  },
  computed: {
    currentLang() {
      return this.$store.state.currentLang;
    },
  },
  watch: {
    trigger() {
      const vm = this;
      this.$api.customerLevels.gets().then((data) => {
        vm.customerLevels = data;
      });
    },
    customerSaleType(value) {
      this.type = this.$enum.customerSaleType.getByValue(value).name;
    },
  },
  props: ["trigger", "customerSaleType"],
  components: {
    itemModal,
  },
  methods: {
    setOptions() {
      this.salePriceTypes = this.$enum.salPriceType.getAll();
    },
    onSaved() {
      const vm = this;
      this.$api.customerLevels
        .postBatch(vm.customerLevels)
        .then((data) => vm.$emit("onSaved"));
    },
  },
  mounted() {
    this.setOptions();
  },
};
</script>
