<template>
  <input-inline-part :labelText="title + ':'" :degree="12">
    <select2-part
      v-for="(item, index) in currentItems"
      :key="item[idProperty]"
      :index="index"
      :name="item[nameProperty]"
      @onClicked="remove"
    />
    <button class="btn btn-outline-primary float-right" @click="triggerClick">
      {{ $t("button.select") }}
    </button>
  </input-inline-part>
</template>

<script>
import select2Part from "@/components/parts/select2Part";
import inputInlinePart from "@/components/parts/inputInlinePart";

export default {
  data() {
    return {
      currentItems: [],
    };
  },
  props: ["title", "items", "idProperty", "nameProperty", "searchItems"],
  components: {
    select2Part,
    inputInlinePart,
  },
  watch: {
    searchItems(value) {
      if (JSON.parse(sessionStorage.getItem("isSamePage"))) {
        this.currentItems = value[this.nameProperty.replace("Name", "")];
      }
    },
    items(value) {
      this.currentItems = value;
    },
  },
  methods: {
    triggerClick() {
      this.$emit("triggerClick");
    },
    remove(index) {
      this.$emit("remove", index);
    },
  },
};
</script>
