import { httpGet, httpPost, httpDelete } from '@/request/http'
import func from '@/funcs'

let path = '/foodkinds'

export default {
  get(id) {
    return httpGet(`${path}/${id}`)
  },
  getPages(dTO) {
    return httpGet(`${path}/pages`, dTO)
  },
  getNames() {
    return httpGet(`${path}/names`)
  },
  getSequences(moduleId) {
    return httpGet(`modules/${moduleId}/foodkinds/sequences`)
  },
  save(item) {
    return httpPost(path, item)
  },
  remove(id) {
    return httpDelete(`${path}/${id}`)
  },
  before(moduleId, foodKindId) {
    return httpPost(`/modules/foodkinds/sequences/before`, {
      moduleId,
      foodKindId,
    })
  },
  after(moduleId, foodKindId) {
    return httpPost(`/modules/foodkinds/sequences/after`, {
      moduleId,
      foodKindId,
    })
  },
  getExcel() {
    func.getExcel.get(`${path}/excel`, '商品類別')
  },
  postExcel(formData) {
    return func.postExcel(`${path}/excel`, formData, 180000)
  },
}
