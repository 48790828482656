<template>
  <div>
    <condition-part
      :hasAccountDate="true"
      :hasCustomer="true"
      :hasExcel="true"
      :hasPdf="true"
      :hasAdd="false"
      :hasEdit="false"
      :hasRemove="false"
      @getItems="getItems"
      @downloadExcel="downloadExcel"
      @downloadPdf="downloadPdf"
      @printPdf="printPdf"
      :items="receipts"
      :pagination="pagination"
      :columns="columns"
      :nameProperty="nameProperty"
      :reportTypes="reportTypes"
      :getTrigger="getTrigger"
    />
  </div>
</template>

<script>
import conditionPart from "@/components/parts/conditionPart";

export default {
  data() {
    return {
      receipts: [],
      reportTypes: ["statistic", "simple", "detail"],
      reportType: "statistic",
      pagination: {},
      nameProperty: "ReceiptNumber",
      getTrigger: false,
    };
  },
  components: {
    conditionPart,
  },
  computed: {
    columns() {
      if (this.reportType == "statistic") {
        let s = [
          { name: "CustomerNumber" },
          { name: "CustomerName" },
          { name: "Total" },
          { name: "YTaxPrice" },
          { name: "TaxPrice" },
          { name: "Receivable" },
          { name: "Received" },
        ];
        return s;
      } else if (this.reportType == "simple") {
        let s = [
          { name: "TradeType" },
          { name: "SalNumber" },
          { name: "BusinessDay" },
          { name: "AccountDate" },
          { name: "CustomerNumber" },
          { name: "CustomerName" },
          { name: "Total" },
          { name: "Receivable" },
          { name: "YTaxPrice" },
        ];
        return s;
      } else if (this.reportType == "detail") {
        let s = [
          { name: "TradeType" },
          { name: "SalNumber" },
          { name: "BusinessDay" },
          { name: "CustomerNumber" },
          { name: "CustomerName" },
          { name: "AllTotal" },
          { name: "Receivable" },
          { name: "Received" },
          { name: "Memo" },
          { name: "FoodNumber" },
          { name: "FoodName" },
          { name: "Qty" },
          { name: "SalPrice" },
          { name: "Total" },
          { name: "FoodMemo" },
        ];
        return s;
      }
    },
  },
  methods: {
    getItems(condition) {
      const vm = this;
      this.reportType = condition.ReportType;
      this.$api.receivables.getPages(condition).then((data) => {
        vm.receipts = data.Datas;
        vm.pagination = data.Pagination;
      });
    },
    downloadExcel(condition) {
      this.$api.reportExcels.getReceivable(condition);
    },
    downloadPdf(condition) {
      this.$api.reportPdfs.getReceivable(condition);
    },
    printPdf(condition) {
      this.$api.reportPdfs.printReceivable(condition);
    },
  },
};
</script>
