<template>
  <div>
    <search
      :title="`${$t('modal.supplier')}`"
      :items="suppliers"
      idProperty="SupplierId"
      nameProperty="SupplierName"
      @triggerClick="triggerClick"
      @remove="remove"
      :searchItems="searchItems"
    />
    <supplier-select
      :isMultiple="true"
      :trigger="trigger"
      :defaultItems="defaultItems"
      @onSaved="add"
    />
  </div>
</template>

<script>
import search from "./Search";
import supplierSelect from "@/components/selects/SupplierSelects";

export default {
  data() {
    return {
      trigger: false,
      suppliers: [],
      defaultItems: undefined,
    };
  },
  props: {
    searchItems: {
      type: Object,
      default: () => ({}),
    },
  },
  watch: {
    searchItems(value) {
      if (JSON.parse(sessionStorage.getItem("isSamePage")) && value.Supplier) {
        this.suppliers = value.Supplier;
      }
    },
  },
  components: {
    search,
    supplierSelect,
  },
  methods: {
    triggerClick() {
      this.defaultItems = [...this.suppliers];
      this.trigger = !this.trigger;
    },
    add(items) {
      this.suppliers = items;
      this.set();
    },
    remove(index) {
      if (!this.suppliers[index]) return;
      this.suppliers.splice(index, 1);
      this.set();
    },
    set() {
      this.$emit("set", this.suppliers);
    },
  },
};
</script>
