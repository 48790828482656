<template>
  <div>
    <condition-part
      :hasDate="true"
      :hasEatType="true"
      :hasReportSourceType="true"
      :hasStore="true"
      :hasEmployee="true"
      :hasTerminal="true"
      :hasExcel="true"
      :hasPdf="true"
      :hasAdd="false"
      :hasEdit="false"
      :hasRemove="false"
      :columns="columns"
      :items="items"
      :pagination="pagination"
      :isSortable="true"
      @getItems="getItems"
      @downloadExcel="downloadExcel"
      @downloadPdf="downloadPdf"
      @printPdf="printPdf"
      :reportTypes="reportTypes"
    />
  </div>
</template>

<script>
import conditionPart from "@/components/parts/conditionPart";

export default {
  data() {
    return {
      items: [],
      pagination: {},
      reportTypes: ["simple", "statistic"],
      reportType: "simple",
    };
  },
  computed: {
    columns() {
      if (this.reportType == "simple") {
        return [
          { name: "StoreNumber" },
          { name: "StoreName" },
          { name: "TerminalNumber" },
          { name: "TerminalName" },
          { name: "BusinessDay" },
          { name: "BusinessClass" },
          { name: "SaleCounts" },
          { name: "VoidCounts" },
          { name: "People" },
          { name: "Total" },
          { name: "FreePrice" },
          { name: "DiscountPrice" },
          { name: "Cash" },
          { name: "CreditCardPrice" },
          { name: "PayType1" },
          { name: "PayType2" },
          { name: "Receivable" },
        ];
      } else if (this.reportType == "statistic") {
        return [
          { name: "BusinessClass" },
          { name: "SaleCounts" },
          { name: "VoidCounts" },
          { name: "People" },
          { name: "Total" },
          { name: "FreePrice" },
          { name: "DiscountPrice" },
          { name: "Cash" },
          { name: "CreditCardPrice" },
          { name: "PayType1" },
          { name: "PayType2" },
          { name: "Receivable" },
        ];
      } else return undefined;
    },
  },
  components: {
    conditionPart,
  },
  methods: {
    downloadExcel(condition) {
      this.$api.reportExcels.getClassSale(condition);
    },
    downloadPdf(condition) {
      this.$api.reportPdfs.getClassSale(condition);
    },
    printPdf(condition) {
      this.$api.reportPdfs.printClassSale(condition);
    },
    getItems(condition) {
      this.reportType = condition.ReportType;

      const vm = this;
      this.$api.reports.getClassSale(condition).then((data) => {
        vm.items = data.Datas;
        vm.pagination = data.Pagination;
      });
    },
  },
};
</script>
