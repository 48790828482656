<template>
  <div class="card card-table">
    <div class="card-header">
      <button
        type="button"
        class="btn btn-outline-primary btn-icon"
        @click="getItems"
      >
        <i class="fas fa-search"></i>
      </button>
      <button
        type="button"
        class="btn btn-outline-success btn-icon"
        v-if="hasExcel"
        @click="downloadExcel"
      >
        <i class="fas fa-file-excel"></i>
      </button>
      <button
        type="button"
        class="btn btn-outline-danger btn-icon"
        v-if="hasPdf"
        @click="downloadPdf"
      >
        <i class="fas fa-file-pdf"></i>
      </button>
      <button
        type="button"
        class="btn btn-outline-secondary btn-icon"
        v-if="hasPdf"
        @click="printPdf"
      >
        <i class="fas fa-print"></i>
      </button>

      <slot name="buttonArea"></slot>
    </div>
    <div class="card-body">
      <date-range-part
        v-if="hasDate"
        :date="condition"
        :gridDegree="12"
        :degree="12"
        @dateChanged="dateChanged"
      />

      <div class="form-group row" v-if="hasEatType">
        <label for="eatType" class="col-2 col-lg-1 col-form-label"
          >{{ $t("modal.eattype") }}:</label
        >
        <div class="col-10 col-lg-11">
          <select id="eatType" class="form-control" v-model="condition.EatType">
            <option
              v-for="eatType in eatTypes"
              :key="eatType.value"
              :value="eatType.value"
            >
              {{ eatType.text }}
            </option>
          </select>
        </div>
      </div>

      <div class="form-group row" v-if="hasTimeType">
        <label for="timeType" class="col-2 col-lg-1 col-form-label"
          >{{ $t("modal.timetype") }}:</label
        >
        <div class="col-10 col-lg-11">
          <select
            id="timeType"
            class="form-control"
            v-model="condition.TimeReportType"
          >
            <option
              v-for="timeType in timeTypes"
              :key="timeType.value"
              :value="timeType.value"
            >
              {{ timeType.text }}
            </option>
          </select>
        </div>
      </div>

      <store-search v-if="hasStore" @set="storeChanged" />
      <employee-search v-if="hasEmployee" @set="employeeChanged" />
      <supplier-search v-if="hasSupplier" @set="supplierChanged" />
      <customer-search v-if="hasCustomer" @set="customerChanged" />
      <warehouse-search v-if="hasWarehouse" @set="warehouseChanged" />
      <food-kind-search v-if="hasFoodKind" @set="foodKindChanged" />

      <slot name="searchArea" />
    </div>
  </div>
</template>

<script>
import dateRangePart from "@/components/parts/dateRangePart";
import storeSearch from "@/components/searchs/StoreSearch";
import employeeSearch from "@/components/searchs/EmployeeSearch";
import customerSearch from "@/components/searchs/CustomerSearch";
import supplierSearch from "@/components/searchs/SupplierSearch";
import warehouseSearch from "@/components/searchs/WarehouseSearch";
import foodKindSearch from "@/components/searchs/FoodKindSearch";

export default {
  data() {
    return {
      eatTypes: [],
      timeTypes: [],
      condition: {},
    };
  },
  props: [
    "hasExcel",
    "hasPdf",
    "hasDate",
    "hasStore",
    "hasEmployee",
    "hasSupplier",
    "hasCustomer",
    "hasWarehouse",
    "hasFoodKind",
    "hasFood",
    "hasEatType",
    "hasTimeType",
  ],
  components: {
    dateRangePart,
    storeSearch,
    employeeSearch,
    supplierSearch,
    customerSearch,
    warehouseSearch,
    foodKindSearch,
  },
  watch: {
    hasDate(value) {
      if (value) {
        this.condition.DayBegin = date(Date.now());
        this.condition.DayEnd = date(Date.now());
      }
    },
    hasEatType(value) {
      if (value) {
        this.condition.EatType = -1;
        this.eatTypes = this.$enum.eatSearchType.getAll();
      }
    },
    hasTimeType(value) {
      if (value) {
        this.condition.TimeReportType = 0;
        this.timeTypes = this.$enum.timeType.getAll();
      }
    },
  },
  methods: {
    getItems() {
      this.$emit("getItems", this.condition);
    },
    downloadExcel() {
      this.$emit("downloadExcel", this.condition);
    },
    downloadPdf() {
      this.$emit("downloadPdf", this.condition);
    },
    printPdf() {
      this.$emit("printPdf", this.condition);
    },
    dateChanged(begin, end) {
      this.condition.DayBegin = begin;
      this.condition.DayEnd = end;
    },
    storeChanged(ids) {
      this.condition.StoreIds = ids;
    },
    employeeChanged(ids) {
      this.condition.EmployeeIds = ids;
    },
    supplierChanged(ids) {
      this.condition.SupplierIds = ids;
    },
    customerChanged(ids) {
      this.condition.CustomerIds = ids;
    },
    warehouseChanged(ids) {
      this.condition.WarehouseIds = ids;
    },
    foodKindChanged(ids) {
      this.condition.FoodKindIds = ids;
    },
  },
};
</script>
