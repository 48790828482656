import i18n from "@/langs/i18n";

export function getAll() {
  var items = [
    {
      value: 0,
      name: "Manually",
      text: `${i18n.t("option.customerIdFillType.manually")}`,
    },
    {
      value: 1,
      name: "AutoForDate",
      text: `${i18n.t("option.customerIdFillType.autofordate")}`,
    },
  ];
  return items;
}
