<template>
  <div>
    <condition-part
      :hasDate="true"
      :hasWarehouse="true"
      :items="inventorys"
      :hasAdd="authority.IsAdd"
      :hasEdit="true"
      :hasRemove="true"
      :pagination="pagination"
      :columns="columns"
      @getItems="getItems"
      @addItem="add"
      @editItem="edit"
      @removeItem="remove"
      :nameProperty="nameProperty"
      :getTrigger="getTrigger"
      :isSortable="true"
    >
      <template slot="dataButtons" slot-scope="props">
        <button
          type="button"
          class="btn btn-outline-secondary btn-icon"
          @click="print(props.index)"
        >
          <i class="fas fa-print"></i>
        </button>
        <button
          type="button"
          class="btn btn-outline-primary btn-icon"
          @click="openInventoryModal"
        >
          <i class="fas fa-reply"></i>
        </button>
      </template>
    </condition-part>
    <inventory-selects
      :trigger="inventoryModalTrigger"
      :items="inventorysData"
      :isMultiple="true"
      @onSaved="turnStockAdjust"
    />
  </div>
</template>

<script>
import conditionPart from "@/components/parts/conditionPart";
import inventorySelects from "@/components/selects/InventorySelects";

export default {
  data() {
    return {
      inventorys: [],
      inventorysData: [],
      pagination: {},
      columns: [
        {
          name: "InventoryNumber",
        },
        {
          name: "InventoryDate",
        },
        {
          name: "StockAdjustNumber",
        },
        {
          name: "EmployeeName",
        },
        {
          name: "WarehouseNumber",
        },
        {
          name: "WarehouseName",
        },
        {
          name: "Memo",
        },
        {
          name: "CreateTime",
        },
        {
          name: "UpdateTime",
        },
      ],
      nameProperty: "InventoryNumber",
      getTrigger: false,
      authority: {},
      inventoryModalTrigger: false,
    };
  },
  components: {
    conditionPart,
    inventorySelects,
  },
  methods: {
    getItems(condition) {
      const vm = this;
      this.$api.inventorys.getPages(condition).then((data) => {
        vm.inventorys = data.Datas;
        vm.pagination = data.Pagination;
      });
    },
    add() {
      this.$router.push({ name: "InventoryAdd" });
    },
    edit(item) {
      if (item.StockAdjustId) {
        this.$func.message.alert("此盤點單已無法編輯 !");
        return;
      }
      this.$router.push({
        name: "InventoryEdit",
        params: { id: item.InventoryId },
      });
    },
    remove(item) {
      if (item.StockAdjustId) {
        this.$func.message.alert("此盤點單已無法刪除 !");
        return;
      }
      const vm = this;
      this.$api.inventorys.delete(item.InventoryId).then(() => {
        vm.getTrigger = !vm.getTrigger;
      });
    },
    openInventoryModal() {
      const vm = this;
      this.$api.inventorys.getInventorys().then((data) => {
        vm.inventorysData = data;
        if (vm.inventorysData.length > 0) {
          vm.inventoryModalTrigger = !vm.inventoryModalTrigger;
        } else {
          vm.$func.message.alert("目前沒有可轉調整單的盤點單 !");
        }
      });
    },
    turnStockAdjust(ids) {
      const vm = this;
      this.$api.inventorys
        .turnStockAdjust({ InventoryIds: ids })
        .then((data) => {
          vm.$func.message.success(`${data.Memo}`);
          vm.getTrigger = !vm.getTrigger;
        })
        .catch((error) => {
          vm.$func.message.error(error.data.Message);
        });
    },
    print(index) {
      let inventory = this.inventorys[index];
      if (!inventory) {
        this.$func.message.alert("請選擇一筆盤點單 !");
      } else {
        this.$api.inventorys.print(inventory.InventoryId);
      }
    },
  },
  created() {
    this.authority = this.$func.localStorage.getAuthByName(this.$route.name);
  },
};
</script>
