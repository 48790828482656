<template>
  <div class="row">
    <div class="col-12 py-2" v-if="title">
      <h4 class="d-inline-block align-middle">
        {{ title }}
      </h4>
    </div>
    <div class="col-12">
      <h5>{{ $t("title.specification") }}</h5>
      <page-table
        :items="specificationTypes"
        :columns="columns"
        :idProperty="idProperty"
        :nameProperty="nameProperty"
        @getItems="getSpecifications"
        @addItem="addSpecification"
        @editItem="editSpecification"
        @removeItem="removeSpecification"
        @trdbClicked="editSpecification"
        @currentIndexChanged="currentIndexChanged"
        :getTrigger="getTrigger"
        :changeIndex="currentIndex"
        :hasAdd="!hasShowmore && !hasDigiwin"
        :hasEdit="!hasShowmore && !hasDigiwin"
        :hasRemove="!hasShowmore && !hasDigiwin"
        :hasPage="false"
        :isSortable="false"
      >
      </page-table>
    </div>
    <div class="col-12">
      <h5>{{ $t("title.food") }}</h5>
      <specification-foods
        :specifications="specifications"
        :specificationId="currentKindId"
        @getItems="getSpecifications"
      />
    </div>

    <itemModal
      :isShowModal="isShowModal"
      modalClass="modal-lg"
      @onClosed="isShowModal = !isShowModal"
      @onSaved="saveSpecification"
      :title="modalTitle"
    >
      <div slot="item">
        <div class="row">
          <div class="col-12">
            <div class="form-group row">
              <label
                for="specificationTypeName"
                class="col-form-label col-3 col-lg-2"
                >{{ $t("modal.specificationtypename") }}</label
              >
              <input
                type="text"
                class="form-control col-6 col-lg-8"
                id="specificationTypeName"
                v-model="specificationType.SpecificationTypeName"
                required
              />
            </div>
          </div>
          <div class="col-12">
            <div class="form-group row">
              <label
                for="specificationName"
                class="col-form-label col-3 col-lg-2"
                >{{ $t("modal.specificationname") }}</label
              >
              <div
                class="col-6 col-lg-8"
                style="
                  display: block;
                  width: 100%;
                  min-height: calc(1.5em + 0.75rem + 2px);
                  padding: 0.375rem 0.75rem;
                  font-size: 1rem;
                  font-weight: 400;
                  line-height: 1.5;
                  color: #495057;
                  background-color: #fff;
                  background-clip: padding-box;
                  border: 1px solid #ced4da;
                  border-radius: 0.25rem;
                "
                id="specificationName"
              >
                <div
                  v-for="(s, index) in newSpecificationDetails"
                  :key="index"
                  style="
                    position: relative;
                    border: 1px solid #999;
                    width: 100%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                  "
                  :style="index > 0 ? 'margin-top: 5px' : ''"
                >
                  {{ s.SpecificationName }}
                  <div
                    style="
                      cursor: pointer;
                      position: absolute;
                      right: 0px;
                      padding: 0 8px;
                    "
                    @click="removeNewSepcification(index)"
                  >
                    <i class="fas fa-times"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-12">
            <div class="form-group row">
              <div class="col-3 col-lg-2"></div>
              <input
                type="text"
                class="form-control col-6 col-lg-8"
                id="newSpecification"
                v-model="newSpecification"
                @focus="addKeydownEnter"
                @blur="removeKeydownEnter"
              />
              <div class="col-3 col-lg-2">
                <div
                  class="btn btn-outline-primary"
                  @click="addNewSpecification"
                >
                  {{ $t("button.add") }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </itemModal>
  </div>
</template>

<script>
import pageTable from "@/components/PageTable";
import itemModal from "@/components/ItemModal";
import specificationFoods from "@/components/datas/SpecificationFoods";

export default {
  data() {
    return {
      specificationTypes: [],
      specifications: [],
      specification: {},
      title: "",
      currentKindId: "",
      isShowModal: false,
      modalTitle: "規格設定",
      columns: [
        {
          name: "SpecificationTypeName",
        },
        {
          name: "SpecificationName",
        },
      ],
      idProperty: "SpecificationId",
      nameProperty: "SpecificationName",
      getTrigger: false,
      currentIndex: -1,
      authority: {},
      foodId: "",
      newSpecification: "",
      specificationType: {
        SpecificationTypeName: "",
      },
      specificationDetails: [],
      newSpecificationDetails: [],
    };
  },
  components: {
    pageTable,
    itemModal,
    specificationFoods,
  },
  computed: {
    hasDigiwin() {
      return window.localStorage["erpType"] == 3;
    },
    hasShowmore() {
      return window.localStorage["hasShowmore"] == "true";
    },
  },
  methods: {
    keydownEnter(e) {
      if (e.key == "Enter" && this.newSpecification) {
        e.preventDefault();
        this.addNewSpecification();
      }
    },
    addKeydownEnter() {
      document.addEventListener("keydown", this.keydownEnter);
    },
    removeKeydownEnter() {
      document.removeEventListener("keydown", this.keydownEnter);
    },
    addNewSpecification() {
      if (!this.newSpecification) return;
      this.newSpecificationDetails.push({
        SpecificationName: this.newSpecification,
      });
      this.newSpecification = "";
    },
    removeNewSepcification(index) {
      this.newSpecificationDetails.splice(index, 1);
    },
    currentIndexChanged(index) {
      this.currentIndex = index;
      if (this.currentIndex < 0) {
        this.currentKindId = null;
      }
      const specification = this.specificationTypes[this.currentIndex];
      if (!specification) {
        this.currentKindId = null;
      } else {
        this.currentKindId = specification.SpecificationId;
      }
    },
    getSpecifications() {
      this.$api.foods.getFoodSpecifications(this.foodId).then((data) => {
        this.specifications = data;
        this.title = data.MainSpecificationFood.FoodName;
        let specificationGroups = data.SpecificationGroups;
        this.specificationTypes = [];
        specificationGroups.forEach((s) => {
          let newItem = {};
          let text = "";

          s.Specifications.forEach((spec, index) => {
            if (index == 0) {
              text = spec.SpecificationName;
            } else {
              text = text + ", " + spec.SpecificationName;
            }
          });
          newItem = {
            SpecificationTypeName: s.SpecificationType.SpecificationTypeName,
            SpecificationName: text,
          };
          this.specificationTypes.push(newItem);
        });
      });
    },
    addSpecification() {
      this.specificationType = { SpecificationTypeName: "" };
      this.newSpecificationDetails = [];
      this.isShowModal = !this.isShowModal;
    },
    editSpecification(spec) {
      this.newSpecificationDetails = [];
      let selectedSpecification = this.specifications.SpecificationGroups.find(
        (s) =>
          s.SpecificationType.SpecificationTypeName ==
          spec.SpecificationTypeName
      );
      this.specificationType = selectedSpecification.SpecificationType;
      selectedSpecification.Specifications.forEach((s) => {
        this.newSpecificationDetails.push(s);
      });
      this.isShowModal = !this.isShowModal;
    },
    removeSpecification() {
      this.$api.foods
        .removeSpecification(
          this.specifications.SpecificationGroups[this.currentIndex]
            .SpecificationType.SpecificationTypeId
        )
        .then(() => {
          this.getTrigger = !this.getTrigger;
        });
    },
    saveSpecification() {
      if (this.newSpecificationDetails.length == 0) {
        this.$func.message.alert("至少需要設定一種規格 !");
        return;
      }
      this.specificationType.FoodId = this.foodId;
      let specificationDTO = {
        SpecificationType: this.specificationType,
        Specifications: this.newSpecificationDetails,
      };
      this.$api.foods
        .saveSpecification(specificationDTO)
        .then(() => {
          this.getSpecifications();
          this.isShowModal = !this.isShowModal;
        })
        .catch((error) => {
          this.$func.message.error(error.data.Message);
        });
    },
  },
  created() {
    this.foodId = this.$route.params.id;
    this.authority = this.$func.localStorage.getAuthByName(this.$route.name);
  },
};
</script>
