import { httpGet, httpPost, httpDelete } from "@/request/http";

let path = "/tablemaps";

export default {
  getByFloor(id) {
    return httpGet(`floormaps/${id}/tablemaps`);
  },
  getPages(id) {
    return httpGet(`/terminals/${id}${path}`);
  },
  save(item) {
    return httpPost(path, item);
  },
  remove(id) {
    return httpDelete(`${path}/${id}`);
  },
  getTables(id) {
    return httpGet(`/terminals/${id}${path}`);
  },
  getQRcode(terminalId, tableMapId) {
    return httpGet(`/onlineorders/${terminalId}/instore/tables/${tableMapId}`);
  },
};
