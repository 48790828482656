<template>
  <form
    id="elecInvoiceform"
    class="modal fade"
    tabindex="-1"
    role="dialog"
    data-backdrop="static"
    @submit.prevent="onSaved"
  >
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">{{ $t("modal.elecinvoice") }}</h5>
          <button type="button" class="close" @click="onClosed">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-12">
              <div class="form-group">
                <label for="eleInvoiceCompanyName">{{
                  $t("modal.companyname")
                }}</label>
                <input
                  type="text"
                  class="form-control"
                  id="eleInvoiceCompanyName"
                  v-model="elecInvoice.CompanyName"
                  required
                />
              </div>
            </div>
            <div class="col-12">
              <div class="form-group">
                <label for="eleInvoiceCompanyAddress">{{
                  $t("modal.companyaddress")
                }}</label>
                <input
                  type="text"
                  class="form-control"
                  id="eleInvoiceCompanyAddress"
                  v-model="elecInvoice.CompanyAddress"
                  required
                />
              </div>
            </div>
            <div class="col-12 elec-invoice-switches-container">
              <div
                class="d-flex justify-content-center align-items-center elec-invoice-switches"
              >
                <div
                  class="d-flex justify-content-center align-items-center elec-invoice-switch active"
                  @click="changeEleInvoice($event, 0)"
                >
                  發票抬頭顯示
                </div>
                <div
                  class="d-flex justify-content-center align-items-center elec-invoice-switch"
                  @click="changeEleInvoice($event, 1)"
                >
                  發票印章顯示
                </div>
                <div class="elec-invoice-switch-bar"></div>
              </div>
            </div>
            <div v-if="elecInvoiceType == 0" class="col-12">
              <div class="form-group">
                <select
                  id="elecInvoiceTitleType"
                  class="form-control"
                  v-model="elecInvoiceTitleType"
                >
                  <option
                    v-for="elecInvoiceTitleType in elecInvoiceTitleTypes"
                    :key="elecInvoiceTitleType.value"
                    :value="elecInvoiceTitleType.value"
                  >
                    {{ elecInvoiceTitleType.text }}
                  </option>
                </select>
              </div>
            </div>
            <div v-else-if="elecInvoiceType == 1" class="col-12">
              <div class="form-group">
                <select
                  id="elecInvoiceTitleType"
                  class="form-control"
                  v-model="invoiceStampType"
                >
                  <option
                    v-for="elecInvoiceTitleType in elecInvoiceTitleTypes"
                    :key="elecInvoiceTitleType.value"
                    :value="elecInvoiceTitleType.value"
                  >
                    {{ elecInvoiceTitleType.text }}
                  </option>
                </select>
              </div>
            </div>
            <div
              v-if="elecInvoiceType == 0 && elecInvoiceTitleType == 0"
              class="col-12"
            >
              <div class="form-group">
                <input
                  type="text"
                  class="form-control"
                  id="eleInvoiceTitle"
                  v-model="elecInvoice.Title"
                  required
                />
              </div>
            </div>
            <div
              v-else-if="elecInvoiceType == 0 && elecInvoiceTitleType == 1"
              class="col-12"
            >
              <div class="d-flex justify-content-center align-items-center">
                <label for="invoiceImageFile" class="invoice-image-label">
                  <div>
                    <img
                      v-if="invoiceImage"
                      class="img-invoice border-light-gray"
                      :src="invoiceImage"
                      alt=""
                    />
                    <div
                      v-else
                      class="img-invoice border-gray d-flex justify-content-center align-items-center"
                    >
                      <span>{{ $t("placeholder.chooseanimage") }}</span>
                    </div>
                    <input
                      id="invoiceImageFile"
                      type="file"
                      @change="onFileChanged"
                      hidden
                      accept=".jpg,.jpeg,.png"
                    />
                  </div>
                </label>
              </div>
            </div>
            <div
              v-else-if="elecInvoiceType == 1 && invoiceStampType == 1"
              class="col-12"
            >
              <div class="d-flex justify-content-center align-items-center">
                <label for="invoiceStampImageFile" class="invoice-image-label">
                  <div>
                    <img
                      v-if="invoiceStampImage"
                      class="img-invoice border-light-gray"
                      :src="invoiceStampImage"
                      alt=""
                    />
                    <div
                      v-else
                      class="img-invoice border-gray d-flex justify-content-center align-items-center"
                    >
                      <span>{{ $t("placeholder.chooseanimage") }}</span>
                    </div>
                    <input
                      id="invoiceStampImageFile"
                      type="file"
                      @change="onStampFileChanged"
                      hidden
                      accept=".jpg,.jpeg,.png"
                    />
                  </div>
                </label>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button type="submit" class="btn btn-primary">
            {{ $t("button.save") }}
          </button>
          <button type="button" class="btn btn-secondary" @click="onClosed">
            {{ $t("button.cancel") }}
          </button>
        </div>
      </div>
    </div>
  </form>
</template>

<script>
import $ from "jquery";

export default {
  data() {
    return {
      elecInvoiceTitleTypes: this.$enum.elecInvoiceTitleType.getAll(),
      elecInvoiceType: 0,
      elecInvoiceTitleType: null,
      invoiceStampType: null,
      invoiceImage: "",
      file: "",
      invoiceStampImage: "",
      stampFile: "",
    };
  },
  props: {
    formTrigger: false,
    elecInvoice: {},
    terminalId: String,
  },
  watch: {
    formTrigger(value) {
      if (value) {
        const bar = document.querySelector(".elec-invoice-switch-bar");
        const switches = document.querySelectorAll(".elec-invoice-switch");
        this.elecInvoiceType = 0;
        switches.forEach((s) => {
          s.classList.remove("active");
        });
        switches[0].classList.add("active");
        bar.style.left = 0;
        this.elecInvoiceTitleType = this.elecInvoice.ElecInvoiceTitleType;
        this.invoiceStampType = this.elecInvoice.InvoiceStampType;
        $(`#elecInvoiceform`).modal("toggle");
      } else {
        $(`#elecInvoiceform`).modal("toggle");
      }
    },
    elecInvoiceType(value) {
      if (value == 1 && this.invoiceStampType == 1 && !this.stampFile) {
        this.getStampImage();
      }
    },
    elecInvoiceTitleType(value) {
      if (value == 1 && this.elecInvoiceType == 0 && !this.file) {
        this.getImage();
      }
    },
    invoiceStampType(value) {
      if (value == 1 && this.elecInvoiceType == 1 && !this.stampFile) {
        this.getStampImage();
      }
    },
  },
  methods: {
    getImage() {
      const vm = this;
      this.$api.terminals.getImage(this.terminalId).then((data) => {
        if (data.size > 10) {
          vm.file = data;
          let reader = new FileReader();
          reader.readAsDataURL(data);
          reader.onload = () => {
            vm.invoiceImage = reader.result;
          };
        }
      });
    },
    getStampImage() {
      const vm = this;
      this.$api.terminals.getStampImage(this.terminalId).then((data) => {
        if (data.size > 10) {
          vm.stampFile = data;
          let reader = new FileReader();
          reader.readAsDataURL(data);
          reader.onload = () => {
            vm.invoiceStampImage = reader.result;
          };
        }
      });
    },
    onFileChanged(e) {
      if (e.target.files[0]) {
        let reader = new FileReader();
        let fileSize = e.target.files[0].size / 1024;
        if (fileSize > 150) {
          this.$func.message.alert(
            `${this.$t("funcMsg.imagesizecantover150kb")}`
          );
          document.getElementById("invoiceImageFile").value = "";
          return;
        }
        reader.readAsDataURL(e.target.files[0]);
        reader.onload = () => {
          this.file = e.target.files[0];
          this.invoiceImage = reader.result;

          let formData = new FormData();
          formData.append("file", this.file);
          const vm = this;
          this.$api.terminals
            .postImage(vm.terminalId, formData)
            .then(() => {
              vm.$func.message.success(
                `${this.$t("funcMsg.imageupdatesuccess")} !`
              );
            })
            .catch((error) => {
              vm.$func.message.error(error.data.Message);
            });
        };
      }
    },
    onStampFileChanged(e) {
      if (e.target.files[0]) {
        let reader = new FileReader();
        let fileSize = e.target.files[0].size / 1024;
        if (fileSize > 150) {
          this.$func.message.alert(
            `${this.$t("funcMsg.imagesizecantover150kb")}`
          );
          document.getElementById("invoiceImageFile").value = "";
          return;
        }
        reader.readAsDataURL(e.target.files[0]);
        reader.onload = () => {
          this.stampFile = e.target.files[0];
          this.invoiceStampImage = reader.result;

          let formData = new FormData();
          formData.append("file", this.stampFile);
          const vm = this;
          this.$api.terminals
            .postStampImage(vm.terminalId, formData)
            .then(() => {
              vm.$func.message.success(
                `${this.$t("funcMsg.imageupdatesuccess")} !`
              );
            })
            .catch((error) => {
              vm.$func.message.error(error.data.Message);
            });
        };
      }
    },
    changeEleInvoice(e, type) {
      const bar = document.querySelector(".elec-invoice-switch-bar");
      const switches = document.querySelectorAll(".elec-invoice-switch");
      switches.forEach((s) => {
        s.classList.remove("active");
      });
      e.target.classList.add("active");
      if (type == 0) {
        bar.style.left = "0";
        this.elecInvoiceType = 0;
      } else if (type == 1) {
        bar.style.left = "50%";
        this.elecInvoiceType = 1;
      }
    },
    onSaved() {
      const vm = this;
      this.elecInvoice.ElecInvoiceTitleType = this.elecInvoiceTitleType;
      this.elecInvoice.InvoiceStampType = this.invoiceStampType;
      this.$api.terminals
        .saveElecInvoice(this.elecInvoice)
        .then(() => {
          this.onClosed();
        })
        .catch((error) => vm.$func.message.error(error.data.Message));
    },
    onClosed() {
      this.$emit("onClosed");
    },
  },
};
</script>
