import i18n from "@/langs/i18n";

export function getAll() {
  return [
    {
      value: 0,
      name: "Level0",
      text: `${i18n.t("option.degreeType.Level0")}`,
    },
    {
      value: 1,
      name: "Level1",
      text: `${i18n.t("option.degreeType.Level1")}`,
    },
    {
      value: 2,
      name: "Level2",
      text: `${i18n.t("option.degreeType.Level2")}`,
    },
    {
      value: 3,
      name: "Level3",
      text: `${i18n.t("option.degreeType.Level3")}`,
    },
    {
      value: 4,
      name: "Level4",
      text: `${i18n.t("option.degreeType.Level4")}`,
    },
    {
      value: 5,
      name: "Level5",
      text: `${i18n.t("option.degreeType.Level5")}`,
    },
    {
      value: 6,
      name: "Level6",
      text: `${i18n.t("option.degreeType.Level6")}`,
    },
  ];
}
