<template>
  <div>
    <pageTable
      :parentPage="pagination"
      @getItems="gets"
      @addItem="add"
      @editItem="edit"
      @removeItem="remove"
      @trdbClicked="edit"
      :items="priceLabels"
      :columns="columns"
      :idProperty="idProperty"
      :nameProperty="nameProperty"
      :getTrigger="getTrigger"
      :hasAdd="authority.IsAdd"
      :hasEdit="authority.IsUpdate"
      :hasRemove="authority.IsRemove"
      :isSortable="true"
    >
    </pageTable>

    <itemModal
      :isShowModal="isShowModal"
      @onClosed="isShowModal = !isShowModal"
      @onSaved="save"
      :title="modalTitle"
    >
      <div slot="item">
        <div class="row">
          <div class="col-md-6">
            <div class="form-group">
              <label for="identifier">{{ $t("modal.identifier") }}</label>
              <input
                type="text"
                class="form-control"
                id="identifier"
                v-model="priceLabel.Identifier"
                required
              />
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label for="totalLength">{{ $t("modal.totallength") }}</label>
              <input
                type="number"
                class="form-control"
                id="totalLength"
                v-model="priceLabel.TotalLength"
                required
              />
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label for="priceLabelType">{{
                $t("modal.pricelabeltype")
              }}</label>
              <select
                class="form-control"
                v-model="priceLabel.PriceLabelType"
                id="priceLabelType"
              >
                <option
                  v-for="priceLabelType in priceLabelTypes"
                  :key="priceLabelType.value"
                  :value="priceLabelType.value"
                >
                  {{ priceLabelType.text }}
                </option>
              </select>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label for="foodPosition">{{ $t("modal.foodposition") }}</label>
              <input
                type="number"
                class="form-control"
                id="foodPosition"
                v-model="priceLabel.FoodPosition"
                required
              />
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label for="foodLength">{{ $t("modal.foodlength") }}</label>
              <input
                type="number"
                class="form-control"
                id="foodLength"
                v-model="priceLabel.FoodLength"
                required
              />
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label for="pricePosition">{{ $t("modal.priceposition") }}</label>
              <input
                type="number"
                class="form-control"
                id="pricePosition"
                v-model="priceLabel.PricePosition"
                required
              />
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label for="priceLength">{{ $t("modal.pricelength") }}</label>
              <input
                type="number"
                class="form-control"
                id="priceLength"
                v-model="priceLabel.PriceLength"
                required
              />
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label for="priceDecimalNumber">{{
                $t("modal.pricedecimalnumber")
              }}</label>
              <input
                type="number"
                class="form-control"
                id="priceDecimalNumber"
                v-model="priceLabel.PriceDecimalNumber"
                required
              />
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label for="qtyPosition">{{ $t("modal.qtyposition") }}</label>
              <input
                type="number"
                class="form-control"
                id="qtyPosition"
                v-model="priceLabel.QtyPosition"
                required
              />
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label for="qtyLength">{{ $t("modal.qtylength") }}</label>
              <input
                type="number"
                class="form-control"
                id="qtyLength"
                v-model="priceLabel.QtyLength"
                required
              />
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label for="qtyDecimalNumber">{{
                $t("modal.qtydecimalnumber")
              }}</label>
              <input
                type="number"
                class="form-control"
                id="qtyDecimalNumber"
                v-model="priceLabel.QtyDecimalNumber"
                required
              />
            </div>
          </div>
        </div>
      </div>
    </itemModal>
  </div>
</template>

<script>
import pageTable from "@/components/PageTable";
import itemModal from "@/components/ItemModal";
import importPart from "@/components/ImportPart";
import exportPart from "@/components/ExportPart";

export default {
  data() {
    return {
      priceLabelTypes: [],
      priceLabels: [],
      priceLabel: {},
      pagination: {},
      isShowModal: false,
      modalTitle: "",
      columns: [
        {
          name: "Identifier",
        },
        {
          name: "TotalLength",
        },
        {
          name: "PriceLabelType",
        },
        {
          name: "FoodPosition",
        },
        {
          name: "FoodLength",
        },
        {
          name: "PricePosition",
        },
        {
          name: "PriceLength",
        },
        {
          name: "PriceDecimalNumber",
        },
        {
          name: "QtyPosition",
        },
        {
          name: "QtyLength",
        },
        {
          name: "QtyDecimalNumber",
        },
      ],
      idProperty: "PriceLabelId",
      nameProperty: "Identifier",
      getTrigger: false,
      authority: {},
    };
  },
  components: {
    pageTable,
    itemModal,
    importPart,
    exportPart,
  },
  computed: {
    currentLang() {
      return this.$store.state.currentLang;
    },
  },
  watch: {
    currentLang() {
      this.setOptions();
    },
  },
  methods: {
    setOptions() {
      this.priceLabelTypes = this.$enum.priceLabelType.getAll();
      const vm = this;
      this.priceLabelTypes.forEach((type) => {
        type.text = vm.$t(`option.${type.name}`);
      });
    },
    gets(dTO) {
      const vm = this;
      this.$api.priceLabels.getPages(dTO).then((data) => {
        vm.priceLabels = data.Datas;
        vm.pagination = data.Pagination;
      });
    },
    add() {
      this.priceLabel = {};
      this.isShowModal = !this.isShowModal;
      this.modalTitle = this.$t("modal.addpricelabel");
    },
    edit(priceLabel) {
      if (!this.authority.IsUpdate) {
        return;
      }
      const vm = this;
      this.$api.priceLabels.get(priceLabel.PriceLabelId).then((data) => {
        vm.priceLabel = data;
        vm.isShowModal = !vm.isShowModal;
        vm.modalTitle = this.$t("modal.editpricelabel");
      });
    },
    remove(priceLabel) {
      const vm = this;
      this.$api.priceLabels.remove(priceLabel.PriceLabelId).then(() => {
        vm.getTrigger = !vm.getTrigger;
      });
    },
    save() {
      const vm = this;
      this.$api.priceLabels
        .save(vm.priceLabel)
        .then(() => {
          vm.isShowModal = !vm.isShowModal;
          vm.getTrigger = !vm.getTrigger;
        })
        .catch((error) => vm.$func.message.error(error.data.Message));
    },
  },
  mounted() {
    this.setOptions();
  },
  created() {
    this.authority = this.$func.localStorage.getAuthByName(this.$route.name);
  },
};
</script>
