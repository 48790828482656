import { render, staticRenderFns } from "./PGTalkDeposit.vue?vue&type=template&id=03d20a96&"
import script from "./PGTalkDeposit.vue?vue&type=script&lang=js&"
export * from "./PGTalkDeposit.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports