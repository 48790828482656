<template>
  <form
    id="foodImage"
    class="modal fade"
    tabindex="-1"
    role="dialog"
    data-backdrop="static"
    @submit.prevent="onSaved"
  >
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">{{ $t("title.foodimage") }}</h5>
          <button type="button" class="close" @click="onClosed">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body px-4">
          <div class="row">
            <div class="m-auto w-100 d-flex justify-content-center">
              <span>＊建議圖片大小不超過100KB＊</span>
            </div>
            <div class="m-auto py-2">
              <label for="imageFile">
                <div>
                  <img class="img-food" :src="img" alt="" v-if="img" />
                  <div v-else class="img-food border-gray"></div>
                  <input
                    id="imageFile"
                    type="file"
                    @change="onFileChanged"
                    hidden
                  />
                </div>
              </label>
            </div>
            <button type="submit" class="btn btn-primary btn-lg btn-block">
              {{ $t("button.save") }}
            </button>
            <button
              type="button"
              class="btn btn-secondary btn-lg btn-block"
              @click="onCleared"
            >
              {{ $t("button.clear") }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </form>
</template>

<script>
import $ from "jquery";

export default {
  data() {
    return {
      img: "",
      file: "",
    };
  },
  props: ["id"],
  methods: {
    onFileChanged(e) {
      if (e.target.files[0].size > 512000) {
        this.$func.message.alert("圖片大小不可超過500KB !");
        return;
      }
      this.file = e.target.files[0];
      var reader = new FileReader();
      reader.readAsDataURL(e.target.files[0]);
      reader.onload = () => {
        this.img = reader.result;
      };
    },
    onSaved() {
      let formData = new FormData();
      formData.append("file", this.file);
      const vm = this;
      this.$api.foods.postImage(vm.id, formData).then(() => {
        $(`#foodImage`).modal("toggle");
        vm.$emit("onClosed");
      });
    },
    onClosed() {
      $(`#foodImage`).modal("toggle");
      window.onbeforeunload = null;
      this.$emit("onClosed");
    },
    onCleared() {
      const vm = this;
      this.$api.foods.deleteImage(vm.id).then(() => {
        $(`#foodImage`).modal("toggle");
        window.onbeforeunload = null;
        vm.$emit("onClosed");
      });
    },
  },
  mounted() {
    $(`#foodImage`).modal("toggle");
    const vm = this;
    this.$api.foods.getImage(this.id).then((data) => {
      if (data.size > 0) {
        vm.file = data;
        let reader = new FileReader();
        reader.readAsDataURL(data);
        reader.onload = () => {
          vm.img = reader.result;
        };
      }
    });
    window.onbeforeunload = () => {
      return true;
    };
  },
};
</script>
