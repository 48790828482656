import { httpGet, httpPost, httpDelete } from "@/request/http";

let path = "/onlineordermodifiergroups";

export default {
  get(id) {
    return httpGet(`${path}/${id}`);
  },
  getPages(dTO) {
    return httpGet(`${path}/pages`, dTO);
  },
  post(item) {
    return httpPost(`${path}`, item);
  },
  postBatch(dTO) {
    return httpPost(`${path}/batch`, dTO);
  },
  remove(id) {
    return httpDelete(`${path}/${id}`);
  },
  getItems(id) {
    return httpGet(`${path}/${id}/onlineorderitems`);
  },
  postItems(id, dTO) {
    return httpPost(`${path}/${id}/onlineorderitems`, dTO);
  },
  getTastesSequence(id) {
    return httpGet(`${path}/${id}/onlineordermodifiers/sequence`);
  },
  postTastesSequence(id, items) {
    return httpPost(`${path}/${id}/onlineordermodifiers/sequence`, items);
  },
  getTasteKindsSequence() {
    return httpGet(`${path}/sequence`);
  },
  postTasteKindsSequence(items) {
    return httpPost(`${path}/sequence`, items);
  },
};
