<template>
  <form
    :id="modalId"
    class="modal fade"
    tabindex="-1"
    role="dialog"
    data-backdrop="static"
    @submit.prevent="onSearch"
  >
    <div
      class="modal-dialog modal-dialog-centered"
      :class="modalClass"
      role="document"
    >
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">{{ title }}</h5>
          <button type="button" class="close" @click="onClosed">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="col-12">
            <div class="form-group d-flex">
              <label for="searchVoid" class="col-2 col-form-label"
                >關鍵字 :</label
              >
              <input
                type="text"
                class="form-control col-9"
                id="searchVoid"
                v-model="search"
              />
              <div class="col-1 px-1">
                <button type="submit" class="btn btn-outline-primary border-0">
                  <i class="fas fa-search"></i>
                </button>
              </div>
            </div>
          </div>
          <table-part
            :columns="columns"
            :items="items"
            :idProperty="idProperty"
            @currentIndexChanged="currentIndexChanged"
          >
          </table-part>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-danger" @click="onCancelVoid">
            取消作廢
          </button>
          <button type="button" class="btn btn-secondary" @click="onClosed">
            取消
          </button>
        </div>
      </div>
    </div>
  </form>
</template>

<script>
import tablePart from "@/components/parts/tablePart";
import $ from "jquery";

export default {
  data() {
    return {
      items: [],
      columns: [
        {
          name: "SalNumber",
          width: 250,
        },
        {
          name: "InvoiceNumber",
          width: 250,
        },
        {
          name: "CustomerName",
        },
        {
          name: "Total",
        },
      ],
      search: "",
      idProperty: "SalNumber",
      currentIndex: -1,
    };
  },
  props: {
    isShow: false,
    title: String,
    modalId: String,
    businessId: String,
    modalClass: String,
  },
  components: {
    tablePart,
  },
  watch: {
    isShow(value) {
      if (value) {
        this.search = "";
        this.items = [];
        $(`#${this.modalId}`).modal("toggle");
        window.onbeforeunload = () => {
          return true;
        };
      } else {
        $(`#${this.modalId}`).modal("toggle");
        window.onbeforeunload = null;
      }
    },
  },
  methods: {
    onSearch() {
      const vm = this;
      if (this.search.length < 5) {
        this.$func.message.alert("關鍵字至少需五個字");
        return;
      }
      this.$api.businesses.searchVoid(vm.businessId, vm.search).then((data) => {
        vm.items = data;
      });
    },
    onCancelVoid() {
      const vm = this;
      if (this.items[this.currentIndex]) {
        let item = vm.items[vm.currentIndex];
        vm.$func.message
          .confirm(`確定刪除單號${item.SalNumber}嗎 ?`)
          .then((result) => {
            if (result.isConfirmed) {
              vm.$api.businesses
                .cancelVoid(vm.businessId, item.SalId)
                .then(() => {
                  vm.$func.message.success("取消作廢成功");
                  vm.$emit("onClosed");
                })
                .catch((error) => {
                  vm.$func.message.error(error.data.Message);
                });
            }
          });
      } else {
        this.$func.message.alert("請選擇一筆資料");
      }
    },
    currentIndexChanged(index) {
      this.currentIndex = index;
    },
    onClosed() {
      this.$emit("onClosed");
    },
  },
};
</script>
