import { httpGet, httpPost, httpDelete } from "@/request/http";

let path = "/combokinds";

export default {
  get(id) {
    return httpGet(`${path}/${id}`);
  },
  getPages(dTO) {
    return httpGet(`${path}/pages`, dTO);
  },
  save(item) {
    return httpPost(path, item);
  },
  remove(id) {
    return httpDelete(`${path}/${id}`);
  },
  before(item) {
    return httpPost(`${path}/sequence/before`, item);
  },
  after(item) {
    return httpPost(`${path}/sequence/after`, item);
  },
};
