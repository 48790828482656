<template>
  <div>
    <search-and-table-part
      :pagination="pagination"
      @getItems="get"
      @editItem="edit"
      @removeItem="remove"
      :items="combinations"
      :columns="columns"
      :idProperty="idProperty"
      :nameProperty="nameProperty"
      :getTrigger="getTrigger"
      :hasAdd="false"
      :hasEdit="authority.IsUpdate"
      :hasRemove="authority.IsRemove"
      :isSortable="true"
    >
      <template slot="searchArea">
        <div class="row">
          <div class="col-lg-6">
            <div class="form-group row">
              <label
                for="searchFoodKindNumber"
                class="col-3 col-md-2 col-lg-3 col-form-label"
                >{{ $t("modal.foodkindnumber") }}:</label
              >
              <div class="col">
                <input
                  type="text"
                  class="form-control"
                  id="searchFoodKindNumber"
                  v-model="searchDTO.FoodKindNumber"
                />
              </div>
            </div>
          </div>
          <div class="col-lg-6">
            <div class="form-group row">
              <label
                for="searchFoodKindName"
                class="col-3 col-md-2 col-lg-3 col-form-label"
                >{{ $t("modal.foodkindname") }}:</label
              >
              <div class="col">
                <input
                  type="text"
                  class="form-control"
                  id="searchFoodKindName"
                  v-model="searchDTO.FoodKindName"
                />
              </div>
            </div>
          </div>
          <div class="col-lg-6">
            <div class="form-group row">
              <label
                for="searchFoodNumber"
                class="col-3 col-md-2 col-lg-3 col-form-label"
                >{{ $t("modal.foodnumber") }}:</label
              >
              <div class="col">
                <input
                  type="text"
                  class="form-control"
                  id="searchFoodNumber"
                  v-model="searchDTO.FoodNumber"
                />
              </div>
            </div>
          </div>
          <div class="col-lg-6">
            <div class="form-group row">
              <label
                for="searchFoodName"
                class="col-3 col-md-2 col-lg-3 col-form-label"
                >{{ $t("modal.foodname") }}:</label
              >
              <div class="col">
                <input
                  type="text"
                  class="form-control"
                  id="searchFoodName"
                  v-model="searchDTO.FoodName"
                />
              </div>
            </div>
          </div>
        </div>
      </template>

      <template slot="dataButtonArea">
        <import-part
          v-if="authority.IsAdd"
          @onImport="onImport"
          :title="`${$t('modal.import')}`"
        />
        <export-part @onExport="onExport" />
      </template>
    </search-and-table-part>
  </div>
</template>

<script>
import searchAndTablePart from "@/components/parts/searchAndTablePart";
import importPart from "@/components/ImportPart";
import exportPart from "@/components/ExportPart";

export default {
  data() {
    return {
      combinations: [],
      combination: {},
      pagination: {},
      columns: [
        {
          name: "FoodNumber",
        },
        {
          name: "FoodName",
        },
      ],
      idProperty: "CombinationId",
      nameProperty: "FoodName",
      getTrigger: false,
      authority: {},
      searchDTO: {},
    };
  },
  components: {
    searchAndTablePart,
    importPart,
    exportPart,
  },
  methods: {
    get(dTO) {
      const d = { ...dTO, ...this.searchDTO };
      const vm = this;
      this.$api.combinations.getPages(d).then((data) => {
        vm.combinations = data.Datas;
        vm.pagination = data.Pagination;
      });
    },
    edit(combination) {
      this.$router.push({
        name: "CombinationEdit",
        params: { id: combination.FoodId },
      });
    },
    remove(combination) {
      this.$api.combinations.remove(combination.FoodId).then(() => {
        this.getTrigger = !this.getTrigger;
      });
    },

    onExport() {
      this.$api.combinations.getExcel();
    },
    onImport(file) {
      let formData = new FormData();
      formData.append("file", file);
      this.$api.combinations.postExcel(formData).then(() => {
        this.getTrigger = !this.getTrigger;
      });
    },
  },
  created() {
    this.authority = this.$func.localStorage.getAuthByName(this.$route.name);
  },
};
</script>
