export function getAll() {
  return [
    {
      value: 0,
      name: "LongTermLease",
      text: "長期使用",
    },
    {
      value: 1,
      name: "ShortTermLease",
      text: "短期租賃",
    },
  ];
}

export function getByValue(value) {
  const type = getAll().find(function (item) {
    return item.value == value;
  });
  return type;
}
