export function getAll() {
  return [
    {
      value: 0,
      name: "All",
      text: "全部"
    },
    {
      value: 1,
      name: "Sale",
      text: "開立"
    },
    {
      value: 2,
      name: "Void",
      text: "作廢"
    }
  ];
}
