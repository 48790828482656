<template>
  <div>
    <condition-part
      :hasDate="true"
      :hasTradeStatusType="true"
      :hasStore="true"
      :hasEmployee="true"
      :hasFoodMajorKind="true"
      :hasFoodKind="true"
      :hasTerminal="true"
      :hasExcel="true"
      :hasPdf="true"
      :hasAdd="false"
      :hasEdit="false"
      :hasRemove="false"
      :columns="columns"
      :items="items"
      :pagination="pagination"
      :isSortable="true"
      @getItems="getItems"
      @downloadExcel="downloadExcel"
      @downloadPdf="downloadPdf"
      @printPdf="printPdf"
      :reportTypes="reportTypes"
    >
      <div
        class="row"
        v-if="reportType == 'statistic' && chartData.rows.length > 0"
        slot="middleArea"
      >
        <div class="col-md-6">
          <div class="card card-table card-body">
            <ve-histogram :data="chartData" :settings="chartSettings" />
          </div>
        </div>
        <div class="col-md-6">
          <div class="card card-table card-body">
            <ve-pie :data="chartData"></ve-pie>
          </div>
        </div>
      </div>
    </condition-part>
  </div>
</template>

<script>
import conditionPart from "@/components/parts/conditionPart";

export default {
  data() {
    return {
      items: [],
      pagination: {},
      reportTypes: ["statistic", "detail"],
      reportType: "statistic",
      chartSettings: {
        labelMap: {
          Qty: "數量",
          Total: "金額",
        },
        axisSite: { right: ["Total"] },
        yAxisType: ["normal", "KMB"],
        yAxisName: ["數量", "金額"],
      },
      chartData: {
        columns: ["FoodName", "Qty", "Total"],
        rows: [],
      },
    };
  },
  computed: {
    columns() {
      if (this.reportType == "detail") {
        return [
          { name: "FoodNumber" },
          { name: "FoodName" },
          { name: "SalNumber" },
          { name: "BusinessDay" },
          { name: "BusinessClass" },
          { name: "EmployeeNumber" },
          { name: "EmployeeName" },
          { name: "CustomerNumber" },
          { name: "CustomerName" },
          { name: "Qty" },
          { name: "TotalCost" },
          { name: "Total" },
          { name: "SalMemo" },
          { name: "Memo" },
        ];
      } else {
        return [
          { name: "FoodKindNumber" },
          { name: "FoodKindName" },
          { name: "FoodNumber" },
          { name: "FoodName" },
          { name: "Qty" },
          { name: "TotalCost" },
          { name: "Total" },
          { name: "Unit" },
        ];
      }
    },
  },
  components: {
    conditionPart,
  },
  methods: {
    downloadExcel(condition) {
      this.$api.reportExcels.getFree(condition);
    },
    downloadPdf(condition) {
      this.$api.reportPdfs.getFree(condition);
    },
    printPdf(condition) {
      this.$api.reportPdfs.printFree(condition);
    },
    getItems(condition) {
      this.reportType = condition.ReportType;

      const vm = this;
      this.$api.reports.getFree(condition).then((data) => {
        vm.items = data.Datas;
        vm.pagination = data.Pagination;
        vm.chartData.rows = data.Datas;
      });
    },
  },
};
</script>
