import { httpGet, httpPost, httpDelete } from "@/request/http";
import func from "@/funcs";

let path = "/brands";

export default {
  get(id) {
    return httpGet(`${path}/${id}`);
  },
  getPages(dTO) {
    return httpPost(`${path}/pages`, dTO);
  },
  save(item) {
    return httpPost(`${path}`, item);
  },
  remove(id) {
    return httpDelete(`${path}/${id}`);
  },
  getExcel() {
    func.getExcel.get(`${path}/excel`, "品牌");
  },
  postExcel(formData) {
    return func.postExcel(`${path}/excel`, formData, 180000);
  },
};
