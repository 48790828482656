import { httpGet, httpPost, httpDelete } from "@/request/http";

let path = "/ocardrules";

export default {
  getPages(dTO) {
    return httpGet(`${path}/pages`, dTO);
  },
  get(id) {
    return httpGet(`${path}/${id}`);
  },
  save(item) {
    return httpPost(path, item);
  },

  saveFoodIds(id, item) {
    return httpPost(`${path}/${id}/foods`, item);
  },
  remove(id) {
    return httpDelete(`${path}/${id}`);
  },
};
